import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  carousalData: any;
  ourMission: any;
  explore: any;
  learnAbout: any;
  testimonials: any;
  windowWidth: any;
  errorMsg: any;
  loading: boolean;
  other: any;
  deferredPrompt: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HomePageController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getHomePageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      carousalData: [],
      ourMission: [],
      explore: [],
      learnAbout: [],
      testimonials: [],
      windowWidth: window.innerWidth,
      errorMsg: '',
      loading: false,
      other: [],
      deferredPrompt: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  // Customizable Area Start
  componentDidMount = (): any => {
    setTimeout(() => {
      // @ts-ignore
      document.getElementById("pwa").style.display = "none";
    }, 30000)
    this.getHomePageDetails()
    window.addEventListener('resize', this.updateDimensions)
    window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
  }

  componentWillUnmount = (): any => {
    window.removeEventListener('resize', this.updateDimensions)
    window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
  }
  // Customizable Area End

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if(apiRequestCallId === this.getHomePageId){
          if (responseJson && !responseJson.error && responseJson.homepage_data) {
            if (responseJson.homepage_data.length === 0) {
                this.setState({
                    errorMsg: "Data Not Found",
                    loading: false
                });
            } else {
                this.setState({
                    ...this.state,
                    errorMsg: "",
                    loading: false,
                    carousalData: responseJson?.homepage_data?.slider,
                    ourMission: responseJson?.homepage_data?.mission,
                    explore: responseJson?.homepage_data?.explore,
                    learnAbout: responseJson?.homepage_data?.learn_abouts,
                    testimonials: responseJson?.homepage_data?.testimonials,
                    other: responseJson?.homepage_data?.other
                }, () => {
                  console.log("Inside controlle satet", this.state)
                });
            }
          } else {
              if (errorReponse === undefined) {
                  this.setState({
                      errorMsg: "Something went wrong",
                      loading: false,
                  });
              } else {
                  this.setState({
                      errorMsg: errorReponse,
                      loading: false,
                  });
              }
          }
        }
      }

  }
}


// Customizable Area Start

hidePWA = () => {
  // @ts-ignore
  document.getElementById("pwa").style.display = "none";
}

handleBeforeInstallPrompt = (event: any) => {
  event.preventDefault();
  this.setState({ deferredPrompt: event });
};

handleInstallClick = () => {
  const { deferredPrompt } = this.state;

  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('PWA installed');
        // Optional: Perform additional actions after installation
      }

      this.setState({ deferredPrompt: null });
    });
  }
};



getHomePageDetails = () => {
  this.setState({
    loading: true,
  })
  const header = {
      "Content-Type": configJSON.contentType,
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getHomePageId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getHomePageApi
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

updateDimensions = () => this.setState({windowWidth: window.innerWidth})

filterLearnAbout = (data: string) => {
  window.localStorage.setItem('searchQuery', data)
      if (window.location?.pathname === '/Search') {
        window.location.reload();
      } else {
        this.props.navigation.navigate("Search");
      }
}

getSecPath = (getFullName: string): string => {
  const [_, secName] = getFullName?.trim()?.split(" ");
  switch (secName?.toLowerCase()) {
    case 'talk':
      return '/podcast';
    case 'read':
      return '/Blog';
    case 'watch':
      return '/Videos';
    case 'learn':
      return '/AllCourses';
    case 'discover':
      return '/LetsDiscovers/User';
    default:
      return '';
  }
};


// Customizable Area End
  
}
