import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import React, { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { blogImg1 } from "../../blocks/ContentManagement/src/assets";
import { getAge } from "../../blocks/ContentManagement/src/BlogPage.web";
import { BlogType } from "../../blocks/ContentManagement/src/Interfaces.web";
import { useDropzone } from 'react-dropzone'
import IconButton from '@material-ui/core/IconButton';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
export interface DropProps {
    imgId: number;
    handleImgChange: (id: number, value: any[], files: any[]) => void;
    imagesList: any[];
    isUrl?: boolean;
}

const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#EEEEEE',
    borderStyle: 'solid',
    backgroundColor: '#FAFAFA',
    // color: '#BDBDBD',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: "pointer"
};
const focusedStyle = {
    borderColor: '#2196F3'
};
const acceptStyle = {
    borderColor: '#00E676'
};
const rejectStyle = {
    borderColor: '#FF1744'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


const CustomDrop = ({ isUrl, imgId, handleImgChange, imagesList }: DropProps) => {
    const [files, setFiles] = useState<any[]>();
    const classes = useStyles();
    const { getRootProps, getInputProps, acceptedFiles, isFocused, isDragAccept, isDragReject } = useDropzone({
        // noClick: false,
        accept: { 'image/*': [] },
        useFsAccessApi: false,
        maxFiles: 3,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: isUrl ? file : URL.createObjectURL(file)
            })));
            let selectedFiles: any[] = []
            let _files: any[] = [];
            acceptedFiles.forEach(file => {
                selectedFiles.push(file);
                getBase64(file, (base64Img: any) => {
                    _files.push(base64Img.split(',').pop())
                });
            })

            setTimeout(() => {
                handleImgChange(imgId, selectedFiles, _files)
            }, 1500)

        }
    });


    const style: any = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const thumbs = imagesList?.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div style={thumbInner}>
                {
                    typeof(file) === "string" ? <img
                        src={file}
                        style={img}
                        // Revoke data uri after image is loaded
                        // onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                    :
                    <img
                    src={URL.createObjectURL(file)}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
                }
                
            </div>
        </div>
    ));
    return (
        <div  {...getRootProps({ style })} >
            <input {...getInputProps()} />
            {
                thumbs.length  ?
                <>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <aside className={classes.thumbsContainer}>
                            {thumbs}
                        </aside>
                        <IconButton aria-label="delete">
                            <img height="100px" src={require("../src/assets/plus.png")} />
                        </IconButton>
                    </div>
                </>
                :
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <img
                            height="70px"
                            src={require('../src/assets/uploadIcon.png')}
                            alt="" 
                        />
                        <p 
                            style={{ 
                                fontFamily: "Poppins-SemiBold" 
                            }}
                        >
                            Drag and drop image files to upload
                        </p>
                    </div>
                </>
            }
            
        </div >
    )
}
export default CustomDrop;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        baseStyle: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#EEEEEE',
            borderStyle: 'dashed',
            backgroundColor: '#FAFAFA',
            // color: '#BDBDBD',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        },
        focusedStyle: {
            borderColor: '#2196F3'
        },
        cceptStyle: {
            borderColor: '#00E676'
        },
        ejectStyle: {
            borderColor: '#FF1744'
        },
        thumb: {
            display: 'inline-flex',
            borderRadius: 2,
            border: '1px solid #eaeaea',
            marginBottom: 8,
            marginRight: 8,
            width: 100,
            height: 100,
            padding: 4,
            boxSizing: 'border-box'
        },
        thumbsContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 16
        }

    })
);