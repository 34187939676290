import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import RequestApi from "../../../components/src/RequestApi";
import { ProfileContext } from "./contextFile/ProfileContext";


const token = typeof window !== 'undefined' ? localStorage.getItem("token") : null
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  profileImg: any;
  loading: boolean;
  alertMessage: any;
  alertOpen: boolean;
  isInfoEditable: boolean;
  isPasswordEditable: boolean;
  fName: any;
  lName: any;
  email: any;
  inputError: any;
  oldPwd: any;
  newPwd: any;
  confirmPwd: any;
  inputPwdError: any;
  validatePassword: boolean;
  validInfo: boolean;
  userName: string;
  userPic: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuestionBankController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  static contextType: any = ProfileContext;
  updateProfileId: string = '';
  emailReg: RegExp;
  nameReg: RegExp;
  newsLetterId: string = '';
  passwordReg: RegExp;
  updatePwdId: string = '';
  getProfileCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      profileImg: '',
      loading: false,
      alertMessage: '',
      alertOpen: false,
      isInfoEditable: false,
      isPasswordEditable: false,
      fName: '',
      lName: '',
      email: '',
      userName: '',
      userPic: '',
      inputError: '',
      oldPwd: '',
      newPwd: '',
      confirmPwd: '',
      inputPwdError: '',
      validatePassword: false,
      validInfo: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    this.nameReg = new RegExp("^[a-zA-Z ]+$");
    this.passwordReg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    // istanbul ignore next
    if (apiRequestCallId != null) {
      if (apiRequestCallId == this.updateProfileId) {
        if(Object.keys(responseJson.data).length){
          this.setProfileResponse(responseJson)
        } else {
          this.setState({
            alertOpen: true,
            alertMessage: 'Something went wrong!',
            loading: false
          })
        }
      }

      if(apiRequestCallId === this.newsLetterId){
        this.setState({
          loading: false,
          alertMessage: responseJson.message,
          alertOpen: true
        }, () => {
          localStorage.setItem('newsLetter', 'true')
        })
      }

      if(apiRequestCallId === this.updatePwdId){
        this.setPwdResponse(responseJson)
      }

      if(apiRequestCallId === this.getProfileCallId){
        this.getProfileResponse(responseJson)
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start

  setPwdResponse = (responseJson: any) => {
    if(responseJson.error || responseJson.errors){
      // istanbul ignore next
      this.setState({
        loading: false,
        alertOpen: true,
        alertMessage: responseJson.error ? responseJson.error : responseJson.errors
      })
    } else {
      this.setState({
        loading: false,
        alertOpen: true,
        alertMessage: 'Password changed successfully!',
        isPasswordEditable: false,
        oldPwd: '',
        newPwd: '',
        confirmPwd: '',
        inputPwdError: {}
      })
    }
  }

  setProfileResponse = (responseJson: any) => {
    if(responseJson?.data && !responseJson.errors){
      this.setState({
        alertOpen: true,
        alertMessage: responseJson?.data?.attributes?.message,
        loading: false,
        isInfoEditable: false
      }, () => {
        this.getProfileRequest()
      })
    } else {
      this.setState({
        alertOpen: true,
        alertMessage: "Sorry! Something went wrong",
        loading: false,
        isInfoEditable: false,
      })
    }
  }

  setMailVerifyResponse = (responseJson: any) => {
    this.setState({
      alertOpen: true,
      alertMessage: 'Profile updated successfully! Please check your mailbox to verify your email.',
      loading: false,
      isInfoEditable: false,
      // email: typeof window !== 'undefined' ? localStorage.getItem('userEmail') : null
    }, () => {
      this.getProfileRequest()
    })
  }

  setResponse = (responseJson: any) => {
    if(typeof window !== 'undefined'){
      localStorage.setItem('userPic', responseJson.data?.attributes?.profile_photo);
      localStorage.setItem('userName', responseJson.data?.attributes?.full_name);
      localStorage.setItem('userEmail', responseJson.data?.attributes?.email);
      this.setName();
    }
  }

  setName = () => {
    const userName = typeof window !== 'undefined' ? localStorage.getItem('userName') : null;
    this.setState({
      userName: userName ? userName : ''
    })
  }

  async componentDidMount(): Promise<void> {
    const token = localStorage.getItem('token');
    this.getProfileRequest()
    // istanbul ignore next
    if(!token){
      this.props.navigation.navigate('Login')
    }
  }

  handlePInfoChange = (e: any, type: any) => {
    const value: any = e.target.value;
    // istanbul ignore if
    if(type === 'fName'){
      this.setState({
        fName: value
      }, () => {
        this.infoValidation();
      })
    } else if(type === 'lName'){
      this.setState({
        lName: value
      }, () => {
        this.infoValidation();
      })
    } else {
      this.setState({
        email: value
      }, () => {
        this.infoValidation();
      })
    }
    this.infoValidation();
  }

  infoValidation = () => {
    let error: any = {}
    const {
      fName,
      lName,
      email
    } = this.state;

    if(!fName){
      error.fName = 'Please provide first name!'
    }

    if(!this.nameReg.test(fName)){
      error.fName = 'Please provide a valid first name!'
    }

    if(!lName){
      error.name = 'Please provide last name!'
    }

    if(!this.nameReg.test(lName)){
      error.lName = 'Please provide a valid last name!'
    }

    if(!email){
      error.email = 'Please provide email!'
    }

    if(!this.isValidEmail(email)){
      error.email = "Please provide a valid email"
    }

    if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
      this.setState({ inputError: {}, validInfo: true })
    }
    else {
        this.setState({ inputError: error, validInfo: false })
    }
  }

  handlePwdChange = (e: any, type: any) => {
    const value: any = e.target.value;
    if(type === 'oldPwd'){
      this.setState({
        oldPwd: value
      }, () => {
        this.validatePwd()
      })
    } else if (type === 'newPwd'){
      this.setState({
        newPwd: value
      }, () => {
        this.validatePwd()
      })
    } else {
      this.setState({
        confirmPwd: value
      }, () => {
        this.validatePwd()
      })
    }
    this.validatePwd()
  }

  validatePwd = () => {
    let error: any = {}
    const {
      oldPwd,
      newPwd,
      confirmPwd
    } = this.state;

    if(!oldPwd){
      error.oldPwd = 'Please provide old password!'
    }

    if(!newPwd){
      error.newPwd = 'Please provide new password!'
    }

    if(!this.passwordReg.test(newPwd)){
      error.newPwd = 'Password must contains atleast one number, one capital letter, one small letter and one special character.'
    }

    if(newPwd !== confirmPwd){
      error.confirmPwd = 'Confirm password is not same as new password!'
    }

    if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
      this.setState({ inputPwdError: {}, validatePassword: true })
    }
    else {
        this.setState({ inputPwdError: error, validatePassword: false })
    }
  }

  updateInfo = () => {
    const {
      fName,
      lName,
      email,
      validInfo
    } = this.state
    // istanbul ignore if
    if(validInfo){
      const formData = new FormData();
      const fullN = `${fName} ${lName}`
      formData.append('account[full_name]', fullN);
      formData.append('account[email]', email);
      this.updateProfile(formData)
    }
  }

  updatePassword = () => {
    this.validatePwd();
    const {
      newPwd,
      oldPwd,
      validatePassword
    } = this.state;
    if(validatePassword){
      // istanbul ignore next
      const data = {
        'data': {
          'old_password': oldPwd,
          'new_password': newPwd
        }
      }
      // istanbul ignore next
      this.updateNewPwd(data);
    }
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }


  onDrop = (files: any) => {
    // istanbul ignore next
    this.setState({
      profileImg: files
    }, () => {
      const formData = new FormData();
      const mediaBlob = new Blob(this.state.profileImg)
      formData.append('account[profile_photo]', mediaBlob)
      this.updateProfile(formData)
    })
  }

  updateProfile = (data: any) => {
    this.setState({
      loading: true
    })
    const localToken = localStorage.getItem("token");
    const header = {
      token: localToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProfileId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateNewPwd = (data: any) => {
    this.setState({
      loading: true
    })
    const header = {
      'Content-Type': 'application/json',
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updatePwdId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePwdApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  subscribeNewsLetter = () => {
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const data = {
      email: this.state.email
    }
    this.newsLetterId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newsLetterApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProfileResponse = (responseJson: any) => {
    if(!responseJson.errors && responseJson.data){
      this.setState({
        userName: responseJson.data.attributes.full_name,
        email: responseJson.data.attributes.email,
        userPic: responseJson.data.attributes.profile_photo
      }, () => this.getFullName())
      this.context?.getUserProfileData()
    }
  }

  getProfileRequest = () => {
    const token = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = RequestApi({
      header: header,
      endPoint: configJSON.getProfileApi,
      method: "GET",
    });

    this.getProfileCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFullName() {
    const userName = this.state.userName;
    const fullName = userName?.split(' ');
    let fName = '';
    let lName = '';
  
    if (fullName && fullName.length >= 3) {
      fName = fullName.slice(0, 2).join(' ');
      lName = fullName.slice(2).join(' ');
    } else if (fullName && fullName.length === 2) {
      fName = fullName[0];
      lName = fullName[1];
    }
  
    this.setState({
      fName,
      lName
    })
  }

  
  // Customizable Area End
  
}
