import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
// Customizable Area End
export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean;
  loading: boolean;
  openAlert: boolean;
  alertMsg: any;
  categoryList: any;
  categoryId: any;
  filmName: any;
  filmAbout: any;
  filmUrl: any;
  filmImage: any;
  inputError: any;
  personList: any;
  personId: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddFilmController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiCreateFilmId: string = "";
  apiGetCategoryDataCallId: string = "";
  apiGetPersonId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mobileOpen: false,
      loading: false,
      openAlert: false,
      alertMsg: '',
      categoryId: '',
      categoryList: '',
      filmAbout: '',
      filmImage: '',
      filmName: '',
      filmUrl: '',
      inputError: '',
      personList: '',
      personId: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getOrgCategories()
    this.getPersons()
  }

  setPersonList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        personList: responseJson.data
      });
    }
  }

  setCreateFilm = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        openAlert: true,
        loading: false,
        alertMsg: 'Film created successfully!'
      });
    } else {
      this.setState({
        loading: false,
        openAlert: true,
        alertMsg: responseJson.errors
      })
    }
  }

  setCategoryList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        categoryList: responseJson.data
      });
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetCategoryDataCallId) {
          this.setCategoryList(responseJson)
        }
        if (apiRequestCallId === this.apiCreateFilmId) {
          this.setCreateFilm(responseJson)
        }
        if(apiRequestCallId === this.apiGetPersonId){
          this.setPersonList(responseJson)
        }
      }
    }
  }

  getPersons = () => {
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPersonId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPersonListApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOrgCategories = () => {
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoryDataCallId = requestMessage.messageId;
    const hitURL = `${configJSON.categoryUrl}?type=film_category`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  validation = () => {
    const {
      filmAbout,
      filmImage,
      filmName,
      filmUrl,
      categoryId,
      personId
    } = this.state

    let error: any = {}
    if (!filmName) {
        error.filmName = "Please provide film name"
    }

    if (!filmAbout) {
      error.filmAbout = "Please provide film about"
    }

    if (!personId) {
      error.personId = "Please select person"
    }

    if (!categoryId) {
      error.categoryId = "Please select category"
    }

    if (!filmUrl) {
      error.filmUrl = "Please provide film link"
    }

    if (!filmImage) {
      error.filmImage = "Please upload film image"
    }
    
    if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
        this.setState({ inputError: {} })
        this.createFilm()
    }
    else {
        this.setState({ inputError: error })
    }
  }

  createFilm = () => {
    this.setState({
      loading: true
    })
    const userToken = window.localStorage.getItem("token");
    const header = {
      // "Content-Type": 'multipart/form-data',
      token: userToken
    };

    const { 
        filmAbout,
        filmImage,
        filmName,
        filmUrl,
        categoryId,
        personId
      } = this.state

    const formData = new FormData();

    formData.append('films[name]', filmName);
    formData.append('films[about]', filmAbout);
    formData.append('films[category_id]', categoryId);
    formData.append('films[person_id]', personId);
    let blobFilmImage = new Blob(filmImage);
    formData.append('films[image]', blobFilmImage);
    formData.append('films[url]', filmUrl)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateFilmId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createFilmApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCategoryChange = (e: any) => {
    this.setState({
      categoryId: e.target.value
    })
  };

  handleFilmNameChange = (e: any) => {
    this.setState({
      filmName: e.target.value
    })
  }

  handleFilmAboutChange = (e: any) => {
    this.setState({
      filmAbout: e.target.value
    })
  }

  handleFilmUrlChange = (e: any) => {
    this.setState({
      filmUrl: e.target.value
    })
  }

  handleFilmImageChange = (files: any) => {
    this.setState({
      filmImage: files
    })
  }

  handlePersonChange = (e: any) => {
    this.setState({
      personId: e.target.value
    })
  };

  // Customizable Area End

}
