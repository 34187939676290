import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { BlogType, PaginationType } from "../../../ContentManagement/src/Interfaces.web"
import { Views } from "../Interfaces.web";
// Customizable Area End
export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabs: number;
  mobileOpen: boolean;
  apiData: {
    data: BlogType[];
    meta: {
      pagination: PaginationType
    }
  };
  loading: boolean;
  errorMsg: string;
  anchorEl: null | HTMLElement;
  blogPerPage: number;
  modalOpen: boolean;
  currentPage: number;
  type: string;
  sorting: string;
  sortingLoader: boolean;
  statOpen: boolean;
  deleteStatus: boolean;
  deleteId: string;
  viewStats: Views;
  previewId: string;
  previewModal: boolean;
  actionLoading: boolean;
  searchQuery: string;
  searchList: any;
  openSearch: boolean;
  searchLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LetsReadController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiGetDataCallId: string = "";
  statusUpdateCallId: string = "";
  deleteDataCallId: string = "";
  viewStatsCallId: string = "";
  deleteDataId: string = "";
  apiSearchItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // getName(MessageEnum.NavigationToSingleArticle),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tabs: 0,
      mobileOpen: false,
      apiData: {
        data: [],
        meta: {
          pagination: {
            current_page: 1,
            next_page: 0,
            prev_page: 0,
            total_pages: 0,
            total_count: 0,
          }
        }
      },
      loading: false,
      actionLoading: false,
      errorMsg: '',
      anchorEl: null,
      blogPerPage: 10,
      modalOpen: false,
      statOpen: false,
      currentPage: 1,
      type: 'published',
      sorting: 'newest_first',
      sortingLoader: false,
      deleteStatus: false,
      deleteId: "",
      viewStats: {
        July: 0,
        January: 0,
        February: 0,
        March: 0,
        April: 0,
        May: 0,
        June: 0,
        August: 0,
        September: 0,
        October: 0,
        November: 0,
        December: 0
      },
      previewId: "",
      previewModal: false,
      searchQuery: '',
      searchList: [],
      openSearch: false,
      searchLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId == this.apiGetDataCallId) {
          if (responseJson && !responseJson.errors && responseJson.data) {
            if (responseJson.data.length === 0) {
              this.setState({
                errorMsg: "Data Not Found",
                loading: false,
                sortingLoader: false,
                apiData: {
                  data: [],
                  meta: {
                    pagination: {
                      current_page: 1,
                      next_page: 0,
                      prev_page: 0,
                      total_pages: 0,
                      total_count: 0,
                    }
                  }
                }
              });
            } else {
              this.setState({
                ...this.state,
                apiData: responseJson,
                errorMsg: "",
                loading: false,
                sortingLoader: false,
              });
            }
          } else {
            if (errorReponse === undefined) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false,
                sortingLoader: false,
              });
            } else {
              this.setState({
                errorMsg: errorReponse,
                loading: false,
                sortingLoader: false,
              });
            }
          }
        }
        if(apiRequestCallId === this.apiSearchItemCallId){
          if (responseJson && !responseJson.errors) {
            this.setState({
              searchList: responseJson,
              searchLoading: false,
            })
          }
        }
        if (apiRequestCallId == this.viewStatsCallId) {
          if (responseJson && !responseJson.errors && responseJson.views) {
            this.setState({
              ...this.state,
              viewStats: responseJson.views,
              errorMsg: "",
            });
          } else {
            if (errorReponse === undefined) {
              this.setState({
                errorMsg: "Something went wrong",
              });
            } else {
              this.setState({
                errorMsg: errorReponse,
              });
            }
          }
        }
        if (apiRequestCallId == this.deleteDataCallId) {

          if (responseJson && !responseJson.errors) {
            const { apiData } = this.state;
            const filterData = apiData?.data.filter((data) => data.id !== this.deleteDataId)
            this.setState((prev_state) => {
              return {
                actionLoading: false,
                mobileOpen: false,
                deleteStatus: true,
                errorMsg: "",
                apiData: {
                  ...prev_state.apiData,
                  data: filterData,
                }
              }
            });
          } else {
            if (errorReponse === undefined) {
              this.setState({
                errorMsg: "Something went wrong",
                actionLoading: false
              });
            } else {
              this.setState({
                errorMsg: errorReponse,
                actionLoading: false
              });
            }
          }
        }
        if (apiRequestCallId == this.statusUpdateCallId) {

          if (responseJson && !responseJson.errors) {
            const { apiData } = this.state;
            const filterData = apiData?.data.filter((data) => {
              if (data.id === this.deleteDataId) {
                console.log({ id: data.id, name: data.attributes.title, data })
              }
              return data.id !== this.deleteDataId
            })
            this.setState((prev_state) => {
              return {
                errorMsg: "",
                actionLoading: false,
                apiData: {
                  ...prev_state.apiData,
                  data: filterData,
                }
              }
            });
          } else {
            if (errorReponse === undefined) {
              this.setState({
                errorMsg: "Something went wrong",
                actionLoading: false
              }); 
            } else {
              this.setState({
                errorMsg: errorReponse,
                actionLoading: false
              });
            }
          }
        }
      }
    }
    // Customizable Area End

  }
  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getData(this.state.type, this.state.sorting, this.state.currentPage)

  }

  // Customizable Area End

  // Customizable Area Start

  getSearchItems = () => {
    this.setState({
      searchLoading: true
    })
    const header = {
      // "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSearchItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.adminSearchApi+`?key=${this.state.searchQuery}&data=blogs`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  handleInputChange = (e: any) => {
    this.setState({
      searchQuery: e.target.value
    }, () => {
      this.setState({
        openSearch: true,
        searchLoading: true
      }, ()=> {
        setTimeout(() => {
          this.getSearchItems();
        }, 5000)
      })
    })
  }

  getData = (type: string, sort: string, page: number, perPage?: number) => {

    this.setState({ loading: true, currentPage: page, type: type })
    if (sort) {
      this.setState({ sorting: sort })
    }
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;
    const hitURL = `${configJSON.letsReadGetUrl}?type=${type}&page=${page}&per_page=${perPage ? perPage : this.state.blogPerPage}&sort=${sort ? sort : this.state.sorting}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSortingLoader = (type: string, sort: string, page: number, perPage?: number) => {

    this.setState({ sortingLoader: true, currentPage: page, type: type })
    if (sort) {
      this.setState({ sorting: sort })
    }
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;
    const hitURL = `${configJSON.letsReadGetUrl}?type=${type}&page=${page}&per_page=${perPage ? perPage : this.state.blogPerPage}&sort=${sort ? sort : this.state.sorting}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleStatusUpdate = (id: string, status: string) => {
    this.setState({
      actionLoading: true
    })
    const header = {
      "Content-Type": configJSON.contentType,
      token: localStorage.getItem("token")
    };

    let blogs: any = {}
    blogs.status = status;
    const body = {
      blogs: blogs
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.statusUpdateCallId = requestMessage.messageId;
    this.deleteDataId = id
    const hitURL = `${configJSON.updateBlogUrl}/${id}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteData = (id: string) => {
    this.setState({
      actionLoading: true
    })
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteDataCallId = requestMessage.messageId;
    this.deleteDataId = id;
    const hitURL = `${configJSON.updateBlogUrl}/${id}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getViewStats = (id: string) => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewStatsCallId = requestMessage.messageId;
    const hitURL = `${configJSON.viewStatsUrl}?id=${id}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewStatsUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    console.log("viewStates api call", requestMessage)

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleModalOpen = (id: string) => {
    this.setState({ modalOpen: true, deleteId: id })
  }
  handleStatOpen = (id: string) => {
    this.setState({ statOpen: true }, () => this.getViewStats(id))
  }
  handleModalClose = () => {
    this.setState({ modalOpen: false, statOpen: false, previewModal: false })
  }
  closeAlertBox = () => {
    this.setState({
      deleteStatus: false
    });
  };
  handleDeleteClose = () => {
    this.setState({ modalOpen: false }, () => this.deleteData(this.state.deleteId))
  }
  handlePreviewOpen = (id: string) => {
    this.setState({ previewModal: true, previewId: id })
  }
  goToEditPage = (id: any) => {
    this.props.navigation.navigate("EditLetsRead", {
      id:id
    })
  }


  // Customizable Area End

}
