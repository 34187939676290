import React, {useState, useEffect, useContext} from "react";
import { View } from "react-native";
import { withRouter } from "react-router-dom";
import { 
  Box,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
  Button,
  Typography,
  TextField,
  IconButton
} 
from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CustomButton from "../src/CustomButton";
import {palette} from "../src/theme";
import DrawerComponent from "../src/DrawerComponent";
import { makeStyles } from "@material-ui/core/styles";
import {Link} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { ProfileContext } from "../../blocks/QuestionBank/src/contextFile/ProfileContext";
export const configJSON = require("../../framework/src/config");
const axios = require('axios').default;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  navButton: {
    margin: "auto",
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    backgroundColor: "orange",
  },
}));

const TopNav = ({ history }) => {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuList, setMenuList] = useState({});
  const [searchModal, setSearchModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchError, setSearchError] = useState('');
  const token = localStorage.getItem('token');


  useEffect(() => {
    if(token){
      getMenuListWithHeader();
    } else {
      getMenuList();
    }
  }, []);

  const getMenuList = () => {
    axios.get(configJSON.baseURL + '/bx_block_admin/menus')
    .then(function (response) {
      // handle success
      const sortedMenu = response.data.data.sort((a, b) => {
        return (a.id - b.id);
      });
      setMenuList(sortedMenu);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }

  const getMenuListWithHeader = () => {
    const header = {
      token: token
    }
    axios.get(configJSON.baseURL + '/bx_block_admin/menus', {headers: header})
    .then(function (response) {
      // handle success
      const sortedMenu = response.data.data.sort((a, b) => {
        return (a.id - b.id);
      });
      setMenuList(sortedMenu);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }

  const handleOpenMenu = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const openSearch = () => {
    setSearchModal(true)
  }

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleClose = () => {
    if(searchQuery){
      window.localStorage.setItem('searchQuery', searchQuery)
      if (history.location.pathname === '/Search') {
        window.location.reload();
      } else {
        history.push('/Search');
      }
      setSearchQuery('')
      setAnchorEl(null);
    } else {
      setSearchError('Please input your search.')
    }
  }

  const handleMenuClose = (link) => {
    setAnchorEl(null);
    if(link){
      history.replace(link)
    }
    if(token){
      getMenuListWithHeader();
    } else {
      getMenuList();
    }
  }

  const closeModal = () => {
    setSearchModal(false)
  }

  const handleMenuLogOutClose = (val) => {
    if(val == 'logout'){
      getMenuList();
      setAnchorEl(null);
      localStorage.clear();
      history.replace('/Login')
    } else {
      setAnchorEl(null);
    }
  }

  const getSearchMenuPopup = () => {
    return (
      <Menu
        anchorEl={anchorEl && anchorEl[999]}
        keepMounted
        open={anchorEl && Boolean(anchorEl[999])}
        // onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        style={{
          textAlign: 'left',
          marginTop: "10px"
        }}
      >
        <MenuItem
          onClick={() => closeModal()}
          style={{
            fontSize: '14px',
            fontFamily: 'Poppins-SemiBold',
            fontWeight: '500',
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            // padding: '10px',
            paddingTop: '5px',
            paddingBottom: '5px',
            textAlign: 'left',
            minWidth: '150px',
            // height: "150px"
          }}
        >
          <Box>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'relative',
                marginRight: "20px"
              }}
            >
              <IconButton
                onClick={handleMenuClose}
                aria-haspopup="true"
              >
                <CancelRoundedIcon
                  style={{
                    color: palette.primary.main,
                    cursor: 'pointer',
                    right: '-30px',
                    position: 'absolute',
                    top: 0,
                  }}
                />
              </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "flex-end" }}>
              <TextField
                fullWidth
                name="title"
                value={searchQuery}
                onChange={(e) => handleSearchChange(e)}
                InputProps={{
                  // className: classes.input,
                  disableUnderline: true,
                  style: {
                    height: "44px",
                    border: "1px solid #e6e8f0",
                    borderRadius: "9px",
                    padding: "16px 32px 16px 16px",
                    fontSize: "20px",
                  }
                }}
                style={{
                  fontSize: "20px",
                  fontFamily: "Poppins-Light",
                }}
                placeholder="Search here"
                error={searchError === undefined || searchError === "" ? false : true}
                helperText={searchError}
              />
              <Button
                onClick={handleClose}
                autoFocus
                style={{
                  background: palette.primary.main,
                  color: '#fff',
                  width: "40%"
                }}
              >
                Search
              </Button>
            </div>
          </Box>
        </MenuItem>
      </Menu>
    )
  }

  //Breakpoints
  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down('md'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const {full_name} = useContext(ProfileContext)
  

  return (
    <View>
      <div>
        <Dialog
          open={searchModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        ><DialogContent>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'relative',
              }}
            >
              <CancelRoundedIcon 
                style={{
                  color: palette.primary.main,
                  cursor: 'pointer',
                  right: '-20px',
                  position: 'absolute',
                  top: '-20px'
                }}
                onClick={() => closeModal()}
              />
            </div>
            <div>
            <TextField
              fullWidth
              name="title"
              value={searchQuery}
              onChange={(e) => handleSearchChange(e)}
              InputProps={{
                // className: classes.input,
                disableUnderline: true,
                style: {
                  height: "64px",
                  border: "1px solid #e6e8f0",
                  borderRadius: "9px",
                  padding: "16px 36px 16px 24px",
                  fontSize: "20px"
                }
              }}
              style={{
                fontSize: "20px",
                fontFamily: "Poppins-Light",
                marginTop: "10px"
              }}
              placeholder="Search here"
              error={searchError === undefined || searchError === "" ? false : true}
              helperText={searchError}
            />
            </div>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleClose} 
              autoFocus
              style={{
                background: palette.primary.main,
                color: '#fff'
              }}
            >
              Search
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <AppBar 
        position="static" 
        color="transparent" 
        // elevation={3}
        style={{
          padding: isSm ? "5px" : "15px",
          boxShadow: '0 -4px 14px 0 rgba(0, 0, 0, 0.13)'
        }}
      >
        <Box style={{paddingLeft: isSm ? '0px' : '30px', paddingRight: isSm ? '0px' : '30px'}}>
          <Toolbar
            style={{
              paddingLeft: '0px',
              paddingRight: '0px'
            }}
          >
            {
              isMatch ? 
                <DrawerComponent />
                : null
            }
            <Box
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: "row",
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box style={{display: 'flex', flex: 1, paddingRight: isSm ? '20px' : '50px', justifyContent: isSm ? 'center' : 'flex-start'}}>
                <img 
                  height={isSm ? '50px' : '80px'} 
                  width={'auto'} 
                  src={require('../src/logo.svg')} 
                  onClick={() => history.push('/')}
                  style={{
                    cursor: 'pointer',
                    marginLeft: isSm ? '-20px' : ''
                  }}
                />
              </Box>
              {
                isMatch ? null
              : 
              <Box
                style={{
                  display: 'flex',
                  flex: 7,
                  flexDirection: "row",
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                {
                  menuList.length && menuList.map((item, index) => (
                    item?.attributes?.is_active 
                      ? <div className={classes.navButton} key={index}>
                        {/* {index}{item}{menuList[index]?.id} */}
                        <Button 
                          color="inherit" 
                          onClick={(e) => handleOpenMenu(index, e)}
                          style={{
                            textTransform: 'none',
                            fontFamily: 'Poppins-Light',
                            fontSize: '16px'
                          }}
                        >
                          {item?.attributes?.name} <ExpandMoreIcon />
                        </Button>
                        <Menu
                          anchorEl={anchorEl && anchorEl[index]}
                          keepMounted
                          key={index}
                          open={anchorEl && Boolean(anchorEl[index])}
                          onClose={handleMenuClose}
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                          transformOrigin={{ vertical: "top", horizontal: "center" }}
                          style={{
                            textAlign: 'left'
                          }}
                        >
                          {item.attributes.sub_menus.length && item.attributes.sub_menus.map((menuitems, menuindex) => (
                            <MenuItem
                              key={menuindex}
                              selected={menuitems === item}
                              onClick={() => handleMenuClose(menuitems.attributes.frontend_url)}
                              style={{
                                fontSize: '14px',
                                fontFamily: 'Poppins-SemiBold',
                                fontWeight: '500',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexDirection: 'column',
                                padding: '10px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                textAlign: 'left',
                                minWidth: '150px'
                              }}
                            >
                              {/* <Link 
                                style={{
                                  fontSize: '14px',
                                  fontFamily: 'Poppins-SemiBold',
                                  fontWeight: '500',
                                  textDecoration: 'none',
                                  color: '#000',
                                  textAlign: 'left',
                                }}
                                to={menuitems.link}
                              > */}
                                {menuitems.attributes.name}
                              {/* </Link> */}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    : null
                  ))
                }
                {
                  !isMatch ?
                  null
                  : 
                  <>
                    <Box
                      style={{
                        padding: isSm ? '5px' : '20px',
                        display: isSm ? 'none' : ''
                      }}
                    >
                      <SearchRoundedIcon 
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={(e) => handleOpenMenu(999, e)}
                      />
                    </Box>
                    {getSearchMenuPopup()}
                    <Box
                      style={{
                        padding: isSm ? '5px' : '20px'
                      }}
                    >
                      <Button 
                        color="inherit"
                        style={{
                          textTransform: 'none'
                        }}
                      >
                        <Link to={'/Login'}>Login</Link>
                      </Button>
                    </Box>
                    <Box
                      style={{
                        padding: isSm ? '5px' : '20px'
                      }}
                    >
                      <Link
                        to={'/signup'}
                        style={{
                          textDecoration: 'none'
                        }}
                      >
                        <CustomButton 
                          title="Sign Up"
                          color={palette.primary.main}
                          textColor={"#FFFFFF"}
                        />
                      </Link>
                    </Box>
                  </>
                }
              </Box>
              }
              {
                !token
                ? <>
                    <Box
                      style={{
                        padding: isSm ? '5px' :  '20px',
                        display: isSm ? 'none' : ''
                      }}
                    >
                      <SearchRoundedIcon 
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={(e) => handleOpenMenu(999, e)}
                      />
                    </Box>
                    <Box
                        style={{
                          padding: isSm ? '5px' :  '20px',
                          display: isSm ? 'none' : ''
                        }}
                      >
                          <Button 
                            color="inherit"
                            style={{
                              textTransform: 'none'
                            }}
                          >
                            <Link 
                              to={'/Login'}
                              style={{
                                textDecoration: 'none',
                                color: '#000'
                              }}
                            >
                              Login
                            </Link>
                          </Button>
                      </Box>
                      {getSearchMenuPopup()}
                    <Box
                      style={{
                        padding: isSm ? '5px' :  '20px',
                        display: isSm ? 'none' : ''
                      }}
                    >
                      <Link
                        to={'/signup'}
                        style={{
                          textDecoration: 'none'
                        }}
                      >
                        <CustomButton 
                          title="Sign Up"
                          color={palette.primary.main}
                          textColor={"#FFFFFF"}
                        />
                      </Link>
                    </Box>
                    </>
                  : <>
                    <Box
                      style={{
                        padding: '20px'
                      }}
                    >
                      <SearchRoundedIcon 
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={(e) => handleOpenMenu(999, e)}
                      />
                    </Box>
                    {getSearchMenuPopup()}
                    <Box
                      style={{
                        padding: '20px'
                      }}
                    >
                    <div className={classes.navButton}>
                    <Button 
                      color="inherit" 
                      onClick={(e) => handleOpenMenu('1a', e)}
                      style={{
                        textTransform: 'none',
                        fontFamily: 'Poppins-Light',
                        fontSize: '16px'
                      }}
                    >
                      <Typography 
                        style={{
                          textTransform: 'Capitalize',
                          color: '#000',
                          fontSize: '16px',
                          fontFamily: 'Poppins-SemiBold'
                        }}
                      >
                        {/* {localStorage.getItem('userName')} */}
                        {full_name}
                      </Typography>
                    </Button>
                    <Menu
                      anchorEl={anchorEl && anchorEl['1a']}
                      keepMounted
                      open={anchorEl && Boolean(anchorEl['1a'])}
                      onClose={() => handleMenuLogOutClose('name')}
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                      transformOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                      
                        {
                          localStorage.getItem('userType') == 'instructor' || localStorage.getItem('userType') == 'admin'  ?
                          <MenuItem
                            onClick={() => handleMenuLogOutClose('dashboard')}
                            style={{
                              fontSize: '14px',
                              fontFamily: 'Poppins-SemiBold',
                              fontWeight: '500'
                            }}
                          >
                            <Link
                              to={'/Dashboard'}
                              style={{
                                fontSize: '14px',
                                fontFamily: 'Poppins-SemiBold',
                                fontWeight: '500',
                                textDecoration: 'none',
                                color: '#000'
                              }}
                            >
                              Dashboard
                            </Link>
                          </MenuItem>
                          : null
                        }

                        <MenuItem
                            style={{
                              fontSize: '14px',
                              fontFamily: 'Poppins-SemiBold',
                              fontWeight: '500'
                            }}
                            onClick={() => handleMenuLogOutClose('dashboard')}
                          >
                            <Link
                              to={'/Favourites'}
                              style={{
                                fontSize: '14px',
                                fontFamily: 'Poppins-SemiBold',
                                fontWeight: '500',
                                textDecoration: 'none',
                                color: '#000'
                              }}
                            >
                              Favourites
                            </Link>
                          </MenuItem>
                          <MenuItem
                            style={{
                              fontSize: '14px',
                              fontFamily: 'Poppins-SemiBold',
                              fontWeight: '500'
                            }}
                            onClick={() => handleMenuLogOutClose('dashboard')}
                          >
                            <Link
                              to={'/accountSettings'}
                              style={{
                                fontSize: '14px',
                                fontFamily: 'Poppins-SemiBold',
                                fontWeight: '500',
                                textDecoration: 'none',
                                color: '#000'
                              }}
                            >
                              My Account
                            </Link>
                          </MenuItem>
                        
                        <MenuItem
                          onClick={() => handleMenuLogOutClose('logout')}
                          style={{
                            fontSize: '14px',
                            fontFamily: 'Poppins-SemiBold',
                            fontWeight: '500'
                          }}
                        >
                          Logout
                        </MenuItem>
                    </Menu>
                  </div>
                </Box>
                </>
              }
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </View>
  );
};

// const styles = StyleSheet.create({
//   main: {
//     flexDirection: "row",
//     backgroundColor: "#ffffff",
//     justifyContent: "space-between",
//     alignItems: "center",
//     boxShadow: '0px 4px 7px -4px rgba(1,1,1,0.54)',
//     padding: '30px',
//     paddingRight: '60px',
//     paddingLeft: '60px',
//   }
// });

export default withRouter(TopNav);
