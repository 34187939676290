import { Box, Card, CardContent, CardMedia, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import BarChartIcon from "@material-ui/icons/BarChart";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import React, { FunctionComponent, ReactNode, useState } from "react";
import Link from "@material-ui/icons/Link";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { BlogType } from "../../blocks/ContentManagement/src/Interfaces.web";
import Send from "@material-ui/icons/Send";
import Restore from "@material-ui/icons/Restore";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { palette } from "./theme";

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Props {
    blogData: BlogType;
    isRestore?: boolean;
    isPublish?: boolean;
    handlePublish?: () => void;
    handleStatOpen?: (id: string) => void;
    handleStatusUpdate: (id: string, status: string) => void;
    handlePreviewOpen?: (id: string) => void;
    type: any;
    copyLink: string;
}




const TheeDot = ({ blogData, isRestore, type, copyLink, handlePreviewOpen, handleStatOpen, handleStatusUpdate, isPublish, handlePublish }: Props) => {
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    const handleSnackBarClick = () => {
        setOpenSnackBar(true);
    };

    const handleSnackBarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    };
    const handleClose = () => {
        setAnchorEl(null)
    };

    const open = Boolean(anchorEl);

    const classes = useStyles();

    return (
        <>
            <Snackbar 
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackBar} 
                autoHideDuration={1000} 
                onClose={handleSnackBarClose}
            >
                <Alert 
                    onClose={handleSnackBarClose} 
                    severity="success"
                    style={{
                        background: palette.primary.main
                    }}
                >
                    Link Copied!
                </Alert>
            </Snackbar>
            <IconButton
                onClick={(e) => handleClick(e)}
                size="small"
                style={{ marginLeft: "3px" }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <MoreHorizRoundedIcon
                    style={{
                        color: '#8991a4',
                        cursor: 'pointer'
                    }}
                />
            </IconButton>


            {
                isRestore ?
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={() => handleClose()}
                        onClick={() => handleClose()}
                        PaperProps={{
                            elevation: 0,
                            className: isRestore ? classes.triangleRestore : classes.triangle,
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem className={classes.menuItemStyle} onClick={() => handleStatusUpdate(blogData.id, "draft")}>
                            <Restore style={{ marginRight: '8px' }} fontSize="small" />
                            Restore {type == 'course' ? 'Course' : 'Post' }
                        </MenuItem>
                    </Menu>
                    :
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={() => handleClose()}
                        onClick={() => handleClose()}
                        PaperProps={{
                            elevation: 0,
                            className: isRestore ? classes.triangleRestore : classes.triangle,
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        {isPublish === true ?
                            <MenuItem className={classes.menuItemStyle} onClick={() => handleStatusUpdate(blogData.id, "published")}>

                                <Send style={{ marginRight: '8px' }} fontSize="small" />

                                Publish Now
                            </MenuItem>
                            :
                            <MenuItem className={classes.menuItemStyle} onClick={() => handleStatOpen?.(blogData.id)}  >

                                <BarChartIcon style={{ marginRight: '8px' }} fontSize="small" />

                                View Status
                            </MenuItem>
                        }
                        {
                            type == 'course' || type == 'podcast' || type == 'video' ?
                            null
                            :
                            <MenuItem className={classes.menuItemStyle} onClick={() => handlePreviewOpen?.(blogData.id)}>

                                <Visibility style={{ marginRight: '8px' }} fontSize="small" />

                                Preview
                            </MenuItem>
                        }
                        <MenuItem 
                            className={
                                classes.menuItemStyle
                            }
                            onClick={
                                () => {
                                    navigator.clipboard.writeText(copyLink).then(
                                        () => {
                                            handleSnackBarClick() 
                                        }
                                    )
                                }
                            }
                            style={{
                                alignItems: 'center'
                            }}
                        >
                            {/* <span
                                onClick={handleSnackBarClick}
                            > */}
                                <Link style={{ marginRight: '8px' }} fontSize="small" />
                                Copy link
                            {/* </span> */}
                        </MenuItem>
                        <MenuItem className={classes.menuItemStyle} onClick={() => handleStatusUpdate(blogData.id, "deleted")}>

                            <DeleteOutlineIcon style={{ marginRight: '8px' }} fontSize="small" />

                            Trash {type == 'course' ? 'Course' : 'Post' }
                        </MenuItem>
                    </Menu>

            }

        </>
    )
}
export default TheeDot;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        triangle: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.3))',
            marginTop: "0",
            marginLeft: "12px",
            borderRadius: "10px",
            '& .MuiAvatar-root': {
                width: '32px ',
                height: '32px',
                marginLeft: '-0.5px ',
                marginRight: '1px',
            },
            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: '0px',
                right: '20px ',
                width: '15px',
                height: '15px ',
                backgroundColor: '#ffffff',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
        },
        triangleRestore: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.3))',
            marginTop: "42px",
            marginLeft: "12px",
            borderRadius: "10px",
            '& .MuiAvatar-root': {
                width: '32px ',
                height: '32px',
                marginLeft: '-0.5px ',
                marginRight: '1px',
            },
            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: '0px',
                right: '20px ',
                width: '15px',
                height: '15px ',
                backgroundColor: '#ffffff',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
        },
        root: {
            color: "#F27024 !important",
            "& .Mui-selected": {
                background: "#F27024",
                color: "white",
                fontWeight: "bold",
            },
            ul: {
                "& .MuiPaginationItem-root": {
                    color: "#F27024"
                }
            }
        },
        modalStyle: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '496px',
            height: "200px",
            backgroundColor: '#ffffff',
            borderRadius: '6px',
            boxShadow: '24px',
            padding: '10px 5px',
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        menuItemStyle: {
            fontFamily: 'Poppins-Light',
            '&:hover': {
                textAlign: "right",
                color: '#d53636',
                backgroundColor: '#ffffff',
            }
        },
    })
);