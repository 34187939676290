import React from "react";
import {
    Button,
    IconButton
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';



const CustomButton = (props: any) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: props.color,
            color: props.textColor,
            textTransform: 'none',
            width: props.fullWidth == true ? '100%' : '',
            height: props.height ? props.height : '',
            fontSize: props.fontSize ? props.fontSize : '',
            fontFamily: 'Poppins-SemiBold',
            paddingLeft: props.pl ? props.pl : '',
            paddingRight: props.pr ? props.pr : '',
            borderRadius : props.br ? props.br : '',
            '&:hover':{
                background: 'transparent',
                border: `1px solid ${props.color}`,
                color: props.color,
                textTransform: 'none',
                width: props.fullWidth == true ? '100%' : '',
                height: props.height ? props.height : '',
                fontSize: props.fontSize ? props.fontSize : '',
                fontFamily: 'Poppins-SemiBold',
                paddingLeft: props.pl ? props.pl : '',
                paddingRight: props.pr ? props.pr : '',
                borderRadius : props.br ? props.br : '',
            }
        }
    }));
    const classes = useStyles();

    return (
        <>
            {
                <Button 
                    variant="contained" 
                    className={classes.root}
                    {...props}
                    startIcon={props.icon}
                >
                    {props.title}
                </Button>
                
            }
        </>
    )
}


export default CustomButton;