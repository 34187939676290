import React, {forwardRef, useEffect} from "react";
import Typography from "@material-ui/core/Typography";

import { makeStyles, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import { palette } from "./theme";
import PictureInPictureAltRoundedIcon from '@material-ui/icons/PictureInPictureAltRounded';
import VolumeDownRoundedIcon from '@material-ui/icons/VolumeDownRounded';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import VolumeMuteRoundedIcon from '@material-ui/icons/VolumeMuteRounded';
import ClosedCaptionRoundedIcon from '@material-ui/icons/ClosedCaptionRounded';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    Box
} from "@material-ui/core";
import { 
    backward10, 
    forward10,
    volMainIcon,
    settingsIcon,
    ccIcon,
    expandIcon
} from "../../blocks/VideoManagement/src/assets";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

interface Props {
    children: React.ReactElement;
    open: boolean;
    value: number;
}

function ValueLabelComponent(props: Props) {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="right" title={value}>
            {children}
        </Tooltip>
    );
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
        >
        {value === index && (
            // @ts-ignore
            <Box p={1}>
                <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}


const StyledTabs = withStyles({
    root: {
        borderBottom: '1px solid #d8d8d8',
        padding: '0px',
    },
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      backgroundColor: palette.primary.main,
      '& > span': {
        maxWidth: '100px',
        minWidth: '100px',
        width: '100%',
        backgroundColor: palette.primary.main,
      },
    },
  })((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
  interface StyledTabProps {
    label: string;
  }

  interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  }
  
  const StyledTab = withStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: 'none',
        color: '#000',
        width: '100px',
        maxWidth: '100px',
        minWidth: '100px',
        fontFamily: 'Poppins-Light',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(12),
        marginRight: theme.spacing(1),
        '&:focus': {
          opacity: 1,
        },
      },
    }),
  )((props: StyledTabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme: any) => ({
  controlsWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.6)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 1,
    [theme.breakpoints.down("xs")]: {
      bottom: -20,
      paddingTop: '0px'
    },
  },

  bottomIcons: {
    color: "#999",
    "&:hover": {
      color: "#fff",
    },
  },
  volumeSlider: {
    width: 50,
  },
  vertical: {
    height: 120,
    padding: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '0px',
    [theme.breakpoints.down("xs")]: {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },
  volumeHorizontal: {
    width: 50, 
    marginTop: '22px'
  }
}));

const VerticalSlider = withStyles({
    root: {
      color: palette.primary.main,
      height: 6,
      '&$vertical': {
        width: 6
      }
    },
    thumb: {
      height: 15,
      width: 15,
      backgroundColor: '#fff',
      border: `3px solid ${palette.primary.main}`,
      marginTop: -8,
      marginLeft: -10,
      '&:focus, &:hover': {
        boxShadow: '0px 0px 0px 8px rgba(84, 199, 97, 0.16)'
      },
      '&$active': {
        boxShadow: '0px 0px 0px 12px rgba(84, 199, 97, 0.16)'
      }
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)'
    },
    track: {
      height: 6,
      borderRadius: 4
    },
    rail: {
      height: 6,
      borderRadius: 4
    },
    vertical: {
      '& $rail': {
        width: 6
      },
      '& $track': {
        width: 6
      },
      '& $thumb': {
        marginLeft: -4.5,
        marginBottom: -10
      }
    }
  })(Slider)


  const VolumeHorizontalSlider = withStyles({
    root: {
      color: palette.primary.main,
      height: 6,
      '&$vertical': {
        width: 6
      }
    },
    thumb: {
      height: 15,
      width: 15,
      backgroundColor: '#fff',
      border: `3px solid ${palette.primary.main}`,
      marginTop: -5,
      marginLeft: -10,
      '&:focus, &:hover': {
        boxShadow: '0px 0px 0px 8px rgba(84, 199, 97, 0.16)'
      },
      '&$active': {
        boxShadow: '0px 0px 0px 12px rgba(84, 199, 97, 0.16)'
      }
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)'
    },
    track: {
      height: 6,
      borderRadius: 4
    },
    rail: {
      height: 6,
      borderRadius: 4
    },
    vertical: {
      '& $rail': {
        width: 6
      },
      '& $track': {
        width: 6
      },
      '& $thumb': {
        marginLeft: -4.5,
        marginBottom: -10
      }
    }
  })(Slider)

const PrettoSlider = withStyles( (theme: any) => ({
  root: {
    height: 4,
    paddingTop: '0px',
    [theme.breakpoints.down("sm")]: {
      padding: '0px'
    }
  },
  thumb: {
    height: 10,
    width: 10,
    backgroundColor: "#fff",
    border: `3px solid ${palette.primary.main}`,
    marginTop: -3,
    marginLeft: -4,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 4,
    borderRadius: 4,
    background: palette.primary.main
  },
  rail: {
    height: 4,
    borderRadius: 0,
    background: '#78909c'
  },
}))(Slider);

export default forwardRef((props:any, ref: any ) => {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:1070px)');
  const forSmall = useMediaQuery('(max-width:600px)');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handlePopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopover2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };


  useEffect(() => {
    window.addEventListener('keyup', (e: any) => {
      if(e.key == 'Escape'){
        props.onEscape();
      }
    })
  }, [])
  

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const id = open ? "playbackrate-popover" : undefined;
  return (
    <div className={classes.controlsWrapper} {...props} ref={ref}>
      {/* bottom controls */}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
        {/* @ts-ignore */}
          <PrettoSlider
            min={0}
            max={100}
            ValueLabelComponent={ValueLabelComponent}
            value={props.state.carausalId == props.videoId ? props.played * 100 : 0}
            onChange={props.onSeek}
            onMouseDown={props.onSeekMouseDown}
            onChangeCommitted={props.onSeekMouseUp}
            // @ts-ignore
            onDuration={props.onDuration}
          />
        </Grid>
        <Grid xs={12} item >
            <Grid container alignItems="center" justifyContent="space-between" direction="row">
                <Grid xs={forSmall ? 8 : matches ? 6 : 5}>
                    {
                      props.backwardSeekIcon ? 
                        <IconButton
                          style={{marginTop: matches && !forSmall  ? '5px' : '0px'}}
                          className={
                              classes.bottomIcons
                          }
                          onClick={props.rewind}
                        >
                            <img src={backward10} height={forSmall ? '22px' : '27px'} />
                        </IconButton>
                      : null
                    }
                    <IconButton 
                        className={
                            classes.bottomIcons
                        }
                        style={{
                            background: '#fff',
                            color: palette.primary.main,
                            padding: forSmall ? '3px' : '6px',
                            marginLeft: forSmall ? '2px' : '10px',
                            marginTop: matches && !forSmall  ? '5px' : '0px'
                        }}
                        onClick={props.playPause}
                    >
                        {
                            !props.state.videoPlaying ?
                                <PlayArrowRoundedIcon 
                                  fontSize={forSmall ? "small" : "large"}
                                />
                            :
                                <PauseRoundedIcon 
                                  fontSize={forSmall ? "small" : "large"}
                                />
                        }
                    </IconButton>

                    {
                      props.forwardSeekIcon ? 
                        <IconButton
                          className={
                              classes.bottomIcons
                          }
                          style={{
                            marginLeft: forSmall ? '2px' : '10px',
                            marginTop: matches && !forSmall ? '5px' : '0px'
                          }}
                          onClick={props.forward}
                        >
                          <img src={forward10} height={forSmall ? '22px' : '27px'} />
                        </IconButton>
                      : null
                    }
                    {
                        matches && props.timeDuration ?
                          <Button 
                              variant="text" 
                              style={{ color: "#fff", marginLeft: 1 }}
                              onClick={props.onChangeDisplayFormat}
                          >
                              <Typography>
                                  {props.elapsedTime} / {props.totalDuration}
                              </Typography>
                          </Button>
                        : null
                    }
                </Grid>
                {/* @ts-ignore */}
                <Grid xs={!matches ? 2 : 0}>
                    {
                        !matches && props.timeDuration ?
                        // @ts-ignore
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Button 
                                variant="text" 
                                style={{ color: "#fff", marginLeft: 16 }}
                                onClick={props.onChangeDisplayFormat}
                            >
                                <Typography>
                                    {props.elapsedTime} / {props.totalDuration}
                                </Typography>
                            </Button>
                        </Box>
                        : null
                    }
                </Grid>
                <Grid xs={forSmall ? 4 : 5}>
                    {/* @ts-ignore */}
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        {
                          props.hideBtn ? 
                            props.volumeIcon ?
                              <>
                                <IconButton style={{color:'#fff', padding: '5px', marginLeft: '7px'}} onClick={props.onMute} className={classes.bottomIcons}>
                                  {
                                      props.muted ?
                                      <VolumeMuteRoundedIcon style={{fontSize: '38px'}} />
                                      : 
                                      props.volume * 100 > 50 ?
                                        <img src={volMainIcon} height={'60px'} style={{marginTop: '6px'}} />
                                      : <VolumeDownRoundedIcon style={{fontSize: '38px'}} />
                                  }
                                </IconButton>
                                <div className={classes.volumeHorizontal}>
                                  {/* @ts-ignore */}
                                  <VolumeHorizontalSlider
                                      orientation="horizontal"
                                      valueLabelDisplay="auto"
                                      ValueLabelComponent={ValueLabelComponent}
                                      min={0}
                                      max={100}
                                      value={props.muted ? 0 : props.volume * 100}
                                      onChange={props.onVolumeChange}
                                      aria-labelledby="input-slider"
                                      className={classes.volumeSlider}
                                      onMouseDown={props.onSeekMouseDown}
                                      onChangeCommitted={props.onVolumeSeekDown}
                                  />
                                </div>
                              </>
                            : null
                          :
                          <>
                            {
                              props.volumeIcon ?
                              <>
                                <Button
                                    onClick={handlePopover}
                                    variant="text"
                                    className={classes.bottomIcons}
                                >
                                  <img src={volMainIcon} height={'60px'} />
                                </Button>
                                <Popover
                                    id={'1'}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                >
                                    <Grid container direction="column">
                                        <div className={classes.vertical}>
                                            {/* @ts-ignore */}
                                            <VerticalSlider
                                                orientation="vertical"
                                                valueLabelDisplay="auto"
                                                ValueLabelComponent={ValueLabelComponent}
                                                min={0}
                                                max={100}
                                                value={props.muted ? 0 : props.volume * 100}
                                                onChange={props.onVolumeChange}
                                                aria-labelledby="input-slider"
                                                className={classes.volumeSlider}
                                                onMouseDown={props.onSeekMouseDown}
                                                onChangeCommitted={props.onVolumeSeekDown}
                                            />
                                        </div>
                                        <div>
                                            <IconButton style={{color:'grey', marginLeft: '7px'}} onClick={props.onMute} className={classes.bottomIcons}>
                                                {
                                                    props.muted ?
                                                    <VolumeMuteRoundedIcon fontSize="medium" />
                                                    : <VolumeUpRoundedIcon fontSize="medium" />
                                                }
                                            </IconButton>
                                        </div>
                                    </Grid>
                                </Popover>
                              </>
                              : null
                            }
                            {
                              props.settingsIcon && !forSmall ?
                                <>
                                  <IconButton onClick={handlePopover2} className={classes.bottomIcons}>
                                    <img src={settingsIcon} height={'60px'} />
                                  </IconButton> 
                                  <Popover
                                    id={'2'}
                                    open={open2}
                                    anchorEl={anchorEl2}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                    }}
                                    transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            maxWidth: '200px'
                                        }}
                                    >
                                        {/* @ts-ignore */}
                                        <Box>
                                            {/* @ts-ignore */}
                                            <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
                                                {/* @ts-ignore */}
                                                <StyledTab label="Quality" />
                                                {/* @ts-ignore */}
                                                <StyledTab label="Speed" />
                                            </StyledTabs>
                                            <TabPanel value={value} index={0}>
                                                <Grid container direction="column-reverse">
                                                    {[1080, 720, 480, 360].map((quality) => (
                                                        <Typography
                                                            style={{
                                                                color: quality === props.quality ? palette.primary.main : "#8991a4",
                                                                fontSize: '12px',
                                                                fontFamily: 'Poppins-Light',
                                                                padding: '5px',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => props.onChangeQuality(quality)}
                                                        >
                                                            {
                                                                quality === props.quality ?
                                                                <CheckRoundedIcon 
                                                                    style={{
                                                                        fontSize: '14px', 
                                                                        color: palette.primary.main,
                                                                        marginRight: '10px'
                                                                    }}
                                                                />
                                                                :<FiberManualRecordRoundedIcon 
                                                                    style={{
                                                                        fontSize: '10px', 
                                                                        color: "#8991a4",
                                                                        marginRight: '10px'
                                                                    }}
                                                                />
                                                            } 
                                                            {quality} p
                                                        </Typography>
                                                    )).reverse()}
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={value} index={1}>
                                                <Grid container direction="column-reverse">
                                                    {[0.5, 1, 1.5, 2].map((rate) => (
                                                        <Typography
                                                            style={{
                                                                color: rate === props.playbackRate ? palette.primary.main : "#8991a4",
                                                                fontSize: '12px',
                                                                fontFamily: 'Poppins-Light',
                                                                padding: '5px',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => props.onPlaybackRateChange(rate)}
                                                        >
                                                            {
                                                                rate == 1 ?
                                                                'Normal'
                                                                : rate + 'X'
                                                            }
                                                        </Typography>
                                                        // </Button>
                                                    ))}
                                                </Grid>
                                            </TabPanel>
                                        </Box>   
                                    </div>
                                </Popover>
                                </>
                              : null
                            }
                          </>
                        }
                        {
                          props.ccIcon && !forSmall ? 
                            <IconButton className={classes.bottomIcons} style={{color: '#fff', opacity: 0.5}} disabled={true}>
                              {/* <img src={ccIcon} height={'60px'} /> */}
                              <ClosedCaptionRoundedIcon fontSize="large" />
                            </IconButton>
                          : null
                        }
                        {
                          props.pipIcon && !forSmall ? 
                            <IconButton 
                              className={classes.bottomIcons}
                              onClick={props.pip}
                              style={{
                                  color:'#fff'
                              }}
                            >
                              <PictureInPictureAltRoundedIcon fontSize="medium" />
                            </IconButton>
                          : null
                        }
                        <IconButton 
                            className={classes.bottomIcons}
                            onClick={props.onToggleFullScreen}
                        >
                            <img src={expandIcon} height={'60px'} />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
      </Grid>
    </div>
  );
});