import React from "react";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    IconButton,
    Toolbar,
    Button,
    AppBar,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Menu,
    MenuItem,
    Avatar,
    Divider,
    ListItemIcon,
    Modal,
    TextField,
    FormControl,
    Select
} from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu';
import CourseCategoriesController, { Props } from "./CourseCategoriesController";
import CustomInput from "../../../../components/src/CustomInput";
import ThreeDot from "../../../../components/src/ThreeDot";
import { palette } from "../../../../components/src/theme";
import CustomButton from "../../../../components/src/CustomButton";
import SortingHeader from "../../../../components/src/SortingHeader";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";
import { BlogType, CategoryType } from "../../../ContentManagement/src/Interfaces.web";
import AlertModal from "../../../../components/src/AlertModal";
import { EditIcon } from "../assets";
import AdminSearch from "../../../../components/src/AdminSearch";
import CircularProgress from '@material-ui/core/CircularProgress';

const drawerWidth = 240;
export const dasboardFullDateFormatter = (date: string) => {
    if (!date) return;
    return moment.utc(date, "YYYY-MM-DD HH:mm:ss").local().format('D-MMM-YYYY');
}

// Customizable Area End

class CourseCategories extends CourseCategoriesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { categoryName, searchList, dataActionMsg, selectedCategory, inputError, mobileOpen, apiData, loading, errorMsg, anchorEl, blogPerPage, modalOpen, currentPage, sortingLoader, sorting } = this.state;
        const open = Boolean(anchorEl);
        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                {/* @ts-ignore */}
                {dataActionMsg ? (
                    <AlertModal
                        open={dataActionMsg}
                        data={dataActionMsg ? dataActionMsg : errorMsg}
                        onClose={() => this.closeAlertBox()}
                    />
                ) : null}
                {/* @ts-ignore */}
                <Modal
                    open={modalOpen}
                    onClose={() => this.handleModalClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {/* @ts-ignore */}
                    <Box className={classes.modalStyle}>
                        <span
                            style={{
                                position: 'absolute',
                                right: '-10px',
                                top: '-10px',
                                background: '#F27024',
                                color: '#fff',
                                borderRadius: '50%',
                                fontFamily: 'Poppins-Light',
                                padding: '0px 7px 0px 7px',
                                cursor: 'pointer'
                            }}
                            onClick={() => this.handleModalClose()}
                        >
                            X
                        </span>
                        <TextField
                            fullWidth
                            name="Category"
                            id="Category"
                            value={categoryName}
                            onChange={(e) => this.handleInputChange(e)}
                            InputProps={{
                                className: classes.input,
                                disableUnderline: true
                            }}
                            style={{
                                fontSize: "4rem",
                                fontFamily: "Poppins-Light",
                                height: '47px'
                                // marginTop: "15px"
                            }}
                            placeholder="Category Name"
                            error={inputError.categoryName === undefined || inputError.categoryName === "" ? false : true}
                            helperText={inputError.categoryName}
                        />
                        <Button style={{
                            backgroundColor: "#F27024", color: "#ffffff",
                            marginBottom: "25px", textTransform: "initial",
                            fontFamily: "Poppins-Bold",
                            height: '45px',
                            marginLeft: '10px'
                        }}
                            onClick={() => this.updateCategory(selectedCategory)}
                            variant="outlined" >Update</Button>
                    </Box>
                </Modal>

                <DashboardDrawer
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={this.handleDrawerToggle}
                    // @ts-ignore
                    navigation={this.props.navigation}
                />
                {/* @ts-ignore */}
                <Box
                    className={classes.appBarWrap}
                >
                    <AppBar position='fixed' className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                aria-label="open drawer"
                                edge="start"
                                onClick={this.handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                            <div className={classes.logo}>
                                <img src={require('../../../../components/src/logo.svg')} height={70} />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'space-between',
                                    padding: '10px'
                                }}
                            >
                                <div>
                                    <Typography
                                        style={{
                                            fontFamily: 'Poppins-SemiBold',
                                            color: '#353535',
                                            textTransform: 'capitalize'
                                        }}
                                        className={classes.name}
                                    >
                                        Hi {localStorage.getItem('userName')},
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontFamily: 'Poppins-SemiBold',
                                            fontSize: '40px',
                                            color: '#353535'
                                        }}
                                        className={classes.welcome}
                                    >
                                        Welcome back
                                    </Typography>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'relative'
                                        }}
                                    >
                                    <CustomInput
                                        passwordfield={false}
                                        placeholder={"Search"}
                                        icon={require("../../assets/search.png")}
                                        iconHeight={"24px"}
                                        height={48}
                                        borderRadius={6}
                                        value={this.state.searchQuery}
                                        onChange={(e: any) =>
                                            this.handleSearchInputChange(e)
                                        }
                                    />
                                    {
                                        this.state.openSearch ?
                                            <div
                                            style={{
                                                position: 'absolute',
                                                zIndex: 999999,
                                                color: '#000',
                                                maxHeight: '200px',
                                                overflowY: 'scroll',
                                                boxShadow: '-1px -1px 19px -3px rgba(0,0,0,0.6)',
                                                borderRadius: '8px',
                                                minWidth: '250px',
                                                background: '#fff',
                                                padding: '10px',
                                                top: '80px'
                                            }}
                                            >
                                            {
                                                !this.state.searchLoading ?
                                                    searchList.blogs.length ||
                                                    searchList.videos.length ||
                                                    searchList.podcasts.length ||
                                                    searchList.courses.length ||
                                                    searchList.lets_discover.books ||
                                                    searchList.lets_discover.films ||
                                                    searchList.lets_discover.organisations ||
                                                    searchList.lets_discover.people?
                                                    <AdminSearch 
                                                        searchList={searchList}
                                                        navigation={this.props.navigation}
                                                    />
                                                    : 'No search found!'
                                                : <div
                                                    style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                    }}
                                                >
                                                    <CircularProgress
                                                    style={{
                                                        color: palette.primary.main
                                                    }}
                                                    />
                                                </div>
                                            }
                                            <div
                                                style={{
                                                borderTop: '1px solid #d3d3d3',
                                                marginTop: '10px'
                                                }}
                                            >
                                                <Typography
                                                style={{
                                                    color: palette.primary.main,
                                                    fontSize: '12px',
                                                    fontFamily: 'Poppins-SemiBold',
                                                    cursor: 'pointer',
                                                    textAlign: 'center',
                                                    marginTop: '10px'
                                                }}
                                                onClick={() => this.setState({openSearch: false})}
                                                >
                                                Cancel
                                                </Typography>
                                            </div>
                                            </div>
                                        : null
                                        }
                                    </div>
                                    <IconButton
                                        style={{
                                            border: '1px solid #bdbdbd',
                                            height: '45px',
                                            width: '45px',
                                            borderRadius: '5px',
                                            marginTop: '25px',
                                            marginLeft: '20px'
                                        }}
                                    >
                                        <img src={require('../../assets/notification.png')} height={'25px'} />
                                    </IconButton>
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {/* @ts-ignore */}
                    <Box style={{ padding: "0 5%", marginBottom: "10%" }}>
                        <Grid container>
                            <div style={{ display: 'flex', marginTop: "15px", width: "100%" }}>
                                {/* <Grid item xs={12} md={}> */}
                                    <TextField
                                        fullWidth
                                        name="Category"
                                        id="Category"
                                        value={categoryName}
                                        onChange={(e) => this.handleInputChange(e)}
                                        InputProps={{
                                            className: classes.input,
                                            disableUnderline: true
                                        }}
                                        style={{
                                            fontSize: "4rem",
                                            fontFamily: "Poppins-Light",
                                            marginBottom: "5px",
                                            height: '47px'
                                            // marginTop: "15px"
                                        }}
                                        placeholder="Category Name"
                                        error={inputError.categoryName === undefined || inputError.categoryName === "" ? false : true}
                                        helperText={inputError.categoryName}
                                    />
                                {/* </Grid> */}

                                {/* <div style={{ display: "flex", gap: "10px", marginBottom: "5px" }}>
                                    <Typography style={{ fontFamily: "Poppins-Light", marginRight: "3px" }}>Category Type</Typography>
                                    <FormControl style={{ minWidth: "190", width: "50px", border: '1px solid rgba(82,92, 229, 0.12)' }}>
                                        <Select
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={categoryType}
                                            className={classes.select}
                                            label="Sort"
                                            onChange={e => this.handleTypeChange(e)}
                                            disableUnderline={true}
                                            IconComponent={KeyboardArrowDownIcon}
                                        >
                                            <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "Poppins-Light" }} value="blog_category">Blog</MenuItem>
                                            <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "Poppins-Light" }} value="podcast_category">Podcast</MenuItem>
                                            <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "Poppins-Light" }} value="course_category">Course</MenuItem>
                                            <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "Poppins-Light" }} value="video_category">Video</MenuItem>
                                        </Select>
                                        <span className={classes.customArrow}></span>
                                    </FormControl>
                                </div> */}
                                <Button style={{
                                    height: '45px',
                                    marginLeft: '10px',
                                    backgroundColor: "#F27024", color: "#ffffff",
                                    marginBottom: "25px", textTransform: "initial",
                                    fontFamily: "Poppins-Bold"
                                }}
                                    onClick={() => this.fieldValidation()}
                                    variant="outlined" >Create</Button>
                            </div>
                            {
                                loading ? <Typography style={{ fontFamily: "Poppins-SemiBold", width: "100%", textAlign: "center", margin: "2rem 0" }}>Loading....</Typography> : errorMsg ? <Typography style={{ fontFamily: "Poppins-SemiBold", width: "100%", textAlign: "center", margin: "2rem 0" }}>{errorMsg}</Typography> : apiData?.length ? "" : <Typography style={{ fontFamily: "Poppins-SemiBold", width: "100%", textAlign: "center", margin: "2rem 0" }}>No data found</Typography>
                            }

                        </Grid>
                        {loading ? "" : sortingLoader ? "" : errorMsg ? "" :
                            apiData?.length ?
                                <TableContainer component={Paper}>
                                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Category Name</TableCell>
                                                <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Category Type</TableCell>
                                                <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Created Date</TableCell>
                                                <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {apiData?.map((row: CategoryType) => (
                                                <TableRow
                                                    key={row.id}
                                                    className={classes.lastTableRow}
                                                >
                                                    <TableCell style={{ fontFamily: "Poppins-SemiBold" }} align="left">{row?.attributes?.name}</TableCell>
                                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">{row?.attributes?.category_type}</TableCell>
                                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left"><Button>{dasboardFullDateFormatter(row?.attributes?.created_at)}</Button></TableCell>
                                                    <TableCell align="left">
                                                        <IconButton
                                                            onClick={() => this.handleModalOpen(row.id, row?.attributes?.name)}
                                                            style={{
                                                                height: '45px',
                                                                width: '45px',
                                                                borderRadius: '5px',
                                                                marginLeft: '25px'
                                                            }}
                                                        >
                                                            <img src={EditIcon} height={'25px'} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                ""
                        }


                    </Box>

                </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// export default withStyles(styles)(LetsRead);
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            logo: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            menuButton: {
                marginRight: theme.spacing(2),
                color: palette.primary.main,
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            appBar: {
                background: '#fff',
                boxShadow: 'none',
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                    background: '#fff',
                    boxShadow: 'none'
                },
            },
            appBarWrap: {
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                },
            },
            welcome: {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            name: {
                fontSize: '24px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '20px',
                    fontSize: '16px'
                },
            },
            input: {
                width: "100%",
                height: "100%",
                border: "1px solid rgba(82,92, 229, 0.12)",
                borderRadius: "7px",
                textDecoration: "none",
                fontSize: "1rem",
                fontFamily: "Poppins-Light",
                backgroundColor: "white",
                color: '#000 !important',
                padding: "0px 1rem",
                "& > .MuiInputBase-input": {
                    height: "100%",
                },
                "&  .MuiFormHelperText-root.Mui-error": {
                    fontFamily: "Poppins-Light",
                },
            },
            triangle: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.06))',
                marginTop: "49px",
                marginLeft: "12px",
                borderRadius: "10px",
                '& .MuiAvatar-root': {
                    width: '32px ',
                    height: '32px',
                    marginLeft: '-0.5px ',
                    marginRight: '1px',
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: '0px',
                    right: '20px ',
                    width: '15px',
                    height: '15px ',
                    backgroundColor: '#ffffff',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            },
            root: {
                color: "#F27024 !important",
                "& .Mui-selected": {
                    background: "#F27024",
                    color: "white",
                    fontWeight: "bold",
                },
                ul: {
                    "& .MuiPaginationItem-root": {
                        color: "#F27024"
                    }
                }
            },
            modalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '496px',
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '20px',
                display: "flex",
            },

            menuItemStyle: {
                fontFamily: 'Poppins-Light',
                '&:hover': {
                    textAlign: "right",
                    color: '#d53636',
                    backgroundColor: '#ffffff',
                }
            },
            select: {
                fontSize: "14px",
                fontFamily: "Poppins-SemiBold",
                backgroundColor: 'none',
                paddingLeft: "3px",
                "& .MuiSelect-icon": {
                    zIndex: 1,
                    right: ".7rem",
                    color: '#F27024',
                },
                "& .MuiSelect-select": {
                    color: '#F27024',
                    backgroundColor: '#fff !important',
                    paddingLeft: '0',
                },
            },
            lastTableRow: {
                '&:last-child td, &:last-child th': { border: 0 }
            },


        }))(CourseCategories)
)
// Customizable Area End
