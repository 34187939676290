import React from "react";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Toolbar,
  AppBar,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import TutorialController, { Props } from "./TutorialController";
import {
  withTheme,
  withStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import CustomLoader from "../../../../components/src/CustomLoader";
import ReactPlayer from 'react-player';
import screenfull from "screenfull";
import PlayerControlls from "../../../../components/src/PlayerControlls";
import { palette } from "../../../../components/src/theme";


let count = 0;

const format = (seconds: any) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

const drawerWidth = 240;

// Customizable Area End

class Help extends TutorialController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // @ts-ignore
    this.window = window;
    window.scrollTo(0, 0);
    // @ts-ignore
    this.playerRef = React.createRef();
    // @ts-ignore
    this.controlsRef = React.createRef();
    // @ts-ignore
    this.playerContainerRef = React.createRef();
    // @ts-ignore
    this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

    // @ts-ignore
    this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";

    // @ts-ignore
    this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
    // @ts-ignore
    this.totalDuration = format(this.duration);
    // Customizable Area End
  }
  // Customizable Area Start

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleProgress = (changeState: any) => {
    // @ts-ignore
    this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

    // @ts-ignore
    this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";

    // @ts-ignore
    this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
    // @ts-ignore
    this.totalDuration = format(this.duration);
    if (count > 3) {
      // @ts-ignore
      this.controlsRef.current.style.visibility = "hidden";
      count = 0;
    }
    // @ts-ignore
    if (this.controlsRef.current.style.visibility == "visible") {
      count += 1;
    }
    if (!this.state.seeking) {
      this.setState({ ...this.state, ...changeState });
    }
    console.log('====================================');
    console.log("TotalProgressValue, ", changeState);
    console.log('====================================');
  };

  handleOnEnded = () => {
    this.setState({ ...this.state, videoPlaying: false, played: 0 });
  }

  handlePip = () => {
    this.setState({ ...this.state, pip: !this.state.pip, hideBtn: false })
  }

  handleDisplayFormat = () => {
    this.setState({ timeDisplayFormat: this.state.timeDisplayFormat == " normal" ? 'remaining' : 'normal' })
  };

  handleVolumeSeekDown = (e: any, newValue: any) => {
    // @ts-ignore
    this.setState({ ...this.state, seeking: false, volume: parseFloat(newValue / 100) });
  };
  handleVolumeChange = (e: any, newValue: any) => {
    this.setState({
      ...this.state,
      // @ts-ignore
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  handlePlayPause = () => {
    this.setState({ ...this.state, videoPlaying: !this.state.videoPlaying });
  };

  handleRewind = () => {
    // @ts-ignore
    this.playerRef.current.seekTo(this.playerRef.current.getCurrentTime() - 10);
  };

  handleForward = () => {
    // @ts-ignore
    this.playerRef.current.seekTo(this.playerRef.current.getCurrentTime() + 10);
  };

  handleSeekChange = (e: any, newValue: any) => {
    // @ts-ignore
    this.setState({ ...this.state, played: parseFloat(newValue / 100) });
    console.log('====================================');
    console.log("SeekChangeValue, ", newValue);
    console.log('====================================');
  };

  handleSeekMouseDown = (e: any) => {
    this.setState({ ...this.state, seeking: true });
  };

  handleSeekMouseUp = (e: any, newValue: any) => {
    this.setState({ ...this.state, seeking: false });
    // @ts-ignore
    this.playerRef.current.seekTo(newValue / 100, "fraction");
    console.log('====================================');
    console.log("MouseUpValue, ", newValue);
    console.log('====================================');
  };

  handleDuration = (duration: any) => {
    this.setState({ ...this.state, allDuration: duration });
    console.log('====================================');
    console.log("DurationValue", duration);
    console.log('====================================');
  };

  toggleFullScreen = () => {
    this.setState({ hideBtn: !this.state.hideBtn })
    // @ts-ignore
    screenfull.toggle(this.playerContainerRef.current);
  };

  onEscape = () => {
    this.setState({ hideBtn: false })
  }

  handleMute = () => {
    this.setState({ ...this.state, muted: !this.state.muted });
  };

  handlePlaybackRate = (rate: any) => {
    this.setState({ ...this.state, playbackRate: rate });
  };

  onChangeBitrate = (quality: any) => {
    // @ts-ignore
    const internalPlayer = this.playerRef.current?.getInternalPlayer();
    if (internalPlayer) {
      // currentLevel expect to receive an index of the levels array
      internalPlayer.currentLevel = quality;
      this.setState({ ...this.state, quality: quality });
    }
  }

  getAppBar = (classes: any) => {
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logo}>
            <img
              src={require("../../../../components/src/logo.svg")}
              height={70}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              padding: "10px"
            }}
          >
            <div>
              <Typography
                style={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "25px",
                  color: "#353535",
                  marginTop: "25px"
                }}
                className={classes.welcome}
              >
                Help
              </Typography>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  getPlayer = () => {
    return (
      // @ts-ignore
      <Box
        component={'div'}
        style={{
          borderRadius: '10px',
          overflow: 'hidden',
          boxShadow: '0 0 7px 1px rgba(0, 0, 0, 0.22)',
          marginTop: '-30px',
          position: 'relative',
          // width: '100%',
          // height: '70vh',
          // height: '0',
          paddingTop: "56.25%",
          maxHeight: '80%',
          background: '#000'
        }}
        // @ts-ignore
        ref={this.playerContainerRef}
      >
        {/* @ts-ignore */}
        <ReactPlayer
          url={
            this.state.tutorialsDetails?.attributes?.video ? this.state.tutorialsDetails?.attributes?.video : "https://minio.b64156.dev.eastus.az.svc.builder.cafe/sbucket/w6btseztgy5xm5utuswb626tlq6e"}
          playing={this.state.videoPlaying}
          width={'100%'}
          height={'100%'}
          style={{
            // minWidth: '100%',
            // minHeight: '100%',
            position: "absolute",
            borderRadius: '10px',
            top: 0,
            left: 0,
          }}
          // @ts-ignore
          ref={this.playerRef}
          onProgress={this.handleProgress}
          controls={false}
          onEnded={this.handleOnEnded}
          light={this.state.light}
          loop={this.state.loop}
          playbackRate={this.state.playbackRate}
          volume={this.state.volume}
          muted={this.state.muted}
          pip={this.state.pip}
        />
        <PlayerControlls
          // @ts-ignore
          ref={this.controlsRef}
          playPause={this.handlePlayPause}
          state={this.state}
          rewind={this.handleRewind}
          forward={this.handleForward}
          onSeek={this.handleSeekChange}
          onSeekMouseDown={this.handleSeekMouseDown}
          onSeekMouseUp={this.handleSeekMouseUp}
          onDuration={this.handleDuration}
          played={this.state.played}
          onToggleFullScreen={this.toggleFullScreen}
          // @ts-ignore
          elapsedTime={this.elapsedTime}
          hideBtn={this.state.hideBtn}
          // @ts-ignore
          totalDuration={this.totalDuration}
          onMute={this.handleMute}
          muted={this.state.muted}
          volume={this.state.volume}
          pip={this.handlePip}
          onVolumeChange={this.handleVolumeChange}
          onVolumeSeekDown={this.handleVolumeSeekDown}
          onChangeDisplayFormat={this.handleDisplayFormat}
          onPlaybackRateChange={this.handlePlaybackRate}
          playbackRate={this.state.playbackRate}
          quality={this.state.quality}
          onChangeQuality={this.onChangeBitrate}
          onEscape={this.onEscape}
          pipIcon={true}
          forwardSeekIcon={true}
          backwardSeekIcon={true}
          volumeIcon={true}
          settingsIcon={true}
          ccIcon={true}
          timeDuration={true}
        />
      </Box>
    )
  }

  getTutorialsDetails = (classes: any) => {
    return (
      <>
        <div
          className={classes.parent}
        >
          <Container>
            <div
              className={classes.playerWrap}
            >
              {this.getPlayer()}
            </div>
          </Container>
          <Container>
            <div
              className={classes.titleWrap}
            >
              <Typography
                className={classes.title}
              >
                {this.state.tutorialsDetails?.attributes?.title}
              </Typography>
            </div>
            <div
              className={classes.descWrap}
            >
              <Typography
                className={classes.description}
              >
                {this.state.tutorialsDetails?.attributes?.description}
              </Typography>
            </div>
          </Container>
        </div>
      </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { mobileOpen } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div>
        {this.state.loading ? <CustomLoader open={this.state.loading} /> : null}

        <DashboardDrawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          // @ts-ignore
          navigation={this.props.navigation}
        />
        {/* @ts-ignore */}
        <Box className={classes.appBarWrap}>
          {this.getAppBar(classes)}
          {/* @ts-ignore */}
          <div>
            {
              this.getTutorialsDetails(classes)
            }
          </div>
        </Box>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// export default withStyles(styles)(LetsRead);
export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      input: {
        width: "100%",
        height: "100%",
        border: "1px solid rgba(82,92, 229, 0.12)",
        borderRadius: "7px",
        textDecoration: "none",
        fontSize: "1em",
        fontFamily: "Poppins-Light",
        backgroundColor: "white",
        color: "#000 !important",
        padding: "5px 1rem", //
        "& > .MuiInputBase-input": {
          height: "100%"
        },
        "&  .MuiFormHelperText-root.Mui-error": {
          fontFamily: "Poppins-Light"
        }
      },
      logo: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
          display: "none"
        }
      },
      menuButton: {
        marginRight: theme.spacing(2),
        color: palette.primary.main,
        [theme.breakpoints.up("md")]: {
          display: "none"
        }
      },
      appBar: {
        background: "#fff",
        boxShadow: "none",
        [theme.breakpoints.up("md")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
          background: "#fff",
          boxShadow: "none"
        }
      },
      appBarWrap: {
        [theme.breakpoints.up("md")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth
        }
      },
      welcome: {
        [theme.breakpoints.down("sm")]: {
          display: "none"
        }
      },
      name: {
        fontSize: "24px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "20px",
          fontSize: "16px"
        }
      },
      playerWrap: {
        marginTop: '30px',
      },
      titleWrap: {
        paddingTop: '20px',
        paddingBottom: '20px',
        borderBottom: '1px solid #e6e8f0',
        [theme.breakpoints.down("sm")]: {
          paddingTop: '10px',
          paddingBottom: '10px',
        }
      },
      title: {
        color: '#353535',
        fontSize: '24px',
        fontFamily: 'Poppins-SemiBold'
      },
      descWrap: {
        marginTop: '20px',
        [theme.breakpoints.down("sm")]: {
          marginTop: '10px',
        }
      },
      description: {
        color: '#545454',
        fontSize: '16px',
        fontFamily: 'Poppins-Light'
      },
      parent: {
        padding: '100px',
        [theme.breakpoints.down("sm")]: {
          padding: '10px',
        }
      }
    })
  )(Help)
);
// Customizable Area End
