import React from "react";

// Customizable Area Start
import { 
  Container, 
  Box, 
  Typography, 
  Grid,
  CircularProgress,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { palette } from "../../../components/src/theme";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FavouriteIcon from "../../../components/src/FavouriteIcon";
import AlertModal from "../../../components/src/AlertModal";
import CustomLoader from "../../../components/src/CustomLoader";
import CustomHoverBtn from "../../../components/src/CustomHoverBtn";

interface FilterContentProps {
  cat_loading: boolean;
  coursesFilterOptionsData: any[];
  catId: string;
  getFilteredPodcast: (id: any) => void;
}
interface ContentProps {
  courses_loading: boolean;
  allCoursesData: any;
  makeFavourite: (id: any, status: any) => void;
  gotoCourseDetails: (id: any) => void
  navigation: any; // Adjust the type according to your needs
}
// Customizable Area End

import ProjectNotesController, {
  Props,
  configJSON
} from "./ProjectNotesController";
import BreadCrumb from "../../../components/src/BreadCrumb";
import { readMore } from "../../../framework/src/Utilities";
import CustomPagination from "../../../components/src/CustomPagination";


class ProjectNotes extends ProjectNotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const path: any = window.location.href.split('/', 5).pop()
    this.setState({enrolled: path, enrolledAPI: path == "MyCourses" ? true : false})
    super.componentDidMount();
    this.getPodcastFilterList();
    this.getAllCourses("", 1, path == "MyCourses" ? true : false)
    window.scrollTo({ top: 0, behavior: 'smooth' })

  }

  async componentWillUnmount(): Promise<void> {
    localStorage.removeItem('category_id')
  }

  async componentDidUpdate() {
    const path: any = window.location.href.split('/', 5).pop()
    // this.getAllCourses("",1,path == 'user' ? true : false)
    
  }

  closeAlertBox = () => {
    this.setState({
        openAlert: false
    })
}

renderFilteCourseContent({ cat_loading, coursesFilterOptionsData, catId, getFilteredPodcast }: FilterContentProps) {
  if (cat_loading) {
    return (
      <Typography style={{ fontFamily: palette.fontFamily.main, color: palette.primary.main, fontSize: '18px', fontWeight: 'bold', textAlign: 'center', margin: 'auto' }}>
        Loading...
      </Typography>
    );
  } else if (coursesFilterOptionsData.length) {
    // istanbul ignore next
    return coursesFilterOptionsData.map((item: any, index: any) => (
      <Grid key={item.id} item md={2} sm={4} xs={6}>
        <CustomHoverBtn
          selected={catId === item.attributes.id}
          handleClick={() => getFilteredPodcast(item.attributes.id)}
          color={palette.primary.main}
          br="none"
          fullWidth
          title={
            item.attributes.name && item.attributes.name.length > 11
              ? item.attributes.name.substring(0, 10) + '...'
              : item.attributes.name
          }
        />
      </Grid>
    ));
  } else {
    return (
      <Typography style={{ fontFamily: palette.fontFamily.main, color: palette.primary.main, fontSize: '18px', fontWeight: 'bold', textAlign: 'center', margin: 'auto' }}>
        No Record Found
      </Typography>
    );
  }
}


renderCourseContent({courses_loading, allCoursesData, navigation, makeFavourite, gotoCourseDetails}: ContentProps) {
  if (courses_loading) {
    return (
      <Grid style={{ display: 'flex', justifyContent: 'center' }} container spacing={3}>
        <Grid item lg={1}>
          <CircularProgress style={{ color: palette.primary.main }} />
        </Grid>
      </Grid>
    );
  } else if (allCoursesData.length) {
    // istanbul ignore next
    return allCoursesData.map((item: any, index: number) => {
      const halfCompletionStatus = item?.attributes?.completion_status === 'completed'
      ? 'Completed'
      : null;
      const completionStatus =
        item?.attributes?.completion_status === 'in_progress'
          ? 'In progress'
          : halfCompletionStatus;
      const showCompletionStatus =
        item?.attributes?.completion_status &&
        window.location.href.split('/', 5).pop() === 'MyCourses' &&
        completionStatus !== null;

      const showBestSeller =
        item?.attributes?.best_seller && window.location.href.split('/', 5).pop() !== 'MyCourses';

      return (
        <Grid item xs={12} sm={6} md={4} key={item.id}>
          <Card>
            <FavouriteIcon
              isFavourite={item.attributes.favourite}
              position="absolute"
              right="10px"
              top="10px"
              makeFavourite={(status: any) => makeFavourite(item.id, status)}
              navigation={navigation}
            />
            <CardActionArea>
              <Box style={{ cursor: 'pointer', position: 'relative' }}>
                {showCompletionStatus && (
                  <Typography
                    style={{
                      position: 'absolute',
                      color: '#fff',
                      backgroundColor: palette.primary.main,
                      borderRadius: '3px',
                      padding: '5px 10px',
                      fontFamily: "Poppins-Light",
                      margin: '15px',
                    }}
                  >
                    {completionStatus}
                  </Typography>
                )}
                {showBestSeller && (
                  <Typography
                    style={{
                      position: 'absolute',
                      color: '#fff',
                      backgroundColor: palette.primary.main,
                      borderRadius: '3px',
                      padding: '5px 10px',
                      fontFamily: "Poppins-Light",
                      margin: '15px',
                    }}
                  >
                    Best Sellers
                  </Typography>
                )}
                <img
                  onClick={() => gotoCourseDetails(item.id)}
                  width="100%"
                  src={item?.attributes?.preview_image || require('../../../blocks/Gallery/assets/audioBg.jpg')}
                  style={{ minHeight: '140px', height: '200px', minWidth: '50px' }}
                />
              </Box>
            </CardActionArea>
            <CardContent style={{ height: '100px' }}>
              <Typography style={{ fontFamily: 'Poppins-Light', fontSize: '16px', fontWeight: 600 }} gutterBottom variant="h5" component="h2">
                {item.attributes.title}
              </Typography>
              <Typography style={{ fontFamily: 'Poppins-Light', fontSize: '12px' }} variant="body2" color="textSecondary" component="p">
                {readMore(item.attributes.about, 25)}
                <Typography
                  style={{
                    fontFamily: 'Poppins-Light',
                    fontSize: '12px',
                    color: palette.primary.main,
                    cursor: 'pointer',
                  }}
                  onClick={() => gotoCourseDetails(item.id)}
                >
                  Read more
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    });
  } else {
    return (
      <Typography
        style={{
          fontFamily: 'Poppins-Light',
          color: palette.primary.main,
          fontSize: '18px',
          fontWeight: 'bold',
          textAlign: 'center',
          margin: 'auto',
        }}
      >
        No Record Found
      </Typography>
    );
  }
}
  
  // Customizable Area End

  render() {

    // Customizable Area Start
    
    const { classes } = this.props;
    const {coursesFilterOptionsData,sort, enrolled, catId, totalPages,allCoursesData,cat_loading, courses_loading} = this.state

    // Customizable Area End

    return (
      // Customizable Area Start
          <Container >
            {
              this.state.openAlert ?
                  <AlertModal 
                      open={this.state.openAlert}
                      data={this.state.updateMessage}
                      onClose={() => this.closeAlertBox()}
                  />
              :   null
            }
            {
              this.state.loading ?
                <CustomLoader 
                    open={this.state.loading}
                />
              : null
            }
            <Grid container style={{display: 'flex', justifyContent: 'space-between'}}>
              <Grid item md={9} sm={8} xs={12}>
                <BreadCrumb 
                  base={'Home'}
                  url1={{
                    base: `Let's Learn`,
                    link: enrolled == "AllCourses" ? 'All Courses' : 'My Courses'
                  }}
                  url2={{
                    path: '',
                    link: ''
                  }}
                />
              </Grid>
              <Grid item md={3} sm={4} xs={12} style={{borderBottom: '2px inset #e6e6e6'}}>
                <Box className={classes.customSelectWrapper}>
                  <Typography style={{ fontFamily: palette.fontFamily.main ,width: "100%", color: 'grey', fontSize: "14px" }}>Sort By :&nbsp;</Typography>
                  <FormControl style={{ minWidth: "190", width: "100%" }}>
                      <Select
                          MenuProps={{
                              anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                              },
                              getContentAnchorEl: null
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={sort}
                          className={classes.select}
                          label="Sort"
                          onChange={this.handleSortChange}
                          disableUnderline={true}
                          IconComponent={KeyboardArrowDownIcon}
                      >
                          <MenuItem className={classes.menuItemStyle} style={{ fontFamily: palette.fontFamily.main }} value={"oldest_first"}>Oldest</MenuItem>
                          <MenuItem className={classes.menuItemStyle} style={{ fontFamily: palette.fontFamily.main }} value={"newest_first"}>Recent</MenuItem>
                          <MenuItem className={classes.menuItemStyle} style={{ fontFamily: palette.fontFamily.main }} value={"popularity"}>Popularity</MenuItem>
                      </Select>
                      <span className={classes.customArrow}></span>
                  </FormControl>
                  </Box>
              </Grid>
            </Grid>

           {/* @ts-ignore */}
           <Box mt={5}>
            <Grid container spacing={2}>
            <Grid item md={2} sm={4} xs={6}>
              <CustomHoverBtn
                selected={!catId}
                handleClick={() => this.clickAllCourses()}
                color={palette.primary.main}
                br="none"
                fullWidth
                title="All Courses"
              />
            </Grid>
              {
          //        cat_loading ?
          //        <Typography style={{ fontFamily: palette.fontFamily.main, color: palette.primary.main , fontSize: '18px', fontWeight: 'bold',
          //       textAlign: 'center', margin: 'auto'
          //         }}>Loading...</Typography> :
          //        coursesFilterOptionsData.length ? coursesFilterOptionsData.map((item: any, index: any) => {
          //         return (
          //           <Grid item md={2} sm={4} xs={6}>
          //             <CustomHoverBtn
          //               selected={catId === item.attributes.id}
          //               handleClick={() => this.getFilteredPodcast(item.attributes.id)}
          //               color={palette.primary.main}
          //               br="none"
          //               fullWidth
          //               title={
          //                 item.attributes.name && item.attributes.name.length > 11 ?
          //                   item.attributes.name.substring(0, 10) + '...'
          //                   : item.attributes.name}
          //             />
          //           </Grid>
          //         )
          //       })
          //       : <Typography style={{fontFamily: palette.fontFamily.main, color: palette.primary.main , fontSize: '18px', fontWeight: 'bold',
          //       textAlign: 'center', margin: 'auto'
          // }}>No Record Found</Typography>
          this.renderFilteCourseContent({
            cat_loading: cat_loading, 
            coursesFilterOptionsData: coursesFilterOptionsData, 
            catId: catId, 
            getFilteredPodcast: this.getFilteredPodcast
          })
              }
            </Grid>
          </Box>
          {/* @ts-ignore */}
          <Box mt={5} mb={5}>
                <Grid container spacing={3}>   
          {
          //   courses_loading ?
          //   <Grid style={{ display: 'flex', justifyContent: 'center' }} container spacing={3}> 
          //   <Grid lg={1} >
          //   <CircularProgress 
          //   style={{ color: palette.primary.main}}
          //   />
          // </Grid>
          // </Grid> : allCoursesData.length ? allCoursesData.map((item:any, index: any) => {
          //     return (
            
          //       <Grid item xs={12} sm={6} md={4}>
          //       <Card>
          //       <FavouriteIcon 
          //           isFavourite={item.attributes.favourite}
          //           position={'absolute'}
          //           right={'10px'}
          //           top={'10px'}
          //           makeFavourite={(status: any) => this.makeFavourite(item.id, status)}
          //           navigation={this.props.navigation}
          //       />
          //       <CardActionArea>
          //        <Box
          //           style={{
          //               cursor: 'pointer',
          //               position: 'relative'
          //           }}
          //           >
          //             {
          //               item?.attributes?.completion_status && window.location.href.split('/', 5).pop() == 'MyCourses' ? 
          //               item?.attributes?.completion_status == 'in_progress'
          //               ? <Typography 
          //                   style={{
          //                     position: 'absolute',
          //                     color: '#fff',
          //                     backgroundColor: palette.primary.main,
          //                     borderRadius: '3px',
          //                     padding: '5px 10px',
          //                     fontFamily: palette.fontFamily.main,
          //                     margin: '15px'
          //                   }}
          //                 > 
          //                   In progress
          //                 </Typography>
          //               : item?.attributes?.completion_status == 'completed'
          //               ? 
          //                 <Typography 
          //                   style={{
          //                     position: 'absolute',
          //                     color: '#fff',
          //                     backgroundColor: palette.primary.main,
          //                     borderRadius: '3px',
          //                     padding: '5px 10px',
          //                     fontFamily: palette.fontFamily.main,
          //                     margin: '15px'
          //                   }}
          //                 > 
          //                   Completed
          //                 </Typography>
          //               : null
          //               : null
          //             }
          //           {item?.attributes?.best_seller && window.location.href.split('/', 5).pop() !== 'MyCourses' ? <Typography 
          //             style={{
          //               position: 'absolute',
          //               color: '#fff',
          //               backgroundColor: palette.primary.main,
          //               borderRadius: '3px',
          //               padding: '5px 10px',
          //               fontFamily: palette.fontFamily.main,
          //               margin: '15px'
          //             }}> Best Sellers </Typography> : null }
          //               <img 
          //               onClick={() => this.props.navigation.navigate("CourseDetails", {id: item.id})}
          //                   width={'100%'} 
          //                   src={item?.attributes?.preview_image ? item?.attributes?.preview_image : require('../../../blocks/Gallery/assets/audioBg.jpg')} 
          //                   style={{minHeight: '140px', height: '200px', minWidth: '50px'}} 
          //               />
          //               {/* @ts-ignore */}
          //               {/* <Box 
          //                   style={{
          //                       position: 'absolute',
          //                       top: '50%',
          //                       left: '50%',
          //                       transform: 'translate(-50%, -50%)'
          //                   }}
          //               >
          //                   <Box
          //                       style={{
          //                           height: '40px',
          //                           width: '40px',
          //                           borderRadius: '50%',
          //                           background: '#ffffff',
          //                           display: 'flex',
          //                           justifyContent: 'center',
          //                           alignItems: 'center'
          //                       }}
          //                   >
          //                       <PlayArrowRoundedIcon 
          //                           style={{
          //                               color: palette.primary.main,
          //                               fontSize: 25,
          //                           }}
          //                            onClick={() => this.props.navigation.navigate("CourseDetails", {id: item.id})}
          //                           // fontSize="large"
          //                       />
          //                   </Box>
          //               </Box> */}
          //           </Box>
          //           </CardActionArea>
          //         <CardContent style={{height: '100px'}}>
          //           <Typography style={{fontFamily: palette.fontFamily.main, fontSize: '16px' , fontWeight: 600}} gutterBottom variant="h5" component="h2">
          //             {item.attributes.title}
          //           </Typography>
          //           <Typography style={{fontFamily: palette.fontFamily.main, fontSize: '12px'}} variant="body2" color="textSecondary" component="p">
          //             {readMore(item.attributes.about, 25)}
                       
          //             <Typography  
          //               style={{
          //                 fontFamily: palette.fontFamily.main, 
          //                 fontSize: '12px' ,
          //                 color: palette.primary.main,
          //                 cursor: 'pointer'
          //               }}
          //               onClick={() => this.props.navigation.navigate("CourseDetails", {id: item.id})}
          //             >
          //                 Read more
          //             </Typography>
                      
          //           </Typography>
                 
          //         </CardContent>
                
          //     </Card>
          //       </Grid>
           
          //     )
          //   })
          //   : <Typography style={{fontFamily: palette.fontFamily.main, color: palette.primary.main , fontSize: '18px', fontWeight: 'bold',
          //       textAlign: 'center', margin: 'auto'
          // }}>No Record Found</Typography>
          this.renderCourseContent({
            courses_loading: courses_loading, 
            allCoursesData: allCoursesData, 
            navigation: this.props.navigation, 
            makeFavourite: this.makeFavourite, 
            gotoCourseDetails: this.gotoCourseDetails
          })
          }
             </Grid>
              </Box>
              {
              totalPages.total_pages == 1 || !totalPages.total_pages ?
              null
              : 
                // @ts-ignore
                <Box
                  mt={10}
                  mb={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CustomPagination 
                    onChange={this.changePage}
                    totalPage={this.state.totalPages.total_pages}
                  />
                </Box>
            }
          </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTheme(
  withStyles((theme: Theme) =>
      createStyles({
        customSelectWrapper: {
          // position: "absolute",
          top: '120px',
          // right: '80px',
          fontFamily: palette.fontFamily.main,
          boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          backgroundColor: "#fff",
          paddingLeft: '8px',
          borderRadius: "5px 2px 2px 5px",
          display: "flex",
          justifyContent: 'flex-end',
          alignItems: "center",
          [theme.breakpoints.down('sm')]: {
              padding: '2px 5px',
              // width: '88%',
              // right: '20px',
              // left: '20px',
          },
      },
      customArrow: {
        position: "absolute",
        top: 0,
        right: 0,
        display: "block",
        backgroundColor: "#f4f5f7",
        borderLeft: "1px solid rgba(27, 31, 35, 0.15)",
        // borderRadius: "0 6px 6px 0",
        height: '100%',
        width: "3rem",
        pointerEvents: 'none',
    },
    select: {
      fontSize: "14px",
      fontFamily: "Poppins-SemiBold",
      "& .MuiSelect-icon": {
          zIndex: 1,
          right: ".7rem"
      },
      "& .MuiSelect-select": {
          backgroundColor: "white"
      },
  },
  menuItemStyle: {
    textAlign: "right"
  },
      })
      )(ProjectNotes)
  )
// Customizable Area End
