import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loading: boolean;
  courseList: any;
  blogList: any;
  podcastList: any;
  bookList: any;
  orgList: any;
  personList: any;
  filmsList: any;
  videosList: any;
  openAlert: boolean;
  updateMessage: any;
  isFavourite: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommentsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getFavouriteListId: string = '';
  apiMakeFavouriteId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loading: false,
      courseList: '',
      blogList: '',
      podcastList: '',
      bookList: '',
      orgList: '',
      personList: '',
      filmsList: '',
      videosList: '',
      openAlert: false,
      updateMessage: '',
      isFavourite: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.getFavouriteListId) {
          this.setState({
            loading: false,
            courseList: responseJson.data.courses,
            blogList: responseJson.data.blogs,
            podcastList: responseJson.data.podcasts,
            videosList: responseJson.data.videos,
            bookList: responseJson.data.lets_discover.books,
            filmsList: responseJson.data.lets_discover.films,
            personList: responseJson.data.lets_discover.people,
            orgList: responseJson.data.lets_discover.organisations
          }, () => {
            console.log("RESPONSE JSON", responseJson)
          })
        }
        if(this.apiMakeFavouriteId === apiRequestCallId){
          this.setFav(responseJson)
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start

  setFav = (responseJson: any) => {
    if(responseJson.message === "Favourite Updated!"){
      this.setState({
        loading: false,
        updateMessage: this.state.isFavourite ? 'Added to your favourites!' : 'Removed from your favourites!',
        openAlert: true
      }, () => {
        this.getFavouriteList()
      })
    } else {
      this.setState({
        loading: false,
        updateMessage: 'Something went wrong!',
        openAlert: true
      })
    }
  }

  makeFavourite = (id: any, status: any, type: any) => {
    this.updateFavourite(id, status, type)
    this.setState({
      isFavourite: status
    })
  }

  updateFavourite = (id: any, status: any, type: any) => {
    this.setState({
      loading: true
    })
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken
    };

    const data = {
      type: type,
      id: id,
      favourite: status
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMakeFavouriteId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateFavouriteApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getFavouriteList();
  }

  getPodcast = (id: any, type: any) => {
    if(type === 'video_podcast'){
      this.props.navigation.navigate("VideoManagement", {id:id})
    } else {
      this.props.navigation.navigate("AudioMusic", {id:id})
    }
  }


  getFavouriteList = () => {
    this.setState({ loading: true })
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFavouriteListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favListAPIEndPOint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
  
}
