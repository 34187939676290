import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { BlogsResponse, BlogType, CategoryType, SingleBlogResponse } from "./Interfaces.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  singleBlog: SingleBlogResponse;
  sigleBlogLoading: boolean;
  reviewLoading: boolean;
  articleId: string;
  reviewStatus: boolean;
  bold: boolean;
  underlined: boolean;
  italic: boolean;
  alignment: string;
  loading: boolean;
  popUpAlertModified: boolean;
  selectFavIconModified: boolean;
  selectFavMessageModified: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BlogArticleController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiGetSingleBlogCallId: string = "";
  apiPostReviewId: string = "";
  apiFavouriteSelectionCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // getName(MessageEnum.NavigationToSingleArticle),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      sigleBlogLoading: false,
      reviewLoading: false,
      articleId: "none",
      singleBlog: {
        blog: {
         id: "",
         type: "",
         attributes: {
             id: 0,
             title: '',
             subtitle: "",
             instructor_name: '',
             cover_image: '',
             reviews: [],
             body: "",
             instructor_id: 0,
             category: {
              id: -1,
              name: '',
              created_at: '',
              updated_at: ''
             },
             tag_others: '',
             created_at: "",
             components: [],
             favourite: false
         }
        },
        related_articles: []
       },
       reviewStatus: false,
       bold: false,
       underlined: false,
       italic: false,
       alignment: 'left',
       loading: false,
       selectFavMessageModified: "",
       popUpAlertModified: false,
       selectFavIconModified: false,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const id = message.getData(
        getName(MessageEnum.NavigationToSingleArticleId)
      );

      this.setState({
        articleId: id
      });
      this.getSingleBlogData('latest_first')
      
    } else {
      if (this.state.articleId === "none") {
        this.props.navigation.navigate("Blog")
      }
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      
      const apiRequestCallIds = {
        [this.apiGetSingleBlogCallId]: this.responseGetSingleBlogData,
        [this.apiPostReviewId]: this.addReviewToState,
        [this.apiFavouriteSelectionCallId]: this.selectFavouriteResponseModified,
      };

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
        apiRequestCallIds[apiRequestCallId](responseJson);
      }
    }
    // Customizable Area End

  }
// Customizable Area Start
  async componentDidMount() {
    const path: any = window.location.href.split('/', 5).pop()
    // window.scrollTo({ top: 0, behavior: 'smooth' })
    if (path) {
      this.setState({ articleId: path })
      if(this.state.articleId){
        this.getSingleBlogData('latest_first');
        this.getReviewData('latest_first');
      }
    }
    

  }

  async componentwillUnmount() {
    localStorage.removeItem("articleId");
  }

// Customizable Area End

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start
  responseGetSingleBlogData = (responseJson: any) => {
    if(responseJson && !responseJson.error){
      this.setState({
        sigleBlogLoading: false,
        reviewLoading: false,
        singleBlog: responseJson?.data,
        bold: responseJson.data.blog.attributes.components.length ? responseJson.data.blog.attributes.components[0].attributes.heading.style.bold : false,
        italic: responseJson.data.blog.attributes.components.length ? responseJson.data.blog.attributes.components[0].attributes.heading.style.italic : false,
        underlined: responseJson.data.blog.attributes.components.length ? responseJson.data.blog.attributes.components[0].attributes.heading.style.underlined: false,
        alignment: responseJson.data.blog.attributes.components.length ? responseJson.data.blog.attributes.components[0].attributes.heading.style.alignment: false
      })
    }
  }

  addReviewToState = (responseJson: any) => {
    this.setState((prevState) => {
      const updatedReviews = [...prevState.singleBlog.blog.attributes.reviews, { ...responseJson.data }];
  
      return {
        singleBlog: {
          ...prevState.singleBlog,
          blog: {
            ...prevState.singleBlog.blog,
            attributes: {
              ...prevState.singleBlog.blog.attributes,
              reviews: updatedReviews
            }
          }
        },
        reviewStatus: true
      };
    });
  
    setTimeout(() => {
      this.setState({ reviewStatus: false });
    }, 5000);
  };
  
  getSingleBlogData = (sort: string) => {
    const path: any = window.location.href.split('/', 5).pop()
    this.setState({ sigleBlogLoading: true, articleId: path })
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const userToken = localStorage.getItem("token") ? localStorage.getItem("token") : '';
    let header: any = '';
    if(userToken){
      header = {
        "Content-Type": configJSON.contentType,
        token: userToken,
      };
    } else {
      header = {
        "Content-Type": configJSON.contentType,
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSingleBlogCallId = requestMessage.messageId;
    const hitURL = `${configJSON.getAllBlogsApiUrl}/${path + '?sort=' + sort}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleReviewSubmit =(values: any) =>{
    const header = {
      token: localStorage.getItem("token")
    };
    
    const formData = new FormData();
           formData.append("review[blog_id]", this.state.articleId);
           formData.append("review[text]",values.description );
           formData.append("review[full_name]",values.fullName );
           formData.append("review[email]",values.workEmail );
           formData.append("review[type]", "blog" );

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostReviewId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postReviewUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReviewData = (sort: string) => {
    this.setState({ reviewLoading: true })
    const path: any = window.location.href.split('/', 5).pop()
    const userToken = localStorage.getItem("token") ? localStorage.getItem("token") : '';
    let header: any = '';
    if(userToken){
      header = {
        "Content-Type": configJSON.contentType,
        token: userToken,
      };
    } else {
      header = {
        "Content-Type": configJSON.contentType,
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSingleBlogCallId = requestMessage.messageId;
    const hitURL = `${configJSON.getAllBlogsApiUrl}/${path}?sort=${sort}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToSingleArticle = (id: string) => {
    this.setState({
      articleId: id
    }, () => {
      this.getSingleBlogData('latest_first');
    })
    
    this.getReviewData('latest_first');
    this.props.navigation.navigate('BlogArticle', {id:id})
  }

  requestFavouriteSelection = (itemId: any, selectionStatus: any) => {
    const requestData = {
      type: 'BxBlockPosts::Blog',
      id: itemId,
      favourite: selectionStatus
    }

    const authToken = window.localStorage.getItem("token");
    const requestHeader = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    const apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFavouriteSelectionCallId = apiRequestMessage.messageId;
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateFavouriteApi
    );

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeader)
    );

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestData)
    );

    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
}

selectFavouriteResponseModified = (responseJson: any) => {
  if (responseJson.message === "Favourite Updated!") {
    this.setState({
      loading: false,
      selectFavMessageModified: this.state.selectFavIconModified ? 'Added to your favourites!' : 'Removed from your favourites!',
      popUpAlertModified: true
    }, () => this.getSingleBlogData('latest_first'))
  } else {
    this.setState({
      loading: false,
      selectFavMessageModified: 'Something went wrong!',
      popUpAlertModified: true
    }, () => this.getSingleBlogData('latest_first'))
  }
}

toggleFavIconModified = (idModified: any, statusModified: any) => {
  this.requestFavouriteSelection(idModified, statusModified)
  this.setState({
    selectFavIconModified: statusModified
  })
}

cancelAlertModified = () => {
  this.setState({
    popUpAlertModified: false,
  })
}


  // Customizable Area End

}
