import { Box, Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import React, { FunctionComponent, ReactNode, useState } from "react";
import { blogImg1 } from "../../blocks/ContentManagement/src/assets";
import { getAge } from "../../blocks/ContentManagement/src/BlogPage.web";
import { BlogType } from "../../blocks/ContentManagement/src/Interfaces.web";
import { VideoType } from "../../blocks/VideoManagement/src/Interfaces.web";
import PlayerControlls from "./PlayerControlls";
import ReactPlayer from 'react-player';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import screenfull from "screenfull";
export interface PlayerProps {
    item: VideoType;
    currentPlayingId: any;
    changeCurrentPlayingId: (id: any, cb?:any) => void;
}

const format = (seconds: any) => {
    if (isNaN(seconds)) {
        return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
        return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
};

interface PlayerState {
    videoPlaying: boolean;
    played: any;
    seeking: boolean;
    allDuration: any;
    light: boolean;
    muted: boolean;
    pip: boolean;
    playbackRate: any;
    volume: any;
    loop: boolean;
    timeDisplayFormat: any;
    videoDetails: any;
    loading: boolean;
    openShare: boolean;
    quality: any;
    hideBtn: boolean;
}



class CarousalPlayer extends React.Component<PlayerProps, PlayerState> {

    constructor(props: PlayerProps) {
        super(props);

        const {item, currentPlayingId, changeCurrentPlayingId} = this.props
        
        this.state = {
            videoPlaying: false,
            played: 0,
            seeking: false,
            allDuration: 0,
            light: false,
            muted: false,
            pip: false,
            playbackRate: 1,
            volume: 1,
            loop: false,
            timeDisplayFormat: 'normal',
            videoDetails: {},
            loading: false,
            openShare: false,
            quality: 480,
            hideBtn: false
        }

        // @ts-ignore
        this.window = window;
        // @ts-ignore
        this.playerRef = React.createRef();
        // @ts-ignore
        this.controlsRef = React.createRef();
        // @ts-ignore
        this.playerContainerRef = React.createRef();
        // @ts-ignore
        this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

        // @ts-ignore
        this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";

        // @ts-ignore
        this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
        // @ts-ignore
        this.totalDuration = format(this.duration);
        
    }

    count: number = 0;
    stateChange: number = 0;

    


    handlePlayPause = () => {
        if(this.props.currentPlayingId === this.props.item.id){
            this.setState((prev_state) =>{
                return {
                    videoPlaying: !prev_state.videoPlaying
                }
            });
            return;
        }
        this.props.changeCurrentPlayingId(this.props.item?.id, ()=>{
            // console.log("VideoPlaying from func", this.state.videoPlaying)
            this.setState((prev_state) =>{
                return {
                    videoPlaying: true
                }
            });
        })
    };

    handleRewind = () => {
        // @ts-ignore
        this.playerRef.current.seekTo(this.playerRef.current.getCurrentTime() - 10);
    };

    handleForward = () => {
        // @ts-ignore
        this.playerRef.current.seekTo(this.playerRef.current.getCurrentTime() + 10);
    };

    handleSeekChange = (e: any, newValue: any) => {
        // @ts-ignore
        this.setState({ ...this.state, played: parseFloat(newValue / 100) });
    };

    handleSeekMouseDown = (e: any) => {
        this.setState({ ...this.state, seeking: true });
    };

    handleSeekMouseUp = (e: any, newValue: any) => {
        this.setState({ ...this.state, seeking: false });
        // @ts-ignore
        this.playerRef.current.seekTo(newValue / 100, "fraction");
    };

    handleDuration = (duration: any) => {
        this.setState({ ...this.state, allDuration: duration });
    };

    toggleFullScreen = () => {
        this.setState({ hideBtn: !this.state.hideBtn })
        // @ts-ignore
        screenfull.toggle(this.playerContainerRef.current);
    };

    onEscape = () => {
        this.setState({ hideBtn: false })
    }

    handleProgress = (changeState: any) => {
        // @ts-ignore
        this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

        // @ts-ignore
        this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";

        // @ts-ignore
        this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
        // @ts-ignore
        this.totalDuration = format(this.duration);
        if (this.count > 3) {
            // @ts-ignore
            this.controlsRef.current.style.visibility = "hidden";
            this.count = 0;
        }
        // @ts-ignore
        if (this.controlsRef.current.style.visibility == "visible") {
            this.count += 1;
        }
        if (!this.state.seeking) {
            this.setState({ ...this.state, ...changeState });
        }
    };

    handleOnEnded = () => {
        this.setState({ ...this.state, videoPlaying: false, played: 0 });
    }

    handlePip = () => {
        this.setState({ ...this.state, pip: !this.state.pip, hideBtn: false })
    }

    handleDisplayFormat = () => {
        this.setState({ timeDisplayFormat: this.state.timeDisplayFormat == " normal" ? 'remaining' : 'normal' })
    };

    handleVolumeSeekDown = (e: any, newValue: any) => {
        // @ts-ignore
        this.setState({ ...this.state, seeking: false, volume: parseFloat(newValue / 100) });
    };
    handleVolumeChange = (e: any, newValue: any) => {
        this.setState({
            ...this.state,
            // @ts-ignore
            volume: parseFloat(newValue / 100),
            muted: newValue === 0 ? true : false,
        });
    };

    closeShare = () => {
        this.setState({
            openShare: false
        })
    }

    shareOpen = () => {
        this.setState({
            openShare: true
        })
    }

    handleMute = () => {
        this.setState({ ...this.state, muted: !this.state.muted });
    };

    handlePlaybackRate = (rate: any) => {
        this.setState({ ...this.state, playbackRate: rate });
    };

    onChangeBitrate = (quality: any) => {
        // @ts-ignore
        const internalPlayer = this.playerRef.current?.getInternalPlayer();
        if (internalPlayer) {
            // currentLevel expect to receive an index of the levels array
            internalPlayer.currentLevel = quality;
            this.setState({ ...this.state, quality: quality });
        }
    }

    render() {
        const {currentPlayingId, item} = this.props;
        // console.table({
        //     currentPlayingId, currentItemId: item.id, videoPlaying: this.state.videoPlaying
        // })
        const needVideoPlay = currentPlayingId === item.id ? this.state.videoPlaying : false;
        return (
            <Box>
                {/* @ts-ignore */}
                <Box
                    style={{
                        // padding: '25px 0',
                        
                    }}
                >
                    {/* @ts-ignore */}
                    <Box
                        component={'div'}
                        style={{
                            borderRadius: '10px',
                            overflow: 'hidden',
                            boxShadow: '0 0 7px 1px rgba(0, 0, 0, 0.22)',
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            maxHeight: '100%',
                            background: '#000'
                        }}
                        // @ts-ignore
                        ref={this.playerContainerRef}
                    >
                        {/* @ts-ignore */}
                        <ReactPlayer
                            url={
                                this.props.item.attributes?.video ? this.props.item.attributes?.video : 'https://minio.b64156.dev.eastus.az.svc.builder.cafe/sbucket/twimtyaj4fwz64voegthw9n8lbs8'}
                            playing={ needVideoPlay}
                            width={'100%'}
                            height={'100%'}
                            style={{
                                minWidth: '100%',
                                minHeight: '100%'
                            }}
                            // @ts-ignore
                            ref={this.playerRef}
                            onProgress={this.handleProgress}
                            controls={false}
                            onEnded={this.handleOnEnded}
                            light={this.state.light}
                            loop={this.state.loop}
                            playbackRate={this.state.playbackRate}
                            volume={this.state.volume}
                            muted={this.state.muted}
                            pip={this.state.pip}
                        />
                        {/* <Box
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'flex-end'
                            }}
                        > */}
                            <PlayerControlls
                                // @ts-ignore
                                ref={this.controlsRef}
                                playPause={this.handlePlayPause}
                                state={{...this.state, videoPlaying: needVideoPlay}}
                                rewind={this.handleRewind}
                                forward={this.handleForward}
                                onSeek={this.handleSeekChange}
                                onSeekMouseDown={this.handleSeekMouseDown}
                                onSeekMouseUp={this.handleSeekMouseUp}
                                onDuration={this.handleDuration}
                                played={this.state.played}
                                onToggleFullScreen={this.toggleFullScreen}
                                // @ts-ignore
                                elapsedTime={this.elapsedTime}
                                hideBtn={this.state.hideBtn}
                                // @ts-ignore
                                totalDuration={this.totalDuration}
                                onMute={this.handleMute}
                                muted={this.state.muted}
                                volume={this.state.volume}
                                pip={this.handlePip}
                                onVolumeChange={this.handleVolumeChange}
                                onVolumeSeekDown={this.handleVolumeSeekDown}
                                onChangeDisplayFormat={this.handleDisplayFormat}
                                onPlaybackRateChange={this.handlePlaybackRate}
                                playbackRate={this.state.playbackRate}
                                quality={this.state.quality}
                                onChangeQuality={this.onChangeBitrate}
                                onEscape={this.onEscape}
                            />
                        {/* </Box> */}
                    </Box>
                </Box>
            </Box>
        )
    }
}
export default CarousalPlayer;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            body2: {
                fontFamily: 'Poppins, sans-serif'
            },
        },
        limitedText: {
            overflowWrap: "break-word",
            display: "-webkit-box",
            height: "40px",
            fontSize: "14px",
            textAlign: "left",
            whiteSpace: "break-spaces",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical"
        }
    })
);