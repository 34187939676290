Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdvancedSearch";
exports.labelBodyText = "AdvancedSearch Body";

exports.btnExampleTitle = "CLICK ME";
exports.getSearchResultApi = "bx_block_library2/courses/search?key="
// Customizable Area End