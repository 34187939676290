import React from "react";

// Customizable Area Start
import {
    Box,
    Container,
    Typography
} from '@material-ui/core';
// Customizable Area End

import TermsAndConditionController, {
    Props,
    configJSON
} from "./TermsAndConditionController";





class TermsAndCondition extends TermsAndConditionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            // @ts-ignore
            <Box>
                <Container>
                    <Typography
                        style={{
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '2em',
                            color: '#000',
                            textAlign: 'center',
                            marginTop: '30px'
                        }}
                    >
                        {'Terms & Conditions'}
                    </Typography>
                    <Typography
                        style={{
                            fontFamily: 'Poppins-Light',
                            fontSize: '1em',
                            color: '#000',
                            marginTop: '30px',
                            marginBottom: '30px'
                        }}
                    >
                        {
                            this.state.loading ?
                            'Loading...'
                            : this.state.termsData
                        }
                    </Typography>
                </Container>
            </Box>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
export default TermsAndCondition
// Customizable Area End
