import React from "react";

//Customizable Area Start
import {
  StyleSheet,
  ScrollView,
} from "react-native";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { withStyles } from "@material-ui/core/styles";
import { 
  Grid, 
  Box, 
  Typography, 
  Container 
} from "@material-ui/core";
import CustomCard from "../../../components/src/CustomCard";
import CustomInput from "../../../components/src/CustomInput";
import CustomButton from "../../../components/src/CustomButton";
import { palette } from "../../../components/src/theme";
import CustomLoader from "../../../components/src/CustomLoader";
import OtpInput from 'react-otp-input';
//Customizable Area End

const style = (theme: any) => ({
  mainGrid: {
    padding: "60px",
    paddingTop: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      paddingTop: "20px"
    }
  },
  welcomeText: {
    paddingLeft: "65px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      textAlign: "center"
    }
  },
  cardWrap: {
    padding: "20px",
    paddingRight: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px"
    }
  },
  cardPad: {
    padding: "50px",
    marginTop: '20px',
    boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.13)",
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      marginTop: '60px'
    }
  },
  head: {
    fontFamily: "Poppins-SemiBold",
    fontSize: "2vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: '2.5vw',
      fontFamily: "Poppins-SemiBold",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: '4vw',
      fontFamily: "Poppins-SemiBold",
    }
  },
  subHead: {
    fontFamily: "Poppins-Light",
    fontSize: "1.3vw",
    color: '#8991a4',
    marginTop: '5px',
    [theme.breakpoints.down("sm")]: {
      fontSize: '2vw',
      color: '#8991a4',
      marginTop: '5px',
      fontFamily: "Poppins-Light",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: '3vw',
      color: '#8991a4',
      marginTop: '5px',
      fontFamily: "Poppins-Light",
    }
  }
});

class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  getOtpForm = (classes: any) => {
    return (
      <Box
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box>
          <Typography
            variant="h3"
            className={classes.head}
          >
            Enter OTP
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h6"
            className={classes.subHead}
          >
            Please enter OTP sent to your email.
          </Typography>
        </Box>
        <Box
          style={{
            width: "100%",
            marginTop: "10px",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {/* @ts-ignore */}
          <OtpInput
            value={this.state.otp}
            onChange={this.handleOtpChange}
            shouldAutoFocus={true}
            numInputs={4}
            inputStyle={{
              border: '1px solid #F27024',
              borderRadius: '3px',
              margin: '10px',
              height: '40px',
              width: '40px'
            }}
            isInputNum={true}
            errorStyle={{
              border: '2px solid red',
              borderRadius: '3px',
              margin: '10px',
              height: '40px',
              width: '40px'
            }}
            hasErrored={this.state.error}
            separator={<span> </span>}
          />
          {
            this.state.errorMsg ?
              <span
                style={{
                  color: 'red',
                  fontSize: '14px',
                  fontFamily: 'Poppins-Light'
                }}
              >
                {this.state.errorMsg}
              </span>
            : null
          }
        </Box>
        <Box
          style={{
            marginTop: '20px'
          }}
        >
          <CustomButton
            title={"Submit OTP"}
            color={palette.primary.main}
            textColor={"#FFFFFF"}
            fullWidth={true}
            height={"44px"}
            fontSize={"18px"}
            onClick={() => this.submitOtp()}
          />
        </Box>
      </Box>
    )
  }

  getEmailForm = (classes: any) => {
    return (
      <Box
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box>
          <Typography
            variant="h3"
            className={classes.head}
          >
            Forgot Password
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h6"
            className={classes.subHead}
          >
            Please provide your registered email to get OTP.
          </Typography>
        </Box>
        <Box
          style={{
            width: "100%",
            marginTop: "10px"
          }}
        >
          <CustomInput
            passwordfield={false}
            placeholder={"Email"}
            onChange={(e: any) =>
              this.handleInputChange("email", e)
            }
          />
            {
              this.state.errorMsg ?
                <span
                  style={{
                    color: 'red',
                    fontSize: '14px',
                    fontFamily: 'Poppins-Light'
                  }}
                >
                  {this.state.errorMsg}
                </span>
              : null
            }
          </Box>
        <Box
          style={{
            marginTop: '20px'
          }}
        >
          <CustomButton
            title={"Request OTP"}
            color={palette.primary.main}
            textColor={"#FFFFFF"}
            fullWidth={true}
            height={"44px"}
            fontSize={"18px"}
            onClick={() => this.requestOtp()}
          />
        </Box>
      </Box>
    )
  }

  getPasswordForm = (classes: any) => {
    return (
      <Box
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box>
          <Typography
            variant="h3"
            className={classes.head}
          >
            Set new password
          </Typography>
        </Box>
        <Box
          style={{
            width: "100%",
            marginTop: "10px",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CustomInput
            passwordfield={true}
            placeholder={"Password"}
            endIcon={require('../assets/eye-icon.png')}
            iconHeight={'24px'}
            onChange={(e: any) =>
              this.handlePasswordChange("password", e)
            }
          />
          {
            this.state.pwdErrorMsg ?
              <span
                style={{
                  color: 'red',
                  fontSize: '14px',
                  fontFamily: 'Poppins-Light',
                  textAlign: 'left'
                }}
              >
                {this.state.pwdErrorMsg}
              </span>
            : null
          }
          <CustomInput
            passwordfield={true}
            placeholder={"Confirm Password"}
            endIcon={require('../assets/eye-icon.png')}
            iconHeight={'24px'}
            onChange={(e: any) =>
              this.handlePasswordChange("confirmpassword", e)
            }
          />
          {
            this.state.confirmPwdErrorMsg ?
              <span
                style={{
                  color: 'red',
                  fontSize: '14px',
                  fontFamily: 'Poppins-Light',
                  textAlign: 'left'
                }}
              >
                {this.state.confirmPwdErrorMsg}
              </span>
            : null
          }
        </Box>
        <Box
          style={{
            marginTop: '20px'
          }}
        >
          <CustomButton
            title={"Submit"}
            color={palette.primary.main}
            textColor={"#FFFFFF"}
            fullWidth={true}
            height={"44px"}
            fontSize={"18px"}
            onClick={() => this.submitNewPassword()}
          />
        </Box>
      </Box>
    )
  }

  getThanksNote = (classes: any) => {
    return (
      <Box
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box>
          <Typography
            variant="h3"
            className={classes.head}
          >
            Password Changed Successfully!
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h6"
            className={classes.subHead}
            onClick={() => this.goToLogin()}
            style={{
              color: palette.primary.main,
              fontFamily: 'Poppins-SemiBold',
              cursor: 'pointer'
            }}
          >
            Click here to Login
          </Typography>
        </Box>
      </Box>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <ScrollView keyboardShouldPersistTaps="always" style={styles.containerWeb}>
        {
          this.state.loading ? 
            <CustomLoader 
              open={this.state.loading}
            />
          : null
        }
        <Container>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
            <Container>
              <Grid container>
                <Grid md={3} sm={2}></Grid>
                <Grid md={6} xs={12} sm={8}>
                  <CustomCard className={classes.cardPad}>
                    {
                      this.state.isEmailForm ?
                        this.getEmailForm(classes)
                      : null
                    }
                    {
                      this.state.isOtpForm ?
                        this.getOtpForm(classes)
                      : null
                    }
                    {
                      this.state.isPasswordForm ?
                        this.getPasswordForm(classes)
                      :  null
                    }
                    {
                      this.state.isThanksNote ? 
                        this.getThanksNote(classes)
                      : null
                    }
                  </CustomCard>
                </Grid>
                <Grid md={3} sm={2}></Grid>
              </Grid>
            </Container>
            </Grid>
          </Grid>
        </Container>
      </ScrollView>
    );
  }
}

export default withStyles(style)(ForgotPassword)

// Customizable Area Start

const styles = StyleSheet.create({
  containerWeb: {
    flex: 1,
    marginTop: "20px",
    // padding: 60,
    // paddingTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#ffffffff"
  },
});
