import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import RequestApi from "../../../components/src/RequestApi";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface S {
  // Customizable Area Start
  fullName: string;
  email: string;
  password: string;
  reTypePassword: string;
  data: any[];
  termsAndCondition: any;
  firstName: string;
  lastName: string;
  otpAuthToken: string;
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  loading: boolean;
  googleId: any;
  openAlert: boolean;
  errorData: any;
  newsLetter_text: string;
  newsletter: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  nameReg: RegExp;
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  apiNewsLetterTextCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      fullName: "",
      email: "",
      password: "",
      reTypePassword: "",
      data: [],
      termsAndCondition: false,
      firstName: "",
      lastName: "",
      otpAuthToken: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      loading: false,
      googleId: '',
      openAlert: false,
      errorData: {},
      newsLetter_text: '',
      newsletter: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    this.emailReg = new RegExp("[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,3}$");
    this.nameReg = new RegExp("^[a-zA-Z ]+$");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      
      const apiRequestCallIds = {
        [this.apiNewsLetterTextCallId]: this.getNewsLetterTextResponse,
        [this.createAccountApiCallId]: this.createAccountApiResponse,
      };

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
        apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
      }
    }

    
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidMount = (): any => {
    this.getNewsLetterTextRequest()
  }

  getNewsLetterTextResponse = (responseJson: any, errorReponse: any) => {
    if(!responseJson.errors){
      this.setState({
        newsLetter_text: responseJson.text
      })
    }else{
      this.setState({
        errorData: errorReponse
      })
    }
  }

  createAccountApiResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      window.localStorage.setItem("token", responseJson.meta.token);
      window.localStorage.setItem("userName", responseJson.data.attributes.full_name);
      window.localStorage.setItem("userType", responseJson.data.attributes.user_type);
      window.localStorage.setItem("userEmail", responseJson.data.attributes.email);
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );
      this.setState({
        loading: false
      })
      this.send(msg);

      this.openInfoPage();
      // this.props.navigation.navigate('EmailAccountLoginBlock')

    } else {
      //Check Error Response
      // console.log("ERROR RESPONSE FOR SIGNUP", responseJson)
      this.setState({
        loading: false,
        openAlert: true,
        errorData: responseJson.errors[0].account
      })
      // this.parseApiErrorResponse(responseJson);
    }
    this.setState({
      loading: false
    })
    this.parseApiCatchErrorResponse(errorReponse);
  }

  getGoogleProfile(profile: any) {
    this.setState({
      fullName: profile.name,
      email: profile.email,
      googleId: profile.sub
    }, () => {
      this.createAccountWithSocial();
    })
  }

  getFacebookProfile(profile: any) {
    this.setState({
      fullName: profile.name,
      email: profile.email,
      googleId: profile.id
    }, () => {
      this.createAccountWithSocial();
    })
  }

  openInfoPage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
    // window.location.reload();
  }

  handleInputChange = (inputType: string, e: any) => {
    this.setState({ [inputType]: e.target.value } as Pick<S, keyof S>);
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {

    this.setState({
      loading: true
    })
    if (
      this.isStringNullOrBlank(this.state.fullName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword) ||
      this.isStringNullOrBlank(this.state.termsAndCondition)
    ) {
      // this.showAlert(
      //   configJSON.errorTitle,
      //   configJSON.errorAllFieldsAreMandatory
      // );
      this.setState({
        openAlert: true,
        loading: false,
        errorData: configJSON.errorAllFieldsAreMandatory
      })
      return false;
    }

    if (!this.nameReg.test(this.state.fullName)) {
      // this.showAlert(configJSON.errorTitle, configJSON.errorNameNotValid);
      this.setState({
        openAlert: true,
        loading: false,
        errorData: configJSON.errorNameNotValid
      })
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      // this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      this.setState({
        openAlert: true,
        loading: false,
        errorData: configJSON.errorEmailNotValid
      })
      return false;
    }
    if (this.state.password.length < 8) {
      // this.showAlert(configJSON.errorTitle, configJSON.errorPasswordLengthNotValid);
      this.setState({
        openAlert: true,
        loading: false,
        errorData: configJSON.errorPasswordLengthNotValid
      })
      return false;
    }
    if (!this.passwordReg.test(this.state.password)) {
      // this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      this.setState({
        openAlert: true,
        loading: false,
        errorData: configJSON.errorPasswordNotValid
      })
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      // this.showAlert(
      //   configJSON.errorTitle,
      //   configJSON.errorBothPasswordsNotSame
      // );
      this.setState({
        openAlert: true,
        loading: false,
        errorData: configJSON.errorBothPasswordsNotSame
      })
      return false;
    }

    if(!this.state.termsAndCondition){
      // this.showAlert(configJSON.errorTitle, configJSON.errorTermsAndCondition);
      this.setState({
        openAlert: true,
        loading: false,
        errorData: configJSON.errorTermsAndCondition
      })
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      full_name: this.state.fullName,
      email: this.state.email,
      password: this.state.password,
      terms_and_condition: this.state.termsAndCondition,
      activated: true,
      newsletter: this.state.newsletter
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createAccountWithSocial(): boolean {

    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      full_name: this.state.fullName,
      email: this.state.email,
      activated: true,
      unique_auth_id: this.state.googleId
    };

    const data = {
      type: "social_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  getNewsLetterTextRequest = () => {
    const header = {
      "Content-Type": configJSON.signUpContentType
    };

    const requestMessage = RequestApi({
      header: header,
      endPoint: configJSON.newsLetterTextEndpoint,
      method: "GET",
    });

    this.apiNewsLetterTextCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNewsLetterCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({newsletter : event.target.checked});
  };

  // Customizable Area End

}