import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../blocks/CarouselDisplay/assets/carouselCSS.css"
import { makeStyles, createStyles, useTheme } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import IconButton from '@material-ui/core/IconButton';



const useStyles = makeStyles((theme: Theme) => createStyles({
  sliderCard: {
    display: "flex",
    width: "80%",
    [theme.breakpoints.down('md')]: {
      display: "block"
    }
  }
})
)

const SliderPrevArrow = ({ ...props }) => {
  const { className, style, onClick } = props
  return (
    <div
      className="slick-prev"
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <IconButton
        style={{
          border: '2px solid #000',
          borderRadius: '50%',
          padding: '0px'
        }}
      >
        <KeyboardArrowLeftRoundedIcon 
          style={{
            fontSize: '1.6em',
            color: '#000'
          }}
        />
      </IconButton>
    </div>
  );
};
const SliderNextArrow = ({ ...props }) => {
  const { className, style, onClick } = props
  return (
    <div
      className="slick-next"
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <IconButton
        style={{
          border: '2px solid #000',
          borderRadius: '50%',
          padding: '0px'
        }}
      >
        <KeyboardArrowRightRoundedIcon 
          style={{
            fontSize: '1.6em',
            color: '#000'
          }}
        />
      </IconButton>
    </div>
  );
};

const HomeCarousel = (props: any) => {
  const path = window.location.href.split('/', 5).pop();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
    beforeChange: () => {
      if(path == 'podcast' || path == 'Videos'){
        props.pauseCurrent(false)
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          initialSlide: 0,
          beforeChange: () => {
            if(path == 'podcast' || path == 'Videos'){
              props.pauseCurrent(false)
            }
          }
        }
      },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     dots: true,
      //     infinite: true,
      //     speed: 500,
      //     slidesToShow: 1,
      //     arrows: false,
      //     adaptiveHeight: true,
      //     slidesToScroll: 1,
      //     initialSlide: 0,
      //     beforeChange: () => {
      //       if(path == 'podcast' || path == 'Videos'){
      //         props.pauseCurrent(false)
      //       }
      //     }
      //   }
      // },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     dots: true,
      //     infinite: true,
      //     speed: 500,
      //     arrows: false,
      //     adaptiveHeight: true,
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     initialSlide: 0,
      //     beforeChange: () => {
      //       if(path == 'podcast' || path == 'Videos'){
      //         props.pauseCurrent(false)
      //       }
      //     }
      //   }
      // }
    ]
  }

  const theme = useTheme();
  // const classes = useStyles(theme);

  return (
    <div 
      style={{
        boxShadow: path == 'podcast' ? "0px 2px 12px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)" : "",
        borderRadius: '6px'
      }}
    >
      {/* @ts-ignore */}
      <Slider {...settings}>
        {
          props.children
        }
      </Slider>
    </div>
  )

}

export default HomeCarousel;