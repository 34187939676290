import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import React, { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { blogImg1 } from "../../blocks/ContentManagement/src/assets";
import { getAge } from "../../blocks/ContentManagement/src/BlogPage.web";
import { BlogType } from "../../blocks/ContentManagement/src/Interfaces.web";
import { useDropzone } from 'react-dropzone'
export interface BgImgProps {
    handleBgImgChange: (value: any[], files: any[]) => void;
    isUrl?: boolean;
    from: string;
    comingFrom: string;
    coverUrl: string;
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#F27024',
    borderStyle: 'solid',
    backgroundColor: '#FAFAFA',
    // color: '#BDBDBD',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: "pointer"
};
const focusedStyle = {
    borderColor: '#2196F3'
};
const acceptStyle = {
    borderColor: '#00E676'
};
const rejectStyle = {
    borderColor: '#FF1744'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    borderRaius: '9px'
};

const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}



const BgImgDrop = ({handleBgImgChange, isUrl, from, comingFrom, coverUrl}: BgImgProps) => {
    const [files, setFiles] = useState<any[]>();
    const [source, setSource] = useState<any>(comingFrom)
    const classes = useStyles();
    const { getRootProps, getInputProps, acceptedFiles, isFocused, isDragAccept, isDragReject }: any = useDropzone({
        accept: { 'image/*': [] },
        useFsAccessApi: false,
        maxFiles: 1,
        onDrop: acceptedFiles => {
            setSource('')
            handleBgImgChange(acceptedFiles, acceptedFiles)
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: isUrl ? file : URL.createObjectURL(file)
            })));
        },
    });

    const style: any = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const removeProfilePic = () => {
        setFiles([])
        setSource('')
    }

    const thumbs = files?.map(file => (
        <div className={classes.thumb} key={file.name}>
            <div style={thumbInner}>
            {
                    typeof(file) === "string" ? <img
                        src={file}
                        style={img}
                        // Revoke data uri after image is loaded
                        // onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                    :
                    <img
                    src={URL.createObjectURL(file)}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
                }
            </div>
        </div>
    ));
    return (
        <section 
            className="container" 
            style={{
                cursor: 'pointer', 
                border: '2px solid #F27024',
                borderRadius: '9px',
                padding: '10px',
                width: '100%',
                textAlign: 'center'
            }}
        >
            <div
                style={{
                    position: 'relative'
                }}
            >
                <aside>
                {
                        source == 'edit' ?
                            <>
                                <img 
                                    src={coverUrl}
                                    style={{
                                        borderRadius: '6px',
                                        height: '180px',
                                        width: '100%'
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        display: 'flex',
                                        top: '0px',
                                        bottom: '0px',
                                        left: '0px',
                                        right: '0px',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        style={{
                                            marginRight: '5px',
                                        }}
                                    >
                                        <div {...getRootProps({className: 'dropzone'})}>
                                            <input {...getInputProps()} />
                                            <div
                                                style={{
                                                    background: '#fff',
                                                    borderRadius: '50%',
                                                }}
                                            >
                                                <img 
                                                    style={{
                                                        marginRight: 'auto',
                                                        marginLeft: 'auto'
                                                    }}
                                                    width={'40px'}
                                                    src={require('../src/assets/uploadIcon.png')}
                                                />
                                            </div> 
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: '5px'
                                        }}
                                    >
                                        <div
                                            style={{
                                            background: '#fff',
                                            borderRadius: '50%',
                                            }}
                                        >
                                            <img 
                                                style={{
                                                    marginRight: 'auto',
                                                    marginLeft: 'auto'
                                                }}
                                                width={'40px'}
                                                src={require('../src/assets/delete.png')}
                                                onClick={() => removeProfilePic()}
                                            />
                                        </div> 
                                    </div>
                                </div>
                            </>
                        :   thumbs
                    }
                    {!files?.length ?
                        null
                    :
                    <div
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            top: '0px',
                            bottom: '0px',
                            left: '0px',
                            right: '0px',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                marginRight: '5px',
                            }}
                        >
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <div
                                    style={{
                                        background: '#fff',
                                        borderRadius: '50%',
                                    }}
                                >
                                    <img 
                                        style={{
                                            marginRight: 'auto',
                                            marginLeft: 'auto'
                                        }}
                                        width={'40px'}
                                        src={require('../src/assets/uploadIcon.png')}
                                    />
                                </div> 
                            </div>
                        </div>
                        <div
                            style={{
                                marginLeft: '5px'
                            }}
                        >
                            <div
                                style={{
                                background: '#fff',
                                borderRadius: '50%',
                                }}
                            >
                                <img 
                                    style={{
                                        marginRight: 'auto',
                                        marginLeft: 'auto'
                                    }}
                                    width={'40px'}
                                    src={require('../src/assets/delete.png')}
                                    onClick={() => removeProfilePic()}
                                />
                            </div> 
                        </div>
                    </div>
                }
                </aside>
            </div>
            {
                files?.length || source == 'edit' ?
                    null
                :
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <div style={{borderColor: '#F27024'}}>
                        <p style={{ fontFamily: "Poppins-SemiBold", color: "#F27024" }}>Upload Image</p>
                    </div>
                </div>
            }
        </section>
    )
}
export default BgImgDrop;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        baseStyle: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#EEEEEE',
            borderStyle: 'dashed',
            backgroundColor: '#FAFAFA',
            // color: '#BDBDBD',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        },
        focusedStyle: {
            borderColor: '#2196F3'
        },
        cceptStyle: {
            borderColor: '#00E676'
        },
        ejectStyle: {
            borderColor: '#FF1744'
        },
        thumb: {
            display: 'inline-flex',
            borderRadius: 2,
            border: '1px solid #eaeaea',
            // marginBottom: 8,
            // marginRight: 8,
            width: 'auto',
            height: 100,
            // padding: 4,
            boxSizing: 'border-box'
        },
        thumbsContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            // marginTop: 16
        },
        img: {
            borderRaius: '9px'
        }

    })
);