import {
    Paper,
    Modal,
    Typography,
    Button,
    Box
} from "@material-ui/core";
import React from "react";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const TokenTimeOut = ({
    open,
    setOpen,
}: Props) => {
    const handleLogin = () => {
        setOpen(false)
        localStorage.clear()
        window.location.replace("/Login")
    }
    return (
        <div>
            <Modal
                style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                open={open}
                // onClose={() => setOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Paper
                    style={{ width: "400px", padding: "20px", borderRadius: "20px", textAlign: "center" }}
                >
                    <Typography>Your session has expired. Please log in again.</Typography>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "20px",
                            marginRight: "17px"
                        }}>
                        <Button variant="contained"
                            style={{
                                background: "#F27024",
                                color: "#ffffff"
                            }}
                            onClick={handleLogin}>Login</Button>
                    </Box>
                </Paper>
            </Modal>
        </div>
    );
}

export default TokenTimeOut;

