import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "../../Download/src/assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  mobileOpen: boolean;
  loading: boolean;
  eventDetails: any;
  error: any;
  openConfirm: boolean;
  successMessage: any;
  openAlert: boolean;
  videoPlaying: boolean;
  played: any;
  seeking: boolean;
  allDuration: any;
  light: boolean;
  muted: boolean;
  pip: boolean;
  playbackRate: any;
  volume: any;
  loop: boolean;
  timeDisplayFormat: any;
  quality: any;
    hideBtn: any;
    matches: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PastEventDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getEventDetailsId: string = '';
  registerEventId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      mobileOpen: false,
      loading: false,
      eventDetails: {},
      error: '',
      openConfirm: false,
      successMessage: '',
      openAlert: false,
      videoPlaying: false,
      played: 0,
      seeking: false,
      allDuration: 0,
      light: false,
      muted: false,
      pip: false,
      playbackRate: 1,
      volume: 1,
      loop: false,
      timeDisplayFormat: 'normal',
      quality: 480,
      hideBtn: false,
      matches: window.matchMedia("(min-width: 940px)").matches,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId != null) {
            if(apiRequestCallId === this.getEventDetailsId){
                this.setEveDetails(responseJson)
            }
            if(apiRequestCallId === this.registerEventId){
                this.setRegEve(responseJson)
            }
        }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start

  setRegEve = (responseJson: any) => {
    if(responseJson.message === 'Registration Sucessfull!'){
      this.setState({
          loading: false,
          successMessage: 'Event registered successfully',
          openAlert: true
      })
  } else {
      this.setState({
          loading: false,
          successMessage: 'Something went wrong!',
          openAlert: true
      })
  }
  }

  setEveDetails = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
          eventDetails: responseJson.data.live_stream,
          loading: false
      })
  } else {
      this.setState({
          loading: false,
          error: responseJson.errors
      })
  }
  }

  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getEventDetails()
}

  getEventDetails = () => {
    const id = window.location.href.split('/', 6).pop();
    this.setState({
        loading: true
    })
        const userToken = localStorage.getItem("token");
        let header: any = {};
        if(userToken){
          header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
          };
        } else {
          header = {
            "Content-Type": configJSON.contentType
        };
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getEventDetailsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getEventDetailsApi+'/'+id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  registerEvent = (id: any) => {
    this.setState({
        loading: true,
        openConfirm: false
    })
    const header = {
        "Content-Type": configJSON.contentType,
        token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.registerEventId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.registerEventApi+`?live_stream_id=${id}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
  
}
