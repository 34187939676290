import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import React from "react";
import { getAge } from "../../blocks/ContentManagement/src/BlogPage.web";
import { BlogType } from "../../blocks/ContentManagement/src/Interfaces.web";
import { PlaceholderImage } from "../../blocks/ContentManagement/src/assets";
export interface SingeArticleProps {
  item: BlogType;
  gotToSingleArticle: (id: string) => void;
}


const BlogCard1 = ({ item, gotToSingleArticle }: SingeArticleProps) => {
  const classes = useStyles();
  return (
    <Card onClick={() => gotToSingleArticle(item?.id)} style={{fontFamily:"Poppins", width: "100%", boxShadow: "0 2px 10px 0 rgba(130, 131, 144, 0.26)", borderRadius: "10px", cursor: "pointer" }}>
      <CardMedia
        component="img"
        height="152"
        style={{objectFit: "cover", width: "100%"}}
        image={item?.attributes?.cover_image || PlaceholderImage}
        alt="Blog card image"
      />
      <CardContent
        style={{
          height: '155px',
          paddingBottom: '16px'
        }}
      >
        <Typography 
          gutterBottom 
          style={{ 
            fontFamily: "Poppins-SemiBold", 
            fontSize: "1rem"
          }}
        >
          {item?.attributes?.title.length <= 50 ? item?.attributes?.title : item?.attributes?.title.substring(0, 50) + '...'}
        </Typography>
        <Typography gutterBottom  style={{ color: "grey", fontFamily:"Poppins-Light", fontSize: "14px", marginBottom: "12px", marginTop: "8px" }}>
          {getAge(item?.attributes?.created_at)} ago by {item?.attributes?.instructor_name}
        </Typography>
        <Typography style={{fontFamily:"Poppins-Light"}} className={classes.limitedText}>{item?.attributes?.subtitle === null ? "Subtitle is not present" : item?.attributes?.subtitle.length <= 100 ? item?.attributes?.subtitle : item?.attributes?.subtitle.substring(0, 100) + '...'}</Typography>
      </CardContent>
    </Card>
  )
}
export default BlogCard1;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      body2: {
        fontFamily: 'Poppins, sans-serif'
      },
    },
    limitedText: {
      overflowWrap: "break-word",
      display: "-webkit-box",
      // height: "40px",
      fontSize: "14px",
      textAlign: "left",
      whiteSpace: "break-spaces",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden"
    }
  })
);