export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const playerBg = require("../assets/player-bg.jpg");
export const audio = require("../assets/audio.jpg");
export const backward10 = require("../assets/backward10.png");
export const forward10 = require("../assets/forward10.png");
export const volMainIcon = require("../assets/volume@3x.png");
export const expandIcon = require("../assets/expand@3x.png");
export const ccIcon = require("../assets/cc@3x.png");
export const settingsIcon = require("../assets/settings@3x.png");
