import React from "react";

// Customizable Area Start
import { 
  Container, 
  Box,
  Grid,
  Typography, 
  Button
} from '@material-ui/core';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import BreadCrumb from "../../../components/src/BreadCrumb";
import PodCastVideo from "../../../components/src/PodCastVideo";
import { palette } from "../../../components/src/theme";
import AlertModal from "../../../components/src/AlertModal";
import CustomLoader from "../../../components/src/CustomLoader";


const styles = (theme: any) => ({
  otherPodcastHead: {
    fontSize: '30px',
    fontFamily: 'Poppins-SemiBold',
    [theme.breakpoints.down("md")]: {
      fontSize: '24px',
      fontFamily: 'Poppins-SemiBold',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '18px',
      fontFamily: 'Poppins-SemiBold',
    },
  },
  otherPodcastCardHead: {
    fontSize: '20px',
    fontFamily: 'Poppins-Light',
    color: '#ffffff',
    [theme.breakpoints.down("sm")]: {
      fontSize: '16px',
      fontFamily: 'Poppins-Light',
      color: '#ffffff',
    },
  },
  otherPodcastCardDesc: {
    fontSize: '14px',
    fontFamily: 'Poppins-Light',
    color: '#ffffff',
    [theme.breakpoints.down("sm")]: {
      fontSize: '12px',
      fontFamily: 'Poppins-Light',
      color: '#ffffff',
    },
  }
});
// Customizable Area End

import Library2Controller, {
  Props
} from "./Library2Controller";
import CustomPagination from "../../../components/src/CustomPagination";

class Library2 extends Library2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // localCatId: any = localStorage.getItem('category_id');
  changePage = (event: React.ChangeEvent<unknown>, value: number) => {
      this.setState({
        talkCurrentPage: value,
        videoPodcastData: {}
      })
      if(this.state.catId != null || this.state.catId != undefined){
        this.getPodcastVideosList(this.state.catId, value);
      } else {
        this.getPodcastVideosList('', value);
      }
  };


  closeAlertBox = () => {
    this.setState({
        openAlert: false
    })
  }
  // Customizable Area End

  render() {
    const {classes} = this.props
    const {windowWidth} = this.state;
    return (
      // Customizable Area Start
        <ThemeProvider theme={theme} >
          <Container>
            {
                this.state.openAlert ?
                    <AlertModal 
                        open={this.state.openAlert}
                        data={this.state.updateMessage}
                        onClose={() => this.closeAlertBox()}
                    />
                :   null
            }
            {
                this.state.loading ?
                <CustomLoader 
                    open={this.state.loading}
                />
                : null
            }
            <BreadCrumb 
              base={'Home'}
              url1={{
                base: `Let's Talk`,
                link: 'podcast'
              }}
              url2={{
                path: "Video Podcasts",
                link: "videopodcasts"
              }}
            />
            {/* @ts-ignore */}
            <Box mt={5}>
              <Grid container spacing={2}>
                <Grid item md={2} sm={4} xs={6}>
                  <Button
                    id={'btnExample'}
                    variant="outlined"
                    style={{
                      color: !this.state.catId ? "#ffffff" : palette.primary.main,
                      borderColor: palette.primary.main,
                      minWidth: '10vw',
                      width: '100%',
                      fontFamily: 'Poppins-Light',
                      textTransform: 'capitalize',
                      background: !this.state.catId ? palette.primary.main : ""
                    }}
                    onClick={() => this.getAllPodcasts()}
                  >
                    All Podcasts
                  </Button>
                </Grid>
                {
                  // istanbul ignore next
                  this.state.podcastFilterOptionsData.length ? this.state.podcastFilterOptionsData.map((item: any, index: any) => {
                    return (
                      <Grid item md={2} sm={4} xs={6}>
                        <Button
                          variant="outlined"
                          style={{
                            color: this.state.catId == item.attributes.id ? "#ffffff" : palette.primary.main,
                            borderColor: palette.primary.main,
                            minWidth: '10vw',
                            width: '100%',
                            fontFamily: 'Poppins-Light',
                            textTransform: 'capitalize',
                            background: this.state.catId == item.attributes.id ? palette.primary.main : ""
                          }}
                          onClick={() => this.getFilteredPodcast(item.attributes.id)}
                          title={item.attributes.name}
                        >
                          {
                            windowWidth > 960 && windowWidth < 1024 ?
                            item.attributes.name && item.attributes.name.length > 14 
                            ? item.attributes.name.substring(0,13) + '...' 
                            : item.attributes.name
                            : item.attributes.name
                          }
                        </Button>
                      </Grid>
                    )
                  })
                  : <Typography>Loading...</Typography>
                }
              </Grid>
            </Box>
            {/* @ts-ignore */}
            <Box mt={5}>
              <PodCastVideo 
                imgUrls={this.state.videoPodcastData}
                showSeeMore={false}
                getVideo = {(id: any) => this.getVideo(id)}
                loading={this.state.loading}
                vErrorMsg={this.state.errorMsg}
                navigation={this.props.navigation}
                makeFavourite={(id: any, status: any) => this.makeFavourite(id, status)}
              />
            </Box>
            {
              this.state.totalPages.total_pages == 1 ?
              null
              : 
                // @ts-ignore
                <Box
                  mt={10}
                  mb={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <CustomPagination 
                    onChange={this.changePage}
                    totalPage={this.state.totalPages.total_pages}
                  />
                </Box>
            }
          </Container>
          {/* @ts-ignore */}
        <Box
          mt={8}
          style={{
            position: 'relative'
          }}
        >
          {/* @ts-ignore */}
          <Box
            style={{
              background: '#FFFBE6',
              minHeight: '200px',
              height: '200px',
              position: 'absolute',
              minWidth: '100%'
            }}
          >

          </Box>
        </Box>
        {
          this.state.otherPodcast.length ?
            <Container>
              {/* @ts-ignore */}
              <Box 
                mb={8}
                style={{
                  position: 'relative'
                }}
              >
                {/* @ts-ignore */}
                <Box pt={6}>
                    {/* @ts-ignore */}
                      <Box
                          style={{
                              display: 'flex',
                              // flex: 1,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                          }}
                      >
                        {/* @ts-ignore */}
                          <Box>
                              <Typography
                                  className={classes.otherPodcastHead}
                              >
                                  Other podcasts you may like
                              </Typography>
                          </Box>
                      </Box>
                    </Box>
                    {/* @ts-ignore */}
                    <Box mt={3}>
                      <Grid container spacing={5}>
                        {
                          this.state.otherPodcast.slice(0, 4).map((item: any, index: any) => {
                            return (
                              <Grid key={index} item xs={12} sm={6} md={3}>
                              {/* @ts-ignore */}
                                <Box
                                  style={{
                                      cursor: 'pointer',
                                      position: 'relative'
                                  }}
                                  onClick={() => this.getVideo(item.id)}
                                >
                                  <img width={'100%'} height={'100%'} src={item.attributes.poster} style={{borderRadius: '8px', position: 'relative'}} />
                                  {/* @ts-ignore */}
                                  <Box
                                      style={{
                                          position: 'absolute',
                                          zIndex: 9999,
                                          bottom: '15px',
                                          padding: '20px',
                                      }}
                                  >
                                      <Typography
                                          className={classes.otherPodcastCardHead}
                                      >
                                          {item.attributes.title}
                                      </Typography>
                                      <Typography
                                          className={classes.otherPodcastCardDesc}
                                      >
                                          {item.attributes.summary}
                                      </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            )
                          })
                        }
                        </Grid>
                      </Box>
              </Box>
            </Container>
          : null
        }
        </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default withStyles(styles)(Library2);
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: '20px 0px',
    }
  },
});
// Customizable Area End
