import React from "react";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Toolbar,
  AppBar,
  Grid,
  Tabs,
  Tab
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MenuIcon from "@material-ui/icons/Menu";
import UserListController, { Props } from "./UserListController";
import { palette } from "../../../../components/src/theme";
import {
  withTheme,
  withStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import CustomToggle from "../../../../components/src/CustomToggle";
import CustomLoader from "../../../../components/src/CustomLoader";
import AlertModal from "../../../../components/src/AlertModal";

const drawerWidth = 240;

// Customizable Area End

class UserList extends UserListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleTabsChange = (event: any, newValue: any) => {
    this.setState(
      {
        tabs: newValue
      },
      () => {
        if (this.state.tabs === 1) {
          this.getUserList("student");
        } else {
          this.getUserList("instructor");
        }
      }
    );
  };

  getAppBar = (classes: any) => {
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logo}>
            <img
              src={require("../../../../components/src/logo.svg")}
              height={70}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              padding: "10px"
            }}
          >
            <div>
              <Typography
                style={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "25px",
                  color: "#353535",
                  marginTop: "25px"
                }}
                className={classes.welcome}
              >
                User List
              </Typography>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  getInstructorUserRow = () => {
    return (
      <>
        {this.state.userList.length ? (
          this.state.userList.map((item: any, index: any) => {
            return (
              <TableRow key={index}>
                <TableCell
                  style={{ fontFamily: "Poppins-Light" }}
                  // @ts-ignore
                  component="th"
                  scope="row"
                >
                  {item.id}
                </TableCell>
                <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                  {item.attributes.full_name}
                </TableCell>
                <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                  {item.attributes.email}
                </TableCell>
                <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                  {item.attributes.phone_number
                    ? item.attributes.phone_number
                    : "---"}
                </TableCell>
                <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                  <CustomToggle
                    canManage={item.attributes.can_manage_profile}
                    toggle={(permission: any) =>
                      this.changePermission(permission, item.id)
                    }
                  />
                </TableCell>
                <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                  {item.attributes.activated ? (
                    <span style={{ color: palette.primary.main }}>Active</span>
                  ) : (
                    <span style={{ color: "red" }}>In-Active</span>
                  )}
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <span
            style={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "20px",
              color: "#000"
            }}
          >
            No data found!
          </span>
        )}
      </>
    );
  };

  getUserRow = () => {
    return (
      <>
        {this.state.userList.length ? (
          this.state.userList.map((item: any, index: any) => {
            return (
              <TableRow key={index}>
                <TableCell
                  style={{ fontFamily: "Poppins-Light" }}
                  // @ts-ignore
                  component="th"
                  scope="row"
                >
                  {item.id}
                </TableCell>
                <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                  {item.attributes.full_name}
                </TableCell>
                <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                  {item.attributes.email}
                </TableCell>
                <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                  {item.attributes.phone_number
                    ? item.attributes.phone_number
                    : "---"}
                </TableCell>
                <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                  {item.attributes.activated ? (
                    <span style={{ color: palette.primary.main }}>Active</span>
                  ) : (
                    <span style={{ color: "red" }}>In-Active</span>
                  )}
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <span
            style={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "20px",
              color: "#000"
            }}
          >
            No data found!
          </span>
        )}
      </>
    );
  };

  getFirstTab = (classes: any) => {
    return (
      <>
      {/* @ts-ignore */}
        <Box>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Phone</TableCell>
                  <TableCell align="left">Can Manage</TableCell>
                  <TableCell align="left">Activated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.getInstructorUserRow()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    );
  };

  getSecondTab = (classes: any) => {
    return (
      <>
      {/* @ts-ignore */}
        <Box>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Phone</TableCell>
                  <TableCell align="left">Activated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.getUserRow()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { mobileOpen } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div>
        {this.state.loading ? <CustomLoader open={this.state.loading} /> : null}

        {this.state.openModal ? (
          <AlertModal
            open={this.state.openModal}
            data={this.state.modalMsg}
            // @ts-ignore
            onClose={() => this.closeAlertBox()}
          />
        ) : null}

        <DashboardDrawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          // @ts-ignore
          navigation={this.props.navigation}
        />
        {/* @ts-ignore */}
        <Box className={classes.appBarWrap}>
          {this.getAppBar(classes)}
          {/* @ts-ignore */}
          <Box
            style={{
              padding: "20px"
            }}
          >
            <Grid container>
              <Grid lg={12} className={classes.tabsBox}>
                <Tabs
                  className={classes.tabs_main}
                  value={this.state.tabs}
                  onChange={this.handleTabsChange}
                >
                  <Tab className={classes.headingTabs} label="Instructors" />
                  <Tab className={classes.headingTabs} label="General Users" />
                </Tabs>
              </Grid>
            </Grid>
            {this.state.tabs === 0 ? this.getFirstTab(classes) : null}
            {this.state.tabs === 1 ? this.getSecondTab(classes) : null}
          </Box>
        </Box>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// export default withStyles(styles)(LetsRead);
export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      input: {
        width: "100%",
        height: "100%",
        border: "1px solid rgba(82,92, 229, 0.12)",
        borderRadius: "7px",
        textDecoration: "none",
        fontSize: "1em",
        fontFamily: "Poppins-Light",
        backgroundColor: "white",
        color: "#000 !important",
        padding: "5px 1rem", //
        "& > .MuiInputBase-input": {
          height: "100%"
        },
        "&  .MuiFormHelperText-root.Mui-error": {
          fontFamily: "Poppins-Light"
        }
      },
      logo: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
          display: "none"
        }
      },
      menuButton: {
        marginRight: theme.spacing(2),
        color: palette.primary.main,
        [theme.breakpoints.up("md")]: {
          display: "none"
        }
      },
      appBar: {
        background: "#fff",
        boxShadow: "none",
        [theme.breakpoints.up("md")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
          background: "#fff",
          boxShadow: "none"
        }
      },
      appBarWrap: {
        [theme.breakpoints.up("md")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth
        }
      },
      welcome: {
        [theme.breakpoints.down("sm")]: {
          display: "none"
        }
      },
      name: {
        fontSize: "24px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "20px",
          fontSize: "16px"
        }
      },
      tabsBox: {
        // flex: 1,
        // flexDirection: 'row',
        // justifyContent: 'space-between',
        width: "100%",
        borderBottom: "2px solid",
        borderBottomColor: "rgba(0, 0, 0, 0.12)"
      },
      tabs_main: {
        "& .MuiTabs-indicator": {
          backgroundColor: palette.primary.main,
          borderRadius: "1.5px",
          height: "4px"
        },
        "& .Mui-selected": {
          color: palette.primary.main
        }
      },
      headingTabs: {
        fontSize: "24px",
        fontWeight: 600,
        fontFamily: palette.fontFamily.main,
        width: "100%",
        display: "flex",
        textTransform: "inherit"
        // minWidth: '50% !important'
      },
      lastTableRow: {
        "&:last-child td, &:last-child th": { border: 0 }
      },
      triangle: {
        overflow: "visible",
        filter: "drop-shadow(0px 1px 4px rgba(0,0,0,0.06))",
        marginTop: "49px",
        marginLeft: "12px",
        borderRadius: "10px",
        "& .MuiAvatar-root": {
          width: "32px ",
          height: "32px",
          marginLeft: "-0.5px ",
          marginRight: "1px"
        },
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: "0px",
          right: "20px ",
          width: "15px",
          height: "15px ",
          backgroundColor: "#ffffff",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0
        }
      },
      root: {
        color: "#F27024 !important",
        "& .Mui-selected": {
          background: "#F27024",
          color: "white",
          fontWeight: "bold"
        },
        ul: {
          "& .MuiPaginationItem-root": {
            color: "#F27024"
          }
        }
      },
      modalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "496px",
        height: "200px",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "24px",
        padding: "10px 5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      statModalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "800px",
        height: "360px",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "24px",
        padding: "10px 5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      previewModalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "796px",
        maxHeight: "80vh",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "24px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      menuItemStyle: {
        fontFamily: "Poppins-Light",
        "&:hover": {
          textAlign: "right",
          color: "#d53636",
          backgroundColor: "#ffffff"
        }
      }
    })
  )(UserList)
);
// Customizable Area End
