// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import Footer from "../../components/src/Footer";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import LiveFeedCapture from "../../blocks/LiveFeedCapture/src/LiveFeedCapture";
import AudioMusic from "../../blocks/AudioMusic/src/AudioMusic";
import GroupChat from "../../blocks/GroupChat/src/GroupChat";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank.web";
import DonationPayments from "../../blocks/DonationPayments/src/DonationPayments";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import LetsRead from "../../blocks/dashboard/src/LetsReadFolder/LetsRead";
import AddNew from "../../blocks/dashboard/src/LetsReadFolder/AddNewArticle";
import LetsTalk from "../../blocks/dashboard/src/LetsTalk/LetsTalk";
import LetsTalkAddPodcast from "../../blocks/dashboard/src/LetsTalk/AddPodcast";
import EditPodcast from '../../blocks/dashboard/src/LetsTalk/EditPodcast';
import LetsTalkCategories from "../../blocks/dashboard/src/LetsTalk/LetsTalkCategories";
import LetsWatch from "../../blocks/dashboard/src/LetsWatch/LetsWatch";
import EditVideo from "../../blocks/dashboard/src/LetsWatch/EditVideo";
import LetsWatchAddVideos from "../../blocks/dashboard/src/LetsWatch/AddVideo";
import LetsWatchCategories from "../../blocks/dashboard/src/LetsWatch/LetsWatchCategories";
import EditLetsRead from "../../blocks/dashboard/src/LetsReadFolder/EditLetsRead";
import LetsReadCategories from "../../blocks/dashboard/src/LetsReadFolder/Categories";
import LetsLearnCategories from "../../blocks/dashboard/src/LetsLearn/CourseCategories";
import EditCourse from "../../blocks/dashboard/src/LetsLearn/EditCourse";
import SplitViewInterface from "../../blocks/SplitViewInterface/src/SplitViewInterface";
import CarouselDisplay from "../../blocks/CarouselDisplay/src/CarouselDisplay";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Videos4 from "../../blocks/Videos4/src/Videos4";
import StripeIntegration from "../../blocks/StripeIntegration/src/StripeIntegration";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3.web";
import Library2 from "../../blocks/Library2/src/Library2";
import AudioLibrary from "../../blocks/Library2/src/AudioLibrary";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import VideoAutoplay from "../../blocks/VideoAutoplay/src/VideoAutoplay";
import TaskViews from "../../blocks/TaskViews/src/TaskViews";
import Share from "../../blocks/share/src/Share";
import EventRegistration from "../../blocks/EventRegistration/src/EventRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Download from "../../blocks/Download/src/Download";
import LiveStreaming from "../../blocks/LiveStreaming/src/LiveStreaming";
import Calendar from "../../blocks/Calendar/src/Calendar";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import ProjectTemplates from "../../blocks/ProjectTemplates/src/ProjectTemplates";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ProjecttaskTracking2 from "../../blocks/ProjecttaskTracking2/src/ProjecttaskTracking2";
import IcalendarSupport from "../../blocks/IcalendarSupport/src/IcalendarSupport";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import AdvancedSearch from "../../blocks/AdvancedSearch/src/AdvancedSearch";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import Comments from "../../blocks/Comments/src/Comments";
import Gallery from "../../blocks/Gallery/src/Gallery";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import ProjectNotes from "../../blocks/ProjectNotes/src/ProjectNotes";
import VideoManagement from "../../blocks/VideoManagement/src/VideoManagement";
import HomePage from "../../blocks/CarouselDisplay/src/HomePage.web";
import BlogPage from "../../blocks/ContentManagement/src/BlogPage.web";
import BlogArticle from "../../blocks/ContentManagement/src/BlogArticle.web";
import TermsAndCondition from "../../blocks/ContentManagement/src/TermsAndCondition";
import PrivacyPolicy from "../../blocks/ContentManagement/src/PrivacyPolicy";
import LetsWatchHome from "../../blocks/VideoManagement/src/LetsWatchHome.web";
import WatchVideo from "../../blocks/VideoManagement/src/WatchVideo.web";
import OtherVideo from "../../blocks/VideoManagement/src/OtherVideo.web";
import Courses from "../../blocks/ProjectNotes/src/ProjectNotes.web";
import CourseDetails from "../../blocks/ProjectNotes/src/CourseDetails.web";
import singleLessons from "../../blocks/ProjectNotes/src/singleLessons";
import LetsLearn from "../../blocks/dashboard/src/LetsLearn/LetsLearn";
import AddNewCourses from "../../blocks/dashboard/src/LetsLearn/AddNewCourses";
import LetsDiscover from "../../blocks/dashboard/src/LetsDiscover/DiscoverTabs";
import AddPeople from "../../blocks/dashboard/src/LetsDiscover/AddPeople";
import AddOrganisation from "../../blocks/dashboard/src/LetsDiscover/AddOrganisation";
import AddFilm from "../../blocks/dashboard/src/LetsDiscover/AddFilm";
import AddBook from "../../blocks/dashboard/src/LetsDiscover/AddBook";
import AllList from "../../blocks/dashboard/src/LetsDiscover/AllList";
import PeopleCategories from "../../blocks/dashboard/src/LetsDiscover/PeopleCategories";
import OrgCategories from "../../blocks/dashboard/src/LetsDiscover/OrgCategories";
import BookCategories from "../../blocks/dashboard/src/LetsDiscover/BookCategories";
import FilmCategories from "../../blocks/dashboard/src/LetsDiscover/FilmCategories";
import UserList from "../../blocks/dashboard/src/UserList/UserList";
import EditPeople from "../../blocks/dashboard/src/LetsDiscover/EditPeople";
import EditOrg from "../../blocks/dashboard/src/LetsDiscover/EditOrg";
import EditFilm from "../../blocks/dashboard/src/LetsDiscover/EditFilm";
import EditBook from "../../blocks/dashboard/src/LetsDiscover/EditBook";
import AddEvent from '../../blocks/dashboard/src/LetsTalk/AddEvent';
import LetsTalkEvents from "../../blocks/dashboard/src/LetsTalk/Events";
import EventDetails from "../../blocks/dashboard/src/LetsTalk/EventDetails";
import UserEventDetails from "../../blocks/EventRegistration/src/UserEventDetails";
import UserEvent from '../../blocks/EventRegistration/src/EventRegistration.web';
import UserPastEventDetails from '../../blocks/EventRegistration/src/PastEventDetails';
import PastEventDetails from '../../blocks/dashboard/src/LetsTalk/PastEventDetails';
import Faqs from "../../blocks/helpcentre/src/FaqPage.web"
import Help from "../../blocks/dashboard/src/Help/Help.web";
import Tutorial from "../../blocks/dashboard/src/Help/Tutorial.web";
import { ProfileContext } from "../../blocks/QuestionBank/src/contextFile/ProfileContext";
import TokenTimeOut from "../../components/src/TokenTimeOut"




const routeMap = {
singleLessons:{
 component:singleLessons,
path:"/lesson/:id"},
CourseDetails:{
 component:CourseDetails,
path:"/CourseDetails/:id"},
AllCourses:{
 component:Courses,
path:"/AllCourses"},
MyCourses:{
 component:Courses,
path:"/MyCourses"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
LiveFeedCapture:{
 component:LiveFeedCapture,
path:"/LiveFeedCapture"},
AudioMusic:{
 component:AudioMusic,
path:"/AudioMusic/:id"},
GroupChat:{
 component:GroupChat,
path:"/GroupChat"},
CommunityForum:{
 component:CommunityForum,
path:"/LetsDiscovers/user"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
QuestionBank:{
 component:QuestionBank,
path:"/accountSettings"},
DonationPayments:{
 component:DonationPayments,
path:"/DonationPayments"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/signup"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
LetsRead:{
  component:LetsRead,
 path:"/LetsRead/AllPosts"},
 AddNew:{
  component:AddNew,
 path:"/LetsRead/AddNew"},

 EditLetsRead:{
  component:EditLetsRead,
 path:"/LetsRead/Edit/:id"},
 LetsDiscover:{
  component: LetsDiscover,
  path: "/LetsDiscover/DiscoverTabs"
},
AddPeople: {
  component: AddPeople,
  path:'/LetsDiscover/AddPeople'
},
EditPeople: {
  component: EditPeople,
  path:'/LetsDiscover/EditPeople/:id'
},
AddOrganisation: {
  component: AddOrganisation,
  path:'/LetsDiscover/AddOrganisation'
},
EditOrganisation: {
  component: EditOrg,
  path: '/LetsDiscover/EditOrganisation/:id'
},
AddFilm: {
  component: AddFilm,
  path:'/LetsDiscover/AddFilm'
},
EditFilm: {
  component: EditFilm,
  path: '/LetsDiscover/EditFilm/:id'
},
AddBook: {
  component: AddBook,
  path:'/LetsDiscover/AddBook'
},
EditBook: {
  component: EditBook,
  path: '/LetsDiscover/EditBook/:id'
},
Help: {
  component: Help,
  path: '/Help'
},
Tutorial: {
  component: Tutorial,
  path: '/Tutorial/:id'
},
AllList: {
  component: AllList,
  path: '/LetsDiscover/AllList'
},
PeopleCategories: {
  component: PeopleCategories,
  path: '/LetsDiscover/PeopleCategories'
},
OrgCategories: {
  component: OrgCategories,
  path: '/LetsDiscover/OrgCategories'
},
BookCategories: {
  component: BookCategories,
  path: '/LetsDiscover/BookCategories'
},
FilmCategories: {
  component: FilmCategories,
  path: '/LetsDiscover/FilmCategories'
},
UserList: {
  component: UserList,
  path: '/UserList'
},
 LetsReadCategories:{
  component:LetsReadCategories,
 path:"/LetsRead/Categories"},
LetsLearn:{
  component:LetsLearn,
  path:"/LetsLearn/AllCourses"},
EditCourse:{
    component:EditCourse,
    path:"/LetsLearn/EditCourse/:id"
},
AddNewCourses:{
    component:AddNewCourses,
   path:"/LetsLearn/AddNewCourses"},
LetsLearnCategories:{
  component:LetsLearnCategories,
  path:"/LetsLearn/CourseCategories"},
LetsTalk:{
  component:LetsTalk,
  path:"/LetsTalk/AllPodcasts"},
LetsTalkAddPodcast:{
  component:LetsTalkAddPodcast,
  path:"/LetsTalk/AddPodcast"},
EditPodcast:{
  component:EditPodcast,
  path:"/LetsTalk/EditPodcast/:id"},
AddEvent:{
    component:AddEvent,
    path:"/LetsTalk/AddEvent"},
LetsTalkEvents:{
  component:LetsTalkEvents,
  path:"/LetsTalk/Events"},
EventDetails:{
  component:EventDetails,
  path:"/LetsTalk/EventDetails/:id"},
UserEventDetails:{
    component:UserEventDetails,
    path:"/UserEventDetails/:id"},
UserEvent:{
  component:UserEvent,
  path:"/UserEvent"},
UserPastEventDetails:{
    component:UserPastEventDetails,
    path:"/UserPastEventDetails/:id"},
PastEventDetails:{
  component:PastEventDetails,
  path:"/LetsTalk/PastEventDetails/:id"},
LetsTalkCategories:{
  component:LetsTalkCategories,
  path:"/LetsTalk/PodcastCategories"},
LetsWatch:{
  component:LetsWatch,
  path:"/LetsWatch/AllVideos"},
LetsWatchAddVideos:{
  component:LetsWatchAddVideos,
  path:"/LetsWatch/AddVideos"},
EditVideo:{
  component:EditVideo,
  path:"/LetsWatch/EditVideo/:id"},
LetsWatchCategories:{
  component:LetsWatchCategories,
  path:"/LetsWatch/VideoCategories"},
SplitViewInterface:{
 component:SplitViewInterface,
path:"/SplitViewInterface"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Videos4:{
 component:Videos4,
path:"/Videos4"},
StripeIntegration:{
 component:StripeIntegration,
path:"/StripeIntegration"},
AdminConsole3:{
 component:AdminConsole3,
path:"/aboutus"},
Library2:{
 component:Library2,
path:"/videopodcasts"},
AudioLibrary:{
  component:AudioLibrary,
 path:"/audiopodcasts"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
VideoAutoplay:{
 component:VideoAutoplay,
path:"/VideoAutoplay"},
TaskViews:{
 component:TaskViews,
path:"/TaskViews"},
Share:{
 component:Share,
path:"/Share"},
EventRegistration:{
 component:EventRegistration,
path:"/EventRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Download:{
 component:Download,
path:"/Download"},
LiveStreaming:{
 component:LiveStreaming,
path:"/LiveStreaming/:id"},
Calendar:{
 component:Calendar,
path:"/Calendar"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
ProjectTemplates:{
 component:ProjectTemplates,
path:"/ProjectTemplates"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Login:{
 component:EmailAccountLoginBlock,
path:"/Login"},
ProjecttaskTracking2:{
 component:ProjecttaskTracking2,
path:"/ProjecttaskTracking2"},
IcalendarSupport:{
 component:IcalendarSupport,
path:"/IcalendarSupport"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/User/:id"},
Search:{
 component:AdvancedSearch,
path:"/Search"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
Comments:{
 component:Comments,
path:"/Favourites"},
Gallery:{
 component:Gallery,
path:"/podcast"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
ProjectNotes:{
 component:ProjectNotes,
path:"/ProjectNotes"},
VideoManagement:{
 component:VideoManagement,
path:"/VideoManagement/:id"},


 
  Home: {
    component: HomePage,
    path: '/',
    exact: true
  },
  Blog: {
    component: BlogPage,
    path: '/Blog',
    exact: true
  },
  BlogArticle: {
    component: BlogArticle,
    path: '/BlogArticle/:id?',
    exact: true
  },
  TermsAndCondition: {
    component: TermsAndCondition,
    path: '/TermsAndCondition',
    exact: true
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy',
    exact: true
  },
  Faqs: {
    component: Faqs,
    path: '/Faqs',
    exact: true
  },
  Videos: {
    component: LetsWatchHome,
    path: '/Videos',
    exact: true
  },
  WatchVideo: {
    component: WatchVideo,
    path: '/WatchVideo/:id?',
    exact: true
  },
  OtherVideo: {
    component: OtherVideo,
    path: '/OtherVideo',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  static contextType = ProfileContext; 
   
  render() {
    const path = window.location.href.split('/')
    const defaultAnalytics = firebaseAPI.analytics();
    const userType = localStorage.getItem('userType');
    const userPermission = localStorage.getItem('userPermission');
    defaultAnalytics.logEvent('APP_Loaded');
    let disableHeaderFor = [
      'CourseDetails',
      'Dashboard',
      'lesson',
      'Tutorial'
    ]

    let disableFooterFor = [
      'Login',
      'signup',
      'Dashboard',
      'LetsTalk',
      'LetsRead',
      'LetsLearn',
      'LetsWatch',
      'LetsDiscover',
      'ForgotPassword',
      'UserList',
      'Help',
      'Tutorial'
    ]

    if(userType !== 'admin'){
      delete routeMap.UserList
    }

    if(userPermission === 'false'){
      delete routeMap.LetsDiscover
      delete routeMap.AddPeople
      delete routeMap.AddOrganisation 
      delete routeMap.AddFilm 
      delete routeMap.AddBook 
      delete routeMap.AllList
      delete routeMap.PeopleCategories 
      delete routeMap.OrgCategories
      delete routeMap.BookCategories
      delete routeMap.FilmCategories
      delete routeMap.EditBook
      // delete routeMap.EditCourse
      delete routeMap.EditFilm
      delete routeMap.EditOrganisation
    }

    console.log("ContextApp", this.context)
    const {isTokenInvalid, setTokenValidity} = this.context;

    return (
      <View style={{ height: '100vh' }}>
        {
          disableHeaderFor.includes(path[3]) ? null : <TopNav />
        }
        {WebRoutesGenerator({ routeMap })}
        {
          disableFooterFor.includes(path[3]) ? null : <Footer />
        }
        <ModalContainer />
        <TokenTimeOut open={isTokenInvalid} setOpen={setTokenValidity} />
      </View>
    );
  }
}

export default App;