import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  borderRadius: '8px'
};

const thumb = {
  display: 'inline-flex',
  borderRadius: '8px',
  marginBottom: 15,
  width: '184px',
  height: '184px',
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  borderRadius: '8px',
  position: 'relative'
};

const img = {
  display: 'block',
  width: '223px',
  minWidth: '223px',
  height: '100%',
  borderRadius: '8px'
};


function AddInstructorDropZone(props) {
  const [files, setFiles] = useState([]);
  const [source, setSource] = useState(props.comingFrom)
  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    accept: props.type === 'video' ? {
      'video/mp4': ['.mp4', '.MP4', '.mkv', '.mp3', '.MP3'],
    } :
    {
      'image/png': ['.png', '.PNG'],
      'image/jpeg': ['.jpeg', '.JPEG'],
      'image/jpg': ['.jpg', '.JPG'],
    }
    ,
    onDrop: acceptedFiles => {
      setSource('')
      props.addInstructorProfile(acceptedFiles);
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const removeProfilePic = () => {
    setFiles([])
    setSource('')
  }
  
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        {
          props.type == 'video' ?
          <video controls={false}>
            <source src={file.preview} type="video/mp4" />
          </video>
          :
          <img
            src={file.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => { URL.revokeObjectURL(file.preview) }}
          />
        }
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="container" style={{cursor: 'pointer'}}>
      <div
        style={{
          position: 'relative'
        }}
      >
        <aside style={thumbsContainer}>
          {
            source == 'edit' ?
            <div style={thumb} >
              <div style={thumbInner}>
                {
                  props.type == 'video' ?
                  <video controls={false}>
                    <source src={props.mainMediaUrl ? props.mainMediaUrl : 'https://minio.b64156.dev.eastus.az.svc.builder.cafe/sbucket/8h04ved3jv1k5rgea8j1da7ad8eu' } type="video/mp4" />
                  </video>
                  :
                  <img
                    src={props.mainMediaUrl}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(props.mainMediaUrl) }}
                  />
                }
              </div>
              <div
            style={{
              position: 'absolute',
              top: '37%',
              left: '30px'
            }}
          >
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
                  <div
                      style={{
                        background: '#fff',
                        borderRadius: '50%',
                      }}
                  >
                      <img 
                          style={{
                              marginRight: 'auto',
                              marginLeft: 'auto'
                          }}
                          width={'40px'}
                          src={require('../src/assets/uploadIcon.png')}
                      />
                  </div> 
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              top: '37%',
              left: '106px'
            }}
          >
            <div
                style={{
                  background: '#fff',
                  borderRadius: '50%',
                }}
            >
                <img 
                    style={{
                        marginRight: 'auto',
                        marginLeft: 'auto'
                    }}
                    width={'40px'}
                    src={require('../src/assets/delete.png')}
                    onClick={() => removeProfilePic()}
                />
            </div> 
          </div>
            </div>
            : thumbs
          }
          {!files.length ?
            null
          :
          <>
            <div
            style={{
              position: 'absolute',
              top: '37%',
              left: '30px'
            }}
          >
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
                  <div
                      style={{
                        background: '#fff',
                        borderRadius: '50%',
                      }}
                  >
                      <img 
                          style={{
                              marginRight: 'auto',
                              marginLeft: 'auto'
                          }}
                          width={'40px'}
                          src={require('../src/assets/uploadIcon.png')}
                      />
                  </div> 
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              top: '37%',
              left: '106px'
            }}
          >
            <div
                style={{
                  background: '#fff',
                  borderRadius: '50%',
                }}
            >
                <img 
                    style={{
                        marginRight: 'auto',
                        marginLeft: 'auto'
                    }}
                    width={'40px'}
                    src={require('../src/assets/delete.png')}
                    onClick={() => removeProfilePic()}
                />
            </div> 
          </div>
          </>
          }
        </aside>
      </div>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        {
          files.length || source == 'edit' ?
            null
          :
            <div 
                style={{
                    borderRadius: '8px',
                    border: '1px solid rgba(82,92, 229, 0.12)',
                    padding: '24px 172px 24px 171px',
                }}
            >
              <div
                  style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column'
                  }}
              >
                  <img 
                      style={{
                          marginRight: 'auto',
                          marginLeft: 'auto'
                      }}
                      width={'100px'}
                      src={require('../src/assets/uploadIcon.png')}
                  />
                  <span
                      style={{
                          color: '#353535',
                          fontFamily: 'Poppins-SemiBold',
                          fontSize: '20px',
                          textAlign: 'center',
                      }}
                  >
                      { props.type == 'video' ? 'Drag and drop your video files here to upload' : "Drag and drop your image files here to upload"}
                  </span>
                  <span
                      style={{
                          color: '#8991a4',
                          fontFamily: 'Poppins-Light',
                          fontSize: '16px',
                          textAlign: 'center',
                          marginTop: '15px'
                      }}
                  >
                      {props.type == 'video' ? 'Files supported - mp4, mkv.' : 'Files supported - png, jpg, jpeg.'}
                  </span>
                  <span
                    style={{
                      color: 'red',
                      fontFamily: 'Poppins-Light',
                      fontSize: '16px',
                      textAlign: 'center',
                      marginTop: '15px'
                  }}
                  >
                    {props.error}
                  </span>
              </div> 
            </div>

        }
      </div>
    </section>
  );
}

export default AddInstructorDropZone;