import { Box, Card, CardContent, Typography } from "@material-ui/core";
import React, {useState} from "react";

const ExperienceCard = (props: any) => {

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    return (
        <Card 
            style={{ 
                maxWidth: 345, 
                backgroundColor: isHovering ? "#ffbe63" : "#fff", 
                boxShadow: "0 2px 10px 0 rgba(130, 131, 144, 0.26)" 
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <CardContent>
                <Box sx={webStyle.title}>&ldquo;</Box>
                <Typography variant="body2" style={{fontFamily: "Poppins-Light", lineHeight: 1.67, color: "grey"}}>
                    {props.data.attributes.comment}
                </Typography>
                <Box style={{display: "flex", marginTop: "30px"}}>
                <Box style={{marginRight: "10px"}}>
                    <img style={{borderRadius: "50%"}} height="41px" width="41px" alt="client_pic" src={props.data.attributes.image} />
                </Box>
                <Box style={{display: "flex", flexDirection: "column"}}>
                    <Typography style={{fontFamily: "Poppins-SemiBold"}}>{props.data.attributes.name}</Typography>
                    <Typography variant="body2" style={{color: "grey", fontFamily: "Poppins-Light"}}>{props.data.attributes.profession ? props.data.attributes.profession : "Artist"}</Typography>
                </Box>
                </Box>
            </CardContent>
        </Card>
    )
}
export default ExperienceCard;

const webStyle = {
    title: {
        width: "0",
        height: "0",
        margin: "0 6px 80px 0",
        fontFamily: "Roboto",
        fontSize: "60px",
        fontWeight: "bold",
        textAlign: "center",
        color: "#F27024",
    },
}