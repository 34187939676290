import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Typography, Box } from '@material-ui/core';
import Divider from '../src/Divider';

// function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
//   event.preventDefault();
//   console.info('You clicked a breadcrumb.', event);
// }

export default function Breadcrumb(props: any) {
  return (
    // @ts-ignore
    <Box mt={2}>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/">
                <Typography
                    style={{
                        fontFamily: 'Poppins-Light',
                        color:'#F27024',
                        textDecoration: 'none'
                    }}
                >
                    {props.base}
                </Typography>
            </Link>
            {
                props.url1.base ?
                <Link color="inherit" href={'/'+ props.url1.link}>
                    <Typography
                        style={{
                            fontFamily: 'Poppins-Light',
                            textTransform: 'capitalize',
                            textDecoration: 'none',
                            color: props.url1.link ? '#F27024' : '',
                        }}
                    >
                        {
                            props.url1.base ? props.url1.base : ""
                        }
                    </Typography>
                </Link>
                : null
            }
            {
                props.url1.link ?
                <Link color="inherit" href={'/'+ props.url1.link}>
                    <Typography
                        style={{
                            fontFamily: 'Poppins-Light',
                            textTransform: 'capitalize',
                            textDecoration: 'none',
                            color: props.url2.path ? '#F27024' : '',
                        }}
                    >
                        {
                            props.url1.link
                        }
                    </Typography>
                </Link>
                : null
            }
            {
                props.url2.link ?
                <Link color="inherit" href={'/'+ props.url2.link}>
                    <Typography
                        style={{
                            fontFamily: 'Poppins-Light',
                            textTransform: 'capitalize',
                            textDecoration: 'none'
                        }}
                    >
                        {props.url2.path}
                    </Typography>
                </Link>
                : props.url2.path ? <Typography
                        style={{
                            fontFamily: 'Poppins-Light',
                            textTransform: 'capitalize',
                            textDecoration: 'none'
                        }}
                    >
                        {props.url2.path}
                    </Typography> :  null
            }
            
        </Breadcrumbs>
        {/* @ts-ignore */}
        <Box mt={2}>
            <Divider 
                mb={'0px'}
                bgColor="#e6e6e6"
            />
        </Box>
    </Box>
  );
}