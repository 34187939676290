import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@material-ui/core";
import React, { ComponentType, FunctionComponent, ReactNode, useEffect, useState } from "react";
import { articleAuthorImg, blogImg1 } from "../../blocks/ContentManagement/src/assets";
import { BlogType, ReviewType, SingleBlogResponse } from "../../blocks/ContentManagement/src/Interfaces.web";
import axios from 'axios'
let config = require('../../framework/src/config')
import {
    makeStyles,
    createStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import { BlogComponentsType } from "../../blocks/dashboard/src/Interfaces.web";
import { fullDateFormatter } from "../../blocks/ContentManagement/src/BlogPage.web";
export interface SingleBlogType {
    id: string;
    type: string;
    attributes: {
        id: number;
        title: string;
        subtitle: string;
        instructor_name: string;
        instructor_id: number;
        cover_image: string;
        category: {
            id: number,
            name: string,
            created_at: string,
            updated_at: string
        };
        tag: string;
        status: string;
        created_at: string;
        reviews: ReviewType[];
        components: BlogComponentsType[];
    }

}
export interface ResponseBlog {
    blog: SingleBlogType,
    related_articles: SingleBlogType[]
}

interface Prop {
    previewId: string;
}

const PreviewBE = ({ previewId }: Prop) => {
    const classes = useStyles();
    const [data, setData] = useState<ResponseBlog>(
        {
            blog: {
                id: "",
                type: "",
                attributes: {
                    id: 0,
                    title: '',
                    subtitle: "",
                    instructor_name: '',
                    cover_image: '',
                    reviews: [],
                    instructor_id: 0,
                    category: {
                        id: -1,
                        name: '',
                        created_at: '',
                        updated_at: ''
                    },
                    tag: "",
                    status: "",
                    created_at: "",
                    components: []
                }
            },
            related_articles: []
        },
    );
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);
    const getSingleBlog = async (id: any) => {
        setLoader(true);
        await axios
            .get(config.baseURL + `/bx_block_posts/blogs/${previewId}?dashboard=true`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token") as string,
                },
            })
            .then((response: any) => {
                setLoader(false);
                setData(response.data.data);
                setErrorMsg("");

            })
            .catch((error: any) => {
                setLoader(true)
                setErrorMsg(error);
            });
    }

    useEffect(() => {
        getSingleBlog(previewId)
    }, [])

    console.log("Single blog from preview Be", data);

    return (
        <>
            {
                loader ? <Typography style={{ fontFamily: "Poppins-SemiBold", width: "100%", textAlign: "center" }}>Loading...</Typography>
                    :
                    errorMsg ? <Typography style={{ fontFamily: "Poppins-SemiBold", width: "100%", textAlign: "center" }}>{errorMsg}</Typography>
                        :
                        <Box style={{ overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}>
                            <Box style={{
                                backgroundImage: `url(${data?.blog?.attributes?.cover_image})`
                            }} className={classes.headerBg}>
                                <Box className={classes.overlay}>
                                    <Box className={classes.mainWrapper}>
                                        <Typography style={{ fontSize: "3rem", fontFamily: "Poppins-Bold", textAlign: "center" }} gutterBottom>{data?.blog?.attributes?.title}</Typography>
                                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1rem" }}>
                                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: "2rem" }}>
                                                <Box style={{ marginRight: "10px" }}>
                                                    <img style={{ borderRadius: "50%" }} height="41px" width="41px" alt="client_pic" src={articleAuthorImg} />
                                                </Box>
                                                <Box style={{ display: "flex", flexDirection: "row" }}>
                                                    <Typography style={{ fontFamily: "Poppins-SemiBold" }}>{data?.blog?.attributes?.instructor_name}</Typography>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography style={{ fontFamily: "Poppins-Light" }}>{fullDateFormatter(data?.blog?.attributes?.created_at)}</Typography>
                                            </Box>
                                        </Box>
                                        <Typography style={{ fontFamily: "Poppins-Light", fontSize: "1rem", textAlign: "center", padding: "0 25%", color: "#8991a4" }} gutterBottom>{data?.blog?.attributes?.subtitle === null ? "Subtitle is not present" : data?.blog?.attributes?.subtitle}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {
                                data?.blog?.attributes?.components?.map((single: BlogComponentsType) => (
                                    <div key={single?.id}>
                                        {single?.attributes?.heading?.text ? <Typography style={{ fontFamily: "Poppins-SemiBold", fontSize: "2rem" }}>{single.attributes.heading.text}</Typography> : ""}
                                        {single?.attributes?.sub_heading?.text ? <Typography style={{ fontFamily: "Poppins-SemiBold", fontSize: "1.5rem" }}>{single?.attributes?.sub_heading?.text}</Typography> : ""}
                                        {single.attributes.paragraph.text ? <Typography style={{ fontFamily: "Poppins-Light", fontSize: "1rem", margin: "5px 0 15px 0" }}>{single.attributes.paragraph.text}</Typography> : ""}
                                        {single.attributes.images.length ?
                                            <>
                                                <Grid container spacing={2}>
                                                    {
                                                        single.attributes.images.map((img: any) =>
                                                        (
                                                            <Grid item sm={4} xs={6} key={img}>
                                                                <div>
                                                                    <img src={img} width="100%" height="100%" alt="no_img_found" />
                                                                </div>
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                                <Typography style={{ fontFamily: "Poppins-Light", fontSize: "14px", textAlign: "center", color: "grey", margin: "10px" }}>{single.attributes.caption.text}</Typography>
                                            </>

                                            : ""}
                                    </div>
                                ))
                            }
                        </Box>
            }
        </>
    )
}
export default PreviewBE;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerBg: {
            width: "100%",
            height: "auto",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "local",
            backgroundSize: "cover",
            backgroundPosition: "center",
            // backdropFilter: "blur(5px) saturate(100%)"
        },
        mainWrapper: {
            fontFamily: 'Poppins',
            // padding: '5% 8%',
            // [theme.breakpoints.down("xs")]: {
            //     padding: '5% 12%'
            // },
            justifyContent: "center",
        },
        overlay: {
            background: "rgba(50, 70, 80, 0.7)",
            overflow: "hidden",
            // height: "100%"
        },
    })
);