import React from "react";

// Customizable Area Start
import { 
  Container, 
  Box,
  Link,
  Typography,
  Breadcrumbs,
  Grid,
  Button
} from '@material-ui/core';
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { palette } from "../../../components/src/theme";
import CustomLoader from "../../../components/src/CustomLoader";
// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
  configJSON
} from "./CustomisableUserProfilesController";

class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getBreadCrumb = () => {
    return (
      <>
        <Breadcrumbs>
          <Link
            href="/"
          >
            <Typography
                style={{
                    fontFamily: 'Poppins-Light',
                    color:'#F27024',
                    textDecoration: 'none'
                }}
            >
                Home
            </Typography>
          </Link>
          <Link
            href="/LetsDiscovers/user"
          >
            <Typography
                style={{
                    fontFamily: 'Poppins-Light',
                    color:'#F27024',
                    textDecoration: 'none'
                }}
            >
                Let's Discover
            </Typography>
          </Link>
          <Typography
              style={{
                  fontFamily: 'Poppins-Light',
                  color:'rgba(0, 0, 0, 0.54)',
                  textDecoration: 'none'
              }}
          >
              People
          </Typography>
        </Breadcrumbs>
      </>
    ) 
  }

  getOrg = (orgList: any) => {
    return (
      <>
        <div
          style={{
            marginTop: '30px'
          }}
        >
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
            >
                <div
                  className={this.props.classes.imgBookCard}
                >
                  <img 
                    src={orgList?.attributes?.image} 
                    className={this.props.classes.imgBook}
                  />
                </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={10}
            >
              <div>
                <Typography
                  className={this.props.classes.bookTitle}
                >
                  {orgList?.attributes?.name}
                </Typography>
              </div>
              <div>
                <Typography 
                  className={this.props.classes.bookAbout}
                >
                  {orgList?.attributes?.about}
                </Typography>
              </div>
              <div>
                  <Button
                    className={this.props.classes.linkButton}
                  >
                    <a 
                      href={orgList?.attributes?.website_url} 
                      target='_blank'
                      style={{
                        textDecoration: 'none',
                        color: palette.primary.main
                      }}
                    >
                      Go to website
                    </a>
                  </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  getBooks = (bookList: any) => {
    return (
      <>
        <div>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
          >
            <div>
              <Typography 
                className={this.props.classes.secHead}
              >
                Books
              </Typography>
            </div>
          </Grid>
          {
            bookList?.length ? bookList?.map((item: any, index: any) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid
                    container
                    spacing={4}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={2}
                    >
                        <div
                          className={this.props.classes.imgBookCard}
                        >
                          <img 
                            src={item?.attributes?.image} 
                            className={this.props.classes.imgBook}
                          />
                        </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={9}
                      md={10}
                    >
                      <div>
                        <Typography
                          className={this.props.classes.bookTitle}
                        >
                          {item?.attributes?.title}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          className={this.props.classes.bookAuthor}
                        >
                          by {this.state.userDetails?.attributes?.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography 
                          className={this.props.classes.bookAbout}
                        >
                          {item.attributes.about}
                        </Typography>
                      </div>
                      <div>
                        <Button
                          className={this.props.classes.linkButton}
                        >
                          <a
                            href={item.attributes.url}
                            target='_blank'
                            style={{
                              textDecoration: 'none',
                              color: palette.primary.main
                            }}
                          >
                            Link To Buy
                          </a>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })
            : <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                >
                  <span
                    className={this.props.classes.loading}
                  >
                    No books found!
                  </span>
                </Grid>
              </Grid>
          }
        </Grid>
        </div>
      </>
    )
  }

  getFilms = (filmList: any) => {
    const {classes} = this.props;
    return (
      <>
        <div>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
          >
            <div>
              <Typography 
                className={this.props.classes.secHead}
              >
                Films
              </Typography>
            </div>
          </Grid>
          {
            filmList?.length ? filmList?.map((item: any, index: any) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid
                    container
                    spacing={4}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={2}
                    >
                        <div
                          className={classes.imgBookCard}
                        >
                          <img 
                            src={item.attributes.image} 
                            className={classes.imgBook}
                          />
                        </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={9}
                      md={10}
                    >
                      <div>
                        <Typography
                          className={classes.bookTitle}
                        >
                          {item.attributes.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography 
                          className={classes.bookAbout}
                        >
                          {item.attributes.about}
                        </Typography>
                      </div>
                      <div>
                        <Button
                          className={classes.linkButton}
                        >
                          <a
                            href={item.attributes.url}
                            target='_blank'
                            style={{
                              textDecoration: 'none',
                              color: palette.primary.main
                            }}
                          >
                            Watch Here
                          </a>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })
            : <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                >
                  <span
                    className={classes.loading}
                  >
                    No films found!
                  </span>
                </Grid>
              </Grid>
          }
        </Grid>
        </div>
      </>
    )
  }

  getUserProfile = (userDetails: any) => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid 
            item
            xs={12}
            sm={12}
            md={12}
          >
            <div
              className={this.props.classes.profileWrap}
            >
              <div
                className={this.props.classes.profilePicWrap}
              >
                <img 
                  src={userDetails?.attributes?.image}
                  className={this.props.classes.profilePic}
                  alt="Profile Pic"
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
          >
            <div
              style={{
                marginTop: '30px'
              }}
            >
              <Typography
                className={this.props.classes.profileName}
              >
                {userDetails?.attributes?.name}
              </Typography>
            </div>
            <div
              style={{
                marginTop: '20px'
              }}
            >
              <Typography
                className={this.props.classes.profileAbout}
              >
                {userDetails?.attributes?.about}
              </Typography>
            </div>
            <div>
              {
                userDetails?.attributes?.organisation ?
                  this.getOrg(userDetails?.attributes?.organisation)
                : null
              }
            </div>
            <div
              style={{
                marginTop: '30px'
              }}
            >
              {
                this.getBooks(userDetails?.attributes?.books)
              }
            </div>
            <div
              style={{
                marginTop: '30px'
              }}
            >
              {
                this.getFilms(userDetails?.attributes?.films)
              }
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {
          this.state.loading ? 
            <CustomLoader 
              open={this.state.loading}
            />
          : null
        }
        {/* @ts-ignore */}
        <Box
          className={this.props.classes.parentContainer}
        >
          <Container>
            {
              this.getBreadCrumb()
            }
            {
              this.getUserProfile(this.state.userDetails)
            }
          </Container>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTheme(
  withStyles((theme: Theme) =>
      createStyles({
        parentContainer: {
          padding: '20px'
        },
        profileWrap: {
          display: 'flex',
          justifyContent: 'center'
        },
        profilePicWrap: {
          minWidth: '252px',
          maxWidth: '252px',
          minHeight: '252px',
          maxHeight: '252px',
          borderRadius: '50%',
          overflow: 'hidden',
          marginTop: '30px',
        },
        profilePic: {
          minWidth: '252px',
          maxWidth: '252px',
          minHeight: '252px',
          maxHeight: '252px',
        },
        profileName: {
          fontFamily: 'Poppins-Bold',
          fontSize: '28px',
          color: '#1c1c1c'
        },
        profileAbout: {
          fontFamily: 'Poppins-Light',
          color: '#545454',
          fontSize: '24px'
        },
        imgBookCard: {
          boxShadow: '0 2px 10px 0 rgba(130, 131, 144, 0.26)',
          height: '283px',
          borderRadius: '16px',
          overflow: 'hidden'
        },
        imgBook: {
          width: '100%',
          maxHeight: '283px',
          minHeight: '283px'
        },
        bookTitle: {
          fontSize: '24px',
          fontFamily: 'Poppins-Bold',
          color: '#353535'
        },
        bookAuthor: {
          color: palette.primary.main,
          fontSize: '16px',
          fontFamily: 'Poppins-Light'
        },
        bookAbout: {
          fontSize: '16px',
          fontFamily: 'Poppins-Light',
          marginTop: '10px',
          minHeight: '130px',
          maxHeight: '130px',
          color: '#6b7180',
          display: '-webkit-box',
          width: '100%',
          lineClamp: 4,
          boxOrient: 'vertical',
          overflow: 'hidden'
        },
        linkButton: {
          border: `1px solid ${palette.primary.main}`,
          width: '228px',
          height: '40px',
          color: palette.primary.main,
          fontFamily: 'Poppins-Light'
        },
        secHead: {
          fontSize: '24px',
          fontFamily: 'Poppins-Bold',
          marginTop: '30px'
        },
        loading: {
          display: 'flex',
          justifyContent: 'center',
          fontSize: '24px',
          fontFamily: 'Poppins-SemiBold',
          textAlign: 'center'
        },
      }))(CustomisableUserProfiles)
)
// Customizable Area End
