export const palette = {
    primary: {
      main: "#F27024",
      text: "#FAD6B1"
    },
    course:{
      primary: '#ffffff',
      secondary: '#fffbe6',
      para: '#e6e6e6'
    },
    secondary: {
      main: "#FFFBE6",
    },
    fontFamily: {
      main: "Poppins-Light"
    }
  };