Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LiveStreaming";
exports.labelBodyText = "LiveStreaming Body";
exports.getEventDetailsApi = "bx_block_content_management/live_streams";
exports.getStreamTokenApi = "/bx_block_content_management/live_streams/start_live_stream";
exports.btnExampleTitle = "CLICK ME";
exports.endStreamApi = "/bx_block_content_management/live_streams/end_live_stream";
exports.startRecording = "bx_block_content_management/live_streams/start_live_stream_recording?id=";
// Customizable Area End