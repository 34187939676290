import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../blocks/ContentManagement/assets/blogCarouselStyle.css"
import { makeStyles, createStyles, Typography, Grid, Box, CardContent, useMediaQuery, IconButton } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { CarouselAuthorImg, PlaceholderImage } from "../../blocks/ContentManagement/src/assets";
import { BlogType } from "../../blocks/ContentManagement/src/Interfaces.web";
import { fullDateFormatter } from "../../blocks/ContentManagement/src/BlogPage.web";
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
export interface CarouselData{
    carouselDt: BlogType[]
}




const SliderPrevArrow = ({ ...props }) => {
    const { style, onClick } = props
    return (
        <div
            className="slick-prev"
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <IconButton
                style={{
                    border: '2px solid #000',
                    borderRadius: '50%',
                    padding: '0px'
                }}
            >
                <KeyboardArrowLeftRoundedIcon
                    style={{
                        fontSize: '1.6em',
                        color: '#000'
                    }}
                />
            </IconButton>
        </div>
    );
};
const SliderNextArrow = ({ ...props }) => {
    const { style, onClick } = props
    return (
        <div
            className="slick-next"
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <IconButton
                style={{
                    border: '2px solid #000',
                    borderRadius: '50%',
                    padding: '0px'
                }}
            >
                <KeyboardArrowRightRoundedIcon
                    style={{
                        fontSize: '1.6em',
                        color: '#000'
                    }}
                />
            </IconButton>
        </div>
    );
};

const BlogCarousel = ({carouselDt}: CarouselData) => {
    const isSmallScreen = useMediaQuery('(max-width: 600px)');
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                initialSlide: 0,
                
              }
            },
            {
              breakpoint: 600,
              settings: {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                arrows: false,
                adaptiveHeight: true,
                slidesToScroll: 1,
                initialSlide: 0,
                
              }
            },
            {
              breakpoint: 480,
              settings: {
                dots: true,
                infinite: true,
                speed: 500,
                arrows: false,
                adaptiveHeight: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0,
                
              }
            }
          ]
    }

    const classes = useStyles();
    return (
        <div>
            {/* @ts-ignore */}
            <Slider {...settings}>
                {
                    carouselDt.map((data: BlogType) => <div key={data.id}>
                        <Grid style={{width: "100%", display: isSmallScreen ? "block" : "flex", height: "100%", alignItems: "flex-end" }}>
                            {/* <Grid item xs={12} sm={5} className="imgHeightWidth"> */}
                                <img src={data?.attributes?.cover_image || PlaceholderImage} className={classes.imgBorder} style={{ width: isSmallScreen ? "100%" : "40%", height: isSmallScreen ? "35vh" : "100%" }} />
                            {/* </Grid> */}
                            <Grid item xs={12} sm={7} className="blogCarouselContentStyle" style={{width: "100%", display: isSmallScreen ? "block" : "flex", alignItems: "flex-start", lineHeight: "inherit"}} >
                                {/* @ts-ignore */}
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent style={{ flex: '1 0 auto' }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", fontSize: "12px", marginBottom:isSmallScreen ? "0" : "1.5rem" }}>
                                            <Typography style={{ color: "#F27024", marginRight: "2rem", fontFamily: "Poppins-SemiBold" }} gutterBottom>LIFESTYLE</Typography>
                                            <Typography style={{ color: "grey", fontFamily: "Poppins-Light" }}>{fullDateFormatter(data?.attributes?.created_at)}</Typography>
                                        </div>
                                        <Typography style={{fontFamily: "Poppins-SemiBold", fontSize: isSmallScreen ? "1.5rem" : "2.5rem", lineHeight: "1.4", marginBottom:isSmallScreen ? "0.5rem" : "1.5rem"}}>{data?.attributes?.title}</Typography>
                                        <Typography style={{ color: "grey", fontSize:isSmallScreen ? "1rem" : "1.5rem", fontFamily: "Poppins-Light", marginBottom:isSmallScreen ? "0.5rem" : "1.5rem" }}  gutterBottom>{data?.attributes?.subtitle === null ? "Subtitle is not present" : data?.attributes?.subtitle.substring(0, 100) + '...'}</Typography>
                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                                                <img style={{ borderRadius: "50%" }} height="41px" width="41px" alt="client_pic" src={CarouselAuthorImg} />
                                                <Typography style={{fontFamily: "Poppins-SemiBold", color: "#353535"}}>{data?.attributes?.instructor_name}</Typography>
                                        </div>
                                    </CardContent>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>)
                }

            </Slider>
        </div>
    )

}

export default BlogCarousel;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        padding: {
            [theme.breakpoints.up("lg")]: {
                paddingTop: "1.6rem",
            },
        },
        imgBorder:{
            borderRadius: "10px 0 0 10px",
            [theme.breakpoints.down("xs")]: {
                borderRadius: "10px 10px 0 0",
            },
        },
        mainWrapper: {
            display: "flex",
            [theme.breakpoints.down("xs")]: {
                display: "block",
            },
            padding: "1.5rem",
            // boxShadow: "0 0 7px 0 rgba(0, 0, 0, 0.12)"
        },
        limitedText: {
            overflowWrap: "break-word",
            display: "-webkit-box",
            height: "40px",
            fontSize: "14px",
            textAlign: "left",
            whiteSpace: "break-spaces",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical"
          }
    })
);
