import React, {useEffect} from "react";
import {
  Button
} from '@material-ui/core';

import { gapi } from "gapi-script";
const axios = require('axios').default;


export default function SyncEvent(props) {
  const google = window.google;
  const userType = localStorage.getItem('userType');
  const baseUrl = window.location.href.split('/', 3).pop();
  
  const CLIENT_ID = '1063462006546-b759lhemgrrtqko6rqn947ltn3e4jnn1.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyCW7lWXyQhay1oOP55583MJeTi8YuhLoxU';
  const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
  const SCOPES = "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.events";

  const accessToken = localStorage.getItem('access_token');
  const expiresIn = localStorage.getItem('expires_in');

  var gapiInited = false, gisInited = false, tokenClient;

  useEffect(() => {
    //const expiryTime = new Date().getTime() + expiresIn * 1000;
    gapiLoaded()
    gisLoaded()
  }, [])

  function gapiLoaded() {
    gapi.load('client', initializeGapiClient);
  }

  async function initializeGapiClient() {
    await gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    gapiInited = true;

    if (accessToken && expiresIn) {
      gapi.client.setToken({
        access_token: accessToken,
        expires_in: expiresIn,
      });
      // listUpcomingEvents();
    }
  }

  function gisLoaded() {
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: ''
    });

    gisInited = true;
  }

  //Enables user interaction after all libraries are loaded.

  function handleAuthClick() {
    gapiLoaded()
    gisLoaded()
    tokenClient.callback = async (resp) => {
      if (resp.error) {
        throw (resp);
      }

      const { access_token, expires_in } = gapi.client.getToken();
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('expires_in', expires_in)
      axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${access_token}`)
      .then((res)=> {
        localStorage.setItem('gUserEmail', res.data.email)
        localStorage.setItem('gUserName', res.data.name)
        addManualEvent()
      })
    };

    if (!(accessToken && expiresIn)) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  //Sign out the user upon button click.
  
  function addManualEvent(){
    const userName = localStorage.getItem('gUserName')
    const userEmail = localStorage.getItem('gUserEmail')
    var event = {
      'kind': 'calendar#event',
      'summary': props.eventDetails.attributes.title,
      'location': '',
      'description': props.eventDetails.attributes.description,
      'start': {
        'dateTime': props.eventDetails.attributes.date,
        'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      'end': {
        'dateTime': props.eventDetails.attributes.end_date,
        'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      'recurrence': [
        'RRULE:FREQ=DAILY;COUNT=1'
      ],
      'attendees': [
        {'email': userEmail,'responseStatus':'needsAction', 'organizer': true, 'displayName': userName},
      ],
      'reminders': {
        'useDefault': true,
      },
      'source': {
        'url': `https://${baseUrl}/LiveStreaming/${props.eventDetails.id}`
      },
      "guestsCanSeeOtherGuests": true,
      'anyoneCanAddSelf': true,
      'guestsCanModify': true,
      'guestsCanInviteOthers': true
    }


      var request = gapi.client.calendar.events.insert({'calendarId': 'primary','resource': event,'sendUpdates': 'all'});
      request.execute((event)=>{
          props.syncInit(event, props.eventDetails.id)
          localStorage.removeItem('access_token')
          localStorage.removeItem('gUserName')
          localStorage.removeItem('gUserEmail')
      },(error)=>{
        console.log(error)
        props.error()
      });
  }

  function startSync() {
    if(accessToken){
      addManualEvent()
    } else {
      handleAuthClick()
    }
  }

  return (
    // <button onClick={startSync}>Sync to Calendar</button>
    <Button
        disabled={props.disabled}
        className={props.classes}
        onClick={startSync}
    >
        Sync to calendar
    </Button>
  )
}
