Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CommunityForum";
exports.labelBodyText = "CommunityForum Body";

exports.btnExampleTitle = "CLICK ME";
exports.categoryUrl = "bx_block_categories/categories";
exports.getPeopleListApi = "bx_block_dashboard/people";
exports.getBookListApi = "bx_block_dashboard/books";
exports.getOrgListApi = "bx_block_dashboard/organisations";
exports.getFilmListApi = "bx_block_dashboard/films";
exports.updateFavouriteApi = "bx_block_library2/courses/favourite";
// Customizable Area End