import { useMediaQuery, Typography } from "@material-ui/core";
import React from "react";

interface IResponsiveFont {
    type: string,
    title: string,
}

const ArticlesResponsiveFont = ({ type, title }: IResponsiveFont) => {
    const isMobileScreen = useMediaQuery('(max-width: 768px)');

    let displayedTitle;
    if (isMobileScreen) {
        displayedTitle = title.length > 15 ? title.slice(0, 13) + '...' : title;
    } else {
        displayedTitle = title;
    }

    return (
        <>
            {
                type === "link" ?
                    <span style={{ color: "#8991a4" }}>
                        {
                            displayedTitle
                        }
                    </span>
                    :
                    <Typography style={{
                        fontSize: isMobileScreen ? "1.8rem" : "3rem",
                        fontFamily: "Poppins-Bold",
                        textAlign: "center"
                    }}
                        gutterBottom>{title}</Typography>}
        </>
    )
}
export default ArticlesResponsiveFont;
