import { Box, Button, CircularProgress, FormControl, Grid, useMediaQuery, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import React, { useLayoutEffect } from "react";
import * as Yup from "yup";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import { Formik } from "formik";
import ReviewDescription from "./ReviewDescription";
import { ReviewType } from "../../blocks/ContentManagement/src/Interfaces.web";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
interface ReviewDesc {
    reviewDesc: ReviewType[];
    handleReviewSubmit: (values: any) => void;
    sorting: (values: string) => void;
    reviewLoading: boolean;
    reviewStatus: boolean;
    isCoursesReview: boolean
}

export function useWindowSize() {
    const [size, setSize] = React.useState([0, 0])
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight])
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, [])
    return size;
}

const formikErrorMessage = (formik: any, fieldName: any) => {
    return (formik.touched[fieldName] && formik.errors[fieldName]
        ? <div style={{ color: "red", fontFamily: "Poppins-Light", margin: "3px 0" }}>{formik.errors[fieldName]}</div>
        : null);
}

const formikErrorClassCheck = (formik: any, fieldName: any) => {
    return `form-control ${formik.errors[fieldName] ? 'is-invalid' : formik.touched[fieldName] && !formik.errors[fieldName] ? 'is-valid' : ''}`;
}


const Reviews = ({ reviewDesc, handleReviewSubmit, sorting, reviewLoading, reviewStatus, isCoursesReview = false }: ReviewDesc) => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            formGrid: {
                [theme.breakpoints.up("md")]: {
                    marginRight: 40,
                },
            },
            padding: {
                [theme.breakpoints.up("lg")]: {
                    paddingTop: "1.6rem",
                },
            },
            mainWrapper: {
                display: "flex",
                [theme.breakpoints.down("xs")]: {
                    display: "block",
                },
                [theme.breakpoints.between("md", "md")]: {
                    display: "block",
                    justifyContent: "center"
                },
                padding: "1.5rem",
                boxShadow: "0 0 7px 0 rgba(0, 0, 0, 0.12)"
            },
            input: {
                width: "100%",
                height: "100%",
                border: isCoursesReview ?  "1px solid #575b64" : "1px solid rgba(82,92, 229, 0.12)",
                borderRadius: "7px",
                textDecoration: "none",
                fontSize: "1em",
                fontFamily: "Poppins-Light",
                backgroundColor: isCoursesReview ?  "#1f1f1f" : "white",
                color: isCoursesReview ? "#fff !important" : '#000 !important',
                padding: "5px 1rem", // 
                "& > .MuiInputBase-input": {
                    height: isSmallScreen ? "80%" : "100%",
                },
            },
            whiteBorder: {
                width: "100%",
                height: "1px",
                // margin: "0 0 45px",
                backgroundColor: isCoursesReview ? "#575b64" : '#e6e6e6'
            },
            customSelectWrapper: {
                position: "relative",
                width: isSmallScreen ? "205px" : "300px",
                fontFamily: "Poppins-Light",
                boxShadow: isCoursesReview ? 'none !important' : "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important",
                backgroundColor:  isCoursesReview ? "#1f1f1f !important" : "#fff !important",
                borderColor: isCoursesReview ? "#575b64 !important" : 'none !important',
                border: isCoursesReview ? "1px solid #575b64": 0,
                paddingLeft: '8px',
                borderRadius: isCoursesReview ?  "5px 5px 5px 5px" : "5px 2px 2px 5px",
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down('xs')]: {
                    padding: '0 0 2px 5px'
                },
                // marginTop: windowWidth <= 599 ? "12px" : "0",
            },
            menuItemStyle: {
                textAlign: "right",
                color: '#000 !important',
                backgroundColor: '#ffffff',
            },
            customArrow: {
                position: "absolute",
                top: 0,
                right: 0,
                display: "block",
                backgroundColor:isCoursesReview ? "#1f1f1f" : "#f4f5f7",
                borderLeft: "1px solid rgba(27, 31, 35, 0.15)",
                // borderRadius: "0 6px 6px 0",
                height: '100%',
                width: "3rem",
                pointerEvents: 'none',
            },
            select: {
                fontSize: "14px",
                fontFamily: "Poppins-SemiBold",
                backgroundColor : isCoursesReview ? "#1f1f1f" : 'none',
                "& .MuiSelect-icon": {
                    zIndex: 1,
                    right: ".7rem",
                    color: isCoursesReview ? "#8991a4" : '#000',
                },
                "& .MuiSelect-select": {
                    color: isCoursesReview ? "#fff !important" : '#000 !important',
                    backgroundColor: isCoursesReview ? "#1f1f1f !important" : '#fff !important',
                    paddingLeft: isCoursesReview ? "10px" : '0',
                },
            },
        })
    );

    const classes = useStyles();
    const [sort, setSort] = React.useState('latest_first');

    const handleSortChange = (event: any) => {
        setSort(event.target.value as string);
        sorting(event.target.value as string)
    };

    const emailReg = new RegExp("[a-z0-9._%+-]+@[a-z0-9]+.[a-z]{2,3}$");

    const dynamicFontIcon = () => {
        return (
            <KeyboardArrowDownIcon 
            style={{ color: isCoursesReview ? '#fff' : 'none'}}
            />
        )
    }

    return (
        <Box style={{ border: isCoursesReview ? "1px solid #575b64" : "1px solid rgba(27, 31, 35, 0.15)", borderRadius: "10px" }}>
            <Box style={{ borderRadius: "10px" }}>
                <Box>
                    <Box style={{ display: 'flex', borderRadius: "10px 10px 0 0", backgroundColor: isCoursesReview ? "#1f1f1f" : "#f4f5f7", justifyContent: "space-between", alignItems: "center", height: isSmallScreen ? "7vh" : "4vh", padding:isSmallScreen ? "1rem 1rem 1rem 1rem" : "1rem 1.5rem 1rem 1.5rem" }}>
                        <Typography style={{ color: isCoursesReview ? "#e6e6e6" : "#000", fontFamily: "Poppins-Light" }}>Reviews({reviewDesc?.length})</Typography>
                        <Box className={classes.customSelectWrapper}>
                            <Typography style={{ width: "87px", color: 'grey', fontSize: isSmallScreen ? "11px" : "14px" }}>Sort By :&nbsp;</Typography>
                            <FormControl style={{minWidth: "150px", width: "100%" }}>
                                <Select
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={sort}
                                    className={classes.select}
                                    label="Sort"
                                    onChange={handleSortChange}
                                    disableUnderline={true}
                                    IconComponent={KeyboardArrowDownIcon}
                                >
                                    <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "Poppins-Light" }} value={"oldest_first"}>Oldest</MenuItem>
                                    <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "Poppins-Light" }} value={"latest_first"}>Recent</MenuItem>
                                </Select>
                                <span className={classes.customArrow}></span>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box className={classes.whiteBorder} />
                    <Box style={{ overflowY: "scroll", overflowX: "hidden", height: isSmallScreen ? "40vh" : "37vh" }}>
                        <Grid
                            container
                            spacing={3} alignItems="center" justifyContent="center" style={{ padding: isSmallScreen ? "0 1rem" : "0 1.5rem" }}>
                            {
                                reviewLoading ? <Box style={{ textAlign: "center", margin: "1.5rem 0" }}> <CircularProgress /> </Box> : reviewDesc?.map((data: any) =>
                                    <Grid item lg={12} xs={12} sm={12} md={12} key={data?.id}>
                                        <ReviewDescription isCoursesReview={isCoursesReview} reviewData={data} />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Box>
                </Box>
                <Box style={{ borderRadius: "0 0 10px 10px", backgroundColor: isCoursesReview ? "#1f1f1f" : "#f4f5f7" }}>
                    <Formik
                        initialValues={{
                            fullName: localStorage.getItem("userName") ? localStorage.getItem("userName") : "",
                            workEmail: localStorage.getItem("userEmail") ? localStorage.getItem("userEmail") : "",
                            description: ""
                        }}
                        enableReinitialize
                        validationSchema={Yup.object().shape({
                            fullName: Yup.string()
                                .min(1, "Please enter minimum 1 alphabet")
                                .required("Full name is required")
                                .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
                                .max(200, "maximum limit 200"),
                            workEmail: Yup.string()
                                .matches(emailReg, 'Email is not valid')
                                // .email("Invalid email")
                                .required("Email is required")
                                .max(200, "maximum limit 200"),
                            description: Yup.string()
                                .min(3, "Please enter minimum 3 alphabets")
                                .max(200, "maximum limit 200")
                                .required("Description is required"),
                        })}
                        onSubmit={(values, { resetForm }) => {
                            handleReviewSubmit(values)
                            resetForm({
                                values: {
                                    fullName: "",
                                    workEmail: "",
                                    description: ""
                                }
                            })
                        }}
                    >
                        {formikProps => {
                            const {
                                values,
                                // setFieldValue,
                                handleChange,
                                handleSubmit,
                                touched,
                                errors
                            } = formikProps;
                            return (
                                <form onSubmit={handleSubmit} style={{ marginBottom: "0" }}>
                                    <Box style={{ padding: "1.7rem 1.5rem" }}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontFamily: "Poppins-Light",
                                                color: isCoursesReview ? "#e6e6e6" : '#353535',
                                                fontSize:
                                                    isSmallScreen
                                                        ? "18px"
                                                        : "22px",
                                                marginBottom: "2rem",
                                                // color: "#353535"
                                            }}>Give your feedback</Typography>
                                        <Box
                                            display={
                                                isSmallScreen ? "block" : "flex"
                                            }
                                            justifyContent="space-between"
                                            mb={2}
                                        >
                                            <Grid container spacing={3} justifyContent="space-between">
                                                <Grid item md={6} xs={12}>
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            fontFamily: "Poppins-Light",
                                                            fontSize:
                                                                isSmallScreen
                                                                    ? "14px"
                                                                    : "18px",
                                                            color: isCoursesReview ? "#e6e6e6" : '#353535',
                                                            marginBottom: "0.6rem"
                                                        }}

                                                    >
                                                        Full Name
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        name="fullName"
                                                        id="fullName"
                                                        value={values.fullName}
                                                        // value={localStorage.getItem("userName") ? localStorage.getItem("userName") : values.fullName}
                                                        onChange={handleChange}
                                                        className={formikErrorClassCheck(formikProps, "fullName")}
                                                        InputProps={{
                                                            className: classes.input,
                                                            disableUnderline: true
                                                        }}
                                                        style={{
                                                            fontSize: "16px",
                                                            fontFamily: "Poppins-Light",
                                                            height:
                                                                isSmallScreen
                                                                    ? "35px"
                                                                    : "auto",
                                                        }}
                                                        disabled={localStorage.getItem("userName") ? true : false}
                                                        placeholder={localStorage.getItem("userName") ? "" : "Erin Green"}
                                                    />
                                                    {formikErrorMessage(formikProps, "fullName")}
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            fontFamily: "Poppins-Light",
                                                            fontSize:
                                                                isSmallScreen
                                                                    ? "14px"
                                                                    : "18px",
                                                                    color: isCoursesReview ? "#e6e6e6" : '#353535',
                                                            marginBottom: "0.6rem"
                                                        }}
                                                    >
                                                        Email Address
                                                    </Typography>

                                                    <TextField
                                                        fullWidth
                                                        id="workEmail"
                                                        name="workEmail"
                                                        className={formikErrorClassCheck(formikProps, "workEmail")}
                                                        value={values.workEmail}
                                                        onChange={handleChange}
                                                        InputProps={{
                                                            className: classes.input,
                                                            disableUnderline: true
                                                        }}
                                                        style={{
                                                            fontFamily: "Poppins-Light",
                                                            fontSize: "16px",
                                                            height:
                                                                isSmallScreen
                                                                    ? "35px"
                                                                    : "auto",
                                                                    
                                                        }}
                                                        disabled={localStorage.getItem("userEmail") ? true : false}
                                                        placeholder={localStorage.getItem("userEmail") ? "" : "eringreen@gmail.com"}
                                                    />
                                                    {formikErrorMessage(formikProps, "workEmail")}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Grid container >
                                            <Grid item lg={12} xs={12}>
                                                <TextField
                                                    id="description"
                                                    name="description"
                                                    className={formikErrorClassCheck(formikProps, "description")}
                                                    value={values.description}
                                                    style={{ fontSize: "16px", fontFamily: "Poppins-Light" }}
                                                    multiline={true}
                                                    rows={7}
                                                    fullWidth
                                                    onChange={handleChange}
                                                    inputProps={{ maxLength: 201 }}
                                                    placeholder="Your description"
                                                    InputProps={{
                                                        className: classes.input,
                                                        disableUnderline: true
                                                    }}
                                                />
                                                {formikErrorMessage(formikProps, "description")}
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ marginTop: "1rem" }}>
                                            <Grid item xs={5} sm={3}>
                                                <Box>
                                                    <Button 
                                                        type="submit" 
                                                        style={{ 
                                                            backgroundColor: "#F27024", 
                                                            color: "white", 
                                                            padding: ".3rem 1.8rem", 
                                                            textTransform: "initial", 
                                                            fontFamily: "Poppins-SemiBold", 
                                                            borderRadius: '7px', 
                                                            fontSize: "16px" ,
                                                            width: '100%'
                                                        }}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <Box mt={1.5} display={reviewStatus ? "flex" : "none"}>
                                                    <Typography
                                                        variant="body2"
                                                        style={{ fontSize: "1rem", fontFamily: "Poppins-SemiBold", color: "green" }}
                                                    >
                                                        Review added successfully!
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </form>
                            );
                        }}
                    </Formik>
                </Box>
            </Box>
        </Box>
    )
}
export default Reviews;
