import { Card, CardContent, CardMedia, Typography, Box } from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import React from "react";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import { PlaceholderImage } from "../../blocks/ContentManagement/src/assets";
import { VideoType } from "../../blocks/VideoManagement/src/Interfaces.web";
export interface SingeArticleProps {
  item: VideoType;
  gotToSingleArticle: (id: string) => void;
}


const VideoCard = ({ item, gotToSingleArticle }: SingeArticleProps) => {
  const classes = useStyles();
  return (
    <Card style={{fontFamily:"Poppins", width: "100%", boxShadow: "0 2px 10px 0 rgba(130, 131, 144, 0.26)", borderRadius: "10px", cursor: "pointer" }}>
     <Box style={{position: "relative"}}>
     <CardMedia
        component="img"
        height="185"
        style={{objectFit: "cover", width: "100%"}}
        image={item?.attributes?.poster || PlaceholderImage}
        alt="Video card image"
      />
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          zIndex: 1,
          transform: 'translate(-50%, -50%)'
        }}
      >
        {/* @ts-ignore */}
        <Box
          style={{
            height: '50px',
            width: '50px',
            borderRadius: '50%',
            background: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <PlayArrowRoundedIcon
            style={{
              color: "red",
              fontSize: 30,
              cursor: 'pointer'
            }}
            onClick={() => gotToSingleArticle(item?.id)}
            // onClick={() => props.getVideo(item.id)}
          // fontSize="large"
          />
        </Box>
      </Box>
     </Box>
      <CardContent  style={{
          height: '100px'
        }}>
        <Typography gutterBottom style={{ fontFamily: "Poppins-Bold", fontSize: "1rem" }}>
          {item?.attributes?.title}
        </Typography>
        <Typography variant="body2" className={classes.limitedText} style={{fontFamily: "Poppins-Light"}}>
          {item?.attributes?.about}
        </Typography>
      </CardContent>
    </Card>
  )
}
export default VideoCard;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    limitedText: {
      overflowWrap: "break-word",
      display: "-webkit-box",
      // height: "40px",
      fontSize: "14px",
      textAlign: "left",
      whiteSpace: "break-spaces",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden"
    }
  })
);