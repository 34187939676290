import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../blocks/ContentManagement/assets/blogCarouselStyle.css"
import { Button, Typography, Box, CardContent, Card, useMediaQuery } from "@material-ui/core";
import { featureAuthorImg } from "../../blocks/ContentManagement/src/assets";
import { BlogType } from "../../blocks/ContentManagement/src/Interfaces.web";
import { dateFormatter, timeFormatter } from "../../blocks/ContentManagement/src/BlogPage.web";
export interface CarouselData {
    carouselDt: BlogType[];
    gotToSingleArticle: (id: string) => void;
}



const BlogFeatureCarousel = ({ carouselDt, gotToSingleArticle }: CarouselData) => {
    const isSmallScreen = useMediaQuery('(max-width: 600px)');
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]

    };

    return (
        <div style={{ width: "93%" }}>
            <Slider {...settings}>
                {
                    carouselDt.map((item: BlogType) =>
                        <Box key={item?.id}>
                            <Card onClick={() => gotToSingleArticle(item?.id)} style={{ height: !isSmallScreen ? 383 : '70vh', width: !isSmallScreen ? "95%" : "120%", cursor: "pointer", backgroundImage: `url(${item?.attributes?.cover_image})`, backgroundRepeat: "no-repeat", backgroundAttachment: "local", backgroundSize: "100% 100%", backgroundPosition: "center", borderRadius: "10px" }}>
                                <CardContent style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "90%" }}>
                                    <Box>
                                        <Button variant="contained" style={{ color: "#ffffff", backgroundColor: "#F27024", minWidth: "10vw", fontFamily: "Poppins-SemiBold", textTransform: "inherit" }}>Featured</Button>
                                    </Box>
                                    <Box>
                                        <Typography gutterBottom style={{ fontFamily: "Poppins-SemiBold", fontSize: "1.5rem", color: "#ffffff" }}>
                                            {item?.attributes?.title}
                                        </Typography>
                                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <Box style={{ marginRight: "10px" }}>
                                                    <img style={{ borderRadius: "50%" }} height="41px" width="41px" alt="client_pic" src={featureAuthorImg} />
                                                </Box>
                                                <Box style={{ display: "flex", flexDirection: "row" }}>
                                                    <Typography style={{ fontFamily: "Poppins-Light", color: "white", fontSize: "1rem" }}>{item?.attributes?.instructor_name}</Typography>
                                                </Box>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", }}>
                                                <Box style={{ marginRight: "10px" }}>
                                                    <Typography style={{ color: "white", fontFamily: "Poppins-Light", fontSize: '14px' }}>{dateFormatter(item?.attributes?.created_at)}</Typography>
                                                </Box>
                                                <Box >
                                                    <Typography style={{ color: "white", fontFamily: "Poppins-Light", fontSize: '14px' }}>{timeFormatter(item?.attributes?.created_at)}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    )
                }

            </Slider>
        </div>
    )

}

export default BlogFeatureCarousel;
