import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ReviewType, VideoType } from "./Interfaces.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  singleVideo: { video: VideoType, related_videos: VideoType[] };
  sigleVideoLoading: boolean;
  reviewLoading: boolean;
  videoId: string;
  reviewStatus: boolean;
  videoPlaying: boolean;
  played: any;
  seeking: boolean;
  allDuration: any;
  light: boolean;
  muted: boolean;
  pip: boolean;
  playbackRate: any;
  volume: any;
  loop: boolean;
  timeDisplayFormat: any;
  videoDetails: any;
  loading: boolean;
  openShare: boolean;
  quality: any;
  hideBtn: boolean;
  selectFavIcon: boolean;
  selectFavMessage: string;
  popUpAlert: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WatchVideoController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiGetSingleVideoCallId: string = "";
  apiPostReviewId: string = "";
  apiSelectFavouriteCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // getName(MessageEnum.NavigationToSingleArticle),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      sigleVideoLoading: false,
      reviewLoading: false,
      videoId: "none",
      singleVideo: {
        video: {
          id: "",
          type: "",
          attributes: {
            title: "",
            about: "",
            category: {
              id: -1,
              name: '',
              created_at: '',
              updated_at: '',
          },
            created_at: "",
            video: "",
            poster: "",
            view_count: 0,
            instructor: {
              name: ""
            },
            reviews: [],
            favourite: false,
          }
        },
        related_videos: []
      },
      reviewStatus: false,
      videoPlaying: false,
      played: 0,
      seeking: false,
      allDuration: 0,
      light: false,
      muted: false,
      pip: false,
      playbackRate: 1,
      volume: 1,
      loop: false,
      timeDisplayFormat: 'normal',
      videoDetails: {},
      loading: false,
      openShare: false,
      quality: 480,
      hideBtn: false,
      selectFavIcon: false,
      selectFavMessage: "",
      popUpAlert: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const id = message.getData(
        getName(MessageEnum.NavigationToSingleVideoId)
      );

      this.setState({
        videoId: id
      });
      this.getSingleVideoData('latest_first')

    } else {
      if (this.state.videoId === "none") {
        this.props.navigation.navigate("LetsWatchHome")
      }
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallIds = {
        [this.apiGetSingleVideoCallId]: this.responseGetSingleVideoData,
        [this.apiPostReviewId]: this.updateReviewInState,
        [this.apiSelectFavouriteCallId]: this.selectFavouriteResponse,
      };

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
        apiRequestCallIds[apiRequestCallId](responseJson);
      }
    }
    // Customizable Area End

  }
  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const path: any = window.location.href.split('/', 5).pop()
    if (path) {
      this.setState({ videoId: path})
      if (this.state.videoId) {
        this.getSingleVideoData('latest_first');
      }
    }
  }

  async componentWillUnmount() {
    localStorage.removeItem("videoId");
  }

  // Customizable Area End

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start
  responseGetSingleVideoData = (responseJson: any) => {
    if(responseJson && !responseJson.error){
      this.setState({
        sigleVideoLoading: false,
        reviewLoading: false,
        singleVideo: responseJson?.data,
      })
    }
  }

  updateReviewInState = (responseJson: any) => {
    this.setState((prevState) => {
      const updatedReviews = [...prevState.singleVideo.video.attributes.reviews, { ...responseJson.data }];
  
      return {
        singleVideo: {
          ...prevState.singleVideo,
          video: {
            ...prevState.singleVideo.video,
            attributes: {
              ...prevState.singleVideo.video.attributes,
              reviews: updatedReviews
            }
          },
          related_videos: responseJson.data.related_video
        },
        reviewStatus: true
      };
    });
  
    setTimeout(() => {
      this.setState({ reviewStatus: false });
    }, 5000);
  };
  

  getSingleVideoData = (sort: string) => {
    const path: any = localStorage.getItem('videoId') ? localStorage.getItem('videoId') : window.location.href.split('/', 5).pop()
    this.setState({ sigleVideoLoading: true, videoId: path })
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const userToken = localStorage.getItem("token") ? localStorage.getItem("token") : '';
        let header: any = '';
        if(userToken){
            header = {
                "Content-Type": configJSON.contentType,
                token: userToken,
            };
        } else {
            header = {
                "Content-Type": configJSON.contentType,
            };
        }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSingleVideoCallId = requestMessage.messageId;
    const hitURL = `${configJSON.getAllVideosApiURL}/${path + '?sort=' + sort}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReviewData = (sort: string) => {
    this.setState({ reviewLoading: true })
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSingleVideoCallId = requestMessage.messageId;
    const hitURL = `${configJSON.getAllVideosApiURL}/${this.state.videoId === "none" ? localStorage.getItem("videoId") + '?sort=' + sort : this.state.videoId}?sort=${sort}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleReviewSubmit = (values: any) => {
    const header = {
      token: localStorage.getItem("token")
    };

    const formData = new FormData();
    formData.append("review[video_id]", this.state.videoId);
    formData.append("review[full_name]", values.fullName);
    formData.append("review[email]", values.workEmail);
    formData.append("review[text]", values.description);
    formData.append("review[type]", "video");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostReviewId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postReviewUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  selectFavouriteRequest = (id: any, status: any) => {

    const data = {
      type: "BxBlockVideoManagement::Video",
      id: id,
      favourite: status
    }

    const token = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: token
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSelectFavouriteCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateFavouriteApi
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  }

  goToSingleVideo = (id: string) => {
    window.localStorage.setItem('videoId', id)
    this.setState({
      videoId: id
    }, () => {
      this.getSingleVideoData('latest_first')
      window.scrollTo({ top: 0, behavior: 'smooth' })
      // this.props.navigation.navigate('WatchVideo', {id:id})
    })
  }

  selectFavouriteResponse = (responseJson: any) => {
    if(responseJson.message === "Favourite Updated!"){
      this.setState({
        loading: false,
        selectFavMessage: this.state.selectFavIcon ? 'Added to your favourites!' : 'Removed from your favourites!',
        popUpAlert: true
      }, () => {
        this.getSingleVideoData('latest_first')
      })
    } else {
      this.setState({
        loading: false,
        selectFavMessage: 'Something went wrong!',
        popUpAlert: true
      }, () => {
        this.getSingleVideoData('latest_first')
      })
    }
  }

  toggleFavIcon = (id: any, status: any) => {
    this.selectFavouriteRequest(id, status)
    this.setState({
      selectFavIcon: status,

    })
  }

  cancelAlert = () => {
    this.setState({
      popUpAlert: false,
    })
  }

  // Customizable Area End

}
