import { Box, Button, Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import React, { FunctionComponent, ReactNode } from "react";
import { experienceImg } from "../../blocks/CarouselDisplay/src/assets";
import { getAge } from "../../blocks/ContentManagement/src/BlogPage.web";
import { ReviewType } from "../../blocks/ContentManagement/src/Interfaces.web";

interface ReviewData{
    reviewData: ReviewType;
    isCoursesReview: boolean
}

const ReviewDescription = ({reviewData,isCoursesReview }: ReviewData) => {
    return (
        <Box>
            <Box style={{margin: "0.5rem 0", }}>
                <Box>
                    <Box style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
                        <Box style={{ marginRight: "10px" }}>
                            <img style={{ borderRadius: "50%" }} height="41px" width="41px" alt="client_pic" src={experienceImg} />
                        </Box>
                        <Box style={{color: isCoursesReview ? "#e6e6e6" : "#000" , display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <Typography style={{  fontFamily: "Poppins-Bold" }}>{reviewData?.attributes?.full_name}</Typography>
                            <Typography style={{fontSize: "13px", color: "grey", fontFamily: "Poppins-Light"}}>{"Student"} | {getAge(reviewData?.attributes?.created_at)} ago</Typography>
                        </Box>
                    </Box>
                    <Typography style={{color: isCoursesReview ? "#e6e6e6" : "#000", fontFamily: "Poppins-Light"}}>{reviewData?.attributes?.text}</Typography>
                </Box>
                <Box style={{marginTop: "8px",marginLeft: "15px",width: "100%", height: "1px", backgroundColor: isCoursesReview ? "#575b64" : "e6e6e6"}} />
            </Box>
        </Box>
    )
}
export default ReviewDescription;