import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
// Customizable Area End
export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean;
  orgName: any;
  orgAbout: any;
  orgUrl: any;
  orgImage: any;
  inputError: any;
  loading: boolean;
  openAlert: boolean;
  alertMsg: any;
  categoryId: any;
  categoryList: any;
  previewImageUpdated: boolean;
  previewImageUrl: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditOrgController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiUpdateOrgId: string = "";
  apiGetCategoryDataCallId: string = "";
  apiGetOrgDetailsId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mobileOpen: false,
      orgName: '',
      orgAbout: '',
      orgUrl: '',
      orgImage: '',
      loading: false,
      openAlert: false,
      alertMsg: '',
      inputError: '',
      categoryId: '',
      categoryList: '',
      previewImageUpdated: false,
      previewImageUrl: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getOrgCategories()
    const id = window.location.href.split('/', 6).pop();
    this.getOrgDetails(id)
  }

  setCategoryList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        categoryList: responseJson.data
      });
    }
  }

  setUpdateOrg = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        openAlert: true,
        loading: false,
        alertMsg: 'Organisation updated successfully!'
      });
    } else {
      this.setState({
        loading: false,
        openAlert: true,
        alertMsg: 'Something went wrong!'
      })
    }
  }

  setOrgDetails = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
        this.setState({
            orgName: responseJson.data.attributes.name,
            orgAbout: responseJson.data.attributes.about,
            orgImage: responseJson.data.attributes.image,
            orgUrl: responseJson.data.attributes.website_url,
            previewImageUrl: responseJson.data.attributes.image,
            categoryId: responseJson.data.attributes.category.id
        })
    }   else {
            this.setState({
            openAlert: true,
            loading: false,
            alertMsg: 'Something went wrong! Not able to fetch details.'
        })
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetCategoryDataCallId) {
          this.setCategoryList(responseJson)
        }
        if (apiRequestCallId === this.apiUpdateOrgId) {
          this.setUpdateOrg(responseJson)
        }
        if(apiRequestCallId === this.apiGetOrgDetailsId){
            this.setOrgDetails(responseJson)
        }
      }
    }
  }

  getOrgCategories = () => {
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoryDataCallId = requestMessage.messageId;
    const hitURL = `${configJSON.categoryUrl}?type=organisation_category`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOrgDetails = (id: any) => {
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOrgDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrgDetailsApi+'/'+id+'?dashboard=true'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  validation = () => {
    const {
      orgName,
      orgAbout,
      orgImage,
      orgUrl,
      categoryId
    } = this.state

    let error: any = {}
    if (!orgName) {
        error.orgName = "Please provide organisation name"
    }

    if (!orgAbout) {
      error.orgAbout = "Please provide organisation about"
    }

    if (!categoryId) {
      error.categoryId = "Please select category"
    }

    if (!orgUrl) {
      error.orgUrl = "Please provide organisation website url"
    }

    if (!orgImage) {
      error.orgImage = "Please upload organisation image"
    }
    
    if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
        this.setState({ inputError: {} })
        this.updateOrganisation()
    }
    else {
        this.setState({ inputError: error })
    }
  }

  updateOrganisation = () => {
    this.setState({
      loading: true
    })
    const id = window.location.href.split('/', 6).pop();
    const userToken = window.localStorage.getItem("token");
    const header = {
      // "Content-Type": 'multipart/form-data',
      token: userToken
    };

    const { 
          orgName,
          orgAbout,
          orgUrl,
          orgImage,
          categoryId,
          previewImageUpdated
          } = this.state

    const formData = new FormData();

    formData.append('organisations[name]', orgName);
    formData.append('organisations[about]', orgAbout);
    formData.append('organisations[category_id]', categoryId);
    if(previewImageUpdated){
        let blobOrgImage = new Blob(orgImage);
        formData.append('organisations[image]', blobOrgImage);
    }
    formData.append('organisations[website_url]', orgUrl)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateOrgId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateOrgApi+'/'+id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleOrgImageChange = (files: any) => {
    this.setState({
      orgImage: files,
      previewImageUpdated: true
    })
  }

  handleOrgNameChange = (e: any) => {
    this.setState({
      orgName: e.target.value
    })
  }

  handleOrgAboutChange = (e: any) => {
    this.setState({
      orgAbout: e.target.value
    })
  }

  handleOrgUrlChange = (e: any) => {
    this.setState({
      orgUrl: e.target.value
    })
  }

  handleCategoryChange = (e: any) => {
    this.setState({
      categoryId: e.target.value
    })
  };


  // Customizable Area End

}
