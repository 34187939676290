import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { BlogType, CategoryType, PaginationType } from "../../../ContentManagement/src/Interfaces.web"
import { IBlog, BlogUniqueItem, Sort, DateTime, BlogData } from "../Interfaces.web";
// Customizable Area End
export const configJSON = require("../config.js");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    mobileOpen: boolean;
    loading: boolean;
    errorMsg: string;
    getCategoryLoading: boolean;
    categoryList: CategoryType[];
    authorList: any[];
    authorLoading: boolean;
    selectecCategory: string;
    status: string;
    openSetting: boolean;
    sort: Sort;
    dateTime: DateTime;
    openAlert: boolean;
    dataCreationMsg: string;
    inputError: any;
    previewModal: boolean;
    open: any;
    bold: boolean;
    underlined: boolean;
    italic: boolean;
    alignment: string;
    title: any;
    summary: any;
    bgImage: any;
    podcastMedia: any;
    podcastType: any;
    catValidation: string;
    createLoading: boolean;
    othersName: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddPodcastController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetDataCallId: string = "";
    getAllCategoryId: string = "";
    getAllAuthorId: string = "";
    createBlogId: string = "";
    createPodcastCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // getName(MessageEnum.NavigationToSingleArticle),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            mobileOpen: false,
            loading: false,
            errorMsg: '',
            categoryList: [],
            authorList: [],
            authorLoading: false,
            getCategoryLoading: false,
            selectecCategory: "",
            status: "",
            openAlert: false,
            dataCreationMsg: "",
            openSetting: false,
            sort: {
                publish: 'immediately',
                visibility: 'public_user',
                author: 92
            },
            dateTime: {
                date: new Date(),
                time: new Date(),
            },
            inputError: {},
            previewModal: false,
            open: false,
            bold: false,
            underlined: false,
            italic: false,
            alignment: 'left',
            title: '',
            summary: '',
            bgImage: [],
            podcastMedia: [],
            podcastType: 'video',
            catValidation: '',
            createLoading: false,
            othersName: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {
                if (apiRequestCallId == this.getAllAuthorId) {
                    if (responseJson && !responseJson.error && responseJson.accounts) {
                        if (responseJson.accounts.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                authorLoading: false,
                                // loading: false,
                                authorList: [],
                                // getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false,
                                authorList: responseJson?.accounts,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.createPodcastCallId) {
                    if (responseJson && !responseJson.error) {
                        if (responseJson.data.type !== "error") {
                            this.setState({
                                ...this.state,
                                dataCreationMsg: "Podcast created successfully!",
                                // loading: false,
                                openAlert: true,
                                createLoading: false
                                // categoryList: responseJson?.data,
                            });
                        }
                        else {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "Please fill required data",
                                errorMsg: "",
                                createLoading: false
                            })
                        }

                    } else {
                        if (responseJson.error) {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "",
                                errorMsg: "Internal Server Error",
                                createLoading: false
                            })
                        }
                        else if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                dataCreationMsg: "",
                                openAlert: true,
                                createLoading: false
                                // loading: false,
                            });
                        } else {
                            this.setState({
                                openAlert: true,
                                errorMsg: errorReponse,
                                dataCreationMsg: "",
                                createLoading: false
                                // loading: false,
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getAllCategoryId) {
                    if (responseJson && !responseJson.error && responseJson.data) {
                        if (responseJson.data.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                // loading: false,
                                categoryList: [],
                                getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                getCategoryLoading: false,
                                categoryList: responseJson?.data,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                getCategoryLoading: false,
                            });
                        }
                    }
                }
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.getCategory()
        this.getAuthorList()
    }

    // Customizable Area End

    // Customizable Area Start

    handleSelectOthers = (e: any) => {
        this.setState({
            othersName: e.target.value
        })
    }

    fieldValidation = () => {
        let error: any = {}
        const {
            title,
            podcastType,
            summary,
            podcastMedia,
            selectecCategory
        } = this.state
        if (!title) {
            error.title = "Please Provide Title"
        }
        if (!summary) {
            error.summary = "Please Provide Summary"
        }
        if(!podcastType){
            error.podcastType = "Please select podcast type"
        }
        if(!podcastMedia.length){
            error.podcastMedia = "Please select a file"
        }
        if(!selectecCategory){
            this.setState({
                openSetting: true,
                catValidation: 'Please select category'
            })
            error.cat = 'Please select category'
        }
        if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
            this.setState({ inputError: {} })
            this.createPodcast()
        }
        else {
            this.setState({ inputError: error })
        }
    }

    getCategory = () => {
        this.setState({ getCategoryLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllCategoryId = requestMessage.messageId;
        const hitURL = `${configJSON.categoryUrl}?type=podcast_category`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            hitURL
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getAuthorList = () => {
        this.setState({ getCategoryLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllAuthorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAuthorUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createPodcast = () => {
        this.setState({
            createLoading: true
        })
        const { 
            title, 
            summary,
            podcastMedia,
            podcastType,
            selectecCategory,
            status,
            dateTime,
            sort,
            bgImage,
            othersName
        } = this.state;
        const formData = new FormData();

        const mediaBlob = new Blob(podcastMedia)

        const userId: any = localStorage.getItem('userId')

        formData.append('podcasts[title]', title);
        formData.append('podcasts[description]', summary);
        formData.append('podcasts[summary]', summary);
        formData.append('podcasts[podcast_type]', podcastType);
        if(podcastType == 'video'){
            formData.append('podcasts[video]', mediaBlob);
        } else {
            formData.append('podcasts[audio]', mediaBlob);
        }
        const posterBlob = new Blob(bgImage)
        formData.append('podcasts[poster]', posterBlob);
        othersName.map((id: any) => {
            return (
                formData.append('podcasts[tag_others][]', id)
            )
        });
        formData.append('podcasts[category_id]', selectecCategory);
        formData.append('podcasts[status]', status == 'draft' ? status :  status == "published" && sort.publish == "schedule_post" ? 'scheduled' : status)
        formData.append('podcasts[publish_timing]', sort.publish)
        formData.append('podcasts[visibility]', sort.visibility)
        // @ts-ignore
        formData.append('podcasts[instructor_id]', userId)
        // @ts-ignore
        formData.append('podcasts[scheduled_date]', dateTime.date)
        // @ts-ignore
        formData.append('podcasts[scheduled_time]', dateTime.time)
    
        const header = {
          token: localStorage.getItem("token")
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createPodcastCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.createPodcastApi
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
    
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

    handleTitleChange = (e: any) => {
        this.setState({
            title: e.target.value
        })
    }

    setMedia = (acceptedFiles: any) => {
        this.setState({
            podcastMedia: acceptedFiles
        })
    }

    handleSummaryChange = (e: any) => {
        this.setState({
            summary: e.target.value
        })
    }

    handleChangeType = (e: any) => {
        this.setState({
            podcastType: e.target.value,
            podcastMedia: ''
        })
    }

    handleBgImgChange = (value: any[], files: any[]) => {
        this.setState({
            bgImage: files
        });
    }

    handleCategorySelect = (id: string) => {
        this.setState({ selectecCategory: id })
    }

    hanldeSettingToggle = () => {
        this.setState({ openSetting: !this.state.openSetting })
    }

    handleSortChange = (event: any, name: string) => {
        const { value } = event.target;
        this.setState({
            sort: {
                ...this.state.sort,
                [name]: value
            }
        });
    };

    handleDateTimeChange = (value: Date, name: string) => {
        this.setState({
            dateTime: {
                ...this.state.dateTime,
                [name]: value,
            }
        })
    }

    handleDraft = () => {
        this.setState((prev_stat) => {
            return {
                ...prev_stat,
                status: "draft"
            }
        }, () => this.fieldValidation())
    }

    handlePublish = () => {
        this.setState((prev_stat) => {
            return {
                ...prev_stat,
                status: "published"
            }
        }, () => this.fieldValidation())
    }

    closeAlertBox = () => {
        this.setState({
            openAlert: false
        });
    };

    handlePreviewOpen = () => {
        this.setState({ previewModal: true })
    }

    handleModalClose = () => {
        this.setState({ previewModal: false })
    }

    // Customizable Area End

}
