import React from "react";

// Customizable Area Start
import { 
  Button,
  Container, Typography, 
} from '@material-ui/core';
import Breadcrumb from "../../../components/src/BreadCrumb";
import Slider from "react-slick";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import CustomLoader from "../../../components/src/CustomLoader";
import { palette } from "../../../components/src/theme";
import FavouriteIcon from "../../../components/src/FavouriteIcon";
import AlertModal from "../../../components/src/AlertModal";
import {
  withTheme,
  withStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";



function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style,
        borderRadius: '50%', 
        border: '2px solid #000',
        height: '30px',
        width: '30px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center' 
      }}
      onClick={onClick}
    >
      <ArrowForwardIosRoundedIcon 
        style={{
          color: '#000'
        }}
      />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick, currentSlide } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style,
        borderRadius: '50%', 
        border: '2px solid #000',
        height: '30px',
        width: '30px',
        padding: '5px',
        display: currentSlide == 0 ? 'none' : 'flex',
        justifyContent: 'center',
        alignItems: 'center' 
      }}
      onClick={onClick}
    >
      <ArrowBackIosRoundedIcon 
        style={{
          color: '#000'
        }}
      />
    </div>
  );
}

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  adaptiveHeight: false,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        adaptiveHeight: false,
        centerMode: false,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        adaptiveHeight: false,
        centerMode: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        adaptiveHeight: false,
        centerMode: false,
      }
    }
  ]
};

const settingsPodcast = {
  dots: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  adaptiveHeight: false,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        adaptiveHeight: false,
        centerMode: false,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        adaptiveHeight: false,
        centerMode: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        adaptiveHeight: false,
        centerMode: false,
      }
    }
  ]
};
// Customizable Area End

import CommentsController, {
  Props,
  configJSON
} from "./CommentsController";

class Comments extends CommentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getCourse = (courseList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleHead}
        >
          Courses
        </Typography>
        <div
          style={{
            marginTop: '40px'
          }}
        >
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settings}>
            {
              courseList?.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.articleCardWrap}
                  >
                    <FavouriteIcon 
                        isFavourite={item.attributes.favourite}
                        position={'absolute'}
                        right={'10px'}
                        top={'10px'}
                        makeFavourite={(status: any) => this.makeFavourite(item.id, status, 'BxBlockLibrary2::Course')}
                        navigation={this.props.navigation}
                    />
                    <div
                      className={classes.videoImgWrap}
                    >
                      <img 
                        src={item.attributes.background_image}
                        className={classes.videoImg}
                        onClick={() => this.props.navigation.navigate('CourseDetails', {id:item.id})}
                      />
                      <div
                        className={classes.podcastContentWrap}
                      >
                        <div
                          className={classes.podcastBtnWrap}
                        >
                          <PlayArrowRoundedIcon 
                            style={{
                              color: palette.primary.main,
                              fontSize: 30,
                              cursor: 'pointer'
                            }}
                            onClick={() => this.props.navigation.navigate('CourseDetails', {id:item.id})}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '10px'
                      }}
                    >
                      <Typography
                        className={classes.articleTitle}
                        onClick={() => this.props.navigation.navigate('CourseDetails', {id:item.id})}
                      >
                        {item.attributes.title}
                      </Typography>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.articleDisc}
                      >
                        {
                          item.attributes.about
                        } 
                      </Typography>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
        </div>
      </div>
    )
  }

  getAge = (dateString: string) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let d1 = birthDate.getDate();
    let m1 = birthDate.getMonth();
    let y1 = birthDate.getFullYear();
    let d2 = today.getDate();
    let m2 = today.getMonth();
    let y2 = today.getFullYear();
    let month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (d1 > d2) {
        d2 = d2 + month[m2 - 1];
        m2 = m2 - 1;
    }
    if (m1 > m2) {
        m2 = m2 + 12;
        y2 = y2 - 1;
    }
    const d = d2 - d1;
    const m = m2 - m1;
    const y = y2 - y1;
  
  
    if (y > 0) {
        return ('' + y + ' years ' + m + ' months ' + d + ' days');
    }
    if (y == 0 && m > 0) {
        return (+m + ' months ' + d + ' days');
    }
    if (y == 0 && m == 0) {
        return (+d + ' days');
    }
  }

  getVideos = (videosList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleHead}
        >
          Videos
        </Typography>
        <div
          style={{
            marginTop: '40px'
          }}
        >
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settings}>
            {
              videosList.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.articleCardWrap}
                  >
                    <div
                      className={classes.videoImgWrap}
                    >
                      <FavouriteIcon 
                        isFavourite={item.attributes.favourite}
                        position={'absolute'}
                        right={'10px'}
                        top={'10px'}
                        makeFavourite={(status: any) => this.makeFavourite(item.id, status, 'BxBlockVideoManagement::Video')}
                        navigation={this.props.navigation}
                      />
                      <img 
                        src={item.attributes.poster}
                        className={classes.videoImg}
                        onClick={() => this.props.navigation.navigate('WatchVideo', {id:item.id})}
                      />
                      <div
                        className={classes.podcastContentWrap}
                      >
                        <div
                          className={classes.podcastBtnWrap}
                        >
                          <PlayArrowRoundedIcon 
                            style={{
                              color: palette.primary.main,
                              fontSize: 30,
                              cursor: 'pointer'
                            }}
                            onClick={() => this.props.navigation.navigate('WatchVideo', {id:item.id})}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '10px'
                      }}
                    >
                      <Typography
                        className={classes.articleTitle}
                      >
                        {item.attributes.title}
                      </Typography>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.articleDisc}
                      >
                        {
                          item.attributes.about
                        } 
                      </Typography>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
        </div>
      </div>
    )
  }

  getArticles = (blogList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleHead}
        >
          Articles
        </Typography>
        <div style={{marginTop: '40px'}}>
          {/* @ts-ignore */}
        <Slider style={{height: '348px', marginTop: '40px'}} {...settings}>
          {
            blogList.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className={classes.articleCardWrap}
                >
                  <div>
                    <FavouriteIcon 
                      isFavourite={item.attributes.favourite}
                      position={'absolute'}
                      right={'10px'}
                      top={'10px'}
                      makeFavourite={(status: any) => this.makeFavourite(item.attributes.id, status, 'BxBlockPosts::Blog')}
                      navigation={this.props.navigation}
                    />
                    <img 
                      src={item.attributes.cover_image}
                      className={classes.articleImg}
                      alt="Article Image"
                      onClick={() => this.props.navigation.navigate('BlogArticle', {id:item.attributes.id})}
                    />
                  </div>
                  <div
                    style={{
                      padding: '10px'
                    }}
                  >
                    <Typography
                      className={classes.articleTitle}
                    >
                      {item.attributes.title}
                    </Typography>
                  </div>
                  <div
                    style={{
                      paddingLeft: '10px',
                      paddingRight: '10px'
                    }}
                  >
                    <Typography
                      className={classes.articleCreated}
                    >
                      {this.getAge(item.attributes.created_at) } ago by { item.attributes.instructor_name}
                    </Typography>
                  </div>
                  <div
                    style={{
                      padding: '10px',
                      paddingTop: '0px'
                    }}
                  >
                    <Typography
                      className={classes.articleDisc}
                    >
                      {
                        item.attributes.subtitle
                      } 
                    </Typography>
                  </div>
                </div>
              )
            })
          }
        </Slider>
        </div>
      </div>
    )
  }

  getPodcasts = (podcastsList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleHead}
        >
          Podcasts
        </Typography>
        <div style={{marginTop: '40px'}}>
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settingsPodcast}>
            {
              podcastsList.map((item: any, index: any) => {
                return (
                  <div
                    style={{
                      position: 'relative'
                    }}
                  >
                    <div
                      className={classes.podcastCard}
                    >
                      <FavouriteIcon 
                        isFavourite={item.attributes.favourite}
                        position={'absolute'}
                        right={'10px'}
                        top={'10px'}
                        makeFavourite={(status: any) => this.makeFavourite(item.id, status,  'BxBlockVideoManagement::VideoPodcast')}
                        navigation={this.props.navigation}
                      />
                      <img 
                        src={item.attributes.poster}
                        className={classes.podcastCover}
                      />
                      <div
                        className={classes.podcastOverlay}
                      >

                      </div>
                      <div
                        className={classes.podcastContentWrap}
                      >
                        <div
                          className={classes.podcastBtnWrap}
                        >
                          <PlayArrowRoundedIcon 
                            style={{
                              color: palette.primary.main,
                              fontSize: 30,
                              cursor: 'pointer'
                            }}
                            onClick={() => this.getPodcast(item.id, item.type)}
                          />
                        </div>
                      </div>
                      <div
                        className={classes.podcastTitleWrap}
                      >
                        <Typography
                            className={classes.podcastTitle}
                            title={item.attributes.title}
                        >
                            {item.attributes.title.length > 70 ? item.attributes.title.substring(0, 70) + "..." : item.attributes.title}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
        </div>
      </div>
    )
  }

  getPeople = (peopleList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleHead}
        >
          Peoples
        </Typography>
        <div style={{marginTop: '40px'}}>
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settingsPodcast}>
            {
              peopleList.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.peopleCard}
                  >
                    <div>
                      <FavouriteIcon 
                        isFavourite={item.attributes.favourite}
                        position={'absolute'}
                        right={'10px'}
                        top={'10px'}
                        makeFavourite={(status: any) => this.makeFavourite(item.attributes.id, status,  'BxBlockDashboard::Person')}
                        navigation={this.props.navigation}
                      />
                      <img 
                        src={item.attributes.image}
                        className={classes.peopleImg}
                        alt="People Image"
                      />
                    </div>
                    <div
                      style={{
                        padding: '10px'
                      }}
                    >
                      <Typography
                        className={classes.articleTitle}
                      >
                        {item.attributes.name}
                      </Typography>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.peopleDisc}
                      >
                        {
                          item.attributes.about
                        } 
                      </Typography>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.peopleDisc}
                        style={{
                          color: palette.primary.main,
                          cursor: 'pointer'
                        }}
                        onClick={() => this.props.navigation.navigate('CustomisableUserProfiles', {id:item.attributes.id})}
                      >
                        Readmore...
                      </Typography>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
          </div>
      </div>
    )
  }

  getOrg = (orgList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleHead}
        >
          Organizations
        </Typography>
        <div style={{marginTop: '40px'}}>
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settingsPodcast}>
            {
              orgList.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.peopleCard}
                  >
                    <FavouriteIcon 
                      isFavourite={item.attributes.favourite}
                      position={'absolute'}
                      right={'10px'}
                      top={'10px'}
                      makeFavourite={(status: any) => this.makeFavourite(item.attributes.id, status,   'BxBlockDashboard::Organisation')}
                      navigation={this.props.navigation}
                    />
                    <img 
                      src={item.attributes.image}
                      className={classes.peopleImg}
                      style={{
                        cursor: 'pointer'
                      }}
                      alt="Organisation Image"
                      onClick={() => this.props.navigation.navigate('CommunityForum')}
                    />
                    <div
                      style={{
                        padding: '10px'
                      }}
                    >
                      <span
                        className={classes.articleTitle}
                        style={{
                          textDecoration: 'none',
                          cursor: 'pointer'
                        }}
                        onClick={() => this.props.navigation.navigate('CommunityForum')}
                      >
                        {item.attributes.name}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.orgDisc}
                      >
                        {
                          item.attributes.about
                        } 
                      </Typography>
                    </div>
                    <div>
                      <Button
                        className={classes.borderedBtn}
                        onClick={() => this.goToExt(item.attributes.website_url)}
                      >
                        Go To Website
                      </Button>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
          </div>
      </div>
    )
  }

  goToExt = (url: any) => {
    window.location.href = url
  }

  getBooks = (bookList: any, classes: any) => {
    return (
      <div style={{marginTop: '30px'}}>
        <Typography
          className={classes.moduleHead}
        >
          Books
        </Typography>
        <div style={{marginTop: '40px'}}>
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settingsPodcast}>
            {
              bookList.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.peopleCard}
                  >
                    <FavouriteIcon 
                      isFavourite={item.attributes.favourite}
                      position={'absolute'}
                      right={'10px'}
                      top={'10px'}
                      makeFavourite={(status: any) => this.makeFavourite(item.attributes.id, status,   'BxBlockDashboard::Book')}
                      navigation={this.props.navigation}
                    />
                      <img 
                        src={item.attributes.image}
                        className={classes.peopleImg}
                        style={{
                          cursor: 'pointer'
                        }}
                        alt="Book Image"
                        onClick={() => this.props.navigation.navigate('CommunityForum')}
                      />
                    <div
                      style={{
                        padding: '10px'
                      }}
                    >
                      <span
                        className={classes.articleTitle}
                        style={{
                          textDecoration: 'none',
                          cursor: 'pointer'
                        }}
                        onClick={() => this.props.navigation.navigate('CommunityForum')}
                      >
                        {item.attributes.title}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.orgDisc}
                      >
                        {
                          item.attributes.about
                        } 
                      </Typography>
                    </div>
                    <div>
                      <Button
                        className={classes.borderedBtn}
                        onClick={() => this.goToExt(item.attributes.url)}
                      >
                        Link To Buy
                      </Button>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
          </div>
      </div>
    )
  }

  getFilms = (filmList: any, classes: any) => {
    return (
      <div style={{marginTop: '30px'}}>
        <Typography
          className={classes.moduleHead}
        >
          Films
        </Typography>
        <div style={{marginTop: '40px'}}>
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settingsPodcast}>
            {
              filmList.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.peopleCard}
                  >
                    <FavouriteIcon 
                      isFavourite={item.attributes.favourite}
                      position={'absolute'}
                      right={'10px'}
                      top={'10px'}
                      makeFavourite={(status: any) => this.makeFavourite(item.attributes.id, status,   'BxBlockDashboard::Film')}
                      navigation={this.props.navigation}
                    />
                      <img 
                        src={item.attributes.image}
                        className={classes.peopleImg}
                        style={{
                          cursor: 'pointer'
                        }}
                        alt="Book Image"
                        onClick={() => this.props.navigation.navigate('CommunityForum')}
                      />
                    <div
                      style={{
                        padding: '10px'
                      }}
                    >
                      <span
                        className={classes.articleTitle}
                        style={{
                          textDecoration: 'none',
                          cursor: 'pointer'
                        }}
                        onClick={() => this.props.navigation.navigate('CommunityForum')}
                      >
                        {item.attributes.name}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.orgDisc}
                      >
                        {
                          item.attributes.about
                        } 
                      </Typography>
                    </div>
                    <div>
                      <Button
                        className={classes.borderedBtn}
                        onClick={() => this.goToExt(item.attributes.url)}
                      >
                        Link To Watch
                      </Button>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
          </div>
      </div>
    )
  }

  closeAlertBox = () => {
    this.setState({
        openAlert: false
    })
  } 

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <div>
          {
              this.state.openAlert ?
                  <AlertModal 
                      open={this.state.openAlert}
                      data={this.state.updateMessage}
                      onClose={() => this.closeAlertBox()}
                  />
              :   null
          }
          {
            this.state.loading ? 
              <CustomLoader 
                open={this.state.loading}
              />
            : null
          }
          <Container>
            <Breadcrumb
                base={'Home'}
                url1={{
                  base: ``,
                  link: 'Favourites'
                }}
                url2={{
                  path: '',
                  link: ''
                }}
            />
          </Container>
          <div
            style={{
              padding: '30px',
              background: '#ffbe63'
            }}
          >
            <Container>
              <Typography
                style={{
                  fontSize: '20px',
                  fontFamily: 'Poppins-SemiBold',
                  color: '#e35400'
                }}
              >
                Your
              </Typography>
              <Typography
                style={{
                  fontSize: '48px',
                  fontFamily: 'Poppins-SemiBold',
                  color: '#353535'
                }}
              >
                Favourites  
              </Typography>
            </Container>
          </div>
          <div>
            <Container>
              <div
                className={this.props.classes.moduleWrap}
              >
                {
                  this.state.courseList?.length || 
                  this.state.videosList?.length ||
                  this.state.blogList?.length ||
                  this.state.personList?.length ||
                  this.state.orgList?.length ||
                  this.state.bookList?.length ||
                  this.state.filmsList?.length|| 
                  this.state.podcastList ||
                  this.state.loading ?
                    null
                  : <span
                      style={{
                        color: '#000',
                        fontFamily: 'Poppins-SemiBold',
                        fontSize: '24px',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      No favourites found!
                    </span>
                }
                {
                  this.state.courseList.length ?
                    this.getCourse(this.state.courseList, this.props.classes)
                  : null
                }
              </div>
              <div
                className={this.props.classes.moduleWrap}
              >
                {
                  this.state?.videosList?.length ?
                    this.getVideos(this.state.videosList, this.props.classes)
                  : null
                }
              </div>
              <div
                className={this.props.classes.moduleWrap}
              >
                {
                  this.state.blogList?.length ?
                    this.getArticles(this.state.blogList, this.props.classes)
                  : null
                }
              </div>
              <div
                className={this.props.classes.moduleWrap}
              >
                {
                  this.state.podcastList?.length ?
                    this.getPodcasts(this.state.podcastList, this.props.classes)
                  : null
                }
              </div>
              <div
                className={this.props.classes.moduleWrap}
              >
                {
                  this.state.personList?.length ?
                    this.getPeople(this.state.personList, this.props.classes)
                  : null
                }
              </div>
              <div
                className={this.props.classes.moduleWrap}
              >
                {
                  this.state.orgList?.length ?
                    this.getOrg(this.state.orgList, this.props.classes)
                  : null
                }
              </div>
              <div
                className={this.props.classes.moduleWrap}
                style={{
                  marginTop: '150px'
                }}
              >
                {
                  this.state.bookList?.length ?
                    this.getBooks(this.state.bookList, this.props.classes)
                  : null
                }
              </div>
              <div
                className={this.props.classes.moduleWrap}
              >
                {
                  this.state.filmsList?.length ?
                    this.getFilms(this.state.filmsList, this.props.classes)
                  : null
                }
              </div>
            </Container>
          </div>
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      topicWrap: {
        marginTop: '60px',
        backgroundColor: '#ffbe63',
        padding: '30px'
      },
      topicContent: {
        display: 'flex',
        flexDirection: 'column'
      },
      topicHead: {
        color: '#e35400',
        fontSize: '20px',
        fontFamily: 'Poppins-SemiBold'
      },
      topic: {
        color: '#353535',
        fontSize: '48px',
        fontFamily: 'Poppins-SemiBold',
        textTransform: 'capitalize'
      },
      moduleWrap: {
        marginTop: '30px',
        marginBottom: '30px'
      },
      moduleHead: {
        color: '#1c1c1c',
        fontSize: '28px',
        fontFamily: 'Poppins-SemiBold'
      },
      moduleSubHead: {
        color: '#1c1c1c',
        fontSize: '20px',
        fontFamily: 'Poppins-SemiBold'
      },
      articleCardWrap: {
        borderRadius: "10px",
        boxShadow: '0 2px 16px 0 #e6e8f0',
        minHeight: '348px',
        maxHeight: '348px',
        minWidth: '348px',
        maxWidth: '348px',
        cursor: 'pointer'
      },
      articleImg: {
        minWidth: '348px',
        maxWidth: '348px',
        minHeight: '150px',
        maxHeight: '150px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px'
      },
      articleTitle: {
        color: '#353535',
        fontSize: '18px',
        fontFamily: 'Poppins-SemiBold',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      articleCreated: {
        fontSize: '12px',
        fontFamily: 'Poppins-Light',
        color: 'grey'
      },
      articleDisc: {
        fontSize: '12px',
        fontFamily: 'Poppins-Light',
        color: '#000000',
        display: '-webkit-box',
        lineClamp: 5,
        boxOrient: 'vertical',  
        overflow: 'hidden',
      },
      podcastCard: {
        position: 'relative',
        overflow: 'hidden',
        maxHeight: '330px',
        borderRadius: '8px',
        minWidth: '254px',
        maxWidth: '254px'
      },
      podcastCover: {
        borderRadius: '8px',
        minHeight: '330px'
      },
      podcastOverlay: {
        borderRadius: '8px',
        minHeight: '50vh',
        // maxHeight: '50vh',
        background: 'rgba(0,0,0,0.5)',
        position: 'absolute',
        zIndex: 99,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      podcastContentWrap: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: 99999,
        transform: 'translate(-50%, -50%)'
      },
      podcastBtnWrap: {
        height: '50px',
        width: '50px',
        borderRadius: '50%',
        background: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      podcastTitleWrap: {
        position: 'absolute',
        zIndex: 9999,
        bottom: '15px',
        padding: '20px',
      },
      podcastTitle: {
        fontFamily: 'Poppins-Light',
        color: '#ffffff',
        fontSize: '1em'
      },
      videoImgWrap: {
        minWidth: '348px',
        maxWidth: '348px',
        minHeight: '196px',
        maxHeight: '196px',
        overflow: 'hidden',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        position: 'relative'
      },
      videoImg: {
        minWidth: '348px',
        maxWidth: '348px',
        minHeight: '196px',
        maxHeight: '196px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px'
      },
      peopleCard: {
        position: 'relative',
        overflow: 'hidden',
        maxHeight: '480px',
        minWidth: '230px',
        maxWidth: '230px'
      },
      peopleImg: {
        minWidth: '230px',
        maxWidth: '230px',
        maxHeight: '270px',
        minHeight: '270px',
        borderRadius: '8px',
        boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.07)',
      },
      peopleDisc: {
        fontSize: '12px',
        fontFamily: 'Poppins-Light',
        color: '#000000',
        display: '-webkit-box',
        lineClamp: 4,
        boxOrient: 'vertical',  
        overflow: 'hidden',
      },
      orgDisc: {
        fontSize: '12px',
        fontFamily: 'Poppins-Light',
        color: '#000000',
        display: '-webkit-box',
        lineClamp: 2,
        boxOrient: 'vertical',  
        overflow: 'hidden',
      },
      borderedBtn: {
        width: '100%',
        height: '35px',
        color: palette.primary.main,
        borderRadius: '4px',
        background: '#fff',
        border: `1px solid ${palette.primary.main}`
      }
    })
  )(Comments)
);
// Customizable Area End
