import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { TopicProps } from "./Interface.web";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    errorMsg: string;
    faqsListData: TopicProps[]
    searchKeyword: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class FaqPageController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getFAQSListDataCallId: string = ""

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            errorMsg: "",
            faqsListData: [],
            searchKeyword: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);


        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.errors && responseJson.errors[0].token) {
                return;
            }
            if (responseJson) {
                if (this.getFAQSListDataCallId === apiRequestCallId) {
                    this.getFAQSDataResponse(responseJson, errorReponse)
                }
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.getFAQSDataRequest()
    }
    // Customizable Area End

    // Customizable Area Start

    getFAQSDataResponse(responseJson: any, errorReponse: any) {
        if (responseJson && !responseJson.errors) {
            const topics: TopicProps[] = responseJson.data.map((topic: any) => ({
                topic: topic.attributes.title,
                faqs: topic.attributes.faqs.map((faq: any) => ({
                    question: faq.attributes.question,
                    answer: faq.attributes.answer,
                })),
            }));

            this.setState({
                loading: false,
                errorMsg: "",
                faqsListData: topics
            })
        } else {
            this.setState({
                loading: false,
                errorMsg: errorReponse
            })
        }
    }


    getFAQSDataRequest = () => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getFAQSListDataCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.faqsAPI}?search=${this.state.searchKeyword}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getSearchedResult = (keyword: string) => {
        this.setState({
            searchKeyword: keyword
        }, () => this.getFAQSDataRequest())
    }


    // Customizable Area End

}
