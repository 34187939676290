export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const CarouselImg = require("../assets/lifeStyle1.png");
export const CarouselAuthorImg = require("../assets/lifeStyle2.png");
export const lifeStyleBlogImg1 = require("../assets/lifeStyle3.png");
export const lifeStyleBlogImg2 = require("../assets/lifeStyle4.png");
export const lifeStyleBlogImg3 = require("../assets/lifeStyle5.png");
export const lifeStyleBlogImg4 = require("../assets/lifeStyle6.png");
export const lifeStyleAuthorBlogImg = require("../assets/lifeStyle7.png");
export const featureBlogImg1 = require("../assets/featureBlog1.jpg");
export const featureBlogImg2 = require("../assets/featureBlog2.jpg");
export const featureAuthorImg = require("../assets/featureAuthor.png");
export const blogImg1 = require("../assets/blogImg1.png");
export const blogImg2 = require("../assets/blogImg2.png");
export const blogImg3 = require("../assets/blogImg3.png");
export const blogImg4 = require("../assets/blogImg4.png");
export const blogImg5 = require("../assets/blogImg5.png");
export const blogImg6 = require("../assets/blogImg6.png");
export const blogImg7 = require("../assets/blogImg7.png");
export const blogImg8 = require("../assets/blogImg8.png");
export const blogImg9 = require("../assets/blogImg9.png");
export const blogImg10 = require("../assets/blogImg10.png");
export const blogImg11 = require("../assets/blogImg11.png");
export const blogImg12 = require("../assets/blogImg12.png");
export const articleBgImg = require("../assets/articleBgImg.png");
export const articleAuthorImg = require("../assets/articleAuthorImg.png");
export const relatedArticleBgImg = require("../assets/relatedArticleImg.png");
export const PlaceholderImage = require("../assets/placeholderImage.png");
