import React from "react";
import DashboardDrawer from "../../../components/src/DashboardDrawer";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Toolbar,
  Button,
  withStyles,
  AppBar,
} from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';

const drawerWidth = 240;
// const theme = createTheme();

const styles = (theme: any) => ({
  logo: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: palette.primary.main,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appBar: {
    background: '#fff',
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      background: '#fff',
      boxShadow: 'none'
    },
  },
  appBarWrap: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  welcome: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  name: {
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      fontSize: '16px'
    },
  }
});

const filterBtn = [
  {
    id: 1,
    title: 'Trending Courses'
  },
  {
    id: 2,
    title: 'Trending Podcasts'
  },
  {
    id: 3,
    title: 'Trending Articles'
  },
  {
    id: 4,
    title: 'Trending Videos'
  }
]

// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
import CustomInput from "../../../components/src/CustomInput";
import CustomCard from "../../../components/src/CustomCard";
import { palette } from "../../../components/src/theme";
import AdminSearch from "../../../components/src/AdminSearch";


class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  handleDrawerToggle = () => {
    this.setState({mobileOpen: !this.state.mobileOpen})
  }

  setFilterData = (id: any) => {
    this.setState({
      selectedFilter: id,
      trendingData: id == 1 ? this.state.trendingCourses : id == 2 ? this.state.trendingPodcasts : id == 3 ? this.state.trendingArticles : this.state.trendingVideos
    })
  }

  // Customizable Area End
    
  render() {
    // @ts-ignore
    const { classes } = this.props;
    const { searchList } = this.state;
    return (
      <div>
        <DashboardDrawer 
          mobileOpen={this.state.mobileOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          // @ts-ignore
          navigation={this.props.navigation}
        />
        {/* @ts-ignore */}
        <Box
          className={classes.appBarWrap}
        >
          <AppBar position='fixed' className={classes.appBar}>
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.logo}>
                <img src={require('../../../components/src/logo.svg')} height={70} />
              </div>
              <div 
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between',
                  padding: '10px'
                }}
              >
                <div>
                  <Typography
                    style={{
                      fontFamily: 'Poppins-SemiBold',
                      color: '#353535',
                      textTransform: 'capitalize'
                    }}
                    className={classes.name}
                  >
                    Hi {localStorage.getItem('userName')},
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: 'Poppins-SemiBold',
                      fontSize: '40px',
                      color: '#353535'
                    }}
                    className={classes.welcome}
                  >
                    Welcome back
                  </Typography>
                </div>
                <div
                  style={{
                    display:'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div
                    style={{
                      position: 'relative'
                    }}
                  >
                    <CustomInput 
                      passwordfield={false}
                      placeholder={"Search"}
                      icon={require("../assets/search.png")}
                      iconHeight={"24px"}
                      height={48}
                      borderRadius={6}
                      value={this.state.searchQuery}
                      onChange={(e: any) =>
                        this.handleInputChange(e)
                      }
                    />
                    {
                      this.state.openSearch ?
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 999999,
                            color: '#000',
                            maxHeight: '200px',
                            overflowY: 'scroll',
                            boxShadow: '-1px -1px 19px -3px rgba(0,0,0,0.6)',
                            borderRadius: '8px',
                            minWidth: '250px',
                            background: '#fff',
                            padding: '10px',
                            top: '80px'
                          }}
                        >
                          {
                            !this.state.searchLoading ?
                                searchList.blogs.length ||
                                searchList.videos.length ||
                                searchList.podcasts.length ||
                                searchList.courses.length ||
                                searchList.lets_discover.books ||
                                searchList.lets_discover.films ||
                                searchList.lets_discover.organisations ||
                                searchList.lets_discover.people ?
                                  <AdminSearch 
                                    searchList={searchList}
                                    navigation={this.props.navigation}
                                  />
                                : 'No search found!'
                            : <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <CircularProgress
                                  style={{
                                    color: palette.primary.main
                                  }}
                                />
                              </div>
                          }
                          <div
                            style={{
                              borderTop: '1px solid #d3d3d3',
                              marginTop: '10px'
                            }}
                          >
                            <Typography
                              style={{
                                color: palette.primary.main,
                                fontSize: '12px',
                                fontFamily: 'Poppins-SemiBold',
                                cursor: 'pointer',
                                textAlign: 'center',
                                marginTop: '10px'
                              }}
                              onClick={() => this.setState({openSearch: false})}
                            >
                              Cancel
                            </Typography>
                          </div>
                        </div>
                      : null
                    }
                  </div>
                  <IconButton
                    style={{
                      border: '1px solid #bdbdbd',
                      height: '45px',
                      width: '45px',
                      borderRadius: '5px',
                      marginTop: '25px',
                      marginLeft: '20px'
                    }}
                  >
                    <img src={require('../assets/notification.png')} height={'25px'} />
                  </IconButton>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          {/* @ts-ignore */}
          <Box
            style={{
              padding: '35px',
              marginTop: '120px'
            }}
          >
            <Grid container spacing={3}>
              <Grid xs={12} sm={6} md={3} item>
                {/* @ts-ignore */}
                <Box>
                  <CustomCard
                    padding="15px"
                    // boxShadow="0 0 27px 1px rgba(0, 0, 0, 0.32)"
                    radius="10px"
                  >
                    {/* @ts-ignore */}
                    <Box>
                      <Typography
                        style={{
                          fontFamily: 'Poppins-SemiBold',
                          fontSize: '20px',
                          color: '#353535'
                        }}
                      >
                        Course Joined
                      </Typography>
                      <Typography
                        style={{
                          color: '#8991a4',
                          fontFamily: 'Poppins-Light',
                          fontSize: '14px'
                        }}
                      >
                        April 2022
                      </Typography>
                    </Box>
                    {/* @ts-ignore */}
                    <Box
                      style={{
                        marginTop: '30px'
                      }}
                    >
                      <Typography
                        style={{
                          color: palette.primary.main,
                          fontFamily: 'Poppins-SemiBold',
                          fontSize: '40px' 
                        }}
                      >
                        {this.state.dashboardData?.joined_data?.courses_joined ? this.state.dashboardData?.joined_data?.courses_joined : '0' }
                      </Typography>
                      <Typography
                        style={{
                          color: '#8991a4',
                          fontFamily: 'Poppins-Light',
                          fontSize: '14px',
                          textTransform: 'uppercase'
                        }}
                      >
                        Students
                      </Typography>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid xs={12} sm={6} md={3} item>
                {/* @ts-ignore */}
                <Box>
                  <CustomCard
                    padding="15px"
                    // boxShadow="0 0 27px 1px rgba(0, 0, 0, 0.32)"
                    radius="10px"
                  >
                    {/* @ts-ignore */}
                    <Box>
                      <Typography
                        style={{
                          fontFamily: 'Poppins-SemiBold',
                          fontSize: '20px',
                          color: '#353535'
                        }}
                      >
                        Podcast Views
                      </Typography>
                      <Typography
                        style={{
                          color: '#8991a4',
                          fontFamily: 'Poppins-Light',
                          fontSize: '14px'
                        }}
                      >
                        April 2022
                      </Typography>
                    </Box>
                    {/* @ts-ignore */}
                    <Box
                      style={{
                        marginTop: '30px'
                      }}
                    >
                      <Typography
                        style={{
                          color: palette.primary.main,
                          fontFamily: 'Poppins-SemiBold',
                          fontSize: '40px' 
                        }}
                      >
                        {this.state.dashboardData?.joined_data?.podcast_joined ? this.state.dashboardData?.joined_data?.podcast_joined : '0' }
                      </Typography>
                      <Typography
                        style={{
                          color: '#8991a4',
                          fontFamily: 'Poppins-Light',
                          fontSize: '14px',
                          textTransform: 'uppercase'
                        }}
                      >
                        Views
                      </Typography>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid xs={12} sm={6} md={3} item>
                {/* @ts-ignore */}
                <Box>
                  <CustomCard
                    padding="15px"
                    // boxShadow="0 0 27px 1px rgba(0, 0, 0, 0.32)"
                    radius="10px"
                  >
                    {/* @ts-ignore */}
                    <Box>
                      <Typography
                        style={{
                          fontFamily: 'Poppins-SemiBold',
                          fontSize: '20px',
                          color: '#353535'
                        }}
                      >
                        Blogs Reach
                      </Typography>
                      <Typography
                        style={{
                          color: '#8991a4',
                          fontFamily: 'Poppins-Light',
                          fontSize: '14px'
                        }}
                      >
                        April 2022
                      </Typography>
                    </Box>
                    {/* @ts-ignore */}
                    <Box
                      style={{
                        marginTop: '30px'
                      }}
                    >
                      <Typography
                        style={{
                          color: palette.primary.main,
                          fontFamily: 'Poppins-SemiBold',
                          fontSize: '40px' 
                        }}
                      >
                        {this.state.dashboardData?.joined_data?.blogs_reach ? this.state.dashboardData?.joined_data?.blogs_reach : '0' }
                      </Typography>
                      <Typography
                        style={{
                          color: '#8991a4',
                          fontFamily: 'Poppins-Light',
                          fontSize: '14px',
                          textTransform: 'uppercase'
                        }}
                      >
                        Views
                      </Typography>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid xs={12} sm={6} md={3} item>
                {/* @ts-ignore */}
                <Box>
                  <CustomCard
                    padding="15px"
                    // boxShadow="0 0 27px 1px rgba(0, 0, 0, 0.32)"
                    radius="10px"
                  >
                    {/* @ts-ignore */}
                    <Box>
                      <Typography
                        style={{
                          fontFamily: 'Poppins-SemiBold',
                          fontSize: '20px',
                          color: '#353535'
                        }}
                      >
                        Videos
                      </Typography>
                      <Typography
                        style={{
                          color: '#8991a4',
                          fontFamily: 'Poppins-Light',
                          fontSize: '14px'
                        }}
                      >
                        April 2022
                      </Typography>
                    </Box>
                    {/* @ts-ignore */}
                    <Box
                      style={{
                        marginTop: '30px'
                      }}
                    >
                      <Typography
                        style={{
                          color: palette.primary.main,
                          fontFamily: 'Poppins-SemiBold',
                          fontSize: '40px' 
                        }}
                      >
                        {this.state.dashboardData?.joined_data?.videos ? this.state.dashboardData?.joined_data?.videos : '0' }
                      </Typography>
                      <Typography
                        style={{
                          color: '#8991a4',
                          fontFamily: 'Poppins-Light',
                          fontSize: '14px',
                          textTransform: 'uppercase'
                        }}
                      >
                        Views
                      </Typography>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* @ts-ignore */}
          <Box
            style={{
              padding: '35px'
            }}
          >
            <CustomCard
              padding="25px"
              // boxShadow="0 0 27px 1px rgba(0, 0, 0, 0.32)"
              radius="10px"
            >
              {/* @ts-ignore */}
              <Box>
                <Grid container spacing={3}>
                  {
                    filterBtn.map((item: any, index: any) => {
                      return (
                        <>
                          <Grid item sm={6} md={3} xs={6} key={index}>
                            <Button
                              variant={'outlined'}
                              fullWidth={true}
                              style={{
                                color: item.id == this.state.selectedFilter ? "#fff" : palette.primary.main,
                                border: `1px solid ${palette.primary.main}`,
                                textTransform: 'none',
                                fontFamily: 'Poppins-SemiBold',
                                fontSize: '14px',
                                minHeight: '32px',
                                borderRadius: '7px',
                                background: item.id == this.state.selectedFilter ? palette.primary.main : ''
                              }}
                              onClick={() => this.setFilterData(item.id)}
                            >
                              {item.title}
                            </Button>
                          </Grid>
                        </>
                      )
                    })
                  }
                </Grid>
              </Box>
              {/* @ts-ignore */}
              <Box
                style={{
                  marginTop: '30px'
                }}
              >
                {
                  this.state.loading ?
                    // @ts-ignore
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Poppins-SemiBold'
                        }}
                      >
                        Loading...
                      </Typography>
                    </Box>
                  :this.state.selectedFilter == 1 ?
                  !this.state.trendingCourses.length ?
                    // @ts-ignore
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Poppins-SemiBold'
                        }}
                      >
                        No Trending Courses Found!
                      </Typography>
                    </Box>
                  :this.state.trendingCourses.map((item: any, index: any) => {
                    return (
                      <>
                        {/* @ts-ignore */}
                        <Box
                          style={{
                            border: "1px solid #e0e0e0",
                            padding: '30px',
                            borderRadius: '6px',
                            marginTop: '20px'
                          }}
                          display= {{sm: 'none', xs: 'none', md: 'none', lg: 'block'}}
                        >
                          <Grid container spacing={3} >
                            <Grid sm={2} md={2} xs={12}>
                              {/* @ts-ignore */}
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontFamily: 'Poppins-SemiBold',
                                      fontSize: '16px',
                                      color: '#081735'
                                    }}
                                  >
                                    {item.attributes?.view_count ? item.attributes?.view_count : '0' }
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontFamily: 'Poppins-Light',
                                      color: '#8991a4',
                                      fontSize: '12px',
                                      textTransform: 'uppercase'
                                    }}
                                  >
                                    Views
                                  </Typography>
                                </Box>
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    minWidth: '1px',
                                    minHeight: '40px',
                                    background: '#e6e6e6'
                                  }}
                                >
                                  
                                </Box>
                              </Box>
                            </Grid>
                            <Grid sm={6} md={7} xs={12}>
                              {/* @ts-ignore */}
                              <Box 
                                style={{
                                  marginLeft: '20px',
                                  marginRight: '20px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography
                                  style={{
                                    color: '#353535',
                                    fontSize: '16px',
                                    lineHeight: 1.25,
                                    fontFamily: 'Poppins-SemiBold'
                                  }}
                                >
                                  {item.attributes?.title ? item.attributes?.title : 'Dummy Title'}
                                </Typography>
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    minWidth: '1px',
                                    minHeight: '40px',
                                    background: '#e6e6e6'
                                  }}
                                >
                                  
                                </Box>
                              </Box>
                            </Grid>
                            <Grid sm={4} md={3} xs={12}>
                              {/* @ts-ignore */}
                              <Box 
                                style={{
                                  display: 'block',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item md={3} sm={12} xs={12}>
                                    {/* @ts-ignore */}
                                    <Box
                                      style={{
                                        border: `2px solid ${palette.primary.main}`,
                                        height: "40px",
                                        width: "40px",
                                        borderRadius: '50%'
                                      }}
                                    >
                                      <img 
                                        src={
                                          item.attributes?.instructor?.profile_photo ? item.attributes?.instructor?.profile_photo
                                          : require('../../Gallery/assets/avatar.png')
                                        } 
                                        height="40px"
                                        width="40px"
                                        style={{
                                          borderRadius: '50%'
                                        }} 
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item md={9} sm={12} xs={12}>
                                    {/* @ts-ignore */}
                                    <Box
                                      style={{
                                        marginTop: '10px'
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: 'Poppins-SemiBold',
                                          fontSize: '16px',
                                          color: '#081735'
                                        }}
                                      >
                                        {item.attributes?.instructor?.name ? item.attributes?.instructor?.name : 'Dummy Name'}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    )
                  })
                  : this.state.selectedFilter == 2 ?
                  !this.state.trendingPodcasts.length ?
                    // @ts-ignore
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Poppins-SemiBold'
                        }}
                      >
                        No Trending Podcasts Found!
                      </Typography>
                    </Box>
                  :this.state.trendingPodcasts.map((item: any, index: any) => {
                    return (
                      <>
                        {/* @ts-ignore */}
                        <Box
                          style={{
                            border: "1px solid #e0e0e0",
                            padding: '30px',
                            borderRadius: '6px',
                            marginTop: '20px'
                          }}
                          display= {{sm: 'none', xs: 'none', md: 'none', lg: 'block'}}
                        >
                          <Grid container spacing={3} >
                            <Grid sm={2} md={2} xs={12}>
                              {/* @ts-ignore */}
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontFamily: 'Poppins-SemiBold',
                                      fontSize: '16px',
                                      color: '#081735'
                                    }}
                                  >
                                    {item.attributes?.view_count ? item.attributes?.view_count : '0' }
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontFamily: 'Poppins-Light',
                                      color: '#8991a4',
                                      fontSize: '12px',
                                      textTransform: 'uppercase'
                                    }}
                                  >
                                    Views
                                  </Typography>
                                </Box>
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    minWidth: '1px',
                                    minHeight: '40px',
                                    background: '#e6e6e6'
                                  }}
                                >
                                  
                                </Box>
                              </Box>
                            </Grid>
                            <Grid sm={6} md={7} xs={12}>
                              {/* @ts-ignore */}
                              <Box 
                                style={{
                                  marginLeft: '20px',
                                  marginRight: '20px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography
                                  style={{
                                    color: '#353535',
                                    fontSize: '16px',
                                    lineHeight: 1.25,
                                    fontFamily: 'Poppins-SemiBold'
                                  }}
                                >
                                  {item.attributes?.title ? item.attributes?.title : 'Dummy Title'}
                                </Typography>
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    minWidth: '1px',
                                    minHeight: '40px',
                                    background: '#e6e6e6'
                                  }}
                                >
                                  
                                </Box>
                              </Box>
                            </Grid>
                            <Grid sm={4} md={3} xs={12}>
                              {/* @ts-ignore */}
                              <Box 
                                style={{
                                  display: 'block',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item md={3} sm={12} xs={12}>
                                    {/* @ts-ignore */}
                                    <Box
                                      style={{
                                        border: `2px solid ${palette.primary.main}`,
                                        height: "40px",
                                        width: "40px",
                                        borderRadius: '50%'
                                      }}
                                    >
                                      <img 
                                        src={
                                          item.attributes?.instructor?.profile_photo ? item.attributes?.instructor?.profile_photo
                                          : require('../../Gallery/assets/avatar.png')
                                        } 
                                        height="40px"
                                        width="40px"
                                        style={{
                                          borderRadius: '50%'
                                        }} 
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item md={9} sm={12} xs={12}>
                                    {/* @ts-ignore */}
                                    <Box
                                      style={{
                                        marginTop: '10px'
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: 'Poppins-SemiBold',
                                          fontSize: '16px',
                                          color: '#081735'
                                        }}
                                      >
                                        {item.attributes?.instructor?.name ? item.attributes?.instructor?.name : 'Dummy Name'}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    )
                  })
                  : this.state.selectedFilter == 3 ?
                  !this.state.trendingArticles.length ?
                    // @ts-ignore
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Poppins-SemiBold'
                        }}
                      >
                        No Trending Articles Found!
                      </Typography>
                    </Box>
                  :this.state.trendingArticles.map((item: any, index: any) => {
                    return (
                      <>
                        {/* @ts-ignore */}
                        <Box
                          style={{
                            border: "1px solid #e0e0e0",
                            padding: '30px',
                            borderRadius: '6px',
                            marginTop: '20px'
                          }}
                          display= {{sm: 'none', xs: 'none', md: 'none', lg: 'block'}}
                        >
                          <Grid container spacing={3} >
                            <Grid sm={2} md={2} xs={12}>
                              {/* @ts-ignore */}
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontFamily: 'Poppins-SemiBold',
                                      fontSize: '16px',
                                      color: '#081735'
                                    }}
                                  >
                                    {item.attributes?.view_count ? item.attributes?.view_count : '0' }
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontFamily: 'Poppins-Light',
                                      color: '#8991a4',
                                      fontSize: '12px',
                                      textTransform: 'uppercase'
                                    }}
                                  >
                                    Views
                                  </Typography>
                                </Box>
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    minWidth: '1px',
                                    minHeight: '40px',
                                    background: '#e6e6e6'
                                  }}
                                >
                                  
                                </Box>
                              </Box>
                            </Grid>
                            <Grid sm={6} md={7} xs={12}>
                              {/* @ts-ignore */}
                              <Box 
                                style={{
                                  marginLeft: '20px',
                                  marginRight: '20px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography
                                  style={{
                                    color: '#353535',
                                    fontSize: '16px',
                                    lineHeight: 1.25,
                                    fontFamily: 'Poppins-SemiBold'
                                  }}
                                >
                                  {item.attributes?.title ? item.attributes?.title : 'Dummy Title'}
                                </Typography>
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    minWidth: '1px',
                                    minHeight: '40px',
                                    background: '#e6e6e6'
                                  }}
                                >
                                  
                                </Box>
                              </Box>
                            </Grid>
                            <Grid sm={4} md={3} xs={12}>
                              {/* @ts-ignore */}
                              <Box 
                                style={{
                                  display: 'block',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item md={3} sm={12} xs={12}>
                                    {/* @ts-ignore */}
                                    <Box
                                      style={{
                                        border: `2px solid ${palette.primary.main}`,
                                        height: "40px",
                                        width: "40px",
                                        borderRadius: '50%'
                                      }}
                                    >
                                      <img 
                                        src={
                                          item.attributes?.instructor?.profile_photo ? item.attributes?.instructor?.profile_photo
                                          : require('../../Gallery/assets/avatar.png')
                                        } 
                                        height="40px"
                                        width="40px"
                                        style={{
                                          borderRadius: '50%'
                                        }} 
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item md={9} sm={12} xs={12}>
                                    {/* @ts-ignore */}
                                    <Box
                                      style={{
                                        marginTop: '10px'
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: 'Poppins-SemiBold',
                                          fontSize: '16px',
                                          color: '#081735'
                                        }}
                                      >
                                        {item.attributes?.instructor?.name ? item.attributes?.instructor?.name : 'Dummy Name'}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    )
                  })
                  : this.state.selectedFilter == 4 ?
                  !this.state.trendingVideos.length ?
                    // @ts-ignore
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px'
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Poppins-SemiBold'
                        }}
                      >
                        No Trending Videos Found!
                      </Typography>
                    </Box>
                  :this.state.trendingVideos.map((item: any, index: any) => {
                    return (
                      <>
                        {/* @ts-ignore */}
                        <Box
                          style={{
                            border: "1px solid #e0e0e0",
                            padding: '30px',
                            borderRadius: '6px',
                            marginTop: '20px'
                          }}
                          display= {{sm: 'none', xs: 'none', md: 'none', lg: 'block'}}
                        >
                          <Grid container spacing={3} >
                            <Grid sm={2} md={2} xs={12}>
                              {/* @ts-ignore */}
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontFamily: 'Poppins-SemiBold',
                                      fontSize: '16px',
                                      color: '#081735'
                                    }}
                                  >
                                    {item.attributes?.view_count ? item.attributes?.view_count : '0' }
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontFamily: 'Poppins-Light',
                                      color: '#8991a4',
                                      fontSize: '12px',
                                      textTransform: 'uppercase'
                                    }}
                                  >
                                    Views
                                  </Typography>
                                </Box>
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    minWidth: '1px',
                                    minHeight: '40px',
                                    background: '#e6e6e6'
                                  }}
                                >
                                  
                                </Box>
                              </Box>
                            </Grid>
                            <Grid sm={6} md={7} xs={12}>
                              {/* @ts-ignore */}
                              <Box 
                                style={{
                                  marginLeft: '20px',
                                  marginRight: '20px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography
                                  style={{
                                    color: '#353535',
                                    fontSize: '16px',
                                    lineHeight: 1.25,
                                    fontFamily: 'Poppins-SemiBold'
                                  }}
                                >
                                  {item.attributes?.title ? item.attributes?.title : 'Dummy Title'}
                                </Typography>
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    minWidth: '1px',
                                    minHeight: '40px',
                                    background: '#e6e6e6'
                                  }}
                                >
                                  
                                </Box>
                              </Box>
                            </Grid>
                            <Grid sm={4} md={3} xs={12}>
                              {/* @ts-ignore */}
                              <Box 
                                style={{
                                  display: 'block',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item md={3} sm={12} xs={12}>
                                    {/* @ts-ignore */}
                                    <Box
                                      style={{
                                        border: `2px solid ${palette.primary.main}`,
                                        height: "40px",
                                        width: "40px",
                                        borderRadius: '50%'
                                      }}
                                    >
                                      <img 
                                        src={
                                          item.attributes?.instructor?.profile_photo ? item.attributes?.instructor?.profile_photo
                                          : require('../../Gallery/assets/avatar.png')
                                        } 
                                        height="40px"
                                        width="40px"
                                        style={{
                                          borderRadius: '50%'
                                        }} 
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item md={9} sm={12} xs={12}>
                                    {/* @ts-ignore */}
                                    <Box
                                      style={{
                                        marginTop: '10px'
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: 'Poppins-SemiBold',
                                          fontSize: '16px',
                                          color: '#081735'
                                        }}
                                      >
                                        {item.attributes?.instructor?.name ? item.attributes?.instructor?.name : 'Dummy Name'}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    )
                  })
                  : null
                }
                <Grid container spacing={3}>
                  {
                    this.state.loading ?
                      // @ts-ignore
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: 'Poppins-SemiBold'
                          }}
                        >
                          {/* Loading... */}
                        </Typography>
                      </Box>
                    : !this.state.trendingData.length ?
                      // @ts-ignore
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                        display= {{sm: 'block', xs: 'block', md: 'block', lg: 'none'}}
                      >
                        <Typography
                          style={{
                            fontFamily: 'Poppins-SemiBold'
                          }}
                        >
                          {/* {
                            this.state.selectedFilter == 1 
                            ? 'No Trending Courses Found!'
                            : this.state.selectedFilter == 2
                            ? 'No Trending Podcasts Found!'
                            : this.state.selectedFilter == 3
                            ? 'No Trending Articles Found!'
                            : 'No Trending Videos Found!'
                          } */}
                        </Typography>
                      </Box>
                    : this.state.trendingData.length && this.state.trendingData.map((item: any, index: any) => {
                      return (
                        <>
                          <Grid item xs={12} sm={6}>
                            {/* @ts-ignore */}
                            <Box
                              style={{
                                border: "1px solid #e0e0e0",
                                padding: '30px',
                                borderRadius: '6px'
                              }}
                              display= {{sm: 'block', xs: 'block', md: 'block', lg: 'none'}}
                            >
                              {/* @ts-ignore */}
                              <Box
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    border: `2px solid ${palette.primary.main}`,
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: '50%'
                                  }}
                                >
                                  <img 
                                    src={
                                      item.attributes?.instructor?.profile_photo 
                                      ? item.attributes?.instructor?.profile_photo
                                      : require('../../Gallery/assets/avatar.png')} 
                                    height="40px"
                                    width="40px"
                                    style={{
                                      borderRadius: '50%'
                                    }} 
                                  />
                                </Box>
                                {/* @ts-ignore */}
                                <Box
                                  style={{
                                    marginLeft: '20px'
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontFamily: 'Poppins-SemiBold',
                                      fontSize: '16px',
                                      color: '#081735'
                                    }}
                                  >
                                    {
                                      item.attributes?.instructor?.name
                                      ? item.attributes?.instructor?.name
                                      : 'Dummy Name'
                                    }
                                  </Typography>
                                </Box>
                              </Box>
                              {/* @ts-ignore */}
                              <Box
                                style={{
                                  marginTop: '20px'
                                }}
                              >
                                <Typography
                                  style={{
                                    color: '#353535',
                                    fontSize: '16px',
                                    lineHeight: 1.25,
                                    fontFamily: 'Poppins-SemiBold'
                                  }}
                                >
                                  {
                                    item.attributes?.title 
                                    ? item.attributes?.title
                                    : 'Dummy Title'
                                  }
                                </Typography>
                              </Box>
                              {/* @ts-ignore */}
                              <Box
                                style={{
                                  display: 'flex',
                                  marginTop: '10px'
                                }}
                              >
                                <Typography
                                  style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    fontSize: '16px',
                                    color: '#081735'
                                  }}
                                >
                                  Views :
                                </Typography>
                                <Typography
                                  style={{
                                    fontFamily: 'Poppins-Light',
                                    color: '#8991a4',
                                    fontSize: '12px',
                                    textTransform: 'uppercase',
                                    marginLeft: '10px',
                                    marginTop: '3px'
                                  }}
                                >
                                  {
                                    item.attributes?.view_count 
                                    ? item.attributes?.view_count 
                                    : '0'
                                  }
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      )
                    })
                  }
                </Grid>
              </Box>
            </CustomCard>
          </Box>
        </Box>
      </div>
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(Dashboard);
// Customizable Area End
