import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import BlogCarousel from "../../../components/src/BlogCarousel";
import BlogCard1 from "../../../components/src/BlogCard1"
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { experienceImg } from "../../CarouselDisplay/src/assets";
import { BlogCardImg } from "../../CarouselDisplay/src/assets";
import { carouselImg } from "../../CarouselDisplay/src/assets";
import { Pagination } from "@material-ui/lab";
import BlogFeatureCard from "../../../components/src/BlogFeatureCard";
import BlogCard2 from "../../../components/src/BlogCard2";
import { blogImg1, blogImg10, blogImg11, blogImg12, blogImg2, blogImg3, blogImg4, blogImg5, blogImg6, blogImg7, blogImg8, blogImg9, featureAuthorImg, featureBlogImg1, featureBlogImg2, lifeStyleAuthorBlogImg, lifeStyleBlogImg1, lifeStyleBlogImg2, lifeStyleBlogImg3, lifeStyleBlogImg4 } from "./assets";
import moment from "moment";
import { palette } from "../../../components/src/theme";
import FavouriteIcon from "../../../components/src/FavouriteIcon";
import CustomLoader from "../../../components/src/CustomLoader";
import AlertModal from "../../../components/src/AlertModal";
import BlogFeatureCarousel from "../../../components/src/BlogFeatureCarousel";
import CustomHoverBtn from "../../../components/src/CustomHoverBtn";
export const timeFormatter = (date: string) => {
    if (!date) return;
    return moment.utc(date, "YYYY-MM-DD HH:mm:ss").local().format('hh:mm a');
}

export const dateFormatter = (date: string) => {
    if (!date) return;
    return moment.utc(date, "YYYY-MM-DD HH:mm:ss").local().format('MMM D');
}

export const fullDateFormatter = (date: string) => {
    if (!date) return;
    return moment.utc(date, "YYYY-MM-DD HH:mm:ss").local().format('D MMM YYYY');
}
export const getAge = (dateString: string) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let d1 = birthDate.getDate();
    let m1 = birthDate.getMonth();
    let y1 = birthDate.getFullYear();
    let d2 = today.getDate();
    let m2 = today.getMonth();
    let y2 = today.getFullYear();
    let month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (d1 > d2) {
        d2 = d2 + month[m2 - 1];
        m2 = m2 - 1;
    }
    if (m1 > m2) {
        m2 = m2 + 12;
        y2 = y2 - 1;
    }
    const d = d2 - d1;
    const m = m2 - m1;
    const y = y2 - y1;


    if (y > 0) {
        return ('' + y + ' years ' + m + ' months ' + d + ' days');
    }
    if (y == 0 && m > 0) {
        return (+m + ' months ' + d + ' days');
    }
    if (y == 0 && m == 0) {
        return (+d + ' days');
    }
}


// Customizable Area End

import BlogPageController, {
    Props,
    configJSON
} from "./BlogPageController";

class BlogPage extends BlogPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    changePage = (event: React.ChangeEvent<unknown>, value: number) => {
        // @ts-ignore
        document.getElementById('pag').scrollIntoView()
        const { getBlogLoading } = this.state
        if (!getBlogLoading) {
            this.getBlogs(value);
        }
    };


    getCatList = (categoryList: any, selectedCategory: any) => {
        return (
            // istanbul ignore next
            categoryList?.map((item: any) => (
                <Grid key={item?.id} item>
                    <CustomHoverBtn
                        selected={selectedCategory?.id === item.id}
                        handleClick={() => this.changedSelectedCategory(item)}
                        color={ palette.primary.main}
                        br="none"
                        fullWidth
                        title={
                            item.attributes.name
                        }
                    />

                </Grid>
            ))
        )
    }

    getBlogLoadingUI = (getBlogLoading: any, classes: any, currentPage: any, blogList: any,blogPerPage: any) => {
        return (
            // @ts-ignore
            getBlogLoading ? <Box style={{ textAlign: "center" }}> <CircularProgress /> </Box> : <Grid
            container
            spacing={3} className={classes.cardCenter}>
            {
                // istanbul ignore next
                blogList?.blogs?.data.length === 0 ? <Typography style={{ textAlign: "center", fontFamily: "Poppins-SemiBold", }}>No data found</Typography> : blogList?.blogs?.data
                    ?.slice((currentPage - 1) * blogPerPage, (currentPage - 1) * blogPerPage + blogPerPage)
                    ?.map((data: any) =>
                        <Grid key={data?.id} item md={4} sm={6} xs={12} style={{ width: "100%" }}>
                            <FavouriteIcon 
                                isFavourite={data.attributes.favourite}
                                position={'absolute'}
                                right={'10px'}
                                top={'10px'}
                                makeFavourite={(status: any) => this.makeFavourite(data.id, status)}
                                navigation={this.props.navigation}
                            />
                            <BlogCard1 item={data} gotToSingleArticle={this.goToSingleArticle} />
                        </Grid>
                    )
            }
        </Grid>
        )
    }

    closeAlertBox = () => {
        this.setState({
            openAlert: false
        })
    }

    getAllBtn = (selectedCategory: any) => {
        return (
            <Button
                variant="outlined"
                style={{
                    color: !selectedCategory?.id ? "#ffffff" : palette.primary.main,
                    borderColor: palette.primary.main,
                    minWidth: '10vw',
                    width: "100%",
                    fontFamily: 'Poppins-Light',
                    textTransform: 'capitalize',
                    background: !selectedCategory?.id ? palette.primary.main : ""
                }}
                onClick={() => this.setAllArticleCategory()}
            >
                All articles
            </Button>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { blogList, getBlogLoading, categoryList, blogPerPage, currentPage, selectedCategory, windowWidth } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            // @ts-ignore
            <Box className={classes.mainWrapper}>
                {
                    this.state.openAlert ?
                        <AlertModal 
                            open={this.state.openAlert}
                            data={this.state.updateMessage}
                            onClose={() => this.closeAlertBox()}
                        />
                    :   null
                }
                {
                    this.state.loading ?
                    <CustomLoader 
                        open={this.state.loading}
                    />
                    : null
                }
                <Typography style={{ color: "#F27024", fontFamily: "Poppins-Light" }} gutterBottom><span style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate("Home")}>Home</span> / <span style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate("Home")}>Let's Read</span> / <span style={{ color: "grey" }}>Articles</span></Typography>
                {/* @ts-ignore */}
                <Box className={classes.whiteBorder} style={{margin: "0 0 45px"}} />
                {/* @ts-ignore */}
                <Box>
                    {getBlogLoading ? <Typography style={{ fontFamily: "Poppins-SemiBold", width: "100%", textAlign: "center" }}>Loading...</Typography> : <BlogCarousel carouselDt={blogList?.carousel_blogs} />}
                </Box>
                {/* @ts-ignore */}
                <Box style={{margin: windowWidth < 600 ? "45px 0 0 0" : "45px 0 45px" }} className={classes.whiteBorder} />
                {/* @ts-ignore */}
                <Box style={{ margin: "2rem 0", textAlign: "center" }} id="pag">
                    <Grid container spacing={2} style={{ width: '100%' }}>
                        {!getBlogLoading && (
                            <Grid item>
                                <CustomHoverBtn
                                    selected={!selectedCategory?.id}
                                    handleClick={() => this.setAllArticleCategory()}
                                    color={palette.primary.main}
                                    br="none"
                                    fullWidth
                                    title="All articles"
                                />
                            </Grid>
                        )}
                        {this.getCatList(categoryList, selectedCategory)}
                    </Grid>
                </Box>
                {/* @ts-ignore */}
                <Box className={classes.Card1Style} >

                    {
                        this.getBlogLoadingUI(getBlogLoading, classes, currentPage, blogList, blogPerPage)
                    }
                </Box>
                {/* @ts-ignore */}
                <Box
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "30px",
                        marginTop: "30px",
                    }}
                >
                    {getBlogLoading ? "" : blogList?.blogs?.meta?.pagination?.total_pages === 1 ? "" : <Pagination
                        variant="outlined"
                        size={windowWidth < 600 ? "medium" : "large"}
                        count={blogList?.blogs?.meta?.pagination?.total_pages}
                        // defaultPage={1}
                        page={blogList?.blogs?.meta?.pagination?.current_page}
                        onChange={this.changePage}
                        className={classes.root}
                        siblingCount={0}
                    />}
                </Box>
                {/* @ts-ignore */}
                <Box className={classes.Card1Style} >
                    <Typography component="div" gutterBottom style={{ fontFamily: "Poppins-SemiBold", color: "#1c1c1c" }} className={classes.headerSize}>
                        Other popular articles</Typography>
                    <BlogFeatureCarousel carouselDt={ blogList?.featured_blogs} gotToSingleArticle={this.goToSingleArticle} />
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            typography: {
                fontFamily: ['Poppins', 'sans-serif'].join(',')
            },
            mainWrapper: {
                fontFamily: 'Poppins',
                padding: '2% 8%',
                [theme.breakpoints.down("xs")]: {
                    padding: '5% 6%'
                },
                background: '#fff',
            },
            varticleLine: {
                width: "2px",
                height: "200px",
                margin: "84.9px 26px 281px 47px",
                opacity: 0.35,
                borderRadius: "1px",
                backgroundImage: "radial-gradient(circle at 50% 54%, #f00, #ffffff 84%)",
                [theme.breakpoints.down("sm")]: {
                    display: "none"
                },

            },

            Card1Style: {
                margin: "5rem 0"
            },
            cardCenter: {
                [theme.breakpoints.down("md")]: {
                    // alignItems: "center",
                    justifyContent: "center"
                },
            },

            title: {
                textAlign: "right",
                [theme.breakpoints.down("xs")]: {
                    textAlign: "left",
                },
            },
            tags: {
                fontFamily: "Poppins-SemiBold",
                color: "#F27024",
                border: "1px solid #F27024",
                padding: "6px 30px",
                textTransform: "initial",
            },
            tagSelected: {
                color: "#fff",
                background: "#F27024"
            },
            root: {
                color: "#F27024 !important",
                "& .Mui-selected": {
                    background: "#F27024",
                    color: "white",
                    fontWeight: "bold",
                },
                ul: {
                    "& .MuiPaginationItem-root": {
                        color: "#F27024"
                    }
                }
            },
            whiteBorder: {
                width: "100%",
                height: "1px",
                // margin: window.innerWidth < 600 ? "0 0 0" : "0 0 45px",
                backgroundColor: "#e6e6e6"
            },
            headerSize: {
                fontSize: "1.8rem",
                [theme.breakpoints.down("sm")]: {
                    fontSize: "1.5rem",
                },
            },

        })
    )(BlogPage)
)
// Customizable Area End
