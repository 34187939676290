import React from "react";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    IconButton,
    Toolbar,
    Button,
    AppBar,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Menu,
    MenuItem,
    Avatar,
    Divider,
    ListItemIcon,
    Modal,
    TextField,
    Container
} from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu';
import BarChart from '@material-ui/icons/BarChart';
import Visibility from '@material-ui/icons/Visibility';
import Link from '@material-ui/icons/Link';
import Send from '@material-ui/icons/Send';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Restore from '@material-ui/icons/Restore';
import Settings from '@material-ui/icons/Settings';
import AddNewArticleController, { Props } from "./AddNewArticleController";
import CustomInput from "../../../../components/src/CustomInput";
import CustomCard from "../../../../components/src/CustomCard";
import { palette } from "../../../../components/src/theme";
import CustomButton from "../../../../components/src/CustomButton";
import SortingHeader from "../../../../components/src/SortingHeader";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment";
import { BlogType } from "../../../ContentManagement/src/Interfaces.web";
import CustomDrop from "../../../../components/src/CustomDrop";
import DashboardSetting from "../../../../components/src/DashboardSetting";
import AlertModal from "../../../../components/src/AlertModal";
import PreviewFE from "../../../../components/src/PreviewFE";
import CustomLoader from "../../../../components/src/CustomLoader";
const drawerWidth = 240;


// Customizable Area End

class AddNewArticle extends AddNewArticleController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    getBold = (value: any) => {
        this.setState({
            bold: value
        })
    }

    getUnderlined = (value: any) => {
        this.setState({
            underlined: value
        })
    }

    getItalic = (value: any) => {
        this.setState({
            italic: value
        })
    }
    getAlignment = (value: any) => {
        this.setState({
            alignment: value
        })
    }
    getAppBar = (classes: any, openSetting: any) => {
        return (
            <AppBar position='fixed' className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.logo}>
                        <img src={require('../../../../components/src/logo.svg')} height={70} />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'space-between',
                            padding: '10px'
                        }}
                    >
                        <div>
                            <Typography
                                style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    fontSize: '40px',
                                    color: '#353535',
                                    marginTop: "25px"
                                }}
                                className={classes.welcome}
                            >
                                Add New
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: "center"
                            }}
                        >
                            <Typography onClick={() => this.handleDraft()} style={{ cursor: "pointer", fontFamily: "Poppins-Bold", color: "grey", marginTop: "25px" }}>Save draft</Typography>
                            <Button variant="contained" onClick={() => this.handlePublish()} style={{ backgroundColor: "#F27024", color: "#ffffff", marginTop: "25px", marginLeft: '20px', textTransform: "initial", fontFamily: "Poppins-Bold" }}>Publish</Button>
                            <IconButton
                                onClick={() => this.hanldeSettingToggle()}
                                style={{
                                    border: '1px solid #bdbdbd',
                                    height: '38px',
                                    width: '38px',
                                    borderRadius: '8px',
                                    marginTop: '25px',
                                    marginLeft: '20px',
                                    backgroundColor: openSetting ? "#F27024" : "#ffffff"
                                }}
                            >
                                <img src={require('../../assets/settings-unselectednew.png')} height={'38px'} />
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    getNextForm = (el: any, classes: any, openSetting: any, inputError: any) => {
        return (
            <Container key={el.id}>
                <div style={{ width: openSetting ? "calc(100% - 300px)" : "100%", border: "1px solid rgba(82,92, 229, 0.12)", margin: "5px", padding: "5px", borderRadius: "5px", marginBottom: "10px" }}>
                    {
                        this.state.blogList.length >= 2 ?
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IconButton onClick={() => this.removeBlog(el.id)}>
                                    <DeleteOutlineIcon style={{ color: "#ffffff", background: "#d56036", cursor: "pointer", borderRadius: "50%" }} fontSize="large" />
                                </IconButton>
                            </div>
                            :
                            ""
                    }
                    <TextField
                        fullWidth
                        name="Heading"
                        value={el.heading.text}
                        onChange={(e) => this.handleHeadingChange(el.id, e.target.value)}
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true
                        }}
                        style={{
                            fontSize: "4rem",
                            fontFamily: "Poppins-Light",
                            marginTop: "10px"
                        }}
                        placeholder="Heading"
                        error={inputError?.heading === undefined || inputError?.heading === "" ? false : true}
                        helperText={inputError.heading}
                    />
                    <TextField
                        fullWidth
                        name="Subheading"
                        value={el.subheading.text}
                        onChange={(e) => this.handleSubheadingChange(el.id, e.target.value)}
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true
                        }}
                        style={{
                            fontSize: "2rem",
                            fontFamily: "Poppins-Light",
                            marginTop: "10px"
                        }}
                        placeholder="Subheading"
                    />
                    <TextField
                        name="paragraph"
                        style={{ fontSize: "16px", fontFamily: "Poppins-Light", paddingTop: "10px" }}
                        multiline={true}
                        minRows={10}
                        fullWidth
                        value={el.para.text}
                        onChange={(e) => this.handleParagraphChange(el.id, e.target.value)}
                        inputProps={{ maxLength: 1250 }}
                        placeholder="Paragraph"
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true
                        }}
                        error={inputError?.para === undefined || inputError?.para === "" ? false : true}
                        helperText={inputError.para}
                    />
                    <div style={{ marginTop: "10px" }}>
                        {/* @ts-ignore */}
                        <CustomDrop handleImgChange={this.handleImgChange} imgId={el.id} imagesList={el.img} />
                    </div>
                    {
                        el.img.length ? <TextField
                            fullWidth
                            name="caption"
                            value={el.caption.text}
                            onChange={(e) => this.handleCaptionChange(el.id, e.target.value)}
                            InputProps={{
                                className: classes.input,
                                disableUnderline: true
                            }}
                            style={{
                                fontSize: "1.5rem",
                                fontFamily: "Poppins-Light",
                                marginTop: "10px"
                            }}
                            placeholder="Caption"
                        />
                            : ""
                    }

                </div>
            </Container>
        )
    }

    getFirstForm = (openSetting: any, blogUniqueItem: any, classes: any, inputError: any) => {
        return (
            <Container >
                <div style={{ width: openSetting ? "calc(100% - 300px)" : "100%" }}>
                    <TextField
                        fullWidth
                        name="Title"
                        id="Title"
                        value={blogUniqueItem.title}
                        onChange={(e) => this.handleBlogUniqueItemChange(e, "title")}
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true
                        }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        style={{
                            fontSize: "4rem",
                            fontFamily: "Poppins-Light",
                            marginTop: "10px"
                        }}
                        placeholder="Title"
                        error={inputError?.title === undefined || inputError?.title === "" ? false : true}
                        helperText={inputError.title}
                    />
                    <TextField
                        fullWidth
                        name="Subtitle"
                        id="Subtitle"
                        value={blogUniqueItem.subtitle}
                        onChange={(e) => this.handleBlogUniqueItemChange(e, "subtitle")}
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true
                        }}
                        inputProps={{min: 0, style: { textAlign: 'center' }}}
                        style={{
                            fontSize: "2rem",
                            fontFamily: "Poppins-Light",
                            marginTop: "10px"
                        }}
                        placeholder="Subtitle"
                        error={inputError?.subtitle === undefined || inputError?.subtitle === "" ? false : true}
                        helperText={inputError.subtitle}
                    />
                </div>
            </Container>
        )
    }
    getAddNewBlogBtn = () => {
        return (
            <Container>
                <IconButton onClick={(e) => this.addNewBlog()} style={{ marginTop: '5px', marginBottom: "2rem", }}>
                    <AddIcon style={{ color: "#ffffff", background: "#F27024", cursor: "pointer", borderRadius: "50%" }} fontSize="large" />
                </IconButton>
            </Container>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        const { classes } = this.props;
        const { 
            mobileOpen, 
            previewModal, 
            errorMsg, 
            blogList, 
            openAlert, 
            categoryList, 
            getCategoryLoading, 
            dataCreationMsg, 
            selectecCategory, 
            blogUniqueItem, 
            openSetting, 
            authorList, 
            authorLoading, 
            sort, 
            dateTime, 
            inputError,
            bold,
            underlined,
            italic,
            alignment,
            createLoading
        } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                {
                    createLoading ?
                        <CustomLoader
                            open={createLoading}
                        />
                    :   null
                }
                {/* @ts-ignore */}
                {openAlert ? (
                    <AlertModal
                        open={openAlert}
                        data={dataCreationMsg ? dataCreationMsg : errorMsg}
                        onClose={() => this.closeAlertBox()}
                        redirectUri={'LetsRead'}
                        navigation={this.props.navigation}
                    />
                ) : null}
                {/* @ts-ignore */}
                <Modal
                    open={previewModal}
                    onClose={() => this.handleModalClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {/* @ts-ignore */}
                    <Box className={classes.previewModalStyle}>
                        <PreviewFE 
                            components={blogList} 
                            titelSubtitle={blogUniqueItem} 
                            date={dateTime} 
                            bold={bold}
                            underlined={underlined}
                            italic={italic}
                            alignment={alignment}
                        />
                    </Box>
                </Modal>

                <DashboardDrawer
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={this.handleDrawerToggle}
                    // @ts-ignore
                    navigation={this.props.navigation}
                />
                {
                    // @ts-ignore
                    openSetting && <DashboardSetting
                        handleDrawerToggle={this.handleDrawerToggle}
                        // @ts-ignore
                        navigation={this.props.navigation}
                        categoryList={categoryList}
                        authorList={authorList}
                        authorLoading={authorLoading}
                        handleSelectCategory={this.handleCategorySelect}
                        selectedCategory={selectecCategory}
                        errorMsg={errorMsg}
                        categoryLoading={getCategoryLoading}
                        handleBgImgChange={this.handleBgImgChange}
                        handleSortChange={this.handleSortChange}
                        sort={sort}
                        dateTime={dateTime}
                        handleDateTimechange={this.handleDateTimeChange}
                        getBold={this.getBold}
                        getUnderlined={this.getUnderlined}
                        getItalic={this.getItalic}
                        getAlignment= {this.getAlignment}
                        type={'article'}
                        from={'blog'}
                        catValidation={this.state.catValidation}
                        othersName={this.state.othersName}
                        handleSelectOthers={this.handleSelectOthers}
                    />
                }
                {/* @ts-ignore */}
                <Box
                    className={classes.appBarWrap}
                >
                    {
                        this.getAppBar(classes, openSetting)
                    }
                    {/* @ts-ignore */}
                    {!this.state.nextPage && <>
                        <div style={{ position: 'relative', height: "5rem", boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)', width: openSetting ? "calc(100% - 300px)" : "100%" }}>
                            {blogUniqueItem?.title && blogUniqueItem.subtitle ? <Button variant="outlined" style={{ position: "absolute", right: "25px", top: "23px", padding: "5px 25px", color: "#F27024", border: "1px solid #F27024", textTransform: "initial", fontFamily: "Poppins-SemiBold" }} onClick={() => this.setState({ nextPage: true })}>Next</Button> : ""}
                        </div>
                        {
                            this.getFirstForm(openSetting, blogUniqueItem, classes, inputError)
                        }
                    </>}
                    {this.state.nextPage && <>
                        <div style={{ position: 'relative', height: "5rem", boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)', width: openSetting ? "calc(100% - 300px)" : "100%" }}>
                            {blogList[0]?.heading?.text && blogList[0].para?.text && blogUniqueItem?.bgImg?.length ? <Button variant="outlined" onClick={() => this.handlePreviewOpen()} style={{ position: "absolute", right: "25px", top: "23px", color: "#F27024", border: "1px solid #F27024", textTransform: "initial", fontFamily: "Poppins-SemiBold" }}>Preview</Button> : ""}
                            <Button variant="outlined" style={{ position: "absolute", left: "30px", top: '23px', color: "#F27024", border: "1px solid #F27024", textTransform: "initial", fontFamily: "Poppins-SemiBold" }} onClick={() => this.setState({ nextPage: false })}>Previous</Button>
                        </div>
                        {
                            this.state.blogList.map((el) => (
                                this.getNextForm(el, classes, openSetting, inputError)
                            ))
                        }
                        {
                            this.getAddNewBlogBtn()
                        }
                    </>}

                </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// export default withStyles(styles)(LetsRead);
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            logo: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            menuButton: {
                marginRight: theme.spacing(2),
                color: palette.primary.main,
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            appBar: {
                background: '#fff',
                boxShadow: 'none',
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                    background: '#fff',
                    boxShadow: 'none'
                },
            },
            appBarWrap: {
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                },
            },
            welcome: {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            name: {
                fontSize: '24px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '20px',
                    fontSize: '16px'
                },
            },
            tabsBox: {
                // flex: 1,
                // flexDirection: 'row',
                // justifyContent: 'space-between',
                width: '100%'
            },
            tabs_main: {
                "& .MuiTabs-indicator": {
                    backgroundColor: palette.primary.main,
                    borderRadius: '1.5px',
                    height: '4px',
                },
                "& .Mui-selected": {
                    color: palette.primary.main
                }
            },
            headingTabs: {
                fontSize: '24px',
                fontWeight: 600,
                fontFamily: palette.fontFamily.main,
                width: "25%",
                textTransform: 'inherit',
                // minWidth: '50% !important'
            },
            lastTableRow: {
                '&:last-child td, &:last-child th': { border: 0 }
            },
            triangle: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.03))',
                marginTop: "49px",
                marginLeft: "12px",
                borderRadius: "10px",
                '& .MuiAvatar-root': {
                    width: '32px ',
                    height: '32px',
                    marginLeft: '-0.5px ',
                    marginRight: '1px',
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: '0px',
                    right: '20px ',
                    width: '15px',
                    height: '15px ',
                    backgroundColor: '#ffffff',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            },
            root: {
                color: "#F27024 !important",
                "& .Mui-selected": {
                    background: "#F27024",
                    color: "white",
                    fontWeight: "bold",
                },
                ul: {
                    "& .MuiPaginationItem-root": {
                        color: "#F27024"
                    }
                }
            },
            modalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '496px',
                height: "200px",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 5px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            previewModalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '796px',
                maxHeight: "80vh",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '20px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            menuItemStyle: {
                fontFamily: 'Poppins-Light',
                '&:hover': {
                    textAlign: "right",
                    color: '#d53636',
                    backgroundColor: '#ffffff',
                }
            },
            input: {
                width: "100%",
                height: "100%",
                border: "1px solid rgba(82,92, 229, 0.12)",
                borderRadius: "7px",
                textDecoration: "none",
                fontSize: "1em",
                fontFamily: "Poppins-Light",
                backgroundColor: "white",
                color: '#000 !important',
                padding: "5px 1rem", // 
                "& > .MuiInputBase-input": {
                    height: "100%",
                },
                "&  .MuiFormHelperText-root.Mui-error": { 
                    fontFamily: "Poppins-Light",
                  },
            },


        }))(AddNewArticle)
)
// Customizable Area End
