import React from "react";

// Customizable Area Start
// istanbul ignore file
import {
    Button,
    Typography,
    Grid,
    CircularProgress,
    IconButton,
    Container,
} from '@material-ui/core';
import Box from '@material-ui/core/Box'
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import VideoCard from "../../../components/src/VideoCard";

// Customizable Area End

import LetsWatchHomeController, {
    Props,
    configJSON
} from "./LetsWatchHomeController";


class OtherVideo extends LetsWatchHomeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    changePage = (event: React.ChangeEvent<unknown>, value: number) => {
        // @ts-ignore
        document.getElementById('pag').scrollIntoView()
        const { videoLoading } = this.state
        if (!videoLoading) {
            this.getVideos('',value);
        }
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { videoList, videoLoading, videosPerPage, currentPage, selectedCategory } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                <Box className={classes.mainWrapper}>
                    <Typography style={{ color: "#F27024", fontFamily: "Poppins-Light" }} gutterBottom><span style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate("Home")}>Home</span> / <span style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate("Videos")} >Videos</span> / <span style={{ color: "grey" }} >Other videos you may like</span></Typography>
                    <Box className={classes.whiteBorder} />
                    <Box id="pag" className={classes.Card1Style} >
                        <Typography component="div" className={classes.headerSize} gutterBottom style={{ fontFamily: "Poppins-SemiBold", marginBottom: "2.5rem" }}>Other videos you may like</Typography>

                        {
                            videoLoading ? <Box style={{ textAlign: "center" }}> <CircularProgress /> </Box> : <Grid
                                container
                                spacing={5} className={classes.cardCenter}>
                                {

                                    videoList?.other_videos
                                        ?.map((data: any) =>
                                            <Grid key={data?.id} item md={4} sm={6} xs={12} style={{ width: "100%" }} >
                                                <VideoCard item={data} gotToSingleArticle={this.goToSingleVideo} />
                                            </Grid>
                                        )
                                }
                            </Grid>}
                    </Box>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            mainWrapper: {
                fontFamily: 'Poppins',
                padding: '2% 8%',
                [theme.breakpoints.down("xs")]: {
                    padding: '2% 12%'
                },
                background: '#fff',
            },
            Card1Style: {
                margin: "3rem 0"
            },
            cardCenter: {
                [theme.breakpoints.down("md")]: {
                    justifyContent: "center"
                },
            },

            title: {
                textAlign: "right",
                [theme.breakpoints.down("xs")]: {
                    textAlign: "left",
                },
            },
            root: {
                color: "#F27024 !important",
                "& .Mui-selected": {
                    background: "#F27024",
                    color: "white",
                    fontWeight: "bold",
                },
                ul: {
                    "& .MuiPaginationItem-root": {
                        color: "#F27024"
                    }
                }
            },
            whiteBorder: {
                width: "100%",
                height: "1px",
                margin: "0 0 35px",
                backgroundColor: "#e6e6e6"
            },
            headerSize: {
                fontSize: "1.8rem",
                [theme.breakpoints.down("sm")]: {
                    fontSize: "1rem",
                },
            },
            seeAll: {
                fontSize: "1.5rem",
                [theme.breakpoints.down("sm")]: {
                    fontSize: ".8rem",
                },
            }

        })
    )(OtherVideo)
)
// Customizable Area End
