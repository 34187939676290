import React from "react";

// Customizable Area Start
import { 
    Typography, 
    Box,
    Container,
    Grid,
    Menu,
    MenuItem
  } from '@material-ui/core';
  import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
  import FullCalendar from '@fullcalendar/react';
  import dayGridPlugin from '@fullcalendar/daygrid';
  // @ts-ignore
  import styled from 'styled-components';
  import { palette } from "../../../components/src/theme";
  import interactionPlugin from "@fullcalendar/interaction";
  import Breadcrumb from "../../../components/src/BreadCrumb";



  const StyledWrapper = styled.div`
  * {
    font-family : Poppins-SemiBold;
    color: #353535;
  }
  .fc-day-today .fc-daygrid-day-frame {
    background: #ffffff
  }
  .fc-daygrid-day-number {
    color: #545454;
    font-weight: 500;
    font-size: 12px;
    font-family: Poppins-SemiBold;
  }
  .fc-day-today .fc-daygrid-day-number{
    color: #fff;
    padding:0.3em;
    background: #f27024;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fc-timegrid-slot-label-cushion{
    text-transform: uppercase;
  }
  .fc-timegrid-axis-cushion, .fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: center;
  }
  .fc-timegrid-slot-minor {
    border: 0!important;
  }
  .fc-col-header {
    background: #ddd;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .fc-col-header-cell {
    height: 40px;
    align-items: center;
    border: 0px;
    border-bottom: 1px solid #ddd;
    padding-top: 10px
  }
  .fc-col-header-cell-cushion {
    word-spacing: 20px;
    color: #f27024;
    font-family: Poppins-Light;
    font-size: 12px;
  }
  .fc-button.fc-prev-button, .fc-button.fc-next-button {
    margin: 10px;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    background: #ffffff;
    border: solid 0.7px #e6e8f0;
    color: red
  }
`
// Customizable Area End

import EventRegistrationController, {
  Props,
  configJSON
} from "./EventRegistrationController";

class EventRegistration extends EventRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }

getDate = (date: any) => {
    let dateNew = new Date(date);

    return dateNew.getDate()
}

getMonth = (date: any) => {
    let dateNew = new Date(date);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    return monthNames[dateNew.getMonth()]
}

handleClick = (event: any, item: any) => {
    this.setState({
        anchorEl: event.currentTarget,
        selectedMenu: item
    })
};

handleClose = () => {
    this.setState({
        anchorEl: null,
        selectedMenu: null
    })
};

joinNow = (item: any) => {
    console.log(item)
    this.setState({
        anchorEl: null,
        selectedMenu: null
    })
    this.props.navigation.navigate('LiveStreaming', {id:item.id})
}

navigate = (type: any, item: any) => {
    if(type === 'past'){
        this.props.navigation.navigate('UserPastEventDetails', {id:item.id})
    } else {
        this.props.navigation.navigate('UserEventDetails', {id:item.id})
    }
}

getScheduledEvent = (classes: any, eventList: any, type: any) => {
    if(this.state.eventsLoading){
        return (
            <div
                style={{
                    fontFamily: 'Poppins-Light',
                    fontSize: '14px'
                }}
            >
                Loading...
            </div>
        )
    }
    return(
        eventList.length ? eventList.map((item: any, index: any) => {
            return(
                <div
                    className={classes.scheduleCard}
                    key={index}
                >
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                        >
                            <div
                                style={{
                                    borderRight: '1px solid #e6e8f0',
                                    cursor: 'pointer'
                                }}
                                onClick= {() => this.navigate(type, item)}
                            >
                                <Typography
                                    className={classes.listDate}
                                >
                                    {this.getDate(item.attributes.date)}
                                </Typography>
                                <Typography
                                    className={classes.listMonth}
                                >
                                    {this.getMonth(item.attributes.date)}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            sm={9}
                            md={9}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div>
                                    <Typography
                                        className={classes.listTime}
                                    >
                                        {item.attributes.start_time}-{item.attributes.end_time}
                                    </Typography>
                                </div>
                                {
                                    item.attributes.already_registered && type !== 'past' ?
                                    <div>
                                        <span
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={(e: any) => this.handleClick(e, item)}
                                        >
                                            ...
                                        </span>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={this.state.anchorEl}
                                            keepMounted
                                            open={this.state.selectedMenu && this.state.selectedMenu.id === item.id}
                                            onClose={this.handleClose}
                                        >
                                            <MenuItem onClick={() => this.joinNow(item)}>Join Now</MenuItem>
                                        </Menu>
                                    </div>
                                    : null
                                }
                            </div>
                            <div
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick= {() => this.navigate(type, item)}
                            >
                                <Typography
                                    className={classes.eventTitle}
                                    title={item.attributes.title}
                                >
                                    {item.attributes.title}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )
        })
        :   <div 
                style={{
                    fontFamily: 'Poppins-Light',
                    fontSize: '14px'
                }}
            >
                No events scheduled!
            </div>
    )
}


clickedEvent = (arg: any) => {
    this.props.navigation.navigate('UserEventDetails', {id:arg.event.id})
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
      {/* @ts-ignore */}
      <Box
          className={this.props.classes.appBarWrap}
      >
         
          {/* @ts-ignore */}
          <Box
              style={{
                  marginTop: '20px',
                  padding: '20px'
              }}
          >
              <Container>
                <Breadcrumb
                    base={'Home'}
                    url1={{
                      base: `Let's Talk`,
                      link: 'userEvent'
                    }}
                    url2={{
                      path: '',
                      link: ''
                    }}
                />
            </Container>
              <Container>
                  <Grid
                      container
                      spacing={5}
                  >
                      <Grid
                          xs={12}
                          sm={5}
                          md={4}
                          item
                      >
                              
                              <div
                                  style={{
                                      marginTop: '20px'
                                  }}
                              >
                                  <Typography
                                      className={this.props.classes.scheduleHead}
                                  >
                                      Upcoming Events
                                  </Typography>
                                  {
                                      this.getScheduledEvent(this.props.classes, this.state.upcomingEvents, 'future')
                                  }
                              </div>
                              <div
                                  style={{
                                      marginTop: '20px'
                                  }}
                              >
                                  <Typography
                                      className={this.props.classes.scheduleHead}
                                  >
                                      Past Events
                                  </Typography>
                                  {
                                      this.getScheduledEvent(this.props.classes, this.state.pastEvents, 'past')
                                  }
                              </div>
                          {/* </div> */}
                      </Grid>
                      <Grid
                          xs={12}
                          sm={7}
                          md={8}
                          item
                      >
                          <div
                              className={this.props.classes.calendarCard}
                          >
                              <StyledWrapper>
                                  {/* @ts-ignore */}
                                  <FullCalendar
                                      lazyFetching={true}
                                      plugins={[ dayGridPlugin, interactionPlugin ]}
                                      displayEventTime={true}
                                      initialView="dayGridMonth"
                                      events={this.state.allEvents}
                                      eventBackgroundColor={'#ffbe63'}
                                      eventBorderColor={'#ffbe63'}
                                      eventDisplay='block'
                                    //   eventClick={(arg: any) => this.clickedEvent(arg)}
                                      headerToolbar={{
                                          left: 'title',
                                          center: '',
                                          right: 'prev,next'
                                      }}
                                  />
                              </StyledWrapper>
                          </div>
                      </Grid>
                  </Grid>
              </Container>
          </Box>
      </Box>
  </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            logo: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            menuButton: {
                marginRight: theme.spacing(2),
                color: palette.primary.main,
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            welcome: {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            name: {
                fontSize: '24px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '20px',
                    fontSize: '16px'
                },
            },
            tabsBox: {
                // flex: 1,
                // flexDirection: 'row',
                // justifyContent: 'space-between',
                width: '100%',
                borderBottom: '2px solid',
                borderBottomColor: 'rgba(0, 0, 0, 0.12)'
            },
            tabs_main: {
                "& .MuiTabs-indicator": {
                    backgroundColor: palette.primary.main,
                    borderRadius: '1.5px',
                    height: '4px',
                },
                "& .Mui-selected": {
                    color: palette.primary.main
                }
            },
            headingTabs: {
                fontSize: '24px',
                fontWeight: 600,
                fontFamily: palette.fontFamily.main,
                width: "25%",
                textTransform: 'inherit',
                // minWidth: '50% !important'
            },
            lastTableRow: {
                '&:last-child td, &:last-child th': { border: 0 }
            },
            triangle: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.06))',
                marginTop: "49px",
                marginLeft: "12px",
                borderRadius: "10px",
                '& .MuiAvatar-root': {
                    width: '32px ',
                    height: '32px',
                    marginLeft: '-0.5px ',
                    marginRight: '1px',
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: '0px',
                    right: '20px ',
                    width: '15px',
                    height: '15px ',
                    backgroundColor: '#ffffff',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            },
            root: {
                color: "#F27024 !important",
                "& .Mui-selected": {
                    background: "#F27024",
                    color: "white",
                    fontWeight: "bold",
                },
                ul: {
                    "& .MuiPaginationItem-root": {
                        color: "#F27024"
                    }
                }
            },
            modalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '496px',
                height: "200px",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 5px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            statModalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '800px',
                height: "360px",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 5px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            previewModalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '796px',
                maxHeight: "80vh",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '20px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            menuItemStyle: {
                fontFamily: 'Poppins-Light',
                '&:hover': {
                    textAlign: "right",
                    color: '#d53636',
                    backgroundColor: '#ffffff',
                }
            },
            scheduleBtn: {
                background: palette.primary.main,
                color: '#fff',
                textTransform: 'capitalize',
                fontSize: '20px',
                fontFamily: 'Poppins-SemiBold',
                '&:hover': {
                    background: palette.primary.main,
                    color: '#fff',
                    textTransform: 'capitalize',
                    fontSize: '20px',
                    fontFamily: 'Poppins-SemiBold', 
                }
            },
            scheduleCard: {
                marginTop: '20px',
                borderRadius: '10px',
                boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
                padding: '16px 20px 20px 7.5px',
            },
            scheduleHead: {
                fontSize: '28px',
                fontFamily: 'Poppins-SemiBold',
                color: '#353535'
            },
            listDate: {
                display: 'flex',
                justifyContent: 'center',
                paddingRight: '5px',
                color: palette.primary.main,
                fontFamily: 'Poppins-SemiBold',
                fontSize: '28px'
            },
            listMonth: {
                display: 'flex',
                justifyContent: 'center',
                paddingRight: '5px',
                color: '#353535',
                fontFamily: 'Poppins-Light',
                fontSize: '18px'
            },
            listTime: {
                fontSize: '14px',
                color: '#8991a4',
                fontFamily: 'Poppins-Light'
            },
            eventTitle: {
                fontSize: '18px',
                fontFamily: 'Poppins-SemiBold',
                color: '#353535',
                display: '-webkit-box',
                width: '100%',
                lineClamp: 2,
                boxOrient: 'vertical',
                overflow: 'hidden'
            },
            calendarCard: {
                borderRadius: '10px',
                boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
                padding: '26px 24px 32px'
            }
        }))(EventRegistration)
)
// Customizable Area End
