import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  videoCall: boolean;
  eventDetails: any;
  loading: boolean;
  streamLoading: boolean;
  agoraToken: string;
  channelName: string;
  tokenAud: string;
  showMessage: boolean;
  message: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LiveStreamingController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getEventDetailsId: string = '';
  getStreamTokenId: string = '';
  endStreamId: string = '';
  startRecordingId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      videoCall: true,
      eventDetails: {},
      loading: false,
      streamLoading: false,
      agoraToken: '',
      channelName: '',
      tokenAud: '',
      showMessage: false,
      message: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiRequestCallId != null) {
          this.setAllData(apiRequestCallId, responseJson)
      }
  }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start

  async componentDidMount() {
    const userType = typeof window !== 'undefined' ? localStorage.getItem('userType') : null;
    const eId: any = typeof window !== 'undefined' ? window.location.href.split('/', 6).pop() : null;
    localStorage.setItem('eId', eId);
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getEventDetails()
    if(userType !== 'student' ){
      this.generateAgoraToken()
    }
  }

  async componentWillUnmount() {
      this.endStream()
  }


  setAllData = (apiRequestCallId: any, responseJson: any ) => {
    if(apiRequestCallId === this.getEventDetailsId){
      this.setEventDetails(responseJson)
    }
    if(apiRequestCallId === this.getStreamTokenId){
      this.setAgoraToken(responseJson)
    }
    if(apiRequestCallId === this.endStreamId){
      this.endStreamRes(responseJson)
    }
    if(apiRequestCallId === this.startRecordingId){
      this.setStartRecording(responseJson)
    }
  }

  setStartRecording = (responseJson: any) => {
    if(responseJson && !responseJson.error){
      console.log("Recording started");
    } else {
      this.setState({
        showMessage: true,
        message: 'Recording not started!'
      })
    }
  }

  endStreamRes = (responseJson: any) => {
    // localStorage.removeItem('eId')
    if(responseJson && !responseJson.error){
      this.setState({
        showMessage: true,
        message: 'Stream has been ended!'
      })
    } else {
      this.setState({
        showMessage: true,
        message: 'Something went wrong!'
      })
    }
  }

  setEventDetails = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
        this.setState({
            eventDetails: responseJson.data?.live_stream,
            tokenAud: responseJson.data?.live_stream?.attributes?.token_subscriber,
            channelName: responseJson.data?.live_stream?.attributes?.channel_name,
            loading: false
        })
    } else {
        this.setState({
            loading: false
        })
    }
  }

  setAgoraToken = (responseJson: any) => {
    if(responseJson && (!responseJson.error || !responseJson.errors)){
      this.setState({
        loading: false,
        agoraToken: responseJson.token_publisher,
        tokenAud: responseJson.token_subscriber,
        channelName: responseJson.channel_name
      })
    } else {
      this.setState({
        loading: false
      })
    }
  }


  generateAgoraToken = () => {
    const id = typeof window !== 'undefined' ? window.location.href.split('/', 6).pop() : null;
    this.setState({
        loading: true
    })
    const userToken = localStorage.getItem("token");
    const header = {
        "Content-Type": 'application/json',
        token: userToken,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStreamTokenId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getStreamTokenApi+'?id='+id
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  startRecording = () => {
    const id = typeof window !== 'undefined' ? window.location.href.split('/', 6).pop() : null;
    const userToken = localStorage.getItem("token");
    const header = {
        // "Content-Type": 'application/json',
        token: userToken,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.startRecordingId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.startRecording+id
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  endStream = () => {
    this.setState({
        loading: true
    })
    const eId = localStorage.getItem('eId');
    const userToken = localStorage.getItem("token");
    const header = {
        "Content-Type": 'application/json',
        token: userToken,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.endStreamId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endStreamApi+'?id='+eId
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getEventDetails = () => {
    const id = typeof window !== 'undefined' ? window.location.href.split('/', 6).pop() : null;
    this.setState({
        loading: true
    })
    const userToken = localStorage.getItem("token");
    const header = {
        "Content-Type": 'application/json',
        token: userToken,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEventDetailsId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getEventDetailsApi+'/'+id
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
  
}
