import React from 'react';
import { TextField, Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function CustomInput(props: any) {
    const [password, showPassword] = React.useState(props.passwordfield ?? "false");
    const useStyles = makeStyles((theme) => ({
        input: {
            height: props.height ? props.height : '54px',
            borderRadius: props.borderRadius ? props.borderRadius : 10,
            fontSize: "1em",
            width: '100%',
            color: props.inputcolor,
            backgroundColor: props.bgcolor ? props.bgcolor : '#ffffff',
            '& > .MuiInputBase-input': {
                height: '100%'
            }
        },
        field: {
            width: props.width + '%' ?? '100%',
            height: props.height + '%' ?? '100%',
        }
    }));
    const classes = useStyles();

    return (
        // @ts-ignore
        <Box height="100%" width="100%" mt={3}>
            {props.text ? <Typography variant={props.size} color={'primary'}>{props.text}</Typography> : null }
            <TextField
                type={props.passwordfield ? password ? "password" : "text" : "text"}
                // className={classes.field}
                fullWidth
                placeholder={props.placeholder}
                label={false}
                defaultValue={props.value}
                InputProps={{
                    readOnly: props.readOnly,
                    className: classes.input,
                    startAdornment: <img src={props.icon} style={{height: props.iconHeight, marginRight: '10px'}}/>,
                    endAdornment: props.passwordfield == true ? (<Button style={{paddingRight: '0px'}} onClick={() => showPassword(!password)}>{password ? <img src={require('../../blocks/email-account-login/assets/cross-eye-icon.png')} style={{height: props.iconHeight}} /> : <img src={require('../../blocks/email-account-login/assets/eye-icon.png')} style={{height: props.iconHeight}} />}</Button>) : <></>,
                }}
                multiline={props.multiline ?? false}
                rows={props.multiline ? props.rows : 0}
                variant="outlined"
                {...props}
            />
        </Box>
    )
}