import { Box, Typography, FormControl, Select, MenuItem } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import React, { FunctionComponent, ReactNode, useLayoutEffect } from "react";
import { BlogType } from "../../blocks/ContentManagement/src/Interfaces.web";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
interface Iprops{
    handleSortingLoader: (type: string, sort: string, page: number, perPage?: number) => void;
    currentPage: number;
    component: string;
    type: string;
    dataSize: any;
}

function useWindowSize() {
    const [size, setSize] = React.useState([0, 0])
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight])
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, [])
    return size;
}

const SortingHeader = ({handleSortingLoader, currentPage, component, type, dataSize }:Iprops) => {
    const classes = useStyles();
    const [sort, setSort] = React.useState('popularity');
    const [windowWidth, windowHeight] = useWindowSize();
    
    const handleSortChange = (event: any) => {
        setSort(event.target.value as string);
        handleSortingLoader(type, event.target.value as string, currentPage)
    };

    return (
        <>
            <Box style={{ borderRadius: "10px 10px 0 0", backgroundColor: "#f4f5f7", display: windowWidth <= 768 ? "block" : "flex", justifyContent: "space-between", alignItems: "center", height: windowWidth <= 768 ? "7vh" : "4vh", padding: "1rem 1.5rem 1rem 1.5rem" }}>
                <Typography style={{ color: "#000", fontFamily: "Poppins-SemiBold" }}>{component} ({dataSize})</Typography>
                <Box className={classes.customSelectWrapper}>
                    <Typography style={{ width: "100%", color: 'grey', fontSize: "14px" }}>Sort By :&nbsp;</Typography>
                    <FormControl style={{ minWidth: "190", width: "100%" }}>
                        <Select
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sort}
                            className={classes.select}
                            label="Sort"
                            onChange={handleSortChange}
                            disableUnderline={true}
                            IconComponent={KeyboardArrowDownIcon}
                        >
                            <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "Poppins-Light" }} value={"popularity"}>Popularity</MenuItem>
                            <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "Poppins-Light" }} value={"newest_first"}>Recent</MenuItem>
                            <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "Poppins-Light" }} value={"oldest_first"}>Oldest</MenuItem>
                        </Select>
                        <span className={classes.customArrow}></span>
                    </FormControl>
                </Box>
            </Box>
            {/* <Box className={classes.whiteBorder} /> */}
        </>
    )
}
export default SortingHeader;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        whiteBorder: {
            width: "100%",
            height: "1px",
            // margin: "0 0 45px",
            backgroundColor: "#e6e6e6"
        },
        customSelectWrapper: {
            position: "relative",
            fontFamily: "Poppins-Light",
            boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
            backgroundColor:  "#fff",
            paddingLeft: '8px',
            borderRadius: "5px 2px 2px 5px",
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down('sm')]: {
                padding: '2px 5px'
            },
        },
        menuItemStyle: {
            textAlign: "right",
            color: '#353535',
        },
        customArrow: {
            position: "absolute",
            top: 0,
            right: 0,
            display: "block",
            backgroundColor:"#f4f5f7",
            borderLeft: "1px solid rgba(27, 31, 35, 0.15)",
            // borderRadius: "0 6px 6px 0",
            height: '100%',
            width: "3rem",
            pointerEvents: 'none',
        },
        select: {
            fontSize: "14px",
            fontFamily: "Poppins-SemiBold",
            color: '#00000',
            "& .MuiSelect-icon": {
                zIndex: 1,
                right: ".7rem"
            },
            "& .MuiSelect-select": {
                backgroundColor: "#fff"
            },
        },
    })
);