import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import {palette} from "./theme";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import {
  Button,
  Slider,
  Tooltip,
  Popover,
  IconButton,
  Box,
  Grid,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import VolumeMuteRoundedIcon from '@material-ui/icons/VolumeMuteRounded';

import WaveSurfer from "wavesurfer.js";


function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
      <Tooltip open={open} enterTouchDelay={0} placement="right" title={value}>
          {children}
      </Tooltip>
  );
}


const VerticalSlider = withStyles({
  root: {
    color: palette.primary.main,
    height: 6,
    '&$vertical': {
      width: 6
    }
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: '#fff',
    border: `3px solid ${palette.primary.main}`,
    marginTop: -8,
    marginLeft: -10,
    '&:focus, &:hover': {
      boxShadow: '0px 0px 0px 8px rgba(84, 199, 97, 0.16)'
    },
    '&$active': {
      boxShadow: '0px 0px 0px 12px rgba(84, 199, 97, 0.16)'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 6,
    borderRadius: 4
  },
  rail: {
    height: 6,
    borderRadius: 4
  },
  vertical: {
    '& $rail': {
      width: 6
    },
    '& $track': {
      width: 6
    },
    '& $thumb': {
      marginLeft: -4.5,
      marginBottom: -10
    }
  }
})(Slider)

const useStyles = makeStyles({
  controlsWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 10,
    background: "rgba(0,0,0,0.6)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 1,
  },

  bottomIcons: {
    color: "#999",
    "&:hover": {
      color: "#fff",
    },
  },
  volumeSlider: {
    width: 100,
  },
  vertical: {
    height: 120,
    padding: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '0px'
  },
  verticalSpeed: {
    height: 140,
    padding: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '0px',
    paddingTop: '5px'
  },
});

const format = (seconds) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

const formWaveSurferOptions = ref => ({
  container: ref,
  waveColor: "#eee",
  progressColor: palette.primary.main,
  cursorColor: palette.primary.main,
  barWidth: 3,
  barRadius: 2,
  responsive: true,
  height: 35,
  barGap: 2,
  cursor: 'pointer',
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true
});

export default  forwardRef((props, ref) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(null);
  const [totalTime, setTotalTime] = useState(null);
  const { audioDetails  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [muted, setMute] = React.useState(false);
  const [playbackRate, setPlayBackRate] = React.useState(1);

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    setPlay(true);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(props.url);
    
    
    wavesurfer.current.on("ready", function() {
      // https://wavesurfer-js.org/docs/methods.html
      // wavesurfer.current.play();
      // setPlay(true);
        var totalTime = wavesurfer.current.getDuration(),
        currentTime = wavesurfer.current.getCurrentTime();
        props.getTotalTime(format(totalTime), playing);
        // remainingTime = totalTime - currentTime;
        setCurrentTime(format(currentTime));
        setTotalTime(format(totalTime));
      // make sure object stillavailable when file loaded
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume * 100);
      }
    });

    wavesurfer.current.on('audioprocess', function() {
      if(wavesurfer.current.isPlaying()){
        var totalTime = wavesurfer.current.getDuration(),
          currentTime = wavesurfer.current.getCurrentTime();
          // remainingTime = totalTime - currentTime;

          setCurrentTime(format(currentTime));
          setTotalTime(format(totalTime));
      }
    });

    wavesurfer.current.on('finish', function() {
      wavesurfer.current.stop();
      if(wavesurfer.current){
        setPlay(false);
        props.getTotalTime(format(wavesurfer.current.getDuration()), false);
      }
    })

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [props.url]);

  const handlePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopover2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  useImperativeHandle(ref, () => ({

    getAlert() {
      handlePlayPause();
    }

  }));

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
    if(playing){
      props.getTotalTime(format(wavesurfer.current.getDuration()), true);
    } else {
      props.getTotalTime(format(wavesurfer.current.getDuration()), false);
    }
  };

  const onMute = () => {
    wavesurfer.current.toggleMute();
    if(muted){
      setMute(false);
    } else {
      setMute(true)
    }
  }

  const onVolumeChange = (e, newVolume) => {
    if (newVolume) {
      setVolume(newVolume);
      setMute(false);
      wavesurfer.current.setVolume(newVolume / 100 || 1);
    }
  };

  const onSeekMouseDown = (e, newVolume) => {
    if (newVolume) {
      setVolume(newVolume);
      setMute(false);
      wavesurfer.current.setVolume(newVolume / 100 || 1);
    }
  }

  const onVolumeSeekDown = (e, newVolume) => {
    if (newVolume) {
      setVolume(newVolume);
      setMute(false);
      wavesurfer.current.setVolume(newVolume / 100 || 1);
    }
  } 

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handleBackward = () => {
    wavesurfer.current.skipBackward(10);
  }

  const handleForward = () => {
    wavesurfer.current.skipForward(10);
  }

  const onPlaybackRateChange = (rate) => {
    setPlayBackRate(rate);
    wavesurfer.current.setPlaybackRate(rate)
  }

  const isXs = useMediaQuery('(max-width: 600px)');
  const isSm = useMediaQuery('(max-width: 960px)');

  return (
    <>
      {/* @ts-ignore */}
      <Box
        mt={2}
        style={{
          borderRadius: '10px',
          // minHeight: '100px',
          // maxHeight: '100px',
          height: '100%',
          background: '#ffffff',
          // overflow: 'hidden',
          boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)'
        }}
      >
        <Grid container>
          <Grid item xs={12} md={1} >
              {/* @ts-ignore */}
              <Box display={{sm: 'none', xs: 'none', md: 'block'}}>
                <img 
                  height={'100px'} 
                  width={'100%'} 
                  src={audioDetails.poster ? audioDetails.poster : require('../../blocks/AudioMusic/assets/audio.jpg')} 
                />
              </Box>
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            {/* @ts-ignore */}
            <Box
              p={1}
              pl={isXs ? 1 : 2}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={5}>
                    {/* @ts-ignore */}
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      <IconButton
                        onClick={handleBackward}
                        style={{
                          marginTop: '15px'
                        }}
                      >
                        <img width={32} height={32} src={require("../../blocks/AudioMusic/assets/backward.png")} />
                      </IconButton>
                      <IconButton 
                        aria-label="play/pause" 
                        style={{
                          background: '#fff',
                          boxShadow: '0 0 7px 2px rgba(0, 0, 0, 0.12)',
                          marginTop: '15px'
                        }}
                        onClick={handlePlayPause}
                      >
                        {
                          !playing ?
                            <PauseRoundedIcon 
                              fontSize="large" 
                              style={{
                                color: palette.primary.main,
                              }} 
                            />
                          :
                            <PlayArrowRoundedIcon 
                              fontSize="large" 
                              style={{
                                color: palette.primary.main,
                              }} 
                            />
                        }
                      </IconButton>
                      <IconButton
                        onClick={handleForward}
                        style={{
                          marginTop: '15px'
                        }}
                      >
                        <img width={32} height={32} src={require("../../blocks/AudioMusic/assets/forward.png")} />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    {/* @ts-ignore */}
                    <Box
                      mt={3}
                    >
                      <Typography
                        style={{
                          color: "#000",
                          fontFamily: 'Poppins-SemiBold',
                          fontSize: '16px'
                        }}
                        title={audioDetails.title}
                      >
                       {audioDetails.title && audioDetails.title.length > 30 ? audioDetails.title.substring(0, 30) + "..." : audioDetails.title}
                      </Typography>
                      <Typography
                        style={{
                          color: '#8991a4',
                          fontFamily: 'Poppins-Light',
                          fontSize: '12px',
                          textTransform: 'uppercase'
                        }}
                        title={audioDetails.description}
                      >
                       {audioDetails.description && audioDetails.description.length > 30 ? audioDetails.description.substring(0, 30) + "..." : audioDetails.description }
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
            </Box>
          </Grid>
          <div style={{minHeight: '100%', display: isXs ? 'none' : 'flex', width: '1px', background: '#e6e6e6'}}></div>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: isXs ? '-40px' : '15px',
                marginTop: isSm && !isXs ? '40px' : ''
              }}
            >
              <Grid xs={2} sm={2} md={1}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      fontFamily: 'Poppins-Light',
                      letterSpacing: 'normal',
                      marginTop: '22px'
                    }}
                  >
                    {currentTime}
                  </Typography>
              </Grid>
              <Grid xs={4} sm={6} md={7}>
                <Box mt={3} style={{cursor: 'pointer'}} id="waveform" ref={waveformRef}></Box>       
              </Grid>
              <Grid xs={3} sm={4} md={4}>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: '14px',
                        fontWeight: 'normal',
                        fontFamily: 'Poppins-Light',
                        letterSpacing: 'normal',
                        marginTop: '20px',
                        marginLeft: '10px'
                      }}
                    >
                      {totalTime}
                    </Typography>
                    <Button
                        onClick={handlePopover}
                        variant="text"
                        className={classes.bottomIcons}
                        style={{
                          marginTop: '15px'
                        }}
                    >
                        <img src={require('../../blocks/AudioMusic/assets/volume-high.png')} height={'60px'} />
                    </Button>
                    <Popover
                        id={'1'}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                    >
                        <Grid container direction="column">
                            <div className={classes.vertical}>
                                {/* @ts-ignore */}
                                <VerticalSlider
                                    orientation="vertical"
                                    valueLabelDisplay="auto"
                                    ValueLabelComponent={ValueLabelComponent}
                                    min={0}
                                    max={100}
                                    value={muted ? 0 : volume}
                                    onChange={onVolumeChange}
                                    aria-labelledby="input-slider"
                                    className={classes.volumeSlider}
                                    onMouseDown={onSeekMouseDown}
                                    onChangeCommitted={onVolumeSeekDown}
                                />
                            </div>
                            <div>
                                <IconButton style={{color:'grey', marginLeft: '7px'}} onClick={onMute} className={classes.bottomIcons}>
                                    {
                                        muted ?
                                        <VolumeMuteRoundedIcon fontSize="medium" />
                                        : <VolumeUpRoundedIcon fontSize="medium" />
                                    }
                                </IconButton>
                            </div>
                        </Grid>
                    </Popover>
                    <Button
                        onClick={handlePopover2}
                        variant="text"
                        className={classes.bottomIcons}
                        style={{
                          marginTop: '15px'
                        }}
                    >
                        <img src={require('../../blocks/AudioMusic/assets/settings.png')} height={'60px'} />
                    </Button>
                    <Popover
                      id={'2'}
                      open={open2}
                      anchorEl={anchorEl2}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <div className={classes.verticalSpeed}>
                        <div>
                          <Typography
                            style={{
                              fontSize: '14px',
                              fontFamily: 'Poppins-Light',
                              color: '#000'
                            }}
                          >
                            Speed
                          </Typography>
                        </div>
                        <Grid container direction="column-reverse">
                          {[0.5, 1, 1.5, 2].map((rate) => (
                              <Typography
                                  style={{
                                      color: rate === playbackRate ? palette.primary.main : "#8991a4",
                                      fontSize: '12px',
                                      fontFamily: 'Poppins-Light',
                                      padding: '5px',
                                      cursor: 'pointer'
                                  }}
                                  onClick={() => onPlaybackRateChange(rate)}
                              >
                                  {
                                      rate == 1 ?
                                      'Normal'
                                      : rate + 'X'
                                  }
                              </Typography>
                              // </Button>
                          ))}
                        </Grid>
                      </div>
                    </Popover>
                  </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
})