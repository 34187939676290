Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "EventRegistration";
exports.labelBodyText = "EventRegistration Body";
exports.getEventDetailsApi = "bx_block_content_management/live_streams";
exports.contentType = "application/json";
exports.registerEventApi = 'bx_block_content_management/event_registrations';
exports.getEventsListApi = "bx_block_content_management/live_streams";
exports.getRegisteredEventsListApi = "bx_block_content_management/event_registrations";
exports.googleInitApi = "https://accounts.google.com/o/oauth2/v2/auth";
exports.syncCalendarApi = "bx_block_content_management/live_streams/sync_to_google_calendar?id=";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End