Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/instructor_dashboards";
exports.letsReadGetUrl = "/bx_block_dashboard/get_blogs";
exports.getAllCoursesUrl = "bx_block_dashboard/get_courses";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.contentType = "application/json";
exports.categoryUrl = "bx_block_categories/categories";
exports.categoryType = "blog_category"
exports.getAllAuthorUrl = "account_block/accounts"
exports.postBlogUrl = "bx_block_posts/blogs"
exports.updateBlogUrl = "bx_block_posts/blogs"
exports.deleteBlogUrl = "bx_block_posts/blogs"
exports.deleteCourseUrl = "bx_block_library2/courses"
exports.viewStatsUrl = "bx_block_dashboard/get_stats"
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.updateMethodType = "PUT"
exports.deleteMethodType = "DELETE";
exports.addInstructorApi = "bx_block_library2/add_instructor";
exports.getCoInstructorApi = "bx_block_library2/list_co_instructors";
exports.createCourseApi = "bx_block_library2/courses";
exports.updateCourseApi = "bx_block_library2/courses";
exports.getCourseApi = "bx_block_library2/courses";
exports.getPodcastsApi = "bx_block_dashboard/get_podcasts";
exports.getPodcastDetailsApi = 'bx_block_video_management/video_podcasts';
exports.updatePodcastApi = 'bx_block_video_management/video_podcasts';
exports.createPodcastApi = "bx_block_video_management/video_podcasts";
exports.createVideosApi = "bx_block_video_management/videos";
exports.getVideosApi = "bx_block_dashboard/get_videos";
exports.getSingleVideoApi = "bx_block_video_management/videos";
exports.updateVideoApi = "bx_block_video_management/videos";
exports.userListGetApi = "account_block/accounts/list_users";
exports.changePermissionApi = "account_block/accounts/update_permission";
exports.createPersonApi = "bx_block_dashboard/people";
exports.getPersonListApi = "bx_block_dashboard/people";
exports.createOrgApi = "bx_block_dashboard/organisations";
exports.getOrgListApi = "bx_block_dashboard/organisations";
exports.createBookApi = "bx_block_dashboard/books";
exports.getBookListApi = "bx_block_dashboard/books";
exports.createFilmApi = "bx_block_dashboard/films";
exports.getFilmsListApi = "bx_block_dashboard/films";
exports.getPeopleDetailsApi = "bx_block_dashboard/people";
exports.updatePersonApi = "bx_block_dashboard/people";
exports.getOrgDetailsApi = "bx_block_dashboard/organisations";
exports.updateOrgApi = "bx_block_dashboard/organisations";
exports.getFilmApi = "bx_block_dashboard/films";
exports.updateFilmApi = "bx_block_dashboard/films";
exports.updateBookApi = "bx_block_dashboard/books";
exports.getBookApi = "bx_block_dashboard/books";
exports.adminSearchApi = "bx_block_dashboard/search";
exports.createEventApi = "bx_block_content_management/live_streams";
exports.getEventsListApi = "bx_block_dashboard/get_live_streams?type=published";
exports.getEventDetailsApi = "bx_block_content_management/live_streams";
exports.syncCalendarApi = "bx_block_content_management/live_streams/sync_to_google_calendar?id=";
exports.getTutorialsListApi = 'bx_block_content_management/tutorials';
exports.getTutorialsDetailsApi = 'bx_block_content_management/tutorials';
// Customizable Area End