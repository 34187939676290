import React from "react";

// Customizable Area Start
import { 
  Container, 
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Button
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { palette } from "../../../components/src/theme";
import {EditIcon} from '../src/assets';
import CameraAltRoundedIcon from '@material-ui/icons/CameraAltRounded';
import Dropzone from 'react-dropzone';
import AlertModal from "../../../components/src/AlertModal";
import CustomLoader from "../../../components/src/CustomLoader";


const newsLetter = typeof window !== 'undefined' ? localStorage.getItem('newsLetter') : null;

// Customizable Area End

import QuestionBankController, {
  Props,
  configJSON
} from "./QuestionBankController";

export default class QuestionBank extends QuestionBankController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getBreadCrumb = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link  
          href="/"
          style={{
            color: palette.primary.main,
            fontFamily: 'Poppins-Light',
            fontSize: '14px'
          }}
        >
          Home
        </Link>
        <Typography 
          style={{
            fontFamily: 'Poppins-Light',
            fontSize: '14px'
          }}
        >
          Account Settings
        </Typography>
      </Breadcrumbs>
    )
  }
// istanbul ignore next
  getProfileImage = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin:'40px',
          position: 'relative'
        }}
      >
        <div
          style={{
            height: '200px',
            width: '200px',
            overflow: 'hidden',
            position: 'relative'
          }}
        >
          {
            this.state.profileImg?.length ? this.state.profileImg?.map((file: any, index: any) => {
              return (
                <img 
                  key={index}
                  style={{
                    height: '200px',
                    width: '200px',
                    minWidth: '200px',
                    minHeight: '200px',
                    overflow: 'hidden',
                    borderRadius: '50%',
                    position: 'relative'
                  }}
                  src={URL.createObjectURL(file)} 
                  onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
              )
            })
            : <img 
                style={{
                  height: '200px',
                  width: '200px',
                  minWidth: '200px',
                  minHeight: '200px',
                  overflow: 'hidden',
                  borderRadius: '50%',
                  position: 'relative'
                }}
                src={this.state.profileImg ? this.state.profileImg : this.state.userPic}
              />
          }
        </div>
        <Dropzone 
          onDrop={this.onDrop}
          multiple={false}
          accept={ {'image/*': []} }
        >
          {({getRootProps, getInputProps}) => (
            <section 
              style={{
                position: 'absolute',
                textAlign: 'center',
                marginTop: '180px'
              }}
            >
              <div {...getRootProps({className: 'dropzone'})}>
                {/* @ts-ignore */}
                <input {...getInputProps()} />
                <div>
                  <CameraAltRoundedIcon 
                    style={{
                      color: '#F27024',
                      cursor: 'pointer'
                    }}
                    fontSize={'large'}
                  />
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    )
  }


  getUserName = () => {
    return(
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Typography
          style={{
            color: '#353535',
            fontFamily: 'Poppins-Bold',
            fontSize: '38px',
            textTransform: 'capitalize'
          }}
        >
          {this.state.userName}
        </Typography>
      </div>
    )
  }

  getUserInfo = () => {
    return(
      <div
        style={{
          display: 'flex',
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={1}></Grid>
            <Grid item xs={12} sm={12} md={10}>
              {
                this.getUserInfoBox()
              }
            </Grid>
            <Grid item xs={12} sm={12} md={1}></Grid>
          </Grid>
        </Container>
      </div>
    )
  }

  editProfile = () => {
    this.setState({
      isInfoEditable: !this.state.isInfoEditable
    }, () => {
      // istanbul ignore if
      if(!this.state.isInfoEditable){
        this.setState({
          fName: this.state.fName,
          lName: this.state.lName,
          email: this.state.email,
          inputError: {}
        })
      }
    })
  }

  getPInfoForm = () => {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              id='unitTest'
              fullWidth
              name="fName"
              value={this.state.fName}
              onChange={(e) => this.handlePInfoChange(e, 'fName')}
              InputProps={{
                  // className: classes.input,
                  disableUnderline: true,
                  style: {
                      height: '48px',
                      border: '1px solid #e6e8f0',
                      borderRadius: '9px',
                      padding: '16px 36px 16px 24px',
                      fontSize: '20px'
                  }
              }}
              style={{
                  fontSize: "20px",
                  fontFamily: "Poppins-Light",
                  marginTop: "10px"
              }}
              placeholder="First Name"
              error={this.state.inputError?.fName === undefined || this.state.inputError?.fName === "" ? false : true}
              helperText={this.state.inputError.fName}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              name="lName"
              value={this.state.lName}
              onChange={(e) => this.handlePInfoChange(e, 'lName')}
              InputProps={{
                  // className: classes.input,
                  disableUnderline: true,
                  style: {
                      height: '48px',
                      border: '1px solid #e6e8f0',
                      borderRadius: '9px',
                      padding: '16px 36px 16px 24px',
                      fontSize: '20px'
                  }
              }}
              style={{
                  fontSize: "20px",
                  fontFamily: "Poppins-Light",
                  marginTop: "10px"
              }}
              placeholder="Last Name"
              error={this.state.inputError?.lName === undefined || this.state.inputError?.lName === "" ? false : true}
              helperText={this.state.inputError.lName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              fullWidth
              name="email"
              value={this.state.email}
              onChange={(e) => this.handlePInfoChange(e, 'email')}
              InputProps={{
                  // className: classes.input,
                  disableUnderline: true,
                  style: {
                      height: '48px',
                      border: '1px solid #e6e8f0',
                      borderRadius: '9px',
                      padding: '16px 36px 16px 24px',
                      fontSize: '20px'
                  }
              }}
              style={{
                  fontSize: "20px",
                  fontFamily: "Poppins-Light",
                  marginTop: "10px"
              }}
              placeholder="Email"
              error={this.state.inputError?.email === undefined || this.state.inputError?.email === "" ? false : true}
              helperText={this.state.inputError.email}
            />
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <div 
              style={{
                display: 'flex',
                marginTop: '10px'
              }}
            >
              <Button
                onClick={() => this.updateInfo()}
                style={{
                  border: '1px solid #f27024',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light',
                  color: '#fff',
                  background: '#f27024',
                  padding: '5px 40px',
                  marginLeft: '10px'
                }}
                // @ts-ignore
                variant="contained"
              >
                Save
              </Button>
              <Button
                onClick={() => this.editProfile()}
                style={{
                  border: '1px solid #8991a4',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light',
                  color: '#8991a4',
                  padding: '5px 40px',
                  marginLeft: '20px',
                  background: '#fff'
                }}
                // @ts-ignore
                variant="contained"
              >
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
    </div>
    )
  }

  getUserInfoBox = () => {
    return(
      <div
        style={{
          padding: '28px 32px 32px 32px',
          boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
          borderRadius: '8px'
        }}
      >
        {
          this.state.isInfoEditable ?
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Typography
                  style={{
                    fontSize: '20px',
                    fontFamily: 'Poppins-SemiBold',
                    color: '#353535'
                  }}
                >
                  Personal Information
                </Typography>
              </div>
              {
                this.getPInfoForm()
              }
            </>
          :
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                style={{
                  fontSize: '20px',
                  fontFamily: 'Poppins-SemiBold',
                  color: '#353535'
                }}
              >
                Personal Information
              </Typography>
              <div>
                {/* <IconButton> */}
                  <img 
                    src={EditIcon} 
                    style={{cursor: 'pointer'}} 
                    onClick={() => this.editProfile()}
                  />
                {/* </IconButton> */}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px'
              }}
            >
              <Typography
                style={{
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light',
                  color: '#545454'
                }}
              >
                Full name - {this.state.userName}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px'
              }}
            >
              <Typography
                style={{
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light',
                  color: '#545454'
                }}
              >
                Email address - {this.state.email}
              </Typography>
            </div>
          </>
        }
      </div>
    )
  }

  getChangePwdUi = () => {
    return(
      <div
        style={{
          display: 'flex',
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={1}></Grid>
            <Grid item xs={12} sm={12} md={10}>
              {
                this.getUserChangePwd()
              }
            </Grid>
            <Grid item xs={12} sm={12} md={1}></Grid>
          </Grid>
        </Container>
      </div>
    )
  }
// istanbul ignore next
  getUserChangePwd = () => {
    return(
      <div
        style={{
          padding: '28px 32px 32px 32px',
          boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
          borderRadius: '8px'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            style={{
              fontSize: '20px',
              fontFamily: 'Poppins-SemiBold',
              color: '#353535'
            }}
          >
            Change Password
          </Typography>
          <div>
            {
              !this.state.isPasswordEditable ?
                <img 
                  src={EditIcon} 
                  style={{cursor: 'pointer'}} 
                  onClick={() => this.changePassword()}
                />
              : null
            }
          </div>
        </div>
        {
          this.state.isPasswordEditable ?
            <div 
              style={{
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div>
                <TextField
                  fullWidth
                  name="oldPwd"
                  value={this.state.oldPwd}
                  onChange={(e) => this.handlePwdChange(e, 'oldPwd')}
                  InputProps={{
                      // className: classes.input,
                      disableUnderline: true,
                      style: {
                          height: '48px',
                          border: '1px solid #e6e8f0',
                          borderRadius: '9px',
                          padding: '16px 36px 16px 24px',
                          fontSize: '20px'
                      }
                  }}
                  style={{
                      fontSize: "20px",
                      fontFamily: "Poppins-Light",
                      marginTop: "10px"
                  }}
                  type='password'
                  placeholder="Old Password"
                  error={this.state.inputPwdError?.oldPwd === undefined || this.state.inputPwdError?.oldPwd === "" ? false : true}
                  helperText={this.state.inputPwdError.oldPwd}
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  name="newPwd"
                  type='password'
                  value={this.state.newPwd}
                  onChange={(e) => this.handlePwdChange(e, 'newPwd')}
                  InputProps={{
                      // className: classes.input,
                      disableUnderline: true,
                      style: {
                          height: '48px',
                          border: '1px solid #e6e8f0',
                          borderRadius: '9px',
                          padding: '16px 36px 16px 24px',
                          fontSize: '20px'
                      }
                  }}
                  style={{
                      fontSize: "20px",
                      fontFamily: "Poppins-Light",
                      marginTop: "10px"
                  }}
                  placeholder="New Password"
                  error={this.state.inputPwdError?.newPwd === undefined || this.state.inputPwdError?.newPwd === "" ? false : true}
                  helperText={this.state.inputPwdError.newPwd}
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  name="confirmPwd"
                  type='password'
                  value={this.state.confirmPwd}
                  onChange={(e) => this.handlePwdChange(e, 'confirmPwd')}
                  InputProps={{
                      // className: classes.input,
                      disableUnderline: true,
                      style: {
                          height: '48px',
                          border: '1px solid #e6e8f0',
                          borderRadius: '9px',
                          padding: '16px 36px 16px 24px',
                          fontSize: '20px'
                      }
                  }}
                  style={{
                      fontSize: "20px",
                      fontFamily: "Poppins-Light",
                      marginTop: "10px"
                  }}
                  placeholder="Confirm Password"
                  error={this.state.inputPwdError?.confirmPwd === undefined || this.state.inputPwdError?.confirmPwd === "" ? false : true}
                  helperText={this.state.inputPwdError.confirmPwd}
                />
              </div>
              <div 
                style={{
                  display: 'flex',
                  marginTop: '10px'
                }}
              >
                <Button
                  onClick={() => this.updatePassword()}
                  style={{
                    border: '1px solid #f27024',
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light',
                    color: '#fff',
                    background: '#f27024',
                    padding: '5px 40px'
                  }}
                  variant="contained"
                >
                  Save
                </Button>
                <Button
                  onClick={() => this.changePassword()}
                  style={{
                    border: '1px solid #8991a4',
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light',
                    color: '#8991a4',
                    padding: '5px 40px',
                    marginLeft: '20px',
                    background: '#fff'
                  }}
                  // @ts-ignore
                  variant="contained"
                >
                  Cancel
                </Button>
              </div>
            </div>
          : null
        }
      </div>
    )
  }

  changePassword = () => {
    this.setState({
      isPasswordEditable: !this.state.isPasswordEditable
    }, () => {
      if(!this.state.isPasswordEditable){
        this.setState({
          oldPwd: '',
          newPwd: '',
          confirmPwd: '',
          inputPwdError: {}
        })
      }
    })
  }

  getNewsLetterUi = () => {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: '20px'
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={1}></Grid>
            <Grid item xs={12} sm={12} md={10}>
              {
                this.getSubscribeUi()
              }
            </Grid>
            <Grid item xs={12} sm={12} md={1}></Grid>
          </Grid>
        </Container>
      </div>
    )
  }

  getSubscribeUi = () => {
    return(
      <div
        style={{
          padding: '28px 32px 32px 32px',
          boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
          borderRadius: '8px'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            style={{
              fontSize: '20px',
              fontFamily: 'Poppins-Light',
              color: '#353535'
            }}
          >
            Subscribe to stay upto date with <b><i>‘’Let’s Be Real’’</i></b>
          </Typography>
          <div>
            <Button
              variant="contained"
              // @ts-ignore
              disabled={newsLetter === 'true' ? true : false}
              style={{
                padding: '11px 30px',
                fontSize: '16px',
                fontFamily: 'Poppins-Light',
                background: newsLetter === 'true' ? '#8991a4' : '#F27024',
                color: '#fff',
                height: '40px',
                textTransform: 'capitalize'
              }}
              onClick={() => this.subscribeNewsLetter()}
            > 
              Subscribe to Newsletter
            </Button>
          </div>
        </div>
      </div>
    )
  }

  closeAlertBox = () => {
    this.setState({
      alertOpen: false
    });
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <div>
          {
            this.state.loading ?
                <CustomLoader 
                    open={this.state.loading}
                />
            : null
          }
          {this.state.alertOpen ? (
              <AlertModal
                  open={this.state.alertOpen}
                  data={this.state.alertMessage}
                  onClose={() => this.closeAlertBox()}
              />
          ) : null}
          <Container>
            <div>
              {
                this.getBreadCrumb()
              }
            </div>
            <div
              style={{
                marginTop: '20px',
                marginBottom: '20px'
              }}
            >
              {
                this.getProfileImage()
              }
            </div>
            <div
              style={{
                marginTop: '20px'
              }}
            >
              {
                this.getUserName()
              }
            </div>
            <div
              style={{
                marginTop: '20px'
              }}
            >
              {
                this.getUserInfo()
              }
            </div>
            <div
              style={{
                marginTop: '20px'
              }}
            >
              {
                this.getChangePwdUi()
              }
            </div>
            <div
              style={{
                marginTop: '20px'
              }}
            >
              {
                this.getNewsLetterUi()
              }
            </div>
          </Container>
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: '20px 0px',
    }
  },
});

const webStyle = {
  mainWrapper: {
    display: 'flex', 
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column', 
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  buttonStyle: { 
    width: '100%',
    height: '45px',  
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)', 
  }
};
// Customizable Area End
