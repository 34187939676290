Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";

exports.btnExampleTitle = "CLICK ME";

exports.contentType = "application/json";
exports.getAllBlogsApiUrl = "bx_block_posts/blogs"
exports.getAllCategoryUrl = "bx_block_categories/categories"
exports.categoryType = "blog_category"
exports.postReviewUrl = "bx_block_library2/reviews"
exports.getApiMethodType = "GET";
exports.termsEndPoint = "bx_block_content_management/show_content";
exports.updateFavouriteApi = "bx_block_library2/courses/favourite";
// Customizable Area End