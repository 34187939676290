import React from "react";
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface CustomHoverBtnProps {
  color: string;
  height?: string;
  fontSize?: string;
  pl?: string;
  pr?: string;
  br?: string;
  fullWidth?: boolean;
  handleClick?: () => void;
  icon?: React.ReactNode;
  title: string;
  selected: boolean;
}

const CustomHoverBtn: React.FC<CustomHoverBtnProps> = (props) => {
  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: 'transparent',
      color: props.color,
      border: `1px solid ${props.color}`,
      textTransform: 'capitalize',
      width: '100%',
      height: props.height ? props.height : '',
      fontSize: props.fontSize ? props.fontSize : '',
      fontFamily: 'Poppins-Light',
      paddingLeft: props.pl ? props.pl : '',
      paddingRight: props.pr ? props.pr : '',
      borderRadius: props.br ? props.br : '',
      minWidth: '10vw',
      '&:hover': {
        background: props.selected ? 'transparent' : props.color,
        color: props.selected ? props.color : "#ffffff",
        textTransform: 'none',
        width: props.fullWidth === true ? '100%' : '',
        height: props.height ? props.height : '',
        fontSize: props.fontSize ? props.fontSize : '',
        fontFamily: 'Poppins-Light',
        paddingLeft: props.pl ? props.pl : '',
        paddingRight: props.pr ? props.pr : '',
        borderRadius: props.br ? props.br : '',
        cursor: props.selected ? 'default' : 'pointer',
      },
      '&:focus': {
        outline: 'none', // Remove focus outline
      },
    },
    selected: {
      background: props.color,
      color: "#ffffff",
      textTransform: 'capitalize',
      width: props.fullWidth === true ? '100%' : '',
      height: props.height ? props.height : '',
      fontSize: props.fontSize ? props.fontSize : '',
      fontFamily: 'Poppins-Light',
      paddingLeft: props.pl ? props.pl : '',
      paddingRight: props.pr ? props.pr : '',
      borderRadius: props.br ? props.br : '',
      border: `1px solid ${props.color}`,
      '&.Mui-disabled': {
        color: "#ffffff", // Set the text color to #ffffff when disabled
      },
      '&.MuiButton-root:hover.Mui-disabled': {
        backgroundColor: props.color
    }
    }
  }));

  const classes = useStyles();

  return (
    <Button
      onClick={props.handleClick}
      variant="outlined"
      className={props.selected ? classes.selected : classes.root}
      startIcon={props.icon}
      disabled={props.selected} // Disable the button when selected
    >
      {props.title}
    </Button>
  );
}

export default CustomHoverBtn;
