import React from "react";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    IconButton,
    Toolbar,
    Button,
    AppBar,
    Modal,
    TextField,
    Container,
    FormControl,
    FormHelperText,
    Select,
    MenuItem
} from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu';
// @ts-ignore
import AddEventController, { Props } from "./AddEventController";
import { palette } from "../../../../components/src/theme";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import EventSetting from "../../../../components/src/EventSettings";
import AlertModal from "../../../../components/src/AlertModal";
import CustomLoader from "../../../../components/src/CustomLoader";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// @ts-ignore
import styled from 'styled-components';
// @ts-ignore
import AddInstructorDropZone from '../../../../components/src/AddInstructorDropZone';
const drawerWidth = 240;
const userType = localStorage.getItem('userType');
const userName = localStorage.getItem('userName');

const StyledWrapper = styled.div`
  * {
    font-family : Poppins-SemiBold;
    color: #353535;
  }
  .fc-day-today .fc-daygrid-day-frame {
    background: #ffffff
  }
  .fc-daygrid-day-number {
    color: #545454;
    font-weight: 500;
    font-size: 12px;
    font-family: Poppins-SemiBold;
  }
  .fc-day-today .fc-daygrid-day-number{
    color: #fff;
    padding:0.3em;
    background: #f27024;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fc-timegrid-slot-label-cushion{
    text-transform: uppercase;
  }
  .fc-timegrid-axis-cushion, .fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: center;
  }
  .fc-timegrid-slot-minor {
    border: 0!important;
  }
  .fc-col-header {
    background: #ddd;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .fc-col-header-cell {
    height: 40px;
    align-items: center;
    border: 0px;
    border-bottom: 1px solid #ddd;
    padding-top: 10px
  }
  .fc-col-header-cell-cushion {
    word-spacing: 20px;
    color: #f27024;
    font-family: Poppins-Light;
    font-size: 12px;
  }
  .fc-button.fc-prev-button, .fc-button.fc-next-button {
    margin: 10px;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    background: #ffffff;
    border: solid 0.7px #e6e8f0;
    color: red
  }
`
// Customizable Area End

class AddEvent extends AddEventController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    getBold = (value: any) => {
        this.setState({
            bold: value
        })
    }

    getUnderlined = (value: any) => {
        this.setState({
            underlined: value
        })
    }

    getItalic = (value: any) => {
        this.setState({
            italic: value
        })
    }
    getAlignment = (value: any) => {
        this.setState({
            alignment: value
        })
    }

    handleChangePresenter = (event: any) => {
        this.setState({
            presenterName: event.target.value
        })
    }

    getPresenterList = (classes: any, inputError: any) => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    borderRadius: '10px',
                    padding: '16px',
                    border: '1px solid rgba(82,92, 229, 0.12)',
                    minWidth: '300px'
                }}
            >
                <span
                    style={{
                        fontFamily: 'Poppins-Light',
                        fontSize: '20px',
                        color: '#353535',
                        marginRight: '10px',
                    }}
                >
                    Presenter*
                </span>
                <FormControl
                    variant="outlined"
                    fullWidth={true}
                >
                    <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        IconComponent={KeyboardArrowDownIcon}
                        className={classes.select}
                        inputProps={{
                            classes: {
                                icon: classes.selectIcon,
                            },
                        }}
                        value={this.state.presenterName}
                        onChange={(event: any) => this.handleChangePresenter(event)}
                        // input={<Input />}
                        renderValue={
                            this.state.presenterName.length > 0
                                ? undefined
                                : () => <em>Placeholder</em>
                            }
                        // MenuProps={MenuProps}
                        >

                        {this.state.presenterList.length ? this.state.presenterList.map((item: any, index: any) => (
                            <MenuItem 
                                key={index} 
                                value={item.id}
                                style={{
                                    fontSize: '18px',
                                    fontFamily: 'Poppins-SemiBold'
                                }}
                            >
                                {item.attributes.name}
                            </MenuItem>
                        )): null
                        }
                    </Select>
                    {inputError.presenterName ? <FormHelperText style={{color: 'red'}}>Please select presenter</FormHelperText> : null}
                </FormControl>
            </div>
        )
    }

    getAppBar = (classes: any, openSetting: any) => {
        return (
            <AppBar position='fixed' className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.logo}>
                        <img src={require('../../../../components/src/logo.svg')} height={70} />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'space-between',
                            padding: '10px'
                        }}
                    >
                        <div>
                            <Typography
                                style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    fontSize: '40px',
                                    color: '#353535',
                                    marginTop: "25px"
                                }}
                                className={classes.welcome}
                            >
                                Add Event
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: "center"
                            }}
                        >
                            <Button variant="contained" onClick={() => this.handlePublish()} style={{ backgroundColor: "#F27024", color: "#ffffff", marginTop: "25px", marginLeft: '20px', textTransform: "initial", fontFamily: "Poppins-Bold" }}>Schedule Event</Button>
                            <IconButton
                                onClick={() => this.hanldeSettingToggle()}
                                style={{
                                    border: '1px solid #bdbdbd',
                                    height: '38px',
                                    width: '38px',
                                    borderRadius: '8px',
                                    marginTop: '25px',
                                    marginLeft: '20px',
                                    backgroundColor: openSetting ? "#F27024" : "#ffffff"
                                }}
                            >
                                <img src={require('../../assets/settings-unselectednew.png')} height={'38px'} />
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    addNewPresenter = () => {
        return (
            // @ts-ignore
            <Box
                style={{
                    marginLeft: '15px',
                    marginTop: '25px'
                }}
            >
                <Button
                    style={{
                        width: '160px',
                        fontSize: '16px',
                        fontFamily: 'Poppins-SemiBold',
                        height: '64px',
                        border: '1px solid #f27024',
                        padding: '16px 10px',
                        color: '#f27024',
                        textTransform: 'none',
                        cursor: 'pointer'
                    }}
                    onClick={() => this.showPresenterForm()}
                >
                    Add New
                </Button>
            </Box>
        )
    }

    showPresenterForm = () => {
        this.setState({
            enablePresenterForm: !this.state.enablePresenterForm
        })
    }

    presenterForm = (classes: any, inputError: any) => {
        return (
            <div
                style={{
                    borderRadius: '8px',
                    border: '1px solid rgba(82,92, 229, 0.12)',
                    padding: '0px 16px 16px',
                    marginBottom: '15px'
                }}
            >
                <div>
                    <AddInstructorDropZone 
                        type={'image'}
                        error={inputError.addProfilePic}
                        addInstructorProfile = {(acceptedFiles: any) => this.addPresenterProfilePic(acceptedFiles)}
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        name="Presenter_Name"
                        id="PresenterName"
                        value={this.state.addPresenterName}
                        onChange={(e) => this.handlePresenterChange(e, "addPresenterName")}
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true
                        }}
                        inputProps={{
                            min: 0, 
                            style: { 
                                textAlign: 'left', 
                                padding: '4px 36px 4px 24px',
                                color: '#353535',
                            }
                        }}
                        style={{
                            fontSize: "20px",
                            fontFamily: "Poppins-Light",
                            marginTop: "10px",
                            color: '#353535',
                        }}
                        placeholder="Name of the Presenter*"
                        error={inputError?.addInsName === undefined || inputError?.addInsName === "" ? false : true}
                        helperText={inputError.addInsName}
                    />
                    <TextField
                        fullWidth
                        name="Presenter_About"
                        id="PresenterAbout"
                        value={this.state.addPresenterAbout}
                        onChange={(e) => this.handlePresenterChange(e, "addPresenterAbout")}
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true
                        }}
                        inputProps={{
                            min: 0, 
                            style: { 
                                textAlign: 'left',
                                padding: '4px 36px 4px 24px',
                                color: '#353535', 
                            }
                        }}
                        style={{
                            fontSize: "20px",
                            fontFamily: "Poppins-Light",
                            marginTop: "10px",
                            color: '#353535',
                        }}
                        minRows={6}
                        multiline={true}
                        placeholder="About the Presenter*"
                        error={inputError?.addInsAbout === undefined || inputError?.addInsAbout === "" ? false : true}
                        helperText={inputError.addInsAbout}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: '15px',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        style={{
                            width: '160px',
                            background: palette.primary.main,
                            color: '#ffffff',
                            height: '64px',
                            padding: '15px 12px',
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '16px',
                            borderRadius: '8px'
                        }}
                        onClick={() => this.addPresenter()}
                    >
                        {this.state.presenterAddLoading ? 'Adding...' : 'Save' }
                    </Button>
                    <Button
                        style={{
                            width: '160px',
                            background: '#ffffff',
                            color: '#8991a4',
                            height: '64px',
                            padding: '15px 12px',
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '16px',
                            borderRadius: '8px',
                            border: '1px solid #8991a4',
                            marginLeft: '20px'
                        }}
                        onClick={() => this.showPresenterForm()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        )
    }

    presenterDetails = () => {
        let filtered = this.state.presenterList.length && this.state.presenterList.filter((i: any) => {
            return this.state.presenterName == i.id
        })
        return (
            filtered.map((name: any)=>{
                return(
                    <div
                        style={{
                            borderRadius: '8px',
                            border: '1px solid rgba(82,92, 229, 0.12)',
                            padding: '40px 48px',
                            marginBottom: '15px'
                        }}
                    >
                        <div>
                            <img 
                                style={{
                                    height: '184px',
                                    width: '184px',
                                    borderRadius: '9px'
                                }}
                                src={name.attributes.profile_image}
                            />
                        </div>
                        <div>
                            <div
                                style={{
                                    borderRadius: '8px',
                                    border: '1px solid rgba(82,92, 229, 0.12)',
                                    padding: '16px 16px 18px 18px',
                                    marginTop: '15px',
                                    height: '56px'
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Poppins-SemiBold'
                                    }}
                                >
                                    {name.attributes.name}
                                </span>
                            </div>
                            <div
                                style={{
                                    borderRadius: '8px',
                                    border: '1px solid rgba(82,92, 229, 0.12)',
                                    padding: '20px 24px 30px',
                                    marginTop: '15px',
                                    minHeight: '250px'
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Poppins-SemiBold'
                                    }}
                                >
                                    {name.attributes.about}
                                </span>
                            </div>
                        </div>
                    </div>
                )
            })
        )
    }

    changeMainInstructor = (e: any) => {
        this.setState({
            selectedMainInstructor: e?.target.value
        })
    }

    getMainInstructorList = (classes: any) => {
        return (
            <FormControl
                variant="outlined"
                fullWidth={true}
            >
                {
                    userType == 'instructor' ?
                        // @ts-ignore
                        <Box
                            style={{
                                // height: '38px',
                                borderRadius: '6px',
                                border: 'solid 1px #e6e8f0',
                                padding: '15px 12px 15px 15px'
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '18px',
                                    fontFamily: 'Poppins-SemiBold',
                                    color: palette.primary.main
                                }}
                            >
                                {userName}
                            </span>
                        </Box>
                    :
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            // disabled={userType == 'instructor' ? true : false}
                            value={this.state.selectedMainInstructor}
                            onChange={(e: any) => this.changeMainInstructor(e)}
                            // label="Age"
                            IconComponent={KeyboardArrowDownIcon}
                            className={classes.select}
                            inputProps={{
                                classes: {
                                    icon: classes.selectIcon,
                                },
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                this.state.mainInstructorList.map((item: any, index: any) => {
                                    return (
                                        <MenuItem key={index} value={item[0]}>
                                            {item[1]}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                }
            </FormControl>
        )
    }

    clickedEvent = (arg: any) => {
        this.props.navigation.navigate('EventDetails', {id:arg.event.id})
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        const { classes } = this.props;
        const { 
            mobileOpen, 
            previewModal, 
            errorMsg, 
            openAlert, 
            categoryList, 
            getCategoryLoading, 
            dataCreationMsg, 
            selectecCategory, 
            openSetting, 
            authorList, 
            authorLoading, 
            sort, 
            startDateTime, 
            inputError,
            title,
            summary,
            createLoading,
            endDateTime
        } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                {/* @ts-ignore */}
                {
                    createLoading ?
                        <CustomLoader 
                            open={createLoading}
                        />
                    : null
                }
                {openAlert ? (
                    <AlertModal
                        open={openAlert}
                        data={dataCreationMsg ? dataCreationMsg : errorMsg}
                        onClose={() => this.closeAlertBox()}
                        redirectUri={'AddEvent'}
                        navigation={this.props.navigation}
                    />
                ) : null}
                {/* @ts-ignore */}
                <Modal
                    open={previewModal}
                    onClose={() => this.handleModalClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {/* @ts-ignore */}
                    <Box className={classes.previewModalStyle}>
                        {/* <PreviewFE 
                            components={blogList} 
                            titelSubtitle={blogUniqueItem} 
                            date={startDateTime} 
                            bold={bold}
                            underlined={underlined}
                            italic={italic}
                            alignment={alignment}
                        /> */}
                    </Box>
                </Modal>

                <DashboardDrawer
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={this.handleDrawerToggle}
                    // @ts-ignore
                    navigation={this.props.navigation}
                />
                {
                    // @ts-ignore
                    openSetting && <EventSetting
                        handleDrawerToggle={this.handleDrawerToggle}
                        // @ts-ignore
                        navigation={this.props.navigation}
                        categoryList={categoryList}
                        authorList={authorList}
                        authorLoading={authorLoading}
                        handleSelectCategory={this.handleCategorySelect}
                        selectedCategory={selectecCategory}
                        errorMsg={errorMsg}
                        categoryLoading={getCategoryLoading}
                        handleBgImgChange={this.handleBgImgChange}
                        handleSortChange={this.handleSortChange}
                        sort={sort}
                        startDateTime={startDateTime}
                        handlestartDateTimechange={this.handlestartDateTimeChange}
                        endDateTime={endDateTime}
                        handleEndDateTimechange={this.handleEndDateTimeChange}
                        getBold={this.getBold}
                        getUnderlined={this.getUnderlined}
                        getItalic={this.getItalic}
                        getAlignment= {this.getAlignment}
                        type={'podcast'}
                        catValidation={this.state.catValidation}
                        startDateValidation={this.state.startDateErr}
                        endDateValidation={this.state.endDateErr}
                        from={'talk'}
                    />
                }
                {/* @ts-ignore */}
                <Box
                    className={classes.appBarWrap}
                >
                    {
                        this.getAppBar(classes, openSetting)
                    }
                    <Container >
                        <div style={{ width: openSetting ? "calc(100% - 300px)" : "100%", marginBottom: '30px' }}>
                            <div
                                // style={{
                                //     padding: '28px 48px 40px',
                                //     boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
                                //     marginTop: '20px',
                                //     marginBottom: '20px'
                                // }}
                            >
                                <div
                                    style={{
                                        marginTop: '20px'
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: '10px',
                                            boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
                                            padding: '26px 24px 32px',
                                            maxWidth: '664px'
                                        }}
                                    >
                                        <StyledWrapper>
                                            {/* @ts-ignore */}
                                            <FullCalendar
                                                plugins={[ dayGridPlugin ]}
                                                initialView="dayGridMonth"
                                                events={this.state.allEventsList}
                                                eventBackgroundColor={'#ffbe63'}
                                                eventBorderColor={'#ffbe63'}
                                                eventClick={(arg: any) => this.clickedEvent(arg)}
                                                headerToolbar={{
                                                    left: 'title',
                                                    center: '',
                                                    right: 'prev,next'
                                                }}
                                            />
                                        </StyledWrapper>
                                    </div>
                                    <TextField
                                        fullWidth
                                        name="title"
                                        value={title}
                                        onChange={(e) => this.handleTitleChange(e)}
                                        InputProps={{
                                            // className: classes.input,
                                            disableUnderline: true,
                                            style: {
                                                height: '88px',
                                                border: '1px solid #e6e8f0',
                                                borderRadius: '9px',
                                                padding: '16px 36px 16px 24px',
                                                fontSize: '20px'
                                            }
                                        }}
                                        style={{
                                            fontSize: "20px",
                                            fontFamily: "Poppins-Light",
                                            marginTop: "10px"
                                        }}
                                        placeholder="Event Title"
                                        error={inputError?.title === undefined || inputError?.title === "" ? false : true}
                                        helperText={inputError.title}
                                    />
                                    <TextField
                                        name="summary"
                                        style={{ fontSize: "16px", fontFamily: "Poppins-Light", paddingTop: "10px" }}
                                        multiline={true}
                                        minRows={7}
                                        fullWidth
                                        value={summary}
                                        onChange={(e) => this.handleSummaryChange(e)}
                                        inputProps={{ maxLength: 1250 }}
                                        placeholder="Event Description"
                                        InputProps={{
                                            className: classes.input,
                                            disableUnderline: true,
                                            style: {
                                                padding: '20px 24px 30px',
                                                fontSize: '20px',
                                            }
                                        }}
                                        error={inputError?.summary === undefined || inputError?.summary === "" ? false : true}
                                        helperText={inputError.summary}
                                    />
                                </div>
                                <div style={{marginTop: '10px'}}>
                                <Grid item md={5} xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        borderRadius: '10px',
                                        padding: '16px',
                                        border: '1px solid rgba(82,92, 229, 0.12)',
                                        minWidth: '300px'
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-Light',
                                            fontSize: '20px',
                                            color: '#353535',
                                            marginRight: '10px'
                                        }}
                                    >
                                        Presenter
                                    </span>
                                    {
                                        this.getMainInstructorList(classes)
                                    }
                                </div>
                            </Grid>
                            </div>
                            </div>
                        </div>
                    </Container>
                </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// export default withStyles(styles)(LetsRead);
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            logo: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            menuButton: {
                marginRight: theme.spacing(2),
                color: palette.primary.main,
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            appBar: {
                background: '#fff',
                boxShadow: 'none',
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                    background: '#fff',
                    boxShadow: 'none'
                },
            },
            appBarWrap: {
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                },
            },
            welcome: {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            name: {
                fontSize: '24px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '20px',
                    fontSize: '16px'
                },
            },
            tabsBox: {
                // flex: 1,
                // flexDirection: 'row',
                // justifyContent: 'space-between',
                width: '100%'
            },
            tabs_main: {
                "& .MuiTabs-indicator": {
                    backgroundColor: palette.primary.main,
                    borderRadius: '1.5px',
                    height: '4px',
                },
                "& .Mui-selected": {
                    color: palette.primary.main
                }
            },
            headingTabs: {
                fontSize: '24px',
                fontWeight: 600,
                fontFamily: palette.fontFamily.main,
                width: "25%",
                textTransform: 'inherit',
                // minWidth: '50% !important'
            },
            lastTableRow: {
                '&:last-child td, &:last-child th': { border: 0 }
            },
            triangle: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.03))',
                marginTop: "49px",
                marginLeft: "12px",
                borderRadius: "10px",
                '& .MuiAvatar-root': {
                    width: '32px ',
                    height: '32px',
                    marginLeft: '-0.5px ',
                    marginRight: '1px',
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: '0px',
                    right: '20px ',
                    width: '15px',
                    height: '15px ',
                    backgroundColor: '#ffffff',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            },
            root: {
                color: "#F27024 !important",
                "& .Mui-selected": {
                    background: "#F27024",
                    color: "white",
                    fontWeight: "bold",
                },
                ul: {
                    "& .MuiPaginationItem-root": {
                        color: "#F27024"
                    }
                }
            },
            modalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '496px',
                height: "200px",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 5px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            previewModalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '796px',
                maxHeight: "80vh",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '20px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            menuItemStyle: {
                fontFamily: 'Poppins-Light',
                '&:hover': {
                    textAlign: "right",
                    color: '#d53636',
                    backgroundColor: '#ffffff',
                }
            },
            input: {
                width: "100%",
                height: "100%",
                border: "1px solid rgba(82,92, 229, 0.12)",
                borderRadius: "7px",
                textDecoration: "none",
                fontSize: "1em",
                fontFamily: "Poppins-Light",
                backgroundColor: "white",
                color: '#000 !important',
                padding: "5px 1rem", // 
                "& > .MuiInputBase-input": {
                    height: "100%",
                },
                "&  .MuiFormHelperText-root.Mui-error": { 
                    fontFamily: "Poppins-Light",
                  },
            },


        }))(AddEvent)
)
// Customizable Area End
