import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { VideoResponse, VideoType } from "./Interfaces.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    currentPage: number;
    videoList: VideoResponse;
    videoLoading: boolean;
    selectedCategory: VideoType;
    loadedBlogs: number;
    videosPerPage: number;
    carausalPlay: boolean;
    carausalId: any;
    videoPlaying: boolean;
    played: any;
    seeking: boolean;
    allDuration: any;
    light: boolean;
    muted: boolean;
    pip: boolean;
    playbackRate: any;
    volume: any;
    loop: boolean;
    timeDisplayFormat: any;
    videoDetails: any;
    loading: boolean;
    openShare: boolean;
    quality: any;
    hideBtn: boolean;
    currentVideoPlayingId: any;
    windowWidth: any;
    catId: any;
    podcastFilterOptionsData: any;
    errorMsg: any;
    isFavourite: boolean;
    openAlert: boolean;
    updateMessage: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class LetsWatchHomeController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetPaginationVideosCallId: string = "";
    GetVideoFilterListApiId: string = "";
    apiMakeFavouriteId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            videoList: {
                videos: {
                    data: [],
                    meta: {
                        pagination: {
                            current_page: 1,
                            next_page: 0,
                            prev_page: 0,
                            total_pages: 0,
                            total_count: 0,
                        }
                    }
                },
                carousel_videos: [],
                other_videos: [],
            },
            videoLoading: false,
            selectedCategory: {
                id: "",
                type: "",
                attributes: {
                    title: "",
                    about: "",
                    category: {
                        id: -1,
                        name: '',
                        created_at: '',
                        updated_at: '',
                    },
                    created_at: "",
                    video: "",
                    poster: "",
                    view_count: 0,
                    instructor: {
                        name: ""
                    },
                    reviews: [],
                    favourite: false,
                }
            },
            currentPage: 1,
            videosPerPage: 15,
            loadedBlogs: 0,
            carausalPlay: false,
            carausalId: '',
            videoPlaying: false,
            played: 0,
            seeking: false,
            allDuration: 0,
            light: false,
            muted: false,
            pip: false,
            playbackRate: 1,
            volume: 1,
            loop: false,
            timeDisplayFormat: 'normal',
            videoDetails: {},
            loading: false,
            openShare: false,
            quality: 480,
            hideBtn: false,
            currentVideoPlayingId: '',
            windowWidth: window.innerWidth,
            catId: localStorage.getItem('category_id'),
            podcastFilterOptionsData: {},
            errorMsg: '',
            isFavourite: false,
            openAlert: false,
            updateMessage: ''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);


        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if(responseJson && responseJson.errors && responseJson.errors[0].token){
                return;
            }
            if (this.apiGetPaginationVideosCallId === apiRequestCallId) {
                this.paginationSet(responseJson)
            }
            if (apiRequestCallId === this.GetVideoFilterListApiId) {
                this.getVideoFilteredSet(responseJson)
            }
            if(this.apiMakeFavouriteId === apiRequestCallId){
                this.setFav(responseJson)
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        if(this.state.catId){
            this.getVideos(this.state.catId, 1);
        } else {
            this.getVideos('', 1);
        }
        this.getVideoFilterList();
        window.addEventListener('resize', this.updateDimensions)
    }

    componentWillUnmount = (): any => {
		window.removeEventListener('resize', this.updateDimensions)
        localStorage.removeItem('category_id');
	}
    // Customizable Area End
    // web events
    setInputValue = (text: string) => {
        this.setState({ txtInputValue: text });
    }

    setEnableField = () => {
        this.setState({ enableField: !this.state.enableField });
    }

    // Customizable Area Start

    setFav = (responseJson: any) => {
        if(responseJson.message === "Favourite Updated!"){
          this.setState({
            loading: false,
            updateMessage: this.state.isFavourite ? 'Added to your favourites!' : 'Removed from your favourites!',
            openAlert: true
          }, () => {
            if(this.state.catId){
                this.getVideos(this.state.catId, 1);
            } else {
                this.getVideos('', 1);
            }
          })
        } else {
          this.setState({
            loading: false,
            updateMessage: 'Something went wrong!',
            openAlert: true
          })
        }
    }

    makeFavourite = (id: any, status: any) => {
        this.updateFavourite(id, status)
        this.setState({
          isFavourite: status
        })
      }
    
    updateFavourite = (id: any, status: any) => {
        this.setState({
          loading: true
        })
        const userToken = window.localStorage.getItem("token");
        const header = {
          "Content-Type": configJSON.contentType,
          token: userToken
        };
    
        const data = {
          type: 'BxBlockVideoManagement::Video' ,
          id: id,
          favourite: status
        }
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.apiMakeFavouriteId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.updateFavouriteApi
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(data)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'PUT'
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getVideoFilteredSet = (responseJson: any) => {
        if(responseJson){ 
            if(!responseJson?.data?.length){
              this.setState({
                errorMsg: "Data Not Found",
                loading: false
              });
            } else {
              this.setState({
                podcastFilterOptionsData: responseJson.data,
                loading: false
              }, () => {
                this.setState({
                  podcastFilterOptionsData: responseJson.data,
                  loading: false
                })
              })
            }
        }
    }

    paginationSet = (responseJson: any) => {
        if (responseJson) {
            this.setState((prev_state) => {
                return {
                    videoLoading: false,
                    videoList: {
                        ...prev_state?.videoList,
                        videos: {
                            ...prev_state?.videoList?.videos,
                            data: prev_state.currentPage === 1 ? [...responseJson?.data?.videos?.data] : [...prev_state?.videoList?.videos?.data, ...responseJson?.data?.videos?.data],
                            meta: responseJson?.data?.videos?.meta
                        },
                        carousel_videos: responseJson?.data?.carousel_videos,
                        other_videos: responseJson?.data?.other_videos
                    }
                }
            })
        }
    }

    getVideoFilterList () {
        this.setState({loading: true})
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: localStorage.getItem('token')
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.GetVideoFilterListApiId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getPodcastCategoryOptionsApiEndPoint+"?type=video_category"
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'GET'
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
    getAllVideos = () => {
        this.getVideos('', 1);
    }
    getFilteredVideos = (catId: any) => {
        this.setState({
            catId: catId
        })
        this.getVideos(catId, 1);
    }
    getVideos = (catId: any, page: number) => {
        this.setState({ videoLoading: true })
        const userToken = localStorage.getItem("token") ? localStorage.getItem("token") : '';
        let header: any = '';
        if(userToken){
            header = {
                "Content-Type": configJSON.contentType,
                token: userToken,
            };
        } else {
            header = {
                "Content-Type": configJSON.contentType,
            };
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetPaginationVideosCallId = requestMessage.messageId;
        let hitURL = "";
        hitURL = `${configJSON.getAllVideosApiURL}?page=${page}&per_page=${this.state.videosPerPage}`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            hitURL
        );

        if(!catId){
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `${configJSON.getAllVideosApiURL}?page=${page}&per_page=${this.state.videosPerPage}`
            );
          } else {
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `${configJSON.getAllVideosApiURL}?page=${page}&per_page=${this.state.videosPerPage}&category_id=${catId}`
            );
          }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    goToSingleVideo = (id: string) => {
        this.props.navigation.navigate('WatchVideo', {id:id})
    }

    changeCurrentPlayingId = (id: any, cb?: any) =>{
        this.setState({currentVideoPlayingId: id}, ()=> {
            if(cb){
                cb();
            }
        });
    }

    updateDimensions = () => this.setState({windowWidth: window.innerWidth})
    // Customizable Area End

}
