import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { BlogsResponse, BlogType, CategoryType } from "./Interfaces.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currentPage: number;
  blogList: BlogsResponse;
  getBlogLoading: boolean;
  getCategoryLoading: boolean;
  categoryList: CategoryType[];
  filteredBlogList: BlogType[];
  selectedCategory: CategoryType;
  clickAllArticles: boolean;
  loadedBlogs: number;
  blogPerPage: number;
  windowWidth: any;
  isFavourite: boolean;
  loading: boolean;
  updateMessage: any;
  openAlert: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BlogPageController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiGetAllFilteredBlogsCallId: string = "";
  apiGetPaginationBlogsCallId: string = "";
  apiGetAllCategoryId: string = "";
  apiMakeFavouriteId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      blogList: {
        blogs: {
          data: [],
          meta: {
            pagination: {
              current_page: 1,
              next_page: 0,
              prev_page: 0,
              total_pages: 0,
              total_count: 0,
            }
          }
        },
        featured_blogs: [],
        carousel_blogs: [],
      },
      updateMessage: '',
      openAlert: false,
      getBlogLoading: false,
      getCategoryLoading: false,
      categoryList: [],
      selectedCategory: {
        id: "",
        type: "",
        attributes: {
          id: -1,
          name: "All_articles",
          created_at: ""
        }
      },
      filteredBlogList: [],
      clickAllArticles: false,
      currentPage: 1,
      blogPerPage: 12,
      loadedBlogs: 0,
      windowWidth: window.innerWidth,
      isFavourite: false,
      loading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.errors && responseJson.errors[0].token) {
        return;
      }
      if (responseJson) {
        this.setAllResponse(responseJson, apiRequestCallId)
      }
    }
    // Customizable Area End

  }
  // Customizable Area Start

  setBlog = (responseJson: any) => {
    this.setState({
      ...this.state,
      blogList: {
        ...responseJson?.data,
        blogs: {
          ...responseJson?.data?.blogs,
        }
      },
      getBlogLoading: false,

    })
  }

  setFilterBlog = (responseJson: any) => {
    this.setState({
      blogList: responseJson?.data,
      getBlogLoading: false,
      clickAllArticles: true,
    })
  }

  setAllCat = (responseJson: any) => {
    this.setState({
      categoryList: responseJson?.data,
      getCategoryLoading: false,
    })
  }

  setFav = (responseJson: any) => {
    if(responseJson.message === "Favourite Updated!"){
      this.setState({
        loading: false,
        updateMessage: this.state.isFavourite ? 'Added to your favourites!' : 'Removed from your favourites!',
        openAlert: true
      }, () => {
        this.getBlogs(1)
      })
    } else {
      this.setState({
        loading: false,
        updateMessage: 'Something went wrong!',
        openAlert: true
      })
    }
  }

  setAllResponse = (responseJson: any, apiRequestCallId: any) => {
    if (this.apiGetPaginationBlogsCallId === apiRequestCallId) {
      this.setBlog(responseJson)
    }
    if (this.apiGetAllFilteredBlogsCallId === apiRequestCallId) {
      this.setFilterBlog(responseJson)
    }
    if (this.apiGetAllCategoryId === apiRequestCallId) {
      this.setAllCat(responseJson)
    }
    if(this.apiMakeFavouriteId === apiRequestCallId){
      this.setFav(responseJson)
    }
  }
  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getBlogs(1);
    this.getCategory();

    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount = (): any => {
		window.removeEventListener('resize', this.updateDimensions)
	}
  // Customizable Area End
  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start

  makeFavourite = (id: any, status: any) => {
    this.updateFavourite(id, status)
    this.setState({
      isFavourite: status
    })
  }

  updateFavourite = (id: any, status: any) => {
    this.setState({
      loading: true
    })
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken
    };

    const data = {
      type: 'BxBlockPosts::Blog',
      id: id,
      favourite: status
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMakeFavouriteId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateFavouriteApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getBlogs = (page: number, perPage?: number) => {
    this.setState({ ...this.state, getBlogLoading: true, })
    const userToken = localStorage.getItem("token") ? localStorage.getItem("token") : '';
    let header: any = '';
    if(userToken){
      header = {
        "Content-Type": configJSON.contentType,
        token: userToken,
      };
    } else {
      header = {
        "Content-Type": configJSON.contentType,
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPaginationBlogsCallId = requestMessage.messageId;
    let hitURL = "";
    if (this.state.selectedCategory.attributes.name === "All_articles") {
      hitURL = `${configJSON.getAllBlogsApiUrl}?page=${page}&per_page=${perPage ? perPage : this.state.blogPerPage}`
    } else {
      hitURL = `${configJSON.getAllBlogsApiUrl}?category_id=${this.state.selectedCategory?.attributes?.id}&page=${page}&per_page=${perPage ? perPage : this.state.blogPerPage}`

    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCategory = () => {
    this.setState({ getCategoryLoading: true })
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllCategoryId = requestMessage.messageId;
      const hitURL = `${configJSON.getAllCategoryUrl}?type=${configJSON.categoryType}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToSingleArticle = (id: string) => {
    this.props.navigation.navigate('BlogArticle', {id:id})
  }

  changedSelectedCategory = (category: CategoryType) => {
    this.setState({
      selectedCategory: category,
      loadedBlogs: 0,
    }, () => {
      this.getBlogs(1)
    })

  }


  setAllArticleCategory = () => {
    this.setState({
      selectedCategory: {
        id: "",
        type: "",
        attributes: {
          id: -1,
          name: "All_articles",
          created_at: ""
        }
      },
      loadedBlogs: 0,
    }, () => {
      this.getBlogs(1)
    })
  }

  updateDimensions = () => this.setState({windowWidth: window.innerWidth})
  // Customizable Area End

}
