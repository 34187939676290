import React, {useEffect} from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { palette } from './theme';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import {Link} from 'react-router-dom';
import ExploreIcon from '@material-ui/icons/Explore';
import LogoutIcon from '@material-ui/icons/ExitToApp'
const drawerWidth = 240; 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      background: '#eceff1'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    nested: {
      paddingLeft: theme.spacing(7),
    },
  }),
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  mobileOpen: any;
  handleDrawerToggle: () => void;
  navigation: any;
}

const getIcon = (open: any, color: any) => {
  if(open){
    return (
      <ExpandMoreRoundedIcon
        fontSize="medium"
        style={{
          marginRight: '-10px',
          color: color
        }} 
      /> 
    )
  }

  return (
    <ChevronRightRoundedIcon 
      fontSize="medium" 
      style={{
        marginRight: '-10px',
        color: color
      }} 
    />
  )
}

const getLetsTalkMenu = (livePath: any, updatePath: any, handleClick: any, classes: any, open: any) => {
  let iconColor: any =  livePath == "EventDetails" || livePath == "AddEvent" || livePath == "Events" || livePath == "LetsTalk" || livePath == "AddPodcast" || livePath == "AllPodcasts" || livePath == "PodcastCategories" ? '#fff' :'#353535'
  return (
    <>
      <ListItem 
        style={{
          background: livePath == "EventDetails" || livePath == "AddEvent" || livePath == "Events" || livePath == "LetsTalk" || livePath == "AddPodcast" || livePath == "AllPodcasts" || livePath == "PodcastCategories"  ? palette.primary.main : 'none',
          borderRadius: '6px',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between'
        }} 
        button 
        onClick={()=> handleClick(3)}
      >
        <ListItemIcon
          style={{
            minWidth: '30px'
          }}
        >
          <img 
            src={require(livePath == "EventDetails" || livePath == "AddEvent" || livePath == "Events" || livePath == "LetsTalk" || livePath == "AddPodcast" || livePath == "AllPodcasts" || livePath == "PodcastCategories" ? './assets/talk-white.png' : './assets/talk.png')} 
            height={'22px'} 
            width={'22px'} 
          />
        </ListItemIcon>
        <Typography 
          style={{
            fontFamily: 'Poppins-Light',
            fontSize: '16px',
            color: livePath == "EventDetails" || livePath == "AddEvent" || livePath == "Events" || livePath == "LetsTalk" || livePath == "AddPodcast" || livePath == "AllPodcasts" || livePath == "PodcastCategories" ? '#fff' :'#353535',
            marginLeft: '-80px'
          }}
        >
          Let's Talk
        </Typography>
        {/* @ts-ignore */}
        {
          getIcon(open[3], iconColor)
        }
      </ListItem>
      {/* @ts-ignore */}
      <Collapse in={open[3]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested} onClick={() => updatePath('LetsTalk', 'AllPodcasts')}>
            <Typography
              style={{
                color: livePath == "AllPodcasts" ? palette.primary.main : '#8991a4',
                fontSize: '16px',
                fontFamily: 'Poppins-Light'
              }}
            >
              All Podcasts
            </Typography>
          </ListItem>
          {/* <Link
            to={'/LetsTalk/AddPodcast'}
            style={{textDecoration : 'none'}}
          > */}
            <ListItem button className={classes.nested} onClick={() => updatePath('LetsTalkAddPodcast', 'AddPodcast')}>
              <Typography
                style={{
                  color: livePath == "AddPodcast" ? palette.primary.main : '#8991a4',
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light'
                }}
              >
                Add Podcasts
              </Typography>
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => updatePath('LetsTalkEvents', 'Events')}>
              <Typography
                style={{
                  color: livePath == "EventDetails" || livePath == "Events" || livePath == "AddEvent" ? palette.primary.main : '#8991a4',
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light'
                }}
              >
                Events
              </Typography>
            </ListItem>
          {/* </Link> */}
          {/* <Link
            to={'/LetsTalk/PodcastCategories'}
            style={{textDecoration : 'none'}}
          > */}
            <ListItem button className={classes.nested} onClick={() => updatePath('LetsTalkCategories', 'PodcastCategories')}>
              <Typography
                style={{
                  color: livePath == "PodcastCategories" ? palette.primary.main : '#8991a4',
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light'
                }}
              >
                Categories
              </Typography>
            </ListItem>
          {/* </Link> */}
        </List>
      </Collapse>
    </>
  )
}

const getLetsWatchMenu = (livePath: any, updatePath: any, handleClick: any, classes: any, open: any) => {
  let iconColor: any = livePath == "LetsWatch" || livePath == "AddVideos" || livePath == "AllVideos" || livePath == "VideoCategories" ? '#fff' :'#353535'
  return (
    <>
      <ListItem 
          style={{
            background: livePath == "LetsWatch" || livePath == "AddVideos" || livePath == "AllVideos" || livePath == "VideoCategories"  ? palette.primary.main : 'none',
            borderRadius: '6px',
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between'
          }} 
          button 
          onClick={()=> handleClick(12)}
        >
          <ListItemIcon
            style={{
              minWidth: '30px'
            }}
          >
            <img 
              src={require(livePath == "LetsWatch" || livePath == "AddVideos" || livePath == "AllVideos" || livePath == "VideoCategories" ? './assets/watch-white.png' : './assets/watch.png')} 
              height={'22px'} 
              width={'22px'} 
            />
          </ListItemIcon>
          <Typography 
            style={{
              fontFamily: 'Poppins-Light',
              fontSize: '16px',
              color: livePath == "LetsWatch" || livePath == "AddVideos" || livePath == "AllVideos" || livePath == "VideoCategories" ? '#fff' :'#353535',
              marginLeft: '-60px'
            }}
          >
            Let's Watch
          </Typography>
          {/* @ts-ignore */}
          {
            getIcon(open[12], iconColor)
          }
        </ListItem>
        {/* @ts-ignore */}
        <Collapse in={open[12]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <Link
              to={'/LetsWatch/AllVideos'}
              style={{textDecoration : 'none'}}
            > */}
              <ListItem button className={classes.nested} onClick={() => updatePath('LetsWatch', 'AllVideos')}>
                <Typography
                  style={{
                    color: livePath == "AllVideos" ? palette.primary.main : '#8991a4',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light'
                  }}
                >
                  All Videos
                </Typography>
              </ListItem>
            {/* </Link> */}
            {/* <Link
              to={'/LetsWatch/AddVideos'}
              style={{textDecoration : 'none'}}
            > */}
              <ListItem button className={classes.nested} onClick={() => updatePath('LetsWatchAddVideos', 'AddVideos')}>
                <Typography
                  style={{
                    color: livePath == "AddVideos" ? palette.primary.main : '#8991a4',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light'
                  }}
                >
                  Add Videos
                </Typography>
              </ListItem>
            {/* </Link> */}
            {/* <Link
              to={'/LetsWatch/VideoCategories'}
              style={{textDecoration : 'none'}}
            > */}
              <ListItem button className={classes.nested} onClick={() => updatePath('LetsWatchCategories', 'VideoCategories')}>
                <Typography
                  style={{
                    color: livePath == "VideoCategories" ? palette.primary.main : '#8991a4',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light'
                  }}
                >
                  Categories
                </Typography>
              </ListItem>
            {/* </Link> */}
          </List>
        </Collapse>
    </>
  )
}

const getLetsLearnMenu = (livePath: any, updatePath: any, handleClick: any, classes: any, open: any) => {
  let iconColor: any = livePath == "LetsLearn" || livePath == "AllCourses" || livePath == "AddNewCourses" || livePath == "CourseCategories" ? '#fff' :'#353535'
  return (
    <>
      <ListItem 
          style={{
            background: livePath == "LetsLearn" || livePath == "AllCourses" || livePath == "AddNewCourses" || livePath == "CourseCategories" ? palette.primary.main : 'none',
            borderRadius: '6px',
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between'
          }} 
          button 
          onClick={()=> handleClick(1)}
        >
          <ListItemIcon
            style={{
              minWidth: '30px'
            }}
          >
            <img 
              src={require(livePath == "LetsLearn" || livePath == "AllCourses" || livePath == "AddNewCourses" || livePath == "CourseCategories" ? './assets/learn-white.png' : './assets/learn.png')} 
              height={'24px'} 
              width={'24px'} 
            />
          </ListItemIcon>
          <Typography 
            style={{
              fontFamily: 'Poppins-Light',
              fontSize: '16px',
              color: livePath == "LetsLearn" || livePath == "AllCourses" || livePath == "AddNewCourses" || livePath == "CourseCategories" ? '#fff' :'#353535',
              marginLeft: '-65px'
            }}
          >
            Let's Learn
          </Typography>
          {/* @ts-ignore */}
          {
            getIcon(open[1], iconColor)
          }
        </ListItem>
        {/* @ts-ignore */}
        <Collapse in={open[1]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <Link
              to={'/LetsLearn/AllCourses'}
              style={{textDecoration : 'none'}}
            > */}
              <ListItem button className={classes.nested} onClick={() => updatePath('LetsLearn', 'AllCourses')}>
                <Typography
                  style={{
                    color: livePath == "AllCourses" ? palette.primary.main : '#8991a4',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light'
                  }}
                >
                  All Courses
                </Typography>
              </ListItem>
            {/* </Link> */}
            {/* <Link
              to={'/LetsLearn/AddNewCourses'}
              style={{textDecoration : 'none'}}
            > */}
              <ListItem button className={classes.nested} onClick={() => updatePath('AddNewCourses', 'AddNewCourses')}>
                <Typography
                  style={{
                    color: livePath == "AddNewCourses" ? palette.primary.main : '#8991a4',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light'
                  }}
                >
                  Add Courses
                </Typography>
              </ListItem>
            {/* </Link> */}
            {/* <Link
              to={'/LetsLearn/CourseCategories'}
              style={{textDecoration : 'none'}}
            > */}
              <ListItem button className={classes.nested} onClick={() => updatePath('LetsLearnCategories', 'CourseCategories')}>
                <Typography
                  style={{
                    color: livePath == "CourseCategories" ? palette.primary.main : '#8991a4',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light'
                  }}
                >
                  Categories
                </Typography>
              </ListItem>
            {/* </Link> */}
          </List>
        </Collapse>
    </>
  )
}

const getLetsReadMenu = (livePath: any, updatePath: any, handleClick: any, classes: any, open: any) => {
  let iconColor: any = livePath == "LetsRead" || livePath == "AllPosts" || livePath == "AddNew" || livePath == "Categories"  ? '#fff' : '#353535'
  return (
    <>
      <ListItem 
          style={{
            background: livePath == "LetsRead" || livePath == "AllPosts" || livePath == "AddNew" || livePath == "Categories" ? palette.primary.main : 'none',
            borderRadius: '6px',
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between'
          }} 
          button 
          onClick={()=> handleClick(2)}
        >
          <ListItemIcon
            style={{
              minWidth: '30px'
            }}
          >
            <img 
              src={require(livePath == "LetsRead" || livePath == "AllPosts" || livePath == "AddNew" || livePath == "Categories"  ? './assets/readIcon-white.png' : './assets/readIcon.png')} 
              height={livePath == "LetsRead" || livePath == "AllPosts" || livePath == "AddNew" || livePath == "Categories"  ? '22px' : '24px'} 
              width={livePath == "LetsRead" || livePath == "AllPosts" || livePath == "AddNew" || livePath == "Categories"  ? '20px' : '24px'} 
            />
          </ListItemIcon>
          <Typography 
            style={{
              fontFamily: 'Poppins-Light',
              fontSize: '16px',
              color: livePath == "LetsRead" || livePath == "AllPosts" || livePath == "AddNew" || livePath == "Categories"  ? '#fff' : '#353535',
              marginLeft: '-65px'
            }}
          >
            Let's Read
          </Typography>
          {/* @ts-ignore */}
          {
            getIcon(open[2], iconColor)
          }
        </ListItem>
        {/* @ts-ignore */}
        <Collapse in={open[2]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <Link to={'/LetsRead/AllPosts'} style={{textDecoration: "none"}}> */}
              <ListItem button className={classes.nested} onClick={() => updatePath('LetsRead', 'AllPosts')}>
                <Typography
                  style={{
                    color: livePath == "AllPosts" ? palette.primary.main : '#8991a4',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light'
                  }}
                >
                  All Posts
                </Typography>
              </ListItem>
            {/* </Link> */}
          </List>
          <List component="div" disablePadding>
            {/* <Link to={'/LetsRead/AddNew'} style={{textDecoration: "none"}}> */}
              <ListItem button className={classes.nested} onClick={() => updatePath('AddNew', 'AddNew')}>
                <Typography
                  style={{
                    color: livePath == "AddNew" ? palette.primary.main : '#8991a4',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light'
                  }}
                >
                  Add New
                </Typography>
              </ListItem>
            {/* </Link> */}
          </List>
          <List component="div" disablePadding>
            {/* <Link to={'/LetsRead/Categories'} style={{textDecoration: "none"}}> */}
              <ListItem button className={classes.nested} onClick={() => updatePath('LetsReadCategories', 'Categories')}>
                <Typography
                  style={{
                    color: livePath == "Categories" ? palette.primary.main : '#8991a4',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light'
                  }}
                >
                  Categories
                </Typography>
              </ListItem>
            {/* </Link> */}
          </List>
        </Collapse>
    </>
  )
}

const getDiscoverCategoriesMenu = (classes: any, updatePath: any, livePath: any) => {
  return (
    <>
      <List component="div" disablePadding>
          {/* <Link to={'/LetsRead/Categories'} style={{textDecoration: "none"}}> */}
            <ListItem button className={classes.nested} onClick={() => updatePath('PeopleCategories', 'PeopleCategories')}>
              <Typography
                style={{
                  color: livePath == "PeopleCategories" ? palette.primary.main : '#8991a4',
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light'
                }}
              >
                People Categories
              </Typography>
            </ListItem>
          {/* </Link> */}
        </List>
        <List component="div" disablePadding>
          {/* <Link to={'/LetsRead/Categories'} style={{textDecoration: "none"}}> */}
            <ListItem button className={classes.nested} onClick={() => updatePath('OrgCategories', 'OrgCategories')}>
              <Typography
                style={{
                  color: livePath == "OrgCategories" ? palette.primary.main : '#8991a4',
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light'
                }}
              >
                Organization Categories
              </Typography>
            </ListItem>
          {/* </Link> */}
        </List>
        <List component="div" disablePadding>
          {/* <Link to={'/LetsRead/Categories'} style={{textDecoration: "none"}}> */}
            <ListItem button className={classes.nested} onClick={() => updatePath('BookCategories', 'BookCategories')}>
              <Typography
                style={{
                  color: livePath == "BookCategories" ? palette.primary.main : '#8991a4',
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light'
                }}
              >
                Book Categories
              </Typography>
            </ListItem>
          {/* </Link> */}
        </List>
        <List component="div" disablePadding>
          {/* <Link to={'/LetsRead/Categories'} style={{textDecoration: "none"}}> */}
            <ListItem button className={classes.nested} onClick={() => updatePath('FilmCategories', 'FilmCategories')}>
              <Typography
                style={{
                  color: livePath == "FilmCategories" ? palette.primary.main : '#8991a4',
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light'
                }}
              >
                Film Categories
              </Typography>
            </ListItem>
          {/* </Link> */}
        </List>
    </>
  )
}

const getLetsDiscoverMenu = (livePath: any, updatePath: any, handleClick: any, classes: any, open: any) => {
  let iconColor: any = livePath == "LetsDiscover" || livePath == "FilmCategories" || livePath == "BookCategories" || livePath == "OrgCategories" || livePath == "PeopleCategories" || livePath == "AllList" || livePath == "DiscoverTabs" || livePath == "AddPeople" || livePath == "AddOrganisation" || livePath == "AddFilm" || livePath == "AddBook"  ? '#fff' : '#353535'
  return (
    <>
      <ListItem 
          style={{
            background: livePath == "LetsDiscover" || livePath == "FilmCategories" || livePath == "BookCategories" || livePath == "OrgCategories" || livePath == "PeopleCategories" || livePath == "AllList" || livePath == "DiscoverTabs" || livePath == "AddPeople" || livePath == "AddOrganisation" || livePath == "AddFilm" || livePath == "AddBook" ? palette.primary.main : 'none',
            borderRadius: '6px',
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between'
          }} 
          button 
          onClick={()=> handleClick(112)}
        >
          <ListItemIcon
            style={{
              minWidth: '30px'
            }}
          >
            <img 
              src={require(livePath == "LetsDiscover" || livePath == "FilmCategories" || livePath == "BookCategories" || livePath == "OrgCategories" || livePath == "PeopleCategories" || livePath == "AllList" || livePath == "DiscoverTabs" || livePath == "AddPeople" || livePath == "AddOrganisation" || livePath == "AddFilm" || livePath == "AddBook"  ? './assets/readIcon-white.png' : './assets/readIcon.png')} 
              height={livePath == "LetsDiscover" || livePath == "FilmCategories" || livePath == "BookCategories" || livePath == "OrgCategories" || livePath == "PeopleCategories" || livePath == "AllList" || livePath == "DiscoverTabs" || livePath == "AddPeople" || livePath == "AddOrganisation" || livePath == "AddFilm" || livePath == "AddBook"  ? '22px' : '24px'} 
              width={livePath == "LetsDiscover" || livePath == "FilmCategories" || livePath == "BookCategories" || livePath == "OrgCategories" || livePath == "PeopleCategories"  || livePath == "AllList" || livePath == "DiscoverTabs" || livePath == "AddPeople" || livePath == "AddOrganisation" || livePath == "AddFilm" || livePath == "AddBook"  ? '20px' : '24px'} 
            />
          </ListItemIcon>
          <Typography 
            style={{
              fontFamily: 'Poppins-Light',
              fontSize: '16px',
              color: livePath == "LetsDiscover" || livePath == "FilmCategories" || livePath == "BookCategories" || livePath == "OrgCategories" || livePath == "PeopleCategories" || livePath == "AllList" || livePath == "DiscoverTabs" || livePath == "AddPeople" || livePath == "AddOrganisation" || livePath == "AddFilm" || livePath == "AddBook"  ? '#fff' : '#353535',
              marginLeft: '-35px'
            }}
          >
            Let's Discover
          </Typography>
          {/* @ts-ignore */}
          {
            getIcon(open[112], iconColor)
          }
        </ListItem>
        {/* @ts-ignore */}
        <Collapse in={open[112]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <Link to={'/LetsRead/AddNew'} style={{textDecoration: "none"}}> */}
              <ListItem button className={classes.nested} onClick={() => updatePath('AllList', 'AllList')}>
                <Typography
                  style={{
                    color: livePath == "AllList" ? palette.primary.main : '#8991a4',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light'
                  }}
                >
                  All List
                </Typography>
              </ListItem>
            {/* </Link> */}
          </List>
          <List component="div" disablePadding>
            {/* <Link to={'/LetsRead/AddNew'} style={{textDecoration: "none"}}> */}
              <ListItem button className={classes.nested} onClick={() => updatePath('LetsDiscover', 'DiscoverTabs')}>
                <Typography
                  style={{
                    color: livePath == "DiscoverTabs" || livePath == "AddPeople" || livePath == "AddOrganisation" || livePath == "AddFilm" || livePath == "AddBook" ? palette.primary.main : '#8991a4',
                    fontSize: '16px',
                    fontFamily: 'Poppins-Light'
                  }}
                >
                  Add New
                </Typography>
              </ListItem>
            {/* </Link> */}
          </List>
          {
            getDiscoverCategoriesMenu(classes, updatePath, livePath)
          }
        </Collapse>
    </>
  )
}

const getDashboardMenu = (livePath: any, updatePath: any) => {
  return (
    <>
      <ListItem button
          style={{
            background: livePath == "Dashboard" || livePath == "" ? palette.primary.main : 'none',
            borderRadius: '6px'
          }}
          onClick={() => updatePath('Dashboard', 'Dashboard')}
        >
          <div
            style={{
              display: 'flex',
              textDecoration: 'none'
            }}
          >
            <ListItemIcon
              style={{
                minWidth: '30px'
              }}
            >
              <img src={require(livePath == "Dashboard" || livePath == "" ? './assets/dashboard-white.png' : './assets/dashboard.png')} height={'22px'} width={'22px'} />
            </ListItemIcon>
            <Typography 
              style={{
                fontFamily: 'Poppins-Light',
                fontSize: '16px',
                color: livePath == "Dashboard" || livePath == "" ? '#FFF' : '#353535'
              }}
            >
              Dashboard
            </Typography>
          </div>
        </ListItem>
    </>
  )
}

const getHelpMenu = (livePath: any, updatePath: any) => {
  return (
    <>
      <ListItem button
          style={{
            background: livePath == "Help" || livePath == "" ? palette.primary.main : 'none',
            borderRadius: '6px',
            marginTop: '20px'
          }}
          onClick={() => updatePath('Help', 'Help')}
        >
          <div
            style={{
              display: 'flex',
              textDecoration: 'none'
            }}
          >
            <ListItemIcon
              style={{
                minWidth: '30px'
              }}
            >
              <img src={require(livePath == "Help" || livePath == "" ? './assets/dashboard-white.png' : './assets/dashboard.png')} height={'22px'} width={'22px'} />
            </ListItemIcon>
            <Typography 
              style={{
                fontFamily: 'Poppins-Light',
                fontSize: '16px',
                color: livePath == "Help" || livePath == "" ? '#FFF' : '#353535'
              }}
            >
              Help
            </Typography>
          </div>
        </ListItem>
    </>
  )
}

const getExploreMenu = (livePath: any, navigatePath:(path: string)=> void) => {
  const handleExplore = () => {
    navigatePath('Home');
  };
  return (
    <>
      <ListItem button
          style={{
            background: livePath == "Home" || livePath == "" ? palette.primary.main : 'none',
            borderRadius: '6px',
            marginTop: '20px'
          }}
          onClick={handleExplore}
        >
          <div
            style={{
              display: 'flex',
              textDecoration: 'none'
            }}
          >
            <ListItemIcon
              style={{
                minWidth: '30px'
              }}
            >
              {/* <img src={require(livePath == "Help" || livePath == "" ? './assets/dashboard-white.png' : './assets/dashboard.png')} height={'22px'} width={'22px'} /> */}
              <ExploreIcon />
            </ListItemIcon>
            <Typography 
              style={{
                fontFamily: 'Poppins-Light',
                fontSize: '16px',
                color: '#353535'
              }}
            >
              Explore
            </Typography>
          </div>
        </ListItem>
    </>
  )
}

const getLogoutMenu = (livePath: any, navigatePath:(path: string)=> void) => {
  const handleLogout = () => {
    navigatePath('Login');
  };
  return (
    <>
      <ListItem button
          style={{
            background: livePath == "Login" || livePath == "" ? palette.primary.main : 'none',
            borderRadius: '6px',
            marginTop: '20px'
          }}
          onClick={handleLogout}
        >
          <div
            style={{
              display: 'flex',
              textDecoration: 'none'
            }}
          >
            <ListItemIcon
              style={{
                minWidth: '30px'
              }}
            >
              {/* <img src={require(livePath == "Help" || livePath == "" ? './assets/dashboard-white.png' : './assets/dashboard.png')} height={'22px'} width={'22px'} /> */}
              <LogoutIcon />
            </ListItemIcon>
            <Typography 
              style={{
                fontFamily: 'Poppins-Light',
                fontSize: '16px',
                color: '#353535'
              }}
            >
              Logout
            </Typography>
          </div>
        </ListItem>
    </>
  )
}

const getUserListMenu = (livePath: any, updatePath: any) => {
  return (
    <>
      <ListItem button
          style={{
            background: livePath == "UserList" || livePath == "" ? palette.primary.main : 'none',
            borderRadius: '6px'
          }}
          onClick={() => updatePath('UserList', 'UserList')}
        >
          <div
            style={{
              display: 'flex',
              textDecoration: 'none'
            }}
          >
            <ListItemIcon
              style={{
                minWidth: '30px'
              }}
            >
              <img src={require(livePath == "UserList" || livePath == "" ? './assets/dashboard-white.png' : './assets/dashboard.png')} height={'22px'} width={'22px'} />
            </ListItemIcon>
            <Typography 
              style={{
                fontFamily: 'Poppins-Light',
                fontSize: '16px',
                color: livePath == "UserList" || livePath == "" ? '#FFF' : '#353535'
              }}
            >
              User List
            </Typography>
          </div>
        </ListItem>
    </>
  )
}

export default function DashboardDrawer(props: Props, {history}: any) {
  const { mobileOpen } = props;
  const classes = useStyles();
  const theme = useTheme();
  const userType = localStorage.getItem('userType');
  const userPermission = localStorage.getItem('userPermission');

  const [open, setOpen] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [livePath, setLivePath] = React.useState(window?.location?.href.split('/', 5).pop());

  const handleLogout = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const updatePath = (path: any, activePath: any) => {
    setLivePath(activePath);
    props.navigation.navigate(path)
  }

  const navigatePath = (path: string) => {
    setLivePath(path)
    if(path === "Login"){
      localStorage.clear()
    }
    props.navigation.navigate(path)
  }

  const handleClose = (type: any) => {
    if(type == 'logout'){
      setAnchorEl(null);
      localStorage.clear();
      props.navigation.navigate('Login')
    } else {
      setAnchorEl(null);
    } 
  };

  const handleClick = (id: any) => {
    //@ts-ignore
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const drawer = (
    <div
      style={{
        marginBottom: '70px'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        <div
          onClick={() => updatePath('Dashboard', 'Dashboard')}
        >
          <img style={{cursor: 'pointer'}} src={require('../src/logo.svg')} height={80} />
        </div>
      </div>
      <div
        style={{
          padding: '10px',
          marginTop: '15px'
        }}
      >
        {
          getDashboardMenu(livePath, updatePath)
        }
        {
          userType === 'admin' ?
            getUserListMenu(livePath, updatePath)
          : null
        }
        {
          getLetsTalkMenu(livePath, updatePath, handleClick, classes, open)
        }
        {
          getLetsWatchMenu(livePath, updatePath, handleClick, classes, open)
        }
        {
          getLetsLearnMenu(livePath, updatePath, handleClick, classes, open)
        }
        {
          getLetsReadMenu(livePath, updatePath, handleClick, classes, open)
        }
        {
          userPermission === 'true' ?
            getLetsDiscoverMenu(livePath, updatePath, handleClick, classes, open)
          : null
        }
        {
          getHelpMenu(livePath, updatePath)
        }
        {
          getExploreMenu(livePath, navigatePath)
        }
        {
          getLogoutMenu(livePath, navigatePath)
        }
      </div>
      <div
        style={{
          position: 'relative',
        }}
      >
        <div 
          style={{
            position: 'fixed',
            bottom: 0,
            width: 'inherit',
            maxWidth: drawerWidth - 40,
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
              paddingBottom: '10px',
              background: '#eceff1'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '140px'
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Poppins-SemiBold',
                  fontSize: '16px',
                  color: '#353535',
                  textTransform: 'capitalize',
                  whiteSpace: 'nowrap',
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {localStorage.getItem('userName')}
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Poppins-Light',
                  fontSize: '14px',
                  color: '#8991a4',
                  marginTop: '-4px',
                  textTransform: 'capitalize'
                }}
              >
                {localStorage.getItem('userType') === "instructor"
                ? "contributor"
                : localStorage.getItem('userType')}
              </Typography>
            </div>
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginLeft: '50px'
              }}
            >
              <MoreHorizRoundedIcon 
                onClick={handleLogout} 
                style={{
                  color: '#8991a4',
                  cursor: 'pointer',
                }}
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose('')}
              >
                <MenuItem 
                  onClick={() => handleClose('')}
                  style={{
                    fontFamily: 'Poppins-Light',
                    color: '#353535',
                    fontSize: '16px'
                  }}
                >
                  <Link
                    to={'/'}
                    style={{
                      fontFamily: 'Poppins-Light',
                      color: '#353535',
                      fontSize: '16px',
                      textDecoration: 'none'
                    }}
                  >
                  Explore
                  </Link>
                </MenuItem>
                <MenuItem 
                  onClick={() => handleClose('logout')}
                  style={{
                    fontFamily: 'Poppins-Light',
                    color: '#353535',
                    fontSize: '16px'
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* @ts-ignore */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={props.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        {/* @ts-ignore */}
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}