Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.contentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.getAllVideosApiURL = "bx_block_video_management/videos"
exports.getVideoDetailsApiEndPoint = 'bx_block_video_management/video_podcasts'
exports.postReviewUrl = "bx_block_library2/reviews"
exports.getPodcastCategoryOptionsApiEndPoint = "bx_block_categories/categories";
exports.updateFavouriteApi = "bx_block_library2/courses/favourite";
// Customizable Area End