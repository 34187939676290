import { Box, Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { SingeArticleProps, useStyles } from "./BlogCard1";
import { PlaceholderImage } from "../../blocks/ContentManagement/src/assets";

const RealtedArticleCard = ({item, gotToSingleArticle }: SingeArticleProps) => {
    const classes = useStyles();
    return (
        <Card onClick={() => gotToSingleArticle(item?.id)} style={{cursor: "pointer", width: "100%", height: 283, backgroundImage: `url(${item?.attributes?.cover_image || PlaceholderImage})`, backgroundRepeat: "no-repeat", backgroundAttachment: "local", backgroundSize: "cover", backgroundPosition: "center", borderRadius: "10px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", height: "90%" }}>
                <Box>
                    <Typography gutterBottom style={{ fontFamily: "Poppins-SemiBold", fontSize: "1.5rem", color: "#ffffff" }}>
                        {item?.attributes?.title}
                    </Typography>
                    <Typography gutterBottom style={{fontSize: "14px", color: "#ffffff", fontFamily: "Poppins-Light" }} className={classes.limitedText}>
                    {item?.attributes?.subtitle === null ? "Subtitle is not present" : item?.attributes?.subtitle.substring(0, 80) + '...'}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
}
export default RealtedArticleCard;