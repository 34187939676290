import React from "react";

// Customizable Area Start
import { createTheme } from '@material-ui/core/styles';
import { 
  Container,
  Grid,
  Typography,
  Breadcrumbs,
  Link
} from "@material-ui/core";
import { palette } from "../../../components/src/theme";
// @ts-ignore
import LiveVideoStreaming from '../../../components/src/LiveVideoStreaming';
import AlertModal from '../../../components/src/AlertModal';
const userType = localStorage.getItem('userType')
// Customizable Area End

import LiveStreamingController, {
  Props,
  configJSON
} from "./LiveStreamingController";

export default class LiveStreaming extends LiveStreamingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getBreadCrumb = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link  
          href="/"
          style={{
            color: palette.primary.main,
            fontFamily: 'Poppins-Light',
            fontSize: '14px'
          }}
        >
          Home
        </Link>
        <Link
          style={{
            color: palette.primary.main,
            fontFamily: 'Poppins-Light',
            fontSize: '14px'
          }}
        >
          Events
        </Link>
        <Typography 
          style={{
            fontFamily: 'Poppins-Light',
            fontSize: '14px'
          }}
        >
          {this.state.eventDetails?.attributes?.title}
        </Typography>
      </Breadcrumbs>
    )
  }

  closeAlertBox = () => {
    this.setState({
      showMessage: false
    }, () => {
      if(this.state.message !== 'Something went wrong!'){
        this.props.navigation.navigate('LetsTalkEvents')
      }
    });
  };
  

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <div>
          {this.state.showMessage ? (
                <AlertModal
                    open={this.state.showMessage}
                    data={this.state.message}
                    // @ts-ignore
                    onClose={() => this.closeAlertBox()}
                />
            ) : null
          }
          <Container>
            <div>
              {
                this.getBreadCrumb()
              }
            </div>
            <div
              style={{
                marginTop: '20px',
                marginBottom: '20px'
              }}
            >
              <Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                >
                  {
                    userType == null ? window.location.reload() : null
                  }
                  {
                    userType && this.state.channelName ? 
                      <LiveVideoStreaming 
                        startRecording={() => this.startRecording()}
                        endStream={() => this.endStream()}
                        eventDetails={this.state.eventDetails}
                        role={userType === 'admin' || userType === 'instructor' ? 'host' : 'audience'}
                        channelName={this.state.channelName}
                        agoraToken={userType === 'admin' || userType === 'instructor' ? this.state.agoraToken : this.state.tokenAud}
                      />
                    : <span>Loading...</span>
                  }
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                >
                  <div
                    style={{
                      marginBottom: '20px',
                      paddingBottom: '20px',
                      borderBottom: '1px solid #e6e8f0'
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'Poppins-SemiBold',
                        fontSize: '32px',
                        color: '#353535'
                      }}
                    >
                      {this.state.eventDetails?.attributes?.title}
                    </Typography>
                  </div>
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      color: '#545454',
                      fontSize: '16px',
                      fontFamily: 'Poppins-Light',
                      letterSpacing: '0.2px'
                    }}
                  >
                    {this.state.eventDetails?.attributes?.description}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      color: '#545454',
                      fontSize: '16px',
                      fontFamily: 'Poppins-Light',
                      marginTop: '20px',
                      letterSpacing: '0.2px'
                    }}
                  >
                    <b style={{color: '#353535'}}>Live Streaming By : </b> <span> {this.state.eventDetails?.attributes?.instructor.name}</span>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: '20px 0px',
    }
  },
});

const webStyle = {
  mainWrapper: {
    display: 'flex', 
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column', 
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  buttonStyle: { 
    width: '100%',
    height: '45px',  
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)', 
  }
};
// Customizable Area End
