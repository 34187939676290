import React, { useState, useEffect } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { SearchIcon } from "../../blocks/helpcentre/src/assets";
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        border: '2px solid #ccc',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: "100%",
        height: '3rem'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        marginLeft: "10px"
    },
}));

interface ISearchProps {
    getSearchResult: (search: string) => void;
}


export default function FaqSearchBar({ getSearchResult }: ISearchProps) {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState("");
    const [timerId, setTimerId] = useState<number | undefined>(undefined);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);

        if (value.length >= 3 || value.length === 0) {
            // Clear the existing timer
            if (timerId) {
                clearTimeout(timerId);
            }

            // Start a new timer
            const newTimerId = window.setTimeout(() => {
                getSearchResult(value);
            }, 500);

            setTimerId(newTimerId);
        }
    };

    useEffect(() => {
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [timerId]);


    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <IconButton aria-label="expand" size="small">
                    <img src={SearchIcon} alt="down icon" height="25px" width="25px" style={{marginRight: "5px"}} />
                </IconButton>
            </div>
            <InputBase
                placeholder="Search"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={searchValue}
                onChange={handleSearchChange}
            />
        </div>
    );
}
