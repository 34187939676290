Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";
exports.forgotPasswordAPiContentType = "application/json";
// Customizable Area Start
exports.getOtpApi = "bx_block_forgot_password/otp";
exports.confirmOtpApi = "bx_block_forgot_password/otp_confirmation"
exports.changePasswordApi = "bx_block_forgot_password/password"
exports.enterValidEmail = 'Please enter a valid email.'
exports.enterValidOtp = 'Please enter valid OTP'
exports.passwordNotMatched = 'Password does not matched!'
exports.enterPwd = 'Please enter password!'
exports.sww = "Something went wrong"
exports.pwdMustContain = 'Password must contains atleast one number, one capital letter, one small letter and one special character.'
// Customizable Area End