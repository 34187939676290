import React from "react";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
// Customizable Area Start
import {
    Box,
    Typography,
    IconButton,
    Toolbar,
    AppBar,
    Grid,
    Button
} from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu';
import DiscoverTabsController, { Props } from "./DiscoverTabsController";
import CustomInput from "../../../../components/src/CustomInput";
import { palette } from "../../../../components/src/theme";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";


const drawerWidth = 240;

const filterBtn = [
    {
        title: 'Add New Person',
        navigation: 'AddPeople'
    },
    {
        title: 'Add New Organization',
        navigation: 'AddOrganisation'
    },
    {
        title: 'Add New Book',
        navigation: 'AddBook'
    },
    {
        title: 'Add New Film',
        navigation: 'AddFilm'
    }
]

// Customizable Area End

class DiscoverTabs extends DiscoverTabsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    getAppBar = (classes: any) => {
        return (
            <AppBar position='fixed' className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.logo}>
                        <img src={require('../../../../components/src/logo.svg')} height={70} />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'space-between',
                            padding: '10px'
                        }}
                    >
                        <div>
                            <Typography
                                style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    color: '#353535',
                                    textTransform: 'capitalize'
                                }}
                                className={classes.name}
                            >
                                Hi {localStorage.getItem('userName')},
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    fontSize: '40px',
                                    color: '#353535'
                                }}
                                className={classes.welcome}
                            >
                                Welcome back
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <CustomInput
                                passwordfield={false}
                                placeholder={"Search"}
                                icon={require("../../assets/search.png")}
                                iconHeight={"24px"}
                                height={48}
                                borderRadius={6}
                            // onChange={(e: any) =>
                            //   this.handleInputChange("email", e)
                            // }
                            />
                            <IconButton
                                style={{
                                    border: '1px solid #bdbdbd',
                                    height: '45px',
                                    width: '45px',
                                    borderRadius: '5px',
                                    marginTop: '25px',
                                    marginLeft: '20px'
                                }}
                            >
                                <img src={require('../../assets/notification.png')} height={'25px'} />
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    getTabs = () => {
        return (
            // @ts-ignore
            <Box>
                <Grid container spacing={5}>
                    {
                        filterBtn.map((item: any, index: any) => {
                            return (
                                <Grid item md={3} sm={6} xs={12} key={index}>
                                    {/* @ts-ignore */}
                                    <Box
                                        style={{
                                            display:'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Button
                                            variant={'outlined'}
                                            fullWidth={true}
                                            style={{
                                                color: palette.primary.main,
                                                border: `1px solid ${palette.primary.main}`,
                                                textTransform: 'none',
                                                fontFamily: 'Poppins-SemiBold',
                                                fontSize: '14px',
                                                minHeight: '45px',
                                                borderRadius: '7px',
                                                background: '#fff'
                                            }}
                                            onClick={() => this.props.navigation.navigate(item.navigation)}
                                        >
                                            {item.title}
                                        </Button>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { 
            mobileOpen, 
        } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                
                <DashboardDrawer
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={this.handleDrawerToggle}
                    // @ts-ignore
                    navigation={this.props.navigation}
                />
                {/* @ts-ignore */}
                <Box
                    className={classes.appBarWrap}
                >
                    {
                        this.getAppBar(classes)
                    }
                    {/* @ts-ignore */}
                    <Box
                        style={{ 
                            padding: "0 4%", 
                            marginBottom: "10%" ,
                            marginTop: '20px'
                        }}
                    >
                        {
                            this.getTabs()
                        }
                    </Box>
                </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// export default withStyles(styles)(LetsRead);
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            logo: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            menuButton: {
                marginRight: theme.spacing(2),
                color: palette.primary.main,
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            appBar: {
                background: '#fff',
                boxShadow: 'none',
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                    background: '#fff',
                    boxShadow: 'none'
                },
            },
            appBarWrap: {
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                },
            },
            welcome: {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            name: {
                fontSize: '24px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '20px',
                    fontSize: '16px'
                },
            },
            tabsBox: {
                // flex: 1,
                // flexDirection: 'row',
                // justifyContent: 'space-between',
                width: '100%',
                borderBottom: '2px solid',
                borderBottomColor: 'rgba(0, 0, 0, 0.12)'
            },
            tabs_main: {
                "& .MuiTabs-indicator": {
                    backgroundColor: palette.primary.main,
                    borderRadius: '1.5px',
                    height: '4px',
                },
                "& .Mui-selected": {
                    color: palette.primary.main
                }
            },
            headingTabs: {
                fontSize: '24px',
                fontWeight: 600,
                fontFamily: palette.fontFamily.main,
                width: "25%",
                textTransform: 'inherit',
                // minWidth: '50% !important'
            },
            lastTableRow: {
                '&:last-child td, &:last-child th': { border: 0 }
            },
            triangle: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.06))',
                marginTop: "49px",
                marginLeft: "12px",
                borderRadius: "10px",
                '& .MuiAvatar-root': {
                    width: '32px ',
                    height: '32px',
                    marginLeft: '-0.5px ',
                    marginRight: '1px',
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: '0px',
                    right: '20px ',
                    width: '15px',
                    height: '15px ',
                    backgroundColor: '#ffffff',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            },
            root: {
                color: "#F27024 !important",
                "& .Mui-selected": {
                    background: "#F27024",
                    color: "white",
                    fontWeight: "bold",
                },
                ul: {
                    "& .MuiPaginationItem-root": {
                        color: "#F27024"
                    }
                }
            },
            modalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '496px',
                height: "200px",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 5px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            statModalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '800px',
                height: "360px",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 5px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            previewModalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '796px',
                maxHeight: "80vh",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '20px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            menuItemStyle: {
                fontFamily: 'Poppins-Light',
                '&:hover': {
                    textAlign: "right",
                    color: '#d53636',
                    backgroundColor: '#ffffff',
                }
            },


        }))(DiscoverTabs)
)
// Customizable Area End
