import React from "react";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Toolbar,
  AppBar,
  Grid,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import HelpController,{ Props } from "./HelpController";
import { palette } from "../../../../components/src/theme";
import {
  withTheme,
  withStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import CustomLoader from "../../../../components/src/CustomLoader";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';

const drawerWidth = 240;

// Customizable Area End

class Help extends HelpController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getAppBar = (classes: any) => {
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logo}>
            <img
              src={require("../../../../components/src/logo.svg")}
              height={70}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              padding: "10px"
            }}
          >
            <div>
              <Typography
                style={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "25px",
                  color: "#353535",
                  marginTop: "25px"
                }}
                className={classes.welcome}
              >
                Help
              </Typography>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  getTutorialsList = (classes: any) => {
    return (
      <>
      {
        this.state.tutorialsList?.length ?
          this.state.tutorialsList?.map((item: any, index: any) => {
            return (
              <div
                className={classes.superWrap}
              >
                <Grid container spacing={3} key={index}>
                  <Grid item xs={4} sm={4} md={3}>
                    <div
                      className={classes.videoParentWrap}
                    >
                      <div
                        className={classes.videoWrap}
                      >
                        <video 
                          width="100%"
                          style={{
                            borderRadius: '10px'
                          }}
                        >
                          <source src={item?.attributes?.video} type="video/mp4" />
                          <source src={item?.attributes?.video} type="video/ogg" />
                        </video>
                      </div>
                      <div
                        className={classes.playWrap}
                      >
                        <div
                          style={{
                            height: '50px',
                            width: '50px',
                            borderRadius: '50%',
                            background: '#fff',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                        >
                          <PlayArrowRoundedIcon 
                            style={{
                              marginTop: '8px',
                              marginLeft: '8px',
                              color: '#F27024'
                            }}
                            fontSize="large"
                            onClick={() => this.goToTutDetails(item?.id)}
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={8} sm={8} md={9}>
                    <div>
                      <Typography
                        className={classes.tutTitle}
                      >
                        {item?.attributes?.title}
                      </Typography>
                    </div>
                    <div
                      style={{
                        marginTop: '10px'
                      }}
                    >
                      <Typography
                        className={classes.tutDesc}
                      >
                        {item?.attributes?.description}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )
          })
        : <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              style={{
                fontSize: '20px',
                fontFamily: 'Poppins-SemiBold',
                color: '#353535'
              }}
            >
              No Tutorials Found!
            </Typography>
          </div>
        }
      </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { mobileOpen } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div style={{padding: '0px 17px'}}>
        {this.state.loading ? <CustomLoader open={this.state.loading} /> : null}

        <DashboardDrawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          // @ts-ignore
          navigation={this.props.navigation}
        />
        {/* @ts-ignore */}
        <Box className={classes.appBarWrap}>
          {this.getAppBar(classes)}
          {/* @ts-ignore */}
          <Box
            style={{
              padding: "20px",
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Typography
              className={classes.tutorialHead}
            >
              Tutorials
            </Typography>
          </Box>
          <div style={{paddingRight: window.innerWidth > 600 ? "121px" : ""}}>
            {
              this.getTutorialsList(classes)
            }
          </div>
        </Box>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// export default withStyles(styles)(LetsRead);
export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      input: {
        width: "100%",
        height: "100%",
        border: "1px solid rgba(82,92, 229, 0.12)",
        borderRadius: "7px",
        textDecoration: "none",
        fontSize: "1em",
        fontFamily: "Poppins-Light",
        backgroundColor: "white",
        color: "#000 !important",
        padding: "5px 1rem", //
        "& > .MuiInputBase-input": {
          height: "100%"
        },
        "&  .MuiFormHelperText-root.Mui-error": {
          fontFamily: "Poppins-Light"
        }
      },
      logo: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
          display: "none"
        }
      },
      menuButton: {
        marginRight: theme.spacing(2),
        color: palette.primary.main,
        [theme.breakpoints.up("md")]: {
          display: "none"
        }
      },
      appBar: {
        background: "#fff",
        boxShadow: "none",
        [theme.breakpoints.up("md")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
          background: "#fff",
          boxShadow: "none"
        }
      },
      appBarWrap: {
        [theme.breakpoints.up("md")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth
        }
      },
      welcome: {
        [theme.breakpoints.down("sm")]: {
          display: "none"
        }
      },
      name: {
        fontSize: "24px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "20px",
          fontSize: "16px"
        }
      },
      tutorialHead: {
        fontSize: '38px',
        color: '#353535',
        fontFamily: 'Poppins-Bold',
        [theme.breakpoints.down("sm")]: {
          fontSize: "20px",
          fontFamily: 'Poppins-SemiBold',
        }
      },
      superWrap: {
        margin:'20px',
        marginTop: '40px',
        height: '160px',
        [theme.breakpoints.down("sm")]: {
          height: 'auto',
        }
      },
      videoParentWrap: {
        position: 'relative',
        height: 'auto',
        width: '100%',
        borderRadius: '10px',
        [theme.breakpoints.down("sm")]: {
          height: 'auto',
        }
      },
      playWrap: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
        marginTop: '28%'
      },
      videoWrap: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: 'rgba(0,0,0,0.5)',
        borderRadius: '10px'
      },
      tutTitle: {
        color: '#353535',
        fontFamily: 'Poppins-SemiBold',
        fontSize: '20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        lineClamp: 1,
        boxOrient: 'vertical',
        [theme.breakpoints.down("sm")]: {
          fontSize: '16px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          lineClamp: 1,
          boxOrient: 'vertical',
        }
      },
      tutDesc: {
        color: '#353535',
        fontFamily: 'Poppins-Light',
        fontSize: '16px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        lineClamp: 2,
        boxOrient: 'vertical',
        [theme.breakpoints.down("sm")]: {
          fontSize: '12px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          lineClamp: 2,
          boxOrient: 'vertical',
        }
      },
      tabsBox: {
        // flex: 1,
        // flexDirection: 'row',
        // justifyContent: 'space-between',
        width: "100%",
        borderBottom: "2px solid",
        borderBottomColor: "rgba(0, 0, 0, 0.12)"
      },
      tabs_main: {
        "& .MuiTabs-indicator": {
          backgroundColor: palette.primary.main,
          borderRadius: "1.5px",
          height: "4px"
        },
        "& .Mui-selected": {
          color: palette.primary.main
        }
      },
      headingTabs: {
        fontSize: "24px",
        fontWeight: 600,
        fontFamily: palette.fontFamily.main,
        width: "100%",
        display: "flex",
        textTransform: "inherit"
        // minWidth: '50% !important'
      },
      lastTableRow: {
        "&:last-child td, &:last-child th": { border: 0 }
      },
      triangle: {
        overflow: "visible",
        filter: "drop-shadow(0px 1px 4px rgba(0,0,0,0.06))",
        marginTop: "49px",
        marginLeft: "12px",
        borderRadius: "10px",
        "& .MuiAvatar-root": {
          width: "32px ",
          height: "32px",
          marginLeft: "-0.5px ",
          marginRight: "1px"
        },
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: "0px",
          right: "20px ",
          width: "15px",
          height: "15px ",
          backgroundColor: "#ffffff",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0
        }
      },
      root: {
        color: "#F27024 !important",
        "& .Mui-selected": {
          background: "#F27024",
          color: "white",
          fontWeight: "bold"
        },
        ul: {
          "& .MuiPaginationItem-root": {
            color: "#F27024"
          }
        }
      },
      modalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "496px",
        height: "200px",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "24px",
        padding: "10px 5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      statModalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "800px",
        height: "360px",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "24px",
        padding: "10px 5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      previewModalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "796px",
        maxHeight: "80vh",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "24px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      menuItemStyle: {
        fontFamily: "Poppins-Light",
        "&:hover": {
          textAlign: "right",
          color: "#d53636",
          backgroundColor: "#ffffff"
        }
      }
    })
  )(Help)
);
// Customizable Area End
