import React, {useEffect, useState} from 'react';
import {
    createClient,
    createMicrophoneAudioTrack,
    createScreenVideoTrack,
    createCameraVideoTrack
} from 'agora-rtc-sdk-ng';

import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import StopScreenShareOutlinedIcon from '@material-ui/icons/StopScreenShareOutlined';
import {
    Button
} from '@material-ui/core';

import CustomLoader from './CustomLoader';
import AlertModal from './AlertModal';

const userId = localStorage.getItem('userId');

const LiveVideoStreaming = (props) => {
    const [client, setClient] = useState(null);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [screenSharing, setScreenSharing] = useState(false);
    const [isStarted, setIsStarted] = useState(false);
    const [isJoined, setIsJoined] = useState(false)
    const [cParam, setCParam] = useState({});
    const [loading, setLoading] = useState(false);
    const [isShareDisabled, setIsShareDisabled] = useState(true);
    const [error, setError] = useState('');
    const [alert, setAlert] = useState(false);
    const [hideVideoOption, setHideVideoOption] = useState(false);

    let options =
    {
        // Pass your App ID here.
        appId: '5986c8dcce634fada4a18bf10b49acf5',
        // Set the channel name.
        channel: props.channelName,
        // channel: 'testforcalendera',
        // Pass your temp token here.
        token: props.agoraToken,
        // token: props.role === 'host' ? hostToken : audToken,
        // Set the user ID.
        uid: 0,
        // Set the user role
        role: props.role
    };

    let channelParameters =
    {
        // A variable to hold a local audio track.
        localAudioTrack: null,
        // A variable to hold a local video track.
        localVideoTrack: null,
        // A variable to hold a remote audio track.
        remoteAudioTrack: null,
        // A variable to hold a remote video track.
        remoteVideoTrack: null,
        localScreenTrack: null,
        // A variable to hold the remote user id.s
        remoteUid: null,
    };

    let agoraEngine

    const startStreaming = async () => {
        try{
            agoraEngine = createClient({ codec: 'vp9', mode: 'live' });
            agoraEngine.setClientRole(options.role == 'host' && userId == props?.eventDetails?.attributes?.instructor_id ? 'host' : 'audience')
            await agoraEngine.join(options.appId, options.channel, options.token, options.uid);
            setClient(agoraEngine)
            // setLoading(true)
            // Publish the local audio and video track if the user joins as a host.
            if(options.role == 'host' && userId == props?.eventDetails?.attributes?.instructor_id)
            {
                // Create a local audio track from the audio sampled by a microphone.
                channelParameters.localAudioTrack = await createMicrophoneAudioTrack();
                // Create a local video track from the video captured by a camera.
                channelParameters.localVideoTrack = await createCameraVideoTrack();
                channelParameters.localVideoTrack.play('local_video');
                // Publish the local audio and video tracks in the channel.
                await agoraEngine.publish([channelParameters.localVideoTrack]);
                await agoraEngine.publish([channelParameters.localAudioTrack]);
                // Play the local video track.
                setCParam(channelParameters)
                setIsStarted(true)
                setLoading(false)
                setIsShareDisabled(false)
                localStorage.setItem('streamStarted', true);
                await agoraEngine.on('track-ended', handleTrackEnded);
                props.startRecording()
            }

            if(options.role === 'audience' || (options.role == 'host' && userId != props?.eventDetails?.attributes?.instructor_id)){
                agoraEngine.on('user-published', async (user, mediaType) => {

                    await agoraEngine.subscribe(user, mediaType)
                    setIsJoined(true)
                    if (mediaType === "video") {
                        // Get `RemoteVideoTrack` in the `user` object.
                        const PlayerContainer = document.createElement("div");
                        PlayerContainer.id = user.uid;
                        let remoteStreame = document.getElementById("remote-stream")
                        remoteStreame.appendChild(PlayerContainer)
                        user.videoTrack.play(`${user.uid}`);
                    }
            
                    if (mediaType === "audio") {
                        // Get `RemoteAudioTrack` in the `user` object.
                        const remoteAudioTrack = user.audioTrack;
                        // Play the audio track. Do not need to pass any DOM element
                        remoteAudioTrack.play();
                    }
                    setLoading(false)
                    setIsStarted(true)
                })
            }
        } catch(error){
            console.log('ERROR===>', error.message)
            setError(error.message)
            setAlert(true)
        }
    }

    const handleTrackEnded = async () => {
        try {
            await client.unpublish(cParam.localScreenTrack);
            if(videoEnabled){
                let lVTrack = await createCameraVideoTrack();
                let updatedObj = {
                    ...cParam,
                    'localVideoTrack': lVTrack
                }
                setCParam(updatedObj);
                await client.publish([lVTrack])
                lVTrack.play('local_video')
            }
            setScreenSharing(false);
            setHideVideoOption(false);
        } catch (error) {
            console.error('Error starting screen sharing:', error);
        }
    };

    async function handleLeave() {
        try {
          if(options.role === 'audience' || (options.role == 'host' && userId != props?.eventDetails?.attributes?.instructor_id)){
            await client.leave();
            setIsJoined(false)
          } else {
            setIsShareDisabled(true)
            props.endStream()
            if(cParam.localAudioTrack){
                cParam.localAudioTrack.close();
                cParam.localAudioTrack.stop();
                cParam.localAudioTrack = null;
                client.unpublish(cParam.localAudioTrack);
                setCParam(null)
                await client.leave();
                setIsStarted(false)
            }
            if(cParam.localVideoTrack){
                cParam.localVideoTrack.close();
                cParam.localVideoTrack.stop();
                cParam.localVideoTrack = null;
                client.unpublish(cParam.localVideoTrack);
                await client.leave();
                setCParam(null)
                setIsStarted(false)
            } 
            if(cParam.localScreenTrack){
                cParam.localScreenTrack.close();
                cParam.localScreenTrack.stop();
                cParam.localScreenTrack = null;
                client.unpublish(cParam.localScreenTrack);
                await client.leave();
                setCParam(null);
                setIsStarted(false)
            }
          }
        } catch (err) {
          console.error(err);
        }
      }

    const startScreenSharing = async () => {
        try {
            let lSTrack = await createScreenVideoTrack();
            await client.unpublish(cParam.localVideoTrack);
            cParam.localVideoTrack.stop();
            cParam.localVideoTrack.close();
            
            let updatedObj = {
                ...cParam,
                'localScreenTrack': lSTrack
            }
            setCParam(updatedObj);
            setHideVideoOption(true);
            setScreenSharing(true)
            await client.publish([lSTrack])
            lSTrack.on("track-ended", handleTrackEnded);
            
        } catch (error) {
            setHideVideoOption(false);
        }
    };


    const stopScreenSharing = async () => {
        try {
            await client.unpublish(cParam.localScreenTrack);
            cParam.localScreenTrack.stop();
            cParam.localScreenTrack.close();
            if(videoEnabled){
                let lVTrack = await createCameraVideoTrack();
                let updatedObj = {
                    ...cParam,
                    'localVideoTrack': lVTrack
                }
                setCParam(updatedObj);
                await client.publish([lVTrack])
                lVTrack.play('local_video')
            } 
            setScreenSharing(false);
            setHideVideoOption(false);
        } catch (error) {
            console.error('Error starting screen sharing:', error);
        }
    }

    useEffect(() => {
        return () => {
            let started = localStorage.getItem('streamStarted')
            if(started){
                localStorage.removeItem('streamStarted')
                handleLeave()
            } else {
                console.log("I AM UNMOUNTED")
            }
        }
    })
    
    // just to turn on/off the video
    const toggleVideo = async () => {
        if(videoEnabled){
            await client.unpublish(cParam.localVideoTrack);
            cParam.localVideoTrack.stop();
            cParam.localVideoTrack.close();
            setVideoEnabled((prev) => !prev);
        } else {
            let lVTrack = await createCameraVideoTrack();
            let updatedObj = {
                ...cParam,
                'localVideoTrack': lVTrack
            }
            setCParam(updatedObj);
            await client.publish([lVTrack])
            // cParam.localVideoTrack.setEnabled(false)
            lVTrack.play('local_video')
            setVideoEnabled((prev) => !prev);
        }
    };

    const closeAlertBox = () => {
        setAlert(false);
        setError('');
    }

    const getShareButton = () => {
        if(isShareDisabled){
            return null
        } else {
            return (
                !screenSharing ?
                    <ScreenShareOutlinedIcon 
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => startScreenSharing()}
                    />
                :   <StopScreenShareOutlinedIcon 
                        style={{
                            color: '#fff',
                            textAlign: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => stopScreenSharing()}
                    />
            )
        }
    }

    const shareText = () => {
        if(!isShareDisabled){
            return screenSharing ? 'Stop Sharing' : 'Share Screen'
        } else {
            return null
        }
    }
    
    return (
        <div>
            {
                loading? 
                    <CustomLoader
                        open={loading}
                    />
                :   null
            }
            {
                alert ?
                <AlertModal
                    open={alert}
                    data={error}
                    // @ts-ignore
                    onClose={() => closeAlertBox()}
                />
                : null
            }
        {
            props.role == 'host' && userId == props?.eventDetails?.attributes?.instructor_id ?
            <>
                <div
                    style={{
                        position: 'relative'
                    }}
                >
                    <div 
                        id="local_video" 
                        className="local_stream" 
                        style={{ 
                            width: "100%", 
                            height: "495px",
                            background: 'grey',
                            borderRadius: '8px',
                            boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
                            position: 'relative' 
                        }}
                    ></div>
                    <div
                        style={{
                            position: 'absolute',
                            background: 'rgba(0, 0, 0, 0.5)',
                            height: '50px',
                            width: '100%',
                            bottom: '0',
                            borderBottomLeftRadius: '8px',
                            borderBottomRightRadius: '8px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        {
                            hideVideoOption || isShareDisabled ?
                                null
                            :
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        minWidth: '50px',
                                        padding: '15px'
                                    }}
                                >
                                    {
                                        videoEnabled ?
                                            <VideocamOutlinedIcon
                                                style={{
                                                    color: '#fff',
                                                    textAlign: 'center',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => toggleVideo()}
                                            />
                                        :   <VideocamOffOutlinedIcon  
                                                style={{
                                                    color: '#fff',
                                                    textAlign: 'center',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => toggleVideo()}
                                            />
                                    }
                                    <span
                                        style={{
                                            color: '#fff',
                                            fontFamily: 'Poppins-Light',
                                            fontSize: '18px'
                                        }}
                                    >
                                        {videoEnabled ? 'Stop Video' : 'Start Video'}
                                    </span>
                                </div>
                        }
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                minWidth: '50px',
                                padding: '15px'
                            }}
                        >
                            {
                                getShareButton()
                            }
                            <span
                                style={{
                                    color: '#fff',
                                    fontFamily: 'Poppins-Light',
                                    fontSize: '18px'
                                }}
                            >
                                {
                                    shareText()
                                }
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                minWidth: '50px',
                                padding: '15px'
                            }}
                        >
                            {
                                isStarted ? 
                                    <Button
                                        style={{
                                            background: '#e30000',
                                            padding: '4px 8px',
                                            height: '32px',
                                            width: '85px',
                                            color: '#fff',
                                            fontSize: '12px',
                                            fontFamily: 'Poppins-SemiBold'
                                        }}
                                        onClick={() => handleLeave()}
                                    >
                                        End
                                    </Button>
                                :
                                    <Button
                                        id="join"
                                        style={{
                                            background: '#e30000',
                                            padding: '4px 8px',
                                            height: '32px',
                                            width: '85px',
                                            color: '#fff',
                                            fontSize: '12px',
                                            fontFamily: 'Poppins-SemiBold'
                                        }}
                                        onClick={() => startStreaming()}
                                    >
                                        Start
                                    </Button>
                            }
                        </div>
                    </div>
                    
                </div>
            </>
            : 

            <>
                <div
                    style={{
                        position: 'relative'
                    }}
                >
                    <div 
                        id="remote-stream" 
                        style={{ 
                            width: "100%", 
                            height: "495px",
                            background: 'grey',
                            borderRadius: '8px',
                            boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
                            position: 'relative' 
                        }}
                    ></div>
                    <div
                        style={{
                            position: 'absolute',
                            background: 'rgba(0, 0, 0, 0.5)',
                            height: '50px',
                            width: '100%',
                            bottom: '0',
                            borderBottomLeftRadius: '8px',
                            borderBottomRightRadius: '8px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        {/* <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                minWidth: '50px',
                                padding: '15px'
                            }}
                        >
                            {
                                audioEnabled ?
                                    <MicOffRoundedIcon 
                                        style={{
                                            color: '#fff',
                                            textAlign: 'center',
                                            cursor: 'pointer'
                                        }}
                                        // onClick={() => toggleAudio()}
                                    />
                                :   <MicNoneRoundedIcon 
                                        style={{
                                            color: '#fff',
                                            textAlign: 'center',
                                            cursor: 'pointer'
                                        }}
                                        // onClick={() => toggleAudio()}
                                    />
                            }
                            <span
                                style={{
                                    color: '#fff',
                                    fontFamily: 'Poppins-Light',
                                    fontSize: '18px'
                                }}
                            >
                                {audioEnabled ? 'Unmute' : 'Mute'}
                            </span>
                        </div> */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                minWidth: '50px',
                                padding: '15px'
                            }}
                        >
                            {
                                isJoined ?
                                    <Button
                                        style={{
                                            background: '#e30000',
                                            padding: '4px 8px',
                                            height: '32px',
                                            width: '85px',
                                            color: '#fff',
                                            fontSize: '12px',
                                            fontFamily: 'Poppins-SemiBold'
                                        }}
                                        onClick={() => handleLeave()}
                                    >
                                        End
                                    </Button>
                                :
                                    <Button
                                        style={{
                                            background: '#e30000',
                                            padding: '4px 8px',
                                            height: '32px',
                                            width: '85px',
                                            color: '#fff',
                                            fontSize: '12px',
                                            fontFamily: 'Poppins-SemiBold'
                                        }}
                                        onClick={() => startStreaming()}
                                    >
                                        Join
                                    </Button>
                            }
                        </div>
                    </div>
                    
                </div>
            </>
        }
    </div>
    )
}


export default LiveVideoStreaming;