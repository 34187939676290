import React from "react";

// Customizable Area Start
import { 
  Container, 
  Typography,
  Box
} from '@material-ui/core';
import {
  withTheme,
  withStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import Slider from "react-slick";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style,
        borderRadius: '50%', 
        border: '2px solid #000',
        height: '30px',
        width: '30px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center' 
      }}
      onClick={onClick}
    >
      <ArrowForwardIosRoundedIcon 
        style={{
          color: '#000'
        }}
      />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick, currentSlide } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style,
        borderRadius: '50%', 
        border: '2px solid #000',
        height: '30px',
        width: '30px',
        padding: '5px',
        display: currentSlide == 0 ? 'none' : 'flex',
        justifyContent: 'center',
        alignItems: 'center' 
      }}
      onClick={onClick}
    >
      <ArrowBackIosRoundedIcon 
        style={{
          color: '#000'
        }}
      />
    </div>
  );
}

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  adaptiveHeight: false,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        adaptiveHeight: false,
        centerMode: false,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        adaptiveHeight: false,
        centerMode: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        adaptiveHeight: false,
        centerMode: false,
      }
    }
  ]
};

const settingsPodcast = {
  dots: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  adaptiveHeight: false,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        adaptiveHeight: false,
        centerMode: false,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        adaptiveHeight: false,
        centerMode: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        adaptiveHeight: false,
        centerMode: false,
      }
    }
  ]
};
import CustomLoader from "../../../components/src/CustomLoader";
import { palette } from "../../../components/src/theme";

// Customizable Area End

import AdvancedSearchController, {
  Props,
  configJSON
} from "./AdvancedSearchController";

class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getAge = (dateString: string) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let d1 = birthDate.getDate();
    let m1 = birthDate.getMonth();
    let y1 = birthDate.getFullYear();
    let d2 = today.getDate();
    let m2 = today.getMonth();
    let y2 = today.getFullYear();
    let month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (d1 > d2) {
        d2 = d2 + month[m2 - 1];
        m2 = m2 - 1;
    }
    if (m1 > m2) {
        m2 = m2 + 12;
        y2 = y2 - 1;
    }
    const d = d2 - d1;
    const m = m2 - m1;
    const y = y2 - y1;
  
  
    if (y > 0) {
        return ('' + y + ' years ' + m + ' months ' + d + ' days');
    }
    if (y == 0 && m > 0) {
        return (+m + ' months ' + d + ' days');
    }
    if (y == 0 && m == 0) {
        return (+d + ' days');
    }
  }
  getTopic = () => {
    return(
      <div
        className={this.props.classes.topicWrap}
      >
        <Container>
          <div
            className={this.props.classes.topicContent}
          >
            <Typography
              className={this.props.classes.topicHead}
            >
              Topic
            </Typography>
            <Typography
              className={this.props.classes.topic}
            >
              {this.state.searchTopic}
            </Typography>
          </div>
        </Container>
      </div>
    )
  }

  getCourse = (courseList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleHead}
        >
          Courses
        </Typography>
        <div
          style={{
            marginTop: '40px'
          }}
        >
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settings}>
            {
              courseList.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.articleCardWrap}
                    onClick={() => this.props.navigation.navigate('CourseDetails', {id:item.id})}
                  >
                    <div
                      className={classes.videoImgWrap}
                    >
                      <img 
                        src={item.attributes.background_image}
                        className={classes.videoImg}
                      />
                      <div
                        className={classes.podcastContentWrap}
                      >
                        <div
                          className={classes.podcastBtnWrap}
                        >
                          <PlayArrowRoundedIcon 
                            style={{
                              color: palette.primary.main,
                              fontSize: 30,
                              cursor: 'pointer'
                            }}
                            onClick={() => this.props.navigation.navigate('CourseDetails', {id:item.id})}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '10px'
                      }}
                    >
                      <Typography
                        className={classes.articleTitle}
                      >
                        {item.attributes.title}
                      </Typography>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.articleDisc}
                      >
                        {
                          item.attributes.about
                        } 
                      </Typography>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
        </div>
      </div>
    )
  }

  getVideos = (videosList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleHead}
        >
          Videos
        </Typography>
        <div
          style={{
            marginTop: '40px'
          }}
        >
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settings}>
            {
              videosList.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.articleCardWrap}
                    onClick={() => this.props.navigation.navigate('WatchVideo', {id:item.id})}
                  >
                    <div
                      className={classes.videoImgWrap}
                    >
                      <img 
                        src={item.attributes.poster}
                        className={classes.videoImg}
                      />
                      <div
                        className={classes.podcastContentWrap}
                      >
                        <div
                          className={classes.podcastBtnWrap}
                        >
                          <PlayArrowRoundedIcon 
                            style={{
                              color: palette.primary.main,
                              fontSize: 30,
                              cursor: 'pointer'
                            }}
                            onClick={() => this.props.navigation.navigate('WatchVideo', {id:item.id})}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: '10px'
                      }}
                    >
                      <Typography
                        className={classes.articleTitle}
                      >
                        {item.attributes.title}
                      </Typography>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.articleDisc}
                      >
                        {
                          item.attributes.about
                        } 
                      </Typography>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
        </div>
      </div>
    )
  }

  getBooks = (bookList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleSubHead}
        >
          Books
        </Typography>
        <div style={{marginTop: '40px'}}>
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settings}>
            {
              bookList.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.articleCardWrap}
                  >
                    <a
                      href={item.attributes.url}
                      target="_blank"
                    >
                      <img 
                        src={item.attributes.image}
                        className={classes.articleImg}
                        alt="Article Image"
                      />
                    </a>
                    <div
                      style={{
                        padding: '10px'
                      }}
                    >
                      <a
                        href={item.attributes.url}
                        target="_blank"
                        className={classes.articleTitle}
                        style={{
                          textDecoration: 'none'
                        }}
                      >
                        {item.attributes.title}
                      </a>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.articleDisc}
                      >
                        {
                          item.attributes.about
                        } 
                      </Typography>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
          </div>
      </div>
    )
  }

  getFilms = (filmList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleSubHead}
        >
          Films
        </Typography>
        <div style={{marginTop: '40px'}}>
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settings}>
            {
              filmList.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.articleCardWrap}
                  >
                    <a
                      href={item.attributes.url}
                      target="_blank"
                    >
                      <img 
                        src={item.attributes.image}
                        className={classes.articleImg}
                        alt="Article Image"
                      />
                    </a>
                    <div
                      style={{
                        padding: '10px'
                      }}
                    >
                      <a
                        className={classes.articleTitle}
                        style={{
                          textDecoration: 'none'
                        }}
                        href={item.attributes.url}
                        target="_blank"
                      >
                        {item.attributes.name}
                      </a>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.articleDisc}
                      >
                        {
                          item.attributes.about
                        } 
                      </Typography>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
          </div>
      </div>
    )
  }

  getPeople = (peopleList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleSubHead}
        >
          Peoples
        </Typography>
        <div style={{marginTop: '40px'}}>
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settings}>
            {
              peopleList.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.articleCardWrap}
                    onClick={() => this.props.navigation.navigate('CustomisableUserProfiles', {id:item.attributes.id})}
                  >
                    <div>
                      <img 
                        src={item.attributes.image}
                        className={classes.articleImg}
                        alt="Article Image"
                      />
                    </div>
                    <div
                      style={{
                        padding: '10px'
                      }}
                    >
                      <Typography
                        className={classes.articleTitle}
                      >
                        {item.attributes.name}
                      </Typography>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.articleDisc}
                      >
                        {
                          item.attributes.about
                        } 
                      </Typography>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
          </div>
      </div>
    )
  }

  getOrg = (orgList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleSubHead}
        >
          Organizations
        </Typography>
        <div style={{marginTop: '40px'}}>
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settings}>
            {
              orgList.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className={classes.articleCardWrap}
                  >
                    <a
                      href={item.attributes.website_url}
                      target='_blank'
                    >
                      <img 
                        src={item.attributes.image}
                        className={classes.articleImg}
                        alt="Article Image"
                      />
                    </a>
                    <div
                      style={{
                        padding: '10px'
                      }}
                    >
                      <a
                        href={item.attributes.website_url}
                        target='_blank'
                        className={classes.articleTitle}
                        style={{
                          textDecoration: 'none'
                        }}
                      >
                        {item.attributes.name}
                      </a>
                    </div>
                    <div
                      style={{
                        padding: '10px',
                        paddingTop: '0px'
                      }}
                    >
                      <Typography
                        className={classes.articleDisc}
                      >
                        {
                          item.attributes.about
                        } 
                      </Typography>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
          </div>
      </div>
    )
  }

  getDiscover = (discoverList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleHead}
        >
          Discover
        </Typography>
        <div style={{marginTop: '40px'}}>
          {
            discoverList.books.length ?
              this.getBooks(discoverList.books, classes)
            : null
          }
        </div>
        <div>
          {
            discoverList.films.length ?
              this.getFilms(discoverList.films, classes)
            : null
          }
        </div>
        <div>
          {
            discoverList.people.length ?
              this.getPeople(discoverList.people, classes)
            : null
          }
        </div>
        <div>
          {
            discoverList.organisations.length ?
              this.getOrg(discoverList.organisations, classes)
            : null
          }
        </div>
      </div>
    )
  }

  getArticles = (blogList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleHead}
        >
          Articles
        </Typography>
        <div style={{marginTop: '40px'}}>
          {/* @ts-ignore */}
        <Slider style={{height: '348px', marginTop: '40px'}} {...settings}>
          {
            blogList.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className={classes.articleCardWrap}
                  onClick={() => this.props.navigation.navigate('BlogArticle', {id:item.attributes.id})}
                >
                  <div>
                    <img 
                      src={item.attributes.cover_image}
                      className={classes.articleImg}
                      alt="Article Image"
                    />
                  </div>
                  <div
                    style={{
                      padding: '10px'
                    }}
                  >
                    <Typography
                      className={classes.articleTitle}
                    >
                      {item.attributes.title}
                    </Typography>
                  </div>
                  <div
                    style={{
                      paddingLeft: '10px',
                      paddingRight: '10px'
                    }}
                  >
                    <Typography
                      className={classes.articleCreated}
                    >
                      {this.getAge(item.attributes.created_at) } ago by { item.attributes.instructor_name}
                    </Typography>
                  </div>
                  <div
                    style={{
                      padding: '10px',
                      paddingTop: '0px'
                    }}
                  >
                    <Typography
                      className={classes.articleDisc}
                    >
                      {
                        item.attributes.subtitle
                      } 
                    </Typography>
                  </div>
                </div>
              )
            })
          }
        </Slider>
        </div>
      </div>
    )
  }

  getPodcasts = (podcastsList: any, classes: any) => {
    return (
      <div>
        <Typography
          className={classes.moduleHead}
        >
          Podcasts
        </Typography>
        <div style={{marginTop: '40px'}}>
          {/* @ts-ignore */}
          <Slider style={{height: '348px', marginTop: '40px'}} {...settingsPodcast}>
            {
              podcastsList.map((item: any, index: any) => {
                return (
                  <div
                    style={{
                      position: 'relative'
                    }}
                  >
                    <div
                      className={classes.podcastCard}
                    >
                      <img 
                        src={item.attributes.poster}
                        className={classes.podcastCover}
                      />
                      <div
                        className={classes.podcastOverlay}
                      >

                      </div>
                      <div
                        className={classes.podcastContentWrap}
                      >
                        <div
                          className={classes.podcastBtnWrap}
                        >
                          <PlayArrowRoundedIcon 
                            style={{
                              color: palette.primary.main,
                              fontSize: 30,
                              cursor: 'pointer'
                            }}
                            onClick={() => this.getPodcast(item.id, item.type)}
                          />
                        </div>
                      </div>
                      <div
                        className={classes.podcastTitleWrap}
                      >
                        <Typography
                            className={classes.podcastTitle}
                            title={item.attributes.title}
                        >
                            {item.attributes.title.length > 70 ? item.attributes.title.substring(0, 70) + "..." : item.attributes.title}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </Slider>
        </div>
      </div>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <div>
          {
            this.state.loading ?
              <CustomLoader
                open={this.state.loading}
              />
            : null
          }
          {
            this.getTopic()
          }
          <Container>
            <div
              className={this.props.classes.moduleWrap}
            >
              {
                this.state.searchList?.courses?.length || this.state.searchList?.videos?.length ||
                this.state.searchList?.blogs?.length  || this.state.searchList?.podcasts?.length ||
                this.state.searchList?.lets_discover?.books?.length || 
                this.state.searchList?.lets_discover?.films?.length ||
                this.state.searchList?.lets_discover?.organisations?.length ||
                this.state.searchList?.lets_discover?.people?.length ?
                null
                : <span
                    style={{
                      color: '#000',
                      fontFamily: 'Poppins-SemiBold',
                      fontSize: '24px',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    No search found!
                  </span>
              }
              {
                this.state.searchList?.courses?.length ?
                  this.getCourse(this.state.searchList?.courses, this.props.classes)
                : null
              }
            </div>
            <div
              className={this.props.classes.moduleWrap}
            >
              {
                this.state.searchList?.videos?.length ?
                  this.getVideos(this.state.searchList?.videos, this.props.classes)
                : null
              }
            </div>
            <div
              className={this.props.classes.moduleWrap}
            >
              {
                this.state.searchList?.blogs?.length ?
                  this.getArticles(this.state.searchList?.blogs, this.props.classes)
                : null
              }
            </div>
            <div
              className={this.props.classes.moduleWrap}
            >
              {
                this.state.searchList?.podcasts?.length ?
                  this.getPodcasts(this.state.searchList?.podcasts, this.props.classes)
                : null
              }
            </div>
            <div>
              {
                this.state.searchList?.lets_discover?.books?.length || 
                this.state.searchList?.lets_discover?.films?.length ||
                this.state.searchList?.lets_discover?.organisations?.length ||
                this.state.searchList?.lets_discover?.people?.length ?
                  this.getDiscover(this.state.searchList?.lets_discover, this.props.classes)
                : null
              }
            </div>
          </Container>
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      topicWrap: {
        marginTop: '60px',
        backgroundColor: '#ffbe63',
        padding: '30px'
      },
      topicContent: {
        display: 'flex',
        flexDirection: 'column'
      },
      topicHead: {
        color: '#e35400',
        fontSize: '20px',
        fontFamily: 'Poppins-SemiBold'
      },
      topic: {
        color: '#353535',
        fontSize: '48px',
        fontFamily: 'Poppins-SemiBold',
        textTransform: 'capitalize'
      },
      moduleWrap: {
        marginTop: '30px',
        marginBottom: '30px'
      },
      moduleHead: {
        color: '#1c1c1c',
        fontSize: '28px',
        fontFamily: 'Poppins-SemiBold'
      },
      moduleSubHead: {
        color: '#1c1c1c',
        fontSize: '20px',
        fontFamily: 'Poppins-SemiBold'
      },
      articleCardWrap: {
        borderRadius: "10px",
        boxShadow: '0 2px 16px 0 #e6e8f0',
        minHeight: '348px',
        maxHeight: '348px',
        minWidth: '348px',
        maxWidth: '348px',
        cursor: 'pointer'
      },
      articleImg: {
        minWidth: '348px',
        maxWidth: '348px',
        minHeight: '150px',
        maxHeight: '150px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px'
      },
      articleTitle: {
        color: '#353535',
        fontSize: '18px',
        fontFamily: 'Poppins-SemiBold',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      articleCreated: {
        fontSize: '12px',
        fontFamily: 'Poppins-Light',
        color: 'grey'
      },
      articleDisc: {
        fontSize: '12px',
        fontFamily: 'Poppins-Light',
        color: '#000000',
        display: '-webkit-box',
        lineClamp: 5,
        boxOrient: 'vertical',  
        overflow: 'hidden',
      },
      podcastCard: {
        position: 'relative',
        overflow: 'hidden',
        maxHeight: '330px',
        borderRadius: '8px',
        minWidth: '254px',
        maxWidth: '254px'
      },
      podcastCover: {
        borderRadius: '8px',
        minHeight: '330px'
      },
      podcastOverlay: {
        borderRadius: '8px',
        minHeight: '50vh',
        // maxHeight: '50vh',
        background: 'rgba(0,0,0,0.5)',
        position: 'absolute',
        zIndex: 99,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      podcastContentWrap: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: 99999,
        transform: 'translate(-50%, -50%)'
      },
      podcastBtnWrap: {
        height: '50px',
        width: '50px',
        borderRadius: '50%',
        background: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      podcastTitleWrap: {
        position: 'absolute',
        zIndex: 9999,
        bottom: '15px',
        padding: '20px',
      },
      podcastTitle: {
        fontFamily: 'Poppins-Light',
        color: '#ffffff',
        fontSize: '1em'
      },
      videoImgWrap: {
        minWidth: '348px',
        maxWidth: '348px',
        minHeight: '196px',
        maxHeight: '196px',
        overflow: 'hidden',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        position: 'relative'
      },
      videoImg: {
        minWidth: '348px',
        maxWidth: '348px',
        minHeight: '196px',
        maxHeight: '196px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px'
      }
    })
  )(AdvancedSearch)
);
// Customizable Area End
