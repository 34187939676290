import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
    classes: any;
    discussions: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  lessonData: any;
  videoPlaying: boolean;
  tabs: any;
  seeking: boolean;
  light: boolean;
  loop:boolean;
  playbackRate: any;
  volume:any;
  muted: boolean;
  pip:boolean;
  played:any;
  hideBtn: boolean;
  quality: any;
  allDuration: any;
  openShare: boolean;
  timeDisplayFormat: any;
  lessonDiscussionText:any;
  lessonID:any;
  errorData: any;
  openAlert: boolean;
  selectedTimeStamp: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class singleLessonController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getLessonApiID : string = ""
  GetAllCoursesApiId : string = ""
  GetSingleCoursesApiId : string = ""
  apiPostReviewId : string = ""
  setMarskAsDoneId : string = ""
  updateSeenuptoApiID : string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      lessonData:[],
      videoPlaying: false,
      tabs: 0,
      seeking: false,
      light: false,
      loop: false,
      playbackRate: 1,
      volume:1,
      muted: false,
      pip: false,
      played: 0,
      hideBtn: false,
      quality: 480,
      allDuration: 0,
      openShare: false,
      timeDisplayFormat: 'normal',
      lessonDiscussionText: '',
      lessonID:'',
      errorData: '',
      openAlert: false,
      selectedTimeStamp: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }



  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );



      if (apiRequestCallId != null) {
        // get single lessons 

        if (apiRequestCallId === this.getLessonApiID) {
            if(responseJson && !responseJson.error){
              if(!responseJson.data){
               
              } else {
                this.setState({
                  lessonData: responseJson.data,
                }, () => {
                  this.setState({
                    lessonData: responseJson.data,
                  }, () => {
                    if(responseJson.data?.attributes?.student_lecture?.seen_upto){
                      // @ts-ignore
                      this.playerRef.current.seekTo(responseJson.data?.attributes?.student_lecture?.seen_upto)
                    }
                  })
                })
              }
            }
          }


          // catch discussion submit by user
          
          if (apiRequestCallId === this.apiPostReviewId) {
            if(responseJson && !responseJson.error){
              if(!responseJson.data){
               console.log(responseJson.error)
              } else {
                const path: any = window.location.href.split('/', 5).pop()
                this.getLessonsData(path)
                this.setState({lessonDiscussionText: ''})
              }
            }
          }

          if (apiRequestCallId === this.setMarskAsDoneId) {
            if(responseJson && !responseJson.error){
              if(!responseJson.message){
               console.log(responseJson.error)
              } else {
              //  this.showAlert("message", "Marked as complete") 
              this.setState({
                openAlert: true,
                errorData: "Yay! Lesson completed."
              }, () => {
                const path: any = window.location.href.split('/', 5).pop()
                this.getLessonsData(path)
              })
              }
            }
          }
      }

       // Customizable Area End

    }
}

updateSeenUpto = async(currentTime: any, pathId: any) => {
  console.log("FOR CHECKING CURRENT TIME", currentTime)
  // const pathId = window.location.href.split('/', 5).pop()
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem('token')
  };
  const data = {
    "lecture_id": pathId,
    "seen_upto": currentTime
  }

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateSeenuptoApiID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_library2/courses/update_seen_upto`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(data)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'PUT'
  );

  runEngine.sendMessage(requestMessage.id, requestMessage)
}

getLessonsData= async(id:any) => {
    // let data = await getStorageData('singleLesson', true)
    // console.log(data)
    // this.setState({lessonData: data})
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem('token')
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getLessonApiID = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_library2/lectures/${id}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage)
}

submitDiscussion = (lesson_id:any) => {
    console.log(lesson_id,this.state.lessonDiscussionText)
    let userName = localStorage.getItem("userName")
    let userEmail = localStorage.getItem("userEmail") 
    const header = {
      token: localStorage.getItem("token")
    };

    const formData = new FormData();
    formData.append("review[lecture_id]", lesson_id);
    formData.append("review[text]", this.state.lessonDiscussionText);
    formData.append("review[full_name]", userName ? userName : '');
    formData.append("review[email]", userEmail ? userEmail: '');
    formData.append("review[type]", "lecture");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostReviewId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postReviewUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


markAsComplete = (lesson_id: any) => {
const header = {
  token: localStorage.getItem("token")
};

let dataToSend = {
  lecture_id: lesson_id,
  status: "complete"
}

const formData = new FormData();
formData.append("lecture_id", lesson_id);
formData.append("status", 'complete');

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.setMarskAsDoneId = requestMessage.messageId;
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  '/bx_block_library2/courses/mark_lecture_as_complete'
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  formData
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  'PUT'
);

runEngine.sendMessage(requestMessage.id, requestMessage);

}

handleTabsChange = (e: any, value:any) =>{
    this.setState({tabs: value})
}

navigationToPdf = (url:any) => {
    console.log(url)
    window.open(url);
}

donate = () => {
  this.props.navigation.navigate('DonationPayments')
}

// Customizable Area End
  
}