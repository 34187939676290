import React from "react";

// Customizable Area Start
import { 
  Container, 
  Box, 
  Grid,
  Typography,
  Button
} from '@material-ui/core';
import { createTheme, withStyles } from '@material-ui/core/styles';
import { playerBg } from "./assets";
import { audio } from "./assets";
import ReactPlayer from 'react-player';
import screenfull from "screenfull";
import ShareModal from "../../../components/src/ShareModal";
import { Link } from "react-router-dom";
// Customizable Area End

import VideoManagementController, {
  Props,
} from "./VideoManagementController";
import Breadcrumb from "../../../components/src/BreadCrumb";
import CustomCard from "../../../components/src/CustomCard";
import { palette } from "../../../components/src/theme";
import CustomButton from "../../../components/src/CustomButton";
import PlayerControlls from "../../../components/src/PlayerControlls";

const styles = (theme: any) => ({
  otherPodcastHead: {
    fontSize: '30px',
    fontFamily: 'Poppins-SemiBold',
    [theme.breakpoints.down("md")]: {
      fontSize: '24px',
      fontFamily: 'Poppins-SemiBold',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '18px',
      fontFamily: 'Poppins-SemiBold',
    },
  },
  otherPodcastCardHead: {
    fontSize: '20px',
    fontFamily: 'Poppins-Light',
    color: '#ffffff',
    [theme.breakpoints.down("sm")]: {
      fontSize: '16px',
      fontFamily: 'Poppins-Light',
      color: '#ffffff',
    },
  },
  otherPodcastCardDesc: {
    fontSize: '14px',
    fontFamily: 'Poppins-Light',
    color: '#ffffff',
    [theme.breakpoints.down("sm")]: {
      fontSize: '12px',
      fontFamily: 'Poppins-Light',
      color: '#ffffff',
    },
  }
});

let count = 0;

const format = (seconds: any) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

class VideoManagement extends VideoManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // @ts-ignore
    this.window = window;
    // @ts-ignore
    this.playerRef = React.createRef();
    // @ts-ignore
    this.controlsRef = React.createRef();
    // @ts-ignore
    this.playerContainerRef = React.createRef();
    // @ts-ignore
    this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

    // @ts-ignore
    this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";
    
    // @ts-ignore
    this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
    // @ts-ignore
    this.totalDuration = format(this.duration);
    // Customizable Area End
  }

  // Customizable Area Start

  handlePlayPause = () => { 
    this.setState({ ...this.state, videoPlaying: !this.state.videoPlaying });
  };

  handleRewind = () => {
    // @ts-ignore
    this.playerRef?.current?.seekTo(this.playerRef.current.getCurrentTime() - 10);
  };

  handleForward = () => {
    // @ts-ignore
    this.playerRef?.current?.seekTo(this.playerRef.current.getCurrentTime() + 10);
  };

  handleSeekChange = (e: any, newValue: any) => {
    // @ts-ignore
    this.setState({ ...this.state, played: parseFloat(newValue / 100) });
  };

  handleSeekMouseDown = (e: any) => {
    this.setState({ ...this.state, seeking: true });
  };

  handleSeekMouseUp = (e: any, newValue: any) => {
    this.setState({ ...this.state, seeking: false });
    // @ts-ignore
    this.playerRef?.current?.seekTo(newValue / 100, "fraction");
  };

  handleDuration = (duration: any) => {
    this.setState({ ...this.state, allDuration: duration });
  };

  toggleFullScreen = () => {
    this.setState({hideBtn: !this.state.hideBtn})
    // @ts-ignore
    screenfull.toggle(this.playerContainerRef.current);
  };

  onEscape = () => {
    this.setState({hideBtn: false})
  }

  handleProgress = (changeState: any) => {
    // @ts-ignore
    this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

    // @ts-ignore
    this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";
    
    // @ts-ignore
    this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
    // @ts-ignore
    this.totalDuration = format(this.duration);
    if (count > 3) {
      // @ts-ignore
      this.controlsRef.current.style.visibility = "hidden";
      count = 0;
    }
    // @ts-ignore
    if (this.controlsRef?.current?.style?.visibility == "visible") {
      count += 1;
    }
    if (!this.state.seeking) {
      this.setState({ ...this.state, ...changeState });
    }
  };

  handleOnEnded = () => {
    this.setState({...this.state, videoPlaying: false, played: 0});
  }

  handlePip = () => {
    this.setState({...this.state, pip: !this.state.pip, hideBtn: false})
  }

  handleDisplayFormat = () => {
    this.setState({timeDisplayFormat : this.state.timeDisplayFormat == " normal" ? 'remaining' : 'normal'})
  };

  handleVolumeSeekDown = (e: any, newValue: any) => {
    // @ts-ignore
    this.setState({ ...this.state, seeking: false, volume: parseFloat(newValue / 100) });
  };
  handleVolumeChange = (e: any, newValue: any) => {
    this.setState({
      ...this.state,
      // @ts-ignore
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  closeShare = () => {
    this.setState({
      openShare: false
    })
  }

  shareOpen = () => {
    this.setState({
      openShare: true
    })
  }

  handleMute = () => {
    this.setState({ ...this.state, muted: !this.state.muted });
  };

  handlePlaybackRate = (rate: any) => {
    this.setState({ ...this.state, playbackRate: rate });
  };

  onChangeBitrate = (quality: any) => {
    // @ts-ignore
    const internalPlayer = this.playerRef.current?.getInternalPlayer();
    if (internalPlayer) {
        // currentLevel expect to receive an index of the levels array
        internalPlayer.currentLevel = quality;
        this.setState({ ...this.state, quality: quality });
    }
  }
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { videoDetails, matches } = this.state;
    return (
      // Customizable Area Start
      <>
      <ShareModal 
        shareClose={this.closeShare}
        openShare={this.state.openShare}
        shareTitle={videoDetails.title}
        shareLink={window.location.href}
        shareDesc={videoDetails.description}
      />
      {/* @ts-ignore */}
      <Box>
        {/* @ts-ignore */}
        <Box
          style={{
            backgroundImage: `url(${playerBg})`,
            minHeight: '400px' ,
            backgroundSize: 'cover'
          }}
        >
          {/* @ts-ignore */}
          <Box
            style={{
              background: 'rgba(129, 212, 250, 0.7)',
              minHeight: '100%',
              minWidth: '100%',
              paddingTop: '20px',
              paddingBottom: '20px'
            }}
          >
            <Container>
              <Breadcrumb
                base={'Home'}
                url1={{
                  base: `Let's Talk`,
                  link: 'podcast'
                }}
                url2={{
                  path: videoDetails.title && videoDetails?.title.length > 30 ? videoDetails?.title.substring(0, 30) + "..." :  videoDetails?.title,
                  link: ''
                }}
              />
              {/* @ts-ignore */}
              <Box 
                mt={5}
                p={matches ? 10 : 2}
                pb={matches ? 10 : 4}
              >
                <Container>
                  <Grid container spacing={5}>
                    <Grid item md={4} sm={12} xs={12}>
                      <CustomCard
                        padding="0px"
                        boxShadow="0 0 27px 1px rgba(0, 0, 0, 0.32)"
                        style={{
                          maxHeight: '50vh',
                          overFlow: 'hidden',
                          minHeight: '50vh'
                        }}
                      >
                        {
                          videoDetails.poster ?
                          <img 
                            width={'100%'} 
                            style={{
                              minWidth: '100%',
                              minHeight: '50vh'
                            }}
                            src={videoDetails.poster} 
                          />
                          : <img 
                              width={'100%'} 
                              src={audio} 
                              style={{
                                minWidth: '100%',
                                minHeight: '50vh'
                              }}
                            />
                        }
                      </CustomCard>
                    </Grid>
                    <Grid item md={8} sm={12} xs={12}>
                      {/* @ts-ignore */}
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                        mt={matches ? 5 : 1}
                      >
                        {/* @ts-ignore */}
                        <Box>
                          <Typography
                            style={{
                              color: palette.primary.main,
                              fontFamily: 'Poppins-SemiBold',
                            }}
                          >
                            {videoDetails.category?.name}
                          </Typography>
                        </Box>
                        {/* @ts-ignore */}
                        <Box>
                          <Typography
                            style={{
                              fontFamily: 'Poppins-SemiBold',
                              fontSize: '36px'
                            }}
                            title={videoDetails?.title}
                          >
                            {videoDetails.title && videoDetails?.title.length > 30 ? videoDetails?.title.substring(0, 30) + "..." :  videoDetails?.title }
                          </Typography>
                        </Box>
                        {/* @ts-ignore */}
                        <Box
                          style={{
                            display: 'flex'
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: 'Poppins-Light',
                              fontSize: '12px'
                            }}
                          >
                            In this conversation
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Poppins-SemiBold',
                              fontSize: '14px',
                              marginLeft: '5px',
                              color: palette.primary.main
                            }}
                          >
                            {videoDetails.instructor?.name}
                            {
                              videoDetails?.tag_others?.data.length ? videoDetails?.tag_others?.data.map((item: any) => {
                                return (
                                  <span>, {item.attributes.full_name}</span>
                                )
                              })
                              : null
                            }
                          </Typography>
                        </Box>
                        {/* @ts-ignore */}
                        <Box
                          style={{
                            display: 'flex'
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: 'Poppins-Light',
                              fontSize: '12px'
                            }}
                          >
                            {/* @ts-ignore */}
                            {this.totalDuration} minutes
                          </Typography>
                        </Box>
                        {/* @ts-ignore */}
                        <Box
                          style={{
                            display: 'flex',
                          }}
                          mt={2}
                        >
                          {/* @ts-ignore */}
                          <Box
                            mt={1}
                          >
                            <CustomButton
                              title={this.state.videoPlaying ? 'Pause' : 'Play'}
                              color={palette.primary.main}
                              textColor={"#FFFFFF"}
                              pl={'40px'}
                              pr={'40px'}
                              height={42}
                              // @ts-ignore
                              onClick={this.handlePlayPause}
                            />
                          </Box>
                          {/* @ts-ignore */}
                          <Box
                            ml={2}
                            onClick={this.shareOpen}
                            style={{
                              marginTop: '3px',
                              cursor: 'pointer'
                            }}
                          >
                            <img src={require('../assets/share.png')} width={52} />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Container>
          </Box>
        </Box>
      </Box>
      {/* @ts-ignore */}
      <Box>
        {/* <Container>
          <Container>
            <Container> */}
              {/* @ts-ignore */}
              <Box
                style={{
                  paddingLeft: !matches ? '0px' : '200px',
                  paddingRight: !matches ? '0px' : '200px',
                  paddingTop: '0px'
                }}
              >
                <Container>
                  {/* @ts-ignore */}
                  <Box
                    component={'div'}
                    style={{
                      borderRadius: '10px',
                      overflow: 'hidden',
                      boxShadow: '0 0 7px 1px rgba(0, 0, 0, 0.22)',
                      marginTop: '-30px',
                      position: 'relative',
                      width: '100%',
                      height: !matches ? '100%' : '80%',
                      maxHeight: !matches ? '100%' : '80%',
                      background: '#000'
                    }}
                    // @ts-ignore
                    ref={this.playerContainerRef}
                  >
                    {/* @ts-ignore */}
                    <ReactPlayer 
                      url={
                        videoDetails.video ? videoDetails?.video : "https://minio.b64156.dev.eastus.az.svc.builder.cafe/sbucket/twimtyaj4fwz64voegthw9n8lbs8"
                      }
                      playing={this.state.videoPlaying}
                      width={'100%'}
                      height={'100%'}
                      style={{
                        minWidth: '100%',
                        minHeight: '100%'
                      }}
                      // @ts-ignore
                      ref={this.playerRef}
                      onProgress={this.handleProgress}
                      controls={false}
                      onEnded={this.handleOnEnded}
                      light={this.state.light}
                      loop={this.state.loop}
                      playbackRate={this.state.playbackRate}
                      volume={this.state.volume}
                      muted={this.state.muted}
                      pip={this.state.pip}
                    />
                    <PlayerControlls 
                    // @ts-ignore
                      ref={this.controlsRef}
                      playPause={this.handlePlayPause}
                      state={this.state}
                      rewind={this.handleRewind}
                      forward={this.handleForward}
                      onSeek={this.handleSeekChange}
                      onSeekMouseDown={this.handleSeekMouseDown}
                      onSeekMouseUp={this.handleSeekMouseUp}
                      onDuration={this.handleDuration}
                      played={this.state.played}
                      onToggleFullScreen={this.toggleFullScreen}
                      // @ts-ignore
                      elapsedTime={this.elapsedTime}
                      hideBtn={this.state.hideBtn}
                      // @ts-ignore
                      totalDuration={this.totalDuration}
                      onMute={this.handleMute}
                      muted={this.state.muted}
                      volume={this.state.volume}
                      pip={this.handlePip}
                      onVolumeChange={this.handleVolumeChange}
                      onVolumeSeekDown={this.handleVolumeSeekDown}
                      onChangeDisplayFormat={this.handleDisplayFormat}
                      onPlaybackRateChange={this.handlePlaybackRate}
                      playbackRate={this.state.playbackRate}
                      quality={this.state.quality}
                      onChangeQuality={this.onChangeBitrate}
                      onEscape={this.onEscape}
                      pipIcon={true}
                      forwardSeekIcon={true}
                      backwardSeekIcon={true}
                      volumeIcon={true}
                      settingsIcon={true}
                      ccIcon={true}
                      timeDuration={true}
                    />
                  </Box>
                </Container>
              </Box>
            {/* </Container>
          </Container>
        </Container> */}
      </Box>
      {/* @ts-ignore */}
      <Box
        mt={5}
      >
        <Container>
          {/* @ts-ignore */}
          <Box
            mt={5}
          >
            <Typography
              style={{
                color: '#000',
                fontSize: '24px',
                fontFamily: 'Poppins-Light'
              }}
            >
              In this conversation with
            </Typography>
          </Box>
          {/* @ts-ignore */}
          <Box
            mt={1}
          >
            <Typography
              style={{
                color: '#000',
                fontSize: '16px',
                fontFamily: 'Poppins-Light'
              }}
            >
              {videoDetails.description ? videoDetails.description : "Dummy Description"}
            </Typography>
          </Box>
        </Container>
        {
          this.state.otherPodcast.length ?
            <>
            {/* @ts-ignore */}
              <Box
                mt={8}
                style={{
                  position: 'relative'
                }}
              >
                {/* @ts-ignore */}
                <Box
                  style={{
                    background: '#FFFBE6',
                    minHeight: '200px',
                    height: '200px',
                    position: 'absolute',
                    minWidth: '100%'
                  }}
                >

                </Box>
              </Box>
            <Container>
              {/* @ts-ignore */}
              <Box 
                mb={8}
                style={{
                  position: 'relative'
                }}
              >
                {/* @ts-ignore */}
                <Box pt={6}>
                    {/* @ts-ignore */}
                      <Box
                          style={{
                              display: 'flex',
                              // flex: 1,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                          }}
                      >
                        {/* @ts-ignore */}
                          <Box>
                              <Typography
                                  className={classes.otherPodcastHead}
                              >
                                  Other podcasts you may like
                              </Typography>
                          </Box>
                          {/* @ts-ignore */}
                          <Box>
                            <Button
                                style={{
                                    color: palette.primary.main,
                                    fontFamily: 'Poppins-SemiBold',
                                }}
                            >
                                <Link
                                    style={{
                                        textDecoration: 'none',
                                        color: palette.primary.main,
                                        fontFamily: 'Poppins-SemiBold',
                                    }} 
                                    to={'/videopodcasts'}
                                >
                                    See more
                                </Link>
                            </Button>
                        </Box>
                      </Box>
                    </Box>
                    {/* @ts-ignore */}
                    <Box mt={3}>
                      <Grid container spacing={5}>
                        {
                          this.state.otherPodcast.slice(0, 4).map((item: any, index: any) => {
                            return (
                              <Grid key={index} item xs={12} sm={6} md={3}>
                              {/* @ts-ignore */}
                                <Box
                                  style={{
                                      cursor: 'pointer',
                                      position: 'relative'
                                  }}
                                  onClick={() => this.getVideo(item.id)}
                                >
                                  <img width={'100%'} height={'100%'} src={item.attributes.poster} style={{borderRadius: '8px', position: 'relative'}} />
                                  {/* @ts-ignore */}
                                  <Box
                                      style={{
                                          position: 'absolute',
                                          zIndex: 9999,
                                          bottom: '15px',
                                          padding: '20px',
                                      }}
                                  >
                                      <Typography
                                          className={classes.otherPodcastCardHead}
                                      >
                                          {item.attributes.title}
                                      </Typography>
                                      <Typography
                                          className={classes.otherPodcastCardDesc}
                                      >
                                          {item.attributes.summary}
                                      </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            )
                          })
                        }
                        </Grid>
                      </Box>
              </Box>
            </Container>
            </>
          : null
        }
      </Box>
    </>
      // Customizable Area End
    );
  }
}

export default withStyles(styles)(VideoManagement);
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: '20px 0px',
    }
  },
});

const webStyle = {
  mainWrapper: {
    display: 'flex', 
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column', 
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  buttonStyle: { 
    width: '100%',
    height: '45px',  
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)', 
  }
};
// Customizable Area End
