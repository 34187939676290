import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    Box,
    TextField,
    Button,
    useMediaQuery,
    useTheme,
    Container,
    Hidden
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {palette} from "./theme";
import Divider from './Divider';
import { Link } from "react-router-dom";
export const configJSON = require("../../framework/src/config");
const axios = require('axios').default;
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#faf9fb',
      height: '100%',
      width: '100%',
      paddingTop: '50px'
    },
    input: {
        fontFamily: 'Poppins-Light',
        paddingRight: '0px'
    },
    attachButton: {
        background: palette.primary.main,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        minHeight: '56px',
        boxShadow: 'none',
        borderLeft: 'none'
    },
  }),
);

const Footer = ({history}: any) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const year = new Date().getFullYear();
    const emailReg = new RegExp("[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,3}$");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [loading, setLoading] = useState(false)


    const isValidEmail = (email: string) => {
        return emailReg.test(email);
    }

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value)
    }

    const subscribeLetter = () => {
        if(isValidEmail(email)){
            setLoading(true)
            axios.put(configJSON.baseURL + '/account_block/accounts/newsletter', {
                email: email
            })
            .then(function (response: any) {
                // handle success
                setSuccess('You have subscribed successfully!')
                setTimeout(() => {
                    setSuccess("");
                    setEmail("");
                }, 2000)
                setLoading(false)
            })
            .catch(function (error: any) {
                // handle error
                console.log(error);
                setError(error);
                setTimeout(() => {
                    setError("");
                }, 2000)
                setLoading(false)
            })
            .then(function () {
                // always executed
                setLoading(false)
            });
        } else {
            setError('Please enter a valid email')
            setLoading(false)
            setTimeout(() => {
                setError("");
            }, 2000)
        }
    }

    return (
        <Box className={classes.root}>
            <Container>
                <Grid>
                    <Box
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: isMatch ? 'column' : 'row',
                        }}
                    >
                        {/* @ts-ignore */}
                        <Hidden smUp>
                            <Grid md={6} sm={12} xs={12}>
                                <Box
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: isMatch ? 'flex-start' : 'flex-end',
                                        alignItems: isMatch ? 'flex-start' : 'flex-end',
                                        marginBottom: '25px'
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            style={{
                                                fontSize: '24px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#000'
                                            }}
                                        >
                                            Join our newsletter
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                                <TextField 
                                                    style={{
                                                        borderBottomRightRadius: 0, 
                                                        borderTopRightRadius: 0
                                                    }} 
                                                    id="input-with-icon-grid" 
                                                    label="Email" 
                                                    value={email}
                                                    variant="outlined" 
                                                    onChange={(e: any) => handleEmailChange(e)}
                                                    InputProps={{
                                                        className: classes.input,
                                                        endAdornment:   <Button 
                                                                            onClick={() => subscribeLetter()}
                                                                            variant="contained" 
                                                                            size="large" 
                                                                            className={classes.attachButton}
                                                                        >
                                                                            {
                                                                                loading ? 
                                                                                <CircularProgress color="secondary" size={20} />
                                                                                : <ArrowForwardIosIcon style={{color: '#fff'}} />
                                                                            }
                                                                        </Button>,
                                                    }}
                                                /> 
                                                {
                                                    error ?
                                                    <Typography
                                                        style={{
                                                            marginTop: '5px',
                                                            color: 'red'
                                                        }}
                                                    >
                                                        {error}
                                                    </Typography>
                                                    : null
                                                }
                                                {
                                                    success ? 
                                                    <Typography
                                                        style={{
                                                            marginTop: '5px',
                                                        }}
                                                    >
                                                        {success}
                                                    </Typography>
                                                    : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                        </Hidden>
                        <Grid item md={6} sm={12} xs={12} container>
                            <Grid md={4} sm={4} xs={6}>
                                <Box style={{padding: '10px'}}>
                                    <Box>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-SemiBold',
                                            }}
                                        >
                                            Programs
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#3d3d3d'
                                            }}
                                        >
                                            All Programs
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Link 
                                            to="/AllCourses"
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#3d3d3d',
                                                cursor: 'pointer',
                                                textDecoration: 'none'
                                            }}
                                        >
                                            Courses
                                        </Link>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#3d3d3d'
                                            }}
                                        >
                                            Live Classes
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#3d3d3d',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <Link 
                                                to="/podcast"
                                                style={{
                                                    fontSize: '16px',
                                                    fontFamily: 'Poppins-Light',
                                                    color: '#3d3d3d',
                                                    cursor: 'pointer',
                                                    textDecoration: 'none'
                                                }}
                                            >
                                                Live Talks
                                            </Link>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid md={4} sm={4} xs={6}>
                                <Box style={{padding: '10px'}}>
                                    <Box>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-SemiBold',
                                            }}
                                        >
                                            About Us
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Link 
                                            to="/aboutus"
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#3d3d3d',
                                                cursor: 'pointer',
                                                textDecoration: 'none'
                                            }}
                                        >
                                            Who we are
                                        </Link>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#3d3d3d',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <Link 
                                                to="/Faqs"
                                                style={{
                                                    fontSize: '16px',
                                                    fontFamily: 'Poppins-Light',
                                                    color: '#3d3d3d',
                                                    cursor: 'pointer',
                                                    textDecoration: 'none'
                                                }}
                                            >
                                                FAQ's
                                            </Link>
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#3d3d3d'
                                            }}
                                        >
                                            Careers
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#3d3d3d'
                                            }}
                                        >
                                        <Link 
                                            to="/Blog"
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#3d3d3d',
                                                cursor: 'pointer',
                                                textDecoration: 'none'
                                            }}
                                        >
                                            Blog
                                        </Link>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid md={4} sm={4} xs={6}>
                                <Box style={{padding: '10px'}}>
                                    <Box>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-SemiBold',
                                            }}
                                        >
                                            Events
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Link 
                                            to="/userEvent"
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#3d3d3d',
                                                cursor: 'pointer',
                                                textDecoration: 'none'
                                            }}
                                        >
                                            About our events
                                        </Link>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#3d3d3d'
                                            }}
                                        >
                                            Local seminars
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#3d3d3d'
                                            }}
                                        >
                                            Let's be real University
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid md={6} sm={12} xs={12}>
                            {/* @ts-ignore */}
                            <Hidden xsDown>
                                <Box
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: isMatch ? 'flex-start' : 'flex-end',
                                        alignItems: isMatch ? 'flex-start' : 'flex-end'
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            style={{
                                                fontSize: '24px',
                                                fontFamily: 'Poppins-Light',
                                                color: '#000'
                                            }}
                                        >
                                            Join our newsletter
                                        </Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                                <TextField 
                                                    style={{
                                                        borderBottomRightRadius: 0, 
                                                        borderTopRightRadius: 0
                                                    }} 
                                                    id="input-with-icon-grid" 
                                                    label="Email" 
                                                    value={email}
                                                    variant="outlined" 
                                                    onChange={(e: any) => handleEmailChange(e)}
                                                    InputProps={{
                                                        className: classes.input,
                                                        endAdornment:   <Button 
                                                                            onClick={() => subscribeLetter()}
                                                                            variant="contained" 
                                                                            size="large" 
                                                                            className={classes.attachButton}
                                                                        >
                                                                            {
                                                                                loading ? 
                                                                                <CircularProgress color="secondary" size={20} />
                                                                                : <ArrowForwardIosIcon style={{color: '#fff'}} />
                                                                            }
                                                                        </Button>,
                                                    }}
                                                /> 
                                                {
                                                    error ?
                                                    <Typography
                                                        style={{
                                                            marginTop: '5px',
                                                            color: 'red'
                                                        }}
                                                    >
                                                        {error}
                                                    </Typography>
                                                    : null
                                                }
                                                {
                                                    success ? 
                                                    <Typography
                                                        style={{
                                                            marginTop: '5px',
                                                        }}
                                                    >
                                                        {success}
                                                    </Typography>
                                                    : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Hidden>
                        </Grid>
                    </Box>
                    <Box style={{marginTop: '20px', marginBottom: '20px'}}>
                        <Grid item>
                            <Divider 
                                bgColor={'#989898'}
                            />
                        </Grid>
                    </Box>
                    <Box style={{marginTop: '20px', marginBottom: '20px'}}>
                        <Box 
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box>
                                <Link
                                    to={'/'}
                                >
                                    <img 
                                        width={'80px'} 
                                        src={require('./logo.svg')} 
                                    />
                                </Link>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Box
                                    style={{
                                        paddingRight: '20px'
                                    }}
                                >
                                    <img width={'20px'} src={require('./yt.png')} />
                                </Box>
                                <Box
                                    style={{
                                        paddingRight: '20px',
                                        paddingLeft: '20px'
                                    }}
                                >
                                    <img width={'10px'} src={require('./fb.png')} />
                                </Box>
                                <Box
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '3px'
                                    }}
                                >
                                    <img width={'18px'} src={require('./tw.png')} />
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography 
                                    style={{
                                        color: '#989898',
                                        fontSize: '12px',
                                        fontFamily: 'Poppins-SemiBold'
                                    }}
                                >
                                    © {year} Let's be real
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Container>
        </Box>
    );
}

export default Footer