import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  mobileOpen: boolean;
  upcomingEvents: any;
  todaysEvents: any;
  pastEvents: any;
  allEvents: any;
  eventsLoading: boolean;
  anchorEl: any;
  selectedMenu: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EventRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getEventsListId: string = '';
  getRegisteredEventsListId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      mobileOpen: false,
      upcomingEvents: [],
      todaysEvents: [],
      pastEvents: [],
      allEvents: [],
      eventsLoading: false,
      anchorEl: null,
      selectedMenu: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId == this.getEventsListId) {
          this.setEventList(responseJson, errorReponse)
        }
        if (apiRequestCallId == this.getRegisteredEventsListId) {
          this.setRegEventList(responseJson, errorReponse)
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start

setRegEventList = (responseJson: any, errorReponse: any) => {
  if(responseJson && !errorReponse){
    let events = responseJson.data.map((item: any) => {
      return (
        {
          id: item.id,
          title: `${item.attributes.start_time} - ${item.attributes.end_time} ` ,
          date: item.attributes.date,
        }
      )
    })
    this.setState({
      eventsLoading: false,
      allEvents: events
    })
  } else {
    this.setState({
      eventsLoading: false
    })
  }
}

setEventList = (responseJson: any, errorReponse: any) => {
  if(responseJson && !errorReponse){
            
    this.setState({
      eventsLoading: false,
      upcomingEvents: responseJson.data.upcoming_streams,
      pastEvents: responseJson.data.past_streams,
      todaysEvents: responseJson.data.today_streams,
    })
  } else {
    this.setState({
      eventsLoading: false
    })
  }
}

  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getEventsList()
    this.getRegisteredEventsList()
}

getEventsList = () => {
  this.setState({ eventsLoading: true })
      const userToken = localStorage.getItem("token");
      let header: any = {};
      if(userToken){
        header = {
          "Content-Type": configJSON.contentType,
          token: userToken,
        };
      } else {
        header = {
          "Content-Type": configJSON.contentType
      };
      }

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getEventsListId = requestMessage.messageId;
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getEventsListApi
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'GET'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
}

getRegisteredEventsList = () => {
  this.setState({ eventsLoading: true })
      const userToken = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.contentType,
        token: userToken,
      };

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getRegisteredEventsListId = requestMessage.messageId;
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getRegisteredEventsListApi
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'GET'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
}
  // Customizable Area End
  
}
