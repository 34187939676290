import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

import googleController, { GoogleWebDelegate } from "./GoogleWebController";
import storage from "../../../framework/src/StorageProvider";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  loading: boolean;
  isRegistration: boolean;
}

interface SS {}

export default class SocialMediaAccountWebController
  extends BlockComponent<Props, S, SS>
  implements GoogleWebDelegate {
  createAccountAPICallId: any;
  googleUser: any;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
    ]);

    this.state = {
      loading: false,
      isRegistration: false
    };
  }

  //When facebook sends back the reponse this gets called
  googleUserStatusChanged(userInfo: any): void {
    if (this.state.isRegistration) {
      this.createAccountFromSocial(userInfo.email, userInfo.id, userInfo.fullName, this.props);
    } else {
      this.logInWithSocial(
        userInfo.email,
        userInfo.password,
        userInfo.id,
        this.props
      );
    }
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  responseFacebook = (response: any) => {
    this.stopLoading();
    this.props.navigation.navigate('VideoManagement')
    if (this.state.isRegistration) {
      this.createAccountFromSocial(response.email, response.id, response.fullName, this.props);
    } else {
      this.logInWithSocial(
        response.email,
        response.email,
        response.id,
        this.props
      );
    }
    runEngine.debugLog(response);
  };

  // googleLogIn = (isRegistration: boolean) => {
  //   const self = this;
  //   //@ts-ignore
  //   googleController.googleLogIn(this).then(
  //     function() {
  //       self.stopLoading();
  //       runEngine.debugLog("User SIGNED IN.");
  //     },
  //     function(error: any) {
  //       self.stopLoading();
  //       if (error.error === "popup_closed_by_user") {
  //         //handle window closed event
  //       }
  //     }
  //   );
  // };

  async receive(from: string, message: Message) {
    this.stopLoading();

    if (getName(MessageEnum.SessionSaveMessage) === message.id) {
      this.openInfoPage();
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createAccountAPICallId != null &&
      this.createAccountAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        this.saveLoggedInUserData(responseJson);
        this.openInfoPage();
      } else if (responseJson && responseJson.errors) {
        alert(responseJson.errors[0].account)
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else {
      runEngine.debugLog("GOIT");
    }
  }

  async createAccountFromSocial(
    incomingEmail: String,
    incomingId: String,
    fullName: String,
    props: Props
  ) {

    
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !fullName ||
      fullName.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountURL
    );

    const data = {
      type: "social_account",
      attributes: {
        email: incomingEmail,
        unique_auth_id: incomingId,
        activated: true,
        full_name: fullName
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async logInWithSocial(
    incomingEmail: string,
    incomingPassword: string,
    incomingId: string,
    props: Props
  ) {
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": "application/json"
    };

    const attrs = {
      email: incomingEmail,
      password: incomingPassword,
      unique_auth_id: incomingId
    };

    const data = {
      type: "social_account",
      attributes: {
        email: incomingEmail,
        password: incomingPassword,
        unique_auth_id: incomingId
      }
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAccountURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigate() {
    runEngine.debugLog("this.isRegistration");
    runEngine.debugLog(this.state.isRegistration);
    if (this.state.isRegistration) {
      runEngine.debugLog("Registration");
      runEngine.debugLog(this.state.isRegistration);
      this.navigateToSignup();
    } else {
      runEngine.debugLog("Registration");
      runEngine.debugLog(this.state.isRegistration);
      this.navigateToLogin();
    }
  }

  navigateToSignup() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );

      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
      if(this.state.isRegistration){
        storage.set("token", responseJson.meta.token);
        storage.set("userName", responseJson.data.attributes.full_name);
        storage.set("userEmail", responseJson.data.attributes.email);
        storage.set("userType", responseJson.data.attributes.user_type);
        this.openInfoPage();
      } else {
        storage.set("token", responseJson.meta.token);
        storage.set("userName", responseJson.meta.user.data.attributes.full_name);
        storage.set("userEmail", responseJson.meta.user.data.attributes.email);
        storage.set("userType", responseJson.meta.user.data.attributes.user_type);
        this.openInfoPage();
      }
    }
  }

  openInfoPage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  //Create response callback.
  responseInfoCallback(error: any, result: any) {
    if (error) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorDescription + error.toString()
      );
    } else {
      runEngine.debugLog("Result Name: " + result.name);
    }
  }

  btnFacebookLogInProps = {
    onPress: () => {
      this.startLoading();
    },
    callback: this.responseFacebook
  };

  btnGoogleLogInProps = {
    onPress: () => {
      // this.googleLogIn(this.state.isRegistration);
      this.startLoading();
    }
  };

  btnNavigateProps = {
    onPress: () => this.navigate()
  };
}
