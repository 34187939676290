import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent, ReactNode } from "react";
import { articleAuthorImg, blogImg1 } from "../../blocks/ContentManagement/src/assets";
import { BlogType, SingleBlogResponse } from "../../blocks/ContentManagement/src/Interfaces.web";
import { VideoType } from "../../blocks/VideoManagement/src/Interfaces.web";
import {
    makeStyles,
    createStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import { BlogUniqueItem, DateTime, IBlog } from "../../blocks/dashboard/src/Interfaces.web";
import { fullDateFormatter } from "../../blocks/ContentManagement/src/BlogPage.web";

interface Props {
    components?: IBlog[];
    titelSubtitle?: BlogUniqueItem;
    date?: DateTime;
    bold: boolean;
    underlined: boolean;
    italic: boolean;
    alignment: string;
}


const PreviewFE = ({ components, titelSubtitle, date, bold, underlined, italic, alignment }: Props) => {
    const classes = useStyles();
    return (
        <div style={{overflowY: "auto", width: '100%', overflowX: "hidden", marginBottom: "20px"}}>
            <div style={{
                backgroundImage: (typeof titelSubtitle?.bgImg[0] == 'string') ? titelSubtitle?.bgImg[0] : `url(${URL.createObjectURL(titelSubtitle?.bgImg[0])})`,
            }} className={classes.headerBg}>
                <div className={classes.overlay}>
                    <div className={classes.mainWrapper}>
                        <Typography style={{ fontSize: "3rem", fontFamily: "Poppins-Bold", textAlign: "center" }} gutterBottom>{titelSubtitle?.title}</Typography>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1rem" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: "2rem" }}>
                                <div style={{ marginRight: "10px" }}>
                                    <img style={{ borderRadius: "50%" }} height="41px" width="41px" alt="client_pic" src={articleAuthorImg} />
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <Typography 
                                        style={{ 
                                            fontFamily: "Poppins-SemiBold" 
                                        }}>
                                            {"Raju"}
                                    </Typography>
                                </div>
                            </div>
                            <div>
                                <Typography style={{ fontFamily: "Poppins-Light" }}>{fullDateFormatter(date?.date as unknown as string)}</Typography>
                            </div>
                        </div>
                        <Typography style={{ fontFamily: "Poppins-Light", fontSize: "1rem", textAlign: "center", padding: "0 25%", color: "#8991a4" }} gutterBottom>{titelSubtitle?.subtitle === null ? "Subtitle is not present" : titelSubtitle?.subtitle}</Typography>
                    </div>
                </div>
            </div>
            {
                components?.map((single: IBlog) => (
                    <div key={single.id}>
                        {single.heading.text ?  <span
                                                    style={{
                                                        textAlign:  alignment == 'center' 
                                                                    ? 'center' : 
                                                                    alignment == 'right'
                                                                    ? 'right' :
                                                                    alignment == 'justify'
                                                                    ? 'justify' : 
                                                                    'left'
                                                    }}
                                                >
                                                    <Typography 
                                                        style={{ 
                                                            fontFamily: bold ? "Poppins-Bold"  : "Poppins-SemiBold", 
                                                            fontSize: "2rem",
                                                            textDecoration: underlined ? 'underline' : 'none'
                                                        }}
                                                    >
                                                        {
                                                            italic
                                                            ? <i>{single.heading.text}</i>
                                                            : single.heading.text
                                                        }
                                                    </Typography> 
                                                </span>
                                                : ""}
                        {single.subheading.text ?   
                                                    <span
                                                        style={{
                                                            textAlign:  alignment == 'center' 
                                                                        ? 'center' : 
                                                                        alignment == 'right'
                                                                        ? 'right' :
                                                                        alignment == 'justify'
                                                                        ? 'justify' : 
                                                                        'left'
                                                        }}
                                                    >
                                                        <Typography 
                                                            style={{ 
                                                                fontFamily: bold ? "Poppins-Bold" : "Poppins-SemiBold", 
                                                                fontSize: "1.5rem" ,
                                                                textDecoration: underlined ? 'underline' : 'none'
                                                            }}
                                                        >
                                                            {
                                                                italic 
                                                                ? <i>{single.subheading.text}</i>
                                                                : single.subheading.text
                                                            }
                                                        </Typography> 
                                                    </span>
                                                    : ""}
                        {single.para.text ? 
                                            <span
                                                style={{
                                                    textAlign:  alignment == 'center' 
                                                                ? 'center' : 
                                                                alignment == 'right'
                                                                ? 'right' :
                                                                alignment == 'justify'
                                                                ? 'justify' : 
                                                                'left'
                                                }}
                                            >
                                                <Typography 
                                                    style={{ 
                                                        fontFamily: bold ? "Poppins-Bold" : "Poppins-Light", 
                                                        fontSize: "1rem", 
                                                        margin: "5px 0 15px 0",
                                                        textDecoration: underlined ? 'underline' : 'none'
                                                    }}
                                                >
                                                    {
                                                        italic 
                                                        ? <i>{single.para.text}</i>
                                                        : single.para.text
                                                    }
                                                </Typography> 
                                            </span>
                                            : ""}
                        {single.img.length ?
                            <>
                                <Grid container spacing={2}>
                                    {
                                        single.img.map((img: any) =>
                                        (
                                            <Grid item sm={4} xs={6} key={img}>
                                                <div>
                                                    <img src={(typeof img == 'string') ? img : URL.createObjectURL(img)} width="100%" height="100%" alt="no_img_found" />
                                                </div>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                                <span
                                    style={{
                                        textAlign:  alignment == 'left' 
                                                    ? 'left' : 
                                                    alignment == 'right'
                                                    ? 'right' :
                                                    alignment == 'justify'
                                                    ? 'justify' : 
                                                    'center'
                                    }}
                                >
                                    <Typography 
                                        style={{ 
                                            fontFamily: bold ? "Poppins-Bold" : "Poppins-Light", 
                                            fontSize: "14px", 
                                            textAlign: "center", 
                                            color: "grey", 
                                            margin: "10px",
                                            textDecoration: underlined ? 'underline' : 'none' 
                                        }}
                                    >
                                        {
                                            italic
                                            ? <i>{single.caption.text}</i>
                                            : single.caption.text
                                        }
                                    </Typography>
                                </span>
                            </>

                            : ""}
                    </div>
                ))
            }
        </div>
    )
}
export default PreviewFE;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerBg: {
            width: "100%",
            height: "auto",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "local",
            backgroundSize: "cover",
            backgroundPosition: "center",
            // backdropFilter: "blur(5px) saturate(100%)"
        },
        mainWrapper: {
            fontFamily: 'Poppins',
            // padding: '5% 8%',
            // [theme.breakpoints.down("xs")]: {
            //     padding: '5% 12%'
            // },
            justifyContent: "center",
        },
        overlay: {
            background: "rgba(50, 70, 80, 0.7)",
            overflow: "hidden",
            // height: "100%"
        },
    })
);