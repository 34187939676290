import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomButton from './CustomButton';
import { palette } from './theme';

export default function AlertModal(props: any) {
  const [open, setOpen] = React.useState(props.open);

  const handleClose = () => {
    if(props.redirectUri){
      props.navigation.navigate(props.redirectUri)
    }
    setOpen(false);
    props.onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span
                style={{
                    fontFamily: 'Poppins-Light'
                }}
            >
                {props.data}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
            }}
          >
            <CustomButton
                title="Ok"
                color={palette.primary.main}
                textColor={"#FFFFFF"}
                pl={'40px'}
                pr={'40px'}
                // @ts-ignore
                onClick={handleClose}
            />
            {/* <Button variant={'contained'} onClick={handleClose} color="primary" autoFocus>
                Ok
            </Button> */}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}