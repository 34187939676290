import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
const passwordInvisibleImage = require("../assets/ic_password_invisible.png");
const passwordVisibleImage = require("../assets/ic_password_visible.png");


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isOtpForm: boolean;
  email: string;
  errorMsg: string;
  loading: boolean;
  error: boolean;
  otp: any;
  otpToken: string;
  isEmailForm: boolean;
  isPasswordForm: boolean;
  password: string;
  confirmpassword: string;
  pwdErrorMsg: string;
  confirmPwdErrorMsg: string;
  isThanksNote: boolean;
  accountStatus: any;
  passwordSchema: any;
  enablePasswordField: boolean;
  passwordRules: any;
  btnConfirmPasswordShowHide: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
const emailReg = /[a-z0-9]+@[a-z]+.[a-z]{2,3}/;
const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/;
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOtpApiId: string;
  confirmOtpId: string;
  changePasswordApiId: string;
  isChangePassword: boolean;
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  passwordVisibleImage: any = passwordVisibleImage;
  passwordInvisibleImage: any = passwordInvisibleImage;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.state = {
      isOtpForm: false,
      email: '',
      errorMsg: '',
      loading: false,
      error: false,
      otp: '',
      otpToken: '',
      isEmailForm: true,
      isPasswordForm: false,
      password: '',
      confirmpassword: '',
      pwdErrorMsg: '',
      confirmPwdErrorMsg: '',
      isThanksNote: false,
      accountStatus: '',
      passwordSchema: '',
      enablePasswordField: true,
      passwordRules: '',
      btnConfirmPasswordShowHide: true
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
  }


  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId != null){
        if(apiRequestCallId === this.getOtpApiId){
          this.setOtp(responseJson)
        }
        if(apiRequestCallId === this.confirmOtpId){
          this.setConfirmOtp(responseJson)
        }
        if(apiRequestCallId === this.changePasswordApiId){
          this.setChangePwd(responseJson)
        }
      }
    }
  }

  setConfirmOtp = (responseJson: any) => {
    if(responseJson && !responseJson.errors){
      this.setState({
        loading: false,
        isEmailForm: false,
        isOtpForm: false,
        isPasswordForm: true
      })
    } else {
      this.setState({
        loading: false,
        error: true,
        errorMsg: responseJson.errors[0].otp,
        isOtpForm: true,
        isEmailForm: false,
        isPasswordForm: false
      })
    }
  }

  setChangePwd = (responseJson: any) => {
    if(responseJson && !responseJson.errors){
      this.setState({
        loading: false,
        isEmailForm: false,
        isOtpForm: false,
        isPasswordForm: false,
        isThanksNote: true
      })
    } else {
      this.setState({
        loading: false,
        error: true,
        errorMsg: configJSON.sww,
        isOtpForm: false,
        isEmailForm: false,
        isPasswordForm: true
      })
    }
  }

  setOtp = (responseJson: any) => {
    if(responseJson && !responseJson.errors){
      this.setState({
        loading: false,
        isEmailForm: false,
        isPasswordForm: false,
        isOtpForm: true,
        errorMsg: '',
        error: false,
        otpToken: responseJson.meta.token
      })
    } else {
      this.setState({
        loading: false,
        isOtpForm: false,
        errorMsg: configJSON.sww,
        error: true
      })
    }
  }

  isValidEmail(email: string) {
    return emailReg.test(email);
  }

  handleInputChange = (type: any, e: any) => {
    if(type == 'email'){
      if(this.isValidEmail(e.target.value)){
        this.setState({
          email: e.target.value,
          errorMsg: ''
        })
      } else {
        this.setState({
          error: true,
          errorMsg: configJSON.enterValidEmail
        })
      }
    }
  }

  handlePasswordChange = (type: any, e: any) => {
    if(type == 'password'){
      if(!passwordReg.test(e.target.value)){
        this.setState({
          pwdErrorMsg: configJSON.pwdMustContain
        })
      } else {
        this.setState({
          password: e.target.value,
          pwdErrorMsg: ''
        })
      }
    } else {
      if(this.state.password != e.target.value){
        this.setState({
          confirmPwdErrorMsg: configJSON.passwordNotMatched
        })
      } else {
        this.setState({
          confirmpassword: e.target.value,
          confirmPwdErrorMsg: ''
        })
      }
    }
  }

  handleOtpChange = (otp: any) => {
    this.setState({otp: otp, error: false, errorMsg: ''})
  }

  submitNewPassword = () => {
    if(!this.state.password){
      this.setState({
        pwdErrorMsg: configJSON.enterPwd
      })
      return
    }
    if(!this.state.confirmpassword){
      this.setState({
        confirmPwdErrorMsg: configJSON.passwordNotMatched
      })
      return
    }
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordApi
    );

    const httpBody = {
      "data": {
        "token": this.state.otpToken,
        "new_password": this.state.password
      } 
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  submitOtp = () => {
    if(this.state.otp.length != 4){
      this.setState({
        error: true,
        errorMsg: configJSON.enterValidOtp
      })
    } else {
      this.confirmOtp()
    }
  }

  requestOtp = () => {
    if(this.isValidEmail(this.state.email)){
      this.getOtp();
    } else {
      this.setState({
        errorMsg: configJSON.enterValidEmail
      })
    }
  }

  confirmOtp = () => {
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.confirmOtpId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.confirmOtpApi
    );

    const httpBody = {
      "data": {
        "token": this.state.otpToken,
        "otp_code": this.state.otp
      } 
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOtp = () => {
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOtpApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOtpApi
    );

    const httpBody = {
      "data": {
        "attributes": {
          "email": this.state.email
        }
      } 
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  goToLogin = () => {
    this.props.navigation.navigate('Login')
  }
}
