// Customizable Area Start
import React from "react";
export interface ProfileContextType {
  full_name: string;
  isTokenInvalid: boolean;
  setTokenValidity: (open: boolean) => void;
  getUserProfileData: () => void;
}
export const defaultContext: ProfileContextType = {
  full_name: "",
  isTokenInvalid: false, 
  setTokenValidity: (open: boolean) => {},
  getUserProfileData: () => {}
};

export const ProfileContext = React.createContext<ProfileContextType>(defaultContext);

// export function useProfile() {
//   return useContext(ProfileContext);
// }
// Customizable Area End