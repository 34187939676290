import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
} from '@material-ui/core';
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import SingleArticleBody from "../../../components/src/SingleArticleBody";
import Reviews from "../../../components/src/Reviews";
import ReactPlayer from 'react-player';
import screenfull from "screenfull";
import ShareModal from "../../../components/src/ShareModal";
import PlayerControlls from "../../../components/src/PlayerControlls";
import VideoCard from "../../../components/src/VideoCard";
import ArticlesResponsiveFont from "../../../components/src/ArticlesResponsiveFont";
import FavouriteIcon from "../../../components/src/FavouriteIcon";
import AlertModal from "../../../components/src/AlertModal";
let count = 0;

const format = (seconds: any) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};


// Customizable Area End

import WatchVideoController, {
  Props,
  configJSON
} from "./WatchVideoController";


class WatchVideo extends WatchVideoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // @ts-ignore
    this.window = window;
    window.scrollTo(0, 0);
    // @ts-ignore
    this.playerRef = React.createRef();
    // @ts-ignore
    this.controlsRef = React.createRef();
    // @ts-ignore
    this.playerContainerRef = React.createRef();
    // @ts-ignore
    this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

    // @ts-ignore
    this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";

    // @ts-ignore
    this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
    // @ts-ignore
    this.totalDuration = format(this.duration);
    // Customizable Area End
  }

  // Customizable Area Start
  handlePlayPause = () => {
    this.setState({ ...this.state, videoPlaying: !this.state.videoPlaying });
  };

  handleRewind = () => {
    // @ts-ignore
    this.playerRef.current.seekTo(this.playerRef.current.getCurrentTime() - 10);
  };

  handleForward = () => {
    // @ts-ignore
    this.playerRef.current.seekTo(this.playerRef.current.getCurrentTime() + 10);
  };

  handleSeekChange = (e: any, newValue: any) => {
    // @ts-ignore
    this.setState({ ...this.state, played: parseFloat(newValue / 100) });
    console.log('====================================');
    console.log("SeekChangeValue, ", newValue);
    console.log('====================================');
  };

  handleSeekMouseDown = (e: any) => {
    this.setState({ ...this.state, seeking: true });
  };

  handleSeekMouseUp = (e: any, newValue: any) => {
    this.setState({ ...this.state, seeking: false });
    // @ts-ignore
    this.playerRef.current.seekTo(newValue / 100, "fraction");
    console.log('====================================');
    console.log("MouseUpValue, ", newValue);
    console.log('====================================');
  };

  handleDuration = (duration: any) => {
    this.setState({ ...this.state, allDuration: duration });
    console.log('====================================');
    console.log("DurationValue", duration);
    console.log('====================================');
  };

  toggleFullScreen = () => {
    this.setState({ hideBtn: !this.state.hideBtn })
    // @ts-ignore
    screenfull.toggle(this.playerContainerRef.current);
  };

  onEscape = () => {
    this.setState({ hideBtn: false })
  }

  handleProgress = (changeState: any) => {
    // @ts-ignore
    this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

    // @ts-ignore
    this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";

    // @ts-ignore
    this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
    // @ts-ignore
    this.totalDuration = format(this.duration);
    if (count > 3) {
      // @ts-ignore
      this.controlsRef.current.style.visibility = "hidden";
      count = 0;
    }
    // @ts-ignore
    if (this.controlsRef.current.style.visibility == "visible") {
      count += 1;
    }
    if (!this.state.seeking) {
      this.setState({ ...this.state, ...changeState });
    }
    console.log('====================================');
    console.log("TotalProgressValue, ", changeState);
    console.log('====================================');
  };

  handleOnEnded = () => {
    this.setState({ ...this.state, videoPlaying: false, played: 0 });
  }

  handlePip = () => {
    this.setState({ ...this.state, pip: !this.state.pip, hideBtn: false })
  }

  handleDisplayFormat = () => {
    this.setState({ timeDisplayFormat: this.state.timeDisplayFormat == " normal" ? 'remaining' : 'normal' })
  };

  handleVolumeSeekDown = (e: any, newValue: any) => {
    // @ts-ignore
    this.setState({ ...this.state, seeking: false, volume: parseFloat(newValue / 100) });
  };
  handleVolumeChange = (e: any, newValue: any) => {
    this.setState({
      ...this.state,
      // @ts-ignore
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  closeShare = () => {
    this.setState({
      openShare: false
    })
  }

  shareOpen = () => {
    this.setState({
      openShare: true
    })
  }

  handleMute = () => {
    this.setState({ ...this.state, muted: !this.state.muted });
  };

  handlePlaybackRate = (rate: any) => {
    this.setState({ ...this.state, playbackRate: rate });
  };

  onChangeBitrate = (quality: any) => {
    // @ts-ignore
    const internalPlayer = this.playerRef.current?.getInternalPlayer();
    if (internalPlayer) {
      // currentLevel expect to receive an index of the levels array
      internalPlayer.currentLevel = quality;
      this.setState({ ...this.state, quality: quality });
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { singleVideo, reviewLoading, reviewStatus } = this.state;
    const title = singleVideo?.video?.attributes?.title;
    // Customizable Area End
    return (
      // Customizable Area Start
      // @ts-ignore
      <Box>
        {
          this.state.popUpAlert ?
            <AlertModal
              open={this.state.popUpAlert}
              data={this.state.selectFavMessage}
              onClose={() => this.cancelAlert()}
            />
            : null
        }
        <ShareModal
          shareClose={this.closeShare}
          openShare={this.state.openShare}
          shareTitle={singleVideo?.video?.attributes?.title}
          shareLink={window.location.href}
          shareDesc={singleVideo?.video?.attributes?.about}
        />
        {/* <Loader loading={sigleVideoLoading} /> */}
        {/* @ts-ignore */}
        <Box className={classes.bodyWrapper}>
          <Typography style={{ color: "#F27024", fontFamily: "Poppins-Light" }} gutterBottom><span style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate("Home")}>Home</span> / <span style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate("Videos")}>Let's Watch</span> / <span style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate("Videos")}>Videos</span> / <ArticlesResponsiveFont type="link" title={title} /> </Typography>
          {/* @ts-ignore */}
          <Box className={classes.whiteBorder} />
          {/* @ts-ignore */}
          <Box>
            {/* @ts-ignore */}
            <Box
              style={{
                padding: '25px 0',
                // paddingTop: '0'
              }}
            >
              {/* @ts-ignore */}
              <Box
                component={'div'}
                style={{
                  borderRadius: '10px',
                  overflow: 'hidden',
                  boxShadow: '0 0 7px 1px rgba(0, 0, 0, 0.22)',
                  marginTop: '-30px',
                  position: 'relative',
                  // width: '100%',
                  // height: '70vh',
                  // height: '0',
                  paddingTop: "56.25%",
                  maxHeight: '80%',
                  background: '#000'
                }}
                // @ts-ignore
                ref={this.playerContainerRef}
              >
                {/* @ts-ignore */}
                <ReactPlayer
                  url={
                    singleVideo?.video?.attributes?.video ? singleVideo?.video?.attributes?.video : "https://minio.b64156.dev.eastus.az.svc.builder.cafe/sbucket/w6btseztgy5xm5utuswb626tlq6e"}
                  playing={this.state.videoPlaying}
                  width={'100%'}
                  height={'100%'}
                  style={{
                    // minWidth: '100%',
                    // minHeight: '100%',
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                  // @ts-ignore
                  ref={this.playerRef}
                  onProgress={this.handleProgress}
                  controls={false}
                  onEnded={this.handleOnEnded}
                  light={this.state.light}
                  loop={this.state.loop}
                  playbackRate={this.state.playbackRate}
                  volume={this.state.volume}
                  muted={this.state.muted}
                  pip={this.state.pip}
                />
                <PlayerControlls
                  // @ts-ignore
                  ref={this.controlsRef}
                  playPause={this.handlePlayPause}
                  state={this.state}
                  rewind={this.handleRewind}
                  forward={this.handleForward}
                  onSeek={this.handleSeekChange}
                  onSeekMouseDown={this.handleSeekMouseDown}
                  onSeekMouseUp={this.handleSeekMouseUp}
                  onDuration={this.handleDuration}
                  played={this.state.played}
                  onToggleFullScreen={this.toggleFullScreen}
                  // @ts-ignore
                  elapsedTime={this.elapsedTime}
                  hideBtn={this.state.hideBtn}
                  // @ts-ignore
                  totalDuration={this.totalDuration}
                  onMute={this.handleMute}
                  muted={this.state.muted}
                  volume={this.state.volume}
                  pip={this.handlePip}
                  onVolumeChange={this.handleVolumeChange}
                  onVolumeSeekDown={this.handleVolumeSeekDown}
                  onChangeDisplayFormat={this.handleDisplayFormat}
                  onPlaybackRateChange={this.handlePlaybackRate}
                  playbackRate={this.state.playbackRate}
                  quality={this.state.quality}
                  onChangeQuality={this.onChangeBitrate}
                  onEscape={this.onEscape}
                  pipIcon={true}
                  forwardSeekIcon={true}
                  backwardSeekIcon={true}
                  volumeIcon={true}
                  settingsIcon={true}
                  ccIcon={true}
                  timeDuration={true}
                />
              </Box>
            </Box>
          </Box>
          {/* @ts-ignore */}
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0" }}>
            <Typography style={{ fontSize: '1.5rem', fontFamily: "Poppins-Bold" }}>{singleVideo?.video?.attributes?.title}</Typography>
            {/* @ts-ignore */}
            <Box
              style={{
                display: 'flex',
                marginLeft: "50px"
              }}
            >
              <FavouriteIcon
                forVideoTitle={true}
                isFavourite={singleVideo?.video?.attributes?.favourite}
                position={'absolute'}
                right={'1px'}
                top={'10px'}
                makeFavourite={(status: any) => this.toggleFavIcon(singleVideo?.video?.id, status)}
                navigation={this.props.navigation}
              />

              {/* @ts-ignore */}
              <Box
                ml={2}
                onClick={this.shareOpen}
                style={{
                  marginTop: '3px',
                  cursor: 'pointer'
                }}
              >
                <img src={require('../assets/share.png')} width={52} />
              </Box>
            </Box>
          </Box>
          {/* @ts-ignore */}
          <Box className={classes.uniqwhiteBorder} />
          <Grid container
            spacing={3}>
            <Grid item md={12} sm={12}>
              <SingleArticleBody 
                video={singleVideo?.video} 
                type={"video"} 
                bold={false}
                italic={false}
                underlined={false}
                alignment={'left'}
              />
            </Grid>
          </Grid>
          {/* @ts-ignore */}
          <Box className={classes.whiteBorder} />
          {/* @ts-ignore */}
          <Box style={{ marginTop: "4rem" }} className={classes.mobileSpace}>
            <Grid container>
              <Grid item md={9} sm={12} xs={12}>
                <Reviews isCoursesReview={false} reviewDesc={singleVideo?.video?.attributes?.reviews} handleReviewSubmit={this.handleReviewSubmit} sorting={this.getReviewData} reviewLoading={reviewLoading} reviewStatus={reviewStatus} />
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* @ts-ignore */}
        <Box
          mt={8}
          mb={8}
          className={classes.otherVideoBg}
        >
          {/* @ts-ignore */}
          <Box style={{ padding: "0 8%", }}>
            {/* @ts-ignore */}
            <Box pt={6} style={{ display: "flex", justifyContent: "space-between", marginBottom: "2rem" }}>
              <Typography component="div" className={classes.headerSize} gutterBottom style={{ fontFamily: "Poppins-Bold" }}>Other videos you may like</Typography>
            </Box>
            <Grid
              container
              spacing={3} className={classes.cardCenter} justifyContent="space-between">
              {
                singleVideo?.related_videos?.length ?
                  singleVideo?.related_videos?.map((data: any) =>
                    <Grid item sm={6} xs={12} md={3} key={data?.id}>
                      <VideoCard item={data} gotToSingleArticle={this.goToSingleVideo} />
                    </Grid>
                  )
                  : <Typography style={{ fontFamily: "Poppins-SemiBold", textAlign: "center", width: "100%" }}>No data found</Typography>
              }
            </Grid>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      typography: {
        body2: {
          fontFamily: 'Poppins, sans-serif'
        },
      },
      mainWrapper: {
        fontFamily: 'Poppins',
        padding: '5% 8%',
        [theme.breakpoints.down("xs")]: {
          padding: '5% 12%'
        },
        justifyContent: "center",
      },
      bodyWrapper: {
        fontFamily: 'Poppins',
        padding: '1% 8%',
        [theme.breakpoints.down("xs")]: {
          padding: '2%'
        },
        justifyContent: "center",
      },
      articleHeader: {
        background: "linear-gradient(to top, #fff000 0%, #000fff 100%)",
      },
      whiteBorder: {
        width: "84%",
        height: "1px",
        margin: "25px 0",
        backgroundColor: "#e6e6e6",
        padding: "0 8% 0"
      },
      uniqwhiteBorder: {
        width: "84%",
        height: "1px",
        margin: "3 0 25px 0",
        backgroundColor: "#e6e6e6",
        padding: "0 8% 0"
      },
      mobileSpace: {
        [theme.breakpoints.down("sm")]: {
          margin: "2rem 0"
        }
      },
      otherVideoBg: {
        background: "linear-gradient(180deg, #fffbe6 50%, #ffffff 50%)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "2%",
      },
      headerSize: {
        fontSize: "1.8rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
        },
    },

    })
  )(WatchVideo)
)
// Customizable Area End
