import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { BlogType, CategoryType, PaginationType } from "../../../ContentManagement/src/Interfaces.web"
import { IBlog, BlogUniqueItem, Sort, DateTime, BlogData, BlogComponentsType } from "../Interfaces.web";
import { ResponseBlog } from "../../../../components/src/PreviewBE";
// Customizable Area End
export const configJSON = require("../config.js");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    mobileOpen: boolean;
    loading: boolean;
    errorMsg: string;
    nextPage: boolean;
    blogUniqueItem: BlogUniqueItem;
    blogList: IBlog[];
    getCategoryLoading: boolean;
    categoryList: CategoryType[];
    authorList: any[];
    authorLoading: boolean;
    selectecCategory: string;
    status: string;
    openSetting: boolean;
    sort: Sort;
    dateTime: DateTime;
    openAlert: boolean;
    dataCreationMsg: string;
    inputError: any;
    previewModal: boolean;
    blogData: ResponseBlog;
    articleId: string;
    title: string;
    bold: boolean;
    underlined: boolean;
    italic: boolean;
    alignment: string;
    createLoading: boolean;
    coverUpdated: boolean;
    coverUrl: string;
    othersName: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EditLetsReadController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getAllCategoryId: string = "";
    getAllAuthorId: string = "";
    updateBlogId: string = "";
    apiGetSingleBlogCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // getName(MessageEnum.NavigationToSingleArticle),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            mobileOpen: false,
            loading: false,
            errorMsg: '',
            nextPage: false,
            blogUniqueItem: {
                title: '',
                subtitle: '',
                bgImg: [],
                base64BgImg: [],
            },
            coverUpdated: false,
            blogList: [{
                dataBaseId: null,
                id: 1,
                img: [],
                base64Img: [],
                heading: {
                    text: "",
                    style: {}
                },
                subheading: {
                    text: "",
                    style: {}
                },
                para: {
                    text: "",
                    style: {}
                },
                caption: {
                    text: "",
                    style: {}
                },
            }],
            coverUrl: '',
            categoryList: [],
            authorList: [],
            authorLoading: false,
            getCategoryLoading: false,
            selectecCategory: "",
            status: "",
            openAlert: false,
            dataCreationMsg: "",
            openSetting: false,
            sort: {
                publish: 'immediately',
                visibility: 'public_user',
                author: 92
            },
            dateTime: {
                date: new Date(),
                time: new Date(),
            },
            inputError: {},
            previewModal: false,
            blogData: {
                blog: {
                    id: "",
                    type: "",
                    attributes: {
                        id: 0,
                        title: '',
                        subtitle: "",
                        instructor_name: '',
                        cover_image: '',
                        reviews: [],
                        instructor_id: 0,
                        category: {
                            id: -1,
                            name: '',
                            created_at: '',
                            updated_at: ''
                        },
                        created_at: "",
                        components: [],
                        tag: "",
                        status: "",
                    }
                },
                related_articles: []
            },
            articleId: "none",
            title: "",
            bold: false,
            underlined: false,
            italic: false,
            alignment: 'left',
            createLoading: false,
            othersName: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {
                if (apiRequestCallId == this.getAllAuthorId) {
                    if (responseJson && !responseJson.errors && responseJson.accounts) {
                        if (responseJson.accounts.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                authorLoading: false,
                                // loading: false,
                                authorList: [],
                                // getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false,
                                authorList: responseJson?.accounts,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getAllCategoryId) {
                    if (responseJson && !responseJson.errors && responseJson.data) {
                        if (responseJson.data.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                // loading: false,
                                categoryList: [],
                                getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                getCategoryLoading: false,
                                categoryList: responseJson?.data,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                getCategoryLoading: false,
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.updateBlogId) {
                    if (responseJson && !responseJson.errors && responseJson.data.type !== "error") {
                        this.setState({
                            ...this.state,
                            dataCreationMsg: "Data updated successfully!",
                            // loading: false,
                            openAlert: true,
                            errorMsg: "",
                            createLoading: false
                            // categoryList: responseJson?.data,
                        });
                    } else {
                        if (responseJson.data.type == "error") {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "Please fill required data",
                                errorMsg: "",
                                createLoading: false
                            })
                        }
                        else if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                dataCreationMsg: "",
                                openAlert: true,
                                createLoading: false
                                // loading: false,
                            });
                        } else {
                            this.setState({
                                openAlert: true,
                                errorMsg: errorReponse,
                                dataCreationMsg: "",
                                createLoading: false
                                // loading: false,
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.apiGetSingleBlogCallId) {
                    if (responseJson && !responseJson.errors && responseJson.data) {
                        const _blogList = responseJson.data.blog.attributes.components.map((blg: BlogComponentsType) => {
                            return {
                                id: blg.id,
                                dataBaseId: blg.id,
                                img: blg.attributes.images,
                                base64Img: [],
                                heading: {
                                    id: blg.attributes.heading.id,
                                    text: blg.attributes.heading.text,
                                    style: blg.attributes.heading.style,
                                },
                                subheading: {
                                    id: blg.attributes.heading.id,
                                    text: blg.attributes.sub_heading.text,
                                    style: blg.attributes.sub_heading.style,
                                },
                                para: {
                                    id: blg.attributes.heading.id,
                                    text: blg.attributes.paragraph.text,
                                    style: blg.attributes.paragraph.style,
                                },
                                caption: {
                                    id: blg.attributes.heading.id,
                                    text: blg.attributes.caption.text,
                                    style: blg.attributes.caption.style,
                                }

                            }
                        })

                        this.setState({
                            ...this.state,
                            errorMsg: "",
                            loading: false,
                            blogData: responseJson.data,
                            coverUrl: responseJson.data.blog.attributes.cover_image,
                            blogUniqueItem: {
                                id: responseJson.data.blog.id,
                                title: responseJson.data.blog.attributes.title,
                                subtitle: responseJson.data.blog.attributes.subtitle,
                                bgImg: [responseJson.data.blog.attributes.cover_image],
                                base64BgImg: [responseJson.data.blog.attributes.cover_image],
                            },
                            blogList: _blogList,
                            othersName: responseJson.data.blog.attributes.tag_others.data.map((item: any) => {
                                return item.id
                            }),
                            dateTime: {
                                date: responseJson.data.blog.attributes.scheduled_date,
                                time: responseJson.data.blog.attributes.scheduled_time
                            },
                            sort: {
                                publish: responseJson.data.blog.attributes.publish_timing,
                                author: responseJson.data.blog.attributes.instructor_id,
                                visibility: "public_user",
                            },
                            selectecCategory: responseJson.data.blog.attributes.category.id,
                            status: responseJson.data.blog.attributes.status,
                            underlined: responseJson.data.blog.attributes.components[0].attributes.heading.style.underlined,
                            bold: responseJson.data.blog.attributes.components[0].attributes.heading.style.bold,
                            italic: responseJson.data.blog.attributes.components[0].attributes.heading.style.italic,
                            alignment: responseJson.data.blog.attributes.components[0].attributes.heading.style.alignment
                        });
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                loading: false,
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                loading: false,
                            });
                        }
                    }
                }
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.getCategory()
        this.getAuthorList()
        this.getSingleBlogData()


    }

    // Customizable Area End

    // Customizable Area Start

    handleSelectOthers = (e: any) => {
        this.setState({
            othersName: e.target.value
        })
    }

    fieldValidation = () => {
        const emailRegExp = /^(([^<>()[]\.,;:\s@"]+(.[^<>()[]\.,;:\s@"]+))|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;
        const passwordRegExp = /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[@$!%?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const { blogUniqueItem, blogList, selectecCategory } = this.state
        let error: any = {}
        if (!blogUniqueItem.title) {
            error.title = "Please Provide Title"
        }
        if (!blogUniqueItem.subtitle) {
            error.subtitle = "Please Provide Subtitle"
        }
        if (!blogUniqueItem.bgImg.length) {
            error.bgImg = "Please Provide Cover Image"
        }
        blogList.forEach((singleBlg: IBlog) => {
            if (!blogList[0].heading.text) {
                error.heading = "Please Provide Heading"
            }
            if (!blogList[0].para.text) {
                error.para = "Please Provide Paragraph"
            }
        })
        // if (phoneNumber) {
        //     if (phoneNumber.length != 10) {
        //         error.phoneNumber = "Phone number must be 10 digits"
        //     }
        // }
        // if (!password) {
        //     error.password = "Enter your Password"

        // }
        // // if (password && !password.match(passwordRegExp)) {
        // //     error.password = "Password not valid, must have atleast 8 characters containing 1 upercase, 1 lowercase, 1 special symbol and 1 number.";
        // // }
        // if (!confirmPassword) {
        //     error.confirmPassword = "Enter your confirm Password"
        // }
        // if (password && confirmPassword) {
        //     if (password.trim() !== confirmPassword.trim()) {
        //         error.confirmPassword = "Password doesnot match"
        //     }
        // }
        if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
            this.setState({ inputError: {} })
            this.updateBlog()
        }
        else {
            this.setState({ inputError: error })
        }
    }
    updateBlog = () => {
        this.setState({
            createLoading: true
        })
        const { blogUniqueItem, dateTime, sort, selectecCategory, status, othersName } = this.state;
        const httpBody = {
            "blogs": {
                title: "",
                subtitle: "",
                category_id: 5,
                tag: "normal",
                status: "", //can be published or draft
                visibility: "public_user", //can be lbl_users
                scheduled_date: "22/09/2022",
                scheduled_time: "01:00",
                components_attributes: [
                    {
                        images: ["base64string"],
                        heading_attributes: {
                            text: "test",
                            style: {
                                display: "test"
                            }
                        },
                        sub_heading_attributes: {
                            text: "test",
                            style: {
                                display: "test"
                            }
                        },
                        paragraph_attributes: {
                            text: "test",
                            style: {
                                display: "test"
                            }
                        },
                        caption_attributes: {
                            text: "test",
                            style: {
                                display: "test"
                            }
                        }
                    }
                ]
            }
        }

        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("token")
        };

        let blogs: any = {}
        let components_attributes: any[] = []
        this.state.blogList.forEach(blg => {
            // let imgList: any[] = []
            // blg.img.map((img) => {
            //     this.getBase64(img, (base64Img: any) => {
            //         imgList.push(base64Img)
            //     })
            // })
            components_attributes.push({
                id: blg.dataBaseId ? blg.dataBaseId : null,
                images: blg.base64Img,
                heading_attributes: {
                    id: blg.heading.id ? blg.heading.id : "",
                    text: blg.heading.text,
                    style: blg.heading.style,
                },
                sub_heading_attributes: {
                    id: blg.subheading.id ? blg.subheading.id : "",
                    text: blg.subheading.text,
                    style: blg.subheading.style
                },
                paragraph_attributes: {
                    id: blg.para.id ? blg.para.id : "",
                    text: blg.para.text,
                    style: blg.para.style
                },
                caption_attributes: {
                    id: blg.caption.id ? blg.caption.id : "",
                    text: blg.caption.text,
                    style: blg.caption.style
                }
            })

        })
        blogs.components_attributes = components_attributes
        blogs.title = blogUniqueItem.title
        blogs.subtitle = blogUniqueItem.subtitle
        blogs.tag_others = othersName
        if(this.state.coverUpdated){
            blogs.cover_image = blogUniqueItem.base64BgImg ? blogUniqueItem.base64BgImg[0] : blogUniqueItem.bgImg 
        }
        blogs.publish_timing = sort.publish
        blogs.status = status
        blogs.visibility = sort.visibility
        blogs.scheduled_date = dateTime.date
        blogs.scheduled_time = dateTime.time
        blogs.tag = "normal"
        blogs.category_id = selectecCategory

        const body = {
            blogs: blogs
        }


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateBlogId = requestMessage.messageId;
        const hitURL = `${configJSON.updateBlogUrl}/${this.state.articleId ? this.state.articleId : localStorage.getItem("blogId")}`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            hitURL
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)

        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getBase64(file: any, cb: any) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    getSingleBlogData = () => {
        this.setState({ loading: true, articleId: localStorage.getItem("blogId") as string })
        window.scrollTo({ top: 0, behavior: 'smooth' })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetSingleBlogCallId = requestMessage.messageId;
        const hitURL = `${configJSON.updateBlogUrl}/${this.state.articleId === "none" ? localStorage.getItem("blogId") : this.state.articleId}?dashboard=true`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            hitURL
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCategory = () => {
        this.setState({ getCategoryLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllCategoryId = requestMessage.messageId;
        const hitURL = `${configJSON.categoryUrl}?type=${configJSON.categoryType}`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            hitURL
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getAuthorList = () => {
        this.setState({ getCategoryLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllAuthorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAuthorUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    addNewBlog = () => {
        this.setState((prev_state) => {
            return {
                blogList: [...prev_state.blogList, {
                    dataBaseId: null,
                    id: new Date().getTime(),
                    img: [],
                    base64Img:[],
                    heading: {
                        text: "",
                        style: {}
                    },
                    subheading: {
                        text: "",
                        style: {}
                    },
                    para: {
                        text: "",
                        style: {}
                    },
                    caption: {
                        text: "",
                        style: {}
                    },
                }]
            }
        })
    }

    removeBlog = (id: number) => {
        const _newblogList = this.state.blogList.filter(blg => blg.id !== id)
        this.setState({ blogList: _newblogList })
    }

    handleBlogUniqueItemChange = (event: any, name: string) => {
        const { value } = event.target;
        this.setState({
            blogUniqueItem: {
                ...this.state.blogUniqueItem,
                [name]: value
            }
        });
    };

    handleBgImgChange = (value: any[], file: any[]) => {
        this.setState({
            coverUpdated: true,
            blogUniqueItem: {
                ...this.state.blogUniqueItem,
                bgImg: value,
                base64BgImg: file,
            }
        })
    }

    handleHeadingChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    heading: {
                        ...blg.heading,
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleSubheadingChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    subheading: {
                        ...blg.subheading,
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleParagraphChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    para: {
                        ...blg.para,
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleImgChange = (id: number, value: any[], files: any[]) => {
        const { blogList } = this.state;
        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    img: [...blg.img, ...value],
                    base64Img: [...blg.base64Img, ...files]
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleCaptionChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    caption: {
                        ...blg.caption,
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleCategorySelect = (id: string) => {
        this.setState({ selectecCategory: id })
    }

    hanldeSettingToggle = () => {
        this.setState({ openSetting: !this.state.openSetting })
        // console.log("Setting toggle", this.state.openSetting)
    }

    handleSortChange = (event: any, name: string) => {
        const { value } = event.target;
        this.setState({
            sort: {
                ...this.state.sort,
                [name]: value
            }
        });
    };

    handleDateTimeChange = (value: Date, name: string) => {
        this.setState({
            dateTime: {
                ...this.state.dateTime,
                [name]: value,
            }
        })
    }

    handleDraft = () => {
        this.setState((pre_state) => {
            return {
                ...pre_state,
                status: "draft"
            }
        }, () => this.fieldValidation())
    }

    handlePublish = () => {
        this.setState((pre_state) => {
            return {
                ...pre_state,
                status: this.state.sort.publish === "schedule_post" ? "scheduled" : "published"
            }
        }, () => this.fieldValidation())
    }

    closeAlertBox = () => {
        this.setState({
            openAlert: false
        });
    };

    handlePreviewOpen = () => {
        this.setState({ previewModal: true })
    }

    handleModalClose = () => {
        this.setState({ previewModal: false })
    }

    // Customizable Area End

}
