import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loading: boolean;
  termsData: any;
  errorMsg: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsAndConditionController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  termsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // getName(MessageEnum.NavigationToSingleArticle),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loading: false,
      termsData: '',
      errorMsg: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getTermsAndCondition();
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );      
        if(apiRequestCallId && responseJson){
            if (this.termsApiCallId === apiRequestCallId) {
                if(responseJson && !responseJson.errors && responseJson.data){
                    if (responseJson.data.length === 0) {
                        this.setState({
                          errorMsg: "Data Not Found",
                          loading: false
                        });
                    } else {
                        this.setState({
                            termsData: responseJson.data?.attributes?.description,
                            errorMsg: '',
                            loading: false
                        })
                    }
                } else {
                    if (errorReponse === undefined) {
                      this.setState({
                        errorMsg: "Something went wrong",
                        loading: false
                      });
                    } else {
                      this.setState({
                        errorMsg: errorReponse,
                        loading: false
                      });
                    }
                }
            }
        }
    }
    // Customizable Area End

  }
// Customizable Area Start
  getTermsAndCondition(): boolean {
    this.setState({
        loading: true
    })
    const header = {
        "Content-Type": configJSON.validationApiContentType
    }
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.termsApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.termsEndPoint+"?type=terms_condition"
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

// Customizable Area End

  


  // Customizable Area End

}
