import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { palette } from './theme';
import CustomButton from './CustomButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { IconButton } from '@material-ui/core';


const filledHeart = require('./assets/HeartFilled.png');
const heart = require('./assets/Heart.png')





const FavouriteIcon = (props:any) => {
    
    const token = localStorage.getItem('token')
    const [openModal, setOpenModal] = useState(false);

    const update = (status: any) => {
        if(token){
            props.makeFavourite(status)
        } else {
            setOpenModal(true)
        }
    }

    const closeAlertBox = () => {
        setOpenModal(false)
        props.navigation.navigate('Login')
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    return (
        <div
            style={{
                position: 'relative'
            }}
        >
            <Dialog
                open={openModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span
                        style={{
                            fontFamily: 'Poppins-Light'
                        }}
                    >
                        To make it your favourite please login!
                    </span>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flex: 1,
                    }}
                >
                    <CustomButton
                        title="Ok"
                        color={palette.primary.main}
                        textColor={"#FFFFFF"}
                        pl={'40px'}
                        pr={'40px'}
                        // @ts-ignore
                        onClick={closeAlertBox}
                    />
                    {/* <Button variant={'contained'} onClick={handleClose} color="primary" autoFocus>
                        Ok
                    </Button> */}
                </div>
                </DialogActions>
            </Dialog>

            {
                props.forVideoTitle ? <IconButton
                    style={{
                        marginTop: "9px",
                        height: "40px",
                        width: "40px",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
                        borderRadius: "5px"
                    }}
                    onClick={() => update(!props.isFavourite)}
                >
                    {
                        props.isFavourite ? <FavoriteIcon style={{color: "red"}} />
                            : <FavoriteBorderIcon style={{color: "black"}} />
                    }
                </IconButton>
            
            :
            <img 
                src={props.isFavourite ? filledHeart : heart} 
                style={{
                    height: props.height ? props.height : '32px',
                    width: props.width ? props.width : '32px',
                    position: props.position,
                    cursor: 'pointer',
                    right: props.right ? props.right : '',
                    top: props.top ? props.top : '',
                    left: props.left ? props.left : '',
                    bottom: props.bottom ? props.bottom : '',
                    zIndex: 99999 
                }}
                onClick={() => update(!props.isFavourite)}
            />

    }
        </div>
    )
}

export default FavouriteIcon;