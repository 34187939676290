import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
    classes: any;
    discussions:any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  coursesFilterOptionsData : any;
  allCoursesData : any;
  singleCourseData : any;
  errorMsg: any;
  cat_loading: any;
  courses_loading: any;
  loading: any;
  catId: any;
  tabs: any;
  carausalId: any;
  carausalPlay: boolean;
  reviewLoading: boolean;
  reviewStatus: boolean;
  singleCourseID: any;
  lessonDiscussionText: any;
  totalPages: any;
  bestSellerIDs: any;
  studentStatus:any;
  enrolled: any;
  enrolledAPI: boolean;
  sort:any;
  openAlert: boolean;
  errorData: any;
  uri: any;
  lectureStatus: any;
  isFavourite: boolean;
  updateMessage: any;
  openFavIconAlertModal: boolean;
  openEnrollModal: boolean;
  openAlertSingleCourse: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectNotesController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getCoursesListApiId : string = ""
  GetAllCoursesApiId : string = ""
  GetSingleCoursesApiId : string = ""
  apiPostReviewId : string = ""
  enrolledCourseApiId : string = ""
  apiMakeFavouriteId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      coursesFilterOptionsData: [],
      allCoursesData: [],
      singleCourseData: {},
      errorMsg: "",
      loading: false,
      cat_loading: false,
      courses_loading: false,
      catId:'',
      tabs: 0,
      carausalId: '',
      carausalPlay: false,
      reviewLoading: false,
      reviewStatus: false,
      singleCourseID: '',
      lessonDiscussionText: '',
      totalPages:'',
      bestSellerIDs: [],
      studentStatus: [],
      enrolled: '',
      enrolledAPI: false,
      sort:'popularity',
      openAlert: false,
      errorData: {},
      uri: '',
      lectureStatus: [],
      isFavourite: false,
      updateMessage: '',
      openFavIconAlertModal: false,
      openEnrollModal: false,
      openAlertSingleCourse: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }



  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );



      if (apiRequestCallId != null) {
    // Response of podcast filter listing api
      if (apiRequestCallId === this.getCoursesListApiId) {
        if(responseJson && !responseJson.error){
          if(!responseJson.data.length){
            this.setState({
              errorMsg: "Data Not Found",
              cat_loading: false
            });
          } else {
            this.setState({
              coursesFilterOptionsData: responseJson.data,
              cat_loading: false
            }, () => {
              this.setState({
                coursesFilterOptionsData: responseJson.data,
                cat_loading: false
              })
            })
          }
        }
      }

      if(this.apiMakeFavouriteId === apiRequestCallId){
        this.setFav(responseJson)
      }

      // Response of All course data api 
      if (apiRequestCallId === this.GetAllCoursesApiId) {
        if(responseJson && !responseJson.errors){
          if(!responseJson.data.length){
            this.setState({
              errorMsg: "Data Not Found",
              allCoursesData: [],
              courses_loading: false,
              totalPages: {}
            });
          } else {
            // let bestSellerId = responseJson.best_seller_courses.map((x:any) => x.id)
            this.setState({  
              allCoursesData: responseJson.data,
              courses_loading: false,
              totalPages: responseJson.meta.pagination,
              // bestSellerIDs: responseJson.best_seller_courses
            }, () => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
              this.setState({
                allCoursesData: responseJson.data,
                courses_loading: false,
                totalPages: responseJson.meta.pagination,
                // bestSellerIDs: responseJson.best_seller_courses
              })
            })
          }
        } else {
            this.setState({
              allCoursesData: [],
              courses_loading: false,
            })
          
        }
      }

      // single course api data 
      if (apiRequestCallId === this.GetSingleCoursesApiId) {
        if(responseJson && !responseJson.error){
          if(!responseJson.data){
            this.setState({
              errorMsg: "Data Not Found",
              loading: false
            });
          } else {
            let studentStatus = responseJson.data.attributes.lectures.map(((x:any)=> (
              {
                id: x.id,
                status: x.attributes.student_lecture ? x.attributes.student_lecture.status : ''
              }

            )))
            // console.log(studentStatus)
            this.setState({
              singleCourseData: responseJson.data,
              lectureStatus: responseJson.data?.attributes?.lectures,
              reviewLoading: false,
              loading: false,
              studentStatus
            }, () => {
              this.setState({
                singleCourseData: responseJson.data,
                lectureStatus: responseJson.data?.attributes?.lectures,
                reviewLoading: false,
                studentStatus
              })
            })
          }
        }
      }


      // apiPostReviewId api data 
      if (apiRequestCallId === this.apiPostReviewId) {
        if(responseJson && !responseJson.error){
          if(!responseJson.data){
            this.setState({
              errorMsg: "Data Not Found",
              loading: false
            });
          } else {
            this.getSingleCourse(this.state.singleCourseID)
            this.setState({
              reviewStatus: true,
              lessonDiscussionText: ''
            }, () => {
              this.setState({
                reviewStatus: true,
                lessonDiscussionText: ''
              })
            })
            setTimeout(() => {
              this.setState({ reviewStatus: false })
            }, 2000)
          }
        }
      }

      // course enrolled 
      if (apiRequestCallId === this.enrolledCourseApiId) {
        if(responseJson && !responseJson.error){
          if(!responseJson.data){
            this.setState({
              errorMsg: "Data Not Found",
              loading: false
            });
          } else {
            this.getSingleCourse(this.state.singleCourseID)
            // this.showAlert("Message", "Enrolled Successfully ")
            this.setState({
              openEnrollModal: false,
              openAlert: true,
              openAlertSingleCourse: true,
              errorData: 'Enrolled successfully!'
            })
          }
        } else {
          this.setState({
            openAlert: true,
            openAlertSingleCourse: responseJson.error,
            errorData: responseJson.error
          })
        }
      }


      }

    // Customizable Area End
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start

  setFav = (responseJson: any) => {
    const path: any = window.location.href.split('/', 5).pop()
    if(responseJson.message === "Favourite Updated!"){
      this.setState({
        loading: false,
        updateMessage: this.state.isFavourite ? 'Added to your favourites!' : 'Removed from your favourites!',
        openAlert: true,
        openFavIconAlertModal: true
      }, () => {
        const _newpath = window.location.pathname;
        const firstName = _newpath.split("/")[1];
        if (firstName === "CourseDetails") {
          this.getSingleCourse(this.state.singleCourseID)
        } else {
          this.getAllCourses("", 1, path == "MyCourses" ? true : false)
        }
      })
    } else {
      this.setState({
        loading: false,
        updateMessage: 'Something went wrong!',
        openFavIconAlertModal: true
      })
    }
  }


  makeFavourite = (id: any, status: any) => {
    this.updateFavourite(id, status)
    this.setState({
      isFavourite: status
    })
  }

  updateFavourite = (id: any, status: any) => {
    this.setState({
      loading: true
    })
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken
    };

    const data = {
      type: 'BxBlockLibrary2::Course',
      id: id,
      favourite: status
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMakeFavouriteId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateFavouriteApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

    navigationToLesson = (lessonId: any) => {
      // let lessonID = this.state.singleCourseData.attributes.lectures.map((x:any) => x.id)
      // let id = lessonID[lessonNo - 1]
      // console.log(lessonNo)
      this.props.navigation.navigate("singleLessons", {id: lessonId})
    } 

  // get completed lessons for user

  getCompletedLessons = () => {
    if(this.state.studentStatus.length){
    let count =  this.state.studentStatus.filter((x:any) => x.status === 'complete').length
    return count
    }
  }


  // courses tabs switching 
    handleTabsChange = (event : any, newValue : any) => {
      this.setState({tabs: newValue})
    }


  // filtered courses 
    getFilteredPodcast = (catId: any) => {
    // localStorage.setItem('category_id', catId);
    this.setState({
      catId: catId
    })
    this.getAllCourses(catId)
  }

  // submit course review API
  // istanbul ignore next
  submitReviewForCourse = (data:any) => {
    const header = {
      token: localStorage.getItem("token")
    };

    const formData = new FormData();
    formData.append("review[course_id]", this.state.singleCourseID);
    formData.append("review[text]", data.description);
    formData.append("review[full_name]", data.fullName);
    formData.append("review[email]", data.workEmail);
    formData.append("review[type]", "course");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostReviewId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postReviewUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // get all categories 
  getPodcastFilterList () {
    
    this.setState({cat_loading: true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCoursesListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPodcastCategoryOptionsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    // return true
  }

  // pagination 

  changePage = (e:any, value: any) => {
    this.getAllCourses(this.state.catId, value)
  }

  // sorting 

  handleSortChange = (event: any) => {
    this.setState({sort : event?.target?.value as string});
    // sorting(event.target.value as string)
    // istanbul ignore next
    this.getAllCourses(this.state.catId,1,false,event?.target?.value)
    };

  // get all courses 
  getAllCourses (catId:any, page: any = 1, enrolled: any = false, sort : any = 'newest_first') {
    const path: any = window.location.href.split('/', 5).pop()
    let enrolledAPI2 = path == 'MyCourses' ? true : false;
    this.setState({courses_loading: true})
    // console.log("called ====> ")
    let user_token = localStorage.getItem('token') 
    const header = user_token ? {
      "Content-Type": configJSON.validationApiContentType,
       token: localStorage.getItem('token') 
    } : {
      "Content-Type": configJSON.validationApiContentType,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.GetAllCoursesApiId = requestMessage.messageId;
    if(catId){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllCoursesApiEndPoint+`?category_id=${catId}&page=${page}&per_page=10&enrolled=${enrolledAPI2}&sort=${sort}`
      );    
    }else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllCoursesApiEndPoint+`?page=${page}&per_page=12&enrolled=${enrolledAPI2}&sort=${sort}`
      );
    }
  

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    // return true
  }

  

  getSingleCourse (courseId:any, sort: any = "latest_first") {
    this.setState({loading: true})
    let user_token = localStorage.getItem('token') 
    const header = user_token ? {
      "Content-Type": configJSON.validationApiContentType,
       token: localStorage.getItem('token') 
    } : {
      "Content-Type": configJSON.validationApiContentType,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetSingleCoursesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCoursesApiEndPoint+"/"+courseId+"?sort="+sort
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    // return true
  }


  // get course sort 
  getSingleCourseSort = (sort: string) => {
    this.setState({reviewLoading: true})
    this.getSingleCourse(this.state.singleCourseID, sort)
  }


  // Enrolled to course 
  // istanbul ignore next
  enrolledToTheCourse (course_id:any, free: any) {
    const token = localStorage.getItem('token');

    if(!token) {
      localStorage.setItem('enrollNow', 'yes')
      localStorage.setItem('courseId', course_id)
      this.setState({
        openEnrollModal: false,
        openAlert: true,
        openAlertSingleCourse: true,
        errorData: "You must login to enroll in this course!",
        uri: "Login"
      })
      // this.props.navigation.navigate('Login');
    } else if(free){
      this.setState({
        openAlert: true,
        openAlertSingleCourse: true,
        errorData: "Payment flow coming soon!",
      })
    } else {
      const header = {
        token: localStorage.getItem("token")
      };
    
      const formData = new FormData();
      formData.append("data[attributes][course_id]", course_id);
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.enrolledCourseApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
       'bx_block_stripe_integration/payment_intent'
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  donate = () => {
    this.props.navigation.navigate('DonationPayments')
  }

  clickAllCourses = () => {
    this.setState({
      catId: "",
    },() => this.getAllCourses(""))
  }

  gotoCourseDetails = (itemId: any) => {
    this.props.navigation.navigate("CourseDetails", {id: itemId})
  }
  openEnrollModal = () => {
    this.setState({
      openEnrollModal: true
    })
  }
  closeEnrollModal = () => {
    this.setState({
      openEnrollModal: false,
    })
  }

  // Customizable Area End
  
}
