import React, { Fragment } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { makeStyles, useTheme, Theme, createStyles, ThemeProvider } from '@material-ui/core/styles';
import { Box, Button, Divider, FormControl, Grid, Select, TextField, Typography, createTheme, IconButton } from '@material-ui/core';
import { palette } from './theme';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import FormatBold from "@material-ui/icons/FormatBold"
import FormatItalic from "@material-ui/icons/FormatItalic"
import FormatUnderlined from "@material-ui/icons/FormatUnderlined"
import FormatAlignCenter from "@material-ui/icons/FormatAlignCenter"
import FormatAlignRight from "@material-ui/icons/FormatAlignRight"
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft"
import FormatAlignJustify from "@material-ui/icons/FormatAlignJustify"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Link } from 'react-router-dom';
import CustomDrop from './CustomDrop';
import BgImgDrop from './BgImgDrop';
import { CategoryType } from '../../blocks/ContentManagement/src/Interfaces.web';
import { DateTime, Sort } from '../../blocks/dashboard/src/Interfaces.web';
import { KeyboardTimePicker, TimePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Edit from '@material-ui/icons/Edit';
import { red } from '@material-ui/core/colors';
// @ts-ignore
import { EditIcon } from '../../blocks/dashboard/src/assets';
import LetsReadBgImgDrop from './LetsReadBgImgDrop';

// const categoryList = [{ id: 1, name: 'Yoga' }, { id: 2, name: 'Lifestyle' }, { id: 3, name: 'Spirituality' }, { id: 4, name: 'Public Speaking' }, { id: 5, name: 'Social Server' }, { id: 6, name: 'Business' }, { id: 7, name: 'Technology' }, { id: 8, name: 'Uncategorized' }]
const drawerWidth = 300;

const materialTheme = createTheme({
    palette: {
        primary: {
            main: "#F27024"
        },
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            // marginTop: '5rem',
            height: `calc(100% - 5rem)`,
            [theme.breakpoints.up('sm')]: {
                // width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
            marginTop: "108px",
            background: '#ffffff'
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        nested: {
            paddingLeft: theme.spacing(7),
        },
        menuItemStyle: {
            textAlign: "right",
            color: '#000 !important',
            backgroundColor: '#ffffff',
        },
        customArrow: {
            position: "absolute",
            top: 0,
            right: 0,
            display: "block",
            height: '100%',
            width: "3rem",
            pointerEvents: 'none',
        },
        select: {
            fontSize: "12px",
            fontFamily: "Poppins-SemiBold",
            backgroundColor: 'none',
            paddingLeft: "10px",
            "& .MuiSelect-icon": {
                zIndex: 1,
                right: ".7rem",
                color: '#F27024',
            },
            "& .MuiSelect-select": {
                color: '#F27024',
                backgroundColor: '#fff !important',
                paddingLeft: '0',
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: "#F27024",
            },
        }
    }),
);

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    open: any;
    handleDrawerToggle: () => void;
    navigation: any;
    categoryList: CategoryType[];
    authorList: any[];
    authorLoading: boolean;
    handleSelectCategory: (id: string) => void;
    selectedCategory: string;
    categoryLoading: boolean;
    errorMsg: string;
    sort: Sort;
    startDateTime: DateTime;
    endDateTime: DateTime;
    isUrl?: boolean;
    getBold: any;
    getUnderlined: any;
    getItalic: any;
    getAlignment: any;
    handleBgImgChange: (value: any[], files: any[]) => void;
    handleSortChange: (event: any, name: string) => void;
    handlestartDateTimechange: (value: Date, name: string) => void;
    handleEndDateTimechange: (value: Date, name: string) => void;
    type: any;
    catValidation: string;
    from: string;
    comingFrom: string;
    coverUrl: string;
    startDateValidation: any;
    endDateValidation: any;
}

const getSchedulePostList = (sort: any, handleSortChange: any, classes: any) => {
    return (
        <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end" }}>
            <Typography style={{ fontFamily: "Poppins-Light", fontSize: '12px', marginRight: "3px" }}>Publish</Typography>
            <FormControl style={{ minWidth: "175", marginLeft: '10px', width: "100%", border: '1px solid rgba(82,92, 229, 0.12)', borderRadius: '5px' }}>
                <Select
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sort.publish}
                    className={classes.select}
                    label="Sort"
                    onChange={e => handleSortChange(e, "publish")}
                    disableUnderline={true}
                    IconComponent={KeyboardArrowDownIcon}
                >
                    <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "Poppins-Light" }} value={"schedule_post"}>Schedule post</MenuItem>
                </Select>
                <span className={classes.customArrow}></span>
            </FormControl>
        </div>
    )
}

const getStartDateCalendar = (sort: any, classes: any, startDateTime: any, handlestartDateTimechange: any) => {
    return (
             sort.publish === "schedule_post" ?
                <>
                    <ListItem style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <KeyboardDatePicker 
                                    disablePast
                                    value={startDateTime.date}
                                    onChange={(newDate: any) => handlestartDateTimechange(newDate, "date")}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            fontSize: '12px',
                                            justifyContent: 'flex-end',
                                            right: '-15px'
                                        }
                                    }}
                                    variant="inline"
                                    className={classes.MuiPickersModal}
                                    format="DD MMMM YYYY"
                                    keyboardIcon={
                                        <IconButton
                                            style={{
                                                height: '15px',
                                                width: '15px',
                                                borderRadius: '50%',
                                                // marginRight: '5px'
                                            }}
                                        >
                                            <img src={EditIcon} height={'20px'} />
                                        </IconButton>
                                    }
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </ListItem>
                    <ListItem style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <KeyboardTimePicker value={startDateTime.time}
                                    onChange={(newTime: any) => handlestartDateTimechange(newTime, "time")}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            fontSize: '12px',
                                            justifyContent: 'flex-end',
                                            right: '-15px'
                                        }
                                    }}
                                    variant="inline"
                                    keyboardIcon={
                                        <IconButton
                                            style={{
                                                height: '15px',
                                                width: '15px',
                                                borderRadius: '50%',
                                                // marginRight: '5px'
                                            }}
                                        >
                                            <img src={EditIcon} height={'20px'} />
                                        </IconButton>
                                    }
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </ListItem>
                </>
                : ""
    )
}

const getEndDateCalendar = (sort: any, classes: any, endDateTime: any, handleEndDateTimechange: any) => {
    return (
             sort.publish === "schedule_post" ?
                <>
                    <ListItem style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <KeyboardDatePicker 
                                    disablePast
                                    value={endDateTime.date}
                                    onChange={(newDate: any) => handleEndDateTimechange(newDate, "date")}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            fontSize: '12px',
                                            justifyContent: 'flex-end',
                                            right: '-15px'
                                        }
                                    }}
                                    variant="inline"
                                    className={classes.MuiPickersModal}
                                    format="DD MMMM YYYY"
                                    keyboardIcon={
                                        <IconButton
                                            style={{
                                                height: '15px',
                                                width: '15px',
                                                borderRadius: '50%',
                                                // marginRight: '5px'
                                            }}
                                        >
                                            <img src={EditIcon} height={'20px'} />
                                        </IconButton>
                                    }
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </ListItem>
                    <ListItem style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <ThemeProvider theme={materialTheme}>
                                <KeyboardTimePicker value={endDateTime.time}
                                    onChange={(newTime: any) => handleEndDateTimechange(newTime, "time")}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            fontSize: '12px',
                                            justifyContent: 'flex-end',
                                            right: '-15px'
                                        }
                                    }}
                                    variant="inline"
                                    keyboardIcon={
                                        <IconButton
                                            style={{
                                                height: '15px',
                                                width: '15px',
                                                borderRadius: '50%',
                                                // marginRight: '5px'
                                            }}
                                        >
                                            <img src={EditIcon} height={'20px'} />
                                        </IconButton>
                                    }
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </ListItem>
                </>
                : ""
    )
}

const getCategoryList = (selectedCategory: any, categoryLoading: any, categoryList: any, handleSelectCategory: any) => {
    return (
        // @ts-ignore
        <Box style={{ margin: "2rem 0", textAlign: "center" }} id="pag">
            {/* <Grid> */}
            {
                // errorMsg ? <Typography>{errorMsg}</Typography>
                    categoryLoading ? <Typography>Loading...</Typography>
                        // @ts-ignore
                        : <Box style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                            <Grid container spacing={2}>
                                {categoryList?.map((item: any) => (
                                    <Grid item md={6} xs={6} sm={6} key={item?.id}>
                                        <Button
                                            key={item?.id}
                                            onClick={() => handleSelectCategory(item.id)}
                                            variant="outlined"
                                            fullWidth={true}
                                            style={{
                                                fontSize: '10px',
                                                width: '100%',
                                                color: selectedCategory == item.id ? "#F27024" : "#8991a4",
                                                borderColor: selectedCategory == item.id ? "#F27024" : "#8991a4",
                                                fontFamily: 'Poppins-Light',
                                                textTransform: 'initial',
                                                background: "",
                                                padding: '5px',
                                                marginTop: '0px',
                                                marginBottom: '0px'
                                            }}
                                            title={item?.attributes?.name}
                                        >
                                            {item?.attributes?.name.length > 10 ? item?.attributes?.name.substring(0, 10) + "..." : item?.attributes?.name }
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
            }
            {/* </Grid> */}
        </Box>
    )
}

export default function EventSetting(props: Props) {
    const {
        window,
        categoryList,
        errorMsg,
        isUrl,
        categoryLoading,
        handleSelectCategory,
        selectedCategory,
        handleBgImgChange,
        sort,
        startDateTime,
        endDateTime,
        handlestartDateTimechange,
        handleEndDateTimechange,
        type,
        from,
        comingFrom,
        coverUrl,
        startDateValidation,
        endDateValidation
    } = props;
    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = React.useState({});
    const [bold, setBold] = React.useState(false);
    const [italic, setItalic] = React.useState(false);
    const [underlined, setUnderlined] = React.useState(false);
    const [alignment, setAlignment] = React.useState('left');

    const handleBold = () => {
        setBold(!bold)
        props.getBold(!bold)
    }

    const handleItalic = () => {
        setItalic(!italic)
        props.getItalic(!italic)
    }

    const handleUnderlined = () => {
        setUnderlined(!underlined)
        props.getUnderlined(!underlined)
    }

    const handleAlignment = (value : any) => {
        setAlignment(value)
        props.getAlignment(value)
    }

    const handleClick = (id: any) => {
        //@ts-ignore
        setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    };


    const drawer = (
        <div style={{ overflowY: "scroll", overflowX: "hidden", height: '450px' }}>
            <div
                style={{
                    padding: '10px',
                }}
            >
                {
                    type == 'article' ?
                    <ListItem>
                    <div>
                        {/* @ts-ignore */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                border: (theme: any) => `1px solid ${theme.palette.divider}`,
                                borderRadius: 1,
                                bgcolor: 'background.paper',
                                color: 'text.secondary',

                            }}
                        >
                            <FormatBold
                                onClick={() => handleBold()}
                                style={{
                                    margin: '4px',
                                    background: bold ? '#000' : '',
                                    color: bold ? '#fff' : '',
                                    cursor: 'pointer'
                                }}
                            />
                            <FormatItalic
                                onClick={() => handleItalic()}
                                style={{
                                    margin: '4px',
                                    background: italic ? '#000' : '',
                                    color: italic ? '#fff' : '',
                                    cursor: 'pointer'
                                }}
                            />
                            <FormatUnderlined
                                onClick={() => handleUnderlined()}
                                style={{
                                    margin: '4px',
                                    background: underlined ? '#000' : '',
                                    color: underlined ? '#fff' : '',
                                    cursor: 'pointer'
                                }}
                            />
                            <Divider
                                orientation="vertical"
                                flexItem
                            />
                            <FormatAlignLeft
                                onClick={() => handleAlignment('left')}
                                style={{
                                    margin: '4px',
                                    background: alignment == 'left' ? '#000' : '',
                                    color: alignment == 'left' ? '#fff' : '',
                                    cursor: 'pointer'
                                }}
                            />
                            <FormatAlignCenter
                                onClick={() => handleAlignment('center')}
                                style={{
                                    margin: '4px',
                                    background: alignment == 'center' ? '#000' : '',
                                    color: alignment == 'center' ? '#fff' : '',
                                    cursor: 'pointer'
                                }}
                            />
                            <FormatAlignRight
                                onClick={() => handleAlignment('right')}
                                style={{
                                    margin: '4px',
                                    background: alignment == 'right' ? '#000' : '',
                                    color: alignment == 'right' ? '#fff' : '',
                                    cursor: 'pointer'
                                }}
                            />
                            <FormatAlignJustify
                                onClick={() => handleAlignment('justify')}
                                style={{
                                    margin: '4px',
                                    background: alignment == 'justify' ? '#000' : '',
                                    color: alignment == 'justify' ? '#fff' : '',
                                    cursor: 'pointer'
                                }}
                            />

                        </Box>
                    </div>

                </ListItem>
                    :
                    null
                }
                <ListItem style={{ marginTop: '20px' }} button onClick={() => handleClick(1)}>
                    {/* @ts-ignore */}
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <Typography
                            style={{
                                fontFamily: 'Poppins-Light',
                                fontSize: '16px',
                                color: '#353535',
                                marginRight: '40px'
                            }}
                        >
                            Start Date Time
                        </Typography>
                        {/* @ts-ignore */}
                        {open[1] ? 
                            <ExpandMoreRoundedIcon 
                                fontSize="medium" 
                            /> 
                            : 
                            <ChevronRightRoundedIcon 
                                fontSize="medium" 
                            />
                        }
                    </Box>
                </ListItem>
                {/* @ts-ignore */}
                <Collapse in={open[1]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* <ListItem style={{justifyContent: 'flex-end'}}>
                            {
                                getSchedulePostList(sort, handleSortChange, classes)
                            }
                        </ListItem> */}
                        {
                            getStartDateCalendar(sort, classes, startDateTime, handlestartDateTimechange)
                        }
                        {/* <ListItem style={{justifyContent: 'flex-end'}} >
                            {
                                getVisibility(classes, sort, handleSortChange)
                            }
                        </ListItem> */}
                        {/* <ListItem style={{justifyContent: 'flex-end'}} >
                            {
                                getAuthorList(sort, classes, handleSortChange, errorMsg, authorLoading, authorList)
                            }
                        </ListItem> */}
                    </List>
                </Collapse>
                <ListItem style={{ marginTop: '20px' }} button onClick={() => handleClick(12)}>
                    {/* @ts-ignore */}
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <Typography
                            style={{
                                fontFamily: 'Poppins-Light',
                                fontSize: '16px',
                                color: '#353535',
                                marginRight: '40px'
                            }}
                        >
                            End Date Time
                        </Typography>
                        {/* @ts-ignore */}
                        {open[12] ? 
                            <ExpandMoreRoundedIcon 
                                fontSize="medium" 
                            /> 
                            : 
                            <ChevronRightRoundedIcon 
                                fontSize="medium" 
                            />
                        }
                    </Box>
                </ListItem>
                {
                    props.endDateValidation ? <span style={{paddingLeft: "20px", color: 'red', fontSize: '14px', fontFamily: 'Poppins-Light'}}>{props.endDateValidation}</span> : null
                }
                {/* @ts-ignore */}
                <Collapse in={open[12]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* <ListItem style={{justifyContent: 'flex-end'}}>
                            {
                                getSchedulePostList(sort, handleSortChange, classes)
                            }
                        </ListItem> */}
                        {
                            getEndDateCalendar(sort, classes, endDateTime, handleEndDateTimechange)
                        }
                        {/* <ListItem style={{justifyContent: 'flex-end'}} >
                            {
                                getVisibility(classes, sort, handleSortChange)
                            }
                        </ListItem> */}
                        {/* <ListItem style={{justifyContent: 'flex-end'}} >
                            {
                                getAuthorList(sort, classes, handleSortChange, errorMsg, authorLoading, authorList)
                            }
                        </ListItem> */}
                    </List>
                </Collapse>
                <ListItem style={{ marginTop: '20px' }} button onClick={() => handleClick(2)}>
                    {/* @ts-ignore */}
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <Typography
                            style={{
                                fontFamily: 'Poppins-Light',
                                fontSize: '16px',
                                color: '#353535',
                                marginRight: '40px'
                            }}
                        >
                            Category
                        </Typography>
                        {/* @ts-ignore */}
                        {open[2] ? <ExpandMoreRoundedIcon fontSize="medium" /> : <ChevronRightRoundedIcon fontSize="medium" />}
                    </Box>
                </ListItem>
                {
                    props.catValidation ? <span style={{paddingLeft: "20px", color: 'red', fontSize: '14px', fontFamily: 'Poppins-Light'}}>{props.catValidation}</span> : null
                }
                {/* @ts-ignore */}
                <Collapse in={open[2]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem>
                            {
                                getCategoryList(selectedCategory, categoryLoading, categoryList, handleSelectCategory)
                            }
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem style={{ marginTop: '20px' }} button onClick={() => handleClick(3)}>
                    {/* @ts-ignore */}
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <Typography
                            style={{
                                fontFamily: 'Poppins-Light',
                                fontSize: '16px',
                                color: '#353535',
                                marginRight: '40px'
                            }}
                        >
                            Cover Image
                        </Typography>
                        {/* @ts-ignore */}
                        {open[3] ? <ExpandMoreRoundedIcon fontSize="medium" /> : <ChevronRightRoundedIcon fontSize="medium" />}
                    </Box>
                </ListItem>
                {
                    props.errorMsg ? <span style={{paddingLeft: "20px", color: 'red', fontSize: '14px', fontFamily: 'Poppins-Light'}}>{props.errorMsg}</span> : null
                }
                {/* @ts-ignore */}
                <Collapse in={open[3]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem >
                            {/* @ts-ignore */}
                            {
                                from == 'blog' ?
                                    <LetsReadBgImgDrop coverUrl={coverUrl} comingFrom={comingFrom} isUrl={isUrl} handleBgImgChange={handleBgImgChange} />
                                :   <BgImgDrop coverUrl={coverUrl} comingFrom={comingFrom} from={from} isUrl={isUrl} handleBgImgChange={handleBgImgChange} />
                            }
                        </ListItem>
                    </List>
                </Collapse>
            </div>
        </div>
        // </MuiPickersUtilsProvider>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* @ts-ignore */}
                <Drawer
                    container={container}
                    variant="permanent"
                    anchor='right'
                    open={true}
                    onClose={props.handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, 
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </div>
    );
}