import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  borderRadius: '8px'
};

const thumb = {
  display: 'inline-flex',
  borderRadius: '8px',
  marginBottom: 15,
  width: '184px',
  height: '184px',
  boxSizing: 'border-box'
};

const thumb2 = {
  display: 'inline-flex',
  borderRadius: '8px',
  marginBottom: 15,
  width: '100%',
  height: 'auto',
  boxSizing: 'border-box'
};

const videoThumb = {
  display: 'inline-flex',
  borderRadius: '8px',
  marginBottom: 15,
  width: '184px',
  height: '220px',
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  borderRadius: '8px',
  position: 'relative'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  borderRadius: '8px'
};

const getFilePath = (file) => {
  return(
    <div
      style={{
        color: '#000',
        fontSize: '20px',
        fontFamily: 'Poppins-SemiBold',
        marginTop: '10px'
      }}
    >
      {file.path}
    </div>
  )
}

const getButtons = (props) => {
  return (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        }}
    >
        <img 
            style={{
                marginRight: 'auto',
                marginLeft: 'auto'
            }}
            width={'100px'}
            src={require('../src/assets/uploadIcon.png')}
        />
        <span
            style={{
                color: '#353535',
                fontFamily: 'Poppins-SemiBold',
                fontSize: '20px',
                textAlign: 'center',
            }}
        >
            { props.type == 'video' ? 'Drag and drop your video files here to upload' : "Drag and drop your audio files here to upload"}
        </span>
        <span
            style={{
                color: '#8991a4',
                fontFamily: 'Poppins-Light',
                fontSize: '16px',
                textAlign: 'center',
                marginTop: '15px'
            }}
        >
            {props.type == 'video' ? 'Files supported - mp4, mkv.' : 'Files supported - mp3'}
        </span>
        <span
          style={{
            color: 'red',
            fontFamily: 'Poppins-Light',
            fontSize: '16px',
            textAlign: 'center',
            marginTop: '15px'
        }}
        >
          {props.error}
        </span>
    </div> 
  )
}

function PodcastDropZone(props) {
  const [files, setFiles] = useState([]);
  const [source, setSource] = useState(props.comingFrom)
  const [videoUrl, setVideoUrl] = useState(props.mainMediaUrl)
  const {getRootProps, getInputProps} = useDropzone({
    accept: props.type === 'video' ? {
      'video/mp4': ['.mp4', '.MP4', '.mkv'],
    } :
    {
      'audio/mp3': ['.mp3', '.MP3'],
    },
    onDrop: acceptedFiles => {
      setSource('')
      props.addInstructorProfile(acceptedFiles);
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  

  const removeProfilePic = () => {
    setFiles([])
    setSource('')
    props.setError(false)
  }
  
  const thumbs = files.map(file => (
    <div style={props.type == 'video' ? thumb : thumb2} key={file.name}>
      <div style={thumbInner}>
        {
          props.type == 'video' ?
          <video controls={false}>
            <source src={file.preview} type="video/mp4" />
          </video>
          :
          getFilePath(file)
        }
      </div>
    </div>
  ));

  useEffect(() => {
    setVideoUrl(props.mainMediaUrl)
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="container" style={{cursor: 'pointer'}}>
      <div
        style={{
          position: 'relative'
        }}
      >
        <aside style={thumbsContainer}>
        {
          source == 'edit' ?
              <>
                  <div style={props.type == 'video' ? videoThumb : thumb2}>
                    <div style={thumbInner}>
                      {
                        props.type == 'video' ?
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            borderRadius: '16px'
                          }}
                        >
                          <video controls={false} preload="metadata">
                            <source src={videoUrl } type="video/mp4" />
                          </video>
                        </div>
                        :
                        <div
                          style={{
                            color: '#000',
                            fontSize: '20px',
                            fontFamily: 'Poppins-SemiBold',
                            marginTop: '10px'
                          }}
                        >
                          {props.mainMediaUrl}
                        </div>
                      }
                    </div>
                    <div
                      style={{
                          position: 'absolute',
                          display: 'flex',
                          top: '0px',
                          bottom: '0px',
                          left: '50px',
                          right: '0px',
                          justifyContent: 'start',
                          alignItems: 'center'
                      }}
                  >
                      <div
                          style={{
                              marginRight: '5px',
                          }}
                      >
                          <div {...getRootProps({className: 'dropzone'})}>
                              <input {...getInputProps()} />
                              <div
                                  style={{
                                      background: '#fff',
                                      borderRadius: '50%',
                                  }}
                              >
                                  <img 
                                      style={{
                                          marginRight: 'auto',
                                          marginLeft: 'auto'
                                      }}
                                      width={'40px'}
                                      src={require('../src/assets/uploadIcon.png')}
                                  />
                              </div> 
                          </div>
                      </div>
                      <div
                          style={{
                              marginLeft: '5px'
                          }}
                      >
                          <div
                              style={{
                              background: '#fff',
                              borderRadius: '50%',
                              }}
                          >
                              <img 
                                  style={{
                                      marginRight: 'auto',
                                      marginLeft: 'auto'
                                  }}
                                  width={'40px'}
                                  src={require('../src/assets/delete.png')}
                                  onClick={() => removeProfilePic()}
                              />
                          </div> 
                      </div>
                  </div>
                  </div>
              </>
          :   thumbs
      }
          {!files.length ?
            null
          :
          <>
            <div
            style={{
              position: 'absolute',
              top: '37%',
              left: '30px'
            }}
          >
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
                  <div
                      style={{
                        background: '#fff',
                        borderRadius: '50%',
                      }}
                  >
                      <img 
                          style={{
                              marginRight: 'auto',
                              marginLeft: 'auto'
                          }}
                          width={'40px'}
                          src={require('../src/assets/uploadIcon.png')}
                      />
                  </div> 
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              top: '37%',
              left: '106px'
            }}
          >
            <div
                style={{
                  background: '#fff',
                  borderRadius: '50%',
                }}
            >
                <img 
                    style={{
                        marginRight: 'auto',
                        marginLeft: 'auto'
                    }}
                    width={'40px'}
                    src={require('../src/assets/delete.png')}
                    onClick={() => removeProfilePic()}
                />
            </div> 
          </div>
          </>
          }
        </aside>
      </div>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        {
          files.length || source == 'edit' ?
            null
          :
            <div 
                style={{
                    borderRadius: '8px',
                    border: '1px solid rgba(82,92, 229, 0.12)',
                    padding: '24px 172px 24px 171px',
                }}
            >
              {
                getButtons(props)
              }
            </div>

        }
      </div>
    </section>
  );
}

export default PodcastDropZone;
