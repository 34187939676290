import React from "react";

// Customizable Area Start
import { StyleSheet, View, ScrollView } from "react-native";

import { Grid, Box, Typography, Button } from "@material-ui/core";
import CustomCard from "../../../components/src/CustomCard";
import CustomInput from "../../../components/src/CustomInput";
import CustomButton from "../../../components/src/CustomButton";
import { palette } from "../../../components/src/theme";
import Divider from "../../../components/src/Divider";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginController";
import LoginWithGoogle from "../../../components/src/GoogleLogin";
import LoginWithFacebbok from "../../../components/src/FacebookLogin";
import AlertModal from "../../../components/src/AlertModal";

const style = (theme: any) => ({
  container: {
    flex: 1,
    marginTop: "20px",
    // padding: 60,
    // paddingTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#ffffffff"
  },
  mainGrid: {
    padding: "60px",
    paddingTop: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      paddingTop: "20px"
    }
  },
  welcomeText: {
    paddingLeft: "65px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      textAlign: "center"
    }
  },
  cardWrap: {
    padding: "20px",
    paddingRight: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px"
    }
  },
  cardPad: {
    padding: "50px",
    boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.13)",
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px"
    }
  },
  parentBox: {
    display: 'flex',
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
    [theme.breakpoints.down("xs")]: {
      display: 'none'
    }
  }
});

class EmailAccountLoginBlock extends EmailAccountLoginController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  closeAlertBox = () => {
    this.setState({
      openAlert: false
    });
  };

  render() {
    const { classes } = this.props;
    return (
      // Required for all blocks
      <div className={classes.container}>
        {this.state.openAlert ? (
          <AlertModal
            open={this.state.openAlert}
            data={this.state.errorData}
            onClose={() => this.closeAlertBox()}
          />
        ) : null}
        {/* Required for all blocks */}
        {/* Customizable Area Start */}
        {/* Merge Engine UI Engine Code */}
        <div>
          <Grid container className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={6}>
              <div
                style={{
                  flex: 1,
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  padding: "20px"
                }}
              >
                <div className={classes.welcomeText}>
                  <Typography
                    variant="h3"
                    style={{
                      fontFamily: "Poppins-ExtraBold",
                      fontSize: "4vw"
                    }}
                  >
                    Welcome back!
                  </Typography>
                </div>
                <div
                  style={{
                    marginTop: "50px",
                    flex: 1,
                    justifyContent: "center"
                  }}
                  className={classes.parentBox}
                >
                  <img
                    width={"60%"}
                    src={require("../assets/loginImage.png")}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div
                className={classes.cardWrap}
              >
                <CustomCard className={classes.cardPad}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div>
                      <Typography
                        variant="h3"
                        style={{
                          fontFamily: "Poppins-SemiBold",
                          fontSize: "3vw"
                        }}
                      >
                        Sign In
                      </Typography>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginTop: "20px"
                      }}
                    >
                      <CustomInput
                        id={'emailInput'}
                        passwordfield={false}
                        placeholder={"Email"}
                        icon={require("../assets/email-icon.png")}
                        iconHeight={"24px"}
                        onChange={(e: any) =>
                          this.handleInputChange("email", e)
                        }
                      />
                    </div>
                    <div
                      style={{
                        width: "100%"
                      }}
                    >
                      <CustomInput
                        id={'txtInputPassword'}
                        passwordfield={true}
                        placeholder={"Password"}
                        icon={require("../assets/lock-icon.png")}
                        iconHeight={"24px"}
                        endIcon={require("../assets/eye-icon.png")}
                        onChange={(e: any) =>
                          this.handleInputChange("password", e)
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: '30px'
                      }}
                    >
                      <Typography
                        id={'btnForgotPassword'}
                        style={{
                          fontFamily: "Poppins-Light",
                          fontSize: "16px",
                          fontWeight: "normal",
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          this.props.navigation.navigate("ForgotPassword")
                        }
                      >
                        Forgot Password?
                      </Typography>
                    </div>
                  </div>
                  <div style={{marginTop: '30px'}}>
                    <CustomButton
                      title={this.state.loading ? "Signing In..." : "Sign In"}
                      color={palette.primary.main}
                      textColor={"#FFFFFF"}
                      fullWidth={true}
                      height={"54px"}
                      fontSize={"20px"}
                      onClick={() => this.doEmailLogIn()}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: '20px'
                    }}
                  >
                    <div
                      style={{
                        flex: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Poppins-Light",
                          fontSize: "14px",
                          fontWeight: "normal"
                        }}
                      >
                        or continue with
                      </Typography>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end"
                      }}
                    >
                      <LoginWithFacebbok
                        getProfile={(profile: any) =>
                          this.getFacebookProfile(profile)
                        }
                      />
                      <LoginWithGoogle
                        getProfile={(profile: any) =>
                          this.getGoogleProfile(profile)
                        }
                      />
                    </div>
                  </div>
                  <div style={{marginTop: '20px'}}>
                    <Divider bgColor={palette.primary.main} />
                  </div>
                  <div
                    style={{
                      flex: 2,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: '20px'
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Poppins-Light",
                        fontSize: "14px",
                        fontWeight: "normal"
                      }}
                    >
                      Don't have an account?
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Poppins-SemiBold",
                        fontSize: "14px",
                        marginLeft: "10px",
                        color: palette.primary.main
                      }}
                    >
                      <Button
                        color="inherit"
                        style={{
                          textTransform: "none"
                        }}
                        id={'signupBtn'}
                        onClick={() =>
                          this.props.navigation.navigate(
                            "EmailAccountRegistration"
                          )
                        }
                      >
                        Sign Up
                      </Button>
                    </Typography>
                  </div>
                </CustomCard>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* Merge Engine UI Engine Code */}
        {/* Customizable Area End */}
        {/* </TouchableWithoutFeedback> */}
      </div>
    );
  }
}

export default withStyles(style)(EmailAccountLoginBlock);

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: "20px",
    // padding: 60,
    // paddingTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#ffffffff"
  }
});
// Customizable Area End
