import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End
export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean;
  openSetting: boolean;
  userList: any;
  tabs: any;
  loading: boolean;
  modalMsg: string;
  openModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddPeopleController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetUserListId: string = "";
  apiChangePermissionId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mobileOpen: false,
      openSetting: false,
      userList: "",
      tabs: 0,
      loading: false,
      modalMsg: "",
      openModal: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getUserList("instructor");
  }

  setUserListData = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        userList: responseJson.data.data,
        loading: false
      });
    } else {
      this.setState({
        loading: false
      });
    }
  };

  setChangePermissionData = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState(
        {
          loading: false,
          modalMsg: "Permission changed successfully!",
          openModal: true
        },
        () => {
          this.getUserList("instructor");
        }
      );
    } else {
      this.setState({
        loading: false,
        modalMsg: responseJson.errors,
        openModal: true
      });
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetUserListId) {
          this.setUserListData(responseJson);
        }
        if (apiRequestCallId === this.apiChangePermissionId) {
          this.setChangePermissionData(responseJson);
        }
      }
    }
  }

  closeAlertBox = () => {
    this.setState({
      openModal: false
    });
  };

  changePermission = (permission: any, id: any) => {
    this.setState({
      loading: true
    });
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const data = {
      id: id,
      can_manage_profile: permission
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePermissionId = requestMessage.messageId;
    const hitURL = `${configJSON.changePermissionApi}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  hanldeSettingToggle = () => {
    this.setState({ openSetting: !this.state.openSetting });
  };

  getUserList = (type: any) => {
    this.setState({
      loading: true,
      userList: []
    });

    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserListId = requestMessage.messageId;
    const hitURL = `${configJSON.userListGetApi}?type=${type}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
