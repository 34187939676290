import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { CategoryType } from "../../../ContentManagement/src/Interfaces.web"
import { Sort, DateTime } from "../Interfaces.web";
import moment from "moment";
// Customizable Area End
export const configJSON = require("../config.js");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    mobileOpen: boolean;
    loading: boolean;
    errorMsg: string;
    getCategoryLoading: boolean;
    categoryList: CategoryType[];
    authorList: any[];
    authorLoading: boolean;
    selectecCategory: string;
    status: string;
    openSetting: boolean;
    sort: Sort;
    startDateTime: DateTime;
    endDateTime: DateTime;
    openAlert: boolean;
    dataCreationMsg: string;
    inputError: any;
    previewModal: boolean;
    open: any;
    bold: boolean;
    underlined: boolean;
    italic: boolean;
    alignment: string;
    title: any;
    summary: any;
    bgImage: any;
    podcastMedia: any;
    podcastType: any;
    catValidation: string;
    createLoading: boolean;
    presenterList: any;
    presenterName: any;
    enablePresenterForm: boolean;
    presenterAddLoading: boolean;
    addPresenterName: any;
    addPresenterAbout: any;
    addPresenterProfile: any;
    getPresentersLoading: any;
    allEventsList: any;
    getMainnstructorLoading: boolean;
    mainInstructorList: any;
    selectedMainInstructor: any;
    startDateErr: any;
    endDateErr: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddEventController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetDataCallId: string = "";
    getAllCategoryId: string = "";
    getAllAuthorId: string = "";
    createBlogId: string = "";
    createEventCallId: string = '';
    getPresenterId: string = '';
    addPresenterId: string = '';
    getEventsListId: string = '';
    getMainInstructorId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // getName(MessageEnum.NavigationToSingleArticle),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            mobileOpen: false,
            loading: false,
            errorMsg: '',
            categoryList: [],
            authorList: [],
            authorLoading: false,
            getCategoryLoading: false,
            selectecCategory: "",
            status: "",
            openAlert: false,
            dataCreationMsg: "",
            openSetting: false,
            sort: {
                publish: 'schedule_post',
                visibility: 'public_user',
                author: 92
            },
            startDateTime: {
                date: new Date(),
                time: new Date(),
            },
            endDateTime: {
                date: new Date(),
                time: new Date(),
            },
            inputError: {},
            previewModal: false,
            open: false,
            bold: false,
            underlined: false,
            italic: false,
            alignment: 'left',
            title: '',
            summary: '',
            bgImage: [],
            podcastMedia: [],
            podcastType: 'video',
            catValidation: '',
            createLoading: false,
            presenterList: '',
            presenterName: '',
            enablePresenterForm: false,
            addPresenterProfile: '',
            getPresentersLoading: false,
            presenterAddLoading: false,
            addPresenterAbout: '',
            addPresenterName: '',
            allEventsList: [],
            getMainnstructorLoading: false,
            mainInstructorList: [],
            startDateErr: '',
            endDateErr: '',
            selectedMainInstructor: localStorage.getItem('userType') == 'instructor' ? localStorage.getItem('userId') : '',
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {
                if (apiRequestCallId == this.getMainInstructorId) {
                    if (responseJson && !responseJson.error && responseJson.accounts) {
                        if (responseJson.accounts.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                getMainnstructorLoading: false,
                                // loading: false,
                                mainInstructorList: [],
                                // getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                // getCategoryLoading: false,
                                getMainnstructorLoading: false,
                                mainInstructorList: responseJson?.accounts,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                // getCategoryLoading: false,
                                getMainnstructorLoading: false
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                // getCategoryLoading: false,
                                getMainnstructorLoading: false
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getEventsListId) {
                    if(responseJson && !errorReponse){
                      let firstEvents = responseJson.data.upcoming_streams.concat(responseJson.data.past_streams);
                      let secEvents = firstEvents.concat(responseJson.data.today_streams)
                      let events = secEvents.map((item: any, index: any) => {
                        return (
                          {
                            id: item.id,
                            title: item.attributes.title,
                            date: item.attributes.date,
                            // start:  `${item.attributes.date}T${item.attributes.start_time}`,
                            // end: `${item.attributes.date}T${item.attributes.end_time}`,
                            // startStr: item.attributes.start_time,
                            // endStr: item.attributes.end_time,
                            // allDay: false
                          }
                        )
                      })
                      this.setState({
                        allEventsList: events
                      })
                    }
                  }
                if (apiRequestCallId == this.getAllAuthorId) {
                    if (responseJson && !responseJson.error && responseJson.accounts) {
                        if (responseJson.accounts.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                authorLoading: false,
                                // loading: false,
                                authorList: [],
                                // getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false,
                                authorList: responseJson?.accounts,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.createEventCallId) {
                    if (responseJson && !responseJson.error) {
                        if (responseJson.data.type !== "error") {
                            this.setState({
                                ...this.state,
                                dataCreationMsg: "Event created successfully!",
                                // loading: false,
                                openAlert: true,
                                createLoading: false,
                                title: '',
                                summary: '',

                                // categoryList: responseJson?.data,
                            }, () => {
                                this.getEventsList()
                            });
                        }
                        else {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "Please fill required data",
                                errorMsg: "",
                                createLoading: false
                            })
                        }

                    } else {
                        if (responseJson.error) {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "",
                                errorMsg: "Internal Server Error",
                                createLoading: false
                            })
                        }
                        else if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                dataCreationMsg: "",
                                openAlert: true,
                                createLoading: false
                                // loading: false,
                            });
                        } else {
                            this.setState({
                                openAlert: true,
                                errorMsg: errorReponse,
                                dataCreationMsg: "",
                                createLoading: false
                                // loading: false,
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getAllCategoryId) {
                    if (responseJson && !responseJson.error && responseJson.data) {
                        if (responseJson.data.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                // loading: false,
                                categoryList: [],
                                getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                getCategoryLoading: false,
                                categoryList: responseJson?.data,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                getCategoryLoading: false,
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getPresenterId) {
                    if(responseJson.data.length){
                        this.setState({
                            presenterList: responseJson.data,
                            getPresentersLoading: false
                        })
                    } else{
                        this.setState({
                            getPresentersLoading: false
                        })
                    }
                }
                if(apiRequestCallId === this.addPresenterId){
                    if (responseJson && !responseJson.error) {
                        if (responseJson.data.type !== "error") {
                            this.setState({
                                ...this.state,
                                dataCreationMsg: "Presenter added successfully!",
                                // loading: false,
                                openAlert: true,
                                errorMsg: "",
                                addPresenterName: '',
                                addPresenterAbout: '',
                                addPresenterProfile: '',
                                enablePresenterForm: false,
                                presenterAddLoading: false
                                // categoryList: responseJson?.data,
                            }, () => {
                                this.getPresenters();
                            });
                        }
                        else {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "Please fill required data",
                                errorMsg: "",
                            })
                        }

                    } else {
                        if (responseJson.error) {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "",
                                errorMsg: "Internal Server Error",
                            })
                        }
                        else if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                dataCreationMsg: "",
                                openAlert: true,
                                // loading: false,
                            });
                        } else {
                            this.setState({
                                openAlert: true,
                                errorMsg: errorReponse,
                                dataCreationMsg: "",
                                // loading: false,
                            });
                        }
                    }
                }
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.getCategory()
        this.getAuthorList()
        this.getPresenters()
        this.getEventsList()
        this.getMainInstructors()
    }

    // Customizable Area End

    // Customizable Area Start

    getFullDate = (date: any) => {
        let d = new Date(date.date)
        let h = moment(date.time).format('HH')
        let m = moment(date.time).format('mm')
        let s = moment(date.time).format('ss')
        d.setHours(Number(h),Number(m),Number(s))

        return d
    }

    fieldValidation = () => {
        let error: any = {}
        const {
            title,
            summary,
            selectedMainInstructor,
            selectecCategory,
            startDateTime,
            endDateTime
        } = this.state


        let strFullDate = this.getFullDate(startDateTime);
        let endFullDate = this.getFullDate(endDateTime);

        if(strFullDate >= endFullDate){
            this.setState({
                openSetting: true,
                endDateErr: 'End datetime should be after start date/time'
            })
            error.endDateErr = 'End datetime should be after start date/time'
        }
        if (!title) {
            error.title = "Please Provide Title"
        }
        if (!selectedMainInstructor) {
            error.instructor = "Please select presenter"
        }
        if (!summary) {
            error.summary = "Please Provide Description"
        }
        if(!selectecCategory){
            this.setState({
                openSetting: true,
                catValidation: 'Please select category'
            })
            error.cat = 'Please select category'
        }
        if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
            this.setState({ inputError: {} })
            this.createEvent()
        }
        else {
            this.setState({ inputError: error })
        }
    }

    getCategory = () => {
        this.setState({ getCategoryLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllCategoryId = requestMessage.messageId;
        const hitURL = `${configJSON.categoryUrl}?type=podcast_category`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            hitURL
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getAuthorList = () => {
        this.setState({ getCategoryLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllAuthorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAuthorUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getEventsList = () => {
            const userToken = localStorage.getItem("token");
            const header = {
                "Content-Type": configJSON.contentType,
                token: userToken,
            };
    
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
    
            this.getEventsListId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.getEventsListApi
            );
    
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
    
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getApiMethodType
            );
    
            runEngine.sendMessage(requestMessage.id, requestMessage);
      }

    

    createEvent = () => {
        this.setState({
            createLoading: true
        })
        const { 
            title, 
            summary,
            selectecCategory,
            startDateTime,
            endDateTime,
            sort,
            bgImage,
            selectedMainInstructor
        } = this.state;

        let strFullDate = this.getFullDate(startDateTime)
        let endFullDate = this.getFullDate(endDateTime)
        const formData = new FormData();
        formData.append('live_streams[title]', title);
        formData.append('live_streams[description]', summary);
        formData.append('live_streams[summary]', summary);
        const posterBlob = new Blob(bgImage)
        formData.append('live_streams[cover_image]', posterBlob);
        formData.append('live_streams[category_id]', selectecCategory);
        formData.append('live_streams[status]', 'published')
        formData.append('live_streams[publish_timing]', 'immediately')
        formData.append('live_streams[visibility]', sort.visibility)
        // @ts-ignore
        formData.append('live_streams[instructor_id]', selectedMainInstructor)
        // @ts-ignore
        formData.append('live_streams[date]', strFullDate)
        // @ts-ignore
        formData.append('live_streams[start_time]', moment(startDateTime.time).format('HH:mm'))
        // @ts-ignore
        formData.append('live_streams[end_time]', moment(endDateTime.time).format('HH:mm'))
        // @ts-ignore
        formData.append('live_streams[end_date]', endFullDate)
    
        const header = {
          token: localStorage.getItem("token")
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createEventCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.createEventApi
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
    
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

    handleTitleChange = (e: any) => {
        this.setState({
            title: e.target.value
        })
    }

    setMedia = (acceptedFiles: any) => {
        this.setState({
            podcastMedia: acceptedFiles
        })
    }

    handleSummaryChange = (e: any) => {
        this.setState({
            summary: e.target.value
        })
    }

    handleChangeType = (e: any) => {
        this.setState({
            podcastType: e.target.value,
            podcastMedia: ''
        })
    }

    handleBgImgChange = (value: any[], files: any[]) => {
        this.setState({
            bgImage: files
        });
    }

    handleCategorySelect = (id: string) => {
        this.setState({ selectecCategory: id })
    }

    hanldeSettingToggle = () => {
        this.setState({ openSetting: !this.state.openSetting })
    }

    handleSortChange = (event: any, name: string) => {
        const { value } = event.target;
        this.setState({
            sort: {
                ...this.state.sort,
                [name]: value
            }
        });
    };

    handlestartDateTimeChange = (value: Date, name: string) => {
        this.setState({
            startDateTime: {
                ...this.state.startDateTime,
                [name]: value,
            }
        })
    }

    handleEndDateTimeChange = (value: Date, name: string) => {
        this.setState({
            endDateTime: {
                ...this.state.endDateTime,
                [name]: value,
            }
        })
    }

    handlePublish = () => {
        this.setState((prev_stat) => {
            return {
                ...prev_stat,
                status: "published"
            }
        }, () => this.fieldValidation())
    }

    closeAlertBox = () => {
        this.setState({
            openAlert: false
        });
    };

    handlePreviewOpen = () => {
        this.setState({ previewModal: true })
    }

    handleModalClose = () => {
        this.setState({ previewModal: false })
    }

    addPresenterProfilePic = (acceptedFiles: any) => {
        this.setState({
            addPresenterProfile: acceptedFiles[0]    
        });
    }

    handlePresenterChange = (event: any, name: string) => {
        const { value } = event.target;
        if(name == 'addPresenterName'){
            this.setState({
                addPresenterName: value
            });
        } else {
            this.setState({
                addPresenterAbout: value
            });
        }
    };

    getMainInstructors = () => {
        this.setState({ getMainnstructorLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getMainInstructorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAuthorUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getPresenters = () => {
        this.setState({ getPresentersLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getPresenterId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCoInstructorApi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addPresenter = () => {
        if(!this.state.addPresenterName){
            let error: any = {};
            error.addInsName = 'Please provide presenter name!';
            this.setState({
                inputError: error
            })
            return;
        }
        if(!this.state.addPresenterProfile){
            let error: any = {};
            error.addProfilePic = 'Please upload a profile picture!';
            this.setState({
                inputError: error
            })
            return;
        }
        if(!this.state.addPresenterAbout){
            let error: any = {};
            error.addInsAbout = 'Please provide presenter about!';
            this.setState({
                inputError: error
            })
            return;
        }
        this.setState({ 
            presenterAddLoading: true,
        })
        const userToken = localStorage.getItem("token");
        const formData = new FormData();
        formData.append('instructor[name]', this.state.addPresenterName)
        formData.append('instructor[about]', this.state.addPresenterAbout)
        formData.append('instructor[profile_image]', this.state.addPresenterProfile)

        const header = {
            // "Content-Type": "multipart/form-data",
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addPresenterId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addInstructorApi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End

}
