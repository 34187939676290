import React from "react";
import { Text, StyleSheet, View, TouchableOpacity } from "react-native";
import {Box} from "@material-ui/core"; 

import Loader from "../../../components/src/Loader";
import SocialMediaAccountWebController, {
  Props,
  configJSON
} from "../../social-media-account/src/SocialMediaAccountWebController";

import CustomFacebookLogInButton from "../../social-media-account/src/CustomFacebookLogInButton";
import CustomGoogleLogInButton from "../../social-media-account/src/CustomGoogleLogInButton";

class SocialMediaAccountLoginScreen extends SocialMediaAccountWebController {
  static SocialMediaAccountLoginScreen: SocialMediaAccountLoginScreen;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      isRegistration: false
    };
  }

  render() {
    const { loading } = this.state;
    return (
      <View style={styles.container}>
        <Loader loading={loading} />
        {/* Customizable Area Start */}

       <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
       >
        {/* <CustomFacebookLogInButton
            testID="btnFacebookLogIn" //Merge Engine::From BDS
            appId="1010368702955176" //Merge Engine::From SDS
            loginFacebookButtonText={configJSON.loginFacebookButtonText} //UI Engine::From Sketch
            {...this.btnFacebookLogInProps} //Merge Engine::From BDS - {...this.testIDProps}
          /> */}

          <CustomGoogleLogInButton
            testID="btnGoogleLogIn" //Merge Engine::From BDS
            style={styles.googleStyle} //UI Engine::From Sketch
            loginGoogleButtonText={configJSON.loginGoogleButtonText} //UI Engine::From Sketch
            googleButtonImageStyle={styles.googleButtonImageStyle} //UI Engine::From Sketch
            {...this.btnGoogleLogInProps} //Merge Engine::From BDS - {...this.testIDProps}
          />
       </Box>

        
        {/* Customizable Area Start */}
      </View>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5FCFF"
  },
  logInButtonContainer: {
    overflow: "hidden",
    display: undefined,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "flex-start",
    alignItems: "center",
    shadowColor: "black",
    shadowOpacity: 0.3,
    shadowRadius: 8,
    height: 40,
    width: 205,
    marginTop: 16,
    elevation: 6,
    backgroundColor: "#ffffff"
  },
  googleStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "black",
    shadowOpacity: 0,
    elevation: 0,
    shadowRadius: 8,
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: "#ffffff",
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  googleButtonImageStyle: {
    width: 38,
    height: 38
  },
});
// Customizable Area End

export default SocialMediaAccountLoginScreen;
