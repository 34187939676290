import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
} from '@material-ui/core';
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import FaqPageController, {
    Props
} from "./FaqPageController.web";
import FaqSearchBar from "../../../components/src/FaqSearchBar";
import { TopicProps } from "./Interface.web";
import FaqListItem from "../../../components/src/FaqListItem";

// Customizable Area End


class FaqPage extends FaqPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const { faqsListData } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.mainWrapper}>
                <Box>
                    <Typography style={{ fontSize: "1rem", fontFamily: "Poppins-Light" }} gutterBottom>
                        <span style={{ color: "#d56036", cursor: "pointer" }}
                            data-testid="gotoHome"
                            onClick={() => this.props.navigation.navigate("Home")}>
                            Home
                        </span>
                        <span style={{ color: "#d56036" }}> / </span>
                        <span style={{ color: "#8991a4" }}>Help</span></Typography>
                    <Box className={classes.whiteBorder} />
                </Box>
                <Box style={{ width: "100%", textAlign: "center", marginBottom: "5%" }}>
                    <Typography style={{ fontFamily: "Poppins-Bold", fontSize: "48px", marginBottom: "20px" }}>Frequently asked questions</Typography>
                    <Typography style={{ fontFamily: "Poppins-Light", fontSize: "24px", color: "#545454" }}>Have any questions? We are here to help</Typography>
                </Box>
                <Box style={{ padding: '1% 2%' }}>
                    <FaqSearchBar getSearchResult={this.getSearchedResult} />
                    <Box style={{ marginTop: "4%" }}>
                        {
                            faqsListData.length > 0 ?
                                faqsListData.map((topic: TopicProps) => (
                                    <Box key={topic.topic} style={{ marginBottom: "60px" }}>
                                        <FaqListItem topic={topic.topic} faqs={topic.faqs} />
                                    </Box>
                                ))
                                :
                                <Typography
                                    style={{
                                        fontFamily: "Poppins-Light",
                                        fontSize: "24px", color: "#545454",
                                        textAlign: "center"
                                    }}
                                >No data found</Typography>
                        }
                    </Box>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            mainWrapper: {
                fontFamily: 'Poppins',
                padding: '2% 8%',
                [theme.breakpoints.down("xs")]: {
                    padding: '5% 12%'
                },
                background: '#fff',
            },

            whiteBorder: {
                width: "100%",
                height: "1px",
                margin: "0 0 45px",
                backgroundColor: "#e6e6e6"
            },
        })
    )(FaqPage)
)
// Customizable Area End
