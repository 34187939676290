import React from "react";

// Customizable Area Start
import { 
  Container, 
  Box,   
  Button,
  Grid,
  Typography
} from '@material-ui/core';
import { createTheme, withStyles } from '@material-ui/core/styles';
import { playerBg } from "./assets";
import { audio } from "./assets";
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
// @ts-ignore
import AudioPlayer from "../../../components/src/AudioPlayer";
import { Link } from "react-router-dom";
// Customizable Area End

import AudioMusicController, {
  Props,
} from "./AudioMusicController";
import Breadcrumb from "../../../components/src/BreadCrumb";
import CustomCard from "../../../components/src/CustomCard";
import { palette } from "../../../components/src/theme";
import CustomButton from "../../../components/src/CustomButton";
import ShareModal from "../../../components/src/ShareModal";

const styles = (theme: any) => ({
  otherPodcastHead: {
    fontSize: '30px',
    fontFamily: 'Poppins-SemiBold',
    [theme.breakpoints.down("md")]: {
      fontSize: '24px',
      fontFamily: 'Poppins-SemiBold',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '18px',
      fontFamily: 'Poppins-SemiBold',
    },
  },
  otherPodcastCardHead: {
    fontSize: '20px',
    fontFamily: 'Poppins-Light',
    color: '#ffffff',
    [theme.breakpoints.down("sm")]: {
      fontSize: '16px',
      fontFamily: 'Poppins-Light',
      color: '#ffffff',
    },
  },
  otherPodcastCardDesc: {
    fontSize: '14px',
    fontFamily: 'Poppins-Light',
    color: '#ffffff',
    [theme.breakpoints.down("sm")]: {
      fontSize: '12px',
      fontFamily: 'Poppins-Light',
      color: '#ffffff',
    },
  }
});

class AudioMusic extends AudioMusicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // @ts-ignore
    this.audioRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start

  closeShare = () => {
    this.setState({
      openShare: false
    })
  }

  shareOpen = () => {
    this.setState({
      openShare: true
    })
  }

  getTotalTime = (totalTime: any, playing: any) => {
    this.setState({
      totalTime: totalTime,
      playing: playing
    })
  }
  // Customizable Area End

  render() {
    const {classes} = this.props
    const { audioDetails, matches } = this.state;
    return (
      // Customizable Area Start
      <>
      <ShareModal 
        shareClose={this.closeShare}
        openShare={this.state.openShare}
        shareTitle={audioDetails.title}
        shareLink={window.location.href}
        shareDesc={audioDetails.description}
      />
      {/* @ts-ignore */}
      <Box>
        {/* @ts-ignore */}
        <Box
          style={{
            backgroundImage: `url(${playerBg})`,
            minHeight: '400px' ,
            backgroundSize: 'cover'
          }}
        >
          {/* @ts-ignore */}
          <Box
            style={{
              background: 'rgba(129, 212, 250, 0.7)',
              minHeight: '100%',
              minWidth: '100%',
              paddingTop: '20px',
              paddingBottom: '20px'
            }}
          >
            <Container>
              <Breadcrumb
                base={'Home'}
                url1={{
                  base: `Let's Talk`,
                  link: 'podcast'
                }}
                url2={{
                  path: audioDetails.title && audioDetails.title.length > 30 ? audioDetails.title.substring(0, 30) + "..." : audioDetails.title,
                  link: ''
                }}
              />
              {/* @ts-ignore */}
              <Box 
                mt={5}
                p={matches ? 10 : 2}
                pb={matches ? 10 : 4}
              >
                <Container>
                  <Grid container spacing={5}>
                    <Grid item md={4} sm={12} xs={12}>
                      <CustomCard
                        padding="0px"
                        boxShadow="0 0 27px 1px rgba(0, 0, 0, 0.32)"
                        style={{
                          maxHeight: '50vh',
                          overFlow: 'hidden',
                          minHeight: '50vh'
                        }}
                      >
                        {
                          audioDetails.poster ?
                          <img 
                            width={'100%'} 
                            style={{
                              minWidth: '100%',
                              minHeight: '50vh',
                            }}
                            src={audioDetails.poster} 
                          />
                          : <img 
                              width={'100%'} 
                              src={audio} 
                              style={{
                                minWidth: '100%',
                                minHeight: '50vh'
                              }}
                            />
                        }
                      </CustomCard>
                    </Grid>
                    <Grid item md={8} sm={12} xs={12}>
                      {/* @ts-ignore */}
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                        mt={5}
                      >
                        {/* @ts-ignore */}
                        <Box>
                          <Typography
                            style={{
                              color: palette.primary.main,
                              fontFamily: 'Poppins-SemiBold',
                            }}
                          >
                            {audioDetails.category?.name ? audioDetails.category?.name : "Social Service" }
                          </Typography>
                        </Box>
                        {/* @ts-ignore */}
                        <Box>
                          <Typography
                            style={{
                              fontFamily: 'Poppins-SemiBold',
                              fontSize: '36px'
                            }}
                            title={audioDetails.title}
                          >
                            {audioDetails.title && audioDetails.title.length > 30 ? audioDetails.title.substring(0, 30) + "..." : audioDetails.title }
                          </Typography>
                        </Box>
                        {/* @ts-ignore */}
                        <Box
                          style={{
                            display: 'flex'
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: 'Poppins-Light',
                              fontSize: '12px'
                            }}
                          >
                            In this conversation
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Poppins-SemiBold',
                              fontSize: '14px',
                              marginLeft: '5px',
                              color: palette.primary.main,
                              textTransform: 'capitalize'
                            }}
                          >
                            {audioDetails.instructor?.name}
                            {
                              audioDetails?.tag_others?.data.length ? audioDetails?.tag_others?.data.map((item: any) => {
                                return (
                                  <span>, {item.attributes.full_name}</span>
                                )
                              })
                              : null
                            }
                          </Typography>
                        </Box>
                        {/* @ts-ignore */}
                        <Box
                          style={{
                            display: 'flex'
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: 'Poppins-Light',
                              fontSize: '12px'
                            }}
                          >
                            {this.state.totalTime} minutes
                          </Typography>
                        </Box>
                        {/* @ts-ignore */}
                        <Box
                          style={{
                            display: 'flex',
                          }}
                          mt={2}
                        >
                          {/* @ts-ignore */}
                          <Box
                            mt={1}
                          >
                            <CustomButton
                              title={this.state.playing ? 'Pause' : 'Play'}
                              color={palette.primary.main}
                              textColor={"#FFFFFF"}
                              pl={'40px'}
                              pr={'40px'}
                              height={42}
                              // @ts-ignore
                              onClick={() => this.audioRef.current.getAlert()}
                            />
                          </Box>
                          {/* @ts-ignore */}
                          <Box
                            ml={2}
                            onClick={this.shareOpen}
                            style={{
                              marginTop: '3px',
                              cursor: 'pointer'
                            }}
                          >
                            {/* <CustomButton
                              icon={<ShareOutlinedIcon fontSize="medium" />}
                              color={'#fff'}
                              textColor={"#000"}
                              iconButton={true}
                              pr={'0px'}
                              pl={'5px'}
                              onClick={this.shareOpen}
                              style={{
                                padding: '10px'
                              }}
                            /> */}
                            <img src={require('../assets/share.png')} width={52} />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Container>
          </Box>
        </Box>
      </Box>
      <AudioPlayer 
        audioDetails={audioDetails}
        getTotalTime={this.getTotalTime}
        playing= {this.state.playing}
        // @ts-ignore
        ref={this.audioRef}
        url={audioDetails?.audio ? audioDetails?.audio : "https://minio.b64156.dev.eastus.az.svc.builder.cafe/sbucket/4sgxaxffdhmj1q69wyb0w6ohi7n2"}
      />
      {/* @ts-ignore */}
      <Box
        mt={5}
      >
        <Container>
          {/* @ts-ignore */}
          <Box
            mt={5}
          >
            <Typography
              style={{
                color: '#000',
                fontSize: '24px',
                fontFamily: 'Poppins-Light'
              }}
            >
              In this conversation with
            </Typography>
          </Box>
          {/* @ts-ignore */}
          <Box
            mt={1}
          >
            <Typography
              style={{
                color: '#000',
                fontSize: '16px',
                fontFamily: 'Poppins-Light',
                letterSpacing: '0.2px',
                lineHeight: 1.5
              }}
            >
              {audioDetails.description}
            </Typography>
          </Box>
        </Container>
        {
          this.state.otherPodcast.length ?
            <>
            {/* @ts-ignore */}
              <Box
                mt={8}
                style={{
                  position: 'relative'
                }}
              >
                {/* @ts-ignore */}
                <Box
                  style={{
                    background: '#FFFBE6',
                    minHeight: '200px',
                    height: '200px',
                    position: 'absolute',
                    minWidth: '100%'
                  }}
                >

                </Box>
              </Box>
            <Container>
              {/* @ts-ignore */}
              <Box 
                mb={8}
                style={{
                  position: 'relative'
                }}
              >
                {/* @ts-ignore */}
                <Box pt={6}>
                    {/* @ts-ignore */}
                      <Box
                          style={{
                              display: 'flex',
                              // flex: 1,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                          }}
                      >
                        {/* @ts-ignore */}
                          <Box>
                              <Typography
                                  className={classes.otherPodcastHead}
                              >
                                  Other podcasts you may like
                              </Typography>
                          </Box>
                          {/* @ts-ignore */}
                          <Box>
                            <Button
                                style={{
                                    color: palette.primary.main,
                                    fontFamily: 'Poppins-SemiBold',
                                }}
                            >
                                <Link
                                    style={{
                                        textDecoration: 'none',
                                        color: palette.primary.main,
                                        fontFamily: 'Poppins-SemiBold',
                                    }} 
                                    to={'/audiopodcasts'}
                                >
                                    See more
                                </Link>
                            </Button>
                        </Box>
                      </Box>
                    </Box>
                    {/* @ts-ignore */}
                    <Box mt={3}>
                      <Grid container spacing={5}>
                        {
                          this.state.otherPodcast.slice(0, 4).map((item: any, index: any) => {
                            return (
                              <Grid key={index} item xs={12} sm={6} md={3}>
                              {/* @ts-ignore */}
                                <Box
                                  style={{
                                      cursor: 'pointer',
                                      position: 'relative'
                                  }}
                                  onClick={() => this.getAudio(item.id)}
                                >
                                  <img width={'100%'} height={'100%'} src={item.attributes.poster} style={{borderRadius: '8px', position: 'relative'}} />
                                  {/* @ts-ignore */}
                                  <Box
                                      style={{
                                          position: 'absolute',
                                          zIndex: 9999,
                                          bottom: '15px',
                                          padding: '20px',
                                      }}
                                  >
                                      <Typography
                                          className={classes.otherPodcastCardHead}
                                      >
                                          {item.attributes.title}
                                      </Typography>
                                      <Typography
                                          className={classes.otherPodcastCardDesc}
                                      >
                                          {item.attributes.summary}
                                      </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            )
                          })
                        }
                        </Grid>
                      </Box>
              </Box>
            </Container>
            </>
          : null
        }
      </Box>
    </>
      // Customizable Area End
    );
  }
}
export default withStyles(styles)(AudioMusic);
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: '20px 0px',
    }
  },
});

const webStyle = {
  mainWrapper: {
    display: 'flex', 
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column', 
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  buttonStyle: { 
    width: '100%',
    height: '45px',  
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)', 
  }
};
// Customizable Area End
