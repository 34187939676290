import React from 'react';

import {
  Box,
  Grid,
  Typography,
  IconButton,
  Toolbar,
  Button,
  withStyles,
  AppBar,
} from "@material-ui/core"

export default function AdminSearch(props: any) {
  const {searchList} = props;

  return (
    <div>
      <div>
        {
          searchList.blogs.length ?
            <div>
              <Typography
                style={{
                  color: '#353535',
                  fontFamily: 'Poppins-SemiBold',
                  fontSize: '18px'
                }}
              >
                Articles
              </Typography>
              {
                searchList.blogs.map((item: any, index: any) => {
                  return (
                    <div
                      style={{
                        marginLeft: '20px'
                      }}
                      key={index}
                    >
                      <Typography
                        style={{
                          cursor: 'pointer',
                          color: '#353535',
                          fontSize: '14px',
                          fontFamily: 'Poppins-Light'
                        }}
                        onClick={() => props.navigation.navigate('EditLetsRead', {id: item.attributes.id})}
                      >
                        {item.attributes.title}
                      </Typography>
                    </div>
                  )
                })
              }
            </div>
          : null
        }
        {
          searchList.videos.length ?
            <div>
              <Typography
                style={{
                  color: '#353535',
                  fontFamily: 'Poppins-SemiBold',
                  fontSize: '18px'
                }}
              >
                Videos
              </Typography>
              {
                searchList.videos.map((item: any, index: any) => {
                  return (
                    <div
                      style={{
                        marginLeft: '20px'
                      }}
                      key={index}
                    >
                      <Typography
                        style={{
                          cursor: 'pointer',
                          color: '#353535',
                          fontSize: '14px',
                          fontFamily: 'Poppins-Light'
                        }}
                        onClick={() => props.navigation.navigate('EditVideo', {id: item.id})}
                      >
                        {item.attributes.title}
                      </Typography>
                    </div>
                  )
                })
              }
            </div>
          : null
        }
        {
          searchList.courses.length ?
            <div>
              <Typography
                style={{
                  color: '#353535',
                  fontFamily: 'Poppins-SemiBold',
                  fontSize: '18px'
                }}
              >
                Courses
              </Typography>
              {
                searchList.courses.map((item: any, index: any) => {
                  return (
                    <div
                      style={{
                        marginLeft: '20px'
                      }}
                      key={index}
                    >
                      <Typography
                        style={{
                          cursor: 'pointer',
                          color: '#353535',
                          fontSize: '14px',
                          fontFamily: 'Poppins-Light'
                        }}
                        onClick={() => props.navigation.navigate('EditCourse', {id: item.id})}
                      >
                        {item.attributes.title}
                      </Typography>
                    </div>
                  )
                })
              }
            </div>
          : null
        }
        {
          searchList.podcasts.length ?
            <div>
              <Typography
                style={{
                  color: '#353535',
                  fontFamily: 'Poppins-SemiBold',
                  fontSize: '18px'
                }}
              >
                Podcasts
              </Typography>
              {
                searchList.podcasts.length && searchList.podcasts.map((item: any, index: any) => {
                  return (
                    <div
                      style={{
                        marginLeft: '20px'
                      }}
                      key={index}
                    >
                      <Typography
                        style={{
                          cursor: 'pointer',
                          color: '#353535',
                          fontSize: '14px',
                          fontFamily: 'Poppins-Light'
                        }}
                        onClick={() => props.navigation.navigate('EditPodcast', {id: item.id})}
                      >
                        {item.attributes.title}
                      </Typography>
                    </div>
                  )
                })
              }
            </div>
          : null
        }
        {
          searchList?.lets_discover?.people?.length ?
            <div>
              <Typography
                style={{
                  color: '#353535',
                  fontFamily: 'Poppins-SemiBold',
                  fontSize: '18px'
                }}
              >
                Peoples
              </Typography>
              {
                searchList.lets_discover.people.map((item: any, index: any) => {
                  return (
                    <div
                      style={{
                        marginLeft: '20px'
                      }}
                      key={index}
                    >
                      <Typography
                        style={{
                          cursor: 'pointer',
                          color: '#353535',
                          fontSize: '14px',
                          fontFamily: 'Poppins-Light'
                        }}
                        onClick={() => props.navigation.navigate('EditPeople', {id: item.id})}
                      >
                        {item.attributes.name}
                      </Typography>
                    </div>
                  )
                })
              }
            </div>
          : null
        }
        {
          searchList?.lets_discover?.organisations?.length ?
            <div>
              <Typography
                style={{
                  color: '#353535',
                  fontFamily: 'Poppins-SemiBold',
                  fontSize: '18px'
                }}
              >
                Organisations
              </Typography>
              {
                searchList.lets_discover.organisations.map((item: any, index: any) => {
                  return (
                    <div
                      style={{
                        marginLeft: '20px'
                      }}
                      key={index}
                    >
                      <Typography
                        style={{
                          cursor: 'pointer',
                          color: '#353535',
                          fontSize: '14px',
                          fontFamily: 'Poppins-Light'
                        }}
                        onClick={() => props.navigation.navigate('EditOrganisation', {id: item.id})}
                      >
                        {item.attributes.name}
                      </Typography>
                    </div>
                  )
                })
              }
            </div>
          : null
        }
        {
          searchList?.lets_discover?.books?.length ?
            <div>
              <Typography
                style={{
                  color: '#353535',
                  fontFamily: 'Poppins-SemiBold',
                  fontSize: '18px'
                }}
              >
                Books
              </Typography>
              {
                searchList.lets_discover.books.map((item: any, index: any) => {
                  return (
                    <div
                      style={{
                        marginLeft: '20px'
                      }}
                      key={index}
                    >
                      <Typography
                        style={{
                          cursor: 'pointer',
                          color: '#353535',
                          fontSize: '14px',
                          fontFamily: 'Poppins-Light'
                        }}
                        onClick={() => props.navigation.navigate('EditBook', {id: item.id})}
                      >
                        {item.attributes.title}
                      </Typography>
                    </div>
                  )
                })
              }
            </div>
          : null
        }
        {
          searchList?.lets_discover?.films?.length ?
            <div>
              <Typography
                style={{
                  color: '#353535',
                  fontFamily: 'Poppins-SemiBold',
                  fontSize: '18px'
                }}
              >
                Films
              </Typography>
              {
                searchList.lets_discover.films.map((item: any, index: any) => {
                  return (
                    <div
                      style={{
                        marginLeft: '20px'
                      }}
                      key={index}
                    >
                      <Typography
                        style={{
                          cursor: 'pointer',
                          color: '#353535',
                          fontSize: '14px',
                          fontFamily: 'Poppins-Light'
                        }}
                        onClick={() => props.navigation.navigate('EditFilm', {id: item.id})}
                      >
                        {item.attributes.name}
                      </Typography>
                    </div>
                  )
                })
              }
            </div>
          : null
        }
      </div>
    </div>
  );
}