import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { palette } from './theme';
import { makeStyles, Button } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    donateEnrollBtn:
    {
        backgroundColor: palette.primary.main,
        textTransform: 'none',
        borderRadius: '7px',
        fontSize: '18px',
        fontWeight: 500,
        color: '#ffff',
        height: '50px',
        padding: '10px 70px',
        margin: '10px 5px',
        '&:hover': {
            background: 'transparent',
            color: palette.primary.main,
            border: `1px solid ${palette.primary.main}`
        },
        '@media (max-width:480px)': {
            width: '100%',
            padding: '0px 0px',
            height: '40px',
            fontSize: '16px',
        }
    },
}));

export default function AlertModal(props: any) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(props.open);

    const handleClose = () => {
        if (props.redirectUri) {
            props.navigation.navigate(props.redirectUri);
        }
        setOpen(false);
        props.onClose();
    };

    const handleDonate = () => {
        props.handleDonate()
    }

    const handleEnrollNow = () => {
        props.handleEnrollNow(props.id, props.free)
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                    <span style={{ fontFamily: 'Poppins-Light' }}>
                        Your generous contribution will make a difference. Please consider supporting our cause by making a donation.
                    </span>
                    <br />
                    <Button
                        onClick={handleDonate}
                        variant="contained"
                        className={classes.donateEnrollBtn}
                    >
                        {"Donate"}
                    </Button>
                </div>
                <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                    <span style={{ fontFamily: 'Poppins-Bold', color: palette.primary.main }}>
                        or
                    </span>
                </div>
                <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                    <span style={{ fontFamily: 'Poppins-Light' }}>
                        You can enroll for this Course
                    </span>
                    <br />
                    <Button
                        onClick={handleEnrollNow}
                        variant="contained"
                        className={classes.donateEnrollBtn}
                    >
                        {"Enroll Now"}
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}
