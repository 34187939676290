// istanbul ignore file
import React, { useEffect, useState, useRef, createRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Grid } from '@material-ui/core';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import { palette } from "../../../components/src/theme";
import { setStorageData } from '../../../framework/src/Utilities';
import AlertModal from '../../../components/src/AlertModal';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const format = (seconds: any) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '30px 0px',
    padding: '10px',
    borderRadius: '10px',
    width: '100%',
    backgroundColor: '#2e2e2e',
    [theme.breakpoints.down("sm")]: {
      padding: '0px'
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily: palette.fontFamily.main,
    fontWeight: theme.typography.fontWeightRegular,
  },
  mainBox:{
    margin: 0,
    borderRadius: 0,
    boxShadow: 'none !important',
    backgroundColor: '#2e2e2e',
    borderBottom: '2px solid #545454'
  },
  imageGrid:{
    padding: '10px',
    [theme.breakpoints.down("sm")]: {
      padding: '0px',
    }
  },
  lessonImg: {
    height: '80px',
    borderRadius: '6px',
    width: '140px',
    [theme.breakpoints.down("sm")]: {
      width: '80px',
    }
  },
  lessonGrid:{
    position: 'relative',
    padding: '20px 0px',
    margin: 'auto 0px',
    [theme.breakpoints.down("sm")]: {
      padding: '5px 10px',
    }
  },
  playBox:{
    cursor: 'pointer',
    position: 'relative'
},
  playBTn:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  playBTn2:{
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    background: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
},
  lessonHeading:{
    fontSize: '18px',
    fontFamily: palette.fontFamily.main,
    fontWeight: 600,
    color: palette.course.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: '16px',
    }
    },
    lessonHeading01:{
    fontSize: '18px',
    fontFamily: palette.fontFamily.main,
    fontWeight: 600,
    color: palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: '16px',
    }
    },
    lessonTime:{
    fontSize: '16px',
    fontFamily: palette.fontFamily.main,
    fontWeight: 600,
    color: palette.course.para
    },
    para:{
        fontSize: '16px',
        fontFamily: palette.fontFamily.main,
        lineHeight: 1.6,
        letterSpacing: '0.25px',
        color: palette.course.para,
        padding: '0 0 0 180px',
        [theme.breakpoints.down('sm')]: {
          padding: '0 0 0 0px',
        }
    }
  
}));

export default function SimpleAccordion({modules, navigation, enrolled}: {modules:any, navigation:any, enrolled : boolean}) {
  const classes = useStyles();
    // console.log(modules)
  const videoContainerRef = useRef(modules.map(() => createRef()));
  const [listData, setListData] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorData, setErrorData] = useState('');
  const [duration, setDuration] = useState([]);

  const handleClick = (item:any) => {
    if(enrolled){
      setStorageData('singleLesson', JSON.stringify(item))
      navigation.navigate("singleLessons", {id: item.id})
    } else {
      // alert("Enrolled to the course first")
      setOpenAlert(true);
      setErrorData('Please enroll to the course to continue.');
    }
  
  }

  useEffect(() => {
    if(modules.length) {
      setListData(modules)
    }
    // console.log(modules)
  },[modules])

  const handleLoadedMetadata = () => {
    const video = videoContainerRef.current.map((ref: any) => {
      return format(ref.current.duration)
    });
    
    if (!video) return;
    setDuration(video)
  };

  const handleExpand = (e:any, expand: any, id: any) => {
    // console.log(e, expand)
    let newData = modules.map((x:any) => {
      if(x.id === id){
        x.expanded = !x.expanded
      }
      return x
    })

    setListData(newData)
  }

  const closeAlertBox = () => {
    setOpenAlert(false);
    setErrorData('');
  }

  return (
    <div className={classes.root}>
      {
        openAlert ?
        <AlertModal 
          open={openAlert} 
          data={errorData}
          onClose={() => closeAlertBox()}
        /> 
        : null
      }
        {
            listData.length ?
            listData.map((item : any, index :any) =>
            <Accordion 
            onChange={(e:any, expended :any) => handleExpand(e, expended, item.id) }
            className={classes.mainBox}>
            <AccordionSummary
              expandIcon={<img src={item.expanded ? require("../assets/downArrow.png") : require("../assets/rightArrow.png")} style={{ height: '44px',width: '44px'}}/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
             <Grid container style={{display: 'flex'}}>
                <Grid className={classes.imageGrid}>
                <Box className={classes.playBox}>
                            <video
                              height={'80px'}
                              width={'140px'}
                              style={{
                                borderRadius: '6px'
                              }}
                            >
                              <source src={item.attributes.video} />
                            </video>
                            <Box className={classes.playBTn} >
                           <Box className={classes.playBTn2}>
                                    <PlayArrowRoundedIcon 
                                        style={{
                                            color: palette.primary.main,
                                            fontSize: 20,
                                        }}
                                         onClick={() => handleClick(item)}
                                        // fontSize="large"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    {/* <img style={{ height: '80px', width: '160px'}} src={require("../assets/background.png")} /> */}
                    </Grid>
                <Grid className={classes.lessonGrid}>
                    <Typography className={item?.attributes?.student_lecture?.status == 'complete' ? classes.lessonHeading01 : classes.lessonHeading}>Lesson {index+1}. {item.attributes.title}</Typography>
                    <Typography className={classes.lessonTime}> {duration[index]}</Typography>
                    <video 
                      style={{display: 'none'}} 
                      src={
                        item?.attributes?.video ? item?.attributes?.video
                        : 'https://minio.b64156.dev.eastus.az.svc.builder.cafe/sbucket/twimtyaj4fwz64voegthw9n8lbs8'
                      } 
                      ref={videoContainerRef.current[index]} 
                      onLoadedMetadata={handleLoadedMetadata} 
                    />
                </Grid>
             </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Typography  className={classes.para}>
                {item.attributes.description}j
              </Typography>
            </AccordionDetails>
          </Accordion>       
            )
            : null
        }
     
    </div>
  );
}
