// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import ProfileContextProvider from "../../blocks/QuestionBank/src/contextFile/ProfileContextProvider.web"

ReactDOM.render(
  <Router>
    <ProfileContextProvider>
    <App />
    </ProfileContextProvider>
  </Router>,
  document.getElementById('root')
);

registerServiceWorker();

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('ServiceWorker registered:', registration);
      })
      .catch(error => {
        console.error('ServiceWorker registration failed:', error);
      });
  });
}
