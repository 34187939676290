import React, { useState } from "react";
import { Typography, Accordion, AccordionDetails, AccordionSummary, Box, makeStyles, IconButton } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { DownIcon } from "../../blocks/helpcentre/src/assets";

type Faq = {
    question: string;
    answer: string;
};

type TopicProps = {
    topic: string;
    faqs: Faq[];
};

const useStyles = makeStyles({
    root: {
        boxShadow: "none",
        borderRadius: 0,
        paddingLeft: 0,
        '& .MuiAccordionSummary-content': {
            margin: 0,
            padding: 0,
        },
        '& .MuiAccordionSummary-root': {
            margin: 0,
            padding: 0,
        },
        '& .MuiAccordionDetails-root': {
            padding: "8px 16px 16px 0"
        }
    },
});

const FaqListItem = ({ topic, faqs }: TopicProps) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const classes = useStyles();

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Typography gutterBottom style={{ fontFamily: "Poppins-SemiBold", fontSize: "26px", color: "#545454", marginBottom: "10px" }}>
                {topic}
            </Typography>
            {faqs.map((faq, index) => (
                <Box key={index} borderRadius="0px">
                    <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}
                        className={classes.root}
                    >
                        <AccordionSummary
                            expandIcon={
                                <IconButton aria-label="expand" size="small"
                                    style={{ transform: expanded === `panel${index}` ? 'rotate(180deg)' : 'rotate(270deg)' }}
                                >
                                    <img src={DownIcon} alt="down icon" height="30px" width="30px" />
                                </IconButton>
                            }
                            aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                            <Typography style={{ fontFamily: "Poppins-SemiBold", fontSize: "20px", color: "#545454" }}>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{ color: "#545454" }}>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            ))}
        </>
    );
};

export default FaqListItem;
