import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";


// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
// Customizable Area End
export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean;
  loading: boolean;
  inputError: any;
  openAlert: boolean;
  alertMsg: any;
  categoryId: any;
  categoryList: any;
  personList: any;
  personId: any;
  bookTitle: any;
  bookAbout: any;
  bookUrl: any;
  bookImage: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddBookController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiGetCategoryDataCallId: string = "";
  apiCreateBookId: string = "";
  apiGetPersonId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mobileOpen: false,
      loading: false,
      inputError: '',
      openAlert: false,
      alertMsg: '',
      categoryId: '',
      categoryList: '',
      personList: '',
      personId: '',
      bookTitle: '',
      bookAbout: '',
      bookUrl: '',
      bookImage: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getBookCategories()
    this.getPersons()
  }

  setBookCreate = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        openAlert: true,
        loading: false,
        alertMsg: 'Book created successfully!'
      });
    } else {
      this.setState({
        loading: false,
        openAlert: true,
        alertMsg: 'Something went wrong!'
      })
    }
  }

  setCategoryList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        categoryList: responseJson.data
      });
    }
  }

  setPersonList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        personList: responseJson.data
      });
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetCategoryDataCallId) {
          this.setCategoryList(responseJson)
        }
        if (apiRequestCallId === this.apiCreateBookId) {
          this.setBookCreate(responseJson)
        }
        if(apiRequestCallId === this.apiGetPersonId){
          this.setPersonList(responseJson)
        }
      }
    }
  }

  validation = () => {
    const {
      personId,
      bookAbout,
      bookImage,
      bookTitle,
      bookUrl,
      categoryId
    } = this.state

    let error: any = {}
    if (!bookTitle) {
        error.bookTitle = "Please provide book name"
    }

    if (!bookAbout) {
      error.bookAbout = "Please provide book about"
    }

    if (!categoryId) {
      error.categoryId = "Please select category"
    }

    if (!personId) {
      error.personId = "Please select person"
    }

    if (!bookUrl) {
      error.bookUrl = "Please provide book link url"
    }

    if (!bookImage) {
      error.bookImage = "Please upload book image"
    }
    
    if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
        this.setState({ inputError: {} })
        this.createBook()
    }
    else {
        this.setState({ inputError: error })
    }
  }

  createBook = () => {
    this.setState({
      loading: true
    })
    const userToken = window.localStorage.getItem("token");
    const header = {
      // "Content-Type": 'multipart/form-data',
      token: userToken
    };

    const { 
          personId,
          bookAbout,
          bookImage,
          bookTitle,
          bookUrl,
          categoryId
        } = this.state

    const formData = new FormData();

    formData.append('books[title]', bookTitle);
    formData.append('books[about]', bookAbout);
    formData.append('books[category_id]', categoryId);
    formData.append('books[person_id]', personId);
    let blobBookImage = new Blob(bookImage);
    formData.append('books[image]', blobBookImage);
    formData.append('books[url]', bookUrl)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateBookId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBookApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBookCategories = () => {
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoryDataCallId = requestMessage.messageId;
    const hitURL = `${configJSON.categoryUrl}?type=book_category`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPersons = () => {
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPersonId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPersonListApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCategoryChange = (e: any) => {
    this.setState({
      categoryId: e.target.value
    })
  };

  handlePersonChange = (e: any) => {
    this.setState({
      personId: e.target.value
    })
  };

  handleTitleChange = (e: any) => {
    this.setState({
      bookTitle: e.target.value
    })
  }

  handleAboutChange = (e: any) => {
    this.setState({
      bookAbout: e.target.value
    })
  }

  handleLinkChange = (e: any) => {
    this.setState({
      bookUrl: e.target.value
    })
  }

  handleBookImageChange = (files: any) => {
    this.setState({
      bookImage: files
    })
  }

  // Customizable Area End

}
