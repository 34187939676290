import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Grid,
} from '@material-ui/core';
import HomeCarousel from "../../../components/src/HomeCarousel";
import ExperienceCard from "../../../components/src/ExperienceCard"
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link } from 'react-router-dom'
import CancelIcon from '@material-ui/icons/Cancel';
let str: any = '';
// Customizable Area End

import HomePageController, {
    Props
} from "./HomePageController";
import { palette } from "../../../components/src/theme";

class HomePage extends HomePageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    getSecName = (secName: any) => {
        str = secName.split(" ");
        return(
            <span>{str[1] == 'Lets' ? str[2] : str[1]}</span>
        )
    }

    renderCarousalData = () => {
        const { classes } = this.props;
        const { windowWidth, carousalData, loading } = this.state
        if (loading) {
          return (
            <Box style={{ display: 'flex', justifyContent: 'center', width: "100%" }}>
              <Box
                style={{
                  fontSize: '20px',
                  fontFamily: 'Poppins-SemiBold',
                  color: palette.primary.main,
                  textAlign: "center"
                }}
              >
                Loading...
              </Box>
            </Box>
          );
        } else {
          return carousalData.map((data: any) => (
            <div key={data.index}>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: windowWidth < 600 ? 'column' : 'row',
                  height: '100%',
                  alignItems: 'flex-end'
                }}
              >
                <Grid item xs={12} sm={7} className="homeCarouselContentStyle">
                  <p style={{ fontFamily: 'Poppins-SemiBold', fontSize: windowWidth < 1077 ? '2rem' : '3rem' }}>
                    {data?.attributes.title}
                  </p>
                  <Typography style={{ fontFamily: 'Poppins-Light' }}>{data.attributes.value}</Typography>
                  {data.attributes.read_more_url ? (
                    <Button variant="contained" className={classes.viewNowBtn}
                    data-testid="learnAboutBtn"
                    onClick={() => window.open(data?.attributes?.read_more_url, '_blank')}
                    >
                      View Now
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={5} className="imgHeightWidth">
                  <img src={data.attributes.image} style={{ width: '100%', height: '100%' }} />
                </Grid>
              </Grid>
            </div>
          ));
        }
      };

      renderExploreItems = (explore: any) => {
        return explore.map((item: any, index: any) => {
          const renderItemImage = () => (
            <div
              style={{
                boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.07)',
                borderRadius: '10px',
                minHeight: '300px',
                maxHeight: '300px',
                overflow: 'hidden',
                width: index === explore.length - 1 ? '100%' : 'auto',
                marginBottom: '20px'
              }}
            >
              <img
                style={{
                  borderRadius: '10px'
                }}
                src={item.attributes.image ? item.attributes.image : require('../assets/bg.jpg')}
                height='100%'
                width='100%'
              />
            </div>
          );
      
        const renderItemConent = () => (
            <div>
              <span
                style={{
                  fontSize: '20px',
                  fontFamily: 'Poppins-Bold'
                }}
              >
                {item.attributes.title}
              </span>
              <div
                style={{
                  marginTop: '10px'
                }}
              >
                <span
                  style={{
                    fontSize: '14px',
                    fontFamily: 'Poppins-Light',
                    color: '#8991a4',
                    lineHeight: 1.43,
                    textAlign: 'justify'
                  }}
                >
                  {item.attributes.description}
                </span>
              </div>
              <div
                style={{
                  marginTop: '10px'
                }}
              >
                <Link
                  to={this.getSecPath(item.attributes.title)}
                  style={{
                    color: palette.primary.main,
                    fontSize: '16px',
                    fontFamily: 'Poppins-SemiBold',
                    textTransform: 'uppercase'
                  }}
                >
                  Let's {this.getSecName(item.attributes.title)} Now
                </Link>
              </div>
            </div>
          );
      
          return (
            <Grid key={index} item xs={12} sm={12} md={index === explore.length - 1 ? 12 : 6}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {renderItemImage()}
                {renderItemConent()}
              </Box>
            </Grid>
          );
        });
      };
      
      renderExploreSection = (explore: any, loading: boolean) => {
        return (
          <Grid container spacing={10}>
            {loading ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <span
                  style={{
                    fontSize: '20px',
                    fontFamily: 'Poppins-SemiBold',
                    color: palette.primary.main
                  }}
                >
                  Loading...
                </span>
              </Box>
            ) : (
              this.renderExploreItems(explore)
            )}
          </Grid>
        );
      };
      
      
    // Customizable Area End

    render() {
        // Customizable Area Start

        const { classes } = this.props;
        const {
            windowWidth,
            testimonials,
            explore,
            other,
            ourMission,
            learnAbout,
            loading,
            deferredPrompt
        } = this.state;

        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
            {deferredPrompt && (
                <div
                    id="pwa"
                    className={classes.pwaContainer}
                >
                    <button 
                        style={{
                            minWidth: '100%',
                            background: '#fff',
                            border: `1.5px solid ${palette.primary.main}`,
                            padding: '5px',
                            borderRadius: '8px',
                            boxShadow: "0 1px 0 0 rgba(0, 0, 0, 0.07)",
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onClick={this.handleInstallClick}
                    >
                        <img src={require('../../../web/public/favicon.ico')} height={'45px'} /> 
                        <span
                            style={{
                                fontFamily: 'Poppins-SemiBold',
                                fontSize: '14px',
                                color: '#000',
                                marginLeft: '10px',
                            }}
                        >
                            Add Let's Be Real to your Home Screen.
                        </span>
                    </button>
                    <div
                        style={{
                            position: 'absolute',
                            right: '0px',
                            zIndex: 9999
                        }}
                    >
                        <CancelIcon 
                            fontSize='large' 
                            style={{color: palette.primary.main}}
                            onClick={() => this.hidePWA()}
                        />
                    </div>
                </div>
            )}
            <Box className={classes.mainWrapper}>
                    <Box>
                        <HomeCarousel>
                            {this.renderCarousalData()}
                        </HomeCarousel>
                    </Box>
                <Box style={{ marginTop: "10rem", marginBottom: "5rem", textAlign: "center" }}>
                    <Typography  gutterBottom style={{ fontFamily: "Poppins-Bold", fontSize: windowWidth < 1077 ?  "2rem" : "3rem" }}>{ourMission[0]?.attributes?.title}</Typography>
                    <Typography style={{ fontSize:windowWidth < 600 ? "18px" : "24px", lineHeight: 1.75,  fontFamily: "Poppins-Light", textAlign: "justify" }}>
                    <span style={{position: "relative", margin: "0"}}><small style={{position: "absolute", left: "-19px", top: "-20px", fontSize: "40px", color: "#F27024",}}>“</small></span> {ourMission[0]?.attributes?.value}<span style={{position: "relative", margin: "0"}}><small style={{position: "absolute", right: "-19px", top: "-20px", fontSize: "40px", color: "#F27024"}}>”</small></span> </Typography>
                </Box>
                <Box style={{marginTop: "2rem"}}>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '10px'
                        }}
                    >
                        <Typography  
                            gutterBottom 
                            style={{ 
                                fontFamily: "Poppins-Bold", 
                                fontSize: windowWidth < 1077 ?  "2rem" : "3rem" 
                            }}
                        >
                            Explore    
                        </Typography>
                    </Box>
                    {/* <Grid container spacing={10}>
                        {
                            loading ? 
                                <Box
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: '20px',
                                            fontFamily: 'Poppins-SemiBold',
                                            color: palette.primary.main
                                        }}
                                    >
                                        Loading...
                                    </span>
                                </Box>
                            :
                            explore.map((item: any, index: any) => {
                                return(
                                    
                                        index == explore.length-1 ?
                                            <Grid item xs={12} md={12} sm={12}>
                                                <Grid
                                                    container
                                                    spacing={5}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        sm={12}
                                                    >
                                                        <div
                                                            style={{
                                                                boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.07)',
                                                                borderRadius: '10px',
                                                                minHeight: '300px',
                                                                maxHeight: '300px',
                                                                overflow: 'hidden',
                                                                width: '100%',
                                                                marginBottom: '20px'
                                                            }}
                                                        >
                                                            <img 
                                                                style={{
                                                                    borderRadius: '10px'
                                                                }}
                                                                src={item.attributes.image ? item.attributes.image : require('../assets/bg.jpg')}
                                                                height='100%'
                                                                width='100%'
                                                            />
                                                        </div>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        sm={12}
                                                    >
                                                        <Box
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                marginTop: '80px'
                                                            }}
                                                        >
                                                            <div>
                                                                <span
                                                                    style={{
                                                                        fontSize: '20px',
                                                                        fontFamily: 'Poppins-Bold'
                                                                    }}
                                                                >
                                                                    {item.attributes.title}
                                                                </span>
                                                            </div> 
                                                            <div
                                                                style={{
                                                                    marginTop: '10px'
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontSize: '14px',
                                                                        fontFamily: 'Poppins-Light',
                                                                        color: '#8991a4',
                                                                        lineHeight: 1.43,
                                                                        textAlign: 'justify'
                                                                    }}
                                                                >
                                                                    {item.attributes.description}
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    marginTop: '10px'
                                                                }}
                                                            >
                                                                <Link
                                                                    href='/podcast'
                                                                    style={{
                                                                        color: palette.primary.main,
                                                                        fontSize: '16px',
                                                                        fontFamily: 'Poppins-SemiBold',
                                                                        textTransform: 'uppercase'
                                                                    }}
                                                                >
                                                                    Let's Talk Now
                                                                </Link>
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid> 
                                        :
                                        <Grid key={index} item xs={12} sm={12} md={6}>
                                            <Box 
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.07)',
                                                        borderRadius: '10px',
                                                        minHeight: '300px',
                                                        maxHeight: '300px',
                                                        overflow: 'hidden',
                                                        width: 'auto',
                                                        marginBottom: '20px'
                                                    }}
                                                >
                                                    <img 
                                                        style={{
                                                            borderRadius: '10px'
                                                        }}
                                                        src={item.attributes.image ? item.attributes.image : require('../assets/bg.jpg')}
                                                        height='100%'
                                                        width='100%'
                                                    />
                                                </div>  
                                                <div>
                                                    <span
                                                        style={{
                                                            fontSize: '20px',
                                                            fontFamily: 'Poppins-Bold'
                                                        }}
                                                    >
                                                        {item.attributes.title}
                                                    </span>
                                                </div> 
                                                <div
                                                    style={{
                                                        marginTop: '10px'
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: '14px',
                                                            fontFamily: 'Poppins-Light',
                                                            color: '#8991a4',
                                                            lineHeight: 1.43,
                                                            textAlign: 'justify'
                                                        }}
                                                    >
                                                        {item.attributes.description}
                                                    </span>
                                                </div>
                                                <div
                                                    style={{
                                                        marginTop: '10px'
                                                    }}
                                                >
                                                    <a
                                                        href="#"
                                                        style={{
                                                            color: palette.primary.main,
                                                            fontSize: '16px',
                                                            fontFamily: 'Poppins-SemiBold',
                                                            textTransform: 'uppercase'
                                                        }}
                                                    >
                                                        Let's {this.getSecName(item.attributes.title)} Now
                                                    </a>
                                                </div>
                                            </Box>
                                        </Grid>
                                    
                                )
                            })
                        }
                    </Grid> */}
                    {this.renderExploreSection(explore, loading)}
                </Box>
                <Box style={{ margin: "5rem 0", textAlign: "center" }}>
                    <Typography  gutterBottom style={{ fontFamily: "Poppins-Bold", marginBottom: "3rem", fontSize: windowWidth < 1077 ?  "2rem" : "3rem" }}>Learn About</Typography>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        {
                            loading ? 
                                <Box
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: '20px',
                                            fontFamily: 'Poppins-SemiBold',
                                            color: palette.primary.main
                                        }}
                                    >
                                        Loading...
                                    </span>
                                </Box>
                            :
                            learnAbout.map((item: any, index: any) => {
                                return(
                                    <Grid item key={index} xs={6} sm={6} md={4}>
                                        <Button 
                                            variant="outlined" 
                                            className={classes.catBtn}
                                            onClick={() => this.filterLearnAbout(item?.attributes?.title)}
                                        >
                                            {item.attributes.title}
                                        </Button>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    {
                        loading ? 
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '20px',
                                        fontFamily: 'Poppins-SemiBold',
                                        color: palette.primary.main
                                    }}
                                >
                                    Loading...
                                </span>
                            </Box>
                        :
                        other.map((item: any, index: any)=> {
                            return(
                                <>
                                    <Grid item sm={12} md={3} key={index}>
                                        {/* <img src={educateIcon} /> */}
                                        <Typography 
                                            variant="h4" 
                                            component="div" 
                                            gutterBottom 
                                            style={{ 
                                                marginTop: "3rem", 
                                                marginBottom: "2rem", 
                                                color: index == 1 ? "#f77600" : "#ffbe63", 
                                                fontFamily: "Poppins-SemiBold" 
                                            }}
                                        >
                                            {item.attributes.title}
                                        </Typography>
                                        <Typography 
                                            variant="body2" 
                                            gutterBottom 
                                            style={{
                                                fontFamily: "Poppins-Light", 
                                                textAlign: "justify"
                                            }}
                                        >
                                            {item.attributes.value}
                                        </Typography>
                                    </Grid>
                                    {
                                        index == other.length -1 ?
                                        null
                                        :<Box className={classes.varticleLine} />
                                    }
                                </>
                            )
                        })
                    }
                </Grid>
            </Box>
                <Box className={classes.cardWrapper}>
                <Box>
                    <Typography variant="h6" component="div" gutterBottom style={{ fontFamily: "Poppins-SemiBold", color: "#F27024", textAlign: "center" }}>See people's experiences</Typography>
                    <Typography variant="h4" component="div" gutterBottom style={{ textAlign: "center", fontFamily: "Poppins-SemiBold" }}>What our members have to say</Typography>
                    <Grid
                        container
                        spacing={3} alignItems="center" className={classes.cardCenter}>
                        {
                            loading ? 
                                <Box
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: '20px',
                                            fontFamily: 'Poppins-SemiBold',
                                            color: palette.primary.main
                                        }}
                                    >
                                        Loading...
                                    </span>
                                </Box>
                            :
                            testimonials.map((data: any, index: any) =>
                                <Grid item md={3} sm={6} xs={12} key={index}>
                                    <ExperienceCard data={data} />
                                </Grid>
                            )
                        }
                    </Grid>
                </Box>
            </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            pwaContainer: {
                display: 'flex',
                position: 'fixed',
                width: '87%',
                zIndex: 999,
                bottom: '60px',
                margin: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.up("sm")]: {
                    display: "none"
                },
            },
            typography: {
                body2: {
                    fontFamily: 'Poppins, sans-serif'
                },
            },
            mainWrapper: {
                padding: '5% 8%',
                [theme.breakpoints.down("xs")]: {
                    padding: '10px'
                },
                background: '#fff',
            },
            viewNowBtn: {
                marginTop: "35px",
                border: "1px solid #F27024", 
                background: '#f27024', 
                color: "#FFFFFF", 
                margin: "10px 0", 
                textTransform: "initial", 
                fontFamily: "Poppins-SemiBold",
                '&:hover': {
                    background: "transparent",
                    color: 'f27024'
                }
            },
            catBtn: {
                minWidth: '100%', 
                color: "#F27024", 
                border: "1px solid #F27024", 
                // padding: windowWidth < 600 ? "10px" : "10px 40px", 
                padding: window.innerWidth < 600 ? "" : "10px 40px",
                textTransform: "initial", 
                fontFamily: "Poppins-SemiBold" ,
                // fontSize: windowWidth < 600 ? '10px' : '16px'
                fontSize: '16px',
                '&:hover': {
                    background: "#F27024",
                    color: "#ffffff"
                },
                '&:focus': {
                    outline: 'none', // Remove focus outline
                },
            },
            cardWrapper: {
                padding: '2% 8%',
                [theme.breakpoints.down("xs")]: {
                    padding: '5% 12%'
                },
                marginBottom: "80px"
            },
            varticleLine: {
                width: "2px",
                height: "200px",
                margin: "84.9px 26px 281px 47px",
                opacity: 0.35,
                borderRadius: "1px",
                backgroundImage: "radial-gradient(circle at 50% 54%, #f00, #ffffff 84%)",
                [theme.breakpoints.down("sm")]: {
                    display: "none"
                },

            },

            courseStyle: {
                background: "linear-gradient(180deg, #fffbe6 50%, #ffffff 50%)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                paddingTop: "5%",
            },
            cardCenter: {
                marginTop: '20px',
                [theme.breakpoints.down("md")]: {
                    alignItems: "center",
                    justifyContent: "center"
                },
            },

            title: {
                textAlign: "right",
                [theme.breakpoints.down("xs")]: {
                    textAlign: "left",
                },
            },
            textCenter: {
                [theme.breakpoints.down("sm")]: {
                    textAlign: "center"
                },
            },
            q: {
                color: "red"
            }

        })
    )(HomePage)
)
// Customizable Area End
