import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleDrawerToggle: () => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  mobileOpen: boolean;
  selectedFilter: any;
  trendingCourses: any;
  trendingPodcasts: any;
  trendingArticles: any;
  trendingVideos: any;
  trendingData: any;
  searchQuery: string;
  searchList: any;
  openSearch: boolean;
  searchLoading: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiSearchItemCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      trendingCourses: [],
      trendingPodcasts: [],
      trendingArticles: [],
      trendingVideos: [],
      errorMsg: "",
      token: "",
      loading: false,
      mobileOpen: false,
      selectedFilter: 1,
      trendingData: [],
      searchQuery: '',
      searchList: [],
      openSearch: false,
      searchLoading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    const userType = localStorage.getItem('userType');
    const isLogin = localStorage.getItem('token') ? true : false;
    if(userType == 'instructor' || userType == "admin"){
      this.getDashboardData();
    } else {
      if(isLogin){
        window.location.href = '/'
      } else {
        this.props.navigation.navigate('Login')
      }
    }    
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    //   let token = message.getData(getName(MessageEnum.SessionResponseToken));
    //   this.setState({ token: token, loading: true }, () => {
    //     this.getDashboardData();
    //   });
    // }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(apiRequestCallId != null){
        if(apiRequestCallId == this.apiDashboardItemCallId){
          if (responseJson && !responseJson.errors && responseJson.data) {
            if (responseJson.data.length === 0) {
              this.setState({
                errorMsg: "Data Not Found",
                loading: false
              });
            } else {
              this.setState({
                dashboardData: responseJson.data,
                trendingData: responseJson.data.trending_data.trending_courses,
                trendingArticles: responseJson.data.trending_data.trending_blogs,
                trendingCourses: responseJson.data.trending_data.trending_courses,
                trendingPodcasts: responseJson.data.trending_data.trending_podcasts,
                trendingVideos: responseJson.data.trending_data.trending_videos,
                errorMsg: "",
                loading: false
              });
            }
          } else {
            if (errorReponse === undefined) {
              this.setState({
                errorMsg: "Something went wrong",
                loading: false
              });
            } else {
              this.setState({
                errorMsg: errorReponse,
                loading: false
              });
            }
          }
        }
        if(apiRequestCallId === this.apiSearchItemCallId){
          if (responseJson && !responseJson.errors) {
            this.setState({
              searchList: responseJson,
              searchLoading: false,
            })
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getSearchItems = () => {
    this.setState({
      searchLoading: true
    })
    const header = {
      // "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSearchItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.adminSearchApi+`?key=${this.state.searchQuery}&data=dashboard`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  handleInputChange = (e: any) => {
    this.setState({
      searchQuery: e.target.value
    }, () => {
      this.setState({
        openSearch: true,
        searchLoading: true
      }, ()=> {
        setTimeout(() => {
          this.getSearchItems();
        }, 5000)
      })
    })
  }
  // Customizable Area End

}
