import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';



export default function CustomCard(props: any) {
  const useStyles = makeStyles({
    root: {
      padding: props.padding ? props.padding : '50px',
      boxShadow: props.boxShadow ? props.boxShadow :'0 0 12px 0 rgba(0, 0, 0, 0.13)',
      borderRadius: props.radius ? props.radius : '20px'
    },
  });
  const classes = useStyles();

  return (
    <Card className={classes.root} {...props}>
      {props.children}
    </Card>
  );
}