import React from "react";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Toolbar,
  Button,
  AppBar,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Modal
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AllListController, {Props} from "./AllListController";
import CustomInput from "../../../../components/src/CustomInput";
import { palette } from "../../../../components/src/theme";
import {
  withTheme,
  withStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
// @ts-ignore
import { EditIcon } from "../assets";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AlertModal from "../../../../components/src/AlertModal";
import CustomLoader from "../../../../components/src/CustomLoader";
import AdminSearch from "../../../../components/src/AdminSearch";
import CircularProgress from '@material-ui/core/CircularProgress';

const drawerWidth = 240;

export const typeBtn = [
  {
    id: 0,
    title: "Persons"
  },
  {
    id: 1,
    title: "Organisations"
  },
  {
    id: 2,
    title: "Films"
  },
  {
    id: 3,
    title: "Books"
  }
];

export const dasboardFullDateFormatter = (date: string) => {
  if (!date) {
    return;
  }
  return moment
    .utc(date, "YYYY-MM-DD HH:mm:ss")
    .local()
    .format("D-MMM-YYYY");
};

// Customizable Area End

class AllList extends AllListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleTabsChange = (event: any, newValue: any) => {
    this.setState({ tabs: newValue });
  };

  getFirstTab = (
    loading: any,
    sortingLoader: any,
    errorMsg: any,
    personList: any,
    classes: any,
    blogPerPage: any
  ) => {
    if (loading || errorMsg || sortingLoader) {
      return null;
    }
    return personList.length ? (
      <>
        <TableContainer component={Paper}>
          <Table style={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  {"Id"}
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  {"Image"}
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  About
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  Contributor Id
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  Organization Id
                </TableCell>
                {/* <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Total Lectures</TableCell> */}
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="right"
                >
                  {"Actions"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {personList.map((row: any) => (
                <TableRow key={row.id} className={classes.lastTableRow}>
                  <TableCell
                    style={{ fontFamily: "Poppins-SemiBold" }}
                    align="left"
                  >
                    {row.id}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Poppins-SemiBold" }}
                    align="left"
                  >
                    {
                      
                      <img 
                        src={
                          row.attributes.image ? row.attributes.image
                          : require('../../../Gallery/assets/avatar.png')
                        } 
                        height="40px"
                        width="40px"
                        style={{
                          borderRadius: '50%'
                        }} 
                      />
                    }
                  </TableCell>
                  <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                    {row.attributes.name}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                    <div
                      style={{ 
                        fontFamily: "Poppins-Light" ,
                        whiteSpace: 'nowrap',
                        width: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }} 
                      title={row.attributes.about}
                    >
                      {row.attributes.about}
                    </div>
                  </TableCell>
                  <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                    {row.attributes.instructor_id}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                    {row.attributes.organisation_id ? row.attributes.organisation_id : '---' }
                  </TableCell>
                  {/* @ts-ignore */}
                  {/* <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">{row?.attributes?.no_of_videos}</TableCell> */}
                  <TableCell align="right">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <IconButton
                        onClick={() => this.goToEditPage(row.id, 'EditPeople')}
                        style={{
                          height: "45px",
                          width: "45px",
                          borderRadius: "5px",
                          marginLeft: "25px"
                        }}
                      >
                        <img src={EditIcon} height={"20px"} />
                      </IconButton>
                      <DeleteOutline 
                        onClick={() => this.deleteList(row.id, 'person')}
                        style={{
                          color: '#bdbdbd',
                          marginTop: '10px',
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          {
            this.getPersonPagination(loading, this.state.personMeta, classes)
          }
        </div>
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px"
        }}
      >
        <Typography
          style={{
            fontFamily: "Poppins-Bold",
            fontSize: "16px"
          }}
        >
          No Data Found!
        </Typography>
      </div>
    );
  };

  getSecondTab = (
    loading: any,
    sortingLoader: any,
    errorMsg: any,
    orgList: any,
    classes: any,
    blogPerPage: any
  ) => {
    if (loading || errorMsg || sortingLoader) {
      return null;
    }
    return orgList.length ? (
      <>
        <TableContainer component={Paper}>
          <Table style={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  {"Org Id"}
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  Image
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  About
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  Category
                </TableCell>
                {/* <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Total Lectures</TableCell> */}
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="right"
                >
                  {"Actions"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orgList.map((row: any) => (
                <TableRow key={row.id} className={classes.lastTableRow}>
                  <TableCell
                    style={{ fontFamily: "Poppins-SemiBold" }}
                    align="left"
                  >
                    {row.attributes.id}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                    {
                        
                      <img 
                        src={
                          row.attributes.image ? row.attributes.image
                          : require('../../../Gallery/assets/avatar.png')
                        } 
                        height="40px"
                        width="40px"
                        style={{
                          borderRadius: '50%'
                        }} 
                      />
                    }
                  </TableCell>
                  <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                    {row.attributes.name}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                    <div
                      style={{ 
                        fontFamily: "Poppins-Light" ,
                        whiteSpace: 'nowrap',
                        width: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }} 
                      title={row.attributes.about}
                    >
                      {row.attributes.about}
                    </div>
                  </TableCell>
                  {/* @ts-ignore */}
                  <TableCell 
                    style={{ 
                      fontFamily: "Poppins-Light" 
                    }} 
                    align="left"
                  >
                    {row.attributes.category.name}
                  </TableCell>
                  <TableCell align="right">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <IconButton
                        onClick={() => this.goToEditPage(row.id, 'EditOrganisation')}
                        style={{
                          height: "45px",
                          width: "45px",
                          borderRadius: "5px",
                          marginLeft: "25px"
                        }}
                      >
                        <img src={EditIcon} height={"20px"} />
                      </IconButton>
                      <DeleteOutline 
                        onClick={() => this.deleteList(row.id, 'org')}
                        style={{
                          color: '#bdbdbd',
                          marginTop: '10px',
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          {
            this.getOrgPagination(loading, this.state.orgMeta, classes)
          }
        </div>
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px"
        }}
      >
        <Typography
          style={{
            fontFamily: "Poppins-Bold",
            fontSize: "16px"
          }}
        >
          No Data Found!
        </Typography>
      </div>
    );
  };

  getThirdTab = (
    loading: any,
    sortingLoader: any,
    errorMsg: any,
    filmList: any,
    classes: any,
    blogPerPage: any
  ) => {
    if (loading || errorMsg || sortingLoader) {
      return null;
    }
    return filmList.length ? (
      <>
        <TableContainer component={Paper}>
          <Table style={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  {"Id"}
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  Image
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  Name
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  {"About"}
                </TableCell>
                <TableCell 
                  style={{ 
                    color: "#8991a4", 
                    fontFamily: "Poppins-Light" 
                  }} 
                  align="left"
                >
                  Url
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="right"
                >
                  {"Actions"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filmList.map((row: any) => (
                <TableRow key={row.id} className={classes.lastTableRow}>
                  <TableCell
                    style={{ fontFamily: "Poppins-SemiBold" }}
                    align="left"
                  >
                    {row.attributes.id}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                    {   
                      <img 
                        src={
                          row.attributes.image ? row.attributes.image
                          : require('../../../Gallery/assets/avatar.png')
                        } 
                        height="40px"
                        width="40px"
                        style={{
                          borderRadius: '50%'
                        }} 
                      />
                    }
                  </TableCell>
                  <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                    {row.attributes.name}
                  </TableCell>
                  <TableCell align="left">
                    <div
                      style={{ 
                        fontFamily: "Poppins-Light" ,
                        whiteSpace: 'nowrap',
                        width: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }} 
                      title={row.attributes.about}
                    >
                      {row.attributes.about}
                    </div>
                  </TableCell>
                  {/* @ts-ignore */}
                  <TableCell 
                    style={{ 
                      fontFamily: "Poppins-Light" 
                    }} 
                    align="left"
                  >
                    <div
                      style={{ 
                        fontFamily: "Poppins-Light" ,
                        whiteSpace: 'nowrap',
                        width: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }} 
                      title={row.attributes.url}
                    >
                      <a
                        style={{
                          color: palette.primary.main
                        }}
                        href={row.attributes.url}
                        target={'_blank'}
                      >
                        {row.attributes.url}
                      </a>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                  <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <IconButton
                        onClick={() => this.goToEditPage(row.id, 'EditFilm')}
                        style={{
                          height: "45px",
                          width: "45px",
                          borderRadius: "5px",
                          marginLeft: "25px"
                        }}
                      >
                        <img src={EditIcon} height={"20px"} />
                      </IconButton>
                      <DeleteOutline 
                        onClick={() => this.deleteList(row.id, 'film')}
                        style={{
                          color: '#bdbdbd',
                          marginTop: '10px',
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          {
            this.getFilmPagination(loading, this.state.orgMeta, classes)
          }
        </div>
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px"
        }}
      >
        <Typography
          style={{
            fontFamily: "Poppins-Bold",
            fontSize: "16px"
          }}
        >
          No Data Found!
        </Typography>
      </div>
    );
  };
  getFourthTab = (
    loading: any,
    sortingLoader: any,
    errorMsg: any,
    bookList: any,
    classes: any,
    blogPerPage: any
  ) => {
    if (loading || errorMsg || sortingLoader) {
      return null;
    }
    return bookList.length ? (
      <>
        <TableContainer component={Paper}>
          <Table style={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  {"Id"}
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  Image
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  Title
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="left"
                >
                  About
                </TableCell>
                <TableCell 
                  style={{ 
                    color: "#8991a4", 
                    fontFamily: "Poppins-Light" 
                  }} 
                  align="left"
                >
                  Link
                </TableCell>
                <TableCell
                  style={{ color: "#8991a4", fontFamily: "Poppins-Light" }}
                  align="right"
                >
                  {"Actions"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookList.map((row: any) => (
                <TableRow key={row.id} className={classes.lastTableRow}>
                  <TableCell
                    style={{ fontFamily: "Poppins-SemiBold" }}
                    align="left"
                  >
                    {row.attributes.id}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                    {   
                      <img 
                        src={
                          row.attributes.image ? row.attributes.image
                          : require('../../../Gallery/assets/avatar.png')
                        } 
                        height="40px"
                        width="40px"
                        style={{
                          borderRadius: '50%'
                        }} 
                      />
                    }
                  </TableCell>
                  <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                    {row.attributes.title}
                  </TableCell>
                  <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                    <div
                      style={{ 
                        fontFamily: "Poppins-Light" ,
                        whiteSpace: 'nowrap',
                        width: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }} 
                      title={row.attributes.about}
                    >
                      {row.attributes.about}
                    </div>
                  </TableCell>
                  {/* @ts-ignore */}
                  <TableCell 
                    align="left"
                  >
                    <div
                      style={{ 
                        fontFamily: "Poppins-Light" ,
                        whiteSpace: 'nowrap',
                        width: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }} 
                      title={row.attributes.url}
                    >
                      <a
                        href={row.attributes.url}
                        target={'_blank'}
                        style={{
                          color: palette.primary.main
                        }}
                      >
                        {row.attributes.url}
                      </a>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                  <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <IconButton
                        onClick={() => this.goToEditPage(row.id, 'EditBook')}
                        style={{
                          height: "45px",
                          width: "45px",
                          borderRadius: "5px",
                          marginLeft: "25px"
                        }}
                      >
                        <img src={EditIcon} height={"20px"} />
                      </IconButton>
                      <DeleteOutline 
                        onClick={() => this.deleteList(row.id, 'book')}
                        style={{
                          color: '#bdbdbd',
                          marginTop: '10px',
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          {
            this.getBookPagination(loading, this.state.bookMeta, classes)
          }
        </div>
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px"
        }}
      >
        <Typography
          style={{
            fontFamily: "Poppins-Bold",
            fontSize: "16px"
          }}
        >
          No Data Found!
        </Typography>
      </div>
    );
  };

  getPersonPagination = (
    loading: any,
    apiData: any,
    classes: any
  ) => {
    if (
      loading ||
      (apiData.total_pages as number) <= 1
    ) {
      return null;
    }
    return this.state.personList.length ? (
      // @ts-ignore
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "30px",
          marginTop: "30px"
        }}
      >
        <Pagination
          variant="outlined"
          size="large"
          count={apiData.total_pages}
          // defaultPage={1}
          page={apiData.current_page}
          onChange={this.changePersonPage}
          className={classes.root}
        />
      </Box>
    ) : null;
  };

  getOrgPagination = (
    loading: any,
    apiData: any,
    classes: any
  ) => {
    if (
      loading ||
      !this.state.orgList.length ||
      apiData.total_pages < 2
    ) {
      return null;
    }
    return (
      // @ts-ignore
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "30px",
          marginTop: "30px"
        }}
      >
        <Pagination
          variant="outlined"
          size="large"
          count={apiData.total_pages}
          // defaultPage={1}
          page={apiData.current_page}
          onChange={this.changeOrgPage}
          className={classes.root}
        />
      </Box>
    )
  };

  getFilmPagination = (
    loading: any,
    apiData: any,
    classes: any
  ) => {
    if (
      loading ||
      !this.state.filmList.length ||
      apiData.total_pages < 2
    ) {
      return null;
    }
    return (
      // @ts-ignore
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "30px",
          marginTop: "30px"
        }}
      >
        <Pagination
          variant="outlined"
          size="large"
          count={apiData.total_pages}
          // defaultPage={1}
          page={apiData.current_page}
          onChange={this.changeFilmPage}
          className={classes.root}
        />
      </Box>
    )
  };

  getBookPagination = (
    loading: any,
    apiData: any,
    classes: any
  ) => {
    if (
      loading ||
      !this.state.bookList.length ||
      apiData.total_pages < 2
    ) {
      return null;
    }
    return (
      // @ts-ignore
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "30px",
          marginTop: "30px"
        }}
      >
        <Pagination
          variant="outlined"
          size="large"
          count={apiData.total_pages}
          // defaultPage={1}
          page={apiData.current_page}
          onChange={this.changeBookPage}
          className={classes.root}
        />
      </Box>
    )
  };

  getAppBar = (classes: any) => {
    const { searchList } = this.state;
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logo}>
            <img
              src={require("../../../../components/src/logo.svg")}
              height={70}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              padding: "10px"
            }}
          >
            <div>
              <Typography
                style={{
                  fontFamily: "Poppins-SemiBold",
                  color: "#353535",
                  textTransform: "capitalize"
                }}
                className={classes.name}
              >
                Hi {window.localStorage.getItem("userName")},
              </Typography>
              <Typography
                style={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "40px",
                  color: "#353535"
                }}
                className={classes.welcome}
              >
                Welcome back
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <div
                  style={{
                      position: 'relative'
                  }}
              >
              <CustomInput
                  passwordfield={false}
                  placeholder={"Search"}
                  icon={require("../../assets/search.png")}
                  iconHeight={"24px"}
                  height={48}
                  borderRadius={6}
                  value={this.state.searchQuery}
                  onChange={(e: any) =>
                      this.handleInputChange(e)
                  }
              />
              {
                  this.state.openSearch ?
                      <div
                      style={{
                          position: 'absolute',
                          zIndex: 999999,
                          color: '#000',
                          maxHeight: '200px',
                          overflowY: 'scroll',
                          boxShadow: '-1px -1px 19px -3px rgba(0,0,0,0.6)',
                          borderRadius: '8px',
                          minWidth: '250px',
                          background: '#fff',
                          padding: '10px',
                          top: '80px'
                      }}
                      >
                      {
                          !this.state.searchLoading ?
                              searchList.blogs.length ||
                              searchList.videos.length ||
                              searchList.podcasts.length ||
                              searchList.courses.length ||
                              searchList.lets_discover.books ||
                              searchList.lets_discover.films ||
                              searchList.lets_discover.organisations ||
                              searchList.lets_discover.people?
                              <AdminSearch 
                                  searchList={searchList}
                                  navigation={this.props.navigation}
                              />
                              : 'No search found!'
                          : <div
                              style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                              }}
                          >
                              <CircularProgress
                              style={{
                                  color: palette.primary.main
                              }}
                              />
                          </div>
                      }
                      <div
                          style={{
                          borderTop: '1px solid #d3d3d3',
                          marginTop: '10px'
                          }}
                      >
                          <Typography
                          style={{
                              color: palette.primary.main,
                              fontSize: '12px',
                              fontFamily: 'Poppins-SemiBold',
                              cursor: 'pointer',
                              textAlign: 'center',
                              marginTop: '10px'
                          }}
                          onClick={() => this.setState({openSearch: false})}
                          >
                          Cancel
                          </Typography>
                      </div>
                      </div>
                  : null
                  }
              </div>
              <IconButton
                style={{
                  border: "1px solid #bdbdbd",
                  height: "45px",
                  width: "45px",
                  borderRadius: "5px",
                  marginTop: "25px",
                  marginLeft: "20px"
                }}
              >
                <img
                  src={require("../../assets/notification.png")}
                  height={"25px"}
                />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  getConfirmationModal = (classes: any) => {
    return (
      // @ts-ignore
      <Box className={classes.modalStyle}>
        <Typography
          style={{ fontFamily: "Poppins-SemiBold", fontSize: "1.5rem" }}
        >
          Are you sure you want to delete this?
        </Typography>
        {/* @ts-ignore */}
        <Box
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: "1.5rem"
          }}
        >
          <Button onClick={() => this.handleModalClose()} variant="outlined" style={{ width: "140px", color: "#F27024", border: "1px solid #F27024", textTransform: "initial", fontFamily: "Poppins-SemiBold", borderRadius: "5px" }}>Cancel</Button>
          {/* @ts-ignore */}
          <Button onClick={() => this.handleDeleteClose()} variant="outlined" style={{ width: "140px", color: "#fff", borderRadius: "5px", textTransform: "initial", fontFamily: "Poppins-SemiBold", backgroundColor: "#F27024" }}>Delete</Button>
        </Box>
      </Box>
    );
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
      deleteId: '',
      deleteType: ''
    })
  }

  closeAlertBox = () => {
    this.setState({
      openAlert: false,
      errorMsg: ''
    })
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const baseUrl = window.location.href.split("/", 3).pop();
    const {
      tabs,
      loading,
      sortingLoader,
      errorMsg,
      personList,
      orgList,
      bookList,
      filmList,
      openAlert,
      blogPerPage,
      modalOpen
    } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div>
        {/* @ts-ignore */}
        <Modal
          open={modalOpen}
          onClose={() => this.handleModalClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {this.getConfirmationModal(classes)}
        </Modal>
        {
          loading ?
            <CustomLoader 
              open={loading}
            />
          : null
        }
        {
          openAlert ? 
            <AlertModal 
              open={openAlert} 
              data={errorMsg}
              onClose={() => this.closeAlertBox()}
            />
          : null
        }
        <DashboardDrawer
          mobileOpen={this.state.mobileOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          // @ts-ignore
          navigation={this.props.navigation}
        />
        {/* @ts-ignore */}
        <Box className={classes.appBarWrap}>
          {this.getAppBar(classes)}
          {/* @ts-ignore */}
          <Box style={{ padding: "0 5%", marginBottom: "10%" }}>
            <Grid container>
              <Grid lg={12} className={classes.tabsBox}>
                <Tabs
                  className={classes.tabs_main}
                  value={tabs}
                  onChange={this.handleTabsChange}
                >
                  <Tab className={classes.headingTabs} label="Persons" onClick={() => {this.getPersonList(1)}}/>
                  <Tab className={classes.headingTabs} label="Organizations" onClick={() => {this.getOrgList(1)}} />
                  <Tab className={classes.headingTabs} label="Films" onClick={() => {this.getFilmList(1)}}/>
                  <Tab className={classes.headingTabs} label="Books" onClick={() => {this.getBookList(1)}}/>
                </Tabs>
              </Grid>
            </Grid>
            {tabs === 0
              ? this.getFirstTab(
                  loading,
                  sortingLoader,
                  errorMsg,
                  personList,
                  classes,
                  blogPerPage
                )
              : null}
              {
                tabs === 1
                ? this.getSecondTab(
                    loading,
                    sortingLoader,
                    errorMsg,
                    orgList,
                    classes,
                    blogPerPage
                  )
                : null}
              {
                tabs === 2
                ? this.getThirdTab(
                    loading,
                    sortingLoader,
                    errorMsg,
                    filmList,
                    classes,
                    blogPerPage
                  )
                : null
              }
            {tabs === 3
              ? this.getFourthTab(
                  loading,
                  sortingLoader,
                  errorMsg,
                  bookList,
                  classes,
                  blogPerPage
                )
              : null}
          </Box>
        </Box>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// export default withStyles(styles)(LetsRead);
export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      logo: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
          display: "none"
        }
      },
      menuButton: {
        marginRight: theme.spacing(2),
        color: palette.primary.main,
        [theme.breakpoints.up("md")]: {
          display: "none"
        }
      },
      appBar: {
        background: "#fff",
        boxShadow: "none",
        [theme.breakpoints.up("md")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
          background: "#fff",
          boxShadow: "none"
        }
      },
      appBarWrap: {
        [theme.breakpoints.up("md")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth
        }
      },
      welcome: {
        [theme.breakpoints.down("sm")]: {
          display: "none"
        }
      },
      name: {
        fontSize: "24px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "20px",
          fontSize: "16px"
        }
      },
      tabsBox: {
        // flex: 1,
        // flexDirection: 'row',
        // justifyContent: 'space-between',
        width: "100%",
        borderBottom: "2px solid",
        borderBottomColor: "rgba(0, 0, 0, 0.12)"
      },
      tabs_main: {
        "& .MuiTabs-indicator": {
          backgroundColor: palette.primary.main,
          borderRadius: "1.5px",
          height: "4px"
        },
        "& .Mui-selected": {
          color: palette.primary.main
        }
      },
      headingTabs: {
        fontSize: "24px",
        fontWeight: 600,
        fontFamily: palette.fontFamily.main,
        width: "25%",
        textTransform: "inherit"
        // minWidth: '50% !important'
      },
      lastTableRow: {
        "&:last-child td, &:last-child th": { border: 0 }
      },
      triangle: {
        overflow: "visible",
        filter: "drop-shadow(0px 1px 4px rgba(0,0,0,0.06))",
        marginTop: "49px",
        marginLeft: "12px",
        borderRadius: "10px",
        "& .MuiAvatar-root": {
          width: "32px ",
          height: "32px",
          marginLeft: "-0.5px ",
          marginRight: "1px"
        },
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: "0px",
          right: "20px ",
          width: "15px",
          height: "15px ",
          backgroundColor: "#ffffff",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0
        }
      },
      root: {
        color: "#F27024 !important",
        "& .Mui-selected": {
          background: "#F27024",
          color: "white",
          fontWeight: "bold"
        },
        ul: {
          "& .MuiPaginationItem-root": {
            color: "#F27024"
          }
        }
      },
      modalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "496px",
        height: "200px",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "24px",
        padding: "10px 5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      statModalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "800px",
        height: "360px",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "24px",
        padding: "10px 5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      previewModalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "796px",
        maxHeight: "80vh",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "24px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      menuItemStyle: {
        fontFamily: "Poppins-Light",
        "&:hover": {
          textAlign: "right",
          color: "#d53636",
          backgroundColor: "#ffffff"
        }
      }
    })
  )(AllList)
);
// Customizable Area End
