import { Box, Typography } from "@material-ui/core";
import React from "react";
import { BlogType } from "../../blocks/ContentManagement/src/Interfaces.web";
import { VideoType } from "../../blocks/VideoManagement/src/Interfaces.web";
 interface Props {
    item?: BlogType;
    video?: VideoType;
    type: string;
    bold: boolean;
    italic: boolean;
    underlined: boolean;
    alignment: string;
}

type textAlignType = "start" | "end" | "left" | "right" | "center" | "justify" | "match-parent"

type justifyContentType = "center" | "flex-start" | "flex-end" | "space-between"


const SingleArticleBody = ({ item, type, video, bold, italic, underlined, alignment }: Props) => {
  let textAlignment: textAlignType;

  if (alignment === 'center') {
    textAlignment = 'center';
  } else if (alignment === 'right') {
    textAlignment = 'right';
  } else if (alignment === 'justify') {
    textAlignment = 'justify';
  } else {
    textAlignment = 'left';
  }

  let justifyContentVar: justifyContentType;

if (alignment === 'center') {
  justifyContentVar = 'center';
} else if (alignment === 'left') {
  justifyContentVar = 'flex-start';
} else if (alignment === 'right') {
  justifyContentVar = 'flex-end';
} else if (alignment === 'justify') {
  justifyContentVar = 'space-between';
} else {
  justifyContentVar = 'flex-start';
}

    return (
      // @ts-ignore
        <Box
          style={{
            display: "flex",
            flexDirection: 'column'
          }}
        >
         {type === "blog" ?  
          <div
            style={{
              marginTop: '30px',
            }}
          >
            {
              item?.attributes?.components && item?.attributes?.components.map((blogData: any, index: any) => {
                return (
                  // <React.Fragment key={index}>
                  //   {/* @ts-ignore */}
                  //   <Box
                  //     key={index}
                  //   >
                  //     {blogData.attributes.heading.text ? 
                  //       <span
                  //         style={{
                  //           marginTop: '20px',
                  //           textAlign:  textAlignment
                  //         }}
                  //       >
                  //         <Typography
                  //           style={{
                  //             fontFamily: bold ? 'Poppins-Bold' : 'Poppins-SemiBold',
                  //             fontSize: '32px',
                  //             lineHeight: '1.5px',
                  //             letterSpacing: '0.18px',
                  //             color: '#353535',
                  //             textDecoration: underlined ? 'underline' : '',
                  //             minHeight: '70px',
                  //           }}
                  //         >
                  //           {
                  //             italic ?
                  //             <i>
                  //               {blogData.attributes.heading.text}
                  //             </i>
                  //             : blogData.attributes.heading.text
                  //           }
                  //         </Typography>
                  //       </span>
                  //     : null
                  //     }
                  //   </Box>
                  //   {/* @ts-ignore */}
                  //   <Box>
                  //     {blogData.attributes.sub_heading.text ? 
                  //       <span
                  //         style={{
                  //           marginTop: '20px',
                  //           textAlign:  textAlignment
                  //         }}
                  //       >
                  //         <Typography
                  //           style={{
                  //             fontFamily: bold ? 'Poppins-Bold' : 'Poppins-SemiBold',
                  //             fontSize: '24px',
                  //             lineHeight: '1.5px',
                  //             letterSpacing: '0.18px',
                  //             color: '#353535',
                  //             textDecoration: underlined ? 'underline' : '',
                  //             minHeight: '70px',
                  //           }}
                  //         >
                  //           {
                  //             italic ?
                  //             <i>
                  //               {blogData.attributes.sub_heading.text}
                  //             </i>
                  //             : blogData.attributes.sub_heading.text
                  //           }
                  //         </Typography>
                  //       </span>
                  //     : null
                  //     }
                  //   </Box>
                  //   {/* @ts-ignore */}
                  //   <Box>
                  //     {blogData.attributes.paragraph.text ? 
                  //       <span
                  //         style={{
                  //           marginTop: '20px',
                  //           textAlign:  textAlignment
                  //         }}
                  //       >
                  //         <Typography
                  //           style={{
                  //             fontFamily: bold ? 'Poppins-Bold' : 'Poppins-SemiBold',
                  //             fontSize: '16px',
                  //             lineHeight: '1.5px',
                  //             letterSpacing: '0.18px',
                  //             color: '#353535',
                  //             textDecoration: underlined ? 'underline' : '',
                  //             // minHeight: '70px',
                  //           }}
                  //         >
                  //           {
                  //             italic ?
                  //             <i>
                  //               {blogData.attributes.paragraph.text}
                  //             </i>
                  //             : blogData.attributes.paragraph.text
                  //           }
                  //         </Typography>
                  //       </span>
                  //     : null
                  //     }
                  //   </Box>
                  //   {/* @ts-ignore */}
                  //   <Box
                  //     style={{
                  //       marginTop: '40px'
                  //     }}
                  //   >
                  //     {/* @ts-ignore */}
                  //     <Box
                  //       style={{
                  //         display: 'flex',
                  //         justifyContent:  justifyContentVar
                  //       }}
                  //     >
                  //       {
                  //         blogData.attributes.images.map((imgs: any, ind: any) => {
                  //           return (
                  //             <img 
                  //               src={imgs}
                  //               key={ind}
                  //               width={'200px'}
                  //               style={{
                  //                 borderRadius: '10px',
                  //                 margin: '10px'
                  //               }}
                  //             />
                  //           )
                  //         })
                  //       }
                  //     </Box>
                  //     {
                  //       <span
                  //         style={{
                  //           marginTop: '50px',
                  //           textAlign:  textAlignment
                  //         }}
                  //       >
                  //         <Typography
                  //           style={{
                  //             color: '#8991a4',
                  //             fontSize: '14px',
                  //             fontFamily: bold ? 'Poppins-Bold' : 'Poppins-Light',
                  //             textDecoration: underlined ? 'underline' : ''
                  //           }}
                  //         >
                  //           {
                  //             italic ? <i>{blogData.attributes.caption.text}</i>
                  //             : blogData.attributes.caption.text
                  //           }
                  //         </Typography>
                  //       </span>
                  //     }
                  //   </Box>
                  // </React.Fragment>
                  <React.Fragment key={index}>
                    {blogData.attributes.heading.text && (
                      <Box style={{ marginTop: '20px', textAlign: textAlignment }}>
                        <Typography
                          style={{
                            fontFamily: bold ? 'Poppins-Bold' : 'Poppins-SemiBold',
                            fontSize: '32px',
                            // lineHeight: '1.5px',
                            letterSpacing: '0.18px',
                            color: '#353535',
                            textDecoration: underlined ? 'underline' : '',
                            minHeight: '70px',
                          }}
                        >
                          {italic ? <i>{blogData.attributes.heading.text}</i> : blogData.attributes.heading.text}
                        </Typography>
                      </Box>
                    )}

                    {blogData.attributes.sub_heading.text && (
                      <Box style={{ marginTop: '20px', textAlign: textAlignment }}>
                        <Typography
                          style={{
                            fontFamily: bold ? 'Poppins-Bold' : 'Poppins-SemiBold',
                            fontSize: '24px',
                            // lineHeight: '1.5px',
                            letterSpacing: '0.18px',
                            color: '#353535',
                            textDecoration: underlined ? 'underline' : '',
                            minHeight: '70px',
                          }}
                        >
                          {italic ? <i>{blogData.attributes.sub_heading.text}</i> : blogData.attributes.sub_heading.text}
                        </Typography>
                      </Box>
                    )}

                    {blogData.attributes.paragraph.text && (
                      <Box style={{ marginTop: '20px', textAlign: textAlignment }}>
                        <Typography
                          style={{
                            fontFamily: bold ? 'Poppins-Bold' : 'Poppins-SemiBold',
                            fontSize: '16px',
                            // lineHeight: '1.5px',
                            letterSpacing: '0.18px',
                            color: '#353535',
                            textDecoration: underlined ? 'underline' : '',
                          }}
                        >
                          {italic ? <i>{blogData.attributes.paragraph.text}</i> : blogData.attributes.paragraph.text}
                        </Typography>
                      </Box>
                    )}

                    <Box style={{ marginTop: '40px' }}>
                      <Box style={{ display: 'flex', justifyContent: justifyContentVar }}>
                        {blogData.attributes.images.map((imgs: any, ind: any) => (
                          <img
                            src={imgs}
                            key={ind}
                            width={'200px'}
                            style={{ borderRadius: '10px', margin: '10px' }}
                          />
                        ))}
                      </Box>

                      {blogData.attributes.caption.text && (
                        <span style={{ marginTop: '50px', textAlign: textAlignment }}>
                          <Typography
                            style={{
                              color: '#8991a4',
                              fontSize: '14px',
                              fontFamily: bold ? 'Poppins-Bold' : 'Poppins-Light',
                              textDecoration: underlined ? 'underline' : '',
                            }}
                          >
                            {italic ? <i>{blogData.attributes.caption.text}</i> : blogData.attributes.caption.text}
                          </Typography>
                        </span>
                      )}
                    </Box>
                  </React.Fragment>

                )
              })
            }
          </div>
         : 
          <div style={{fontFamily: "Poppins-Light", lineHeight: "1.75", color: "#353535"}} dangerouslySetInnerHTML={{ __html: video?.attributes?.about as string }} />}
    </Box>
  )
}
export default SingleArticleBody;