import React from "react";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Toolbar,
  AppBar,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";
// @ts-ignore
import AddInstructorDropZone from "../../../../components/src/AddInstructorDropZone";
import MenuIcon from "@material-ui/icons/Menu";
import EditBookController, {Props} from "./EditBookController";
import { palette } from "../../../../components/src/theme";
import {
  withTheme,
  withStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import CustomLoader from "../../../../components/src/CustomLoader";
import AlertModal from "../../../../components/src/AlertModal";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const drawerWidth = 240;

// Customizable Area End

class EditBook extends EditBookController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getAppBar = (classes: any) => {
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logo}>
            <img
              src={require("../../../../components/src/logo.svg")}
              height={70}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              padding: "10px"
            }}
          >
            <div>
              <Typography
                style={{
                  fontFamily: "Poppins-SemiBold",
                  fontSize: "25px",
                  color: "#353535",
                  marginTop: "25px"
                }}
                className={classes.welcome}
              >
                Edit Book
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Button
                variant="contained"
                onClick={() => this.validation()}
                style={{
                  backgroundColor: "#F27024",
                  color: "#ffffff",
                  marginTop: "25px",
                  marginLeft: "20px",
                  textTransform: "initial",
                  fontFamily: "Poppins-Bold"
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  getForm = (classes: any) => {
    const {
      inputError,
      bookTitle,
      bookAbout,
      bookUrl,
    } = this.state
    return (
      // @ts-ignore
      <Box>
        <div
          style={{
            padding: "28px 48px 40px",
            boxShadow: "0 0 7px 0 rgba(0, 0, 0, 0.12)",
            marginTop: "20px",
            marginBottom: "20px"
          }}
        >
          <TextField
            fullWidth
            name="title"
            value={bookTitle}
            onChange={(e) => this.handleTitleChange(e)}
            InputProps={{
              // className: classes.input,
              disableUnderline: true,
              style: {
                height: "64px",
                border: "1px solid #e6e8f0",
                borderRadius: "9px",
                padding: "16px 36px 16px 24px",
                fontSize: "20px"
              }
            }}
            style={{
              fontSize: "20px",
              fontFamily: "Poppins-Light",
              marginTop: "10px"
            }}
            placeholder="Name of the Book"
            error={inputError?.bookTitle === undefined || inputError?.bookTitle === "" ? false : true}
            helperText={inputError.bookTitle}
          />
          <TextField
            name="about"
            style={{
              fontSize: "16px",
              fontFamily: "Poppins-Light",
              paddingTop: "10px"
            }}
            multiline={true}
            minRows={7}
            fullWidth
            value={bookAbout}
            onChange={(e) => this.handleAboutChange(e)}
            inputProps={{ maxLength: 1250 }}
            placeholder="About the Book"
            InputProps={{
              className: classes.input,
              disableUnderline: true,
              style: {
                padding: "20px 24px 30px",
                fontSize: "20px"
              }
            }}
            error={inputError?.bookAbout === undefined || inputError?.bookAbout === "" ? false : true}
            helperText={inputError.bookAbout}
          />
          <TextField
            fullWidth
            name="link"
            value={bookUrl}
            onChange={(e) => this.handleLinkChange(e)}
            InputProps={{
              // className: classes.input,
              disableUnderline: true,
              style: {
                height: "64px",
                border: "1px solid #e6e8f0",
                borderRadius: "9px",
                padding: "16px 36px 16px 24px",
                fontSize: "20px"
              }
            }}
            style={{
              fontSize: "20px",
              fontFamily: "Poppins-Light",
              marginTop: "10px"
            }}
            placeholder="Link to buy/listen"
            error={inputError?.bookUrl === undefined || inputError?.bookUrl === "" ? false : true}
            helperText={inputError.bookUrl}
          />
          <AddInstructorDropZone
            error={inputError.bookImage}
            type={"image"}
            mainMediaUrl={this.state.previewImageUrl}
            comingFrom={'edit'}
            addInstructorProfile={(acceptedFiles: any) =>
              this.handleBookImageChange(acceptedFiles)
            }
          />
          <FormControl
            variant="outlined"
            fullWidth={true}
            style={{
              marginTop: "10px"
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-mutiple-checkbox"
              IconComponent={KeyboardArrowDownIcon}
              className={classes.select}
              displayEmpty
              inputProps={{
                'aria-label': 'Without label',
                classes: {
                  icon: classes.selectIcon
                }
              }}
              value={this.state.categoryId}
              onChange={(event: any) => this.handleCategoryChange(event)}
              // @ts-ignore
              renderValue={
                this.state.categoryId
                  ? null
                  : () => (
                      <span
                        style={{
                          fontFamily: "Poppins-Light",
                          color: "#7F8487"
                        }}
                      >
                        Select Category
                      </span>
                    )
              }
            >
              <MenuItem value="" disabled>
                Select Category
              </MenuItem>
              {this.state.categoryList.length
                ? this.state.categoryList.map((item: any, index: any) => (
                    <MenuItem
                      key={index}
                      value={item.id}
                      style={{
                        fontSize: "18px",
                        fontFamily: "Poppins-SemiBold"
                      }}
                    >
                      {item.attributes.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
          {
            this.state.inputError.categoryId ? 
              <span 
                style={{
                  color: '#f44336', 
                  fontFamily: 'Poppins-Light',
                  fontSize: '12px'
                }}
              >
                {this.state.inputError.categoryId}
              </span> 
            : null
          }
          <FormControl
            variant="outlined"
            fullWidth={true}
            style={{
              marginTop: "10px"
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-mutiple-checkbox"
              IconComponent={KeyboardArrowDownIcon}
              className={classes.select}
              displayEmpty
              inputProps={{
                'aria-label': 'Without label',
                classes: {
                  icon: classes.selectIcon
                }
              }}
              value={this.state.personId}
              onChange={(event: any) => this.handlePersonChange(event)}
              // @ts-ignore
              renderValue={
                this.state.personId
                  ? null
                  : () => (
                      <span
                        style={{
                          fontFamily: "Poppins-Light",
                          color: "#7F8487"
                        }}
                      >
                        Select Person
                      </span>
                    )
              }
            >
              <MenuItem value="" disabled>
                Select Person
              </MenuItem>
              {this.state.personList.length
                ? this.state.personList.map((item: any, index: any) => (
                    <MenuItem
                      key={index}
                      value={item.id}
                      style={{
                        fontSize: "18px",
                        fontFamily: "Poppins-SemiBold"
                      }}
                    >
                      {item.attributes.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
          {
            this.state.inputError.personId ? 
              <span 
                style={{
                  color: '#f44336', 
                  fontFamily: 'Poppins-Light',
                  fontSize: '12px'
                }}
              >
                {this.state.inputError.personId}
              </span> 
            : null
          }
        </div>
      </Box>
    );
  };

  closeAlertBox = () => {
    this.setState({
        openAlert: false,
        alertMsg: ''
    })
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { 
      mobileOpen,
      openAlert,
      loading,
      alertMsg
    } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div>
        {
          loading ?
            <CustomLoader
              open={loading}
            />
          : null
        }
        {
          openAlert ? 
            <AlertModal 
              open={openAlert} 
              data={alertMsg}
              onClose={() => this.closeAlertBox()}
            />
          : null
        }
        <DashboardDrawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          // @ts-ignore
          navigation={this.props.navigation}
        />
        {/* @ts-ignore */}
        <Box className={classes.appBarWrap}>
          {this.getAppBar(classes)}
          {/* @ts-ignore */}
          <Box
            style={{
              padding: "0 4%",
              marginBottom: "10%",
              marginTop: "20px"
            }}
          >
            {this.getForm(classes)}
          </Box>
        </Box>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// export default withStyles(styles)(LetsRead);
export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      input: {
        width: "100%",
        height: "100%",
        border: "1px solid rgba(82,92, 229, 0.12)",
        borderRadius: "7px",
        textDecoration: "none",
        fontSize: "1em",
        fontFamily: "Poppins-Light",
        backgroundColor: "white",
        color: "#000 !important",
        padding: "5px 1rem", //
        "& > .MuiInputBase-input": {
          height: "100%"
        },
        "&  .MuiFormHelperText-root.Mui-error": {
          fontFamily: "Poppins-Light"
        }
      },
      logo: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
          display: "none"
        }
      },
      menuButton: {
        marginRight: theme.spacing(2),
        color: palette.primary.main,
        [theme.breakpoints.up("md")]: {
          display: "none"
        }
      },
      appBar: {
        background: "#fff",
        boxShadow: "none",
        [theme.breakpoints.up("md")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
          background: "#fff",
          boxShadow: "none"
        }
      },
      appBarWrap: {
        [theme.breakpoints.up("md")]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth
        }
      },
      welcome: {
        [theme.breakpoints.down("sm")]: {
          display: "none"
        }
      },
      name: {
        fontSize: "24px",
        [theme.breakpoints.down("sm")]: {
          marginTop: "20px",
          fontSize: "16px"
        }
      },
      tabsBox: {
        // flex: 1,
        // flexDirection: 'row',
        // justifyContent: 'space-between',
        width: "100%",
        borderBottom: "2px solid",
        borderBottomColor: "rgba(0, 0, 0, 0.12)"
      },
      tabs_main: {
        "& .MuiTabs-indicator": {
          backgroundColor: palette.primary.main,
          borderRadius: "1.5px",
          height: "4px"
        },
        "& .Mui-selected": {
          color: palette.primary.main
        }
      },
      headingTabs: {
        fontSize: "24px",
        fontWeight: 600,
        fontFamily: palette.fontFamily.main,
        width: "25%",
        textTransform: "inherit"
        // minWidth: '50% !important'
      },
      lastTableRow: {
        "&:last-child td, &:last-child th": { border: 0 }
      },
      triangle: {
        overflow: "visible",
        filter: "drop-shadow(0px 1px 4px rgba(0,0,0,0.06))",
        marginTop: "49px",
        marginLeft: "12px",
        borderRadius: "10px",
        "& .MuiAvatar-root": {
          width: "32px ",
          height: "32px",
          marginLeft: "-0.5px ",
          marginRight: "1px"
        },
        "&:before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: "0px",
          right: "20px ",
          width: "15px",
          height: "15px ",
          backgroundColor: "#ffffff",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0
        }
      },
      root: {
        color: "#F27024 !important",
        "& .Mui-selected": {
          background: "#F27024",
          color: "white",
          fontWeight: "bold"
        },
        ul: {
          "& .MuiPaginationItem-root": {
            color: "#F27024"
          }
        }
      },
      modalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "496px",
        height: "200px",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "24px",
        padding: "10px 5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      statModalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "800px",
        height: "360px",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "24px",
        padding: "10px 5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      previewModalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "796px",
        maxHeight: "80vh",
        backgroundColor: "#ffffff",
        borderRadius: "6px",
        boxShadow: "24px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      menuItemStyle: {
        fontFamily: "Poppins-Light",
        "&:hover": {
          textAlign: "right",
          color: "#d53636",
          backgroundColor: "#ffffff"
        }
      }
    })
  )(EditBook)
);
// Customizable Area End
