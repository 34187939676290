import React from "react";
import {
    Box,
    Button,
    Grid,
    Typography
} from "@material-ui/core"
import { palette } from "./theme";
import { Link } from "react-router-dom";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FavouriteIcon from "./FavouriteIcon";


export default function PodCastVideo(props : any) {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        // @ts-ignore
        <Box>
            <Grid container>
                {/* @ts-ignore */}
                <Box
                    style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    {/* @ts-ignore */}
                    <Box>
                        <Typography
                            style={{
                                fontFamily: 'Poppins-SemiBold',
                                fontSize: !matches ? '3.5vw' : '1.8vw',
                            }}
                        >
                            Video Podcasts
                        </Typography>
                    </Box>
                    {
                        props.showSeeMore ?
                        // @ts-ignore 
                        <Box>
                            <Button
                                style={{
                                    color: palette.primary.main,
                                    fontFamily: 'Poppins-SemiBold',
                                }}
                            >
                                <Link
                                    style={{
                                        textDecoration: 'none',
                                        color: palette.primary.main,
                                        fontFamily: 'Poppins-SemiBold',
                                    }} 
                                    to={'/videopodcasts'}
                                >
                                    See more
                                </Link>
                            </Button>
                        </Box>
                        : 
                        null
                    }
                </Box>
            </Grid>
            {/* @ts-ignore */}
            <Box mt={5}>
                <Grid container spacing={5}>
                {
                    props.vErrorMsg ? 
                        // @ts-ignore
                        <Box 
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flex: 1,
                                marginBottom: '50px'
                            }}
                        >
                            <Typography
                                style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    color: '#000',
                                    fontSize: '18px'
                                }}
                            >
                                No video podcasts available!
                            </Typography>
                        </Box>
                        : null
                    }
                    {
                        props.loading
                        ? 
                        // @ts-ignore
                        <Box 
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flex: 1,
                                marginBottom: '50px'
                            }}
                        >
                            <Typography
                                style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    color: '#000',
                                    fontSize: '18px'
                                }}
                            >
                                Loading...
                            </Typography>
                        </Box>
                        : null
                    }
                    {
                        props?.imgUrls.length ? props?.imgUrls.map((item: any, index: any) => {
                            return (
                                <Grid md={3} sm={4} xs={12} item key={index} style={{position: 'relative'}}>
                                    <FavouriteIcon 
                                        isFavourite={item.attributes.favourite}
                                        position={'absolute'}
                                        right={'10px'}
                                        top={'10px'}
                                        makeFavourite={(status: any) => props.makeFavourite(item.id, status)}
                                        navigation={props.navigation}
                                    />
                                    {/* @ts-ignore */}
                                    <Box
                                        style={{
                                            position: 'relative',
                                            overflow: 'hidden',
                                            maxHeight: '50vh',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <img 
                                            width={'100%'} 
                                            src={item.attributes.poster ? item.attributes.poster : require('../../blocks/Gallery/assets/videoBg.jpg')} 
                                            style={{
                                                borderRadius: '8px',
                                                minHeight: '50vh',
                                                // maxHeight: '50vh'
                                            }} 
                                        />
                                        {/* @ts-ignore */}
                                        <Box
                                            style={{
                                                borderRadius: '8px',
                                                minHeight: '50vh',
                                                // maxHeight: '50vh',
                                                background: 'rgba(0,0,0,0.5)',
                                                position: 'absolute',
                                                zIndex: 99,
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                            }}
                                        >

                                        </Box>
                                        {/* @ts-ignore */}
                                        <Box 
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                zIndex: 99999,
                                                transform: 'translate(-50%, -50%)'
                                            }}
                                        >
                                            {/* @ts-ignore */}
                                            <Box
                                                style={{
                                                    height: '50px',
                                                    width: '50px',
                                                    borderRadius: '50%',
                                                    background: '#ffffff',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <PlayArrowRoundedIcon 
                                                    style={{
                                                        color: palette.primary.main,
                                                        fontSize: 30,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => props.getVideo(item.id)}
                                                    // fontSize="large"
                                                />
                                            </Box>
                                        </Box>
                                        {/* @ts-ignore */}
                                        <Box
                                            style={{
                                                position: 'absolute',
                                                zIndex: 9999,
                                                bottom: '15px',
                                                padding: '20px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontFamily: 'Poppins-Light',
                                                    color: '#ffffff',
                                                    fontSize: '1em'
                                                }}
                                                title={item.attributes.title}
                                            >
                                                {item.attributes.title.length > 70 ? item.attributes.title.substring(0, 70) + "..." : item.attributes.title}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        }) : null
                    }
                </Grid>
            </Box>
        </Box>
    )
}