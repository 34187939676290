import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Drawer, 
    IconButton, 
    Button, 
    List, 
    Box, 
    ListItem, 
    ListItemText,
    ListSubheader,
    useMediaQuery,
    useTheme,
    Collapse,
    Typography
} from '@material-ui/core';
//@ts-ignore
import MenuIcon from '@material-ui/icons/Menu';
//@ts-ignore
import ExpandLess from '@material-ui/icons/ExpandLess';
//@ts-ignore
import ExpandMore from '@material-ui/icons/ExpandMore';
//@ts-ignore
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import {Link} from 'react-router-dom';
import { isNull } from 'util';
export const configJSON = require("../../framework/src/config");
const axios = require('axios').default;

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const getCustomOptions = () => {
    const items = [
      {
        id: 1,
        title: "About",
        subMenu: "About 1",
        subMenuLink: '',
        subMenu2: "About 2",
        subMenuLink2: '',
        subMenu3: "",
        subMenuLink3: ""
      },
      {
        id: 2,
        title: "Let's Learn",
        subMenu: "All Courses",
        subMenuLink: '/AllCourses',
        subMenu2: "My Courses",
        subMenuLink2: '/MyCourses',
        subMenu3: "",
        subMenuLink3: ""
      },
      {
        id: 3,
        title: "Let's Talk",
        subMenu: "Podcasts",
        subMenuLink: '/podcast',
        subMenu2: "Video Podcasts",
        subMenuLink2: "/videopodcasts",
        subMenu3: "Audio Podcasts",
        subMenuLink3: "/audiopodcasts"
      },
      {
        id: 4,
        title: "Let's Read",
        subMenu: "Blog",
        subMenuLink: '/Blog',
        subMenu2: "",
        subMenuLink2: '',
        subMenu3: '',
        subMenuLink3: ''
      },
      {
        id: 5,
        title: "Let's Watch",
        subMenu: "Videos",
        subMenuLink: '/Videos',
        // subMenu2: "",
        // subMenuLink2: '',
        // subMenu3: '',
        // subMenuLink3: ''
      },
      {
        id: 6,
        title: "Let's Discover",
        subMenu: "All",
        subMenuLink: '/LetsDiscovers/user',
        // subMenu2: "Discover 2",
        // subMenuLink2: '',
        // subMenu3: '',
        // subMenuLink3: ''
      }
    ];
    return items;
  };

  const getCustomOptions2 = () => {
    const items = [
      {
        id: 1,
        title: "About",
        subMenu: "About 1",
        subMenuLink: '',
        subMenu2: "About 2",
        subMenuLink2: '',
        subMenu3: "",
        subMenuLink3: ""
      },
      {
        id: 2,
        title: "Let's Learn",
        subMenu: "All Courses",
        subMenuLink: '/AllCourses',
        subMenu2: "",
        subMenuLink2: '',
        subMenu3: "",
        subMenuLink3: ""
      },
      {
        id: 3,
        title: "Let's Talk",
        subMenu: "Podcasts",
        subMenuLink: '/podcast',
        subMenu2: "Video Podcasts",
        subMenuLink2: "/videopodcasts",
        subMenu3: "Audio Podcasts",
        subMenuLink3: "/audiopodcasts"
      },
      {
        id: 4,
        title: "Let's Read",
        subMenu: "Blog",
        subMenuLink: '/Blog',
      },
      {
        id: 5,
        title: "Let's Watch",
        subMenu: "Videos",
        subMenuLink: '/Videos',
        // subMenu2: "",
        // subMenuLink2: '',
        // subMenu3: '',
        // subMenuLink3: ''
      },
      {
        id: 6,
        title: "Let's Discover",
        subMenu: "All",
        subMenuLink: '/LetsDiscovers/user',
        // subMenu2: "Discover 2",
        // subMenuLink2: '',
        // subMenu3: '',
        // subMenuLink3: ''
      },
    ];
    return items;
  };

export default function DrawerComponent() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [open, setOpen] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const token = localStorage.getItem('token')

  const theme = useTheme();

  // const isMatch = useMediaQuery(theme.breakpoints.down('md'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (id: any) => {
    if(token){
      getMenuListWithHeader();
    } else {
      getMenuList();
    }
    //@ts-ignore
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    if(token){
      getMenuListWithHeader();
    } else {
      getMenuList();
    }
  }, []);

  const getMenuList = () => {
    axios.get(configJSON.baseURL + '/bx_block_admin/menus')
    .then(function (response: any) {
      // handle success
      const sortedMenu = response.data.data.sort((a: any, b: any) => {
        return (a.id - b.id);
      });
      setMenuList(sortedMenu);
    })
    .catch(function (error: any) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }

  const getMenuListWithHeader = () => {
    const header = {
      token: token
    }
    axios.get(configJSON.baseURL + '/bx_block_admin/menus', {headers: header})
    .then(function (response: any) {
      // handle success
      const sortedMenu = response.data.data.sort((a: any, b: any) => {
        return (a.id - b.id);
      });
      setMenuList(sortedMenu);
    })
    .catch(function (error: any) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader 
                  component="div" 
                  id="nested-list-subheader"
                  style={{
                    height: '56px',
                    boxShadow: '0 -4px 14px 0 rgba(0, 0, 0, 0.13)'
                  }}
                >
                    {/* @ts-ignore */}
                    <Box
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingTop: '20px'
                        }}
                    >
                        {/* @ts-ignore */}
                        {/* <Box> */}
                        {/* <img 
                          height={isSm ? '50px' : '80px'} 
                          width={'auto'} 
                          src={require('../src/logo.svg')} 
                          // onClick={() => history.push('/')}
                          style={{
                            cursor: 'pointer'
                          }}
                        /> */}
                          <Typography
                            style={{
                              fontSize: '16px',
                              fontFamily: 'Poppins-SemiBold',
                              color: '#353535',
                              textTransform: 'capitalize'
                            }}
                          >
                            {localStorage.getItem('userName') ? localStorage.getItem('userName') : 'Hi, Guest'}
                          </Typography>
                        {/* </Box> */}
                        <ClearRoundedIcon 
                          onClick={toggleDrawer(anchor, false)}
                          style={{
                            color: '#000',
                            cursor: 'pointer'
                          }}
                          fontSize='small'
                        />
                    </Box> 
                </ListSubheader>
            }
        >
        {
            menuList.length && menuList.map((item: any, index) => {
                return (
                    <>
                        {/* @ts-ignore */}
                        { item?.attributes?.is_active 
                          ? 
                          // @ts-ignore
                            <Box
                              // style={{
                              //   padding: '10px'
                              // }}
                              key={index}
                            >
                              <ListItem 
                                button 
                                onClick={() => handleClick(item.id)}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                              >
                                  {/* <ListItemText  
                                    style={{
                                      fontFamily: 'Poppins-SemiBold',
                                      fontSize: '18px'
                                    }}
                                    primary={item.attributes.name} 
                                  /> */}
                                  <span
                                    style={{
                                      fontFamily: 'Poppins-SemiBold',
                                      fontSize: '18px',
                                      color: '#545454'
                                    }}
                                  >
                                    {item.attributes.name}
                                  </span>
                                  {/* @ts-ignore */}
                                  {open[item.id] ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              {/* @ts-ignore */}
                              <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                                  <List component="div">
                                      {
                                        item.attributes.sub_menus.length && item.attributes.sub_menus.map((sub: any, index: any) => {
                                          return (
                                            <ListItem
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start'
                                              }}
                                              button
                                              onClick={toggleDrawer(anchor, false)}
                                              key={index}
                                            >
                                                {/* @ts-ignore */}
                                                <Link 
                                                  style={{
                                                    textDecoration: 'none',
                                                    color: '#545454',
                                                    fontFamily: 'Poppins-SemiBold',
                                                    fontSize: '18px',
                                                    marginLeft: '15px'
                                                  }} 
                                                  to={sub.attributes.frontend_url}
                                                >
                                                  <ListItemText primary={sub.attributes.name} />
                                                </Link>
                                            </ListItem>
                                          )
                                        })
                                      }
                                  </List>
                              </Collapse>
                            </Box>
                          : null
                        }
                    </>
                )
            })
        }
        {
          !localStorage.getItem('token') ?
          <ListItem 
            button 
            onClick={toggleDrawer(anchor, false)}
            style={{
              marginTop: '0px'
            }}
          >
              <Link
                style={{
                  fontFamily: 'Poppins-SemiBold',
                  textDecoration: 'none',
                  color: '#545454',
                  fontSize: '18px'
                }}
                to={'/Login'}
              >
                Login
              </Link>
          </ListItem>
          :
          <ListItem 
            button 
            onClick={() => {
              localStorage.clear(),
              getMenuList()
            }}
            style={{
              marginTop: '20px'
            }}
          >
              <Typography
                style={{
                  fontFamily: 'Poppins-SemiBold',
                  color: '#f02d2d',
                  fontSize: '18px'
                }}
              >
                Logout
              </Typography>
          </ListItem>
        }
       </List>
    </div>
  );

  return (
    <div>
      {(["left"] as Anchor[]).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
            >
                <MenuIcon />
            </IconButton>
          </Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
            <div
              style={{
                position: 'relative',
                height: '100%',
                padding: '15px'
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  bottom: '15px'
                }}
              >
                <Link
                  to={'/'}
                >
                  <img 
                    height={isSm ? '50px' : '80px'} 
                    width={'auto'} 
                    src={require('../src/logo.svg')} 
                    onClick={toggleDrawer(anchor, false)}
                    style={{
                      cursor: 'pointer'
                    }}
                  />
                </Link>
              </div>
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}