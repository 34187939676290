import React from "react";

// Customizable Area Start
// istanbul ignore file
import {
  Container,
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  CircularProgress
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import "./index.css";
import { palette } from "../../../components/src/theme";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LessonsCard from "./LessonsCard";
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
// @ts-ignore
import bgImage from "../assets/background.png";
import DonateEnrollModal from "../../../components/src/DonateEnrollModal"
import FavouriteIcon from "../../../components/src/FavouriteIcon";

// Customizable Area End

import ProjectNotesController, {
  Props,
  configJSON
} from "./ProjectNotesController";
import ReactPlayer from "react-player";
import Reviews from "../../../components/src/Reviews";
import AlertModal from "../../../components/src/AlertModal";


const BorderLinearProgress = withStyles((theme: any) => ({
  root: {
    height: 4,
    borderRadius: 3,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 3,
    backgroundColor: palette.primary.main,
  },
}))(LinearProgress);


class CourseDetails extends ProjectNotesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    //@ts-ignore
    this.playerRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const path: any = window.location.href.split('/', 5).pop()
    this.setState({ singleCourseID: path })
    this.getSingleCourse(path)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  playCarausal = (carausalId: any) => {
    this.setState({ carausalPlay: !this.state.carausalPlay, carausalId: carausalId })
  }

  pauseVideo = (playingState: any) => {
    this.setState({ carausalPlay: playingState })
  }


  closeAlertBox = () => {
    this.setState({
      openAlertSingleCourse: false,
      openFavIconAlertModal: false
    })
  }
  
  getIncompleteStatus = (value: any, index: any, array: any) => {
    return value.attributes?.student_lecture?.status !== 'complete'
  }
  
  getTopNav = (classes: any, navigation: any) => {
    return (
      <Grid className={classes.topNav}>
        <IconButton 
          onClick={
            () => navigation.goBack()
          } 
          className={
            classes.iconButton
          }
        >
          <KeyboardBackspaceRoundedIcon 
            className={
              classes.backArrowIcon
            }
          />
        </IconButton>
        <Typography className={classes.topHeading}>Get started with your course</Typography>
      </Grid>
    )
  }

  getCourseHeader = (
    classes: any, 
    singleCourseData: any, 
    carausalId: any, 
    carausalPlay: any,
    studentStatus: any,
    filteredLecture: any,
    lectureIndex: any
  ) => {
    return (
      <Box mt={5} mb={2}>
        {singleCourseData?.attributes?.enrolled ?
          <Grid container spacing={5} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid item sm={6} xs={12} className={classes.videoPlayerGrid}>
              <Box>
                {
                  singleCourseData.type === 'course' ?
                    <>
                      {/* @ts-ignore */}
                      <Box
                        style={{
                          position: 'relative',
                          borderRadius: '10px',
                          overflow: 'hidden',
                          boxShadow: '0 0 7px 1px rgba(0, 0, 0, 0.22)',
                        }}
                      >
                        {/* @ts-ignore */}
                        <ReactPlayer
                          playing={carausalId == singleCourseData.id ? carausalPlay : false}
                          height='80%'
                          controls={false}
                          width='100%'
                          // @ts-ignore
                          ref={this.playerRef}
                          url={singleCourseData.attributes?.preview_video ? singleCourseData.attributes?.preview_video : 'https://minio.b64156.dev.eastus.az.svc.builder.cafe/sbucket/twimtyaj4fwz64voegthw9n8lbs8'}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                          }}
                        >
                          <IconButton
                            style={{
                              color: palette.primary.main,
                              background: '#fff'
                            }}
                            onClick={() => this.playCarausal(singleCourseData.id)}
                          >
                            {
                              carausalPlay && carausalId == singleCourseData.id ?
                                <PauseRoundedIcon fontSize="large" />
                                : <PlayArrowRoundedIcon fontSize="large" />
                            }
                          </IconButton>
                        </div>
                      </Box>
                    </>
                    : <img
                      src={singleCourseData.attributes?.poster ? singleCourseData.attributes?.poster : require("../assets/background.png")}
                      style={{
                        width: "100%",
                        height: "100%"
                      }}
                    />
                }
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography style={{ fontFamily: palette.fontFamily.main, color: '#e6e6e6', fontSize: '20px' }}>Contributor</Typography>
              <Box mt={2} mb={2}>
                <Grid container>
                  <Grid md={2}>
                    <img style={{ borderRadius: '50%', height: '60px', width: '60px' }} src={singleCourseData?.attributes?.instructor_detail?.data?.attributes?.profile_photo ? singleCourseData?.attributes?.instructor_detail?.data?.attributes?.profile_photo : require("../assets/avatar.png")} />
                  </Grid>
                  <Grid className={classes.insName} md={9}>
                    <Typography className={classes.instructor_name}>{singleCourseData?.attributes?.instructor_detail?.data?.attributes?.full_name}</Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={2}>
                <Grid lg={8}>
                  <BorderLinearProgress variant="determinate" value={Math.round((studentStatus.filter((x: any) => x.status === 'complete').length / studentStatus.length) * 100)} />
                </Grid>
              </Box>
              <Box>
                <Grid className={classes.lessonRow} lg={8}>
                  {/* <Typography style={{ color: palette.course.primary , fontFamily: palette.fontFamily.main, }}>{studentStatus.filter((x:any) => x.status === 'complete').length}-{studentStatus.length} Lessons</Typography> */}
                  <Typography style={{ color: palette.course.primary, fontFamily: palette.fontFamily.main, }}>{studentStatus.length == 1 ? studentStatus.length + ' Lesson' : studentStatus.length + ' Lessons'}</Typography>
                </Grid>
              </Box>
              <Box mt={2} mb={2}>
                <Grid lg={8}>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid item xs={10}>
                      <Button
                        disabled={studentStatus.filter((x: any) => x.status === 'complete').length === singleCourseData.attributes?.lectures?.length ? true : false}
                        onClick={() => this.navigationToLesson(filteredLecture?.id)}
                        variant="contained"
                        className={classes.button}
                        style={{
                          background: studentStatus.filter((x: any) => x.status === 'complete').length === singleCourseData.attributes?.lectures?.length ? 'rgb(213, 96, 54, 0.65)' : '',
                          color: studentStatus.filter((x: any) => x.status === 'complete').length === singleCourseData.attributes?.lectures?.length ? 'rgba(0, 0, 0, 0.26)' : ''
                        }}
                      >
                        {filteredLecture?.attributes?.student_lecture?.status == 'in_progress'
                          ? `Continue Lesson ${lectureIndex + 1}`
                          :
                          filteredLecture?.attributes?.student_lecture?.status == 'not_started'
                            ? `Start Lesson ${lectureIndex + 1}`
                            : 'Completed'}
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <FavouriteIcon
                        startLesson={true}
                        isFavourite={singleCourseData?.attributes?.favourite}
                        position={'absolute'}
                        right={'1px'}
                        top={'10px'}
                        makeFavourite={(status: any) => this.makeFavourite(singleCourseData?.id, status)}
                        navigation={this.props.navigation}
                      />
                    </Grid>
                  </Box>
                  {/* <Button 
                    onClick={() => this.donate()} 
                    variant="contained" 
                    className={classes.button}
                    style={{
                      marginTop: '10px'
                    }}
                  >
                    Donate
                  </Button> */}
                </Grid>
              </Box>
            </Grid>
          </Grid> :
          <Grid container lg={10} style={{ justifyContent: 'center', alignItems: 'center', margin: '0 auto' }}>
            <Grid container lg={10} className={classes.topDiv}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '10px' }}>
                    <img style={{ borderRadius: '50%', height: '60px', width: '60px' }} src={singleCourseData?.attributes?.instructor_detail?.data?.attributes?.profile_photo ? singleCourseData?.attributes?.instructor_detail?.data?.attributes?.profile_photo : require("../assets/avatar.png")} />
                  </div>
                  <div>
                    <Typography className={classes.instructor_name}>{singleCourseData?.attributes?.instructor_detail?.data?.attributes?.full_name}</Typography>
                  </div>
                </div>
              </div>

              <div className={classes.topButtonGrid}>
                <Button 
                  // onClick={() => this.enrolledToTheCourse(singleCourseData?.id, !singleCourseData?.attributes?.free)} 
                  onClick={() => this.openEnrollModal()}
                  variant="contained" 
                  className={classes.buttonTopEnroll}
                >
                  {"Enroll Now"}
                </Button>
              </div>
            </Grid>
            <Grid md={10} lg={10} className={classes.videoGrid}>
              <Box
                style={{
                  height: '50%',
                  width: '100%',
                  borderRadius: '10px',
                  background: '#1f1f1f',
                  boxShadow: '0 0 7px 1px rgba(0, 0, 0, 0.22)',
                }}
              >
                {
                  singleCourseData.type === 'course' ?
                    <>
                      {/* @ts-ignore */}
                      <Box
                        style={{
                          position: 'relative',
                          borderRadius: '10px',
                          overflow: 'hidden',
                        }}
                      >
                        {/* @ts-ignore */}
                        <ReactPlayer
                          playing={carausalId == singleCourseData.id ? carausalPlay : false}
                          height='50%'
                          controls={false}
                          width='100%'
                          style={{ borderRadius: '50px' }}
                          // @ts-ignore
                          ref={this.playerRef}
                          url={singleCourseData.attributes?.preview_video ? singleCourseData.attributes?.preview_video : 'https://minio.b64156.dev.eastus.az.svc.builder.cafe/sbucket/twimtyaj4fwz64voegthw9n8lbs8'}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                            // borderRadius: '6px'
                          }}
                        >
                          <IconButton
                            style={{
                              color: palette.primary.main,
                              background: '#fff'
                            }}
                            onClick={() => this.playCarausal(singleCourseData.id)}
                          >
                            {
                              carausalPlay && carausalId == singleCourseData.id ?
                                <PauseRoundedIcon fontSize="large" />
                                : <PlayArrowRoundedIcon fontSize="large" />
                            }
                          </IconButton>
                        </div>
                      </Box>
                    </>
                    : <img
                      src={singleCourseData.attributes?.poster ? singleCourseData.attributes?.poster : require("../assets/background.png")}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderTopRightRadius: '6px',
                        borderBottomRightRadius: '6px',
                      }}
                    />
                }
              </Box>
            </Grid>
            {/* <Grid md={10} lg={10}>
                <div className={classes.topButtonGrid}>
                  <Button 
                    onClick={() => this.enrolledToTheCourse(singleCourseData?.id, !singleCourseData?.attributes?.free)} 
                    variant="contained" 
                    fullWidth={true}
                    className={classes.buttonTopEnroll}
                  >
                    Enroll Now
                  </Button>
                </div>
            </Grid> */}
          </Grid>
        }
      </Box>
    )
  }

  renderCourseInfo() {
    const { classes } = this.props;
    const {singleCourseData} = this.state;
    return (
      <Box mt={5}>
        <Grid container>
          <Grid xs={6} sm={6} md={3} className={classes.horizontalLine}>
            <Typography className={classes.bottomHeadings}>Lessons</Typography>
            <Typography className={classes.bottomHeadings2}>{singleCourseData?.attributes?.lectures?.length}</Typography>
          </Grid>
          <Grid xs={6} sm={6} md={3} className={classes.horizontalLine}>
            <Typography className={classes.bottomHeadings}>Total Time</Typography>
            <Typography className={classes.bottomHeadings2}>{singleCourseData?.attributes?.duration} Hours</Typography>
          </Grid>
          <Grid xs={6} sm={6} md={3} className={classes.horizontalLine}>
            <Typography className={classes.bottomHeadings}>Access</Typography>
            <Typography className={classes.bottomHeadings2}>{singleCourseData?.attributes?.access}</Typography>
          </Grid>
          <Grid xs={6} sm={6} md={3} className={classes.horizontalLine}>
            <Typography className={classes.bottomHeadings}>People Enrolled</Typography>
            <Typography className={classes.bottomHeadings2}>{singleCourseData?.attributes?.people_enrolled}</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  renderReviewsBox =(filteredLecture: any, lectureIndex: any) => {
    const {classes} = this.props;
    const { singleCourseData, reviewLoading, reviewStatus } = this.state;
    let lessonStatus;

    if (filteredLecture?.attributes?.student_lecture?.status === 'in_progress') {
      lessonStatus = `Continue Lesson ${lectureIndex + 1}`;
    } else if (filteredLecture?.attributes?.student_lecture?.status === 'not_started') {
      lessonStatus = `Start Lesson ${lectureIndex + 1}`;
    } else {
      lessonStatus = 'Completed';
    }
    return (
      <Box mt={5}>
        <Grid container>
          <Grid style={{ marginBottom: '20px' }} md={9} sm={12} xs={12} >
            {singleCourseData?.attributes?.reviews ? (
              <Reviews
                reviewDesc={singleCourseData?.attributes?.reviews}
                handleReviewSubmit={this.submitReviewForCourse}
                sorting={this.getSingleCourseSort}
                reviewLoading={reviewLoading}
                reviewStatus={reviewStatus}
                isCoursesReview={true}
              />
            ) : (
              singleCourseData?.attributes?.reviews.length
            )}
          </Grid>
          <Grid md={3} sm={12} xs={12}>
            {singleCourseData?.attributes?.enrolled ? (
              <Button
                disabled={this.state.studentStatus.filter((x: any) => x.status === 'complete').length === singleCourseData.attributes.lectures.length}
                onClick={() => this.navigationToLesson(filteredLecture?.id)}
                variant="contained"
                className={classes.buttonEnroll}
                style={{
                  background: this.state.studentStatus.filter((x: any) => x.status === 'complete').length === singleCourseData.attributes?.lectures?.length ? 'rgb(213, 96, 54, 0.65)' : '',
                  color: this.state.studentStatus.filter((x: any) => x.status === 'complete').length === singleCourseData.attributes?.lectures?.length ? 'rgba(0, 0, 0, 0.26)' : ''
                }}
              >
                {lessonStatus}
              </Button>
            ) : (
              <Button onClick={() => this.enrolledToTheCourse(singleCourseData?.id, !singleCourseData?.attributes?.free)} variant="contained" className={classes.buttonEnrollBottom}>
                {!singleCourseData?.attributes?.free ? "Buy Now" : "Enroll Now"}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }

  renderTabs = (filteredLecture: any, lectureIndex: any) => {
    const { singleCourseData, tabs } = this.state;
    const { classes } = this.props;
    return (
      <>
        {tabs === 0 ? (
          <Box>
            <Box mt={5}>
              <Grid>
                <Typography className={classes.heading1}>About the Course</Typography>
              </Grid>
              <Grid>
                <Typography className={classes.para}>{singleCourseData?.attributes?.about}</Typography>
              </Grid>
              <Box mt={5}>
                <Grid container>
                  <Grid sm={1} xs={2}>
                    <img style={{ height: '48px', width: '48px' }} src={require("../assets/avatar.png")} />
                  </Grid>
                  <Grid sm={11} xs={10}>
                    <Typography className={classes.heading2}>
                      {singleCourseData?.attributes?.instructor_detail?.data?.attributes?.full_name}
                      {singleCourseData?.attributes?.tag_others.data.length ? singleCourseData?.attributes?.tag_others.data.map((item: any) => (
                        <span key={item?.id}>, {item.attributes.full_name}</span>
                      )) : null}
                    </Typography>
                  </Grid>
                  <Typography className={classes.para}>{singleCourseData?.attributes?.instructor_detail?.data?.attributes?.about_author}</Typography>
                </Grid>
              </Box>
            </Box>
  
            {this.renderCourseInfo()}
            {this.renderReviewsBox(filteredLecture, lectureIndex)}
          </Box>
        ) : null}
      </>
    );
  }

  renderLessons() {
    const { classes } = this.props;
    const { singleCourseData, tabs } = this.state;
    return (
      <>
        {tabs === 1 && singleCourseData?.attributes?.lectures?.length > 0 && (
          <LessonsCard
            enrolled={singleCourseData?.attributes?.enrolled}
            navigation={this.props.navigation}
            modules={singleCourseData?.attributes?.lectures}
          />
        )}
        {tabs === 1 && singleCourseData?.attributes?.lectures?.length === 0 && (
          <Grid xs={12}>
            <Typography className={classes.lessonHeading}>No Lessons Found</Typography>
          </Grid>
        )}
      </>
    );
  }

  renderTabsName() {
    const { classes } = this.props;
    const { tabs } = this.state;
    console.log({tabs})
    return (
      <Box>
        <Grid container>
          <Grid lg={12} className={classes.tabsBox}>
            <Tabs
              className={classes.tabs_main}
              value={tabs}
              onChange={this.handleTabsChange}>
              <Tab className={classes.headingTabs} label="Course Description" />
              <Tab className={classes.headingTabs} label="Lessons" />
            </Tabs>
          </Grid>
        </Grid>
      </Box>
    )
  }

  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { reviewLoading, studentStatus, reviewStatus, singleCourseData, tabs, carausalId, carausalPlay } = this.state
    const { classes, navigation } = this.props;
    // @ts-ignore
    const filteredLecture = this.state.lectureStatus.find(this.getIncompleteStatus)
    const lectureIndex = this.state.lectureStatus.findIndex(this.getIncompleteStatus)
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {
          this.state.openAlertSingleCourse ?
            <AlertModal
              open={this.state.openAlertSingleCourse}
              data={this.state.errorData}
              onClose={() => this.closeAlertBox()}
              redirectUri={this.state.uri}
              navigation={this.props.navigation}
            />
            : null
        }
        {
          this.state.openFavIconAlertModal ?
            <AlertModal
              open={this.state.openFavIconAlertModal}
              data={this.state.updateMessage}
              onClose={() => this.closeAlertBox()}
              redirectUri={this.state.uri}
              navigation={this.props.navigation}
            />
            : null
        }
        {
          this.state.openEnrollModal ?
            <DonateEnrollModal
              open={this.state.openEnrollModal}
              onClose={() => this.closeEnrollModal()}
              handleDonate={this.donate}
              id={this.state.singleCourseData?.id}
              free={!singleCourseData?.attributes?.free}
              handleEnrollNow={this.enrolledToTheCourse.bind(this)}
              redirectUri={this.state.uri}
              navigation={this.props.navigation}
            />
            : null
        }
        <div className={classes.manage_background}>
          <div className={classes.blur_effect}>
          </div>
          <Container className={classes.mainWrapper}>
            {
              this.getTopNav(classes, navigation)
            }
            <Grid lg={12}>
              <Typography
                className={classes.mainHeading}
              >
                {singleCourseData?.attributes?.title}
              </Typography>
            </Grid>
            {
              this.state.loading ? 
              // @ts-ignore
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '50px'
                  }}
                >
                  <CircularProgress 
                    style={{
                      color: palette.primary.main
                    }}
                  />
                </Box>
              :
              <Grid lg={12}>
                {
                  this.getCourseHeader(
                    classes, 
                    singleCourseData, 
                    carausalId, 
                    carausalPlay,
                    studentStatus,
                    filteredLecture,
                    lectureIndex
                  )
                }
              </Grid>
            }
            {this.renderTabsName()}
            {this.renderTabs(filteredLecture, lectureIndex)}
            {this.renderLessons()}
          </Container>
        </div>
      </>
      // Customizable Area End
    )
  }
}
// Customizable Area Start
export default withTheme(
  withStyles((theme: Theme) =>
    createStyles({
      topHeading: {
        fontFamily: palette.fontFamily.main,
        color: '#fff',
        fontSize: '18px',
        lineHeight: 1.33,
        padding: '40px 0',
        '@media (max-width:480px)': {
          fontSize: '20px',
          paddingTop: '40px',
          paddingLeft: '20px'
        }
      },
      backArrowIcon: {
        color: '#fff',
        fontSize: '45px',
        '@media (max-width:480px)':{
          fontSize: '30px',
          marginTop: '5px'
        }
      },
      topNav: { display: 'flex', flexDirection: 'row', justifyContent: 'center' },
      iconButton: { 
        position: 'absolute', 
        marginTop: '18.5px', 
        left: '5px' 
      },
      blur_effect: {
        position: 'absolute',
        width: '100%',
        height: '120vh',
        zIndex: 0,
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        filter: 'blur(25px)'
      },
      lessonHeading: {
        fontSize: '50px',
        fontWeight: 'bold',
        fontFamily: palette.fontFamily.main,
        margin: '150px 0',
        textAlign: 'center',
        color: palette.primary.main
      },
      mainWrapper: {
        position: 'relative'
      },
      manage_background: {
        backgroundColor: '#1f1f1f',
        position: 'relative',
      },
      tabs_main: {
        "& .MuiTabs-indicator": {
          // display: "none"
          backgroundColor: palette.primary.main,
          borderRadius: '1.5px',
          height: '4px'
        }
      },
      instructor_name: {
        fontFamily: palette.fontFamily.main,
        fontSize: '20px',
        color: palette.course.primary,
        textTransform: 'capitalize',
        '@media (max-width:480px)':{
          fontSize: '24px',
        }
        // margin : '10px 2px'
      },
      topDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: '30px',
        // width: '100%'
      },
      topButtonGrid: {
        // textAlign: 'right',
        '@media (max-width:480px)':{
          width: '160px'
        }
      },
      buttonTopEnroll:
      {
        backgroundColor: palette.primary.main,
        textTransform: 'none',
        borderRadius: '7px',
        fontSize: '18px',
        fontWeight: 500,
        color: '#ffff',
        height: '50px',
        padding: '10px 70px',
        margin: '10px 5px',
        '&:hover': {
          background: 'transparent',
          color: palette.primary.main,
          border: `1px solid ${palette.primary.main}`
        },
        '@media (max-width:480px)':{
          width: '100%',
          padding: '0px 0px',
          height: '40px',
          fontSize: '16px',
        }
      },
      videoGrid: {
        justifyContent: 'center', 
        width: '100%',
        alignItems: 'center',
        '@media (max-width:991.98px)': {
          justifyContent: 'center', 
          width: '100%', 
          alignItems: 'center',
        }
      },
      horizontalLine: {
        position: 'relative',
        '&::before': {
          content: `''`,
          position: 'absolute',
          left: 'auto',
          top: '0',
          right: '0',
          bottom: '0',
          width: '2px',
          height: '100%',
          backgroundColor: palette.primary.main,

          // opacity: 1,
          // backgroundImage: `radial-gradient(circle at 50% 54%, ${palette.primary.main}, '#252525' '84%')`
          // backgroundImage: radialGradient(circle at 50% 54%, palette.primary.main, '#252525' '84%')
          // border: '50px solid transparent',
          // borderTopColor: 'red',
        },
        '&:last-child': {
          '&::before': {
            content: 'none'
          }
        },
        '@media (max-width:991.98px)': {
          '&::before': {
            content: 'none'
          }
        }
      },
      heading1: {
        fontFamily: palette.fontFamily.main,
        fontSize: '24px', fontWeight: 600,
        color: palette.course.primary,
        borderBottomColor: palette.primary.main,
        borderWidth: '4px'
      },
      heading2: {
        fontFamily: palette.fontFamily.main,
        fontSize: '24px', fontWeight: 600,
        color: palette.course.primary,
        borderBottomColor: palette.primary.main,
        borderWidth: '4px',
        textTransform: 'capitalize',
        marginTop: '5px'
      },
      headingTabs: {
        fontSize: '24px',
        fontWeight: 600,
        fontFamily: palette.fontFamily.main,
        color: palette.course.primary,
        textTransform: 'none',
        minWidth: '50% !important',
        '@media (max-width: 480px)': {
          fontSize: '20px'
        }
      },
      mainHeading: {
        fontFamily: palette.fontFamily.main,
        fontSize: '36px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '20px 20px 0px 20px',
        color: palette.course.primary,
        '@media (max-width:480px)': {
          fontSize: '25px',
          padding: '0px 20px 0px 20px',
        }
      },
      videoPlayerGrid: {
        // padding:'50px',
        // margin:'30px'
      },
      tabsBox: { flex: 1, flexDirection: 'row', justifyContent: 'space-between', width: '100%' },
      outerGridR: {
        margin: '20px 0px'
      },
      reviepara: {
        margin: '20px 0px'
      },
      button: {
        backgroundColor: palette.primary.main,
        textTransform: 'none',
        borderRadius: '7px',
        fontFamily: palette.fontFamily.main,
        fontSize: '18px',
        fontWeight: 500,
        color: '#ffff',
        height: '50px',
        width: '100%',
        '&:hover': {
          background: 'transparent',
          color: palette.primary.main,
          border: `1px solid ${palette.primary.main}`
        }
      },
      buttonEnroll: {
        backgroundColor: palette.primary.main,
        textTransform: 'none',
        fontFamily: palette.fontFamily.main,
        fontSize: '18px',
        fontWeight: 500,
        color: '#ffff',
        height: '50px',
        minWidth: '240px',
        padding: '10px 40x',
        margin: '10px 40px',
        borderRadius: '7px',
        '&:hover': {
          background: 'transparent',
          color: palette.primary.main,
          border: `1px solid ${palette.primary.main}`
        },
        '@media (max-width:960px)': {
          padding: '10px 60px',
          margin: '10px 0px 30px',
          width: '100%'
        }
      },
      buttonEnrollBottom: {
        backgroundColor: palette.primary.main,
        textTransform: 'none',
        fontFamily: palette.fontFamily.main,
        fontSize: '18px',
        fontWeight: 500,
        color: '#ffff',
        height: '50px',
        minWidth: '240px',
        padding: '10px 60px',
        margin: '0px 40px',
        borderRadius: '7px',
        '&:hover': {
          background: 'transparent',
          color: palette.primary.main,
          border: `1px solid ${palette.primary.main}`
        },
        '@media (max-width:960px)': {
          padding: '10px 60px',
          margin: '10px 0px 30px',
          width: '100%'
        }
      },
      insName: {
        fontFamily: palette.fontFamily.main,
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: 1.2,
        // flexDirection: 'column',
        margin: 'auto'
      },
      lessonRow: { textAlign: 'end' },
      para: {
        fontFamily: palette.fontFamily.main,
        fontSize: '20px',
        lineHeight: 1.6,
        letterSpacing: '0.25px',
        color: palette.course.para,
        marginTop: '10px',
        textAlign: 'justify'
      },
      paraReviews: {
        fontFamily: palette.fontFamily.main,
        fontSize: '14px',
        lineHeight: 1.71,
        // letterSpacing: '0.25px'
      },
      bottomHeadings: {
        fontFamily: palette.fontFamily.main,
        fontSize: '28px',
        fontWeight: 500,
        color: palette.course.para,
        // lineHeight: 1.79,
        // letterSpacing: '0.78px',
        textAlign: 'center',
        '@media (max-width:480px)': {
          fontSize: '22px',
        }
      },
      bottomHeadings2: {
        fontFamily: palette.fontFamily.main,
        color: '#fef4bc',
        fontSize: '32px',
        fontWeight: 'bold',
        lineHeight: 1.56,
        letterSpacing: '0.89px',
        textAlign: 'center',
        '@media (max-width:480px)': {
          fontSize: '24px',
        }
      },
      reviewBox: {
        borderRadius: '4px',
        borderWidth: '2px',
        borderColor: palette.primary.main,
        minHeight: '200px',
        margin: '20px 0px',
        padding: '10px 20px 10px 20px',
        height: '300px',
        overflow: 'auto'
      },
      reviewerBox: {
        flexDirection: 'column',
        justifyContent: 'space-between'
      },
    })
  )(CourseDetails)
)
  // Customizable Area End

