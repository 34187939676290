import React from "react";

// Customizable Area Start
import { 
    Typography, 
    Box,
    Container,
    Grid,
    Button,
    IconButton,
    AppBar,
    Toolbar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CustomInput from "../../../../components/src/CustomInput";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { palette } from "../../../../components/src/theme";
import CustomLoader from "../../../../components/src/CustomLoader";
import Dialog from '@material-ui/core/Dialog';
import AlertModal from "../../../../components/src/AlertModal";
import ReactPlayer from "react-player";
import PlayerControlls from "../../../../components/src/PlayerControlls";

const drawerWidth = 240;

let count = 0;

const format = (seconds: any) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};
// Customizable Area End

import PastEventDetailsController, {
  Props,
  configJSON
} from "./PastEventDetailsController";


class PastEventDetails extends PastEventDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // @ts-ignore
    this.window = window;
    // @ts-ignore
    this.playerRef = React.createRef();
    // @ts-ignore
    this.controlsRef = React.createRef();
    // @ts-ignore
    this.playerContainerRef = React.createRef();
    // @ts-ignore
    this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

    // @ts-ignore
    this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";
    
    // @ts-ignore
    this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
    // @ts-ignore
    this.totalDuration = format(this.duration);
    // Customizable Area End
  }

  // Customizable Area Start


  handlePlayPause = () => { 
    this.setState({ ...this.state, videoPlaying: !this.state.videoPlaying });
    console.log(configJSON)
  };

  handleRewind = () => {
    // @ts-ignore
    this.playerRef.current.seekTo(this.playerRef.current.getCurrentTime() - 10);
  };

  handleForward = () => {
    // @ts-ignore
    this.playerRef.current.seekTo(this.playerRef.current.getCurrentTime() + 10);
  };

  handleSeekChange = (e: any, newValue: any) => {
    // @ts-ignore
    this.setState({ ...this.state, played: parseFloat(newValue / 100) });
  };

  handleSeekMouseDown = (e: any) => {
    this.setState({ ...this.state, seeking: true });
  };

  handleSeekMouseUp = (e: any, newValue: any) => {
    this.setState({ ...this.state, seeking: false });
    // @ts-ignore
    this.playerRef.current.seekTo(newValue / 100, "fraction");
  };

  handleDuration = (duration: any) => {
    this.setState({ ...this.state, allDuration: duration });
  };

  toggleFullScreen = () => {
    this.setState({hideBtn: !this.state.hideBtn})
    // @ts-ignore
    screenfull.toggle(this.playerContainerRef.current);
  };

  onEscape = () => {
    this.setState({hideBtn: false})
  }

  handleProgress = (changeState: any) => {
    // @ts-ignore
    this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

    // @ts-ignore
    this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";
    
    // @ts-ignore
    this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
    // @ts-ignore
    this.totalDuration = format(this.duration);
    if (count > 3) {
      // @ts-ignore
      this.controlsRef.current.style.visibility = "hidden";
      count = 0;
    }
    // @ts-ignore
    if (this.controlsRef.current.style.visibility == "visible") {
      count += 1;
    }
    if (!this.state.seeking) {
      this.setState({ ...this.state, ...changeState });
    }
  };

  handleOnEnded = () => {
    this.setState({...this.state, videoPlaying: false, played: 0});
  }

  handlePip = () => {
    this.setState({...this.state, pip: !this.state.pip, hideBtn: false})
  }

  handleDisplayFormat = () => {
    this.setState({timeDisplayFormat : this.state.timeDisplayFormat == " normal" ? 'remaining' : 'normal'})
  };

  handleVolumeSeekDown = (e: any, newValue: any) => {
    // @ts-ignore
    this.setState({ ...this.state, seeking: false, volume: parseFloat(newValue / 100) });
  };
  handleVolumeChange = (e: any, newValue: any) => {
    this.setState({
      ...this.state,
      // @ts-ignore
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };
  handleMute = () => {
    this.setState({ ...this.state, muted: !this.state.muted });
  };

  handlePlaybackRate = (rate: any) => {
    this.setState({ ...this.state, playbackRate: rate });
  };

  onChangeBitrate = (quality: any) => {
    // @ts-ignore
    const internalPlayer = this.playerRef.current?.getInternalPlayer();
    if (internalPlayer) {
        // currentLevel expect to receive an index of the levels array
        internalPlayer.currentLevel = quality;
        this.setState({ ...this.state, quality: quality });
    }
  }

getEventDate = (eventDetails: any) => {
    let date = new Date(eventDetails.attributes.date)
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();
    return `${day} - ${month} - ${year},`
}

getEventImage = (classes: any, eventDetails: any) => {
    return (
        <div
            className={classes.eventImgCard}
        >
            <img 
                alt={eventDetails?.attributes?.title}
                src={eventDetails?.attributes?.cover_image}
                className={classes.eventImg}
            />
        </div>
    )
}

getEventInfo = (classes: any, eventDetails: any) => {
    return (
        <div
            className={classes.eventMianInfo}
        >
            <div>
                <Typography
                    className={classes.eventTitle}
                >
                    {eventDetails.attributes.title}
                </Typography>
            </div>
            <div>
                <span
                    className={classes.presenterDetails}
                >
                    In this conversation
                </span>
                <span
                    className={classes.presenterName}
                >
                    {eventDetails.attributes.instructor.name}
                </span>
            </div>
            <div>
                <span
                    className={classes.presenterDetails}
                >
                    Date : {this.getEventDate(eventDetails)}, {eventDetails.attributes.start_time} - {eventDetails.attributes.end_time} 
                </span>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginTop: '20px'
                }}
            >
                <Button
                    className={classes.darkBtn}
                    onClick={() => this.openConfirmModal()}
                >
                    Register
                </Button>
                <Button
                    className={classes.borderedBtn}
                >
                    Sync to calendar
                </Button>
            </div>
        </div>
    )
}

getEventAbout = (classes: any, eventDetails: any) => {
    return (
        <div
            style={{
                marginTop: '40px'
            }}
        >
            <div>
                <Typography
                    className={classes.aboutHead}
                >
                    About the Event
                </Typography>
            </div>
            <div
                style={{
                    marginTop: '20px'
                }}
            >
                <Typography
                    className={classes.aboutContent}
                >
                    {eventDetails.attributes.description}
                </Typography>
            </div>
        </div>
    )
}

getPresenterAbout = (classes: any, eventDetails: any) => {
    return(
        <Grid
            item
            xs={12}
            md={12}
            sm={12}
        >
            <div
                style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div>
                    <img 
                        className={classes.avatar}
                        src={require('../../../Gallery/assets/avatar.png')}
                    />
                </div>
                <div>
                    <Typography
                        className={classes.userName}
                    >
                        {eventDetails?.attributes?.instructor_detail?.data?.attributes?.full_name}
                    </Typography>
                </div>
                <div
                    style={{
                        marginTop: '20px'
                    }}
                >
                    <Typography
                        className={classes.creatorAbout}
                    >
                        {eventDetails?.attributes?.instructor_detail?.data?.attributes?.about_author}
                    </Typography>
                </div>
            </div>
            <div 
                style={{
                    marginTop: '30px',
                    marginBottom: '30px'
                }}
            >
                <span
                    style={{
                        fontSize: '16px',
                        fontFamily: 'Poppins-SemiBold',
                        color: '#353535'
                    }}
                >
                    Published Date & Time :
                </span>
                <span
                    style={{
                        marginLeft: '5px',
                        fontSize: '16px',
                        fontFamily: 'Poppins-Light',
                        color: '#353535'
                    }}
                >
                    {this.getEventDate(eventDetails)} {eventDetails.attributes.start_time} - {eventDetails.attributes.end_time}
                </span>
            </div>
        </Grid>
    )
}

getEventDetailsData = (classes: any, eventDetails: any) => {
    return (
        <Container>
            <div
                style={{
                    marginTop: '30px'
                }}
            >
                <Grid
                    container
                    spacing={5}
                >
                    {/* <Grid
                        item
                        xs={1}
                        md={1}
                        sm={1}
                    ></Grid> */}
                    <Grid
                        xs={12}
                        md={12}
                        sm={12}
                        item
                    >
                        {/* @ts-ignore */}
                        <Box
                            component={'div'}
                            style={{
                            borderRadius: '10px',
                            overflow: 'hidden',
                            boxShadow: '0 0 7px 1px rgba(0, 0, 0, 0.22)',
                            position: 'relative',
                            width: '100%',
                            height: !this.state.matches ? '100%' : '80%',
                            maxHeight: !this.state.matches ? '100%' : '80%',
                            background: '#000'
                            }}
                            // @ts-ignore
                            ref={this.playerContainerRef}
                        >
                        {/* @ts-ignore */}
                            <ReactPlayer 
                                url={
                                    "https://minio.b64156.dev.eastus.az.svc.builder.cafe/sbucket/twimtyaj4fwz64voegthw9n8lbs8"
                                }
                                playing={this.state.videoPlaying}
                                width={'100%'}
                                height={'100%'}
                                style={{
                                    minWidth: '100%',
                                    minHeight: '100%'
                                }}
                                // @ts-ignore
                                ref={this.playerRef}
                                onProgress={this.handleProgress}
                                controls={false}
                                onEnded={this.handleOnEnded}
                                light={this.state.light}
                                loop={this.state.loop}
                                playbackRate={this.state.playbackRate}
                                volume={this.state.volume}
                                muted={this.state.muted}
                                pip={this.state.pip}
                            />
                            <PlayerControlls 
                                // @ts-ignore
                                ref={this.controlsRef}
                                playPause={this.handlePlayPause}
                                state={this.state}
                                rewind={this.handleRewind}
                                forward={this.handleForward}
                                onSeek={this.handleSeekChange}
                                onSeekMouseDown={this.handleSeekMouseDown}
                                onSeekMouseUp={this.handleSeekMouseUp}
                                onDuration={this.handleDuration}
                                played={this.state.played}
                                onToggleFullScreen={this.toggleFullScreen}
                                // @ts-ignore
                                elapsedTime={this.elapsedTime}
                                hideBtn={this.state.hideBtn}
                                // @ts-ignore
                                totalDuration={this.totalDuration}
                                onMute={this.handleMute}
                                muted={this.state.muted}
                                volume={this.state.volume}
                                pip={this.handlePip}
                                onVolumeChange={this.handleVolumeChange}
                                onVolumeSeekDown={this.handleVolumeSeekDown}
                                onChangeDisplayFormat={this.handleDisplayFormat}
                                onPlaybackRateChange={this.handlePlaybackRate}
                                playbackRate={this.state.playbackRate}
                                quality={this.state.quality}
                                onChangeQuality={this.onChangeBitrate}
                                onEscape={this.onEscape}
                                pipIcon={true}
                                forwardSeekIcon={true}
                                backwardSeekIcon={true}
                                volumeIcon={true}
                                settingsIcon={true}
                                ccIcon={true}
                                timeDuration={true}
                                />
                        </Box>
                    </Grid>
                    {/* <Grid
                        item
                        xs={1}
                        md={1}
                        sm={1}
                    ></Grid> */}
                </Grid>
            </div>
            <div
                style={{
                    marginTop: '-140px',
                    paddingBottom: '10px',
                    borderBottom: '1px solid #d3d3d3'
                }}
            >
                <Typography
                    className={classes.eventTitle}
                >
                    {eventDetails.attributes.title}
                </Typography>
            </div>
            <div
                style={{
                    marginTop: '20px',
                }}
            >
                <Typography
                    className={classes.aboutContent}
                >
                    {eventDetails.attributes.description}
                </Typography>
            </div>
            <div
                style={{
                    marginTop: '20px'
                }}
            >
                {
                    this.getPresenterAbout(classes, eventDetails)
                }
            </div>
            <Grid
                container
                spacing={10}
            >
                <Grid
                    item
                >
                    <div>
                        <Typography
                            className={classes.aboutHead}
                        >
                            Registered Users ({eventDetails?.attributes?.registered_users?.data?.length})
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Container>
                <Grid
                    container
                    spacing={6}
                    style={{
                        marginBottom: '30px'
                    }}
                >
                    {
                        eventDetails?.attributes?.registered_users?.data?.length ?
                        eventDetails?.attributes?.registered_users?.data.map((item: any) => {
                            return (
                                <Grid
                                    xs={12}
                                    sm={6}
                                    md={4}
                                >
                                    <div
                                        style={{
                                            marginTop: '20px',
                                            display: 'flex',
                                            alignContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div
                                            className={classes.avatarWrap}
                                        >
                                            <img 
                                                className={classes.avatar2}
                                                src={item.attributes.profile_photo ? item.attributes.profile_photo : require('../../../Gallery/assets/avatar.png')}
                                            />
                                        </div>
                                        <div>
                                            <Typography
                                                className={classes.userName2}
                                            >
                                                {item.attributes.full_name}
                                            </Typography>
                                        </div>
                                    </div>
                                </Grid>
                            )
                        }) 
                        :   <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%'
                                }}
                            >
                                <span 
                                    style={{
                                        fontSize: '18px',
                                        fontFamily: 'Poppins-SemiBold',
                                        textAlign: 'center',
                                        marginTop: '20px',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    No users registered yet!
                                </span>
                            </div>
                    }
                </Grid>
            </Container>
        </Container>
    )
}

handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
}

getAppBar = (classes: any) => {
    return (
        <AppBar position='fixed' className={classes.appBar}>
            <Toolbar>
                <IconButton
                    aria-label="open drawer"
                    edge="start"
                    onClick={this.handleDrawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <div className={classes.logo}>
                    <img src={require('../../../../components/src/logo.svg')} height={70} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'space-between',
                        padding: '10px'
                    }}
                >
                    <div>
                        <Typography
                            style={{
                                fontFamily: 'Poppins-SemiBold',
                                color: '#353535',
                                textTransform: 'capitalize'
                            }}
                            className={classes.name}
                        >
                            Hi {window.localStorage.getItem('userName')},
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'Poppins-SemiBold',
                                fontSize: '40px',
                                color: '#353535'
                            }}
                            className={classes.welcome}
                        >
                            Welcome back
                        </Typography>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div
                            style={{
                                position: 'relative'
                            }}
                        >
                        <CustomInput
                            passwordfield={false}
                            placeholder={"Search"}
                            icon={require("../../assets/search.png")}
                            iconHeight={"24px"}
                            height={48}
                            borderRadius={6}
                        />
                        </div>
                        <IconButton
                            style={{
                                border: '1px solid #bdbdbd',
                                height: '45px',
                                width: '45px',
                                borderRadius: '5px',
                                marginTop: '25px',
                                marginLeft: '20px'
                            }}
                        >
                            <img src={require('../../assets/notification.png')} height={'25px'} />
                        </IconButton>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    )
}

getConfirmModal = (classes: any, eventDetails: any) => {
    return (
        <>
            <Dialog
                open={this.state.openConfirm}
                onClose={this.closeAlertBox}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div
                    style={{
                        padding: '56px',
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center',
                            fontSize: '24px',
                            color: '#353535',
                            fontFamily: 'poppins-SemiBold'
                        }}
                    >
                        You are now registering for the event.
                    </div>
                    
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px'
                        }}
                    >
                        <Button 
                            onClick={this.closeAlertBox} 
                            className={classes.cancelBtn}
                        >
                            Cancel
                        </Button>
                        <Button 
                            onClick={() => this.registerEvent(eventDetails.id)} 
                            autoFocus
                            className={classes.registerBtn}
                        >
                            Register
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

openConfirmModal = () => {
    this.setState({
        openConfirm: !this.state.openConfirm
    })
}

closeAlertBox = () => {
    this.setState({
      openConfirm: false,
      openAlert: false
    })
}

  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { loading, eventDetails } = this.state;
    return (
      // Customizable Area Start
        <div>
            {
                loading ? 
                    <CustomLoader
                        open={loading}
                    />
                :   null
            }
            {
                this.state.openAlert ? 
                    <AlertModal 
                        open={this.state.openAlert} 
                        data={this.state.successMessage}
                        onClose={() => this.closeAlertBox()}
                    />
                : null
            }
            {
                this.state.openConfirm ? 
                    this.getConfirmModal(classes, eventDetails)
                : null
            }
            <DashboardDrawer
                mobileOpen={this.state.mobileOpen}
                handleDrawerToggle={this.handleDrawerToggle}
                // @ts-ignore
                navigation={this.props.navigation}
            />
            {/* @ts-ignore */}
            <Box
                className={classes.appBarWrap}
            >
                {
                    this.getAppBar(classes)
                }
                {
                    Object.keys(eventDetails).length ?
                        this.getEventDetailsData(classes, eventDetails)
                    : null
                }
                {
                    this.state.error ? 
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '40px'
                            }}
                        >
                            <span 
                                style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    fontSize: '20px',
                                    color: '#353535'
                                }}
                            >
                                Details not found!
                            </span>
                        </div>
                    : null
                }
            </Box>
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            logo: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            menuButton: {
                marginRight: theme.spacing(2),
                color: palette.primary.main,
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            eventImgCard: {
                width: '352px',
                height: '344px',
                maxWidth: '352px',
                maxHeight: '344px',
                overFlow: 'hidden',
                borderRadius: '8px',
                boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)'
            },
            eventImg: {
                width: '352px',
                height: '344px',
                maxWidth: '352px',
                maxHeight: '344px',
                overFlow: 'hidden',
                borderRadius: '8px',
                boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)'
            },
            eventMianInfo: {
                maxHeight: '344px',
                height: '344px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            eventTitle: {
                fontSize: '32px',
                fontFamily: 'Poppins-SemiBold',
                color: '#353535',
                textTransform: 'capitalize'
            },
            presenterDetails: {
                fontSize: '20px',
                fontFamily: 'Poppins-Light',
                color: '#353535'
            },
            presenterName: {
                fontSize: '20px',
                fontFamily: 'Poppins-SemiBold',
                color: palette.primary.main,
                marginLeft: '10px',
                textTransform: 'capitalize'
            },
            darkBtn: {
                background: palette.primary.main,
                textTransform: 'capitalize',
                color: '#fff',
                fontSize: '20px',
                fontFamily: 'Poppins-Light',
                paddingLeft: '60px',
                paddingRight: '60px',
                '&:hover': {
                    background: palette.primary.main,
                    textTransform: 'capitalize',
                    color: '#fff',
                    fontSize: '20px',
                    fontFamily: 'Poppins-Light',
                    paddingLeft: '60px',
                    paddingRight: '60px',
                }
            },
            borderedBtn: {
                marginLeft: '30px',
                textTransform: 'capitalize',
                border: `1px solid ${palette.primary.main}`,
                fontSize: '16px',
                fontFamily: 'Poppins-Light',
                paddingLeft: '35px',
                paddingRight: '35px'
            },
            registerBtn: {
                minWidth: '142px',
                background: palette.primary.main,
                textTransform: 'capitalize',
                marginLeft: '10px',
                color: '#fff',
                fontSize: '20px',
                fontFamily: 'Poppins-Light',
                paddingLeft: '60px',
                paddingRight: '60px',
                '&:hover': {
                    background: palette.primary.main,
                    textTransform: 'capitalize',
                    color: '#fff',
                    fontSize: '20px',
                    fontFamily: 'Poppins-Light',
                    paddingLeft: '60px',
                    paddingRight: '60px',
                }
            },
            cancelBtn: {
                minWidth: '142px',
                marginRight: '10px',
                textTransform: 'capitalize',
                border: `1px solid ${palette.primary.main}`,
                fontSize: '16px',
                fontFamily: 'Poppins-Light',
                paddingLeft: '35px',
                paddingRight: '35px'
            },
            aboutHead: {
                fontSize: '24px',
                fontFamily: 'Poppins-SemiBold',
                color: '#353535'
            },
            aboutContent: {
                fontSize: '18px',
                fontFamily: 'Poppins-Light',
                color: '#545454'
            },
            avatar: {
                maxWidth: '72px',
                maxHeight: '72px',
                minWidth: '72px',
                minHeight: '72px',
                border: `1px solid ${palette.primary.main}`,
                borderRadius: '50%'
            },
            avatar2: {
                maxWidth: '48px',
                maxHeight: '48px',
                minWidth: '48px',
                minHeight: '48px',
                border: `1px solid ${palette.primary.main}`,
                borderRadius: '50%'
            },
            userName: {
                fontSize: '24px',
                fontFamily: 'Poppins-SemiBold',
                color: '#545454',
                marginLeft: '20px'
            },
            userName2: {
                fontSize: '16px',
                fontFamily: 'Poppins-Light',
                color: '#545454',
                marginLeft: '20px'
            },
            creatorAbout: {
                fontFamily: 'Poppins-Light',
                fontSize: '18px',
                color: '#545454'
            },
            appBar: {
                background: '#fff',
                boxShadow: 'none',
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                    background: '#fff',
                    boxShadow: 'none'
                },
            },
            appBarWrap: {
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                },
            },
        })
    )(PastEventDetails)
)
// Customizable Area End
