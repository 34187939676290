import React from "react";

// Customizable Area Start
import { 
  Container,
  Grid,
  Typography,
  Avatar,
  IconButton,
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box"
import HomeCarousel from "../../../components/src/HomeCarousel";
import { carouselImg } from "../../../blocks/CarouselDisplay/src/assets";
import Divider from "../../../components/src/Divider";
import { palette } from "../../../components/src/theme";
import moment from "moment";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import ReactPlayer from 'react-player';
import AlertModal from "../../../components/src/AlertModal";
import CustomLoader from "../../../components/src/CustomLoader";
import CustomHoverBtn from "../../../components/src/CustomHoverBtn";
// Customizable Area End

import GalleryController, {
  Props,
} from "./GalleryController";
import BreadCrumb from "../../../components/src/BreadCrumb";
import PodCastVideo from "../../../components/src/PodCastVideo";
import PodCastAudio from "../../../components/src/PodCastAudio";


const styles = (theme: any) => ({
  otherPodcastHead: {
    fontSize: '30px',
    fontFamily: 'Poppins-SemiBold',
    [theme.breakpoints.down("md")]: {
      fontSize: '24px',
      fontFamily: 'Poppins-SemiBold',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '18px',
      fontFamily: 'Poppins-SemiBold',
    },
  },
  otherPodcastCardHead: {
    fontSize: '20px',
    fontFamily: 'Poppins-Light',
    color: '#ffffff',
    [theme.breakpoints.down("sm")]: {
      fontSize: '16px',
      fontFamily: 'Poppins-Light',
      color: '#ffffff',
    },
  },
  otherPodcastCardDesc: {
    fontSize: '14px',
    fontFamily: 'Poppins-Light',
    color: '#ffffff',
    [theme.breakpoints.down("sm")]: {
      fontSize: '12px',
      fontFamily: 'Poppins-Light',
      color: '#ffffff',
    },
  }
});

class Gallery extends GalleryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // @ts-ignore
    this.playerRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  catId: any = localStorage.getItem('category_id');


  playCarausal = (carausalId: any) => {
    this.setState({carausalPlay: !this.state.carausalPlay, carausalId: carausalId})
  }

  pauseVideo = (playingState: any) => {
    this.setState({carausalPlay: playingState})
  }

  closeAlertBox = () => {
    this.setState({
        openAlert: false
    })
  }

  renderAlertModal() {
    const { openAlert, updateMessage } = this.state;
    // istanbul ignore if
    if (openAlert) {
      return (
        <AlertModal 
          open={openAlert}
          data={updateMessage}
          onClose={() => this.closeAlertBox()}
        />
      );
    }
    return null;
  }

  renderCustomLoader() {
    const { loading } = this.state;
    if (loading) {
      return (
        <CustomLoader 
          open={loading}
        />
      );
    }
    return null;
  }
  // istanbul ignore next
  renderCarouselData(data: any) {
    const { screenWidth } = this.state;
  
    return (
      <div key={data.index}>
        <Grid style={{display: "flex", flexDirection: screenWidth < 600 ? 'column' : 'row',  height: "100%" }}>
          <Grid item md={6} xs={12} sm={6} className="talkCarouselContentStyle">
            {/* @ts-ignore */}
            <Box
              style={{
                display:"flex",
                flexDirection: 'row',
                width: '100%'
              }}
            >
              <Typography
                style={{
                  color: palette.primary.main,
                  fontFamily: 'Poppins-SemiBold',
                  textTransform: 'uppercase'
                }}
              >
                {data.attributes.category?.name ? data.attributes.category?.name : 'SOCIAL SERVICE'}
              </Typography>
              <Typography
                style={{
                  color: '#8991a4',
                  fontFamily: 'Poppins-Light',
                  textTransform: 'uppercase',
                  marginLeft: '40px'
                }}
              >
                {data.attributes.created_at ? moment(data.attributes.created_at).format("DD MMM YYYY") : "02 FEB 2022"}
              </Typography>
            </Box>
            <Typography
              style={{
                fontFamily: 'Poppins-Light',
                fontSize: '3.2vw',
                marginTop: '30px'
              }}
            >
              {data.attributes.title}
            </Typography>
            <Typography
              style={{
                fontFamily: 'Poppins-Light',
                fontSize: '20px',
                marginTop: '30px',
                color: '#8991a4'
              }}
            >
              {data.attributes.description}
            </Typography>
            {/* @ts-ignore */}
            <Box mt={5}>
              {/* @ts-ignore */}
              <Box
                style={{display: 'flex'}}
              >
                <Typography
                  style={{
                    fontFamily: 'Poppins-Light',
                    textTransform: 'uppercase'
                  }}
                >
                  Speakers
                </Typography>
              </Box>
              {/* @ts-ignore */}
              <Box
                mt={2}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flex: 1,
                  alignItems: 'center'
                }}
              >
                  {/* @ts-ignore */}
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%'
                    }}
                  >
                    <Avatar
                      style={{
                        border: '1px solid red'
                      }}
                      src={require('../assets/avatar.png')} 
                    />
                    <Typography
                      style={{
                        fontFamily: 'Poppins-Light',
                        fontSize: '14px',
                        marginLeft: '20px',
                        textTransform: 'capitalize'
                      }}
                    >
                      {data.attributes?.instructor?.name}
                    </Typography>
                  </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            {/* <img 
              src={data.attributes?.poster ? data.attributes?.poster : carouselImg} 
              style={{ 
                width: "100%", 
                height: "100%" 
              }} 
            /> */}
            {/* @ts-ignore */}
            <Box
              style={{
                height: '100%',
                width: '100%',
                borderTopRightRadius: '6px',
                borderBottomRightRadius: '6px',
                background: '#000'
              }}
            >
              {
                data.type == 'video_podcast' ?
                <>
                  {/* @ts-ignore */}
                  <Box
                    style={{
                      position: 'relative'
                    }}
                  >
                    {/* @ts-ignore */}
                    <ReactPlayer 
                      playing={this.state.carausalId == data.id ? this.state.carausalPlay : false}
                      height= '100%'
                      controls= {false}
                      width= '100%'
                      // @ts-ignore
                      ref={this.playerRef}
                      url={data.attributes?.video ? data.attributes?.video : 'https://minio.b64156.dev.eastus.az.svc.builder.cafe/sbucket/twimtyaj4fwz64voegthw9n8lbs8'}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1
                      }}
                    >
                      <IconButton
                        style={{
                          color: palette.primary.main,
                          background: '#fff'
                        }}
                        onClick={() => this.playCarausal(data.id)}
                      >
                        {
                          this.state.carausalPlay && this.state.carausalId == data.id ? 
                          <PauseRoundedIcon fontSize="large" />
                          : <PlayArrowRoundedIcon fontSize="large" />
                        }
                      </IconButton>
                    </div>
                  </Box>
                </>
                : <img 
                    src={data.attributes?.poster ? data.attributes?.poster : carouselImg} 
                    style={{ 
                      width: "100%", 
                      height: "100%" 
                    }} 
                  />
              }
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }

  getCarousal = () => {
    return (
      <>
        <HomeCarousel pauseCurrent={(playingState: any) => this.pauseVideo(playingState)}>
          {
          // istanbul ignore next
          this.state.carousalData.length ? this.state.carousalData.map((data: any, index: any) => this.renderCarouselData(data)) :
            // @ts-ignore
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Poppins-SemiBold',
                  fontSize: '16px',
                  color: '#000',
                  textAlign: 'center',
                  verticalAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center'
                }}
              >
                Loading...
              </Typography>
            </Box>
          }
        </HomeCarousel>
      </>
    )
  }
  

  // Customizable Area End

  render() {
    const {classes} = this.props
    const { screenWidth } = this.state;
    return (
      // Customizable Area Start
      <>
        <Container>
        {this.renderAlertModal()}
        {this.renderCustomLoader()}
          <BreadCrumb 
            base={'Home'}
            url1={{
              base: `Let's Talk`,
              link: 'podcast'
            }}
            url2={{
              path: '',
              link: ''
            }}
          />
          {/* @ts-ignore */}
          <Box mt={2} mb={7}>
            {
              this.getCarousal()
            }
          </Box>
        </Container>
        <Container>
          {/* @ts-ignore */}
          <Box mt={8}>
            <Divider 
              bgColor="#e6e6e6"
            />
          </Box>
          {/* @ts-ignore */}
          <Box mt={screenWidth < 600 ? 18 : 5}>
            <Grid container spacing={2}>
              <Grid item md={2} sm={4} xs={6} >
                <CustomHoverBtn
                  selected={!this.state.catId}
                  handleClick={() => this.getAllPodcasts()}
                  color={palette.primary.main}
                  br="none"
                  fullWidth
                  title="All Podcasts"
                />
              </Grid>
              {
                // istanbul ignore next
                this.state.podcastFilterOptionsData.length ? this.state.podcastFilterOptionsData.map((item: any, index: any) => {
                  let titleToShow;
                  if (screenWidth > 960 && screenWidth < 1024) {
                    titleToShow = item.attributes.name && item.attributes.name.length > 14
                      ? item.attributes.name.substring(0, 13) + '...'
                      : item.attributes.name;
                  } else {
                    titleToShow = item.attributes.name;
                  }
                  return (
                    <Grid key={item.id} item md={2} sm={4} xs={6}>                      
                      <CustomHoverBtn
                        selected={this.state.catId === item.attributes.id}
                        handleClick={() => this.getFilteredPodcast(item.attributes.id)}
                        color={palette.primary.main}
                        br="none"
                        fullWidth
                        title={titleToShow}
                      />
                    </Grid>
                  )
                })
                : <Typography>Loading...</Typography>
              }
            </Grid>
          </Box>
          {/* @ts-ignore */}
          <Box mt={5}>
            <PodCastVideo 
              imgUrls={this.state.videoPodcastData}
              showSeeMore={true}
              getVideo = {(id: any) => this.getVideo(id)}
              loading={this.state.vLoading}
              vErrorMsg={this.state.vErrorMsg}
              navigation={this.props.navigation}
              makeFavourite={(id: any, status: any) => this.makeFavourite(id, status)}
            />
          </Box>
          {/* @ts-ignore */}
          <Box mt={5} mb={this.state.otherPodcast.length ? 0 : 5 }>
            <PodCastAudio 
              imgUrls={this.state.audioPodcastData}
              showSeeMore={true}
              loading={this.state.loading}
              errorMsg={this.state.errorMsg}
              getAudio={(id: any) => this.getAudio(id)}
              navigation={this.props.navigation}
              makeFavourite={(id: any, status: any) => this.makeFavourite(id, status)}
            />
          </Box>
        </Container>
        {
          this.state.otherPodcast.length ?
            // @ts-ignore
            <Box
              mt={8}
              style={{
                position: 'relative'
              }}
            >
              {/* @ts-ignore */}
              <Box
                style={{
                  background: '#FFFBE6',
                  minHeight: '200px',
                  height: '200px',
                  position: 'absolute',
                  minWidth: '100%'
                }}
              >

              </Box>
            </Box>
          : null
        }
        
        {
          this.state.otherPodcast.length ?
            <Container>
              {/* @ts-ignore */}
              <Box 
                mb={8}
                style={{
                  position: 'relative'
                }}
              >
                {/* @ts-ignore */}
                <Box pt={6}>
                    {/* @ts-ignore */}
                      <Box
                          style={{
                              display: 'flex',
                              // flex: 1,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                          }}
                      >
                        {/* @ts-ignore */}
                          <Box>
                              <Typography
                                  className={classes.otherPodcastHead}
                              >
                                  Featured Podcasts
                              </Typography>
                          </Box>
                      </Box>
                    </Box>
                    {/* @ts-ignore */}
                    <Box mt={3}>
                      <Grid container spacing={5}>
                        {
                          this.state.otherPodcast.slice(0, 4).map((item: any, index: any) => {
                            return (
                              <Grid key={index} item xs={12} sm={6} md={3}>
                              {/* @ts-ignore */}
                                <Box
                                  style={{
                                      cursor: 'pointer',
                                      position: 'relative'
                                  }}
                                  onClick={() => this.getVideo(item.id)}
                                >
                                  <img width={'100%'} height={'100%'} src={item.attributes.poster} style={{borderRadius: '8px', position: 'relative'}} />
                                  {/* @ts-ignore */}
                                  <Box
                                      style={{
                                          position: 'absolute',
                                          zIndex: 9999,
                                          bottom: '15px',
                                          padding: '20px',
                                      }}
                                  >
                                      <Typography
                                          className={classes.otherPodcastCardHead}
                                      >
                                          {item.attributes.title}
                                      </Typography>
                                      <Typography
                                          className={classes.otherPodcastCardDesc}
                                      >
                                          {item.attributes.summary}
                                      </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            )
                          })
                        }
                        </Grid>
                      </Box>
              </Box>
            </Container>
          : null
        }
       </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default withStyles(styles)(Gallery);

const webStyle = {
  
};
// Customizable Area End
