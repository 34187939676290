import React from "react";

// Customizable Area Start
import { createTheme } from '@material-ui/core/styles';
// Customizable Area End

import DonationPaymentsController, {
  Props,
  configJSON
} from "./DonationPaymentsController";

export default class DonationPayments extends DonationPaymentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div 
        style={{
          position:'relative',
          // overflow:'hidden',
          width:'100%', 
          height:'100%',
          paddingTop:'100%'
        }}
      >
        <iframe 
          title='Donation form powered by Zeffy' 
          style={{
            position: 'absolute', 
            border: 0, 
            top:0,
            left:0,
            bottom:0,
            right:0,
            width:'100%',
            height:'100%'
          }} 
          src='https://www.zeffy.com/en-US/embed/donation-form/7eb285af-281c-46fa-8d7d-556e48b6c41a' 
          // @ts-ignore
          allowpaymentrequest 
          allowTransparency={true}
        >

          </iframe>
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
