import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useTheme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { palette } from './theme';
import CustomButton from './CustomButton';
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";

import { 
    FacebookIcon, 
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
    LinkedinIcon,
    TelegramIcon
} from "react-share";

export default function ShareModal(props: any) {
  const theme = useTheme();

  return (
    <div>
        <Dialog
            // @ts-ignore
            fullWidth={'xs'}
            open={props.openShare}
            onClose={props.shareClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <span
                        style={{
                            fontFamily: 'Poppins-Light'
                        }}
                    >
                        Share On
                    </span>
                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <IconButton
                            style={{
                                color: palette.primary.main
                            }}
                            onClick={props.shareClose}
                        >
                            <CloseRoundedIcon fontSize='medium' />
                        </IconButton>
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div 
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}
                    >
                        <FacebookShareButton 
                            url={props.shareLink}
                            quote={props.shareTitle}
                            hashtag={"#hashtag"}
                            // @ts-ignore
                            description={props.shareDesc}
                        >
                            {/* @ts-ignore */}
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        {/* @ts-ignore */}
                        <TwitterShareButton 
                            title={props.shareTitle}
                            url={props.shareLink}
                            hashtags={["hashtag1", "hashtag2"]}
                        >
                            {/* @ts-ignore */}
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>
                        {/* @ts-ignore */}
                        <WhatsappShareButton
                            title={props.shareTitle}
                            url={props.shareLink}
                        >
                            {/* @ts-ignore */}
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        {/* @ts-ignore */}
                        <EmailShareButton
                            title={props.shareTitle}
                            url={props.shareLink}
                        >
                            {/* @ts-ignore */}
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                        {/* @ts-ignore */}
                        <LinkedinShareButton
                            title={props.shareTitle}
                            url={props.shareLink}
                        >
                            {/* @ts-ignore */}
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                        {/* @ts-ignore */}
                        <TelegramShareButton
                            title={props.shareTitle}
                            url={props.shareLink}
                        >
                            {/* @ts-ignore */}
                            <TelegramIcon size={32} round />
                        </TelegramShareButton>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton 
                    title="Cancel"
                    color={palette.primary.main}
                    onClick={props.shareClose} 
                    textColor={"#FFFFFF"}
                    autoFocus
                />
            </DialogActions>
        </Dialog>
    </div>
  );
}