import { IconButton } from "@material-ui/core";
import React from "react";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'



export default function LoginWithFacebbok(props: any) {


    const responseFacebook = (response: any) => {
        if(response.status == 'unknown'){
            console.log("FB LOGIN RESPONSE", response.status)
        } else {
            props.getProfile(response)
        }
    }


    return (
        <div>
            {/* @ts-ignore */}
            <FacebookLogin
                appId="594395582398173"
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                cookie={false}
                // @ts-ignore
                render={(renderProps: any) => (
                    <IconButton 
                        onClick={() => renderProps.onClick()}
                    >
                        <img src={require('../../blocks/email-account-registration/assets/fb-icon.png')} height={35} />
                    </IconButton>
                )}
            />
        </div>
    )
}