import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { CategoryType } from "../../../ContentManagement/src/Interfaces.web"
import { IBlog, BlogUniqueItem, Sort, DateTime } from "../Interfaces.web";
// Customizable Area End
export const configJSON = require("../config.js");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    mobileOpen: boolean;
    loading: boolean;
    errorMsg: string;
    nextPage: boolean;
    blogUniqueItem: BlogUniqueItem;
    blogList: IBlog[];
    getCategoryLoading: boolean;
    categoryList: CategoryType[];
    authorList: any[];
    authorLoading: boolean;
    selectecCategory: string;
    status: string;
    openSetting: boolean;
    sort: Sort;
    dateTime: DateTime;
    openAlert: boolean;
    dataCreationMsg: string;
    inputError: any;
    previewModal: boolean;
    open: any;
    bold: boolean;
    underlined: boolean;
    italic: boolean;
    alignment: string;
    createLoading: boolean;
    catValidation: string;
    othersName: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddNewArticleController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetDataCallId: string = "";
    getAllCategoryId: string = "";
    getAllAuthorId: string = "";
    createBlogId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // getName(MessageEnum.NavigationToSingleArticle),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            mobileOpen: false,
            loading: false,
            errorMsg: '',
            nextPage: false,
            blogUniqueItem: {
                title: '',
                subtitle: '',
                bgImg: [],
                base64BgImg: []
            },
            catValidation: '',
            blogList: [{
                id: 1,
                dataBaseId: null,
                img: [],
                base64Img: [],
                heading: {
                    text: "",
                    style: {}
                },
                subheading: {
                    text: "",
                    style: {}
                },
                para: {
                    text: "",
                    style: {}
                },
                caption: {
                    text: "",
                    style: {}
                },
            }],
            categoryList: [],
            authorList: [],
            authorLoading: false,
            getCategoryLoading: false,
            selectecCategory: "",
            status: "",
            openAlert: false,
            dataCreationMsg: "",
            openSetting: false,
            sort: {
                publish: 'immediately',
                visibility: 'public_user',
                author: 92
            },
            dateTime: {
                date: new Date(),
                time: new Date(),
            },
            inputError: {},
            previewModal: false,
            open: false,
            bold: false,
            underlined: false,
            italic: false,
            alignment: 'left',
            createLoading: false,
            othersName: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {
                if (apiRequestCallId == this.getAllAuthorId) {
                    if (responseJson && !responseJson.error && responseJson.accounts) {
                        if (responseJson.accounts.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                authorLoading: false,
                                // loading: false,
                                authorList: [],
                                // getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false,
                                authorList: responseJson?.accounts,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getAllCategoryId) {
                    if (responseJson && !responseJson.error && responseJson.data) {
                        if (responseJson.data.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                // loading: false,
                                categoryList: [],
                                getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                getCategoryLoading: false,
                                categoryList: responseJson?.data,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                getCategoryLoading: false,
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.createBlogId) {
                    if (responseJson && !responseJson.error) {
                        if (responseJson.data.type !== "error") {
                            this.setState({
                                ...this.state,
                                dataCreationMsg: "Data created successfully!",
                                // loading: false,
                                openAlert: true,
                                errorMsg: "",
                                createLoading: false
                                // categoryList: responseJson?.data,
                            });
                        }
                        else {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "Please fill required data",
                                errorMsg: "",
                                createLoading: false
                            })
                        }

                    } else {
                        if (responseJson.error) {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "",
                                errorMsg: "Internal Server Error",
                                createLoading: false
                            })
                        }
                        else if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                dataCreationMsg: "",
                                openAlert: true,
                                createLoading: false
                                // loading: false,
                            });
                        } else {
                            this.setState({
                                openAlert: true,
                                errorMsg: errorReponse,
                                dataCreationMsg: "",
                                createLoading: false
                                // loading: false,
                            });
                        }
                    }
                }
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.getCategory()
        this.getAuthorList()
    }

    // Customizable Area End

    // Customizable Area Start

    fieldValidation = () => {
        const emailRegExp = /^(([^<>()[]\.,;:\s@"]+(.[^<>()[]\.,;:\s@"]+))|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;
        const passwordRegExp = /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[@$!%?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const { blogUniqueItem, blogList, selectecCategory } = this.state
        let error: any = {}
        if (!blogUniqueItem.title) {
            error.title = "Please Provide Title"
        }
        if (!blogUniqueItem.subtitle) {
            error.subtitle = "Please Provide Subtitle"
        }
        if (!blogUniqueItem.bgImg) {
            error.bgImg = "Please Provide Cover Image"
        }
        if(!selectecCategory){
            this.setState({
                openSetting: true,
                catValidation: "Please select category"
            })
        }
        blogList.forEach((singleBlg: IBlog) => {
            if (!blogList[0].heading.text) {
                error.heading = "Please Provide Heading"
            }
            if (!blogList[0].para.text) {
                error.para = "Please Provide Paragraph"
            }
        })
        if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
            this.setState({ inputError: {} })
            this.createBlog()
        }
        else {
            this.setState({ inputError: error })
        }
    }
    createBlog = () => {
        const { 
            blogUniqueItem, 
            dateTime, 
            sort, 
            selectecCategory, 
            status,
            italic,
            bold,
            alignment,
            underlined,
            othersName
        } = this.state;
        if(!selectecCategory){
            return
        }
        this.setState({
            createLoading: true
        })
        const httpBody = {
            "blogs": {
                title: "",
                subtitle: "",
                category_id: 5,
                tag: "normal",
                status: "", //can be published or draft
                visibility: "public_user", //can be lbl_users
                scheduled_date: "22/09/2022",
                scheduled_time: "01:00",
                components_attributes: [
                    {
                        images: ["base64string"],
                        heading_attributes: {
                            text: "test",
                            style: {
                                display: "test"
                            }
                        },
                        sub_heading_attributes: {
                            text: "test",
                            style: {
                                display: "test"
                            }
                        },
                        paragraph_attributes: {
                            text: "test",
                            style: {
                                display: "test"
                            }
                        },
                        caption_attributes: {
                            text: "test",
                            style: {
                                display: "test"
                            }
                        }
                    }
                ]
            }
        }

        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("token")
        };
        let blogs: any = {}
        let components_attributes: any[] = []
        this.state.blogList.forEach(blg => {
            // let imgList: any[] = []
            // blg.img.map((img) => {
            //     this.getBase64(img, (base64Img: any) => {
            //         imgList.push(base64Img)
            //     })
            // })
            components_attributes.push({
                images: blg.base64Img,
                heading_attributes: {
                    text: blg.heading.text,
                    style: {
                        bold: bold,
                        underlined: underlined,
                        italic: italic,
                        alignment: alignment
                    },
                },
                sub_heading_attributes: {
                    text: blg.subheading.text,
                    style: blg.subheading.style
                },
                paragraph_attributes: {
                    text: blg.para.text,
                    style: blg.para.style
                },
                caption_attributes: {
                    text: blg.caption.text,
                    style: blg.caption.style
                }
            })

        })
        blogs.components_attributes = components_attributes
        blogs.title = blogUniqueItem.title
        blogs.subtitle = blogUniqueItem.subtitle
        blogs.cover_image = blogUniqueItem.base64BgImg[0]
        blogs.status = status == 'draft' ? status :  status == "published" && sort.publish == "schedule_post" ? 'scheduled' : status
        blogs.publish_timing = sort.publish
        blogs.visibility = sort.visibility
        blogs.scheduled_date = dateTime.date
        blogs.scheduled_time = dateTime.time
        blogs.tag = "normal"
        blogs.category_id = selectecCategory
        blogs.tag_others = othersName

        const body = {
            blogs: blogs
        }


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createBlogId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postBlogUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)

        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getBase64(file: any, cb: any) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    //   handleSortingLoader = (type: string, sort: string, page: number, perPage?: number) => {

    //     this.setState({ sortingLoader: true, currentPage: page, type: type })
    //     if(sort){
    //       this.setState({sorting: sort})
    //     }
    //     const userToken = localStorage.getItem("token");
    //     const header = {
    //       "Content-Type": configJSON.contentType,
    //       token: userToken,
    //     };

    //     const requestMessage = new Message(
    //       getName(MessageEnum.RestAPIRequestMessage)
    //     );

    //     this.apiGetDataCallId = requestMessage.messageId;
    //     const hitURL = `${configJSON.letsReadGetUrl}?type=${type}&page=${page}&per_page=${ perPage ? perPage : this.state.blogPerPage}&sort=${ sort ? sort : this.state.sorting}`
    //     requestMessage.addData(
    //       getName(MessageEnum.RestAPIResponceEndPointMessage),
    //       hitURL
    //     );

    //     requestMessage.addData(
    //       getName(MessageEnum.RestAPIRequestHeaderMessage),
    //       JSON.stringify(header)
    //     );

    //     requestMessage.addData(
    //       getName(MessageEnum.RestAPIRequestMethodMessage),
    //       configJSON.dashboarApiMethodType
    //     );

    //     runEngine.sendMessage(requestMessage.id, requestMessage);
    //   } 

    //   handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //     this.setState({ anchorEl: event.currentTarget });
    //   };
    //   handleClose = () => {
    //     this.setState({ anchorEl: null });
    //   };

    //   handleModalOpen = () => {
    //     this.setState({modalOpen: true})
    //   }
    //   handleModalClose = () => {
    //     this.setState({modalOpen: false})
    //   }

    getCategory = () => {
        this.setState({ getCategoryLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllCategoryId = requestMessage.messageId;
        const hitURL = `${configJSON.categoryUrl}?type=${configJSON.categoryType}`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            hitURL
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getAuthorList = () => {
        this.setState({ getCategoryLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllAuthorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAuthorUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    addNewBlog = () => {
        this.setState((prev_state) => {
            return {
                blogList: [...prev_state.blogList, {
                    id: new Date().getTime(),
                    dataBaseId: null,
                    img: [],
                    base64Img: [],
                    heading: {
                        text: "",
                        style: {}
                    },
                    subheading: {
                        text: "",
                        style: {}
                    },
                    para: {
                        text: "",
                        style: {}
                    },
                    caption: {
                        text: "",
                        style: {}
                    },
                }]
            }
        })
    }

    removeBlog = (id: number) => {
        const _newblogList = this.state.blogList.filter(blg => blg.id !== id)
        this.setState({ blogList: _newblogList })
    }

    handleBlogUniqueItemChange = (event: any, name: string) => {
        const { value } = event.target;
        this.setState({
            blogUniqueItem: {
                ...this.state.blogUniqueItem,
                [name]: value
            }
        });
    };

    handleBgImgChange = (value: any[], files: any[]) => {
        this.setState({
            blogUniqueItem: {
                ...this.state.blogUniqueItem,
                bgImg: value,
                base64BgImg: files,
            }
        })
    }

    handleHeadingChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    heading: {
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleSubheadingChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    subheading: {
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleParagraphChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    para: {
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleImgChange = (id: number, value: any[], files: any[]) => {
        const { blogList } = this.state;
        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    img: [...blg.img, ...value],
                    base64Img: [...blg.base64Img, ...files]
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleCaptionChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    caption: {
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleCategorySelect = (id: string) => {
        this.setState({ selectecCategory: id })
    }

    hanldeSettingToggle = () => {
        this.setState({ openSetting: !this.state.openSetting })
    }

    handleSortChange = (event: any, name: string) => {
        const { value } = event.target;
        this.setState({
            sort: {
                ...this.state.sort,
                [name]: value
            }
        });
    };

    handleSelectOthers = (e: any) => {
        this.setState({
            othersName: e.target.value
        })
    }

    handleDateTimeChange = (value: Date, name: string) => {
        this.setState({
            dateTime: {
                ...this.state.dateTime,
                [name]: value,
            }
        })
    }

    handleDraft = () => {
        this.setState((prev_stat) => {
            return {
                ...prev_stat,
                status: "draft"
            }
        }, () => this.fieldValidation())
    }

    handlePublish = () => {
        this.setState((prev_stat) => {
            return {
                ...prev_stat,
                status: "published"
            }
        }, () => this.fieldValidation())
    }

    closeAlertBox = () => {
        this.setState({
            openAlert: false
        });
    };

    handlePreviewOpen = () => {
        this.setState({ previewModal: true })
    }

    handleModalClose = () => {
        this.setState({ previewModal: false })
    }

    // Customizable Area End

}
