import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End
export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean;
  openSetting: boolean;
  categoryList: any;
  categoryName: any;
  personName: any;
  personAbout: any;
  categoryId: any;
  personImage: any;
  inputError: any;
  loading: boolean;
  instructorList: any;
  instructorId: any;
  openAlert: boolean;
  alertMsg: any;
  organisationId: any;
  organisationList: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  clickChild: any;
  // Customizable Area End
}

export default class AddPeopleController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetCategoryDataCallId: string = "";
  createPersonCallId: string = "";
  apiGetInstructorDataCallId: string = "";
  apiGetOrgDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mobileOpen: false,
      openSetting: false,
      categoryList: "",
      categoryName: "",
      personName: '',
      personAbout: '',
      categoryId: '',
      personImage: '',
      inputError: '',
      loading: false,
      instructorList: [],
      instructorId: '',
      openAlert: false,
      alertMsg: '',
      organisationId: '',
      organisationList: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getPeopleCategories();
    this.getOrganisationList();
    let userType = window.localStorage.getItem('userType')
    if(userType == 'admin'){
      this.getInstructorList();
    }
  }

  setUserListData = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        instructorList: responseJson.data.data,
      });
    }
  };

  setCreatePerson = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        openAlert: true,
        loading: false,
        alertMsg: 'Person created successfully!'
      });
    } else {
      this.setState({
        openAlert: true,
        loading: false,
        alertMsg: 'Something went wrong!'
      })
    }
  }

  setCategory = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        categoryList: responseJson.data
      });
    }
  }

  setOrg =  (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        organisationList: responseJson.data
      });
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetCategoryDataCallId) {
          this.setCategory(responseJson)
        }
        if (apiRequestCallId === this.apiGetInstructorDataCallId) {
          this.setUserListData(responseJson);
        }
        if(apiRequestCallId === this.createPersonCallId){
          this.setCreatePerson(responseJson)
        }
        if(apiRequestCallId === this.apiGetOrgDataCallId){
          this.setOrg(responseJson)
        }
      }
    }
  }

  hanldeSettingToggle = () => {
    this.setState({ openSetting: !this.state.openSetting });
  };

  handleCategoryChange = (e: any) => {
    this.setState({
      categoryId: e.target.value
    })
  };

  handleOrgChange = (e: any) => {
    this.setState({
      organisationId: e.target.value
    })
  };

  handleInstructorChange = (e: any) => {
    this.setState({
      instructorId: e.target.value
    })
  };

  handlePersonNameChange = (e: any) => {
    this.setState({
      personName: e.target.value
    })
  }

  handlePersonAboutChange = (e: any) => {
    this.setState({
      personAbout: e.target.value
    })
  }

  handlePersonImageChange = (files: any) => {
    this.setState({
      personImage: files
    })
  }

  getPeopleCategories = () => {
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoryDataCallId = requestMessage.messageId;
    const hitURL = `${configJSON.categoryUrl}?type=person_category`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getInstructorList = () => {
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetInstructorDataCallId = requestMessage.messageId;
    const hitURL = `${configJSON.userListGetApi}?type=instructor`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOrganisationList = () => {
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOrgDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrgListApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  validation = () => {
    const {
      personName,
      personAbout,
      personImage,
      categoryId,
      instructorId
    } = this.state

    const userType = window.localStorage.getItem('userType')

    let error: any = {}
    if (!personName) {
        error.personName = "Please provide person name"
    }

    if (!personAbout) {
      error.personAbout = "Please provide person about"
    }

    if (!categoryId) {
      error.categoryId = "Please select category"
    }

    if(userType === 'admin'){
      if (!instructorId) {
        error.instructorId = "Please select Contributor"
      }
    }

    if (!personImage) {
      error.personImage = "Please upload person image"
    }
    
    if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
        this.setState({ inputError: {} })
        this.createPerson()
    }
    else {
        this.setState({ inputError: error })
    }
  }

  createPerson = () => {
    this.setState({
      loading: true
    })
    const userToken = window.localStorage.getItem("token");
    const userType = window.localStorage.getItem('userType');
    const insId: any = window.localStorage.getItem('userId');
    const header = {
      // "Content-Type": 'multipart/form-data',
      token: userToken
    };

    

    const { personName, 
            personAbout, 
            categoryId,
            personImage,
            instructorId,
            organisationId
          } = this.state
    const formData = new FormData();
    if(organisationId){
      formData.append('people[organisation_id]', organisationId);
    }
    formData.append('people[name]', personName);
    formData.append('people[about]', personAbout);
    formData.append('people[category_id]', categoryId);
    let blobPersonImage = new Blob(personImage);
    formData.append('people[image]', blobPersonImage);
    if(userType === 'instructor'){
      formData.append('people[instructor_id]', insId);
    } else {
      formData.append('people[instructor_id]', instructorId);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createPersonCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPersonApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
