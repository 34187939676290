import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {Pagination} from '@material-ui/lab';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
        color: "#F27024 !important",
        "& .Mui-selected": {
            background: "#F27024",
            color: "white",
            fontWeight: "bold",
        },
        ul: {
            "& .MuiPaginationItem-root": {
                color: "#F27024"
            }
        }
    },
  }),
);

export default function CustomPagination(props: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Pagination 
            variant="outlined" 
            onChange={props.onChange}
            count={props.totalPage}
        />
    </div>
  );
}