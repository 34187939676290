import React from "react";
import { StyleSheet, View, } from "react-native";

import Loader from "../../../components/src/Loader";
import SocialMediaAccountWebController, {
  Props,
  configJSON
} from "../../social-media-account/src/SocialMediaAccountWebController";
import {
  Box
} from "@material-ui/core";
import CustomFacebookLogInButton from "../../social-media-account/src/CustomFacebookLogInButton";
import CustomGoogleLogInButton from "../../social-media-account/src/CustomGoogleLogInButton";


class SocialMediaAccountRegistrationScreen extends SocialMediaAccountWebController {
  static SocialMediaAccountRegistrationScreen: SocialMediaAccountRegistrationScreen;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      isRegistration: true
    };
  }

  render() {
    const { loading } = this.state;
    return (
      // @ts-ignore
      <Box 
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Loader loading={loading} />

        {/* <CustomFacebookLogInButton
          testID="btnFacebookLogIn" //Merge Engine::From BDS
          appId="1010368702955176" //Merge Engine::From SDS
          loginFacebookButtonText={configJSON.facebookButtonText} //UI Engine::From Sketch
          {...this.btnFacebookLogInProps} //Merge Engine::From BDS - {...this.testIDProps}
        /> */}

        <CustomGoogleLogInButton
          testID="btnGoogleLogIn" //Merge Engine::From BDS
          style={styles.googleStyle} //UI Engine::From Sketch
          loginGoogleButtonText={configJSON.googleButtonText} //UI Engine::From Sketch
          googleButtonImageStyle={styles.googleButtonImageStyle} //UI Engine::From Sketch
          // googleButtonTextStyle={styles.googleButtonTextStyle} //UI Engine::From Sketch
          {...this.btnGoogleLogInProps} //Merge Engine::From BDS - {...this.testIDProps}
        />
        {/* Merge Engine End UI Engine Code */}
      </Box>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: "center",
    alignItems: "center",
  },
  logInButtonContainer: {
    overflow: "hidden",
    display: undefined,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "flex-start",
    alignItems: "center",
    shadowColor: "black",
    shadowOpacity: 0.3,
    shadowRadius: 8,
    height: 40,
    width: 205,
    marginTop: 16,
    elevation: 6,
    backgroundColor: "#ffffff"
  },
  facebookStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "black",
    shadowOpacity: 0.3,
    elevation: 6,
    shadowRadius: 8,
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: "#ffffff",
    padding: "11px"
  },
  facebookImageStyle: {
    marginRight: 10,
    width: 20,
    height: 20
  },
  facebookTextStyle: {
    color: "#2553b4",
    fontFamily: "Helvetica-Bold, sans-serif",
    paddingLeft: 7
  },
  googleStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: '30px'
    // shadowColor: "black",
    // shadowOpacity: 0.3,
    // elevation: 6,
    // shadowRadius: 8,
    // borderWidth: 0,
    // borderRadius: 0,
    // backgroundColor: "#ffffff",
    // padding: "11px",
    // marginTop: 32
  },
  googleButtonImageStyle: {
    // marginRight: 15,
    width: 36,
    height: 36
  },
  googleButtonTextStyle: {
    paddingLeft: 6,
    paddingRight: 3,
    fontSize: 14,
    color: "#2553b4",
    fontFamily: "Helvetica-Bold, sans-serif",
    marginLeft: 8
  },
  orTextStyle: {
    color: "#00000",
    fontWeight: "bold",
    alignSelf: "center",
    margin: 20
  },
  signUpButtonStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "black",
    shadowOpacity: 0.3,
    elevation: 6,
    shadowRadius: 8,
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: "#ffffff",
    padding: "11px",
    height: "100%",
    width: "100%"
  },
  signUpButtonTextStyle: {
    color: "#2553b4",
    fontSize: 11,
    fontFamily: "Helvetica-Bold, sans-serif"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  labelTitle: {
    marginTop: 24,
    marginBottom: 32,
    fontSize: 32,
    textAlign: "left",
    marginVertical: 8,
    color: "#6200EE"
  }
});

export default SocialMediaAccountRegistrationScreen;
