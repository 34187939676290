import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { articleAuthorImg } from "./assets";
import RealtedArticleCard from "../../../components/src/RelatedArticleCard";
import Reviews from "../../../components/src/Reviews";
import SingleArticleBody from "../../../components/src/SingleArticleBody";
import { fullDateFormatter } from "./BlogPage.web";
import ArticlesResponsiveFont from "../../../components/src/ArticlesResponsiveFont";
import AlertModal from "../../../components/src/AlertModal";
import FavouriteIcon from "../../../components/src/FavouriteIcon";
// Customizable Area End

import BlogArticleController, {
    Props,
    configJSON
} from "./BlogArticleController";





class BlogArticle extends BlogArticleController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        window.scrollTo(0,0)
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { 
            singleBlog, 
            sigleBlogLoading, 
            reviewLoading, 
            reviewStatus,
            bold,
            italic,
            underlined,
            alignment
        } = this.state;
        const title = singleBlog?.blog?.attributes?.title;
        
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                {
                    this.state.popUpAlertModified ?
                        <AlertModal
                            open={this.state.popUpAlertModified}
                            data={this.state.selectFavMessageModified}
                            onClose={() => this.cancelAlertModified()}
                        />
                        : null
                }
                {sigleBlogLoading ? <Box style={{ textAlign: "center", margin: "1.5rem 0" }}> <CircularProgress /> </Box> : <Box style={{
                    backgroundImage: `url(${singleBlog?.blog?.attributes?.cover_image})`,
                }} 
                className={classes.headerBg}>
                    <Box className={classes.overlay}>
                        <Box style={{ padding: "2% 8% 0", }}>
                            <Typography style={{ color: "#F27024", fontSize: "0.850rem", fontFamily: "Poppins-Light" }} gutterBottom><span style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate("Home")}>Home</span> / <span style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate("Blog")}>Let's Read</span> / <span style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate("Blog")}>Articles</span> / <ArticlesResponsiveFont title={title} type="link" />
                            </Typography>
                            <Box className={classes.whiteBorder} />
                            <Box style={{
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                                <FavouriteIcon
                                    // forVideoTitle={true}
                                    isFavourite={singleBlog?.blog?.attributes?.favourite}
                                    position={'absolute'}
                                    right={'1px'}
                                    top={'10px'}
                                    makeFavourite={(status: any) => this.toggleFavIconModified(singleBlog?.blog?.id, status)}
                                    navigation={this.props.navigation}
                                />
                            </Box>
                        </Box>
                    <Box className={classes.mainWrapper}>
                        <ArticlesResponsiveFont type="title" title={title} />
                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1rem" }}>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: "2rem" }}>
                                <Box style={{ marginRight: "10px" }}>
                                    <img style={{ borderRadius: "50%" }} height="41px" width="41px" alt="client_pic" src={articleAuthorImg} />
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "row" }}>
                                    <Typography style={{ fontFamily: "Poppins-SemiBold" }}>{singleBlog?.blog?.attributes?.instructor_name}</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography style={{ fontFamily: "Poppins-Light" }}>{fullDateFormatter(singleBlog?.blog?.attributes?.created_at)}</Typography>
                            </Box>
                        </Box>
                        {
                            singleBlog?.blog?.attributes?.tag_others?.data?.length ? singleBlog?.blog?.attributes?.tag_others?.data.map((item: any) => {
                            return (
                                <Typography key={item.id} style={{fontFamily: "Poppins-Light", fontSize: "12px"}}> {item.attributes.full_name}</Typography>
                            )
                            })
                            : null
                        }
                        <Typography 
                            style={{ 
                                fontFamily: "Poppins-Light", 
                                fontSize: "12px", 
                                textAlign: "center", 
                                padding: "0 3%", 
                                color: "#8991a4" 
                            }} 
                            gutterBottom
                        >
                            {singleBlog?.blog?.attributes?.subtitle === null ? "Subtitle is not present" : singleBlog?.blog?.attributes?.subtitle}
                        </Typography>
                    </Box>
                    </Box>
                </Box>}
                
                <Box className={classes.bodyWrapper}>
                    <Grid container
                        spacing={3}>
                        <Grid item md={9} sm={12} xs={12}>
                            <SingleArticleBody 
                                item={singleBlog?.blog} 
                                type={"blog"} 
                                bold={bold}
                                italic={italic}
                                underlined={underlined}
                                alignment={alignment}
                            />
                            <Box 
                                style={{ 
                                    marginTop: "4rem" 
                                }} 
                                className={classes.mobileSpace}
                            >
                                <Reviews 
                                    isCoursesReview={false} 
                                    reviewDesc={singleBlog?.blog?.attributes?.reviews} 
                                    handleReviewSubmit={this.handleReviewSubmit} 
                                    sorting={this.getReviewData} 
                                    reviewLoading={reviewLoading} 
                                    reviewStatus={reviewStatus}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                            <Box style={{ padding: "1.5rem", boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px", borderRadius: "10px" }}>
                                <Typography style={{ fontSize: "20px", fontFamily: "Poppins-SemiBold", letterSpacing: "0.11" }} gutterBottom>Related articles</Typography>
                                <Grid spacing={3} container alignItems="center" justifyContent="center">
                                    {
                                        singleBlog?.related_articles?.map((data: any, index: number) =>
                                            <Grid key={index} item md={12} sm={12} xs={12}>
                                                <RealtedArticleCard item={data} gotToSingleArticle={this.goToSingleArticle} />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            typography: {
                typography: {
                    fontFamily: ['Poppins', 'sans-serif'].join(',')
                },
            },
            headerBg: {
                width: "100%",
                height: "auto",
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "local",
                backgroundSize: "cover",
                backgroundPosition: "center",
                // backdropFilter: "blur(5px) saturate(100%)"
            },
            mainWrapper: {
                fontFamily: 'Poppins',
                padding: '5% 8%',
                [theme.breakpoints.down("xs")]: {
                    padding: '5%'
                },
                justifyContent: "center",
            },
            overlay: {
                background: "rgba(50, 70, 80, 0.93)",
                overflow: "hidden",
                // height: "100%"
               },
            bodyWrapper: {
                fontFamily: 'Poppins',
                padding: '1% 8%',
                [theme.breakpoints.down("xs")]: {
                    padding: '4%'
                },
                justifyContent: "center",
            },
            articleHeader: {
                background: "linear-gradient(to top, #fff000 0%, #000fff 100%)",
            },
            whiteBorder: {
                width: "84%",
                height: "1px",
                margin: "0 0 8px",
                backgroundColor: "#e6e6e6",
                padding: "0 8% 0"
            },
            mobileSpace: {
                [theme.breakpoints.down("sm")]: {
                    margin: "1rem 0"
                }
            }

        })
    )(BlogArticle)
)
// Customizable Area End
