import React from "react";

// Customizable Area Start
import { 
  Container, 
  Link,
  Typography,
  Breadcrumbs,
  Grid,
  Hidden
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CustomLoader from "../../../components/src/CustomLoader";
import { palette } from "../../../components/src/theme";
import AlertModal from "../../../components/src/AlertModal";
// Customizable Area End

import AdminConsole3Controller, {
  Props,
  configJSON
} from "./AdminConsole3Controller";

export default class AdminConsole3 extends AdminConsole3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getBreadCrumb = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link  
          href="/"
          style={{
            color: palette.primary.main,
            fontFamily: 'Poppins-Light',
            fontSize: '14px'
          }}
        >
          Home
        </Link>
        <Typography 
          style={{
            fontFamily: 'Poppins-Light',
            fontSize: '14px'
          }}
        >
          About Us
        </Typography>
      </Breadcrumbs>
    )
  }

  closeAlertBox = () => {
    this.setState({
        alertOpen: false
    })
  } 

  getRightImage = (data: any) => {
    return (
      <Grid container spacing={8}
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center'
        }}
      >
        <Grid item xs={12} sm={6} md={8}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {/* @ts-ignore */}
            <Hidden smUp>
              <Typography
                style={{
                  fontSize: '24px',
                  fontFamily: 'Poppins-Bold',
                  color: palette.primary.main,
                  textAlign: 'center'
                }}
              >
                {data.attributes.title}
              </Typography>
            </Hidden>
            {/* @ts-ignore */}
            <Hidden xsDown>
              <Typography
                style={{
                  fontSize: '38px',
                  fontFamily: 'Poppins-Bold',
                  color: palette.primary.main
                }}
              >
                {data.attributes.title}
              </Typography>
            </Hidden>
            {/* @ts-ignore */}
            <Hidden smUp>
              <div
                style={{
                  marginTop: '25px',
                  borderRadius: '10px',
                  minHeight: '169px',
                  maxHeight: '169px',
                  overflow: 'hidden',
                  boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.07)',
                }}
              >
                <img 
                  src={data.attributes.image} 
                  height={'100%'} 
                  width={'100%'}
                />
              </div>
            </Hidden>
            {/* @ts-ignore */}
            <Hidden smUp>
              <Typography
                style={{
                  marginTop: '25px',
                  color: '#333232',
                  fontSize: '14px',
                  fontFamily: 'Poppins-Light',
                  lineHeight: '1.63',
                  textAlign: 'justify'
                }}
              >
                {data.attributes.description}
              </Typography>
            </Hidden>
            {/* @ts-ignore */}
            <Hidden xsDown>
              <Typography
                style={{
                  marginTop: '25px',
                  color: '#333232',
                  fontSize: '19px',
                  fontFamily: 'Poppins-Light',
                  lineHeight: '1.63',
                  textAlign: 'justify'
                }}
              >
                {data.attributes.description}
              </Typography>
            </Hidden>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {/* @ts-ignore */}
          <Hidden xsDown>
            <div
              style={{
                height: '536px',
                minHeight: '536px',
                width: '100%',
                minWidth: '100%',
                overflow: 'hidden',
                borderRadius: '24px',
                objectFit: 'contain',
                boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.07)',
              }}
            >
              <img 
                src={data.attributes.image} 
                height={'536px'} 
                width={'100%'}
              />
            </div>
          </Hidden>
        </Grid>
      </Grid>
    )
  }

  getLeftImage = (data: any) => {
    return (
      <Grid container spacing={8}
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center'
        }}
      >
        <Grid item xs={12} sm={6} md={4}>
          {/* @ts-ignore */}
          <Hidden xsDown>
            <div
              style={{
                height: '536px',
                minHeight: '536px',
                width: '100%',
                minWidth: '100%',
                overflow: 'hidden',
                borderRadius: '24px',
                objectFit: 'contain',
                boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.07)',
              }}
            >
              <img 
                src={data.attributes.image} 
                height={'536px'} 
                width={'100%'}
              />
            </div>
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '25px'
            }}
          >
            {/* @ts-ignore */}
            <Hidden smUp>
              <Typography
                style={{
                  fontSize: '24px',
                  fontFamily: 'Poppins-Bold',
                  color: palette.primary.main,
                  textAlign: 'center'
                }}
              >
                {data.attributes.title}
              </Typography>
            </Hidden>
            {/* @ts-ignore */}
            <Hidden xsDown>
              <Typography
                style={{
                  fontSize: '38px',
                  fontFamily: 'Poppins-Bold',
                  color: palette.primary.main
                }}
              >
                {data.attributes.title}
              </Typography>
            </Hidden>
            {/* @ts-ignore */}
            <Hidden smUp>
              <div
                style={{
                  marginTop: '25px',
                  borderRadius: '10px',
                  minHeight: '169px',
                  maxHeight: '169px',
                  overflow: 'hidden',
                  boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.07)',
                }}
              >
                <img 
                  src={data.attributes.image} 
                  height={'100%'} 
                  width={'100%'}
                />
              </div>
            </Hidden>
            {/* @ts-ignore */}
            <Hidden smUp>
              <Typography
                style={{
                  marginTop: '25px',
                  color: '#333232',
                  fontSize: '14px',
                  fontFamily: 'Poppins-Light',
                  lineHeight: '1.63',
                  textAlign: 'justify'
                }}
              >
                {data.attributes.description}
              </Typography>
            </Hidden>
            {/* @ts-ignore */}
            <Hidden xsDown>
              <Typography
                style={{
                  marginTop: '25px',
                  color: '#333232',
                  fontSize: '19px',
                  fontFamily: 'Poppins-Light',
                  lineHeight: '1.63',
                  textAlign: 'justify'
                }}
              >
                {data.attributes.description}
              </Typography>
            </Hidden>
          </div>
        </Grid>
      </Grid>
    )
  }

  isEven = (position: any) => {
    if(position % 2 == 0){
      return true
    } else {
      return false
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        {
          this.state.loading ?
              <CustomLoader 
                  open={this.state.loading}
              />
          : null
        }
        {
          this.state.alertOpen ?
            <AlertModal 
              open={this.state.alertOpen}
              data={this.state.alertMessage}
              onClose={() => this.closeAlertBox()}
              data-testid={'alertId'}
            />
          : null
        }
        <Container>
          <div
            style={{
              marginTop: '10px'
            }}
          >
            {
              this.getBreadCrumb()
            }
          </div>
          <div
            style={{
              marginTop: '20px',
              marginBottom: '80px'
            }}
          >
            {
              this.state.aboutData.length?  this.state.aboutData.map((item: any, index: any) => {
                return (
                  <div key={index}>
                    {
                      this.isEven(item.attributes.position) ?
                        <div style={{marginTop: '25px'}}>
                          {
                            this.getLeftImage(item)
                          }
                        </div>
                      :
                        <div style={{marginTop: '25px'}}>
                          {
                            this.getRightImage(item)
                          }
                        </div>
                    }
                  </div>
                )
              })
              : null
            }
          </div>
        </Container>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: '20px 0px',
    }
  },
});

const webStyle = {
  mainWrapper: {
    display: 'flex', 
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column', 
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  buttonStyle: { 
    width: '100%',
    height: '45px',  
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)', 
  }
};
// Customizable Area End
