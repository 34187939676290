import React from "react";

import {Box} from "@material-ui/core";
import {palette} from "./theme";


const Divider = (props: any) => {
    return (
        // @ts-ignore
        <Box 
            style={{
                minHeight: '2px', 
                width: '100%', 
                backgroundColor: props.bgColor, 
                opacity: 0.49,
                marginTop: '5px',
                marginBottom: props.mb ? props.mb : '5px'
            }}
        >
        </Box>
    )
}

export default Divider