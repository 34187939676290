import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { BlogType, CategoryType, PaginationType } from "../../../ContentManagement/src/Interfaces.web"
import { 
    IBlog, 
    BlogUniqueItem, 
    Sort, 
    DateTime, 
    BlogData,
    InstructorUniqueItem 
} from "../Interfaces.web";
// Customizable Area End
export const configJSON = require("../config.js");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    mobileOpen: boolean;
    loading: boolean;
    errorMsg: string;
    nextPage: boolean;
    blogUniqueItem: BlogUniqueItem;
    blogList: IBlog[];
    getCategoryLoading: boolean;
    categoryList: CategoryType[];
    authorList: any[];
    authorLoading: boolean;
    selectecCategory: string;
    status: string;
    openSetting: boolean;
    sort: Sort;
    dateTime: DateTime;
    openAlert: boolean;
    dataCreationMsg: string;
    inputError: any;
    previewModal: boolean;
    open: any;
    bold: boolean;
    underlined: boolean;
    italic: boolean;
    alignment: string;
    enableInstructorForm: boolean;
    instructorUniqueItem: InstructorUniqueItem;
    instructorAddLoading: boolean;
    insProfilePreview: any;
    addInstructorName: string;
    addInstructorAbout: string;
    addInstructorProfile: any;
    getCoinstructorLoading: boolean;
    coInstructorList: any;
    coInstructorName: any;
    getMainnstructorLoading: boolean;
    mainInstructorList: any;
    selectedMainInstructor: any;
    lessonObject: any;
    lessonCount: any;
    courseTitle: any;
    courseDesc: any;
    lessonList: any;
    video: any;
    catValidation: string;
    coursePrice: any;
    isFree: boolean;
    bgImage: any;
    previewImg: any;
    previewVideo: any;
    createLoading: boolean;
    coverUpdated: boolean;
    coverUrl: string;
    previewImgUrl: string;
    previewImgUpdated: boolean;
    previewVideoUrl: string;
    previewVideoUpdated: boolean;
    lessonVideoUpdated: boolean;
    materialVideoUpdated: boolean;
    othersName: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EditCourseController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetDataCallId: string = "";
    getAllCategoryId: string = "";
    getAllAuthorId: string = "";
    createBlogId: string = "";
    addInstructorId: string = "";
    getCoInstructorId: string = "";
    getMainInstructorId: string = "";
    createCourseId: string = "";
    getCourseId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // getName(MessageEnum.NavigationToSingleArticle),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            mobileOpen: false,
            loading: false,
            instructorAddLoading: false,
            errorMsg: '',
            nextPage: false,
            blogUniqueItem: {
                title: '',
                subtitle: '',
                bgImg: [],
                base64BgImg: []
            },
            addInstructorName: '',
            addInstructorAbout: '',
            addInstructorProfile: '',
            instructorUniqueItem: {
                instructorName: '',
                instructorAbout: '',
                bgImg: [],
                base64BgImg: []
            },
            blogList: [{
                id: 1,
                dataBaseId: null,
                img: [],
                base64Img: [],
                heading: {
                    text: "",
                    style: {}
                },
                subheading: {
                    text: "",
                    style: {}
                },
                para: {
                    text: "",
                    style: {}
                },
                caption: {
                    text: "",
                    style: {}
                },
            }],
            categoryList: [],
            authorList: [],
            authorLoading: false,
            getCategoryLoading: false,
            selectecCategory: "",
            status: "",
            openAlert: false,
            dataCreationMsg: "",
            openSetting: false,
            sort: {
                publish: 'immediately',
                visibility: 'public_user',
                author: 92
            },
            dateTime: {
                date: new Date(),
                time: new Date(),
            },
            inputError: {},
            previewModal: false,
            open: false,
            bold: false,
            underlined: false,
            italic: false,
            alignment: 'left',
            enableInstructorForm: false,
            insProfilePreview: '',
            getCoinstructorLoading: false,
            coInstructorList: {},
            coInstructorName: [],
            getMainnstructorLoading: false,
            mainInstructorList: [],
            selectedMainInstructor: localStorage.getItem('userType') == 'instructor' ? localStorage.getItem('userId') : '',
            lessonObject: [],
            lessonCount: [1],
            courseTitle: '',
            courseDesc: '',
            lessonList: [],
            video: [],
            catValidation: '',
            coursePrice: '',
            isFree: false,
            bgImage: [],
            previewImg: [],
            previewVideo: [],
            createLoading: false,
            coverUpdated: false,
            coverUrl: '',
            previewImgUpdated: false,
            previewImgUrl: '',
            previewVideoUpdated: false,
            previewVideoUrl: '',
            lessonVideoUpdated: false,
            materialVideoUpdated: false,
            othersName: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {
                if (apiRequestCallId == this.getAllAuthorId) {
                    if (responseJson && !responseJson.error && responseJson.accounts) {
                        if (responseJson.accounts.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                authorLoading: false,
                                // loading: false,
                                authorList: [],
                                // getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false,
                                authorList: responseJson?.accounts,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getCourseId) {
                    if (responseJson && !responseJson.error && responseJson) {
                        const newLessonList = responseJson?.data?.attributes?.lectures.map((item: any) => {
                            return {
                                id: item?.id,
                                databaseId: item?.id,
                                title: item?.attributes?.title,
                                description: item?.attributes?.description,
                                lessonVideoUrl: item?.attributes?.video,
                                video: item?.attributes?.video,
                                todos: item?.attributes?.todos.map((tItem: any) => {
                                    return {
                                        id: tItem?.id,
                                        tDbId: tItem?.id,
                                        title: tItem?.attributes?.title,
                                        description: tItem?.attributes?.description
                                    }
                                }),
                                faqs: item?.attributes?.faqs.map((fItem: any) => {
                                    return {
                                        id: fItem?.id,
                                        fDbId: fItem?.id,
                                        question: fItem?.attributes?.question,
                                        answer: fItem?.attributes?.answer
                                    }
                                }),
                                questions_attributes: item?.attributes?.questions.map((qItem: any) => {
                                    return {
                                        id: qItem.id,
                                        qDbId: qItem.id,
                                        question: qItem?.attributes.question,
                                        option1: qItem?.attributes.option1,
                                        option2: qItem?.attributes.option2,
                                        option3: qItem?.attributes.option3,
                                        option4: qItem?.attributes.option4,
                                        description: qItem?.attributes?.description,
                                        correct_answer: qItem?.attributes.correct_answer
                                    }
                                }),
                                transcript: item?.attributes?.transcript?.map((tItem: any) => {
                                    return {
                                        id: new Date().getTime(),
                                        time: tItem?.time,
                                        value: tItem?.value
                                    }
                                }),
                                timestamp: item?.attributes?.timestamp?.map((sItem: any) => {
                                    return {
                                        id: new Date().getTime(),
                                        time: sItem?.time,
                                        value: sItem?.value
                                    }
                                }),
                                material_details_attributes: item?.attributes?.material_details.map((mItem: any)=>{
                                    return {
                                        id: mItem?.id,
                                        mDbId: mItem?.id,
                                        title: mItem?.attributes?.title,
                                        summary: mItem?.attributes?.summary,
                                        description: mItem?.attributes?.description,
                                        materialUrl: mItem?.attributes?.material_file,
                                        material_file: mItem?.attributes?.material_file
                                    }
                                })
                            }
                        })
                        let sort = {
                            publish: responseJson?.data?.attributes?.publish_timing,
                            visibility: responseJson?.data?.attributes?.visibility,
                            author: responseJson?.data?.attributes?.instructor_details?.data?.id
                        }
                        let date = {
                            date: responseJson?.data?.attributes?.scheduled_date,
                            time: responseJson?.data?.attributes?.scheduled_time
                        }
                        if (responseJson.data.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                courseTitle: responseJson?.data?.attributes?.title,
                                courseDesc: responseJson?.data?.attributes?.about,
                                coursePrice: responseJson?.data?.attributes?.price,
                                isFree: responseJson?.data?.attributes?.free,
                                coverUrl: responseJson?.data?.attributes?.background_image,
                                bgImage: responseJson?.data?.attributes?.background_image,
                                previewImgUrl: responseJson?.data?.attributes?.preview_image,
                                previewImg: responseJson?.data?.attributes?.preview_image,
                                previewVideoUrl: responseJson?.data?.attributes?.preview_video,
                                previewVideo: responseJson?.data?.attributes?.preview_video,
                                coInstructorName: responseJson?.data?.attributes?.other_instructors?.data.map((item: any) => {
                                    return item.id
                                }),
                                othersName: responseJson?.data?.attributes?.tag_others.data.map((item: any) => {
                                    return item.id
                                }),
                                selectecCategory: responseJson?.data?.attributes?.category?.id,
                                lessonList: newLessonList,
                                status: responseJson?.data?.attributes?.status,
                                sort: sort,
                                dateTime: date
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                // getCategoryLoading: false,
                                // authorLoading: false
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                // getCategoryLoading: false,
                                // authorLoading: false
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getAllCategoryId) {
                    if (responseJson && !responseJson.error && responseJson.data) {
                        if (responseJson.data.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                // loading: false,
                                categoryList: [],
                                getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                getCategoryLoading: false,
                                categoryList: responseJson?.data,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                getCategoryLoading: false,
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getCoInstructorId) {
                    if (responseJson && !responseJson.error && responseJson.data) {
                        if (responseJson.data.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                // loading: false,
                                coInstructorList: [],
                                getCoinstructorLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                getCoinstructorLoading: false,
                                coInstructorList: responseJson?.data,
                            }, () => {
                                this.setState({
                                    coInstructorList: responseJson?.data,
                                })
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                getCoinstructorLoading: false,
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                getCoinstructorLoading: false,
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getMainInstructorId) {
                    if (responseJson && !responseJson.error && responseJson.accounts) {
                        if (responseJson.accounts.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                getMainnstructorLoading: false,
                                // loading: false,
                                mainInstructorList: [],
                                // getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                // getCategoryLoading: false,
                                getMainnstructorLoading: false,
                                mainInstructorList: responseJson?.accounts,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                // getCategoryLoading: false,
                                getMainnstructorLoading: false
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                // getCategoryLoading: false,
                                getMainnstructorLoading: false
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.createCourseId) {
                    if (responseJson && !responseJson.error) {
                        if (responseJson.data.type !== "error") {
                            this.setState({
                                ...this.state,
                                dataCreationMsg: "Course updated successfully!",
                                // loading: false,
                                openAlert: true,
                                errorMsg: "",
                                nextPage: false,
                                courseTitle: '',
                                courseDesc: '',
                                createLoading: false
                                // categoryList: responseJson?.data,
                            });
                        }
                        else {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "Please fill required data",
                                errorMsg: "",
                                createLoading: false
                            })
                        }

                    } else {
                        if (responseJson.error) {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "",
                                errorMsg: "Internal Server Error",
                                createLoading: false
                            })
                        }
                        else if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                dataCreationMsg: "",
                                openAlert: true,
                                createLoading: false
                                // loading: false,
                            });
                        } else {
                            this.setState({
                                openAlert: true,
                                errorMsg: errorReponse,
                                dataCreationMsg: "",
                                createLoading: false
                                // loading: false,
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.addInstructorId) {
                    if (responseJson && !responseJson.error) {
                        if (responseJson.data.type !== "error") {
                            this.setState({
                                ...this.state,
                                dataCreationMsg: "Contributor added successfully!",
                                // loading: false,
                                openAlert: true,
                                errorMsg: "",
                                addInstructorAbout: '',
                                addInstructorName: '',
                                addInstructorProfile: '',
                                enableInstructorForm: false,
                                instructorAddLoading: false
                                // categoryList: responseJson?.data,
                            }, () => {
                                this.getCoInstructors();
                            });
                        }
                        else {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "Please fill required data",
                                errorMsg: ""
                            })
                        }

                    } else {
                        if (responseJson.error) {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "",
                                errorMsg: "Internal Server Error"
                            })
                        }
                        else if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                dataCreationMsg: "",
                                openAlert: true,
                                // loading: false,
                            });
                        } else {
                            this.setState({
                                openAlert: true,
                                errorMsg: errorReponse,
                                dataCreationMsg: ""
                                // loading: false,
                            });
                        }
                    }
                }
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.getCategory()
        this.getAuthorList()
        this.getCoInstructors()
        this.getMainInstructors()
        this.getCourse()
    }

    // Customizable Area End

    // Customizable Area Start

    validateCourse = (error: any) => {
        const { 
            courseTitle,
            courseDesc,
            selectedMainInstructor,
            selectecCategory,
            coInstructorName,
            coursePrice,
            previewImg,
            previewVideo,
            bgImage
        } = this.state
        if (!courseTitle) {
            error.courseTitle = "Please Provide Title"
        }
        if (!courseDesc) {
            error.courseDesc = "Please Provide Course Description"
        }  
        if (!selectedMainInstructor) {
            error.instructor = "Please select instructor"
        }
        if (!coInstructorName) {
            error.coInstructor = "Please select co-instructor"
        }
        if(!selectecCategory){
            this.setState({
                openSetting: true,
                catValidation: "Please select category"
            })
        }
        if(!bgImage) {
            this.setState({
                openSetting: true,
                errorMsg: 'Please upload Cover image'
            })
            error.bgImage = 'Please upload Cover image'
        }
        if(!previewImg) {
            error.previewImg = "Please upload preview image"
        }
        if(!previewVideo) {
            error.previewVideo = "Please upload preview video"
        }
        // if(isNaN(coursePrice)){
        //     error.coursePrice = 'Please enter valid price'
        // }
    }

    validateTodos = (tItem: any, error: any) => {
        if(!tItem.title){
            error.todosTitle = "Please provide title"
        }
        if(!tItem.description){
            error.todosDesc = "Please provide description"
        }
    }

    validateFaqs = (fItem: any, error: any) => {
        if(!fItem.question){
            error.faqsQuestion = "Please provide question"
        }
        if(!fItem.answer){
            error.faqsAnswer = "Please provide answer"
        }
    }

    validateTranscript = (tItem: any, error: any) => {
        if(!tItem.time){
            error.tTime = "Please provide time"
        }
        if(!tItem.value){
            error.tDescription = "Please provide description"
        }
    }

    validateTimestamp = (tItem: any, error: any) => {
        if(!tItem.time){
            error.sTime = "Please provide time"
        }
        if(!tItem.value){
            error.sDescription = "Please provide description"
        }
    }

    validateMaterial = (mItem: any, error: any) => {
        if(!mItem.title){
            error.mTitle = "Please provide material title"
        }
        if(!mItem.summary){
            error.mSummary = "Please provide material summary"
        }
        if(!mItem.description){
            error.mDescription = "Please provide material description"
        }
        if(!mItem.material_file){
            error.mPic = "Please provide material image"
        }
    }

    validateQuestion = (qItem: any, error: any) => {
        if(!qItem.question){
            error.question = "Please provide question"
        }
        if(!qItem.description){
            error.description = "Please provide question subheading"
        }
        if(!qItem.option1){
            error.option1 = "Please provide option answer"
        }
        if(!qItem.option2){
            error.option2 = "Please provide option answer"
        }
        if(!qItem.option3){
            error.option3 = "Please provide option answer"
        }
        if(!qItem.option4){
            error.option4 = "Please provide option answer"
        }
        if(!qItem.correct_answer){
            error.correctAnswer = "Please provide correct answer"
        }
    }

    validateLesson = (lesson: any, error: any) => {
        if(!lesson.title){
            error.lessonTitle = "Please provide lesson title"
        }
        if(!lesson.description){
            error.lessonDescription = "Please provide lesson description"
        }
        if(!lesson.video){
            error.lessonVideo = "Please upload a video"
        }
    }

    handleSelectOthers = (e: any) => {
        this.setState({
            othersName: e.target.value
        })
    }

    fieldValidation = () => {
        const { 
            lessonList,
        } = this.state
        let error: any = {}
        this.validateCourse(error)
        lessonList.map((lesson: any) => {
            this.validateLesson(lesson, error)
            lesson.questions_attributes.map((qItem: any) => {
                this.validateQuestion(qItem, error)
            }) 

            lesson.transcript.map((tItem: any) => {
                this.validateTranscript(tItem, error)
            }) 

            lesson.timestamp.map((tItem: any) => {
                this.validateTimestamp(tItem, error)
            }) 

            lesson.todos.map((tItem: any) => {
                this.validateTodos(tItem, error)
            })

            lesson.faqs.map((fItem: any) => {
                this.validateFaqs(fItem, error)
            })
            
            lesson.material_details_attributes.map((mItem: any) => {
                this.validateMaterial(mItem, error)
            })
        });
        
        if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
            this.setState({ inputError: {} })
            this.createCourse()
        }
        else {
            this.setState({ inputError: error })
        }
    }

    addPreviewPic = (file: any) => {
        this.setState({
            previewImgUpdated: true,
            previewImg: file
        })
    }

    addPreviewVideo = (file: any) => {
        this.setState({
            previewVideoUpdated: true,
            previewVideo: file
        })
    }

    handleTodoChange = (id: number, e: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    todos: lesson.todos.map((q: any) => {
                        if(q.id == id){
                            return {
                                ...q,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return q
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList }, () => {console.log(this.state.lessonList)})
    }

    handleFaqChange = (id: number, e: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    faqs: lesson.faqs.map((q: any) => {
                        if(q.id == id){
                            return {
                                ...q,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return q
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList }, () => {console.log(this.state.lessonList)})
    }

    getTodoFormData = (item: any, formData: any) => {
        return (
            item.todos.length && item.todos.map((tItem: any) => {
                return (
                    tItem.tDbId ?
                        formData.append("course[lectures_attributes][][todos_attributes][][id]", tItem.tDbId)
                    : null,
                    formData.append("course[lectures_attributes][][todos_attributes][][title]", tItem.title),
                    formData.append("course[lectures_attributes][][todos_attributes][][description]", tItem.description)
                )
            })
        )
    }

    getFaqFormData = (item: any, formData: any) => {
        return (
            item.faqs.length && item.faqs.map((fItem: any) => {
                return (
                    fItem.fDbId ?
                        formData.append("course[lectures_attributes][][faqs_attributes][][id]", fItem.fDbId)
                    : null,
                    
                    formData.append("course[lectures_attributes][][faqs_attributes][][question]", fItem.question),
                    formData.append("course[lectures_attributes][][faqs_attributes][][answer]", fItem.answer)
                )
            }),
            
            item.questions_attributes.length && item.questions_attributes.map((qItem: any, i: any)=>{
                return(
                    qItem.qDbId ? 
                        formData.append("course[lectures_attributes][][questions_attributes][][id]", qItem.id)
                    : null,
                    formData.append("course[lectures_attributes][][questions_attributes][][question]", qItem.question),
                    formData.append("course[lectures_attributes][][questions_attributes][][description]", qItem.description),
                    formData.append("course[lectures_attributes][][questions_attributes][][correct_answer]", qItem.correct_answer),
                    formData.append("course[lectures_attributes][][questions_attributes][][option1]", qItem.option1),
                    formData.append("course[lectures_attributes][][questions_attributes][][option2]", qItem.option2),
                    formData.append("course[lectures_attributes][][questions_attributes][][option3]", qItem.option3),
                    formData.append("course[lectures_attributes][][questions_attributes][][option4]", qItem.option4)
                )
            })
        )
    }

    lessonListData = (lessonList: any, formData: any) => {
        let blobVideo: any = '';
        let videoType: any = '';
        return lessonList.map((item: any) => {
            videoType = typeof item.video
            if(videoType === 'object'){
                blobVideo = new Blob(item.video)
            }
            return (
                formData.append('course[lectures_attributes][][title]', item.title),
                item.databaseId ? 
                formData.append('course[lectures_attributes][][id]', item.databaseId)
                : null,
                formData.append('course[lectures_attributes][][description]', item.description),
                videoType === 'object' ?
                    formData.append('course[lectures_attributes][][video]', blobVideo)
                : null,
                this.getFaqFormData(item, formData),
                this.getTodoFormData(item, formData),
                item.material_details_attributes.length && item.material_details_attributes.map((mItem: any, i: any)=>{
                    let blobImg = new Blob([mItem.material_file])
                    return(
                        mItem.mDbId ?
                            formData.append("course[lectures_attributes][][material_details_attributes][][id]", mItem.id)
                        : null,
                        formData.append("course[lectures_attributes][][material_details_attributes][][title]", mItem.title),
                        formData.append("course[lectures_attributes][][material_details_attributes][][description]", mItem.description),
                        formData.append("course[lectures_attributes][][material_details_attributes][][summary]", mItem.summary),
                        this.state.materialVideoUpdated ? formData.append("course[lectures_attributes][][material_details_attributes][][material_file]", blobImg) : null
                    )
                }),
                item.transcript.length && item.transcript.map((tItem: any, i: any)=>{
                    return(
                        formData.append("course[lectures_attributes][][transcript][][time]", tItem.time),
                        formData.append("course[lectures_attributes][][transcript][][value]", tItem.value)
                    )
                }),
                item.timestamp.length && item.timestamp.map((sItem: any, i: any)=>{
                    return(
                        formData.append("course[lectures_attributes][][timestamp][][time]", sItem.time),
                        formData.append("course[lectures_attributes][][timestamp][][value]", sItem.value)
                    )
                })
            )
        })
    }

    getStatus = (status: any, sort: any) => {
        if(status == 'draft'){
            return status
        } else {
            return status == "published" && sort.publish == "schedule_post" ? 'scheduled' : status
        }
    }

    createCourse = () => {
        this.setState({
            createLoading: true
        })
        const id = window.location.href.split('/', 6).pop();
        const {
            courseTitle,
            courseDesc,
            selectedMainInstructor,
            coInstructorName,
            status,
            sort,
            dateTime,
            selectecCategory,
            lessonList,
            coursePrice,
            previewImg,
            previewVideo,
            bgImage,
            othersName
        } = this.state;
            
        const formData = new FormData();
        const header = {
            // "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token")
        };
        
        formData.append('course[title]', courseTitle);
        formData.append('course[about]', courseDesc);
        othersName.map((id: any) => {
            return (
                formData.append('course[tag_others][]', id)
            )
        })
        if(!coursePrice){
            // @ts-ignore
            formData.append('course[free]', true);
        } else {
            // @ts-ignore
            formData.append('course[free]', false);
            formData.append('course[price]', coursePrice);
        }
        coInstructorName.map((id: any) => {
            return (
                formData.append('course[local_instructor_ids][]', id)
            )
        })
        
        this.lessonListData(lessonList, formData)
        if(this.state.previewImgUpdated){
            let blobPriviewImg = new Blob(previewImg);
            formData.append('course[preview_image]', blobPriviewImg);
        }
        if(this.state.coverUpdated){
            let blobBgImage = new Blob(bgImage);
            formData.append('course[background_image]', blobBgImage);
        }
        if(this.state.previewVideoUpdated){
            let blobPriviewVideo = new Blob(previewVideo);
            formData.append('course[preview_video]', blobPriviewVideo);
        }
        formData.append('course[instructor_id]', selectedMainInstructor);
        
        formData.append('course[status]', this.getStatus(status, sort))
        formData.append('course[publish_timing]', sort.publish)
        formData.append('course[visibility]', sort.visibility)
        // @ts-ignore
        formData.append('course[scheduled_date]', dateTime.date)
        // @ts-ignore
        formData.append('course[scheduled_time]', dateTime.time)
        formData.append('course[category_id]', selectecCategory)
        
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createCourseId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateCourseApi+'/'+id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData

        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'PUT'
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getBase64(file: any, cb: any) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    getCategory = () => {
        this.setState({ getCategoryLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllCategoryId = requestMessage.messageId;
        const hitURL = `${configJSON.categoryUrl}?type=course_category`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            hitURL
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCourse = () => {
        const id = window.location.href.split('/', 6).pop()
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCourseId = requestMessage.messageId;
        const hitURL = `${configJSON.getCourseApi}/${id}?dashboard=true`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            hitURL
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCoInstructors = () => {
        this.setState({ getCoinstructorLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCoInstructorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCoInstructorApi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getMainInstructors = () => {
        this.setState({ getMainnstructorLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getMainInstructorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAuthorUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addInstructor = () => {
        if(!this.state.addInstructorName){
            let error: any = {};
            error.addInsName = 'Please provide instructor name!';
            this.setState({
                inputError: error
            })
            return;
        }
        if(!this.state.addInstructorProfile){
            let error: any = {};
            error.addProfilePic = 'Please upload a profile picture!';
            this.setState({
                inputError: error
            })
            return;
        }
        if(!this.state.addInstructorAbout){
            let error: any = {};
            error.addInsAbout = 'Please provide instructor about!';
            this.setState({
                inputError: error
            })
            return;
        }
        this.setState({ 
            instructorAddLoading: true,
        })
        console.log("INSTRUCTOR PROFIL", this.state.addInstructorProfile)
        const userToken = localStorage.getItem("token");
        const formData = new FormData();
        formData.append('instructor[name]', this.state.addInstructorName)
        formData.append('instructor[about]', this.state.addInstructorAbout)
        formData.append('instructor[profile_image]', this.state.addInstructorProfile)

        const header = {
            // "Content-Type": "multipart/form-data",
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addInstructorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addInstructorApi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getAuthorList = () => {
        this.setState({ getCategoryLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllAuthorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAuthorUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    addNewBlog = () => {
        this.setState((prev_state) => {
            return {
                blogList: [...prev_state.blogList, {
                    id: new Date().getTime(),
                    dataBaseId: null,
                    img: [],
                    base64Img: [],
                    heading: {
                        text: "",
                        style: {}
                    },
                    subheading: {
                        text: "",
                        style: {}
                    },
                    para: {
                        text: "",
                        style: {}
                    },
                    caption: {
                        text: "",
                        style: {}
                    },
                }]
            }
        })
    }

    removeBlog = (id: number) => {
        const _newblogList = this.state.blogList.filter(blg => blg.id !== id)
        this.setState({ blogList: _newblogList })
    }

    handleBlogUniqueItemChange = (event: any, name: string) => {
        const { value } = event.target;
        this.setState({
            blogUniqueItem: {
                ...this.state.blogUniqueItem,
                [name]: value
            }
        });
    };

    handleInstructorChange = (event: any, name: string) => {
        const { value } = event.target;
        if(name == 'addInstructorName'){
            this.setState({
                addInstructorName: value
            });
        } else {
            this.setState({
                addInstructorAbout: value
            });
        }
    };

    addInstructorProfilePic = (acceptedFiles: any) => {
        console.log("Contributor profile", acceptedFiles);
        this.setState({
            addInstructorProfile: acceptedFiles[0]    
        });
    }

    handleBgImgChange = (value: any[], files: any[]) => {
        this.setState({
            coverUpdated: true,
            coverUrl: '',
            bgImage: files
        })
    }

    handleLessonChange = (id: number, e: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === id) {
                return {
                    ...lesson,
                    [e.target.name]: e.target.value
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    addLessonVideo = (id: any, acceptedFiles: any) => {
        const { lessonList } = this.state;
        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === id) {
                return {
                    ...lesson,
                    video: acceptedFiles
                }
            } else {
                return lesson;
            }
        })

        this.setState({ 
            lessonList: newLessonList,
            lessonVideoUpdated: true
        })
    }

    handleQuestionChange = (id: number, e: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    questions_attributes: lesson.questions_attributes.map((q: any) => {
                        if(q.id == id){
                            return {
                                ...q,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return q
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    handleTransChange = (id: number, e: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    transcript: lesson.transcript.map((q: any) => {
                        if(q.id == id){
                            return {
                                ...q,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return q
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    handleStampChange = (id: number, e: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    timestamp: lesson.timestamp.map((q: any) => {
                        if(q.id == id){
                            return {
                                ...q,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return q
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    handleMaterialChange = (id: number, e: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    material_details_attributes: lesson.material_details_attributes.map((m: any) => {
                        if(m.id == id){
                            return {
                                ...m,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return m
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    addLessonMaterialImage = (id: number, value: any, pId: any) => {
        const { lessonList } = this.state;
        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    material_details_attributes: lesson.material_details_attributes.map((m: any) => {
                        if(m.id == id){
                            return {
                                ...m,
                                'material_file': value
                            }
                        } else {
                            return m
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ 
            lessonList: newLessonList,
            materialVideoUpdated: true
        })
    }

    handleSubheadingChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    subheading: {
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleParagraphChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    para: {
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleImgChange = (id: number, value: any[], files: any[]) => {
        const { blogList } = this.state;
        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    img: [...blg.img, ...value],
                    base64Img: [...blg.base64Img, ...files]
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleCaptionChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    caption: {
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleCategorySelect = (id: string) => {
        this.setState({ selectecCategory: id })
    }

    hanldeSettingToggle = () => {
        this.setState({ openSetting: !this.state.openSetting })
    }

    handleSortChange = (event: any, name: string) => {
        const { value } = event.target;
        this.setState({
            sort: {
                ...this.state.sort,
                [name]: value
            }
        });
    };

    handleDateTimeChange = (value: Date, name: string) => {
        this.setState({
            dateTime: {
                ...this.state.dateTime,
                [name]: value,
            }
        })
    }

    handleDraft = () => {
        this.setState((prev_stat) => {
            return {
                ...prev_stat,
                status: "draft"
            }
        }, () => this.fieldValidation())
    }

    handlePublish = () => {
        this.setState((prev_stat) => {
            return {
                ...prev_stat,
                status: "published"
            }
        }, () => this.fieldValidation())
    }

    closeAlertBox = () => {
        this.setState({
            openAlert: false
        });
    };

    handlePreviewOpen = () => {
        this.setState({ previewModal: true })
    }

    handleModalClose = () => {
        this.setState({ previewModal: false })
    }

    // Customizable Area End

}
