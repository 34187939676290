Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectNotes";
exports.labelBodyText = "ProjectNotes Body";
exports.getPodcastCategoryOptionsApiEndPoint = "bx_block_categories/categories?type=course_category";
exports.getAllCoursesApiEndPoint = "bx_block_library2/courses";
exports.postReviewUrl = "/bx_block_library2/reviews";
exports.updateFavouriteApi = "bx_block_library2/courses/favourite";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End