import React from "react";

// Customizable Area Start
import {
  Grid,
  Box,
  Typography,
  Button
} from '@material-ui/core';
import CustomCard from '../../../components/src/CustomCard';
import CustomInput from '../../../components/src/CustomInput';
import CustomButton from "../../../components/src/CustomButton";
import {palette} from "../../../components/src/theme";
import Divider from "../../../components/src/Divider";
import { withStyles } from "@material-ui/core/styles";
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import LoginWithGoogle from "../../../components/src/GoogleLogin";
import LoginWithFacebbok from "../../../components/src/FacebookLogin";
import AlertModal from "../../../components/src/AlertModal";


import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";



const CustomColorCheckbox = withStyles({
  root: {
    color: "#000",
    marginTop: '-9px',
    "&$checked": {
      color: "#000",
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const style = (theme: any) => ({
  container: {
    flex: 1,
    marginTop: '20px',
    // padding: 60,
    // paddingTop: '20px',
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#ffffffff"
  },
  mainGrid: {
    padding: '60px',
    paddingTop: '20px',
    [theme.breakpoints.down("sm")]: {
      padding: '0px',
      paddingTop: '20px'
    },
  },
  welcomeText: {
    paddingLeft: '65px',
    [theme.breakpoints.down("sm")]: {
      padding: '0px',
      textAlign: 'center'
    },
  },
  cardWrap: {
    padding: '20px',
    paddingRight: '50px', 
    [theme.breakpoints.down("sm")]: {
      padding: '20px',
    },
  },
  cardPad: {
    padding: '50px',
    boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.13)',
    borderRadius: '20px',
    [theme.breakpoints.down("sm")]: {
      padding: '20px',
    },
  },
  acceptText: {
    fontFamily: 'Poppins-Light',
    fontSize: '16px',
    fontWeight: 'normal',
    [theme.breakpoints.between("xs", "md")]: {
      fontFamily: 'Poppins-Light',
      fontSize: '12px',
      fontWeight: 'normal',
      marginTop: '5px'
    },
  },
  termsText: {
    fontFamily: 'Poppins-SemiBold',
    fontSize: '16px',
    fontWeight: 'normal',
    marginLeft: "5px",
    cursor: 'pointer',
    [theme.breakpoints.between("xs", "md")]: {
      fontFamily: 'Poppins-SemiBold',
      fontSize: '12px',
      fontWeight: 'normal',
      marginLeft: "5px",
      cursor: 'pointer',
      marginTop: '5px'
    },
  },
  andText: {
    fontFamily: 'Poppins-SemiBold',
    fontSize: '16px',
    fontWeight: 'normal',
    marginLeft: "5px",
    [theme.breakpoints.between("xs", "md")]: {
      fontFamily: 'Poppins-SemiBold',
      fontSize: '12px',
      fontWeight: 'normal',
      marginLeft: "5px",
      marginTop: '5px'
    },
  }
});

class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
  
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({termsAndCondition : event.target.checked});
  };

  closeAlertBox = () => {
    this.setState({
      openAlert: false
    })
  }

  render() {
    const {classes} = this.props
    return (
      <Box className={classes.container}>
        {
          this.state.openAlert ? 
            <AlertModal 
              open={this.state.openAlert} 
              data={this.state.errorData}
              onClose={() => this.closeAlertBox()}
            /> 
          : null
        }
          <Box>
            <Grid container className={classes.mainGrid}>
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  style={{
                    flex: 1,
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    padding: '20px',
                  }}
                  display={{xs: 'none', sm: 'none', md: 'flex'}}
                >
                  <Box
                    style={{
                      paddingLeft: '65px'
                    }}
                  >
                    <Typography 
                      variant="h3" 
                      style={{
                        fontFamily: 'Poppins-Bold',
                        fontSize: '20px',
                        letterSpacing: '0.13px'
                      }}
                    >
                      Welcome!
                    </Typography>
                    <Typography 
                      variant="h3" 
                      style={{
                        fontFamily: 'Poppins-ExtraBold',
                        fontSize: '40px',
                        marginTop: '10px'
                      }}
                    >
                      Fill the details to be part of the Let’s Be Real team.
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      marginTop: '50px',
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <img width={'60%'} src={require('../assets/signup.png')} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  className={classes.cardWrap}
                >
                  <CustomCard
                    className={classes.cardPad}
                  >
                    <Box 
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Box>
                        <Typography 
                          variant="h3" 
                          style={{
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '30px'
                          }}
                        >
                          Get Started
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          width: '100%',
                        }}
                      >
                        <CustomInput 
                          passwordfield={false}
                          placeholder={'Full Name'}
                          icon={require('../assets/user-icon.png')}
                          iconHeight={'24px'}
                          onChange={(e: any) => this.handleInputChange("fullName", e)}
                        />
                      </Box>
                      <Box
                        style={{
                          width: '100%',
                        }}
                      >
                        <CustomInput 
                          passwordfield={false}
                          placeholder={'Email'}
                          icon={require('../assets/email-icon.png')}
                          iconHeight={'24px'}
                          onChange={(e: any) => this.handleInputChange("email", e)}
                        />
                      </Box>
                      <Box
                        style={{
                          width: '100%',
                        }}
                      >
                        <CustomInput 
                          passwordfield={true}
                          placeholder={'Password'}
                          icon={require('../assets/lock-icon.png')}
                          iconHeight={'24px'}
                          endIcon={require('../assets/eye-icon.png')}
                          onChange={(e: any) => this.handleInputChange("password", e)}
                        />
                      </Box>
                      <Box
                        style={{
                          width: '100%',
                        }}
                      >
                        <CustomInput 
                          passwordfield={true}
                          placeholder={'Repeat Password'}
                          icon={require('../assets/lock-icon.png')}
                          iconHeight={'24px'}
                          endIcon={require('../assets/eye-icon.png')}
                          onChange={(e: any) => this.handleInputChange("reTypePassword", e)}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Box
                          style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                          mt={3}
                        >
                          <CustomColorCheckbox 
                            checked={this.state.termsAndCondition}
                            onChange={(e: any) => this.handleCheckBoxChange(e)}
                          />
                          <Typography 
                            className={classes.acceptText}
                          >
                            I accepted 
                          </Typography>
                          <Typography 
                            className={classes.termsText}
                            onClick={() => this.props.navigation.navigate('TermsAndCondition')}
                          >
                            {"Terms"}
                          </Typography>
                          <Typography 
                            className={classes.andText}
                          >
                            {"&"}
                          </Typography>
                          <Typography 
                            className={classes.termsText}
                            onClick={() => this.props.navigation.navigate('PrivacyPolicy')}
                          >
                            {"Privacy Policy"}
                          </Typography>
                        </Box>
                    <Box style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                      mt={3}
                    >
                      <CustomColorCheckbox
                        checked={this.state.newsletter}
                        onChange={(e: any) => this.handleNewsLetterCheckBoxChange(e)}
                      />
                      <Typography
                        className={classes.acceptText}
                      >
                        {this.state.newsLetter_text}
                      </Typography>
                    </Box>
                    </Box>
                    {/* @ts-ignore */}
                    <Box
                      mt={3}
                    >
                      <CustomButton 
                        title={this.state.loading ? 'Signing Up...' : 'Register' }
                        color={palette.primary.main}
                        textColor={"#FFFFFF"}
                        fullWidth={true}
                        height={'54px'}
                        fontSize={'20px'}
                        onClick={() => this.createAccount()}
                      />
                    </Box>
                    <Box
                      mt={2}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Box
                        style={{
                          flex: 2,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography 
                          style={{
                            fontFamily: 'Poppins-Light',
                            fontSize: '14px',
                            fontWeight: 'normal'
                          }}
                        >
                          or continue with
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <LoginWithFacebbok 
                          getProfile={(profile: any) => this.getFacebookProfile(profile)}
                        />
                        <LoginWithGoogle 
                          getProfile={(profile: any) => this.getGoogleProfile(profile)}
                        />
                      </Box>
                    </Box>
                    <Box mt={2}>
                      <Divider 
                        bgColor={palette.primary.main}
                      />
                    </Box>
                    <Box
                        style={{
                          flex: 2,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                        mt={2}
                      >
                        <Typography 
                          style={{
                            fontFamily: 'Poppins-Light',
                            fontSize: '14px',
                            fontWeight: 'normal'
                          }}
                        >
                          Don't have an account?
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '14px',
                            marginLeft: "10px",
                            color: palette.primary.main
                          }}
                        >
                          <Button 
                            color="inherit"
                            style={{
                              textTransform: 'none'
                            }}
                            onClick={() => this.props.navigation.navigate('Login')}
                          >
                            Sign In
                          </Button>
                        </Typography>
                      </Box>
                  </CustomCard>
                </Box>
              </Grid>
            </Grid>
          </Box>
      </Box>
    );
  }
}
// @ts-ignore
export default withStyles(style)(EmailAccountRegistrationBlock)

// Customizable Area End