'use strict';

const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";
const devURL =
  'https://letsbereal-64156-ruby.b64156.dev.eastus.az.svc.builder.cafe';

Object.defineProperty(exports, '__esModule', {
  value: true
});
exports.baseURL =
  process.env.NODE_ENV === 'development' ? devURL : baseURL;
