import React from "react";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    IconButton,
    Toolbar,
    Button,
    AppBar,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Modal
} from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu';
import LetsWatchController, { Props } from "./LetsWatchController";
import CustomInput from "../../../../components/src/CustomInput";
import ThreeDot from "../../../../components/src/ThreeDot";
import { palette } from "../../../../components/src/theme";
import SortingHeader from "../../../../components/src/SortingHeader";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import { 
    BarChart, 
    Bar, 
    XAxis, 
    YAxis,
    CartesianGrid, 
    Tooltip, 
    ResponsiveContainer,
    // @ts-ignore
} from 'recharts';
import AlertModal from "../../../../components/src/AlertModal";
import PreviewBE from "../../../../components/src/PreviewBE";
// @ts-ignore
import { EditIcon } from "../assets"
import CustomLoader from "../../../../components/src/CustomLoader";
import AdminSearch from "../../../../components/src/AdminSearch";
import CircularProgress from '@material-ui/core/CircularProgress';


const drawerWidth = 240;

export const typeBtn = [
    {
        id: 0,
        title: 'published'
    },
    {
        id: 1,
        title: 'scheduled'
    },
    {
        id: 2,
        title: 'draft'
    },
    {
        id: 3,
        title: 'deleted'
    }
]

export const dasboardFullDateFormatter = (date: string) => {
    if (!date) return;
    return moment.utc(date, "YYYY-MM-DD HH:mm:ss").local().format('D-MMM-YYYY');
}

// Customizable Area End

class LetsWatch extends LetsWatchController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    handleTabsChange = (event: any, newValue: any) => {
        this.setState({ tabs: newValue })
        const type = typeBtn.find(dt => dt.id === newValue)
        const { sorting } = this.state
        this.getData(type?.title as string, sorting, 1 )
    }

    changePage = (event: React.ChangeEvent<unknown>, value: number) => {
        // @ts-ignore
        // document.getElementById('pag').scrollIntoView()
        const { loading, sorting, type } = this.state
        // const type = typeBtn.find(dt => dt.id === tabs)
        if (!loading) {
            this.getData(type, sorting, value);
        }
    };

    getFirstTab = (loading: any, sortingLoader: any, errorMsg: any, apiData: any, blogPerPage: any, classes: any) => {
        const baseUrl = window.location.href.split('/', 3).pop();
        if(loading || errorMsg || sortingLoader){
            return null
        }

        return (
            apiData.data.length ?
            <TableContainer component={Paper}>
                <Table style={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">{"Title"}</TableCell>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Categories</TableCell>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Published by</TableCell>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Date Publised</TableCell>
                            {/* <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Total Lectures</TableCell> */}
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="right">{"Actions"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apiData.data
                            .slice(0, blogPerPage)
                            .map((row: any) => (
                                <TableRow
                                    key={row.id}
                                    className={classes.lastTableRow}
                                >
                                    <TableCell style={{ fontFamily: "Poppins-SemiBold" }} align="left">{row.attributes.title}</TableCell>
                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">{row.attributes.category.name}</TableCell>
                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                                        {row.attributes.instructor.name ?
                                            row.attributes.instructor.name
                                            // @ts-ignore
                                            : row.attributes.instructor_detail.data.attributes.full_name
                                        }
                                    </TableCell>
                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                                        <Button>
                                            {dasboardFullDateFormatter(row.attributes.created_at)}
                                        </Button>
                                    </TableCell>
                                    {/* @ts-ignore */}
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => this.goToEditPage(row.id)}
                                            style={{
                                                height: '45px',
                                                width: '45px',
                                                borderRadius: '5px',
                                                marginLeft: '25px'
                                            }}
                                        >
                                            <img src={EditIcon} height={'20px'} />
                                        </IconButton>
                                        <ThreeDot 
                                            blogData={row} 
                                            isPublish={false}
                                            copyLink={baseUrl+'/WatchVideo/'+row.id}
                                            type={'video'}
                                            // @ts-ignore
                                            handleStatOpen={this.handleStatOpen}
                                            handleStatusUpdate={this.handleStatusUpdate}
                                            handlePreviewOpen={this.handlePreviewOpen}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            :
            null
        )
    }

    getSecondTab = (loading: any, sortingLoader: any, errorMsg: any, apiData: any, blogPerPage: any, classes: any) => {
        const baseUrl = window.location.href.split('/', 3).pop();
        if(loading || errorMsg || sortingLoader){
            return null
        }

        return (
            apiData.data.length ?
            <TableContainer component={Paper}>
                <Table style={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">{"Title"}</TableCell>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Categories</TableCell>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">To be Published by</TableCell>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Date Scheduled</TableCell>
                            {/* <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Total Lectures</TableCell> */}
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="right">{"Actions"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apiData.data
                            .slice(0, blogPerPage)
                            .map((row: any) => (
                                <TableRow
                                    key={row.id}
                                    className={classes.lastTableRow}
                                >
                                    <TableCell style={{ fontFamily: "Poppins-SemiBold" }} align="left">{row.attributes.title}</TableCell>
                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">{row.attributes.category.name}</TableCell>
                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                                        {row.attributes.instructor.name ?
                                            row.attributes.instructor.name
                                            // @ts-ignore
                                            : row.attributes.instructor_detail.data.attributes.full_name
                                        }
                                    </TableCell>
                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left"><Button>{dasboardFullDateFormatter(row.attributes.created_at)}</Button></TableCell>
                                    {/* @ts-ignore */}
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => this.goToEditPage(row.id)}
                                            style={{
                                                height: '45px',
                                                width: '45px',
                                                borderRadius: '5px',
                                                marginLeft: '25px'
                                            }}
                                        >
                                            <img src={EditIcon} height={'20px'} />
                                        </IconButton>
                                        <ThreeDot 
                                            blogData={row} 
                                            type={'video'}
                                            copyLink={baseUrl+'/WatchVideo/'+row.id}
                                            // @ts-ignore
                                            isPublish={true}
                                            handleStatusUpdate={this.handleStatusUpdate}
                                            handlePreviewOpen={this.handlePreviewOpen}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            :
            null
        )
    }

    getThirdTab = (loading: any, sortingLoader: any, errorMsg: any, apiData: any, blogPerPage: any, classes: any) => {
        const baseUrl = window.location.href.split('/', 3).pop();
        if(loading || errorMsg || sortingLoader){
            return null
        }

        return (
            apiData.data.length ?
            <TableContainer component={Paper}>
                <Table style={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">{"Title"}</TableCell>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Categories</TableCell>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Author</TableCell>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">{"Publish"}</TableCell>
                            {/* <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Total Lectures</TableCell> */}
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="right">{"Actions"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apiData.data
                            .slice(0, blogPerPage)
                            .map((row: any) => (
                                <TableRow
                                    key={row.id}
                                    className={classes.lastTableRow}
                                >
                                    <TableCell style={{ fontFamily: "Poppins-SemiBold" }} align="left">{row.attributes.title}</TableCell>
                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">{row.attributes.category.name}</TableCell>
                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                                        {row.attributes.instructor.name ?
                                            row.attributes.instructor.name
                                            // @ts-ignore
                                            : row.attributes.instructor_detail.data.attributes.full_name
                                        }
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        <Button 
                                            variant="outlined" 
                                            style={{ 
                                                color: "#F27024", 
                                                border: "1px solid #F27024", 
                                                textTransform: "initial", 
                                                fontFamily: "Poppins-SemiBold" 
                                            }}
                                            onClick={() => this.handleStatusUpdate(row.id, 'published')}
                                        >
                                            Publish
                                        </Button>
                                    </TableCell>
                                    {/* @ts-ignore */}
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => this.goToEditPage(row.id)}
                                            style={{
                                                height: '45px',
                                                width: '45px',
                                                borderRadius: '5px',
                                                marginLeft: '25px'
                                            }}
                                        >
                                            <img src={EditIcon} height={'20px'} />
                                        </IconButton>
                                        <ThreeDot
                                            blogData={row} 
                                            type={'video'}
                                            copyLink={baseUrl+'/WatchVideo/'+row.id}
                                            isPublish={false}
                                            // @ts-ignore
                                            handleStatOpen={this.handleStatOpen}
                                            handleStatusUpdate={this.handleStatusUpdate}
                                            handlePreviewOpen={this.handlePreviewOpen}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            :
            null
        )
    }

    getFourthTab = (loading: any, sortingLoader: any, errorMsg: any, apiData: any, blogPerPage: any, classes: any) => {
        const baseUrl = window.location.href.split('/', 3).pop();
        if(loading || errorMsg || sortingLoader){
            return null
        }

        return (
            apiData.data.length ?
            <TableContainer component={Paper}>
                <Table style={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">{"Title"}</TableCell>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Categories</TableCell>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Author</TableCell>
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Date Deleted</TableCell>
                            {/* <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="left">Total Lectures</TableCell> */}
                            <TableCell style={{ color: "#8991a4", fontFamily: "Poppins-Light" }} align="right">{"Actions"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apiData.data
                            .slice(0, blogPerPage)
                            .map((row: any) => (
                                <TableRow
                                    key={row.id}
                                    className={classes.lastTableRow}
                                >
                                    <TableCell style={{ fontFamily: "Poppins-SemiBold" }} align="left">{row.attributes.title}</TableCell>
                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">{row.attributes.category.name}</TableCell>
                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left">
                                        {row.attributes.instructor.name ?
                                            row.attributes.instructor.name
                                            // @ts-ignore
                                            : row.attributes.instructor_detail.data.attributes.full_name
                                        }
                                    </TableCell>
                                    <TableCell style={{ fontFamily: "Poppins-Light" }} align="left"><Button>{dasboardFullDateFormatter(row.attributes.created_at)}</Button></TableCell>
                                    {/* @ts-ignore */}
                                    <TableCell align="right">
                                        {/* @ts-ignore */}
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <DeleteOutlineIcon
                                                onClick={() => this.handleModalOpen(row.id)}
                                                style={{
                                                    color: '#F27024',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                            <ThreeDot 
                                                blogData={row} 
                                                type={'video'}
                                                copyLink={baseUrl+'/WatchVideo/'+row.id}
                                                // @ts-ignore
                                                isRestore={true}
                                                handleStatusUpdate={this.handleStatusUpdate}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            :
            null
        )
    }

    getConfirmModal = (classes: any) => {
        return (
            // @ts-ignore 
            <Box className={classes.modalStyle}>
                <Typography style={{ fontFamily: "Poppins-SemiBold", fontSize: "1.5rem" }}>Are you sure you want to delete this?</Typography>
                {/* @ts-ignore */}
                <Box style={{ width: "80%", display: "flex", justifyContent: "space-evenly", alignItems: "center", marginTop: "1.5rem" }}>
                    <Button onClick={() => this.handleModalClose()} variant="outlined" style={{ width: "140px", color: "#F27024", border: "1px solid #F27024", textTransform: "initial", fontFamily: "Poppins-SemiBold", borderRadius: "5px" }}>Cancel</Button>
                    {/* @ts-ignore */}
                    <Button onClick={() => this.handleDeleteClose()} variant="outlined" style={{ width: "140px", color: "#fff", borderRadius: "5px", textTransform: "initial", fontFamily: "Poppins-SemiBold", backgroundColor: "#F27024" }}>Delete Forever</Button>
                </Box>
            </Box>
        )
    }

    getStatModal = (classes: any, data: any) => {
        return (
            // @ts-ignore 
            <Box className={classes.statModalStyle}>
                {/* @ts-ignore */}
                <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ marginLeft: '30px', fontFamily: "Poppins-Light" }}>Monthly Readers Report</Typography>
                    <IconButton onClick={() => this.handleModalClose()}><CloseIcon /></IconButton>
                </Box>
                {/* @ts-ignore */}
                <ResponsiveContainer width="100%" height="100%">
                    {/* @ts-ignore */}
                    <BarChart width={100} height={300} data={data}>
                        {/* @ts-ignore */}
                        <CartesianGrid vertical={false} />
                        <XAxis dataKey="name" tickLine={false} style={{ fontFamily: "Poppins-Light" }} orientation="bottom" />
                        <YAxis tickLine={false} style={{ fontFamily: "Poppins-Light" }} orientation="left" axisLine={false} />
                        {/* @ts-ignore */}
                        <Tooltip cursor={{ fill: 'transparent' }} isAnimationActive={false} />
                        {/* @ts-ignore */}
                        <Bar dataKey="uv" isAnimationActive={false} barSize={20} fill="#F27024" />
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        )
    }

    getAppBar = (classes: any) => {
        const {searchList} = this.state;
        return (
            <AppBar position='fixed' className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.logo}>
                        <img src={require('../../../../components/src/logo.svg')} height={70} />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'space-between',
                            padding: '10px'
                        }}
                    >
                        <div>
                            <Typography
                                style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    color: '#353535',
                                    textTransform: 'capitalize'
                                }}
                                className={classes.name}
                            >
                                Hi {window.localStorage.getItem('userName')},
                            </Typography>
                            <Typography
                                style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    fontSize: '40px',
                                    color: '#353535'
                                }}
                                className={classes.welcome}
                            >
                                Welcome back
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative'
                                }}
                            >
                            <CustomInput
                                passwordfield={false}
                                placeholder={"Search"}
                                icon={require("../../assets/search.png")}
                                iconHeight={"24px"}
                                height={48}
                                borderRadius={6}
                                value={this.state.searchQuery}
                                onChange={(e: any) =>
                                    this.handleInputChange(e)
                                }
                            />
                            {
                                this.state.openSearch ?
                                    <div
                                    style={{
                                        position: 'absolute',
                                        zIndex: 999999,
                                        color: '#000',
                                        maxHeight: '200px',
                                        overflowY: 'scroll',
                                        boxShadow: '-1px -1px 19px -3px rgba(0,0,0,0.6)',
                                        borderRadius: '8px',
                                        minWidth: '250px',
                                        background: '#fff',
                                        padding: '10px',
                                        top: '80px'
                                    }}
                                    >
                                    {
                                        !this.state.searchLoading ?
                                            searchList.blogs.length ||
                                            searchList.videos.length ||
                                            searchList.podcasts.length ||
                                            searchList.courses.length ||
                                            searchList.lets_discover.books ||
                                            searchList.lets_discover.films ||
                                            searchList.lets_discover.organisations ||
                                            searchList.lets_discover.people?
                                            <AdminSearch 
                                                searchList={searchList}
                                                navigation={this.props.navigation}
                                            />
                                            : 'No search found!'
                                        : <div
                                            style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                            }}
                                        >
                                            <CircularProgress
                                            style={{
                                                color: palette.primary.main
                                            }}
                                            />
                                        </div>
                                    }
                                    <div
                                        style={{
                                        borderTop: '1px solid #d3d3d3',
                                        marginTop: '10px'
                                        }}
                                    >
                                        <Typography
                                        style={{
                                            color: palette.primary.main,
                                            fontSize: '12px',
                                            fontFamily: 'Poppins-SemiBold',
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                            marginTop: '10px'
                                        }}
                                        onClick={() => this.setState({openSearch: false})}
                                        >
                                        Cancel
                                        </Typography>
                                    </div>
                                    </div>
                                : null
                                }
                            </div>
                            <IconButton
                                style={{
                                    border: '1px solid #bdbdbd',
                                    height: '45px',
                                    width: '45px',
                                    borderRadius: '5px',
                                    marginTop: '25px',
                                    marginLeft: '20px'
                                }}
                            >
                                <img src={require('../../assets/notification.png')} height={'25px'} />
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { 
            tabs, 
            previewId, 
            previewModal, 
            viewStats, 
            deleteStatus, 
            mobileOpen, 
            statOpen, 
            apiData, 
            loading, 
            errorMsg, 
            blogPerPage, 
            modalOpen, 
            currentPage, 
            sortingLoader, 
            type, 
            actionLoading
        } = this.state;
        const data = [
            { name: 'JAN', uv: viewStats.January, pv: 2400, amt: 2400 },
            { name: 'FEB', uv: viewStats.February, pv: 1398, amt: 2210 },
            { name: 'MAR', uv: viewStats.March, pv: 9800, amt: 2290 },
            { name: 'APR', uv: viewStats.April, pv: 3908, amt: 2000 },
            { name: 'MAY', uv: viewStats.May, pv: 4800, amt: 2181 },
            { name: 'JUN', uv: viewStats.June, pv: 3800, amt: 2500 },
            { name: 'JULY', uv: viewStats.July, pv: 4300, amt: 2100 },
            { name: 'AUG', uv: viewStats.August, pv: 4300, amt: 2100 },
            { name: 'SEP', uv: viewStats.September, pv: 4300, amt: 2100 },
            { name: 'OCT', uv: viewStats.October, pv: 4300, amt: 2100 },
            { name: 'NOV', uv: viewStats.November, pv: 4300, amt: 2100 },
            { name: 'DEC', uv: viewStats.December, pv: 4300, amt: 2100 },
        ];
        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                {
                    actionLoading ?
                        <CustomLoader
                            open={actionLoading}
                        />
                    :   null
                }
                {/* @ts-ignore */}
                {deleteStatus ? (
                    <AlertModal
                        open={deleteStatus}
                        data={deleteStatus ? "Deleted successfully" : errorMsg}
                        // @ts-ignore
                        onClose={() => this.closeAlertBox()}
                    />
                ) : null}
                {/* @ts-ignore */}
                <Modal
                    open={modalOpen}
                    onClose={() => this.handleModalClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {this.getConfirmModal(classes)}
                </Modal>
                {/* @ts-ignore */}
                <Modal
                    open={statOpen}
                    onClose={() => this.handleModalClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {
                        this.getStatModal(classes, data)
                    }
                </Modal>
                {/* @ts-ignore */}
                <Modal
                    open={previewModal}
                    onClose={() => this.handleModalClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {/* @ts-ignore */}
                    <Box className={classes.previewModalStyle}>
                        <PreviewBE previewId={previewId} />
                    </Box>
                </Modal>
                <DashboardDrawer
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={this.handleDrawerToggle}
                    // @ts-ignore
                    navigation={this.props.navigation}
                />
                {/* @ts-ignore */}
                <Box
                    className={classes.appBarWrap}
                >
                    {
                        this.getAppBar(classes)
                    }
                    {/* @ts-ignore */}
                    <Box style={{ padding: "0 5%", marginBottom: "10%" }}>
                        <Grid container>
                            <Grid lg={12} className={classes.tabsBox}>
                                <Tabs
                                    className={classes.tabs_main}
                                    value={tabs}
                                    onChange={this.handleTabsChange}>
                                    <Tab className={classes.headingTabs} label="Published" />
                                    <Tab className={classes.headingTabs} label="Scheduled" />
                                    <Tab className={classes.headingTabs} label="Drafts" />
                                    <Tab className={classes.headingTabs} label="Deleted" />
                                </Tabs>
                            </Grid>

                            {
                                // @ts-ignore
                                apiData.data.length ? <Box style={{ marginTop: "1rem", width: "100%" }}>
                                        <Grid>
                                            <SortingHeader handleSortingLoader={this.handleSortingLoader} currentPage={currentPage} component={'Videos'} type={type} dataSize={apiData.data.length} />
                                        </Grid>
                                    </Box>
                                :   null
                            }
                            {
                                loading || sortingLoader ? 
                                    <Typography 
                                        style={{ 
                                            fontFamily: "Poppins-SemiBold", 
                                            width: "100%", 
                                            textAlign: "center", 
                                            margin: "2rem 0" 
                                        }}
                                    >  
                                        Loading....
                                    </Typography> 
                                :   null 
                            }
                            {
                                errorMsg ? 
                                    <Typography 
                                        style={{ 
                                            fontFamily: "Poppins-SemiBold", 
                                            width: "100%", 
                                            textAlign: "center", 
                                            margin: "2rem 0" 
                                        }}
                                    >
                                        {errorMsg}
                                    </Typography>
                                :   null
                            }

                            {
                                apiData.data.length ? 
                                    console.log("Api data..........", apiData, "Tabssssssssss", tabs) 
                                :   <Typography 
                                        style={{ 
                                            fontFamily: "Poppins-SemiBold", 
                                            width: "100%", 
                                            textAlign: "center", 
                                            margin: "2rem 0" 
                                        }}
                                    >
                                        No data found
                                    </Typography>
                            }

                        </Grid>
                        {
                            tabs === 0 ?
                                this.getFirstTab(loading, sortingLoader, errorMsg, apiData, blogPerPage, classes)
                            : null
                        }
                        {
                            tabs === 1 ?
                                this.getSecondTab(loading, sortingLoader, errorMsg, apiData, blogPerPage, classes)
                            : null
                        }
                        {
                            tabs === 2 ?
                                this.getThirdTab(loading, sortingLoader, errorMsg, apiData, blogPerPage, classes)
                            : null
                        }
                        {
                            tabs === 3 ?
                                this.getFourthTab(loading, sortingLoader, errorMsg, apiData, blogPerPage, classes)
                            : null
                        }
                        { apiData.meta.pagination.total_pages as number <= 1 ? "" :
                            // @ts-ignore
                            <Box
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: "30px",
                                    marginTop: "30px",
                                }}
                            >
                                <Pagination
                                    variant="outlined"
                                    size="large"
                                    count={apiData.meta.pagination.total_pages}
                                    // defaultPage={1}
                                    page={apiData.meta.pagination.current_page}
                                    onChange={this.changePage}
                                    className={classes.root}
                                />
                            </Box>
                        }

                    </Box>

                </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// export default withStyles(styles)(LetsRead);
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            logo: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            menuButton: {
                marginRight: theme.spacing(2),
                color: palette.primary.main,
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            appBar: {
                background: '#fff',
                boxShadow: 'none',
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                    background: '#fff',
                    boxShadow: 'none'
                },
            },
            appBarWrap: {
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                },
            },
            welcome: {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            name: {
                fontSize: '24px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '20px',
                    fontSize: '16px'
                },
            },
            tabsBox: {
                // flex: 1,
                // flexDirection: 'row',
                // justifyContent: 'space-between',
                width: '100%',
                borderBottom: '2px solid',
                borderBottomColor: 'rgba(0, 0, 0, 0.12)'
            },
            tabs_main: {
                "& .MuiTabs-indicator": {
                    backgroundColor: palette.primary.main,
                    borderRadius: '1.5px',
                    height: '4px',
                },
                "& .Mui-selected": {
                    color: palette.primary.main
                }
            },
            headingTabs: {
                fontSize: '24px',
                fontWeight: 600,
                fontFamily: palette.fontFamily.main,
                width: "25%",
                textTransform: 'inherit',
                // minWidth: '50% !important'
            },
            lastTableRow: {
                '&:last-child td, &:last-child th': { border: 0 }
            },
            triangle: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.06))',
                marginTop: "49px",
                marginLeft: "12px",
                borderRadius: "10px",
                '& .MuiAvatar-root': {
                    width: '32px ',
                    height: '32px',
                    marginLeft: '-0.5px ',
                    marginRight: '1px',
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: '0px',
                    right: '20px ',
                    width: '15px',
                    height: '15px ',
                    backgroundColor: '#ffffff',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            },
            root: {
                color: "#F27024 !important",
                "& .Mui-selected": {
                    background: "#F27024",
                    color: "white",
                    fontWeight: "bold",
                },
                ul: {
                    "& .MuiPaginationItem-root": {
                        color: "#F27024"
                    }
                }
            },
            modalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '496px',
                height: "200px",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 5px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            statModalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '800px',
                height: "360px",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 5px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            previewModalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '796px',
                maxHeight: "80vh",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '20px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            menuItemStyle: {
                fontFamily: 'Poppins-Light',
                '&:hover': {
                    textAlign: "right",
                    color: '#d53636',
                    backgroundColor: '#ffffff',
                }
            },


        }))(LetsWatch)
)
// Customizable Area End
