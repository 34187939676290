import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "../../../Download/src/assets";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  mobileOpen: boolean;
  loading: boolean;
  eventDetails: any;
  openAlert: boolean;
  alertMessage: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DownloadController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getEventDetailsId: string = '';
  syncCalendarEventId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      mobileOpen: false,
      loading: false,
      eventDetails: {},
      openAlert: false,
      alertMessage: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId != null) {
            if(apiRequestCallId === this.getEventDetailsId){
                if (responseJson && !responseJson.error) {
                    this.setState({
                        eventDetails: responseJson.data.live_stream,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            }

            if(apiRequestCallId === this.syncCalendarEventId){
              if(responseJson && !responseJson.error){
                this.setState({
                  openAlert: true,
                  loading: false,
                  alertMessage: 'Event synced to calendar successfully!'
                }, () => {
                  this.getEventDetails()
                })
              } else {
                this.setState({
                  openAlert: true,
                  loading: false,
                  alertMessage: 'Something went wrong, please try again!'
                })
              }
            }
        }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start

  navigateTo = (eveDetails: any) => {
    this.props.navigation.navigate('LiveStreaming', {id:eveDetails.id})
  }

  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getEventDetails()
}

errorInit = () => {
  this.setState({
    openAlert: true,
    alertMessage: 'Something went wrong!'
  })
}

googleInitiate = (event: any, id: any) => {
      this.setState({
        loading: true
      })
      const userToken = localStorage.getItem("token");
      const header = {
          "Content-Type": configJSON.contentType,
          token: userToken,
      };

      const data = {
        event_id: event.id
      }

      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );

      this.syncCalendarEventId = requestMessage.messageId;
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.syncCalendarApi+id
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(data)
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'PUT'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
}

  getEventDetails = () => {
    const id = window.location.href.split('/', 6).pop();
    this.setState({
        loading: true
    })
    const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getEventDetailsId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getEventDetailsApi+'/'+id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
  
}
