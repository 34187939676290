import React from "react";

// Customizable Area Start
import { 
  Container, 
  Box,
  Typography,
  Grid,
  Button
} from '@material-ui/core';
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import BreadCrumb from "../../../components/src/BreadCrumb";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { palette } from "../../../components/src/theme";
import CustomInput from "../../../components/src/CustomInput";
import { Pagination } from "@material-ui/lab";
import AlertModal from "../../../components/src/AlertModal";
import CustomLoader from "../../../components/src/CustomLoader";
import FavouriteIcon from "../../../components/src/FavouriteIcon";

import CustomHoverBtn from "../../../components/src/CustomHoverBtn";
// Customizable Area End

import CommunityForumController, {
  Props
} from "./CommunityForumController";

class CommunityForum extends CommunityForumController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getBreadCrumb = () => {
    return (
      <BreadCrumb 
        base={'Home'}
        url1={{
          base: '',
          link: ''
        }}
        url2={{
          path: `Let's Discover`,
          link: ''
        }}
      />
    )
  }

  getHeaderPart = (classes: any) => {
    return (
      <div className={classes.header}>
        <div>
          <Typography
            className={classes.mainHead}
          >
            Let's Discover
          </Typography>
        </div>
        <div>
          <Typography
            className={classes.subHead}
          >
            Vivamus congue sem non tortor porta, sit amet efficitur orci tincidunt. Quisque tristique, libero at volutpat sollicitudin, nisl tellus pulvinar lacus, eu posuere nisi nibh hendrerit urna. Fusce id justo ut dui luctus congue eget ac elit.
          </Typography>
        </div>
      </div>
    )
  }

  getAllTabs = (classes: any) => {
    return (
      <Grid container>
        <Grid item lg={12} className={classes.tabsBox}>
          <Tabs
            className={classes.tabs_main}
            value={this.state.tabValue}
            onChange={this.handleTabsChange}
          >
            <Tab className={classes.headingTabs} label="People" onClick={() => this.getPeopleData()} />
            <Tab className={classes.headingTabs} label="Organization" onClick={() => this.getOrgData()}/>
            <Tab className={classes.headingTabs} label="Films" onClick={() => this.getFilmData()}/>
            <Tab className={classes.headingTabs} label="Books" onClick={() => this.getBookData()} />
          </Tabs>
        </Grid>
      </Grid>
    )
  }

  getPeoplePagination = (apiData: any, apiMetaData: any, loading: boolean, classes: any) => {
    if(loading || !apiData.length || apiMetaData.total_pages < 2){
      return null
    }
    
    return (
      <Pagination 
        variant="outlined" 
        color="primary" 
        page={apiMetaData.current_page}
        count={apiMetaData.total_pages}
        className={classes.pagination}
        onChange={this.changePeoplePage}
      />
    )
  }

  getOrgPagination = (apiData: any, apiMetaData: any, loading: boolean, classes: any) => {
    if(loading || !apiData.length || apiMetaData.total_pages < 2){
      return null
    }
    
    return (
      <Pagination 
        variant="outlined" 
        color="primary" 
        page={apiMetaData.current_page}
        count={apiMetaData.total_pages}
        className={classes.pagination}
        onChange={this.changeOrgPage}
      />
    )
  }

  getBookPagination = (apiData: any, apiMetaData: any, loading: boolean, classes: any) => {
    if(loading || !apiData.length || apiMetaData.total_pages < 2){
      return null
    }
    
    return (
      <Pagination 
        variant="outlined" 
        color="primary" 
        page={apiMetaData.current_page}
        count={apiMetaData.total_pages}
        className={classes.pagination}
        onChange={this.changeBookPage}
      />
    )
  }

  getFilmPagination = (apiData: any, apiMetaData: any, loading: boolean, classes: any) => {
    if(loading || !apiData.length || apiMetaData.total_pages < 2){
      return null
    }
    
    return (
      <Pagination 
        variant="outlined" 
        color="primary" 
        page={apiMetaData.current_page}
        count={apiMetaData.total_pages}
        className={classes.pagination}
        onChange={this.changeFilmPage}
      />
    )
  }

  getBtnName = (item: any) => {
    if(this.state.windowWidth > 960 && this.state.windowWidth < 1024){
      if(item.attributes.name && item.attributes.name.length > 12){
        return item.attributes.name.substring(0,11) + '...' 
      } else {
        return item.attributes.name
      }
    } else {
      return item?.attributes?.name
    }
  }

  getCatBtn = (item: any, index: any, type: any) => {
    return (
      <Grid item md={2} sm={4} xs={6} key={index}>
        {/* <Button
          variant="outlined"
          style={{
            color: this.state.catId == item.attributes.id ? "#ffffff" : palette.primary.main,
            borderColor: palette.primary.main,
            minWidth: '10vw',
            width: '100%',
            fontFamily: 'Poppins-Light',
            textTransform: 'capitalize',
            background: this.state.catId == item.attributes.id ? palette.primary.main : ""
          }}
          title={item.attributes.name}
          onClick={() => {
            if(type === 'peoples'){
              this.getFilteredPeopleList(item.attributes.id)
            } else if(type === 'books'){
              this.getFilteredBookList(item.attributes.id)
            } else if(type === 'organizations'){
              this.getFilteredOrgList(item.attributes.id)
            } else {
              this.getFilteredFilmList(item.attributes.id)
            }
          }}
        >
          {
            this.getBtnName(item)
          }
        </Button> */}
        <CustomHoverBtn
          selected={this.state.catId === item?.attributes?.id}
          handleClick={() => this.clickIndividualType(type, item?.attributes?.id)}
          color={palette.primary.main}
          br="none"
          fullWidth
          title={
            this.getBtnName(item)
          }
        />
      </Grid>
    )
  }

  getCategoryButtons = (type: any) => {
    return (
      <>
        {/* @ts-ignore */}
        <Box mt={1}>
          <Grid container spacing={2}>
            <Grid item md={2} sm={4} xs={6}>
              {/* <Button
                variant="outlined"
                style={{
                  color: !this.state.catId ? "#ffffff" : palette.primary.main,
                  borderColor: palette.primary.main,
                  minWidth: '10vw',
                  width: '100%',
                  fontFamily: 'Poppins-Light',
                  textTransform: 'capitalize',
                  background: !this.state.catId ? palette.primary.main : ""
                }}
                onClick={() => {
                  if(type === 'peoples'){
                    this.getAllPeople()
                  } else if(type === 'books'){
                    this.getAllBook()
                  } else if(type === 'organizations'){
                    this.getAllOrg()
                  } else {
                    this.getAllFilm()
                  }
                }}
              >
                All <span style={{textTransform: 'capitalize', marginLeft: '2px'}}>{type}</span>
              </Button> */}
              <CustomHoverBtn
                selected={!this.state.catId}
                handleClick={() => this.clickAllType(type)}
                color={palette.primary.main}
                br="none"
                fullWidth
                title={`All ${type}`}
              />
            </Grid>
            {
              // istanbul ignore next
              this.state.categoryList.length ? this.state.categoryList.map((item: any, index: any) => {
                return (
                  this.getCatBtn(item, index, type)
                )
              })
              : <Typography>Loading...</Typography>
            }
          </Grid>
        </Box>
      </>
    )
  }

  getPeopleCard = (classes: any) => {
    const {peopleList, loadingPeople} = this.state;
    return (
      <>
        {
          loadingPeople ? 
            <span
              className={classes.loading}
            >
              Loading...
            </span>
          : 
          <div>
            <Grid
              container
              spacing={2}
            >
              {
                // istanbul ignore next
                peopleList.length ? peopleList.map((item: any, index: any) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={3}
                    >
                      <div>
                        <div
                          className={classes.imgCard}
                        >
                          <FavouriteIcon 
                            isFavourite={item.attributes.favourite}
                            position={'absolute'}
                            right={'10px'}
                            top={'10px'}
                            makeFavourite={(status: any) => this.makeFavourite(item.id, status, 'people')}
                            navigation={this.props.navigation}
                          />
                          <img 
                            src={item.attributes.image} 
                            className={classes.imgPeople}
                            onClick={() => this.goToPersonDetails(item.attributes.id)}
                          />
                        </div>
                        <div>
                          <Typography 
                            className={classes.peopleName}
                            onClick={() => this.goToPersonDetails(item.attributes.id)}
                          >
                            {item.attributes.name}
                          </Typography>
                        </div>
                        <div>
                          <Typography 
                            className={classes.peopleAbout}
                          >
                            {item.attributes.about}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            className={classes.readMore}
                            onClick={() => this.goToPersonDetails(item.attributes.id)}
                          >
                            Read More...
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  )
                })
                : <span className={classes.loading}>
                  No data found!
                </span>
              }
            </Grid>
          </div>
        }
      </>
    )

  }

  getFirstTab = (classes: any) => {
    return (
      <>
        <div
          style={{
            height: '50px'
          }}
        >
          <CustomInput 
            passwordfield={false}
            placeholder={"Search"}
            icon={require('../assets/search.png')}
            iconHeight={"24px"}
            height={'48px'}
            onChange={(e: any) =>
              this.handleSearchChange(e, 'people')
            }
          />
        </div>
        <div
          style={{
            marginTop: '20px'
          }}
        >
          {
            this.getCategoryButtons('peoples')
          }
        </div>
        <div style={{marginTop: '20px'}}>
          {
            this.getPeopleCard(classes)
          }
        </div>
        <div
          style={{
            marginTop: '40px',
            marginBottom: '30px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {
            this.getPeoplePagination(this.state.peopleList, this.state.peopleMetaData, this.state.loadingPeople, classes)
          }
        </div>
      </>
    )
  }

  getBookCard = (classes: any) => {
    const { bookList, loadingPeople } = this.state
    return (
      <>
        {
        loadingPeople ? 
          <span
            className={classes.loading}
          >
            Loading...
          </span>
        :  
        <Grid
          container
          spacing={4}
        >
          {
            // istanbul ignore next
            bookList.length ? bookList.map((item: any, index: any) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid
                    container
                    spacing={4}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={2}
                    >
                        <div
                          className={classes.imgBookCard}
                        >
                          <FavouriteIcon 
                            isFavourite={item.attributes.favourite}
                            position={'absolute'}
                            right={'10px'}
                            top={'10px'}
                            makeFavourite={(status: any) => this.makeFavourite(item.id, status, 'books')}
                            navigation={this.props.navigation}
                          />
                          <img 
                            src={item.attributes.image} 
                            className={classes.imgBook}
                          />
                        </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={9}
                      md={10}
                    >
                      <div>
                        <Typography
                          className={classes.bookTitle}
                        >
                          {item.attributes.title}
                        </Typography>
                      </div>
                      <div>
                        <Typography 
                          className={classes.bookAbout}
                        >
                          {item.attributes.about}
                        </Typography>
                      </div>
                      <div>
                        <Button
                          className={classes.linkButton}
                        >
                          <a
                            href={item.attributes.url}
                            target='_blank'
                            style={{
                              textDecoration: 'none',
                              color: palette.primary.main
                            }}
                          >
                            Link To Buy
                          </a>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })
            : <span
                className={classes.loading}
              >
                No data found!
              </span>
          }
        </Grid>
      }
      </>
    )
  }

  getFourthTab = (classes: any) => {
    return (
      <>
        <div
          style={{
            height: '50px'
          }}
        >
          <CustomInput 
            passwordfield={false}
            placeholder={"Search"}
            icon={require('../assets/search.png')}
            iconHeight={"24px"}
            height={'48px'}
            onChange={(e: any) =>
              this.handleSearchChange(e, 'book')
            }
          />
        </div>
        <div
          style={{
            marginTop: '20px'
          }}
        >
          {
            this.getCategoryButtons('books')
          }
        </div>
        <div style={{marginTop: '20px'}}>
          {
            this.getBookCard(classes)
          }
        </div>
        <div
          style={{
            marginTop: '40px',
            marginBottom: '30px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {
            this.getBookPagination(this.state.bookList, this.state.bookMetaData, this.state.loadingPeople, classes)
          }
        </div>
      </>
    )
  }

  getOrgCard = (classes: any) => {
    const { orgList, loadingPeople } = this.state
    return (
      <>
        {
        loadingPeople ? 
          <span
            className={classes.loading}
          >
            Loading...
          </span>
        :  
        <Grid
          container
          spacing={4}
        >
          {
            // istanbul ignore next
            orgList.length ? orgList.map((item: any, index: any) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid
                    container
                    spacing={4}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={2}
                    >
                        <div
                          className={classes.imgBookCard}
                        >
                          <FavouriteIcon 
                            isFavourite={item.attributes.favourite}
                            position={'absolute'}
                            right={'10px'}
                            top={'10px'}
                            makeFavourite={(status: any) => this.makeFavourite(item.id, status, 'org')}
                            navigation={this.props.navigation}
                          />
                          <img 
                            src={item.attributes.image} 
                            className={classes.imgBook}
                          />
                        </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={9}
                      md={10}
                    >
                      <div>
                        <Typography
                          className={classes.bookTitle}
                        >
                          {item.attributes.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography 
                          className={classes.bookAbout}
                        >
                          {item.attributes.about}
                        </Typography>
                      </div>
                      <div>
                          <Button
                            className={classes.linkButton}
                          >
                            <a 
                              href={item.attributes.website_url} 
                              target='_blank'
                              style={{
                                textDecoration: 'none',
                                color: palette.primary.main
                              }}
                            >
                              Go to website
                            </a>
                          </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })
            : <span
                className={classes.loading}
              >
                No data found!
              </span>
          }
        </Grid>
      }
      </>
    )
  }

  getSecondTab = (classes: any) => {
    return (
      <>
        <div
          style={{
            height: '50px'
          }}
        >
          <CustomInput 
            passwordfield={false}
            placeholder={"Search"}
            icon={require('../assets/search.png')}
            iconHeight={"24px"}
            height={'48px'}
            onChange={(e: any) =>
              this.handleSearchChange(e, 'organization')
            }
          />
        </div>
        <div
          style={{
            marginTop: '20px'
          }}
        >
          {
            this.getCategoryButtons('organizations')
          }
        </div>
        <div style={{marginTop: '20px'}}>
          {
            this.getOrgCard(classes)
          }
        </div>
        <div
          style={{
            marginTop: '40px',
            marginBottom: '30px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {
            this.getOrgPagination(this.state.orgList, this.state.orgMetaData, this.state.loadingPeople, classes)
          }
        </div>
      </>
    )
  }

  getFilmCard = (classes: any) => {
    const { filmList, loadingPeople } = this.state
    return (
      <>
        {
        loadingPeople ? 
          <span
            className={classes.loading}
          >
            Loading...
          </span>
        :  
        <Grid
          container
          spacing={4}
        >
          {
            filmList.length ? filmList.map((item: any, index: any) => {
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid
                    container
                    spacing={4}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={2}
                    >
                        <div
                          className={classes.imgBookCard}
                        >
                          <FavouriteIcon 
                            isFavourite={item.attributes.favourite}
                            position={'absolute'}
                            right={'10px'}
                            top={'10px'}
                            makeFavourite={(status: any) => this.makeFavourite(item.id, status, 'films')}
                            navigation={this.props.navigation}
                          />
                          <img 
                            src={item.attributes.image} 
                            className={classes.imgBook}
                          />
                        </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={9}
                      md={10}
                    >
                      <div>
                        <Typography
                          className={classes.bookTitle}
                        >
                          {item.attributes.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography 
                          className={classes.bookAbout}
                        >
                          {item.attributes.about}
                        </Typography>
                      </div>
                      <div>
                        <Button
                          className={classes.linkButton}
                        >
                          <a
                            href={item.attributes.url}
                            target='_blank'
                            style={{
                              textDecoration: 'none',
                              color: palette.primary.main
                            }}
                          >
                            Watch Here
                          </a>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })
            : <span
                className={classes.loading}
              >
                No data found!
              </span>
          }
        </Grid>
      }
      </>
    )
  }

  getThirdTab = (classes: any) => {
    return (
      <>
        <div
          style={{
            height: '50px'
          }}
        >
          <CustomInput 
            passwordfield={false}
            placeholder={"Search"}
            icon={require('../assets/search.png')}
            iconHeight={"24px"}
            height={'48px'}
            onChange={(e: any) =>
              this.handleSearchChange(e, 'film')
            }
          />
        </div>
        <div
          style={{
            marginTop: '20px'
          }}
        >
          {
            this.getCategoryButtons('films')
          }
        </div>
        <div style={{marginTop: '20px'}}>
          {
            this.getFilmCard(classes)
          }
        </div>
        <div
          style={{
            marginTop: '40px',
            marginBottom: '30px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {
            this.getFilmPagination(this.state.filmList, this.state.filmMetaData, this.state.loadingPeople, classes)
          }
        </div>
      </>
    )
  }

  closeAlertBox = () => {
    this.setState({
        openAlert: false
    })
  }
  // Customizable Area End

  render() {
    const {classes} = this.props;
    const {tabValue} = this.state
    return (
      // Customizable Area Start
      // @ts-ignore
      <Box
        className={classes.parentContainer}
      >
        {
          this.state.openAlert ?
            <AlertModal 
                open={this.state.openAlert}
                data={this.state.updateMessage}
                onClose={() => this.closeAlertBox()}
            />
          :   null
        }
        {
          this.state.loading ?
            <CustomLoader 
                open={this.state.loading}
            />
          : null
        }
        <Container>
          {
            this.getBreadCrumb()
          }
          {
            this.getHeaderPart(classes)
          }
          <div className={classes.tabWrapper}>
            {
              this.getAllTabs(classes)
            }
            <div
              className={classes.tabPanel}
            >
              {
                tabValue === 0 ?
                  this.getFirstTab(classes)
                : null
              }
              {
                tabValue === 1 ?
                  this.getSecondTab(classes)
                : null
              }
              {
                tabValue === 2 ?
                  this.getThirdTab(classes)
                : null
              }
              {
                tabValue === 3 ?
                  this.getFourthTab(classes)
                : null
              }
            </div>
          </div>
        </Container>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTheme(
  withStyles((theme: Theme) =>
      createStyles({
        parentContainer: {
          padding: '20px',
          [theme.breakpoints.down("sm")]: {
            padding: '0px',
          }
        },
        header: {
          padding: '20px',
          [theme.breakpoints.down("sm")]: {
            padding: '0px',
          }
        },
        mainHead: {
          fontFamily: 'Poppins-Bold',
          fontSize: '40px',
          color: '#01040d'
        },
        subHead: {
          fontFamily: 'Poppins-Light',
          fontSize: '24px',
          color: '#545454',
          lineHeight: 1.5,
          letterSpacing: '0.3px',
          marginTop: '20px'
        },
        tabWrapper: {
          padding: '15px',
          [theme.breakpoints.down("sm")]: {
            padding: '0px',
          }
        },
        tabsBox: {
          width: "100%",
          borderBottom: "2px solid",
          borderBottomColor: "rgba(0, 0, 0, 0.12)"
        },
        tabs_main: {
          color: '#acacac',
          "& .MuiTabs-indicator": {
            backgroundColor: palette.primary.main,
            borderRadius: "1.5px",
            height: "4px"
          },
          "& .Mui-selected": {
            color: palette.primary.main
          }
        },
        headingTabs: {
          fontSize: "24px",
          fontWeight: 600,
          fontFamily: palette.fontFamily.main,
          width: "25%",
          textTransform: "inherit",
          [theme.breakpoints.down("sm")]: {
            fontSize: '14px'
          }
          // minWidth: '50% !important'
        },
        loading: {
          display: 'flex',
          justifyContent: 'center',
          fontSize: '24px',
          fontFamily: 'Poppins-SemiBold'
        },
        imgCard: {
          boxShadow: '0 2px 10px 0 rgba(130, 131, 144, 0.26)',
          width: '224px',
          height: '283px',
          borderRadius: '16px',
          overflow: 'hidden',
          cursor: 'pointer'
        },
        imgPeople: {
          minWidth: '224px',
          maxWidth: '224px',
          maxHeight: '283px',
          minHeight: '283px',
          cursor: 'pointer'
        },
        peopleName: {
          fontSize: '20px',
          fontFamily: 'Poppins-Bold',
          marginTop: '10px'
        },
        peopleAbout: {
          fontSize: '16px',
          fontFamily: 'Poppins-Light',
          marginTop: '10px',
          minHeight: '100px',
          maxHeight: '100px',
          color: '#6b7180',
          display: '-webkit-box',
          maxWidth: '224px',
          lineClamp: 4,
          boxOrient: 'vertical',
          overflow: 'hidden'
        },
        readMore: {
          fontSize: '16px',
          fontFamily: 'Poppins-Light',
          marginTop: '10px',
          color: palette.primary.main,
          cursor: 'pointer'
        },
        imgBookCard: {
          boxShadow: '0 2px 10px 0 rgba(130, 131, 144, 0.26)',
          height: '283px',
          borderRadius: '16px',
          overflow: 'hidden'
        },
        imgBook: {
          width: '100%',
          maxHeight: '283px',
          minHeight: '283px'
        },
        bookTitle: {
          fontSize: '24px',
          fontFamily: 'Poppins-Bold',
          color: '#353535'
        },
        bookAuthor: {
          color: palette.primary.main,
          fontSize: '16px',
          fontFamily: 'Poppins-Light'
        },
        bookAbout: {
          fontSize: '16px',
          fontFamily: 'Poppins-Light',
          marginTop: '10px',
          minHeight: '130px',
          maxHeight: '130px',
          color: '#6b7180',
          display: '-webkit-box',
          width: '100%',
          lineClamp: 4,
          boxOrient: 'vertical',
          overflow: 'hidden',
          [theme.breakpoints.down("sm")]: {
            fontSize: '14px',
            lineClamp: 3,
            boxOrient: 'vertical',
            overflow: 'hidden',
            display: '-webkit-box',
          }
        },
        linkButton: {
          border: `1px solid ${palette.primary.main}`,
          width: '228px',
          height: '40px',
          color: palette.primary.main,
          fontFamily: 'Poppins-Light'
        },
        pagination: {
          color: "#F27024 !important",
          "& .MuiPaginationItem-root": {
            color: "#F27024",
            border: `1px solid ${palette.primary.main}`
          },
          "& .Mui-selected": {
              background: palette.primary.main,
              color: "#fff",
              fontFamily: 'Poppins-SemiBold',
              border: `1px solid ${palette.primary.main}`,
          }
        },
      }))(CommunityForum)
)
// Customizable Area End
