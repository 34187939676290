import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { CategoryType } from "../../../ContentManagement/src/Interfaces.web";
// Customizable Area End
export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabs: number;
  mobileOpen: boolean;
  apiData: CategoryType[];
  categoryName: string;
  categoryType: string;
  loading: boolean;
  errorMsg: string;
  anchorEl: null | HTMLElement;
  blogPerPage: number;
  modalOpen: boolean;
  currentPage: number;
  sorting: string;
  sortingLoader: boolean;
  inputError: any;
  selectedCategory: string;
  dataActionMsg: string;
  searchQuery: string;
  searchList: any;
  openSearch: boolean;
  searchLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FilmCategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCategoryDataCallId: string = "";
  createCategoryCallId: string = "";
  updateCategoryCallId: string = "";
  savedCategoryId: string = "";
  apiSearchItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // getName(MessageEnum.NavigationToSingleArticle),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tabs: 0,
      mobileOpen: false,
      apiData: [],
      categoryName: "",
      categoryType: "film_category",
      selectedCategory: "",
      dataActionMsg: "",
      loading: false,
      errorMsg: "",
      anchorEl: null,
      blogPerPage: 10,
      modalOpen: false,
      inputError: {},
      currentPage: 1,
      sorting: "newest_first",
      sortingLoader: false,
      searchQuery: '',
      searchList: [],
      openSearch: false,
      searchLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  setCategoryData = (responseJson: any, errorResponse: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      if (responseJson.data.length === 0) {
        this.setState({
          errorMsg: "Data Not Found",
          loading: false,
          sortingLoader: false,
          apiData: []
        });
      } else {
        this.setState({
          ...this.state,
          apiData: responseJson.data,
          errorMsg: "",
          loading: false,
          sortingLoader: false
        });
      }
    } else {
      if (errorResponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong",
          loading: false,
          sortingLoader: false
        });
      } else {
        this.setState({
          errorMsg: errorResponse,
          loading: false,
          sortingLoader: false
        });
      }
    }
  };

  setUpdateCategory = (responseJson: any, errorResponse: any) => {
    if (responseJson && !responseJson.error) {
      this.setState(
        {
          errorMsg: "",
          dataActionMsg: "Category updated successfully!",
          modalOpen: false,
          categoryName: ""
        },
        () => this.getCategoryData()
      );
    } else {
      if (errorResponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong"
        });
      } else {
        this.setState({
          errorMsg: errorResponse
        });
      }
    }
  };

  createCategoryData = (responseJson: any, errorResponse: any) => {
    if (responseJson && !responseJson.error) {
      this.setState(
        {
          errorMsg: "",
          dataActionMsg: "Category created successfully!",
          categoryName: ""
        },
        () => this.getCategoryData()
      );
    } else {
      if (errorResponse === undefined) {
        this.setState({
          errorMsg: "Something went wrong"
        });
      } else {
        this.setState({
          errorMsg: errorResponse
        });
      }
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetCategoryDataCallId) {
          this.setCategoryData(responseJson, errorResponse);
        }
        if (apiRequestCallId === this.updateCategoryCallId) {
          this.setUpdateCategory(responseJson, errorResponse);
        }
        if (apiRequestCallId === this.createCategoryCallId) {
          this.createCategoryData(responseJson, errorResponse);
        }
        if(apiRequestCallId === this.apiSearchItemCallId){
          if (responseJson && !responseJson.errors) {
            this.setState({
              searchList: responseJson,
              searchLoading: false,
            })
          }
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getCategoryData();
  }

  // Customizable Area End

  // Customizable Area Start

  getSearchItems = () => {
    this.setState({
      searchLoading: true
    })
    const header = {
      // "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSearchItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.adminSearchApi+`?key=${this.state.searchQuery}&data=films`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  handleSearchInputChange = (e: any) => {
    this.setState({
      searchQuery: e.target.value
    }, () => {
      this.setState({
        openSearch: true,
        searchLoading: true
      }, ()=> {
        setTimeout(() => {
          this.getSearchItems();
        }, 5000)
      })
    })
  }

  fieldValidation = () => {
    const { categoryName } = this.state;
    let error: any = {};
    if (!categoryName) {
      error.categoryName = "Please Provide Category Name";
    }

    if (
      error &&
      Object.keys(error).length === 0 &&
      Object.getPrototypeOf(error) === Object.prototype
    ) {
      this.setState({ inputError: {} });
      this.createCategory();
    } else {
      this.setState({ inputError: error });
    }
  };

  createCategory = () => {
    const { categoryName, categoryType } = this.state;

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("token")
    };

    let category: any = {};

    category.name = categoryName;
    category.category_type = categoryType;

    const body = {
      category: category
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createCategoryCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateCategory = (id: string) => {
    const { categoryName } = this.state;

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: window.localStorage.getItem("token")
    };

    let category: any = {};

    category.name = categoryName;
    const body = {
      category: category
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCategoryCallId = requestMessage.messageId;
    this.savedCategoryId = id;
    const hitURL = `${configJSON.categoryUrl}/${id}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCategoryData = () => {
    this.setState({ loading: true });

    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoryDataCallId = requestMessage.messageId;
    const hitURL = `${configJSON.categoryUrl}?type=film_category`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleInputChange = (event: any) => {
    const { value } = event.target;
    this.setState({
      categoryName: value
    });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleModalOpen = (id: string, name: any) => {
    this.setState({
      modalOpen: true,
      selectedCategory: id,
      categoryName: name
    });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };
  closeAlertBox = () => {
    this.setState({});
  };

  // Customizable Area End
}
