import React from "react";

// Customizable Area Start
import { 
    Typography, 
    IconButton,
    AppBar,
    Toolbar,
    Box,
    Container,
    Grid,
    Button
} from '@material-ui/core';
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import CustomInput from "../../../../components/src/CustomInput";
import MenuIcon from '@material-ui/icons/Menu';
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
import { palette } from "../../../../components/src/theme";
import CustomLoader from "../../../../components/src/CustomLoader";
const drawerWidth = 240;
const id = window.location.href.split('/', 6).pop();
// @ts-ignore
import SyncEvent from "../../../../components/src/SyncEvent";
import AlertModal from "../../../../components/src/AlertModal";
const authToken  = localStorage.getItem('authToken');


// Customizable Area End

import EventDetailsController, {
  Props,
  configJSON
} from "./EventDetailsController";


class EventDetails extends EventDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
    console.log(configJSON)
  }


  getAppBar = (classes: any) => {
    return (
        <AppBar position='fixed' className={classes.appBar}>
            <Toolbar>
                <IconButton
                    aria-label="open drawer"
                    edge="start"
                    onClick={this.handleDrawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <div className={classes.logo}>
                    <img src={require('../../../../components/src/logo.svg')} height={70} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'space-between',
                        padding: '10px'
                    }}
                >
                    <div>
                        <Typography
                            style={{
                                fontFamily: 'Poppins-SemiBold',
                                color: '#353535',
                                textTransform: 'capitalize'
                            }}
                            className={classes.name}
                        >
                            Hi {window.localStorage.getItem('userName')},
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: 'Poppins-SemiBold',
                                fontSize: '40px',
                                color: '#353535'
                            }}
                            className={classes.welcome}
                        >
                            Welcome back
                        </Typography>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div
                            style={{
                                position: 'relative'
                            }}
                        >
                        <CustomInput
                            passwordfield={false}
                            placeholder={"Search"}
                            icon={require("../../assets/search.png")}
                            iconHeight={"24px"}
                            height={48}
                            borderRadius={6}
                        />
                        </div>
                        <IconButton
                            style={{
                                border: '1px solid #bdbdbd',
                                height: '45px',
                                width: '45px',
                                borderRadius: '5px',
                                marginTop: '25px',
                                marginLeft: '20px'
                            }}
                        >
                            <img src={require('../../assets/notification.png')} height={'25px'} />
                        </IconButton>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    )
}

getEventDate = (eventDetails: any) => {
    let date = new Date(eventDetails.attributes.date)
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();
    return `${day} ${month} ${year}`
}

getEventImage = (classes: any, eventDetails: any) => {
    return (
        <div
            className={classes.eventImgCard}
        >
            <img 
                alt={eventDetails?.attributes?.title}
                src={eventDetails?.attributes?.cover_image}
                className={classes.eventImg}
            />
        </div>
    )
}

getEventInfo = (classes: any, eventDetails: any) => {
    return (
        <div
            className={classes.eventMianInfo}
        >
            <div>
                <Typography
                    className={classes.eventTitle}
                >
                    {eventDetails.attributes.title}
                </Typography>
            </div>
            <div>
                <span
                    className={classes.presenterDetails}
                >
                    In this conversation
                </span>
                <span
                    className={classes.presenterName}
                >
                    {eventDetails.attributes.instructor.name}
                </span>
            </div>
            <div>
                <span
                    className={classes.presenterDetails}
                >
                    Date : {this.getEventDate(eventDetails)}, {eventDetails.attributes.start_time} - {eventDetails.attributes.end_time} 
                </span>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginTop: '20px'
                }}
            >
                <Button
                    className={classes.darkBtn}
                    onClick={() => this.navigateTo(eventDetails)}
                >
                    Stream Now
                </Button>
                <Button
                    className={classes.borderedBtn}
                >
                    Reschedule
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginTop: '20px'
                }}
            >
                {
                    authToken && !eventDetails.attributes.already_synced ? 
                        <SyncEvent 
                            disabled={false}
                            syncInit={(e: any, id: any) => this.googleInitiate(e, id)}
                            eventDetails={eventDetails}
                            classes={classes.borderedBtnSync}
                            role={localStorage.getItem('userType')}
                            error={() => this.errorInit()}
                        />
                    : null
                }
            </div>
        </div>
    )
}

getEventAbout = (classes: any, eventDetails: any) => {
    return (
        <div
            style={{
                marginTop: '40px'
            }}
        >
            <div>
                <Typography
                    className={classes.aboutHead}
                >
                    About the Event
                </Typography>
            </div>
            <div
                style={{
                    marginTop: '20px'
                }}
            >
                <Typography
                    className={classes.aboutContent}
                >
                    {eventDetails.attributes.description}
                </Typography>
            </div>
        </div>
    )
}

getEventDetailsData = (classes: any, eventDetails: any) => {
    return (
        <Container>
            <div
                style={{
                    padding: '20px'
                }}
            >
                <Grid
                    container
                    spacing={5}
                >
                    <Grid 
                        item
                        xs={12}
                        sm={12}
                        md={4}
                    >
                        {this.getEventImage(classes, eventDetails)}
                    </Grid>
                    <Grid 
                        item
                        xs={12}
                        sm={12}
                        md={8}
                    >
                        {this.getEventInfo(classes, eventDetails)}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        {this.getEventAbout(classes, eventDetails)}
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={10}
                >
                    <Grid
                        item
                    >
                        <div>
                            <Typography
                                className={classes.aboutHead}
                            >
                                Registered Users ({eventDetails?.attributes?.registered_users?.data?.length})
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Container>
                    <Grid
                        container
                        spacing={6}
                        style={{
                            marginBottom: '30px'
                        }}
                    >
                        {
                            eventDetails?.attributes?.registered_users?.data?.length ?
                            eventDetails?.attributes?.registered_users?.data.map((item: any) => {
                                return (
                                    <Grid
                                        xs={12}
                                        sm={6}
                                        md={4}
                                    >
                                        <div
                                            style={{
                                                marginTop: '20px',
                                                display: 'flex',
                                                alignContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div
                                                className={classes.avatarWrap}
                                            >
                                                <img 
                                                    className={classes.avatar}
                                                    src={item.attributes.profile_photo ? item.attributes.profile_photo : require('../../../Gallery/assets/avatar.png')}
                                                />
                                            </div>
                                            <div>
                                                <Typography
                                                    className={classes.userName}
                                                >
                                                    {item.attributes.full_name}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            }) 
                            :   <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%'
                                    }}
                                >
                                    <span 
                                        style={{
                                            fontSize: '18px',
                                            fontFamily: 'Poppins-SemiBold',
                                            textAlign: 'center',
                                            marginTop: '20px',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        No users registered yet!
                                    </span>
                                </div>
                        }
                    </Grid>
                </Container>
            </div>
        </Container>
    )
}
closeAlertBox = () => {
    this.setState({
        openAlert: false
    })
} 

  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { mobileOpen, loading, eventDetails } = this.state;
    return (
      // Customizable Area Start
        <div>
            
            {
                loading ? 
                    <CustomLoader
                        open={loading}
                    />
                :   null
            }
            {
                this.state.openAlert ? 
                    <AlertModal 
                        open={this.state.openAlert}
                        data={this.state.alertMessage}
                        onClose={() => this.closeAlertBox()}
                    />
                : null
            }
            <DashboardDrawer
                mobileOpen={mobileOpen}
                handleDrawerToggle={this.handleDrawerToggle}
                // @ts-ignore
                navigation={this.props.navigation}
            />
            {/* @ts-ignore */}
            <Box
                className={classes.appBarWrap}
            >
                {
                    this.getAppBar(classes)
                }
                {
                    Object.keys(eventDetails).length ?
                        this.getEventDetailsData(classes, eventDetails)
                    : null
                }
            </Box>
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            logo: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            menuButton: {
                marginRight: theme.spacing(2),
                color: palette.primary.main,
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            appBar: {
                background: '#fff',
                boxShadow: 'none',
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                    background: '#fff',
                    boxShadow: 'none'
                },
            },
            appBarWrap: {
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                },
            },
            eventImgCard: {
                width: '352px',
                height: '344px',
                maxWidth: '352px',
                maxHeight: '344px',
                overFlow: 'hidden',
                borderRadius: '8px',
                boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)'
            },
            eventImg: {
                width: '352px',
                height: '344px',
                maxWidth: '352px',
                maxHeight: '344px',
                overFlow: 'hidden',
                borderRadius: '8px',
                boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)'
            },
            eventMianInfo: {
                maxHeight: '344px',
                height: '344px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            eventTitle: {
                fontSize: '40px',
                fontFamily: 'Poppins-SemiBold',
                color: '#353535'
            },
            presenterDetails: {
                fontSize: '20px',
                fontFamily: 'Poppins-Light',
                color: '#353535'
            },
            presenterName: {
                fontSize: '20px',
                fontFamily: 'Poppins-SemiBold',
                color: palette.primary.main,
                marginLeft: '10px',
                textTransform: 'capitalize'
            },
            darkBtn: {
                background: palette.primary.main,
                textTransform: 'capitalize',
                color: '#fff',
                fontSize: '20px',
                fontFamily: 'Poppins-Light',
                paddingLeft: '30px',
                paddingRight: '30px'
            },
            borderedBtn: {
                marginLeft: '30px',
                textTransform: 'capitalize',
                border: `1px solid ${palette.primary.main}`,
                fontSize: '16px',
                fontFamily: 'Poppins-Light',
                paddingLeft: '35px',
                paddingRight: '35px'
            },
            borderedBtnSync: {
                textTransform: 'capitalize',
                border: `1px solid ${palette.primary.main}`,
                fontSize: '16px',
                fontFamily: 'Poppins-Light',
                paddingLeft: '10px',
                paddingRight: '10px',
                width: '188px',
            },
            aboutHead: {
                fontSize: '24px',
                fontFamily: 'Poppins-SemiBold',
                color: '#353535'
            },
            aboutContent: {
                fontSize: '18px',
                fontFamily: 'Poppins-Light',
                color: '#545454'
            },
            // avatarWrap: {
            //     maxWidth: '48px',
            //     maxHeight: '48px',
            //     minWidth: '48px',
            //     minHeight: '48px',
            //     border: `10px solid ${palette.primary.main}`,
            //     borderRadius: '50%'
            // },
            avatar: {
                maxWidth: '48px',
                maxHeight: '48px',
                minWidth: '48px',
                minHeight: '48px',
                border: `1px solid ${palette.primary.main}`,
                borderRadius: '50%'
            },
            userName: {
                fontSize: '18px',
                fontFamily: 'Poppins-Light',
                color: '#545454',
                marginLeft: '10px'
            }
        })
    )(EventDetails)
)
// Customizable Area End
