import React from "react";

// Customizable Area Start
import { 
    Typography, 
    Box,
    Container,
    Grid,
    Button
} from '@material-ui/core';
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { palette } from "../../../components/src/theme";
import CustomLoader from "../../../components/src/CustomLoader";
import Dialog from '@material-ui/core/Dialog';
import AlertModal from "../../../components/src/AlertModal";
import Breadcrumb from "../../../components/src/BreadCrumb";
// @ts-ignore
import SyncEvent from "../../../components/src/SyncEvent";

const authToken  = localStorage.getItem('authToken')

// Customizable Area End

import UserEventDetailsController, {
  Props,
  configJSON
} from "./UserEventDetailsController";


class UserEventDetails extends UserEventDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
    console.log(configJSON)
  }

getEventDate = (eventDetails: any) => {
    let date = new Date(eventDetails.attributes.date)
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();
    return `${day} ${month} ${year}`
}

getEventImage = (classes: any, eventDetails: any) => {
    return (
        <div
            className={classes.eventImgCard}
        >
            <img 
                alt={eventDetails?.attributes?.title}
                src={eventDetails?.attributes?.cover_image}
                className={classes.eventImg}
            />
        </div>
    )
}

getEventInfo = (classes: any, eventDetails: any) => {
    const token = localStorage.getItem('token');
    return (
        <div
            className={classes.eventMianInfo}
        >
            <div>
                <Typography
                    className={classes.eventTitle}
                >
                    {eventDetails.attributes.title}
                </Typography>
            </div>
            <div>
                <span
                    className={classes.presenterDetails}
                >
                    In this conversation
                </span>
                <span
                    className={classes.presenterName}
                >
                    {eventDetails.attributes.instructor.name}
                </span>
            </div>
            <div>
                <span
                    className={classes.presenterDetails}
                >
                    Date : {this.getEventDate(eventDetails)}, {eventDetails.attributes.start_time} - {eventDetails.attributes.end_time} 
                </span>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginTop: '20px'
                }}
            >
                {
                    token ?
                        <>
                            <Button
                                className={eventDetails?.attributes?.already_registered ? classes.disabledBtn : classes.darkBtn}
                                onClick={() => this.openConfirmModal()}
                                disabled={eventDetails?.attributes?.already_registered}
                                style={{
                                    color: '#fff'
                                }}
                            >
                                {eventDetails?.attributes?.already_registered ? 'Registered' : 'Register'}
                            </Button>
                            {
                                authToken ? 
                                    eventDetails?.attributes?.already_registered ?
                                        <SyncEvent 
                                            disabled={eventDetails.attributes.already_synced}
                                            syncInit={(e: any, id: any) => this.googleInitiate(e, id)}
                                            eventDetails={eventDetails}
                                            classes={classes.borderedBtn}
                                            role={localStorage.getItem('userType')}
                                            error={() => this.errorInit()}
                                        />
                                    :   <SyncEvent 
                                            disabled={true}
                                            syncInit={(e: any, id: any) => this.googleInitiate(e, id)}
                                            eventDetails={eventDetails}
                                            classes={classes.borderedBtn}
                                            role={localStorage.getItem('userType')}
                                            error={() => this.errorInit()}
                                        />
                                : null
                            }
                        </>
                    : null
                }
            </div>
        </div>
    )
}

getEventAbout = (classes: any, eventDetails: any) => {
    return (
        <div
            style={{
                marginTop: '40px'
            }}
        >
            <div>
                <Typography
                    className={classes.aboutHead}
                >
                    About the Event
                </Typography>
            </div>
            <div
                style={{
                    marginTop: '20px'
                }}
            >
                <Typography
                    className={classes.aboutContent}
                >
                    {eventDetails.attributes.description}
                </Typography>
            </div>
        </div>
    )
}

getPresenterAbout = (classes: any, eventDetails: any) => {
    return(
        <Grid
            item
            xs={12}
            md={12}
            sm={12}
        >
            <div
                style={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div>
                    <img 
                        className={classes.avatar}
                        src={require('../../Gallery/assets/avatar.png')}
                    />
                </div>
                <div>
                    <Typography
                        className={classes.userName}
                    >
                        {eventDetails?.attributes?.instructor_detail?.data?.attributes?.full_name}
                    </Typography>
                </div>
                <div
                    style={{
                        marginTop: '20px'
                    }}
                >
                    <Typography
                        className={classes.creatorAbout}
                    >
                        {eventDetails?.attributes?.instructor_detail?.data?.attributes?.about_author}
                    </Typography>
                </div>
            </div>
        </Grid>
    )
}

getEventDetailsData = (classes: any, eventDetails: any) => {
    return (
        <Container>
            <div
                style={{
                    padding: '20px'
                }}
            >
                <Grid
                    container
                    spacing={5}
                >
                    <Grid 
                        item
                        xs={12}
                        sm={12}
                        md={4}
                    >
                        {this.getEventImage(classes, eventDetails)}
                    </Grid>
                    <Grid 
                        item
                        xs={12}
                        sm={12}
                        md={8}
                    >
                        {this.getEventInfo(classes, eventDetails)}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        {this.getEventAbout(classes, eventDetails)}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                    >
                        {this.getPresenterAbout(classes, eventDetails)}
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
}

getConfirmModal = (classes: any, eventDetails: any) => {
    return (
        <>
            <Dialog
                open={this.state.openConfirm}
                onClose={this.closeAlertBox}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div
                    style={{
                        padding: '56px',
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center',
                            fontSize: '24px',
                            color: '#353535',
                            fontFamily: 'poppins-SemiBold'
                        }}
                    >
                        You are now registering for the event.
                    </div>
                    
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px'
                        }}
                    >
                        <Button 
                            onClick={this.closeAlertBox} 
                            className={classes.cancelBtn}
                        >
                            Cancel
                        </Button>
                        <Button 
                            onClick={() => this.registerEvent(eventDetails.id)} 
                            autoFocus
                            className={classes.registerBtn}
                        >
                            Register
                        </Button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

openConfirmModal = () => {
    this.setState({
        openConfirm: !this.state.openConfirm
    })
}

closeAlertBox = () => {
    this.setState({
      openConfirm: false,
      openAlert: false
    })
}

  // Customizable Area End

  render() {
    const { classes } = this.props;
    const { loading, eventDetails } = this.state;
    return (
      // Customizable Area Start
        <div>
            {
                loading ? 
                    <CustomLoader
                        open={loading}
                    />
                :   null
            }
            {
                this.state.openAlert ? 
                    <AlertModal 
                        open={this.state.openAlert} 
                        data={this.state.successMessage}
                        onClose={() => this.closeAlertBox()}
                    />
                : null
            }
            {
                this.state.openConfirm ? 
                    this.getConfirmModal(classes, eventDetails)
                : null
            }
            {/* @ts-ignore */}
            <Box
                className={classes.appBarWrap}
            >
                <Container>
                    <Breadcrumb
                        base={'Home'}
                        url1={{
                        base: `Let's Talk`,
                        link: 'userEvent'
                        }}
                        url2={{
                        path: eventDetails?.attributes?.title && eventDetails?.attributes?.title > 30 ? eventDetails?.attributes?.title.substring(0, 30) + "..." :  eventDetails?.attributes?.title,
                        link: ''
                        }}
                    />
                </Container>
                
                {
                    Object.keys(eventDetails).length ?
                        this.getEventDetailsData(classes, eventDetails)
                    : null
                }
                {
                    this.state.error ? 
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '40px'
                            }}
                        >
                            <span 
                                style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    fontSize: '20px',
                                    color: '#353535'
                                }}
                            >
                                Details not found!
                            </span>
                        </div>
                    : null
                }
            </Box>
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            logo: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            menuButton: {
                marginRight: theme.spacing(2),
                color: palette.primary.main,
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            eventImgCard: {
                width: '352px',
                height: '344px',
                maxWidth: '352px',
                maxHeight: '344px',
                overFlow: 'hidden',
                borderRadius: '8px',
                boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)'
            },
            eventImg: {
                width: '352px',
                height: '344px',
                maxWidth: '352px',
                maxHeight: '344px',
                overFlow: 'hidden',
                borderRadius: '8px',
                boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)'
            },
            eventMianInfo: {
                maxHeight: '344px',
                height: '344px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            },
            eventTitle: {
                fontSize: '40px',
                fontFamily: 'Poppins-SemiBold',
                color: '#353535'
            },
            presenterDetails: {
                fontSize: '20px',
                fontFamily: 'Poppins-Light',
                color: '#353535'
            },
            presenterName: {
                fontSize: '20px',
                fontFamily: 'Poppins-SemiBold',
                color: palette.primary.main,
                marginLeft: '10px',
                textTransform: 'capitalize'
            },
            darkBtn: {
                background: palette.primary.main,
                textTransform: 'capitalize',
                color: '#fff',
                fontSize: '20px',
                fontFamily: 'Poppins-Light',
                paddingLeft: '60px',
                paddingRight: '60px',
                '&:hover': {
                    background: palette.primary.main,
                    textTransform: 'capitalize',
                    color: '#fff',
                    fontSize: '20px',
                    fontFamily: 'Poppins-Light',
                    paddingLeft: '60px',
                    paddingRight: '60px',
                }
            },
            disabledBtn: {
                background: '#8991a4',
                textTransform: 'capitalize',
                color: '#fff',
                fontSize: '20px',
                fontFamily: 'Poppins-Light',
                paddingLeft: '60px',
                paddingRight: '60px',
                '&:hover': {
                    background: '#8991a4',
                    textTransform: 'capitalize',
                    color: '#fff',
                    fontSize: '20px',
                    fontFamily: 'Poppins-Light',
                    paddingLeft: '60px',
                    paddingRight: '60px',
                }
            },
            borderedBtn: {
                marginLeft: '30px',
                textTransform: 'capitalize',
                border: `1px solid ${palette.primary.main}`,
                fontSize: '16px',
                fontFamily: 'Poppins-Light',
                paddingLeft: '35px',
                paddingRight: '35px'
            },
            registerBtn: {
                minWidth: '142px',
                background: palette.primary.main,
                textTransform: 'capitalize',
                marginLeft: '10px',
                color: '#fff',
                fontSize: '20px',
                fontFamily: 'Poppins-Light',
                paddingLeft: '60px',
                paddingRight: '60px',
                '&:hover': {
                    background: palette.primary.main,
                    textTransform: 'capitalize',
                    color: '#fff',
                    fontSize: '20px',
                    fontFamily: 'Poppins-Light',
                    paddingLeft: '60px',
                    paddingRight: '60px',
                }
            },
            cancelBtn: {
                minWidth: '142px',
                marginRight: '10px',
                textTransform: 'capitalize',
                border: `1px solid ${palette.primary.main}`,
                fontSize: '16px',
                fontFamily: 'Poppins-Light',
                paddingLeft: '35px',
                paddingRight: '35px'
            },
            aboutHead: {
                fontSize: '24px',
                fontFamily: 'Poppins-SemiBold',
                color: '#353535'
            },
            aboutContent: {
                fontSize: '18px',
                fontFamily: 'Poppins-Light',
                color: '#545454'
            },
            avatar: {
                maxWidth: '72px',
                maxHeight: '72px',
                minWidth: '72px',
                minHeight: '72px',
                border: `1px solid ${palette.primary.main}`,
                borderRadius: '50%'
            },
            userName: {
                fontSize: '24px',
                fontFamily: 'Poppins-SemiBold',
                color: '#545454',
                marginLeft: '10px'
            },
            creatorAbout: {
                fontFamily: 'Poppins-Light',
                fontSize: '18px',
                color: '#545454'
            }
        })
    )(UserEventDetails)
)
// Customizable Area End
