import React, { useState, useEffect } from 'react';
import { 
    Container, 
    Box, 
    Input,  
    Button,
    InputLabel,
    Typography, 
    InputAdornment, 
    IconButton,
    Grid
  } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
  import { palette } from "../../../components/src/theme";
  import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
  import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LessonsDiscussions from './LessonsDiscussions';
import { getStorageData } from '../../../framework/src/Utilities';
import PlayerControlls from '../../../components/src/PlayerControlls';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import screenfull from "screenfull";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import ReactPlayer from 'react-player';
import CustomCheckbox from '../../../components/src/CustomizedCheckbox';
//@ts-ignore
import bgImage from "../assets/lesson1.png";

import singleLessonController, {
  Props,
  configJSON
} from "./singleLessonController";
import AlertModal from '../../../components/src/AlertModal';

let count = 0;

const format = (seconds: any) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};
class singleLessons extends singleLessonController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // @ts-ignore
    this.window = window;
    // @ts-ignore
    this.playerRef = React.createRef();
    // @ts-ignore
    this.controlsRef = React.createRef();
    // @ts-ignore
    this.playerContainerRef = React.createRef();
    // @ts-ignore
    this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

    // @ts-ignore
    this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "0";
    
    // @ts-ignore
    this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
    // @ts-ignore
    this.totalDuration = format(this.duration);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const path: any = window.location.href.split('/', 5).pop()
    this.setState({
      lessonID:path,
    })
    this.getLessonsData(path)
    window.scrollTo(0, 0)
    
  }

  async componentWillUnmount(): Promise<void> {
    // @ts-ignore
     this.updateSeenUpto(this.currentTime, this.state.lessonID);
  }

  handleProgress = (changeState: any) => {
    
    // @ts-ignore
    this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

    // @ts-ignore
    this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";
    
    // @ts-ignore
    this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
    // @ts-ignore
    this.totalDuration = format(this.duration);
    if (count > 3) {
      // @ts-ignore
      this.controlsRef.current.style.visibility = "hidden";
      count = 0;
    }
    // @ts-ignore
    if (this.controlsRef?.current?.style?.visibility == "visible") {
      count += 1;
    }
    if (!this.state.seeking) {
      this.setState({ ...this.state, ...changeState });
    }
  };

  handleOnEnded = () => {
    this.setState({...this.state, videoPlaying: false, played: 0});
  }

  handlePip = () => {
    this.setState({...this.state, pip: !this.state.pip, hideBtn: false})
  }

  handleDisplayFormat = () => {
    this.setState({timeDisplayFormat : this.state.timeDisplayFormat == " normal" ? 'remaining' : 'normal'})
  };

  handleVolumeSeekDown = (e: any, newValue: any) => {
    // @ts-ignore
    this.setState({ ...this.state, seeking: false, volume: parseFloat(newValue / 100) });
  };
  handleVolumeChange = (e: any, newValue: any) => {
    this.setState({
      ...this.state,
      // @ts-ignore
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  closeShare = () => {
    this.setState({
      openShare: false
    })
  }

  shareOpen = () => {
    this.setState({
      openShare: true
    })
  }

  handleMute = () => {
    this.setState({ ...this.state, muted: !this.state.muted });
  };

  handlePlaybackRate = (rate: any) => {
    this.setState({ ...this.state, playbackRate: rate });
  };

  onChangeBitrate = (quality: any) => {
    // @ts-ignore
    const internalPlayer = this.playerRef.current?.getInternalPlayer();
    if (internalPlayer) {
        // currentLevel expect to receive an index of the levels array
        internalPlayer.currentLevel = quality;
        this.setState({ ...this.state, quality: quality });
    }
  }

  handlePlayPause = () => { 
    this.setState({ ...this.state, videoPlaying: !this.state.videoPlaying });
  };

  handleRewind = () => {
    // @ts-ignore
    this.playerRef?.current?.seekTo(this.playerRef.current.getCurrentTime() - 10);
  };

  handleForward = () => {
    // @ts-ignore
    this.playerRef?.current?.seekTo(this.playerRef.current.getCurrentTime() + 10);
  };



  seekToStamp = (time: any) => {
    this.setState({
      selectedTimeStamp: time
    })
    const hr: any = time.split(':');
    let totalSeconds = Number(hr[0]) * 60 + Number(hr[1])
    console.log("TOTAL SECONDS===>", totalSeconds)
    // @ts-ignore
    this.playerRef?.current?.seekTo(totalSeconds);
  }

  handleSeekChange = (e: any, newValue: any) => {
    // @ts-ignore
    this.setState({ ...this.state, played: parseFloat(newValue / 100) });
  };

  handleSeekMouseDown = (e: any) => {
    this.setState({ ...this.state, seeking: true });
  };

  handleSeekMouseUp = (e: any, newValue: any) => {
    this.setState({ ...this.state, seeking: false });
    // @ts-ignore
    this.playerRef?.current?.seekTo(newValue / 100, "fraction");
  };

  handleDuration = (duration: any) => {
    this.setState({ ...this.state, allDuration: duration });
  };

  toggleFullScreen = () => {
    this.setState({hideBtn: !this.state.hideBtn})
    // @ts-ignore
    screenfull.toggle(this.playerContainerRef.current);
  };

  onEscape = () => {
    this.setState({hideBtn: false})
  }

  closeAlertBox = () => {
    this.setState({
      openAlert: false
    })
  }

  getTranscript = (lessonData: any, classes: any) => {
    return (
      lessonData?.attributes?.transcript.length ? lessonData?.attributes?.transcript.map((item: any, i: any) => {
        return (
          <>
            <Grid item xs={12} sm={3} md={2} lg={2} className={classes.transscriptTimeGrid}>
              <Typography className={classes.transscriptTimes}>
                {item.time}
              </Typography>
            </Grid>
            <Grid  item xs={12} sm={9} md={10} lg={10}>
              <Typography className={classes.para2}>
                {item.value}
              </Typography>
            </Grid>
          </>
        )
      })

      : <Grid item lg={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '25%'
            }}
          >
            <Typography
              style={{
                color: '#000',
                fontFamily: 'Poppins-SemiBold',
                fontSize: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              No transcript attached with this lesson.
            </Typography>
          </div>
        </Grid>
    )
  }

  getTodos = (lessonData: any, classes: any) => {
    return (
      lessonData?.attributes?.todos.map((item: any, index: any)=>{
        return (
          <div
            style={{
              marginTop: '15px'
            }}
            key={index}
          >
            <span
              style={{
                fontSize: '18px',
                fontFamily: 'Poppins-SemiBold',
                color: '#1c1c1c'
              }}
            >
              {index+1}. {item?.attributes?.title}
            </span>
            <div>
              <span
                style={{
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light',
                }}
              >
                {item?.attributes?.description}
              </span>
            </div>
          </div>
        )
      })
    )
  }

  getCheckKnowledge = (lessonData: any) => {
    return (
      lessonData?.attributes?.questions.map((item: any, index: any) => {
        return (
          <div
            style={{
              marginTop: '15px'
            }}
            key={index}
          >
            <div>
              <span
                style={{
                  fontSize: '18px',
                  fontFamily: 'Poppins-SemiBold',
                  color: '#545454'
                }}
              >
                Question {index+1}- {item?.attributes?.question}
              </span>
            </div>
            <div>
              <span
                style={{
                  fontSize: '18px',
                  fontFamily: 'Poppins-Light',
                  color: '#545454',
                  marginTop: '5px'
                }}
              >
                Choose one option from following:-
              </span>
            </div>
            <div 
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '15px'
              }}
            >
              <CustomCheckbox /> 
              <span
                style={{
                  fontSize: '18px',
                  fontFamily: 'Poppins-Light',
                  color: '#545454'
                }}
              >
                {item?.attributes?.option1}
              </span>
            </div>
            <div 
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '15px'
              }}
            >
              <CustomCheckbox /> 
              <span
                style={{
                  fontSize: '18px',
                  fontFamily: 'Poppins-Light',
                  color: '#545454'
                }}
              >
                {item?.attributes?.option2}
              </span>
            </div>
            <div 
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '15px'
              }}
            >
              <CustomCheckbox /> 
              <span
                style={{
                  fontSize: '18px',
                  fontFamily: 'Poppins-Light',
                  color: '#545454'
                }}
              >
                {item?.attributes?.option3}
              </span>
            </div>
            <div 
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '15px'
              }}
            >
              <CustomCheckbox /> 
              <span
                style={{
                  fontSize: '18px',
                  fontFamily: 'Poppins-Light',
                  color: '#545454'
                }}
              >
                {item?.attributes?.option4}
              </span>
            </div>
          </div>
        )
      })
    )
  }
  getFaq = (lessonData: any) => {
    return (
      lessonData?.attributes?.faqs.map((item: any, index: any)=>{
        return (
          <div
            style={{
              marginTop: '15px'
            }}
            key={index}
          >
            <span
              style={{
                fontSize: '18px',
                fontFamily: 'Poppins-SemiBold',
                color: '#1c1c1c'
              }}
            >
             Question {index+1}- {item?.attributes?.question}
            </span>
            <div>
              <span
                style={{
                  fontSize: '18px',
                  fontFamily: 'Poppins-SemiBold',
                  color: '#1c1c1c'
                }}
              >
              Answer-
              </span>
              <span
                style={{
                  fontSize: '16px',
                  fontFamily: 'Poppins-Light',
                  marginLeft: '5px'
                }}
              >
                {item?.attributes?.answer}
              </span>
            </div>
          </div>
        )
      })
    )
  }

  getDiscussion = (lessonData: any, classes: any, lessonDiscussionText: any) => {
    return  (
      <Container>
        <Grid container className={classes.mainGrid}>
        <Grid lg={9}>
          <Box className={classes.discussionBox}>
            <Box className={classes.peopleDiscussions}>
              { lessonData?.attributes?.reviews?.length ? 
                lessonData.attributes.reviews.map((item:any) => 
                <Grid container>
                <Grid xs={3} md={1} lg={1}>
                  <img 
                    src={require("../assets/avatar.png")}
                    className={classes.userImages}
                  />
                </Grid>
                <Grid xs={9} md={11} lg={11} className={classes.userCommentsBox}>
                <Typography className={classes.userName}>{item?.attributes?.full_name}</Typography>
                <Typography className={classes.userText}>{item?.attributes?.text}</Typography>
                </Grid>
                <Grid xs={12}>
                <Typography className={classes.userText2}>{item?.attributes?.text}</Typography>
                </Grid>
              </Grid>)
              : <Typography className={classes.emptyText}>No Discussion Found</Typography>
                }
            </Box>
            <hr />
            <Box className={classes.userDiscussions}>
            <Grid container>
                <Grid xs={1} lg={1} >
                  <img 
                    src={require("../assets/avatar.png")}
                    className={classes.userImage2}
                  />
                </Grid>
                <Grid xs={9} lg={10} className={classes.userInputBox}>
                  <input
                value={lessonDiscussionText}
                // disabled={lessonDiscussionText.length > 2}
                type="text"
                placeholder="Say something...."
                onChange={(e) => 
                  {
                    if(lessonDiscussionText.length >= 250){
                      e.preventDefault()
                    } else {
                      this.setState({lessonDiscussionText: e.target.value})
                      }
                }}
                className={classes.userInput}
                />
                  <Grid className={classes.nameGrid}>
                  <Typography className={classes.userName2}>{localStorage.getItem('userName')}</Typography>
                  <Typography className={classes.inputLength}>{lessonDiscussionText.length}/250</Typography>

                  </Grid>
                </Grid>
                <Grid xs={1} lg={1} className={classes.sendBtngrid}>
                  <IconButton disabled={lessonDiscussionText.length ? false : true} onClick={() => this.submitDiscussion(lessonData.id)}>
                  <img 
                    src={require("../assets/send.png")}
                    className={classes.sendBTN}
                />
                  </IconButton>
              
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        </Grid>
      </Container>
    )
  }

  getTimestamp = (lessonData: any, classes: any) => {
    return (
      <div
      >
        {
          lessonData?.attributes?.timestamp.map((item: any, i: any) => {
            return (
              <div 
                className={
                  this.state.selectedTimeStamp === item.time ? classes.selectedStampCard : classes.stampCard
                } 
                key={i}
                onClick={() => this.seekToStamp(item.time)}
              >
                <span
                  className={classes.stampTitle}
                  title={item.value}
                >
                  {item.value}
                </span>
                <div
                  className={classes.stampTime}
                >
                  <span
                    style={{
                      fontFamily: 'Poppins-Light',
                      color: '#fff',
                      fontSize: '12px'
                    }}
                  >
                    {item.time}
                  </span>
                </div>
            </div>
            )
          })
        }
      </div>
    )
  }

  getPlayer = () => {
    const { 
      lessonData,
      videoPlaying,
      tabs,
      light,
      loop,
      playbackRate,
      volume,
      muted,
      pip,
      played,
      hideBtn,
      quality,
      lessonDiscussionText
    } = this.state;
    return (
      <>
        {/* @ts-ignore */}
        <ReactPlayer 
          url={
            lessonData?.attributes?.video ? lessonData?.attributes?.video : "https://minio.b64156.dev.eastus.az.svc.builder.cafe/sbucket/twimtyaj4fwz64voegthw9n8lbs8"
          }
          playing={videoPlaying}
          width={'100%'}
          height={'100%'}
          style={{
            minWidth: '100%',
            minHeight: '100%'
          }}
          // @ts-ignore
          ref={this.playerRef}
          onProgress={this.handleProgress}
          controls={false}
          onEnded={this.handleOnEnded}
          light={light}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          pip={pip}
        />
        <PlayerControlls 
        // @ts-ignore
          ref={this.controlsRef}
          playPause={this.handlePlayPause}
          state={this.state}
          rewind={this.handleRewind}
          forward={this.handleForward}
          onSeek={this.handleSeekChange}
          onSeekMouseDown={this.handleSeekMouseDown}
          onSeekMouseUp={this.handleSeekMouseUp}
          onDuration={this.handleDuration}
          played={played}
          onToggleFullScreen={this.toggleFullScreen}
          // @ts-ignore
          elapsedTime={this.elapsedTime}
          hideBtn={hideBtn}
          // @ts-ignore
          totalDuration={this.totalDuration}
          onMute={this.handleMute}
          muted={muted}
          volume={volume}
          pip={this.handlePip}
          onVolumeChange={this.handleVolumeChange}
          onVolumeSeekDown={this.handleVolumeSeekDown}
          onChangeDisplayFormat={this.handleDisplayFormat}
          onPlaybackRateChange={this.handlePlaybackRate}
          playbackRate={playbackRate}
          quality={quality}
          onChangeQuality={this.onChangeBitrate}
          onEscape={this.onEscape}
          pipIcon={true}
          forwardSeekIcon={true}
          backwardSeekIcon={true}
          volumeIcon={true}
          settingsIcon={true}
          ccIcon={true}
          timeDuration={true}
        />
      </>
    )
  }

  getPdfDownloadBtn = (lessonData: any, classes: any) => {
    return(
      <Box
        className={classes.detailWrap}
      >
        <Typography className={classes.headings}>{lessonData?.attributes?.material_details[0]?.attributes?.title}</Typography>
        <Typography className={classes.para3}> by {lessonData?.attributes?.material_details[0]?.attributes?.instructor_name}</Typography>
        <Typography className={classes.para4}> {lessonData?.attributes?.material_details[0]?.attributes?.description}</Typography>
        <Button
          disabled={lessonData?.attributes?.material_details[0]?.attributes?.material_file ? false : true}
          onClick={() => this.navigationToPdf(lessonData.attributes.material_details[0].attributes.material_file)} className={classes.downloadBtn}>
          Download pdf                              
        </Button>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { 
            lessonData,
            tabs,
            lessonDiscussionText
          } = this.state
    const { classes, navigation } = this.props;
    // Customizable Area End
  return (
    // Customizable Area Start
    <div className={classes.manage_background}>
      {
        this.state.openAlert ?
          <AlertModal
            open={this.state.openAlert} 
            data={this.state.errorData}
            onClose={() => this.closeAlertBox()}
          />
        : null
      }
    <div className={classes.blur_effect}>
    </div>
    <Grid className={classes.topNav}>
        <IconButton onClick={() => navigation.goBack()} className={classes.iconButton}>
          <KeyboardBackspaceRoundedIcon 
            className={
              classes.backArrowIcon
            }
          />
        </IconButton>
        <Typography className={classes.topHeading}>Get started with your lesson</Typography>
    </Grid>
    <Container style={{position: 'relative'}}>
        <Grid lg={12} className={classes.root} container>
          <Grid xs={12}>
            <Typography className={classes.mainHeading}>
              {lessonData?.attributes?.title}
            </Typography>
          </Grid>
            <Grid xs={12} md={1} sm={1}></Grid>
            <Grid xs={12} md={8} sm={8} className={classes.imageGrid}>
              <Grid container spacing={2}>
                <Grid 
                  item 
                  md={12} 
                  sm={12} 
                  xs={12}
                >
                  {/* @ts-ignore */}
                  <Box
                    component={'div'}
                    style={{
                      borderRadius: '10px',
                      overflow: 'hidden',
                      boxShadow: '0 0 7px 1px rgba(0, 0, 0, 0.22)',
                      marginTop: '-30px',
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                      maxHeight: '100%',
                      background: '#000'
                    }}
                    // @ts-ignore
                    ref={this.playerContainerRef}
                  >
                    {
                      this.getPlayer()
                    }
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid 
              item 
              md={lessonData?.attributes?.timestamp.length ? 3 : 12} 
              sm={lessonData?.attributes?.timestamp.length ? 3 : 12} 
              xs={12}
            >
              <div
                style={{
                  minHeight: '350px',
                  maxHeight: '350px',
                  overflowY: 'scroll',
                  width: '100%',
                  padding:'10px'
                }}
              >
                {this.getTimestamp(lessonData, classes)}
              </div>
            </Grid>

            <Grid xs={12} md={9} sm={9} className={classes.courseGridTop}>
            <Tabs
              value={tabs}
              onChange={this.handleTabsChange}
              className={classes.tabsBox}
            >
              <Tab className={classes.headingTabs} label="Overview" />
              <Tab className={classes.headingTabs} label="Discussions" />
            </Tabs>
            </Grid>   
            {/* OverView & Discussion */}
            {
              tabs === 0 ? 
              <Grid lg={9} className={classes.courseGrid}>
            <Typography className={classes.headings}>Summary</Typography>
            <Typography className={classes.para}>
              {lessonData?.attributes?.about}
              </Typography>
            <Typography className={classes.headings}>Transcript</Typography>
              
              <Box className={classes.transcriptBox}>
                <Grid container>
                  {
                    this.getTranscript(lessonData, classes)
                  }
                </Grid>
              </Box>

              <Typography className={classes.headings}>Lesson material</Typography>
            <Typography className={classes.para}>
              Follwing are the pdf files that will help you to complete your assignments and explain more about this lesson. Also the files used in this lesson.
            </Typography>

            <Box className={classes.lessonMaterialBox}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <img 
                    src={require("../assets/rectangle.png")}
                    className={
                      classes.imgLessonMaterial
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={9}>
                  {
                    this.getPdfDownloadBtn(lessonData, classes)
                  }
                </Grid>
              </Grid>
            </Box>
            <Box>
              <div>
                {lessonData?.attributes?.todos.length ? <Typography className={classes.headings}>To-Do</Typography> : null }
              </div>
              {
                this.getTodos(lessonData, classes)
              }
            </Box>
            <Box>
              <div>
                {lessonData?.attributes?.questions.length ? <Typography className={classes.headings}>Check your knowledge</Typography> : null }
              </div>
              {
                this.getCheckKnowledge(lessonData)
              }
            </Box>
            <Box>
              <div>
                {lessonData?.attributes?.faqs.length ? <Typography className={classes.headings}>FAQ's</Typography> : null }
              </div>
              {
                this.getFaq(lessonData)
              }
            </Box>
            </Grid>
            :
              this.getDiscussion(lessonData, classes, lessonDiscussionText)
            }
            

        </Grid>
        <hr 
        style={{
          objectFit: "contain",
          boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.12)",
          backgroundColor: '#ffff'
        }}
        />
        <Box className={classes.bottomBtnGrid}>
          {/* <Grid lg={3}> */}
            <Button     
            disabled={lessonData?.attributes?.student_lecture?.status === 'complete' ? true : false}
             onClick={() => this.markAsComplete(lessonData.id)} variant="contained" className={classes.bottomBtn}>
             {lessonData?.attributes?.student_lecture?.status === 'complete' ? "Completed" : "Mark as complete" }
            </Button>
          {/* </Grid> */}
        </Box>
    </Container>
    </div>
    // Customizable Area End
  )
}
}

        // Customizable Area Start
export default withTheme(
  withStyles((theme: Theme) =>
      createStyles({
        topHeading:{
          fontFamily: palette.fontFamily.main,
          color: '#353535',
          fontSize: '18px',
          lineHeight: 1.33,
          margin: 'auto 0'
        },
        topNav: {
          position: 'relative',
          backgroundColor: '#fffff !importent',
          height: '108px',
          boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
          display: 'flex', 
          flexDirection:'row', 
          justifyContent: 'center',
          '@media (max-width:480px)':{
            height: '80px'
          }
        },
        iconButton:{
          position: 'absolute', 
          marginTop: '20px', 
          left: '75px',
          '@media (max-width:480px)':{
            left: '10px',
            marginTop: '10px',
          }
        },
        imgLessonMaterial: {
          height: 'auto',
          minHeight: '220px',
          width: '100%',
          borderRadius: '10px'
        },
        manage_background:{
          backgroundColor: '#fffff',
          position: 'relative',
        },
        backArrowIcon: {
          color: '#000',
          fontSize: '45px',
          '@media (max-width:480px)':{
            fontSize: '30px',
            marginTop: '5px'
          }
        },
        blur_effect:{
          position: 'absolute',
          width: '100%',
          height: '125vh',
          zIndex: 0,
          backgroundImage : `url(${bgImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat : 'no-repeat',
          filter: 'blur(25px)',
          '@media (max-width:480px)':{
            height: '48vh',
          }
        },
        heading1:{ 
          fontFamily: palette.fontFamily.main,
          fontSize: '24px', 
          fontWeight: 600, 
          color: palette.primary.main, 
          borderBottomColor:palette.primary.main, 
          borderWidth: '4px' 
        },
        discussionBox: {
          height: '600px',
          boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
        },
        nameGrid:{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        },
        inputLength:{
          color: '#545454',
          fontStyle : palette.fontFamily.main,
          fontSize: '18px',
        },
        userInputBox:{
        },
        sendBtngrid:{
          margin:'auto'
        },
        sendBTN:{
          height: '36px',
          width: '36px',
          cursor: 'pointer',
          "@media (max-width: 600px)": {
            height: '30px',
            width: '30px'
          }
        },
        emptyText:{
          fontSize: '25px',
          fontFamily: palette.fontFamily.main,
          color: '#e6e6e6',
          fontWeight: 'bold',
          margin: '20% 35%',
          // padding: 'auto'
        },
        userInput:{
          border: 'none',
          margin: '30px 0px 10px 30px',
          // width: '80%',
          hight: '100px',
          fontFamily: palette.fontFamily.main,
          fontSize: '18px',
          padding: '10px',
          width: '94%',
          borderBottom: '1px solid #e6e6e6',
          outline: 'none'

        },
        userCommentsBox:{
          // margin: '30px'
        },
        userName:{
          margin: '30px 35px 0px 30px',
          fontFamily: palette.fontFamily.main,
          fontSize: '18px',
          fontWeight: 600,
          color: palette.primary.main,
          "@media (max-width: 600px)": {
            margin: '50px 35px 0px 30px',
          }
        },
        userName2:{
          margin: '0px 35px 0px 30px',
          fontFamily: palette.fontFamily.main,
          fontSize: '18px',
          fontWeight: 600,
          color: palette.primary.main
        },
        userText:{
          margin: '0px 35px 30px 30px',
          fontFamily: palette.fontFamily.main,
          fontSize: '18px',
          '@media (max-width: 600px)': {
            display: 'none'
          }
          // color: palette.primary.main
        },
        userText2:{
          display: 'none',
          '@media (max-width: 600px)': {
            margin: '0px 35px 30px 30px',
            fontFamily: palette.fontFamily.main,
            fontSize: '18px',
            display: 'flex'
          }
          // color: palette.primary.main
        },
        userImages:{
          height: '60px',
          width: '60px',
          borderRadius: '50%',
          margin: '30px'
        },
        userImage2:{
          height: '50px',
          width: '50px',
          borderRadius: '50%',
          margin: '30px 10px 30px 10px',
          '@media (max-width: 600px)': {
            margin: '5px',
            marginTop: '20px'
          }
        },
        peopleDiscussions:{
          height: '75%',
          overflow: 'auto',
        },
        userDiscussions:{
          height: '25%',
          padding: '10px'
        },
        mainGrid:{
          justifyContent: 'center'
        },
        root: {
          justifyContent: 'center',
          marginBottom: '20px',
          '@media (max-width:480px)':{
            paddingLeft: '-16px',
            paddingRight: '-16px',
          }
        },
        mainHeading:{
            fontSize: '48px',
            fontFamily: palette.fontFamily.main,
            color: '#353535',
            fontWeight: 800,
            lineHeight: 1.25,
            textAlign: 'center',
            margin: '48px 20px',
            '@media (max-width:480px)':{
              fontSize: '28px',
              textAlign: 'left',
              margin: '48px 0px',
            }
        },
        playBox:{
            cursor: 'pointer',
            position: 'relative',
            justifyContent: 'center'
        },
          playBTn:{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          },
          playBTn2:{
            height: '50px',
            width: '50px',
            borderRadius: '50%',
            background: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        imageGrid:{
            // padding: '20px'
          },
          courseGrid:{
            margin: '20px 0px'
          },
          courseGridTop:{
            marginBottom: '20px'
          },
          transcriptBox:{
            height: '500px',
            objectFit: 'contain',
            boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
            border: '1px solid #e6e8f0',
            overflow: 'auto'
          },
          transscriptTimes:{
            fontSize: '16px',
            fontFamily: palette.fontFamily.main,
            letterSpacing: '0.5px',
            padding: '10px 15px',
            margin: '30px 30px',
            color: '#8991a4',
            border: '1px solid #e6e6e6',
            borderRadius: '8px',
            textAlign: 'center',
            '@media (max-width:480px)':{
              margin: '10px 10px',
            }
          },
          transscriptTimeGrid:{
            justifyContent: 'center'
          },
          lessonMaterialBox:{
            objectFit: 'contain',
            borderRadius: '10px',
            border: '1px solid #c7c7c7',
            margin: '20px 0px'
          },
          detailWrap: {
            '@media (max-width: 600px)': {
              padding: '20px'
            }
          },
          downloadBtn:{
            textTransform: 'none',
            border: `1px solid ${palette.primary.main}`,
            borderRadius: '10px',
            padding: '6px 50px',
            color: palette.primary.main
          },
          bottomBtnGrid:{
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
          },
          bottomBtn:{
            padding: '10px 60px',
            maxHeight: '50px',
            borderRadius: '7px',
            margin: '20px 10px',
            textTransform: 'none',
            backgroundColor: palette.primary.main,
            color: '#fff',
            fontSize: '18px',
            fontFamily: palette.fontFamily.main,
            fontWeight: 500,
          '&:hover': {
            background: 'transparent',
            color: palette.primary.main,
            border: `1px solid ${palette.primary.main}`,
          },
        },
          para: {fontFamily: palette.fontFamily.main, fontSize: '18px', lineHeight: 1.78},
          para3: {fontFamily: palette.fontFamily.main, fontSize: '16px', lineHeight: 2},
          para4: {fontFamily: palette.fontFamily.main, fontSize: '14px', lineHeight: 1.43, color: '#8991a4', padding:'10px 0px'},
          para2: {
            fontFamily: palette.fontFamily.main, 
            fontSize: '16px', 
            letterSpacing: '0.5px', 
            margin: '32px 46px 32px 32px',
            '@media (max-width: 600px)' : {
              margin: '0px 15px 15px 30px',
              width: '100%'
            }
          },
          headings:{fontFamily: palette.fontFamily.main, fontSize: '24px', margin: '20px 0px', fontWeight: 600, color: '#1c1c1c'},
          tabsBox:{ 
            margin: '20px 0px 0px 0px',
          "& .MuiTabs-indicator": {
            backgroundColor: palette.primary.main,
            borderRadius: '1.5px',
            height: '4px'
          }
        },
          headingTabs:{ fontFamily: palette.fontFamily.main, fontSize: '24px', fontWeight: 600, color: '#353535', textTransform: 'none', minWidth: '50% !important' },
        selectedStampCard: {
          height: '70px',
          padding: '14px 114px 14px 16px',
          borderRadius: '10px',
          background: 'rgba(255, 255, 255, 0.51)',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: 'auto',
          cursor: 'pointer'
        },
        stampCard: {
          height: '70px',
          padding: '14px 114px 14px 16px',
          borderRadius: '10px',
          // background: 'rgba(255, 255, 255, 0.51)',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: 'auto',
          cursor: 'pointer'
        },
        stampTitle: {
          fontFamily: 'Poppins-SemiBold',
          fontSize: '14px',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.29,
          width: '100%',
          letterSpacing: '-0.3px'
        },
        stampTime: {
          width: '44px',
          height: '18px',
          margin: '8px 148px 0 0',
          padding: '1px 3px',
          borderRadius: '2px',
          backgroundColor: 'rgba(213, 96, 54, 0.25)',
          textAlign: 'center'
        }
      })
      )(singleLessons)
  )
  // Customizable Area End