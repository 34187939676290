import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
// Customizable Area End
export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mobileOpen: boolean;
  openSetting: boolean;
  tabs: any;
  blogPerPage: number;
  sortingLoader: boolean;
  personList: any;
  orgList: any;
  bookList: any;
  filmList: any;
  loading: boolean;
  errorMsg: string;
  openAlert: boolean;
  modalOpen: boolean;
  deleteId: any;
  deleteType: any;
  personMeta: any;
  orgMeta: any;
  filmMeta: any;
  bookMeta: any;
  searchQuery: string;
  searchList: any;
  openSearch: boolean;
  searchLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddBookController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiGetPersonListId: string = '';
  apiGetOrgListId: string = '';
  apiGetFilmListId: string = '';
  apiGetBookListId: string = '';
  deleteOrgApiId: string = '';
  deletePersonApiId: string = '';
  deleteFilmApiId: string = "";
  deleteBookApiId: string = "";
  apiSearchItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      mobileOpen: false,
      openSetting: false,
      tabs: 0,
      blogPerPage: 10,
      sortingLoader: false,
      personList: {},
      bookList: {},
      orgList: {},
      filmList: {},
      loading: false,
      errorMsg: '',
      openAlert: false,
      modalOpen: false,
      deleteId: '',
      deleteType: '',
      personMeta: '',
      orgMeta: '',
      filmMeta: '',
      bookMeta: '',
      searchQuery: '',
      searchList: [],
      openSearch: false,
      searchLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    let tab = !localStorage.getItem('tab') ? 0 : localStorage.getItem('tab');
    this.setState({
      tabs: tab
    })
    if(tab === 1 || tab === '1'){
      this.getOrgList(1)
      this.setState({
        tabs: 1
      })
    } else if (tab === 2 || tab === '2'){
      this.getFilmList(1)
      this.setState({
        tabs: 2
      })
    } else if(tab === 3 || tab === '3'){
      this.getBookList(1)
      this.setState({
        tabs: 3
      })
    } else {
      this.getPersonList(1)
      this.setState({
        tabs: 0
      })
    }
  }

  goToEditPage = (id: any, path: any) => {
    this.props.navigation.navigate(path, {
      id:id
    })
  }

  hanldeSettingToggle = () => {
    this.setState({ openSetting: !this.state.openSetting })
  }


  deleteList = (id: any, type: any) => {
    this.setState({
      modalOpen: true,
      deleteId: id,
      deleteType: type
    })
  }

  handleDeleteClose = () => {
    if(this.state.deleteType === 'org'){
      this.deleteOrg(this.state.deleteId);
    }
    if(this.state.deleteType === 'person'){
      this.deletePerson(this.state.deleteId);
    }
    if(this.state.deleteType === 'book'){
      this.deleteBook(this.state.deleteId);
    }
    if(this.state.deleteType === 'film'){
      this.deleteFilm(this.state.deleteId);
    }
  }

  setDeleteOrg = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        loading: false,
        openAlert: true,
        deleteId: '',
        deleteType: '',
        errorMsg: 'Organisation deleted successfully!'
      }, () => {
        this.getOrgList(1)
      });
    } else {
      this.setState({
        loading: false,
        openAlert: true,
        deleteId: '',
        deleteType: '',
        errorMsg: 'Something went wrong!'
      })
    }
  }

  setDeletePerson = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        loading: false,
        openAlert: true,
        deleteId: '',
        deleteType: '',
        errorMsg: 'Person deleted successfully!'
      }, () => {
        this.getPersonList(1)
      });
    } else {
      this.setState({
        loading: false,
        openAlert: true,
        deleteId: '',
        deleteType: '',
        errorMsg: 'Something went wrong!'
      })
    }
  }

  setDeleteFilm = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        loading: false,
        openAlert: true,
        deleteId: '',
        deleteType: '',
        errorMsg: 'Film deleted successfully!'
      }, () => {
        this.getFilmList(1)
      });
    } else {
      this.setState({
        loading: false,
        openAlert: true,
        deleteId: '',
        deleteType: '',
        errorMsg: 'Something went wrong!'
      })
    }
  }

  setDeleteBook = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        loading: false,
        openAlert: true,
        deleteId: '',
        deleteType: '',
        errorMsg: 'Book deleted successfully!'
      }, () => {
        this.getBookList(1)
      });
    } else {
      this.setState({
        loading: false,
        openAlert: true,
        deleteId: '',
        deleteType: '',
        errorMsg: 'Something went wrong!'
      })
    }
  }

  setPersonList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        personList: responseJson.data,
        loading: false,
        personMeta: responseJson.meta.pagination
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }

  setOrgList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        orgList: responseJson.data,
        loading: false,
        orgMeta: responseJson.meta.pagination
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }

  setFilmList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        filmList: responseJson.data,
        loading: false,
        filmMeta: responseJson.meta.pagination
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }

  setBookList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        bookList: responseJson.data,
        loading: false,
        bookMeta: responseJson.meta.pagination
      });
    } else {
      this.setState({
        loading: false
      });
    }
  } 

  setListData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiGetPersonListId) {
      this.setPersonList(responseJson)
    }
    if (apiRequestCallId === this.apiGetOrgListId) {
      this.setOrgList(responseJson)
    }
    if (apiRequestCallId === this.apiGetBookListId) {
      this.setBookList(responseJson)
    }

    if (apiRequestCallId === this.apiGetFilmListId) {
      this.setFilmList(responseJson)
    }
    if(apiRequestCallId === this.apiSearchItemCallId){
      if (responseJson && !responseJson.errors) {
        this.setState({
          searchList: responseJson,
          searchLoading: false,
        })
      }
    }
  }

  setDeleteData = (apiRequestCallId: any, responseJson: any) => {
    if(apiRequestCallId === this.deleteOrgApiId){
      this.setDeleteOrg(responseJson)
    }
    if(apiRequestCallId === this.deletePersonApiId){
      this.setDeletePerson(responseJson)
    }
    if(apiRequestCallId === this.deleteFilmApiId){
      this.setDeleteFilm(responseJson)
    }
    if(apiRequestCallId === this.deleteBookApiId){
      this.setDeleteBook(responseJson)
    }
  }

  setAllData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId != null) {
      this.setListData(apiRequestCallId, responseJson)
      this.setDeleteData(apiRequestCallId, responseJson)
    }
  }

  getSearchItems = () => {
    this.setState({
      searchLoading: true
    })
    let tab = !localStorage.getItem('tab') ? 0 : localStorage.getItem('tab');
    const header = {
      // "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSearchItemCallId = requestMessage.messageId;
    let hitUrl: any = '';
    if(tab === 1 || tab === '1'){
      hitUrl = configJSON.adminSearchApi+`?key=${this.state.searchQuery}&data=organisations`
    } else if (tab === 2 || tab === '2'){
      hitUrl = configJSON.adminSearchApi+`?key=${this.state.searchQuery}&data=films`
    } else if (tab === 3 || tab === '3'){
      hitUrl = configJSON.adminSearchApi+`?key=${this.state.searchQuery}&data=books`
    } else {
      hitUrl = configJSON.adminSearchApi+`?key=${this.state.searchQuery}&data=people`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  handleInputChange = (e: any) => {
    this.setState({
      searchQuery: e.target.value
    }, () => {
      this.setState({
        openSearch: true,
        searchLoading: true
      }, ()=> {
        setTimeout(() => {
          this.getSearchItems();
        }, 5000)
      })
    })
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setAllData(apiRequestCallId, responseJson);
    }
  }

  changePersonPage = (e: any, page: any) => {
    console.log(e)
    this.getPersonList(page)
  }

  changeOrgPage = (e: any, page: any) => {
    console.log(e)
    this.getOrgList(page)
  }

  changeBookPage = (e: any, page: any) => {
    console.log(e)
    this.getBookList(page)
  }

  changeFilmPage = (e: any, page: any) => {
    console.log(e)
    this.getFilmList(page)
  }

  getPersonList = (page: any) => {
    this.setState({
      loading: true,
      tabs: 0
    })
    let tab : any = 0;
    window.localStorage.setItem('tab', tab)
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPersonListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPersonListApi+`?page=${page}&per_page=10`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBookList = (page: any) => {
    this.setState({
      loading: true,
      tabs: 3
    })
    let tab : any = 3;
    window.localStorage.setItem('tab', tab)
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetBookListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBookListApi+`?page=${page}&per_page=10`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFilmList = (page: any) => {
    this.setState({
      loading: true,
      tabs: 2
    })
    let tab : any = 2;
    window.localStorage.setItem('tab', tab)
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFilmListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFilmsListApi+`?page=${page}&per_page=10`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteOrg = (orgId: any) => {
    this.setState({
      loading: true,
      modalOpen: false
    })
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteOrgApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrgListApi+'/'+orgId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deletePerson = (personId: any) => {
    this.setState({
      loading: true,
      modalOpen: false
    })
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePersonApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPersonListApi+'/'+personId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteFilm = (filmId: any) => {
    this.setState({
      loading: true,
      modalOpen: false
    })
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFilmApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFilmsListApi+'/'+filmId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteBook = (bookId: any) => {
    this.setState({
      loading: true,
      modalOpen: false
    })
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteBookApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBookListApi+'/'+bookId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOrgList = (page: any) => {
    this.setState({
      loading: true,
    })
    let tab : any = 1;
    window.localStorage.setItem('tab', tab)
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOrgListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrgListApi+`?page=${page}&per_page=10`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End

}
