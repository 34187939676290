// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import { ProfileContextType, defaultContext } from "./ProfileContext";
import RequestApi from "../../../../components/src/RequestApi";

export const configJSON = require("../config");

export interface Props {
    navigation?: any;
    id?: string;
    children?: React.ReactNode;
}

interface S extends ProfileContextType {
    isloading: boolean;
    errorMsg: string;
}

interface SS {
    id: any;
}

export default class ProfileContextController extends BlockComponent<
    Props,
    S,
    SS
> {
    getUserProfilePicCallId: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            ...defaultContext,
            isloading: true,
            errorMsg: ""
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);


        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if(localStorage.getItem('token')? true : false){
                if (responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
                        this.setState({
                            isTokenInvalid: true
                        })
                }
            }


            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {

                if (apiRequestCallId == this.getUserProfilePicCallId) {
                    this.userProfileDataResponse(responseJson, errorReponse)
                }

            }
        }

    }


    async componentDidMount() {
        this.getUserProfileData()
    }



    getUserProfileData = () => {
        const header = {
            "token": localStorage.getItem("token"),
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = RequestApi({
            header: header,
            endPoint: configJSON.getProfileApi,
            method: "GET",
        });
        this.getUserProfilePicCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    userProfileDataResponse = (responseJson: any, errorReponse: any) => {
        console.log("Checking res", responseJson)
        if (responseJson && !responseJson.errors) {
            this.setState({
                full_name: responseJson?.data?.attributes?.full_name,
            });
        } else {
            this.setState({
                errorMsg: errorReponse,
            });
        }
    }

    setTokenValidity = (open: boolean) => {
        this.setState({
            isTokenInvalid: open,
        })
    }

    

}

 // Customizable Area End