import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  mobileOpen: boolean;
  upcomingEvents: any;
  todaysEvents: any;
  pastEvents: any;
  allEvents: any;
  eventsLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EventsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getEventsListId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      mobileOpen: false,
      upcomingEvents: [],
      todaysEvents: [],
      pastEvents: [],
      allEvents: [],
      eventsLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId == this.getEventsListId) {
          if(responseJson && !errorReponse){
            let firstEvents = responseJson.data.upcoming_streams.concat(responseJson.data.past_streams);
            let secEvents = firstEvents.concat(responseJson.data.today_streams)
            let events = secEvents.map((item: any) => {
              return (
                {
                  id: item.id,
                  title: item.attributes.title,
                  date: item.attributes.date,
                  // start:  `${item.attributes.date}T${item.attributes.start_time}`,
                  // end: `${item.attributes.date}T${item.attributes.end_time}`,
                  // startStr: item.attributes.start_time,
                  // endStr: item.attributes.end_time,
                  // allDay: false
                }
              )
            })
            this.setState({
              eventsLoading: false,
              upcomingEvents: responseJson.data.upcoming_streams,
              pastEvents: responseJson.data.past_streams,
              todaysEvents: responseJson.data.today_streams,
              allEvents: events
            })
          } else {
            this.setState({
              eventsLoading: false
            })
          }
        }
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start

  async componentDidMount() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.getEventsList()
  }

  getEventsList = () => {
    this.setState({ eventsLoading: true })
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getEventsListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getEventsListApi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
  
}
