Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "QuestionBank";
exports.labelBodyText = "QuestionBank Body";

exports.btnExampleTitle = "CLICK ME";
exports.updateProfileApi = 'account_block/accounts';
exports.newsLetterApi = 'account_block/accounts/newsletter';
exports.changePwdApi = 'bx_block_forgot_password/password/change_password';
exports.getProfileApi = 'account_block/accounts/profile'
// Customizable Area End