import React from "react";

// Customizable Area Start
import {
    Button,
    Typography,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import Box from '@material-ui/core/Box'
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
// import LetsWatchCarousel from "../../../components/src/LetsWatchCarousel";
import VideoCard from "../../../components/src/VideoCard";
import HomeCarousel from "../../../components/src/HomeCarousel";
import screenfull from "screenfull";
import CarousalPlayer from "../../../components/src/CarousalPlayer";
import FavouriteIcon from "../../../components/src/FavouriteIcon";
import { VideoType } from "./Interfaces.web";
import AlertModal from "../../../components/src/AlertModal";
import CustomLoader from "../../../components/src/CustomLoader";
import CustomHoverBtn from "../../../components/src/CustomHoverBtn";
export const timeFormatter = (date: string) => {
    if (!date) return;
    return moment.utc(date, "YYYY-MM-DD HH:mm:ss").local().format('hh:mm a');
}

export const dateFormatter = (date: string) => {
    if (!date) return;
    return moment.utc(date, "YYYY-MM-DD HH:mm:ss").local().format('MMM D');
}

export const fullDateFormatter = (date: string) => {
    if (!date) return;
    return moment.utc(date, "YYYY-MM-DD HH:mm:ss").local().format('D MMM YYYY');
}
export const getAge = (dateString: string) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let d1 = birthDate.getDate();
    let m1 = birthDate.getMonth();
    let y1 = birthDate.getFullYear();
    let d2 = today.getDate();
    let m2 = today.getMonth();
    let y2 = today.getFullYear();
    let month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (d1 > d2) {
        d2 = d2 + month[m2 - 1];
        m2 = m2 - 1;
    }
    if (m1 > m2) {
        m2 = m2 + 12;
        y2 = y2 - 1;
    }
    const d = d2 - d1;
    const m = m2 - m1;
    const y = y2 - y1;


    if (y > 0) {
        return ('' + y + ' years ' + m + ' months ' + d + ' days');
    }
    if (y == 0 && m > 0) {
        return (+m + ' months ' + d + ' days');
    }
    if (y == 0 && m == 0) {
        return (+d + ' days');
    }
}
let count = 0;

const format = (seconds: any) => {
    if (isNaN(seconds)) {
        return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
        return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
};



// Customizable Area End

import LetsWatchHomeController, {
    Props,
    configJSON
} from "./LetsWatchHomeController";
import { palette } from "../../../components/src/theme";


class LetsWatchHomePage extends LetsWatchHomeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // @ts-ignore
        this.window = window;
        // @ts-ignore
        this.playerRef = React.createRef();
        // @ts-ignore
        this.controlsRef = React.createRef();
        // @ts-ignore
        this.playerContainerRef = React.createRef();
        // @ts-ignore
        this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

        // @ts-ignore
        this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";

        // @ts-ignore
        this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
        // @ts-ignore
        this.totalDuration = format(this.duration);
        // Customizable Area End
    }

    // Customizable Area Start
    changePage = (event: React.ChangeEvent<unknown>, value: number) => {
        // @ts-ignore
        document.getElementById('pag').scrollIntoView()
        const { videoLoading } = this.state
        if (!videoLoading) {
            this.getVideos('', value);
        }
    };
    pauseVideo = (playingState: any) => {
        this.setState({ ...this.state, videoPlaying: playingState })
    }

    handlePlayPause = (carausalId: any) => {
        this.setState({ ...this.state, videoPlaying: !this.state.videoPlaying, carausalId: carausalId });
    };

    handleRewind = () => {
        // @ts-ignore
        this.playerRef?.current?.seekTo(this.playerRef.current.getCurrentTime() - 10);
    };

    handleForward = () => {
        // @ts-ignore
        this.playerRef?.current?.seekTo(this.playerRef.current.getCurrentTime() + 10);
    };

    handleSeekChange = (e: any, newValue: any) => {
        // @ts-ignore
        this.setState({ ...this.state, played: parseFloat(newValue / 100) });
    };

    handleSeekMouseDown = (e: any) => {
        this.setState({ ...this.state, seeking: true });
    };

    handleSeekMouseUp = (e: any, newValue: any) => {
        this.setState({ ...this.state, seeking: false });
        // @ts-ignore
        this.playerRef?.current?.seekTo(newValue / 100, "fraction");
    };

    handleDuration = (duration: any) => {
        this.setState({ ...this.state, allDuration: duration });
    };

    toggleFullScreen = () => {
        this.setState({ hideBtn: !this.state.hideBtn })
        // @ts-ignore
        screenfull.toggle(this.playerContainerRef.current);
    };

    onEscape = () => {
        this.setState({ hideBtn: false })
    }

    handleProgress = (changeState: any) => {
        // @ts-ignore
        this.duration = this.playerRef && this.playerRef.current ? this.playerRef.current.getDuration() : "00:00";

        // @ts-ignore
        this.currentTime = this.playerRef && this.playerRef.current ? this.playerRef.current.getCurrentTime() : "00:00";

        // @ts-ignore
        this.elapsedTime = this.state.timeDisplayFormat == "normal" ? format(this.currentTime) : `-${format(this.duration - this.currentTime)}`;
        // @ts-ignore
        this.totalDuration = format(this.duration);
        if (count > 3) {
            // @ts-ignore
            this.controlsRef.current.style.visibility = "hidden";
            count = 0;
        }
        // @ts-ignore
        if (this.controlsRef.current.style.visibility == "visible") {
            count += 1;
        }
        if (!this.state.seeking) {
            this.setState({ ...this.state, ...changeState });
        }
    };

    handleOnEnded = () => {
        this.setState({ ...this.state, videoPlaying: false, played: 0 });
    }

    handlePip = () => {
        this.setState({ ...this.state, pip: !this.state.pip, hideBtn: false })
    }

    handleDisplayFormat = () => {
        this.setState({ timeDisplayFormat: this.state.timeDisplayFormat == " normal" ? 'remaining' : 'normal' })
    };

    handleVolumeSeekDown = (e: any, newValue: any) => {
        // @ts-ignore
        this.setState({ ...this.state, seeking: false, volume: parseFloat(newValue / 100) });
    };
    handleVolumeChange = (e: any, newValue: any) => {
        this.setState({
            ...this.state,
            // @ts-ignore
            volume: parseFloat(newValue / 100),
            muted: newValue === 0 ? true : false,
        });
    };

    closeShare = () => {
        this.setState({
            openShare: false
        })
    }

    shareOpen = () => {
        this.setState({
            openShare: true
        })
    }

    handleMute = () => {
        this.setState({ ...this.state, muted: !this.state.muted });
    };

    handlePlaybackRate = (rate: any) => {
        this.setState({ ...this.state, playbackRate: rate });
    };

    onChangeBitrate = (quality: any) => {
        // @ts-ignore
        const internalPlayer = this.playerRef.current?.getInternalPlayer();
        if (internalPlayer) {
            // currentLevel expect to receive an index of the levels array
            internalPlayer.currentLevel = quality;
            this.setState({ ...this.state, quality: quality });
        }
    }

    getHomeCarousal = (videoList: any, classes: any) => {
        return (
            <HomeCarousel pauseCurrent={(playingState: any) => this.changeCurrentPlayingId('')}>
            {
                videoList?.carousel_videos.length ?
                    videoList?.carousel_videos.map((data: VideoType, index: any) =>
                        <div key={index} >
                            <Grid 
                                container 
                                spacing={2}
                                className={classes.watchCarousal}
                            >
                                <Grid item xs={12} sm={6}>
                                    {/* @ts-ignore */}
                                    <Box
                                        style={{
                                            padding: '20px'
                                        }}
                                    >
                                        <span
                                            style={{
                                                display: "flex",
                                                flexDirection: 'row',
                                                height: "2rem"
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    // color: palette.primary.main,
                                                    fontFamily: 'Poppins-SemiBold',
                                                    textTransform: 'uppercase',
                                                    color: "#F27024",
                                                    fontSize:  "12px"
                                                    
                                                }}
                                            >
                                                {data?.attributes?.category?.name ? data?.attributes?.category?.name : 'SOCIAL SERVICE'}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    color: '#8991a4',
                                                    fontFamily: 'Poppins-Light',
                                                    textTransform: 'uppercase',
                                                    marginLeft: '40px',
                                                    fontSize:  "12px"
                                                }}
                                            >
                                                {data.attributes.created_at ? moment(data.attributes.created_at).format("DD MMM YYYY") : "02 FEB 2022"}
                                            </Typography>
                                        </span>
                                        <Typography
                                            style={{
                                                fontFamily: 'Poppins-Light',
                                                marginTop: '30px'
                                            }}
                                            className={classes.headerSize}
                                        >
                                            {data.attributes.title}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontFamily: 'Poppins-Light',
                                                marginTop: '30px',
                                                color: '#8991a4'
                                            }}
                                            className={classes.seeAll}
                                        >
                                            {data.attributes.about ? data.attributes.about.substring(0, 50) + '...' : "Video description not present"}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    {/* @ts-ignore */}
                                    <CarousalPlayer 
                                        item={data} 
                                        currentPlayingId={this.state.currentVideoPlayingId} 
                                        changeCurrentPlayingId={this.changeCurrentPlayingId} 
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )
                    :
                    ""
            }
        </HomeCarousel>
        )
    }

    getBtnName = (item: any) => {
        if(this.state.windowWidth > 960 && this.state.windowWidth < 1024){
            return item.attributes.name && item.attributes.name.length > 14 
            ? item.attributes.name.substring(0,13) + '...' 
            : item.attributes.name
        }
        return item.attributes.name
    }

    getFilterButtons = () => {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item md={2} sm={4} xs={6} >
                        <CustomHoverBtn
                            selected={!this.state.catId}
                            handleClick={() => this.getAllVideos()}
                            color={palette.primary.main}
                            br="none"
                            fullWidth
                            title="All Videos"
                        />
                    </Grid>
                {
                    this.state.podcastFilterOptionsData.length ? this.state.podcastFilterOptionsData.map((item: any, index: any) => {
                    return (
                        <Grid item key={item.id}>
                            <CustomHoverBtn
                                selected={this.state.catId === item.attributes.id}
                                handleClick={() => this.getFilteredVideos(item.attributes.id)}
                                color={palette.primary.main}
                                br="none"
                                fullWidth
                                title={
                                    this.getBtnName(item)
                                }
                            />
                        </Grid>
                    )
                    })
                    : <Typography>Loading...</Typography>
                }
                </Grid>
            </Box>
        )
    }

    closeAlertBox = () => {
        this.setState({
            openAlert: false
        })
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { videoList, videoLoading, videosPerPage, currentPage, windowWidth } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                {
                    this.state.openAlert ?
                        <AlertModal 
                            open={this.state.openAlert}
                            data={this.state.updateMessage}
                            onClose={() => this.closeAlertBox()}
                        />
                    :   null
                }
                {
                    this.state.loading ?
                    <CustomLoader 
                        open={this.state.loading}
                    />
                    : null
                }
                <Box className={classes.mainWrapper}>
                    <Typography style={{ color: "#F27024", fontFamily: "Poppins-Light" }} gutterBottom><span style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate("Home")}>Home</span> / <span style={{ cursor: "pointer" }} onClick={() => this.props.navigation.navigate("Videos")}>Let's Watch</span> / <span style={{ color: "grey" }} >Videos</span></Typography>
                    <Box className={classes.whiteBorder} />
                    <Box>
                        {videoLoading ? <Typography style={{ fontFamily: "Poppins-SemiBold", width: "100%", textAlign: "center" }}>Loading...</Typography>
                            :
                            this.getHomeCarousal(videoList, classes)
                        }
                    </Box>
                    <Box style={{ marginTop: "45px" }} className={classes.whiteBorder} />
                    {
                        this.getFilterButtons()
                    }
                    <Box id="pag" className={classes.Card1Style} >
                        {/* <Typography component="div" className={classes.headerSize} gutterBottom style={{ fontFamily: "Poppins-SemiBold", marginBottom: "2.5rem" }}>Videos</Typography> */}

                        {
                            videoLoading ? <Box style={{ textAlign: "center" }}> <CircularProgress /> </Box> : <Grid
                                container
                                spacing={5} className={classes.cardCenter}>
                                {

                                    videoList?.videos?.data
                                        ?.slice((currentPage - 1) * videosPerPage, (currentPage - 1) * videosPerPage + videosPerPage)
                                        ?.map((data: any) =>
                                            <Grid key={data?.id} item md={4} sm={6} xs={12} style={{ width: "100%" }} >
                                                <FavouriteIcon 
                                                    isFavourite={data.attributes.favourite}
                                                    position={'absolute'}
                                                    right={'10px'}
                                                    top={'10px'}
                                                    makeFavourite={(status: any) => this.makeFavourite(data.id, status)}
                                                    navigation={this.props.navigation}
                                                />
                                                <VideoCard item={data} gotToSingleArticle={this.goToSingleVideo} />
                                            </Grid>
                                        )
                                }
                            </Grid>}
                    </Box>

                    {videoLoading ? "" : <Box
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "30px",
                            marginTop: "30px",
                        }}
                    >
                        <Pagination
                            variant="outlined"
                            size={windowWidth < 600 ? "medium" : "large"}
                            count={videoList?.videos?.meta?.pagination?.total_pages}
                            // defaultPage={1}
                            page={videoList?.videos?.meta?.pagination?.current_page}
                            onChange={this.changePage}
                            className={classes.root}
                            siblingCount={0}
                        />
                    </Box>}
                </Box>
                {/* @ts-ignore */}
                {
                    videoList?.other_videos?.length ?
                        <Box
                            mt={8}
                            mb={8}
                            className={classes.otherVideoBg}
                        >
                            <Box style={{ padding: "0 8%", }}>
                                {/* @ts-ignore */}
                                <Box pt={6} style={{ display: "flex", justifyContent: "space-between", marginBottom: "2rem", alignItems: "center" }}>
                                    <Typography component="div" className={classes.headerSize}  style={{ fontFamily: "Poppins-Bold" }}>Other videos you may like</Typography>
                                    <Box>
                                        <Button
                                            style={{
                                                color: "#F27024",
                                                fontFamily: 'Poppins-SemiBold',
                                                textTransform: "inherit",
                                            }}
                                            className={classes.seeAll}
                                            onClick={()=>this.props.navigation.navigate("OtherVideo")}
                                        >
                                            See all
                                        </Button>
                                    </Box>
                                </Box>
                                <Grid
                                    container
                                    spacing={5} alignItems="center" className={classes.cardCenter} justifyContent="space-between">
                                    {
                                        videoList?.other_videos.length ?
                                            videoList?.other_videos?.slice(0, 4)
                                                .map((data: any) =>
                                                    <Grid item sm={6} xs={12} md={3} key={data?.id}>
                                                        <VideoCard item={data} gotToSingleArticle={this.goToSingleVideo} />
                                                    </Grid>
                                                )
                                            : <Typography style={{ fontFamily: "Poppins-SemiBold", textAlign: "center", width: "100%" }}>No data found</Typography>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                        :
                        <Box
                            mt={8}
                            style={{
                                background: '#FFFBE6',
                                minHeight: '200px',
                                height: '200px',
                            }} >
                            <Box style={{ padding: "0 8%", }}>
                                {/* @ts-ignore */}
                                <Box pt={6} style={{ display: "flex", justifyContent: "space-between", marginBottom: "2rem", alignItems: "center" }}>
                                    <Typography component="div" className={classes.headerSize}  style={{ fontFamily: "Poppins-Bold" }}>Other videos you may like</Typography>
                                    <Box>
                                        <Button
                                            style={{
                                                color: "#F27024",
                                                fontFamily: 'Poppins-SemiBold',
                                                textTransform: "inherit",
                                            }}
                                            className={classes.seeAll}
                                        >
                                            See all
                                        </Button>
                                    </Box>
                                </Box>
                                <Typography style={{ fontFamily: "Poppins-SemiBold", textAlign: "center", width: "100%" }}>No data found</Typography>
                            </Box>
                        </Box>
                }
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            typography: {
                body2: {
                    fontFamily: 'Poppins, sans-serif'
                },
            },
            mainWrapper: {
                fontFamily: 'Poppins',
                padding: '2% 8%',
                [theme.breakpoints.down("xs")]: {
                    padding: '2% 6%'
                },
                background: '#fff',
            },
            varticleLine: {
                width: "2px",
                height: "200px",
                margin: "84.9px 26px 281px 47px",
                opacity: 0.35,
                borderRadius: "1px",
                backgroundImage: "radial-gradient(circle at 50% 54%, #f00, #ffffff 84%)",
                [theme.breakpoints.down("sm")]: {
                    display: "none"
                },

            },
            watchCarousal: {
                backgroundColor: '#ffbe63', 
                padding: '20px',
                alignItems: 'center',
                height: '100%',
                [theme.breakpoints.down("xs")]: {
                    height: 'auto',
                }
            },

            Card1Style: {
                margin: "3rem 0"
            },
            cardCenter: {
                [theme.breakpoints.down("md")]: {
                    justifyContent: "center"
                },
            },

            title: {
                textAlign: "right",
                [theme.breakpoints.down("xs")]: {
                    textAlign: "left",
                },
            },
            tags: {
                color: "#F27024",
                border: "1px solid #F27024",
                padding: "6px 30px",
                textTransform: "initial",
                '&:hover': {
                    color: "#fff",
                    background: "#F27024"
                }
            },
            tagSelected: {
                color: "#fff",
                background: "#F27024"
            },
            root: {
                color: "#F27024 !important",
                "& .Mui-selected": {
                    background: "#F27024",
                    color: "white",
                    fontWeight: "bold",
                },
                ul: {
                    "& .MuiPaginationItem-root": {
                        color: "#F27024"
                    }
                }
            },
            whiteBorder: {
                width: "100%",
                height: "1px",
                margin: "0 0 45px",
                backgroundColor: "#e6e6e6"
            },
            carousalWrapper: {
                // display: "flex", 
                height: "100%", 
                alignItems: "center", 
                backgroundColor: '#FFFBE6', 
                // justifyContent: "space-evenly", 
                borderRadius: "15px",
                padding: '40px',
                [theme.breakpoints.down("xs")]: {
                    padding: '20px'
                },
            },
            otherVideoBg: {
                background: "linear-gradient(180deg, #fffbe6 50%, #ffffff 50%)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                paddingTop: "2%",
            },
            headerSize: {
                fontSize: "1.8rem",
                [theme.breakpoints.down("sm")]: {
                    fontSize: "1rem",
                },
            },
            seeAll:{
                fontSize: "1.5rem",
                [theme.breakpoints.down("sm")]: {
                    fontSize: ".8rem",
                },
            }

        })
    )(LetsWatchHomePage)
)
// Customizable Area End
