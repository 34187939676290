export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const carouselImg = require("../assets/carouselImg.png");
export const experienceImg = require("../assets/experienceImg.png");
export const BlogCardImg = require("../assets/blogCardImg.png");
export const educateIcon = require("../assets/EducateIcon.png");
export const improveIcon = require("../assets/ImproveIcon.png");
export const peopleIcon = require("../assets/PeopleIcon.png");
export const okIcon = require("../assets/okIcon.png");
export const prevIcon = require("../assets/arrowLeft.png");
export const nextIcon = require("../assets/arrowRight.png");
