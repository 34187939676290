import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { CategoryType } from "../../../ContentManagement/src/Interfaces.web"
import { 
    IBlog, 
    BlogUniqueItem, 
    Sort, 
    DateTime, 
    InstructorUniqueItem 
} from "../Interfaces.web";

const userType = typeof window !== 'undefined' ? window.localStorage.getItem('userType') : null;

const userId = typeof window !== 'undefined' ? window.localStorage.getItem('userId') : null;
// Customizable Area End
export const configJSON = require("../config.js");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    mobileOpen: boolean;
    loading: boolean;
    errorMsg: string;
    nextPage: boolean;
    blogUniqueItem: BlogUniqueItem;
    blogList: IBlog[];
    getCategoryLoading: boolean;
    categoryList: CategoryType[];
    authorList: any[];
    authorLoading: boolean;
    selectecCategory: string;
    status: string;
    openSetting: boolean;
    sort: Sort;
    dateTime: DateTime;
    openAlert: boolean;
    dataCreationMsg: string;
    inputError: any;
    previewModal: boolean;
    open: any;
    bold: boolean;
    underlined: boolean;
    italic: boolean;
    alignment: string;
    enableInstructorForm: boolean;
    instructorUniqueItem: InstructorUniqueItem;
    instructorAddLoading: boolean;
    insProfilePreview: any;
    addInstructorName: string;
    addInstructorAbout: string;
    addInstructorProfile: any;
    getCoinstructorLoading: boolean;
    coInstructorList: any;
    coInstructorName: any;
    getMainnstructorLoading: boolean;
    mainInstructorList: any;
    selectedMainInstructor: any;
    lessonObject: any;
    lessonCount: any;
    courseTitle: any;
    courseDesc: any;
    lessonList: any;
    video: any;
    catValidation: string;
    coursePrice: any;
    priceValid: boolean;
    previewImg: any;
    previewVideo: any;
    bgImage: any;
    createLoading: boolean;
    setPath: boolean;
    othersName: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddNewCoursesController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetDataCallId: string = "";
    getAllCategoryId: string = "";
    getAllAuthorId: string = "";
    createBlogId: string = "";
    addInstructorId: string = "";
    getCoInstructorId: string = "";
    getMainInstructorId: string = "";
    createCourseId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // getName(MessageEnum.NavigationToSingleArticle),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            mobileOpen: false,
            loading: false,
            instructorAddLoading: false,
            errorMsg: '',
            nextPage: false,
            blogUniqueItem: {
                title: '',
                subtitle: '',
                bgImg: [],
                base64BgImg: []
            },
            addInstructorName: '',
            addInstructorAbout: '',
            addInstructorProfile: '',
            instructorUniqueItem: {
                instructorName: '',
                instructorAbout: '',
                bgImg: [],
                base64BgImg: []
            },
            setPath: false,
            blogList: [{
                id: 1,
                dataBaseId: null,
                img: [],
                base64Img: [],
                heading: {
                    text: "",
                    style: {}
                },
                subheading: {
                    text: "",
                    style: {}
                },
                para: {
                    text: "",
                    style: {}
                },
                caption: {
                    text: "",
                    style: {}
                },
            }],
            categoryList: [],
            authorList: [],
            authorLoading: false,
            getCategoryLoading: false,
            selectecCategory: "",
            status: "",
            openAlert: false,
            dataCreationMsg: "",
            openSetting: false,
            sort: {
                publish: 'immediately',
                visibility: 'public_user',
                author: 92
            },
            dateTime: {
                date: new Date(),
                time: new Date(),
            },
            inputError: {},
            previewModal: false,
            open: false,
            bold: false,
            underlined: false,
            italic: false,
            alignment: 'left',
            enableInstructorForm: false,
            insProfilePreview: '',
            getCoinstructorLoading: false,
            coInstructorList: {},
            coInstructorName: [],
            getMainnstructorLoading: false,
            mainInstructorList: [],
            selectedMainInstructor: userType == 'instructor' ? userId : '',
            lessonObject: [],
            lessonCount: [1],
            courseTitle: '',
            courseDesc: '',
            lessonList: [{
                id:1,
                title: '',
                description: '',
                video: '',
                faqs: [{
                    id: 1,
                    question: '',
                    answer: ''
                }],
                todos: [{
                    id: 1,
                    title: '',
                    description: ''
                }], 
                questions_attributes: [{
                    id: 1,
                    question: '',
                    description: '',
                    option1: '',
                    option2: '',
                    option3: '',
                    option4: '',
                    correct_answer: ''
                }],
                transcript: [{
                    id: 1,
                    time: '',
                    value: ''
                }],
                timestamp: [{
                    id: 1,
                    time: '',
                    value: ''
                }],
                material_details_attributes: [{
                    title: '',
                    summary: '',
                    description: '',
                    material_file: '',
                }]
            }],
            video: [],
            catValidation: '',
            coursePrice: '',
            priceValid: false,
            previewImg: [],
            previewVideo: [],
            bgImage: [],
            createLoading: false,
            othersName: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {
                // if (apiRequestCallId == this.apiGetDataCallId) {
                //     if (responseJson && !responseJson.errors && responseJson.data) {
                //         if (responseJson.data.length === 0) {
                //             this.setState({
                //                 errorMsg: "Data Not Found",
                //                 loading: false,

                //             });
                //         } else {
                //             this.setState({
                //                 ...this.state,
                //                 errorMsg: "",
                //                 loading: false,
                //             });
                //         }
                //     } else {
                //         if (errorReponse === undefined) {
                //             this.setState({
                //                 errorMsg: "Something went wrong",
                //                 loading: false,
                //             });
                //         } else {
                //             this.setState({
                //                 errorMsg: errorReponse,
                //                 loading: false,
                //             });
                //         }
                //     }
                // }
                if (apiRequestCallId == this.getAllAuthorId) {
                    if (responseJson && !responseJson.error && responseJson.accounts) {
                        if (responseJson.accounts.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                authorLoading: false,
                                // loading: false,
                                authorList: [],
                                // getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false,
                                authorList: responseJson?.accounts,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                // getCategoryLoading: false,
                                authorLoading: false
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getAllCategoryId) {
                    if (responseJson && !responseJson.error && responseJson.data) {
                        if (responseJson.data.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                // loading: false,
                                categoryList: [],
                                getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                getCategoryLoading: false,
                                categoryList: responseJson?.data,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                getCategoryLoading: false,
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getCoInstructorId) {
                    if (responseJson && !responseJson.error && responseJson.data) {
                        if (responseJson.data.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                // loading: false,
                                coInstructorList: [],
                                getCoinstructorLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                getCoinstructorLoading: false,
                                coInstructorList: responseJson?.data,
                            }, () => {
                                this.setState({
                                    coInstructorList: responseJson?.data,
                                })
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                getCoinstructorLoading: false,
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                getCoinstructorLoading: false,
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.getMainInstructorId) {
                    if (responseJson && !responseJson.error && responseJson.accounts) {
                        if (responseJson.accounts.length === 0) {
                            this.setState({
                                errorMsg: "Data Not Found",
                                getMainnstructorLoading: false,
                                // loading: false,
                                mainInstructorList: [],
                                // getCategoryLoading: false,
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                errorMsg: "",
                                // loading: false,
                                // getCategoryLoading: false,
                                getMainnstructorLoading: false,
                                mainInstructorList: responseJson?.accounts,
                            });
                        }
                    } else {
                        if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                // loading: false,
                                // getCategoryLoading: false,
                                getMainnstructorLoading: false
                            });
                        } else {
                            this.setState({
                                errorMsg: errorReponse,
                                // loading: false,
                                // getCategoryLoading: false,
                                getMainnstructorLoading: false
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.createCourseId) {
                    if (responseJson && !responseJson.error) {
                        if (responseJson.data.type !== "error") {
                            this.setState({
                                ...this.state,
                                dataCreationMsg: "Course created successfully!",
                                // loading: false,
                                openAlert: true,
                                errorMsg: "",
                                setPath: false,
                                nextPage: false,
                                courseTitle: '',
                                courseDesc: '',
                                createLoading: false
                                // categoryList: responseJson?.data,
                            });
                        }
                        else {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "Please fill required data",
                                errorMsg: "",
                                createLoading: false,
                                setPath: false
                            })
                        }

                    } else {
                        if (responseJson.error) {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "",
                                errorMsg: "Internal Server Error",
                                createLoading: false,
                                setPath: false
                            })
                        }
                        else if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                dataCreationMsg: "",
                                openAlert: true,
                                createLoading: false,
                                setPath: false
                                // loading: false,
                            });
                        } else {
                            this.setState({
                                openAlert: true,
                                errorMsg: errorReponse,
                                dataCreationMsg: "",
                                createLoading: false,
                                setPath: false
                                // loading: false,
                            });
                        }
                    }
                }
                if (apiRequestCallId == this.addInstructorId) {
                    if (responseJson && !responseJson.error) {
                        if (responseJson.data.type !== "error") {
                            this.setState({
                                ...this.state,
                                dataCreationMsg: "Contributor added successfully!",
                                // loading: false,
                                openAlert: true,
                                setPath: true,
                                errorMsg: "",
                                addInstructorAbout: '',
                                addInstructorName: '',
                                addInstructorProfile: '',
                                enableInstructorForm: false,
                                instructorAddLoading: false
                                // categoryList: responseJson?.data,
                            }, () => {
                                this.getCoInstructors();
                            });
                        }
                        else {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "Please fill required data",
                                errorMsg: "",
                                setPath: true
                            })
                        }

                    } else {
                        if (responseJson.error) {
                            this.setState({
                                ...this.state,
                                openAlert: true,
                                dataCreationMsg: "",
                                errorMsg: "Internal Server Error",
                                setPath: true
                            })
                        }
                        else if (errorReponse === undefined) {
                            this.setState({
                                errorMsg: "Something went wrong",
                                dataCreationMsg: "",
                                openAlert: true,
                                setPath: true
                                // loading: false,
                            });
                        } else {
                            this.setState({
                                openAlert: true,
                                errorMsg: errorReponse,
                                dataCreationMsg: "",
                                setPath: true
                                // loading: false,
                            });
                        }
                    }
                }
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.getCategory()
        this.getAuthorList()
        this.getCoInstructors()
        this.getMainInstructors()
    }

    // Customizable Area End

    // Customizable Area Start

    handleSelectOthers = (e: any) => {
        this.setState({
            othersName: e?.target?.value
        })
    }

    validateQ = (lesson: any, error: any) => {
        lesson?.questions_attributes?.map((qItem: any) => {
            if(!qItem.question){
                error.question = "Please provide question"
            }
            if(!qItem.description){
                error.description = "Please provide question subheading"
            }
            if(!qItem.option1){
                error.option1 = "Please provide option answer"
            }
            if(!qItem.option2){
                error.option2 = "Please provide option answer"
            }
            if(!qItem.option3){
                error.option3 = "Please provide option answer"
            }
            if(!qItem.option4){
                error.option4 = "Please provide option answer"
            }
            if(!qItem.correct_answer){
                error.correctAnswer = "Please provide correct answer"
            }
        }) 
    }

    validateFaq = (lesson: any, error: any) => {
        lesson?.faqs?.length && lesson?.faqs.map((fItem: any) => {
            if(!fItem.question){
                error.faqQuestion = "Please provide question"
            }
            if(!fItem.answer){
                error.faqAnswer = "Please provide answer"
            }
        })
    }

    validateTodo = (lesson: any, error: any) => {
        lesson?.todos?.length && lesson.todos.map((tItem: any) => {
            if(!tItem.title){
                error.todoTitle = "Please provide title"
            }
            if(!tItem.description){
                error.todoDesc = "Please provide description"
            }
        })
    }

    validateTrans = (lesson: any, error: any) => {
        lesson?.transcript?.length && lesson.transcript.map((tItem: any) => {
            if(!tItem.time){
                error.tTime = "Time*"
            }
            if(!tItem.value){
                error.tDescription = "Please provide transcript"
            }
        }) 
    }

    validateStamp = (lesson: any, error: any) => {
        lesson?.timestamp && lesson.timestamp.map((sItem: any) => {
            if(!sItem.time){
                error.sTime = "Time*"
            }
            if(!sItem.value){
                error.sDescription = "Please provide timestamp"
            }
        }) 
    }

    validateLMate = (lesson: any, error: any) => {
        lesson.material_details_attributes && lesson.material_details_attributes.map((mItem: any) => {
            if(!mItem.title){
                error.mTitle = "Please provide material title"
            }
            if(!mItem.summary){
                error.mSummary = "Please provide material summary"
            }
            if(!mItem.description){
                error.mDescription = "Please provide material description"
            }
            if(!mItem.material_file){
                error.mPic = "Please provide material image"
            }
        })
    }

    fieldValidation = () => {
        const { 
            courseTitle, 
            courseDesc,
            selectedMainInstructor,
            selectecCategory,
            coInstructorName,
            lessonList,
            bgImage,
            previewImg,
            previewVideo
        } = this.state
        let error: any = {}
        if (!courseTitle) {
            error.courseTitle = "Please Provide Title"
        }
        if (!courseDesc) {
            error.courseDesc = "Please Provide Course Description"
        }
        if (!selectedMainInstructor) {
            error.instructor = "Please select instructor"
        }
        if (!coInstructorName) {
            error.coInstructor = "Please select co-instructor"
        }
        if(!selectecCategory){
            this.setState({
                openSetting: true,
                catValidation: "Please select category"
            })
        }
        if(!bgImage) {
            this.setState({
                openSetting: true,
                errorMsg: 'Please upload Cover image'
            })
            error.bgImage = 'Please upload Cover image'
        }
        if(!previewImg) {
            error.previewImg = "Please upload preview image"
        }
        if(!previewVideo) {
            error.previewVideo = "Please upload preview video"
        }
        lessonList.map((lesson: any) => {
            if(!lesson.title){
                error.lessonTitle = "Please provide lesson title"
            }
            if(!lesson.description){
                error.lessonDescription = "Please provide lesson description"
            }
            if(!lesson.video){
                error.lessonVideo = "Please upload a video"
            }
            
            this.validateQ(lesson, error);

            this.validateFaq(lesson, error);
            this.validateTodo(lesson, error);

            this.validateTrans(lesson, error);

            this.validateStamp(lesson, error);
            
            this.validateLMate(lesson, error);
        });
        
        if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
            this.setState({ inputError: {} })
            this.createCourse()
        }
        else {
            this.setState({ inputError: error })
        }
    }

    addPreviewPic = (file: any) => {
        this.setState({
            previewImg: file
        })
    }

    addPreviewVideo = (file: any) => {
        this.setState({
            previewVideo: file
        })
    }
    // istanbul ignore next
    createCourse = () => {
        if(!this.state.selectecCategory){
            return
        }
        this.setState({
            createLoading: true
        })
        const {
            courseTitle,
            courseDesc,
            selectedMainInstructor,
            coInstructorName,
            status,
            sort,
            dateTime,
            selectecCategory,
            lessonList,
            coursePrice,
            previewImg,
            previewVideo,
            bgImage,
            othersName
        } = this.state;
            
        const formData = new FormData();
        const header = {
            // "Content-Type": "multipart/form-data",
            token: typeof window !== 'undefined' ? localStorage.getItem("token") : null
        };
        
        formData.append('course[title]', courseTitle);
        if(!coursePrice){
            // @ts-ignore
            formData.append('course[free]', true);
        } else {
            // @ts-ignore
            formData.append('course[free]', false);
            formData.append('course[price]', coursePrice);
        }
        let blobPriviewImg = new Blob(previewImg);
        let blobPriviewVideo = new Blob(previewVideo);
        let blobBgImage = new Blob(bgImage);
        formData.append('course[preview_image]', blobPriviewImg);
        formData.append('course[background_image]', blobBgImage);
        formData.append('course[preview_video]', blobPriviewVideo);
        formData.append('course[about]', courseDesc);
        formData.append('course[instructor_id]', selectedMainInstructor);
        othersName.map((id: any) => {
            return (
                formData.append('course[tag_others][]', id)
            )
        })
        coInstructorName.map((id: any) => {
            return (
                formData.append('course[local_instructor_ids][]', id)
            )
        })
        lessonList.map((item: any) => {
            let blobVideo = new Blob(item.video);
            return (
                formData.append('course[lectures_attributes][][title]', item.title),
                formData.append('course[lectures_attributes][][description]', item.description),
                formData.append('course[lectures_attributes][][video]', blobVideo),
                item.faqs.length && item.faqs.map((fItem: any) => {
                    return (
                        formData.append("course[lectures_attributes][][faqs_attributes][][question]", fItem.question),
                        formData.append("course[lectures_attributes][][faqs_attributes][][answer]", fItem.answer)
                    )
                }),
                item.todos.length && item.todos.map((tItem: any) => {
                    return (
                        formData.append("course[lectures_attributes][][todos_attributes][][title]", tItem.title),
                        formData.append("course[lectures_attributes][][todos_attributes][][description]", tItem.description)
                    )
                }),
                item.questions_attributes.length && item.questions_attributes.map((qItem: any)=>{
                    return(
                        formData.append("course[lectures_attributes][][questions_attributes][][question]", qItem.question),
                        formData.append("course[lectures_attributes][][questions_attributes][][description]", qItem.description),
                        formData.append("course[lectures_attributes][][questions_attributes][][correct_answer]", qItem.correct_answer),
                        formData.append("course[lectures_attributes][][questions_attributes][][option1]", qItem.option1),
                        formData.append("course[lectures_attributes][][questions_attributes][][option2]", qItem.option2),
                        formData.append("course[lectures_attributes][][questions_attributes][][option3]", qItem.option3),
                        formData.append("course[lectures_attributes][][questions_attributes][][option4]", qItem.option4)
                    )
                }),
                item.material_details_attributes.length && item.material_details_attributes.map((mItem: any, i: any)=>{
                    let blobImg = new Blob(mItem.material_file)
                    return(
                        formData.append("course[lectures_attributes][][material_details_attributes][][title]", mItem.title),
                        formData.append("course[lectures_attributes][][material_details_attributes][][description]", mItem.description),
                        formData.append("course[lectures_attributes][][material_details_attributes][][summary]", mItem.summary),
                        formData.append("course[lectures_attributes][][material_details_attributes][][material_file]", blobImg)
                    )
                }),
                item.transcript.length && item.transcript.map((tItem: any, i: any)=>{
                    return(
                        formData.append("course[lectures_attributes][][transcript][][time]", tItem.time),
                        formData.append("course[lectures_attributes][][transcript][][value]", tItem.value)
                    )
                }),
                item.timestamp.length && item.timestamp.map((tItem: any, i: any)=>{
                    return(
                        formData.append("course[lectures_attributes][][timestamp][][time]", tItem.time),
                        formData.append("course[lectures_attributes][][timestamp][][value]", tItem.value)
                    )
                })
            )
        })
        formData.append('course[status]', status == 'draft' ? status :  status == "published" && sort.publish == "schedule_post" ? 'scheduled' : status)
        formData.append('course[publish_timing]', sort.publish)
        formData.append('course[visibility]', sort.visibility)
        // @ts-ignore
        formData.append('course[scheduled_date]', dateTime.date)
        // @ts-ignore
        formData.append('course[scheduled_time]', dateTime.time)
        formData.append('course[category_id]', selectecCategory)
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createCourseId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createCourseApi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData

        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getBase64(file: any, cb: any) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    //   handleSortingLoader = (type: string, sort: string, page: number, perPage?: number) => {

    //     this.setState({ sortingLoader: true, currentPage: page, type: type })
    //     if(sort){
    //       this.setState({sorting: sort})
    //     }
    //     const userToken = localStorage.getItem("token");
    //     const header = {
    //       "Content-Type": configJSON.contentType,
    //       token: userToken,
    //     };

    //     const requestMessage = new Message(
    //       getName(MessageEnum.RestAPIRequestMessage)
    //     );

    //     this.apiGetDataCallId = requestMessage.messageId;
    //     const hitURL = `${configJSON.letsReadGetUrl}?type=${type}&page=${page}&per_page=${ perPage ? perPage : this.state.blogPerPage}&sort=${ sort ? sort : this.state.sorting}`
    //     requestMessage.addData(
    //       getName(MessageEnum.RestAPIResponceEndPointMessage),
    //       hitURL
    //     );

    //     requestMessage.addData(
    //       getName(MessageEnum.RestAPIRequestHeaderMessage),
    //       JSON.stringify(header)
    //     );

    //     requestMessage.addData(
    //       getName(MessageEnum.RestAPIRequestMethodMessage),
    //       configJSON.dashboarApiMethodType
    //     );

    //     runEngine.sendMessage(requestMessage.id, requestMessage);
    //   } 

    //   handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //     this.setState({ anchorEl: event.currentTarget });
    //   };
    //   handleClose = () => {
    //     this.setState({ anchorEl: null });
    //   };

    //   handleModalOpen = () => {
    //     this.setState({modalOpen: true})
    //   }
    //   handleModalClose = () => {
    //     this.setState({modalOpen: false})
    //   }

    getCategory = () => {
        this.setState({ getCategoryLoading: true })
        const userToken = typeof window !== 'undefined' ? localStorage.getItem("token") : null;
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllCategoryId = requestMessage.messageId;
        const hitURL = `${configJSON.categoryUrl}?type=course_category`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            hitURL
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCoInstructors = () => {
        this.setState({ getCoinstructorLoading: true })
        const userToken = typeof window !== 'undefined' ? localStorage.getItem("token") : null;
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCoInstructorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCoInstructorApi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getMainInstructors = () => {
        this.setState({ getMainnstructorLoading: true })
        const userToken = typeof window !== 'undefined' ? localStorage.getItem("token") : null;
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getMainInstructorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAuthorUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    addInstructor = () => {
        if(!this.state.addInstructorName){
            let error: any = {};
            error.addInsName = 'Please provide instructor name!';
            this.setState({
                inputError: error
            })
            return;
        }
        if(!this.state.addInstructorProfile){
            let error: any = {};
            error.addProfilePic = 'Please upload a profile picture!';
            this.setState({
                inputError: error
            })
            return;
        }
        if(!this.state.addInstructorAbout){
            let error: any = {};
            error.addInsAbout = 'Please provide instructor about!';
            this.setState({
                inputError: error
            })
            return;
        }
        this.setState({ 
            instructorAddLoading: true,
        })
        const userToken = localStorage.getItem("token");
        const formData = new FormData();
        formData.append('instructor[name]', this.state.addInstructorName)
        formData.append('instructor[about]', this.state.addInstructorAbout)
        formData.append('instructor[profile_image]', this.state.addInstructorProfile)

        const header = {
            // "Content-Type": "multipart/form-data",
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addInstructorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addInstructorApi
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getAuthorList = () => {
        this.setState({ getCategoryLoading: true })
        const userToken = typeof window !== 'undefined' ? window.localStorage.getItem("token") : null;
        const header = {
            "Content-Type": configJSON.contentType,
            token: userToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllAuthorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAuthorUrl
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    addNewBlog = () => {
        this.setState((prev_state) => {
            return {
                blogList: [...prev_state.blogList, {
                    id: new Date().getTime(),
                    dataBaseId: null,
                    img: [],
                    base64Img: [],
                    heading: {
                        text: "",
                        style: {}
                    },
                    subheading: {
                        text: "",
                        style: {}
                    },
                    para: {
                        text: "",
                        style: {}
                    },
                    caption: {
                        text: "",
                        style: {}
                    },
                }]
            }
        })
    }

    removeBlog = (id: number) => {
        const _newblogList = this.state.blogList.filter(blg => blg.id !== id)
        this.setState({ blogList: _newblogList })
    }

    handleBlogUniqueItemChange = (event: any, name: string) => {
        const { value } = event.target;
        this.setState({
            blogUniqueItem: {
                ...this.state.blogUniqueItem,
                [name]: value
            }
        });
    };

    handleInstructorChange = (event: any, name: string) => {
        const { value } = event.target;
        if(name == 'addInstructorName'){
            this.setState({
                addInstructorName: value
            });
        } else {
            this.setState({
                addInstructorAbout: value
            });
        }
    };

    addInstructorProfilePic = (acceptedFiles: any) => {
        this.setState({
            addInstructorProfile: acceptedFiles[0]    
        });
    }

    handleBgImgChange = (value: any[], files: any[]) => {
        this.setState({
            bgImage: files
        });
    }

    handleLessonChange = (id: number, e: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === id) {
                return {
                    ...lesson,
                    [e.target.name]: e.target.value
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    addLessonVideo = (id: any, acceptedFiles: any) => {
        const { lessonList } = this.state;
        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === id) {
                return {
                    ...lesson,
                    video: acceptedFiles
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    handleQuestionChange = (id: number, e: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.length && lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    questions_attributes: lesson.questions_attributes && lesson.questions_attributes.map((q: any) => {
                        if(q.id == id){
                            return {
                                ...q,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return q
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    handleFaqChange = (id: number, e: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    faqs: lesson.faqs.map((q: any) => {
                        if(q.id == id){
                            return {
                                ...q,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return q
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList }, () => {console.log(this.state.lessonList)})
    }

    handleTodoChange = (id: number, e: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    todos: lesson.todos.map((q: any) => {
                        if(q.id == id){
                            return {
                                ...q,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return q
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    handleTransChange = (id: number, e: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    transcript: lesson.transcript.map((q: any) => {
                        if(q.id == id){
                            return {
                                ...q,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return q
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    handleStampChange = (id: number, e: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    timestamp: lesson?.timestamp && lesson.timestamp.map((q: any) => {
                        if(q.id == id){
                            return {
                                ...q,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return q
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    setStampChange = (lesson: any, e: any, pId: any, id: any, error: any) => {
        if(lesson.video){
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    timestamp: lesson.timestamp.map((q: any) => {
                        if(q.id == id){
                            return {
                                ...q,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return q
                        }
                    })
                }
            } else {
                return lesson;
            }
        } else {
            error.sDescription = "Please upload lesson video first!";
        }
    }

    handleMaterialChange = (id: number, e: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    material_details_attributes: lesson.material_details_attributes.map((m: any) => {
                        if(m.id == id){
                            return {
                                ...m,
                                [e.target.name]: e.target.value
                            }
                        } else {
                            return m
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    addLessonMaterialImage = (id: number, value: any, pId: any) => {
        const { lessonList } = this.state;

        const newLessonList = lessonList.map((lesson: any) => {
            if (lesson.id === pId) {
                return {
                    ...lesson,
                    material_details_attributes: lesson.material_details_attributes.map((m: any) => {
                        if(m.id == id){
                            return {
                                ...m,
                                'material_file': value
                            }
                        } else {
                            return m
                        }
                    })
                }
            } else {
                return lesson;
            }
        })

        this.setState({ lessonList: newLessonList })
    }

    handleSubheadingChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    subheading: {
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleParagraphChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    para: {
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleImgChange = (id: number, value: any[], files: any[]) => {
        const { blogList } = this.state;
        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    img: [...blg.img, ...value],
                    base64Img: [...blg.base64Img, ...files]
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleCaptionChange = (id: number, value: string) => {
        const { blogList } = this.state;

        const _newblogList = blogList.map((blg) => {
            if (blg.id === id) {
                return {
                    ...blg,
                    caption: {
                        text: value,
                        style: {

                        }
                    }
                }
            } else {
                return blg;
            }
        })

        this.setState({ blogList: _newblogList })
    }

    handleCategorySelect = (id: string) => {
        this.setState({ selectecCategory: id })
    }

    hanldeSettingToggle = () => {
        this.setState({ openSetting: !this.state.openSetting })
    }

    handleSortChange = (event: any, name: string) => {
        const { value } = event.target;
        this.setState({
            sort: {
                ...this.state.sort,
                [name]: value
            }
        });
    };

    handleDateTimeChange = (value: Date, name: string) => {
        this.setState({
            dateTime: {
                ...this.state.dateTime,
                [name]: value,
            }
        })
    }

    handleDraft = () => {
        this.setState((prev_stat) => {
            return {
                ...prev_stat,
                status: "draft"
            }
        }, () => this.fieldValidation())
    }

    handlePublish = () => {
        this.setState((prev_stat) => {
            return {
                ...prev_stat,
                status: "published"
            }
        }, () => this.fieldValidation())
    }

    closeAlertBox = () => {
        this.setState({
            openAlert: false
        });
    };

    handlePreviewOpen = () => {
        this.setState({ previewModal: true })
    }

    handleModalClose = () => {
        this.setState({ previewModal: false })
    }

    // Customizable Area End

}
