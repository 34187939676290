import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  videoPodcastData: any;
  audioPodcastData: any;
  carousalData: any;
  errorMsg: any;
  loading: boolean;
  catId: any;
  allPodcasts: boolean;
  podcastFilterOptionsData: any;
  carausalPlay: boolean;
  carausalId: any;
  vErrorMsg: any;
  vLoading: any;
  screenWidth: any;
  otherPodcast: any;
  openAlert: boolean;
  updateMessage: any;
  isFavourite: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GalleryController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetPodcastAudioListApiId: string = "";
  GetPodcastVideoListApiId: string = "";
  GetPodcastFilterListApiId: string = "";
  GetCarousalListApiId: string = "";
  apiMakeFavouriteId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      videoPodcastData: {},
      audioPodcastData: {},
      podcastFilterOptionsData: {},
      carousalData: {},
      errorMsg: false,
      loading: false,
      catId: localStorage.getItem('category_id'),
      allPodcasts: true,
      // Customizable Area Start
      carausalPlay: false,
      carausalId: '',
      vErrorMsg: false,
      vLoading: '',
      screenWidth: window.innerWidth,
      otherPodcast: [],
      openAlert: false,
      updateMessage: '',
      isFavourite: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    window.addEventListener('resize', this.updateDimensions)
    this.getPodcastFilterList();
    if(this.state.catId){
      this.getPodcastVideosList(this.state.catId);
      this.getPodcastAudioList(this.state.catId);
    } else {
      this.getPodcastVideosList('');
      this.getPodcastAudioList('');
    }
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener('resize', this.updateDimensions)
    localStorage.removeItem('category_id');
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        // Response of video podcast listing api
        if (apiRequestCallId === this.GetPodcastVideoListApiId) {
          if(responseJson && !responseJson.error){
            if(responseJson.data.podcasts.data.length){
              this.setState({
                videoPodcastData: responseJson.data.podcasts.data.slice(0, 12),
                carousalData: responseJson.data.carousel_podcasts,
                otherPodcast: responseJson.data.featured_podcasts.slice(0,4)
              }, () => {
                this.setState({
                  vLoading: false,
                  vErrorMsg: false
                })
              })
            } else {
              this.setState({
                vErrorMsg: true,
                vLoading: false,
                videoPodcastData: []
              });
            }
          }
        }

        if(this.apiMakeFavouriteId === apiRequestCallId){
          this.setFav(responseJson)
        }

        // Response of audio podcast listing api
        if (apiRequestCallId === this.GetPodcastAudioListApiId) {
          if(responseJson && !responseJson.error){
            if(!responseJson.data.podcasts.data.length){
              this.setState({
                errorMsg: true,
                loading: false,
                audioPodcastData: []
              });
            } else {
              this.setState({
                audioPodcastData: responseJson.data.podcasts.data.slice(0, 10)
              }, () => {
                this.setState({
                  loading: false,
                  errorMsg: false
                })
              })
            }
          }
        }

        // Response of podcast filter listing api
        if (apiRequestCallId === this.GetPodcastFilterListApiId) {
          if(responseJson && !responseJson.error){
            if(!responseJson.data.length){
              this.setState({
                errorMsg: "Data Not Found"
              });
            } else {
              this.setState({
                podcastFilterOptionsData: responseJson.data
              }, () => {
                this.setState({
                  podcastFilterOptionsData: responseJson.data
                })
              })
            }
          }
        }
      }
    }
    // Customizable Area End

  }

  getAudio = (id: any) => {
    this.props.navigation.navigate("AudioMusic", {id:id})
  }

  getVideo = (id: any) => {
    this.props.navigation.navigate("VideoManagement", {id:id})
  }

  getAllPodcasts = () => {
    this.setState({
      allPodcasts: true,
      catId: '',
      videoPodcastData: {},
      loading: true,
      vLoading: true,
      vErrorMsg: false,
      errorMsg: false,
      audioPodcastData: {},
    })
    // localStorage.removeItem('category_id');
    this.getPodcastVideosList('');
    this.getPodcastAudioList('');
  }

  getFilteredPodcast = (catId: any) => {
    // localStorage.setItem('category_id', catId);
    this.setState({
      catId: catId,
      videoPodcastData: {},
      loading: true,
      vLoading: true,
      vErrorMsg: false,
      errorMsg: false,
      audioPodcastData: {}
    })
    this.getPodcastAudioList(catId);
    this.getPodcastVideosList(catId);
  }

  getPodcastFilterList () {
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetPodcastFilterListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPodcastCategoryOptionsApiEndPoint+"?type=podcast_category"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    // return true
  }

  getPodcastAudioList (catId: any) {
    this.setState({loading: true})
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    let header: any = ''

    if(token){
      header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem('token')
      };
    } else {
      header = {
        "Content-Type": configJSON.validationApiContentType
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetPodcastAudioListApiId = requestMessage.messageId;

    if(!catId){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPodcastVideoListApiEndPoint + "?type=audio&page=1&per_page=10"
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPodcastVideoListApiEndPoint + "?type=audio&page=1&per_page=10&category_id=" + catId
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    // return true
  }

  getPodcastVideosList (catId: any) {
    this.setState({vLoading: true})
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    let header: any = ''

    if(token){
      header = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem('token')
      };
    } else {
      header = {
        "Content-Type": configJSON.validationApiContentType
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetPodcastVideoListApiId = requestMessage.messageId;

    if(!catId){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPodcastVideoListApiEndPoint + "?type=video&page=1&per_page=12"
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPodcastVideoListApiEndPoint + "?type=video&page=1&per_page=12&category_id=" + catId
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    // return true
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start

  setFav = (responseJson: any) => {
    if(responseJson.message === "Favourite Updated!"){
      this.setState({
        loading: false,
        updateMessage: this.state.isFavourite ? 'Added to your favourites!' : 'Removed from your favourites!',
        openAlert: true
      }, () => {
        if(this.state.catId){
          this.getPodcastVideosList(this.state.catId);
          this.getPodcastAudioList(this.state.catId);
        } else {
          this.getPodcastVideosList('');
          this.getPodcastAudioList('');
        }
      })
    } else {
      this.setState({
        loading: false,
        updateMessage: 'Something went wrong!',
        openAlert: true
      })
    }
  }

  makeFavourite = (id: any, status: any) => {
    this.updateFavourite(id, status)
    this.setState({
      isFavourite: status
    })
  }

  updateFavourite = (id: any, status: any) => {
    this.setState({
      loading: true
    })
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken
    };

    const data = {
      type: 'BxBlockVideoManagement::VideoPodcast' ,
      id: id,
      favourite: status
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMakeFavouriteId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateFavouriteApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateDimensions = () => this.setState({screenWidth: window.innerWidth})
  // Customizable Area End
  
}
