import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tabValue: any;
  catId: any;
  categoryList: any;
  windowWidth: any;
  peopleList: any;
  loadingPeople: boolean;
  bookList: any;
  orgList: any;
  filmList: any;
  peopleMetaData: any;
  orgMetaData: any;
  filmMetaData: any;
  bookMetaData: any;
  isFavourite: boolean;
  loading: boolean;
  updateMessage: any;
  openAlert: boolean;
  favType: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommunityForumController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetCategoryApiId: string = "";
  GetPeopleApiId: string = "";
  GetBookApiId: string = "";
  GetOrgApiId: string = "";
  GetFilmApiId: string = "";
  apiMakeFavouriteId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabValue: 0,
      catId: '',
      categoryList: '',
      windowWidth: window.innerWidth,
      peopleList: '',
      loadingPeople: false,
      bookList: '',
      orgList: '',
      filmList: '',
      peopleMetaData: '',
      orgMetaData: '',
      filmMetaData: '',
      bookMetaData: '',
      isFavourite: false,
      loading: false,
      updateMessage: '',
      openAlert: false,
      favType: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        this.setAllData(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getCategoriesList('person_category')
    this.getPeopleList('', 1, '')
  }

  setAllData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.GetCategoryApiId) {
      this.setCategoryList(responseJson)
    }
    if(apiRequestCallId === this.GetPeopleApiId){
      this.setPeopleList(responseJson)
    }
    if(apiRequestCallId === this.GetBookApiId){
      this.setBookList(responseJson)
    }
    if(apiRequestCallId === this.GetOrgApiId){
      this.setOrgList(responseJson)
    }
    if(apiRequestCallId === this.GetFilmApiId){
      this.setFilmList(responseJson)
    }
    if(this.apiMakeFavouriteId === apiRequestCallId){
      this.setFav(responseJson)
    }
  }

  setFav = (responseJson: any) => {
    if(responseJson.message === "Favourite Updated!"){
      this.setState({
        loading: false,
        updateMessage: this.state.isFavourite ? 'Added to your favourites!' : 'Removed from your favourites!',
        openAlert: true
      }, () => {
        if(this.state.favType === 'people'){
          this.getPeopleList('', 1, '')
        } else if(this.state.favType === 'books'){
          this.getBookList('', 1, '')
        } else if(this.state.favType === 'films'){
          this.getFilmList('', 1, '')
        } else {
          this.getOrgList('', 1, '')
        }
      })
    } else {
      this.setState({
        loading: false,
        updateMessage: 'Something went wrong!',
        openAlert: true
      })
    }
  }

  makeFavourite = (id: any, status: any, type: any) => {
    this.updateFavourite(id, status, type)
    this.setState({
      isFavourite: status,
      favType: type
    })
  }

  getType = (type: any) => {
    if(type === 'people'){
      return 'BxBlockDashboard::Person'
    } else if(type === 'books'){
      return 'BxBlockDashboard::Book'
    } else if(type === 'films'){
      return 'BxBlockDashboard::Film'
    }
    return 'BxBlockDashboard::Organisation'
  }

  updateFavourite = (id: any, status: any, type: any) => {
    this.setState({
      loading: true
    })
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: userToken
    };

    const data = {
      type: this.getType(type),
      id: id,
      favourite: status
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMakeFavouriteId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateFavouriteApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setCategoryList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        categoryList: responseJson.data
      });
    }
  }

  setPeopleList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        peopleList: responseJson.data,
        loadingPeople: false,
        peopleMetaData: responseJson.meta.pagination
      });
    } else {
      this.setState({
        loadingPeople: false
      });
    }
  }

  setBookList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        bookList: responseJson.data,
        loadingPeople: false,
        bookMetaData: responseJson.meta.pagination
      });
    } else {
      this.setState({
        loadingPeople: false
      });
    }
  }

  setOrgList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        orgList: responseJson.data,
        loadingPeople: false,
        orgMetaData: responseJson.meta.pagination
      });
    } else {
      this.setState({
        loadingPeople: false
      });
    }
  }

  setFilmList = (responseJson: any) => {
    if (responseJson && !responseJson.error && responseJson.data) {
      this.setState({
        filmList: responseJson.data,
        loadingPeople: false,
        filmMetaData: responseJson.meta.pagination
      });
    } else {
      this.setState({
        loadingPeople: false
      });
    }
  }

  getFilteredPeopleList = (catId: any) => {
    this.setState({
      catId: catId
    }, () => {
      this.getPeopleList(this.state.catId, 1, '');
    })
  }

  getFilteredBookList = (catId: any) => {
    this.setState({
      catId: catId
    }, () => {
      this.getBookList(this.state.catId, 1, '');
    })
  }

  getFilteredOrgList = (catId: any) => {
    this.setState({
      catId: catId
    }, () => {
      this.getOrgList(this.state.catId, 1, '')
    })
  }

  getFilteredFilmList = (catId: any) => {
    this.setState({
      catId: catId
    }, () => {
      this.getFilmList(this.state.catId, 1, '')
    })
  }

  getCategoriesList = (type: any) => {
    this.setState({
      loadingPeople: true
    });
    const userToken = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetCategoryApiId = requestMessage.messageId;
    const hitURL = `${configJSON.categoryUrl}?type=${type}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hitURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPeopleList = (catId: any, page: any, searchQuery: any) => {
    const header = {
      "Content-Type": configJSON.contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetPeopleApiId = requestMessage.messageId;

    if(catId){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPeopleListApi+`?category_id=${catId}&page=${page}&per_page=10`
      );
    }
    if(searchQuery){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPeopleListApi+`?page=${page}&per_page=10&search=${searchQuery}`
      );
    }

    if(catId && searchQuery){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPeopleListApi+`?category_id=${catId}&page=${page}&per_page=10&search=${searchQuery}`
      );
    }
    if(!catId && !searchQuery){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPeopleListApi+`?page=${page}&per_page=10`
      );
    }
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBookList = (catId: any, page: any, searchQuery: any) => {
    const header = {
      "Content-Type": configJSON.contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetBookApiId = requestMessage.messageId;
    if(catId){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getBookListApi+`?category_id=${catId}&page=${page}&per_page=10`
      );
    }
    if(searchQuery){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getBookListApi+`?page=${page}&per_page=10&search=${searchQuery}`
      );
    }

    if(catId && searchQuery){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getBookListApi+`?category_id=${catId}&page=${page}&per_page=10&search=${searchQuery}`
      );
    }
    if(!catId && !searchQuery){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getBookListApi+`?page=${page}&per_page=10`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOrgList = (catId: any, page: any, searchQuery: any) => {
    const header = {
      "Content-Type": configJSON.contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetOrgApiId = requestMessage.messageId;
    if(catId){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getOrgListApi+`?category_id=${catId}&page=${page}&per_page=10`
      );
    }
    if(searchQuery){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getOrgListApi+`?page=${page}&per_page=10&search=${searchQuery}`
      );
    }

    if(catId && searchQuery){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getOrgListApi+`?category_id=${catId}&page=${page}&per_page=10&search=${searchQuery}`
      );
    }
    if(!catId && !searchQuery){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getOrgListApi+`?page=${page}&per_page=10`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFilmList = (catId: any, page: any, searchQuery: any) => {
    const header = {
      "Content-Type": configJSON.contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetFilmApiId = requestMessage.messageId;
    if(catId){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getFilmListApi+`?category_id=${catId}&page=${page}&per_page=10`
      );
    }
    if(searchQuery){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getFilmListApi+`?page=${page}&per_page=10&search=${searchQuery}`
      );
    }

    if(catId && searchQuery){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getFilmListApi+`?category_id=${catId}&page=${page}&per_page=10&search=${searchQuery}`
      );
    }
    if(!catId && !searchQuery){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getFilmListApi+`?page=${page}&per_page=10`
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabValue: newValue
    })
  };

  handleSearchChange = (e: any, type: any) => {
    if(type === 'people'){
      this.getPeopleList(this.state.catId, 1, e.target.value);
    } else if(type === 'organization'){
      this.getOrgList(this.state.catId, 1, e.target.value);
    } else if(type == 'book'){
      this.getBookList(this.state.catId, 1, e.target?.value);
    } else {
      this.getFilmList(this.state.catId, 1, e.target?.value);
    }
  }

  getAllPeople = () => {
    this.getPeopleList('', 1, '')
    this.setState({
      catId: ''
    })
  }

  getAllBook = () => {
    this.getBookList('', 1, '')
    this.setState({catId: ''})
  }

  getAllOrg = () => {
    this.getOrgList('', 1, '')
    this.setState({catId: ''})
  }

  getAllFilm = () => {
    this.getFilmList('', 1, '')
    this.setState({catId: ''})
  }

  getBookData = () => {
    this.getAllBook();
    this.getCategoriesList('book_category')
  }

  getFilmData = () => {
    this.getAllFilm();
    this.getCategoriesList('film_category')
  }

  getOrgData = () => {
    this.getAllOrg();
    this.getCategoriesList('organisation_category')
  }

  getPeopleData = () => {
    this.getAllPeople();
    this.getCategoriesList('person_category')
  }

  changePeoplePage = (e: any, page: any) => {
    console.log(e)
    this.getPeopleList(this.state.catId, page, '')
  }

  changeOrgPage = (e: any, page: any) => {
    console.log(e)
    this.getOrgList(this.state.catId, page, '')
  }

  changeBookPage = (e: any, page: any) => {
    console.log(e)
    this.getBookList(this.state.catId, page, '')
  }

  changeFilmPage = (e: any, page: any) => {
    console.log(e)
    this.getFilmList(this.state.catId, page, '')
  }

  goToPersonDetails = (pId: any) => {
    this.props.navigation.navigate('CustomisableUserProfiles', {id:pId})
  }


  updateDimensions = () => this.setState({windowWidth: window.innerWidth})

  clickAllType = (type: any) => {
    if(type === 'peoples'){
      this.getAllPeople()
    } else if(type === 'books'){
      this.getAllBook()
    } else if(type === 'organizations'){
      this.getAllOrg()
    } else {
      this.getAllFilm()
    }
  }

  clickIndividualType = (type: any, id: any) => {
    if(type === 'peoples'){
      this.getFilteredPeopleList(id)
    } else if(type === 'books'){
      this.getFilteredBookList(id)
    } else if(type === 'organizations'){
      this.getFilteredOrgList(id)
    } else {
      this.getFilteredFilmList(id)
    }
  }
  // Customizable Area End
  
}
