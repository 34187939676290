import React from "react";
import {
    Box,
    Button,
    Grid,
    Typography
} from "@material-ui/core"
import { palette } from "./theme";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import CustomCard from "./CustomCard";
import { Link } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FavouriteIcon from "./FavouriteIcon";




export default function PodCastAudio(props : any) {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        // @ts-ignore
        <Box>
            <Grid container>
                {/* @ts-ignore */}
                <Box
                    style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    {/* @ts-ignore */}
                    <Box>
                        <Typography
                            style={{
                                fontFamily: 'Poppins-SemiBold',
                                fontSize: !matches ? '3.5vw' : '1.8vw',
                            }}
                        >
                            Audio Podcasts
                        </Typography>
                    </Box>
                    
                    {
                        props.showSeeMore ?
                        // @ts-ignore 
                        <Box>
                            <Button
                                style={{
                                    color: palette.primary.main,
                                    fontFamily: 'Poppins-SemiBold',
                                }}
                            >
                                <Link
                                    style={{
                                        textDecoration: 'none',
                                        color: palette.primary.main,
                                        fontFamily: 'Poppins-SemiBold',
                                    }} 
                                    to={'/audiopodcasts'}
                                >
                                    See more
                                </Link>
                            </Button>
                        </Box>
                        : 
                        null
                    }
                </Box>
            </Grid>
            {/* @ts-ignore */}
            <Box mt={5}>
                <Grid container spacing={5}>
                    {
                        props.errorMsg ? 
                            // @ts-ignore
                            <Box 
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flex: 1,
                                    marginBottom: '50px'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins-SemiBold',
                                        color: '#000',
                                        fontSize: '18px'
                                    }}
                                >
                                    No audio podcasts available!
                                </Typography>
                            </Box>
                            : null
                    }
                    {
                        props.loading
                        ? 
                        // @ts-ignore
                        <Box 
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flex: 1,
                                marginBottom: '50px'
                            }}
                        >
                            <Typography
                                style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    color: '#000',
                                    fontSize: '18px'
                                }}
                            >
                                Loading...
                            </Typography>
                        </Box>
                        : null
                    }
                    {
                        
                        props.imgUrls.length ? props.imgUrls.map((item: any, index: any) => {
                            return (
                                <Grid md={6} sm={12} xs={12} item key={index} style={{position: 'relative'}}>
                                    <CustomCard
                                        padding={'10px'}
                                        radius={'5px'}
                                        boxShadow={'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'}
                                    >
                                        <FavouriteIcon 
                                            isFavourite={item.attributes.favourite}
                                            position={'absolute'}
                                            right={'5px'}
                                            top={'5px'}
                                            height={'22px'}
                                            width={'22px'}
                                            makeFavourite={(status: any) => props.makeFavourite(item.id, status)}
                                            navigation={props.navigation}
                                        />
                                        {/* @ts-ignore */}
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {/* @ts-ignore */}
                                            <Box
                                                style={{
                                                    display: 'flex',
                                                    flex: 4,
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        fontFamily: 'Poppins-Light',
                                                        fontSize: '14px',
                                                        color: palette.primary.main
                                                    }}
                                                >
                                                    {item.attributes.category?.name ? item.attributes.category?.name : 'SOCIAL SERVICES'}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontFamily: 'Poppins-Light',
                                                        fontSize: '18px',
                                                        color: '#000',
                                                    }}
                                                    title={item?.attributes?.title}
                                                >
                                                    {item?.attributes?.title.length > 40 ? item?.attributes?.title.substring(0, 40) + "..." : item?.attributes?.title }
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontFamily: 'Poppins-Light',
                                                        fontSize: '12px',
                                                        color: '#8991a4',
                                                        marginTop: '5px'
                                                    }}
                                                    title={item?.attributes?.summary}
                                                >
                                                    {item?.attributes?.summary && item?.attributes?.summary.length > 100 ? item?.attributes?.summary.substring(0, 100) + '...' : item?.attributes?.summary }
                                                </Typography>
                                            </Box>
                                            {/* @ts-ignore */}
                                            <Box 
                                                style={{
                                                    display: 'flex',
                                                    flex: 1
                                                }}
                                            >
                                                {/* @ts-ignore */}
                                                <Box
                                                    style={{
                                                        cursor: 'pointer',
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <img 
                                                        width={'100%'} 
                                                        src={item.attributes.poster ? item.attributes.poster : require('../../blocks/Gallery/assets/audioBg.jpg')} 
                                                        style={{borderRadius: '8px', minHeight: '100px', height: '100px', minWidth: '100px'}} 
                                                    />
                                                    {/* @ts-ignore */}
                                                    <Box 
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)'
                                                        }}
                                                    >
                                                        {/* @ts-ignore */}
                                                        <Box
                                                            style={{
                                                                height: '40px',
                                                                width: '40px',
                                                                borderRadius: '50%',
                                                                background: '#ffffff',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <PlayArrowRoundedIcon 
                                                                style={{
                                                                    color: palette.primary.main,
                                                                    fontSize: 25,
                                                                }}
                                                                onClick={() => props.getAudio(item.id)}
                                                                // fontSize="large"
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </CustomCard>
                                </Grid>
                            )
                        })
                        : null
                    }
                </Grid>
            </Box>
        </Box>
    )
}