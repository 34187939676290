// Customizable Area Start
import React from "react";
import { ProfileContext } from "./ProfileContext";

import ProfileContextController, {Props} from "./ProfileContextController";

class ProfileContextProvider extends ProfileContextController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ProfileContext.Provider
        value={{...this.state, ...this}}>
        {this.props?.children }
      </ProfileContext.Provider>
    );
  }
}

export default ProfileContextProvider;
// Customizable Area End