import React from "react";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    IconButton,
    Toolbar,
    Button,
    AppBar,
    Modal,
    TextField,
    Container,
    Select,
    FormControl,
    MenuItem
} from "@material-ui/core"
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuIcon from '@material-ui/icons/Menu';
import EditPodcastController, { Props } from "./EditPodcastController";
import { palette } from "../../../../components/src/theme";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import DashboardSetting from "../../../../components/src/DashboardSetting";
import AlertModal from "../../../../components/src/AlertModal";
import PreviewFE from "../../../../components/src/PreviewFE";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// @ts-ignore
import PodcastDropZone from '../../../../components/src/PodcastDropZone';
import CustomLoader from "../../../../components/src/CustomLoader";
const drawerWidth = 240;


// Customizable Area End

class EditPodcast extends EditPodcastController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    getBold = (value: any) => {
        this.setState({
            bold: value
        })
    }

    getUnderlined = (value: any) => {
        this.setState({
            underlined: value
        })
    }

    getItalic = (value: any) => {
        this.setState({
            italic: value
        })
    }
    getAlignment = (value: any) => {
        this.setState({
            alignment: value
        })
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        const { classes } = this.props;
        const { 
            mobileOpen, 
            previewModal, 
            errorMsg, 
            openAlert, 
            categoryList, 
            getCategoryLoading, 
            dataCreationMsg, 
            selectecCategory, 
            openSetting, 
            authorList, 
            authorLoading, 
            sort, 
            dateTime, 
            inputError,
            title,
            summary,
            createLoading
        } = this.state;

        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                {
                    createLoading ? 
                        <CustomLoader
                            open={createLoading}
                        />
                    :   null
                }
                {/* @ts-ignore */}
                {openAlert ? (
                    <AlertModal
                        open={openAlert}
                        data={dataCreationMsg ? dataCreationMsg : errorMsg}
                        onClose={() => this.closeAlertBox()}
                        redirectUri={'LetsTalk'}
                        navigation={this.props.navigation}
                    />
                ) : null}
                {/* @ts-ignore */}
                <Modal
                    open={previewModal}
                    onClose={() => this.handleModalClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {/* @ts-ignore */}
                    <Box className={classes.previewModalStyle}>
                        {/* <PreviewFE 
                            components={blogList} 
                            titelSubtitle={blogUniqueItem} 
                            date={dateTime} 
                            bold={bold}
                            underlined={underlined}
                            italic={italic}
                            alignment={alignment}
                        /> */}
                    </Box>
                </Modal>

                <DashboardDrawer
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={this.handleDrawerToggle}
                    // @ts-ignore
                    navigation={this.props.navigation}
                />
                {
                    // @ts-ignore
                    openSetting && <DashboardSetting
                        handleDrawerToggle={this.handleDrawerToggle}
                        // @ts-ignore
                        navigation={this.props.navigation}
                        categoryList={categoryList}
                        authorList={authorList}
                        authorLoading={authorLoading}
                        handleSelectCategory={this.handleCategorySelect}
                        selectedCategory={selectecCategory}
                        errorMsg={errorMsg}
                        categoryLoading={getCategoryLoading}
                        handleBgImgChange={this.handleBgImgChange}
                        handleSortChange={this.handleSortChange}
                        sort={sort}
                        dateTime={dateTime}
                        handleDateTimechange={this.handleDateTimeChange}
                        getBold={this.getBold}
                        getUnderlined={this.getUnderlined}
                        getItalic={this.getItalic}
                        getAlignment= {this.getAlignment}
                        type={'podcast'}
                        catValidation={this.state.catValidation}
                        from={'talk'}
                        comingFrom={'edit'}
                        coverUrl={this.state.coverUrl}
                        othersName={this.state.othersName}
                        handleSelectOthers={this.handleSelectOthers}
                    />
                }
                {/* @ts-ignore */}
                <Box
                    className={classes.appBarWrap}
                >
                    <AppBar position='fixed' className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                aria-label="open drawer"
                                edge="start"
                                onClick={this.handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                            <div className={classes.logo}>
                                <img src={require('../../../../components/src/logo.svg')} height={70} />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'space-between',
                                    padding: '10px'
                                }}
                            >
                                <div>
                                    <Typography
                                        style={{
                                            fontFamily: 'Poppins-SemiBold',
                                            fontSize: '40px',
                                            color: '#353535',
                                            marginTop: "25px"
                                        }}
                                        className={classes.welcome}
                                    >
                                        Edit
                                    </Typography>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: "center"
                                    }}
                                >
                                    <Typography onClick={() => this.handleDraft()} style={{ cursor: "pointer", fontFamily: "Poppins-Bold", color: "grey", marginTop: "25px" }}>Save draft</Typography>
                                    <Button variant="contained" onClick={() => this.handlePublish()} style={{ backgroundColor: "#F27024", color: "#ffffff", marginTop: "25px", marginLeft: '20px', textTransform: "initial", fontFamily: "Poppins-Bold" }}>Publish</Button>
                                    <IconButton
                                        onClick={() => this.hanldeSettingToggle()}
                                        style={{
                                            border: '1px solid #bdbdbd',
                                            height: '38px',
                                            width: '38px',
                                            borderRadius: '8px',
                                            marginTop: '25px',
                                            marginLeft: '20px',
                                            backgroundColor: openSetting ? "#F27024" : "#ffffff"
                                        }}
                                    >
                                        <img src={require('../../assets/settings-unselectednew.png')} height={'38px'} />
                                    </IconButton>
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <Container >
                        <div style={{ width: openSetting ? "calc(100% - 300px)" : "100%" }}>
                            <div
                                style={{
                                    padding: '28px 48px 40px',
                                    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
                                    marginTop: '20px',
                                    marginBottom: '20px'
                                }}
                            >
                                <div>
                                    <span
                                        style={{
                                            fontSize: '20px',
                                            fontFamily: 'Poppins-SemiBold',
                                            color: '#353535'
                                        }}
                                    >
                                        Let's Talk
                                    </span>
                                </div>
                                <div
                                    style={{
                                        marginTop: '20px'
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        name="title"
                                        value={title}
                                        onChange={(e) => this.handleTitleChange(e)}
                                        InputProps={{
                                            // className: classes.input,
                                            disableUnderline: true,
                                            style: {
                                                height: '88px',
                                                border: '1px solid #e6e8f0',
                                                borderRadius: '9px',
                                                padding: '16px 36px 16px 24px',
                                                fontSize: '20px'
                                            }
                                        }}
                                        style={{
                                            fontSize: "20px",
                                            fontFamily: "Poppins-Light",
                                            marginTop: "10px"
                                        }}
                                        placeholder="Title of the podcast"
                                        error={inputError?.title === undefined || inputError?.title === "" ? false : true}
                                        helperText={inputError.title}
                                    />
                                    <TextField
                                        name="summary"
                                        style={{ fontSize: "16px", fontFamily: "Poppins-Light", paddingTop: "10px" }}
                                        multiline={true}
                                        minRows={7}
                                        fullWidth
                                        value={summary}
                                        onChange={(e) => this.handleSummaryChange(e)}
                                        inputProps={{ maxLength: 1250 }}
                                        placeholder="Summary of the podcast"
                                        InputProps={{
                                            className: classes.input,
                                            disableUnderline: true,
                                            style: {
                                                padding: '20px 24px 30px',
                                                fontSize: '20px',
                                            }
                                        }}
                                        error={inputError?.summary === undefined || inputError?.summary === "" ? false : true}
                                        helperText={inputError.summary}
                                    />
                                    <div
                                        style={{
                                            marginTop: '15px'
                                        }}
                                    >
                                        <FormControl
                                            variant="outlined"
                                            fullWidth={true}
                                        >
                                            <Select
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                IconComponent={KeyboardArrowDownIcon}
                                                className={classes.select}
                                                inputProps={{
                                                    classes: {
                                                        icon: classes.selectIcon,
                                                    },
                                                }}
                                                value={this.state.podcastType}
                                                onChange={(event: any) => this.handleChangeType(event)}
                                            >
                                                <MenuItem value={'audio'}>
                                                    Audio
                                                </MenuItem>
                                                <MenuItem value={'video'}>
                                                    Video
                                                </MenuItem>
                                            </Select>
                                            {inputError.podcastType ? <FormHelperText style={{color: 'red'}}>{inputError.podcastType}</FormHelperText> : null}
                                        </FormControl>
                                    </div>
                                    <PodcastDropZone 
                                        type={this.state.podcastType}
                                        error={inputError.podcastMedia}
                                        mainMediaUrl={this.state.mainMediaUrl}
                                        comingFrom={'edit'}
                                        addInstructorProfile = {(acceptedFiles: any) => this.setMedia(acceptedFiles)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Container>
                </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// export default withStyles(styles)(LetsRead);
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            logo: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            menuButton: {
                marginRight: theme.spacing(2),
                color: palette.primary.main,
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            appBar: {
                background: '#fff',
                boxShadow: 'none',
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                    background: '#fff',
                    boxShadow: 'none'
                },
            },
            appBarWrap: {
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                },
            },
            welcome: {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            name: {
                fontSize: '24px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '20px',
                    fontSize: '16px'
                },
            },
            tabsBox: {
                // flex: 1,
                // flexDirection: 'row',
                // justifyContent: 'space-between',
                width: '100%'
            },
            tabs_main: {
                "& .MuiTabs-indicator": {
                    backgroundColor: palette.primary.main,
                    borderRadius: '1.5px',
                    height: '4px',
                },
                "& .Mui-selected": {
                    color: palette.primary.main
                }
            },
            headingTabs: {
                fontSize: '24px',
                fontWeight: 600,
                fontFamily: palette.fontFamily.main,
                width: "25%",
                textTransform: 'inherit',
                // minWidth: '50% !important'
            },
            lastTableRow: {
                '&:last-child td, &:last-child th': { border: 0 }
            },
            triangle: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.03))',
                marginTop: "49px",
                marginLeft: "12px",
                borderRadius: "10px",
                '& .MuiAvatar-root': {
                    width: '32px ',
                    height: '32px',
                    marginLeft: '-0.5px ',
                    marginRight: '1px',
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: '0px',
                    right: '20px ',
                    width: '15px',
                    height: '15px ',
                    backgroundColor: '#ffffff',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            },
            root: {
                color: "#F27024 !important",
                "& .Mui-selected": {
                    background: "#F27024",
                    color: "white",
                    fontWeight: "bold",
                },
                ul: {
                    "& .MuiPaginationItem-root": {
                        color: "#F27024"
                    }
                }
            },
            modalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '496px',
                height: "200px",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 5px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            previewModalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '796px',
                maxHeight: "80vh",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '20px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            menuItemStyle: {
                fontFamily: 'Poppins-Light',
                '&:hover': {
                    textAlign: "right",
                    color: '#d53636',
                    backgroundColor: '#ffffff',
                }
            },
            input: {
                width: "100%",
                height: "100%",
                border: "1px solid rgba(82,92, 229, 0.12)",
                borderRadius: "7px",
                textDecoration: "none",
                fontSize: "1em",
                fontFamily: "Poppins-Light",
                backgroundColor: "white",
                color: '#000 !important',
                padding: "5px 1rem", // 
                "& > .MuiInputBase-input": {
                    height: "100%",
                },
                "&  .MuiFormHelperText-root.Mui-error": { 
                    fontFamily: "Poppins-Light",
                  },
            },


        }))(EditPodcast)
)
// Customizable Area End
